import * as React from "react";
import { useState, useEffect } from "react";
import Moment from 'moment';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import Avatar from "@mui/material/Avatar";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from '@mui/material/DialogTitle';
import { eventBus } from 'utils/eventbus';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MDBadge from "components/MDBadge";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import EditCategoryItemDialog from "./editcategoryitemdialog";
import FormField from "layouts/admin/accountants/components/FormField";
import { InvoiceEditSchema } from "../components/schemas/editinvoicevalidation";
import MDSnackbar from "components/MDSnackbar";
import Fade from '@mui/material/Fade';
import EditIcon from '@mui/icons-material/Edit';
import { Formik, Form, Field, ErrorMessage } from "formik";
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from "react-router-dom";
import { GetInvoiceDetails, GetItemList, GetAuditLogList, GetCommentsList, CreateComments, UpdateInvoiceDetails, DeleteInvoice, GetVendorList, MoveMenuInvoice, GetuserClientDetails, GetCategoryList, GetClassesList, GetCustomerList, GetProductsList, EditItem, GetIssueList, GetclientUserList } from "../../../../services/clientservices";
import { DeleteItem, EditClientUserItem } from "../../../../services/clientservices";
import { useAPICall } from "../../../../utils/useapicall";
import MDTypography from "components/MDTypography";
import { canAccess } from '../../../../helpers';
import { DocumnetType } from "helpers/documentType";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AddItemDialog from "./additemdialog";
import AddCategoryItemDialog from "./addCategoryItemDialog";
import EditProductItemDialog from "./editproductdialog";
import EditVendorDialog from "./editvendordialog";
import CardHeader from '@mui/material/CardHeader';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TimelineItem from "./components/Timeline/TimelineItem";
import CurrencyFieldText from "layouts/accountants/user/components/CurrencyField-Text.js";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import { DataGrid, useGridApiContext, GridCellModes } from '@mui/x-data-grid';
import { GridRowModes, GridActionsCellItem } from '@mui/x-data-grid-pro';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/cjs/Page/TextLayer.css'
import { Viewer } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import CustomDialog from "components/CustomDialog";
import { inLocalTime } from "utils/timeHelper";
import { textEllipsis } from "components/textEllipsis";
import { Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";
import Stack from '@mui/material/Stack';
import { makeStyles } from '@material-ui/core/styles';
import FormFieldDate from "layouts/accountants/user/components/FormFieldDate";
import LoaderDailog from "layouts/accountants/user/components/imgLoader/LoaderDailog.js"
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import mentionsInputStyle from "layouts/accountants/user/invoice/mentionsInputStyle";
import { MentionsInput, Mention } from "react-mentions";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const myTheme = createTheme({
    palette: {
        type: 'dark'
    },
    typography: {
        fontSize: 13,
        fontWeight: 600,
        backgroundColor: 'rgb(123, 128, 154)'
    },
    components: {
        //@ts-ignore - this isn't in the TS because DataGird is not exported from `@mui/material`
        MuiDataGrid: {
            styleOverrides: {
                row: {
                    '& .MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    '& .MuiDataGrid-actionsCell': {
                        gridGap: '0px'
                    },
                    '& .MuiDataGrid-cell': {
                        justifyContent: "center",
                        outline: ' none',
                        fontWeight: 'bold !important',
                        color: 'rgb(123 128 154)',
                        // border: 0,
                    },
                    '& .MuiDataGrid-cell:focus': {
                        outline: ' none',
                    },
                    "&.Mui-selected": {
                        backgroundColor: "white",
                        color: "black",
                        "&:hover": {
                            backgroundColor: "white"
                        }
                    }
                }
            }
        }
    }
});
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function EditInvoiceDialog({ invoiceId, businessId, onClose, selectTab, reloadApiAfterClose }) {
    const classes = useStyles();
    const [invoice_id, setInvoiceId] = useState(invoiceId);
    const toolbarPluginInstance = toolbarPlugin();
    const [actualSubtotal, setActualSubtotal] = useState(0);
    const [actualTotal, setActualTotal] = useState(0);
    const { Toolbar } = toolbarPluginInstance;
    const [imageUrl1, setImageUrl1] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [showDetailsForm, setShowDetailsForm] = useState(false);
    const [createObjectURL, setCreateObjectURL] = useState(null);
    const [value, setValue] = useState(1);
    const [shippingprice, setShippingprice] = useState(0);
    const [notApproved, setNotApproved] = useState(false);
    const [notApprovedCount, setNotApprovedCount] = useState(0);
    const [styles, setStyles] = useState({});
    const [totalErrorMsg, setTotalErrorMsg] = useState('');
    const [taxErrorMsg, setTaxErrorMsg] = useState('');
    const [firstTime, setFirstTime] = useState(true);
    const [checkFound, setCheckFound] = useState(true);
    const [subtotalErrorMsg, setSubtotalErrorMsg] = useState('');
    const [ocrStatus, setOcrstatus] = useState(true);
    const [showClasses, SetShowClasses] = useState(true);
    const [isSynced, setIsSynced] = useState(false);
    const [itemType, setItemType] = useState('');
    const [counts, setCounts] = useState(0);
    const [users, setUsers] = useState([]);
    // const [onIssueClick1, setOnIssueClick1] = useState(onIssueClick);
    const [novendorIssue, setNoVendorIssue] = useState(false);
    const [novendorIssueMsg, setNoVendorIssueMsg] = useState('');
    const [priceMismatch, setPriceMismatch] = useState(false);
    const [priceMismatchMsg, setPriceMismatchMsg] = useState('');
    const [totalMismatch, setTotalMismatch] = useState(false);
    const [totalMismatchMsg, setTotalMismatchMsg] = useState(false);
    const [onlytotalMismatch, setOnlyTotalMismatch] = useState(false);
    const [invoiceDateMismatch, setInvoiceDateMismatch] = useState(false);
    const [noProduct, setNoProduct] = useState(false);
    const [noCategory, setNoCategory] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [textAreaVal, setTextAreaVal] = useState("");
    const [submitcomment, setsubmitcomment] = useState(false);
    const [vendorname, setVendorname] = useState("");
    const [vendorname1, setVendorname1] = useState("");
    const [vendorList, setVendorList] = useState([])
    const [invoicenumber, setInvoiceNumber] = useState("")
    const [invoicedate, setInvoiceDate] = useState("")
    const [transactionDate, setTransactionDate] = useState("")
    const [uploaddate, setUploadDate] = useState("")
    const [alert, setAlert] = useState(false);
    const [alertDeleteInvoice, setAlertDeleteInvoice] = useState(false);
    const [toggleShow, setToggleShow] = useState(false)
    const [openEditItem, setOpenEditItem] = useState(false)
    const [openAddItem, setOpenAddItem] = useState(false)
    const [openCategoryAddItem, setOpenCategoryAddItem] = useState(false);
    const [openEditVendor, setOpenEditVendor] = useState(false)
    const [total, setTotal] = useState(0)
    const [tax, setTax] = useState(0)
    const [ponumber, setPoNumber] = useState("");
    const [discount, setDiscount] = useState(0);
    const [onChanges, SetonChanges] = useState(false);
    const [discountStore, setDiscountStore] = useState(0);
    const [documentType, setDocumentType] = useState("");
    const [subtotal, setSubTotal] = useState(0);
    const [itemList, setItemList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [currencySymbol, setCurrencySymbol] = useState('$');
    const [btnDisable, setBtnDisable] = useState(false);
    const [editbleData, setEditbleData] = useState(false);
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 5,
        page: 0,
    });
    const [categoryListData, setCategoryListData] = useState([]);
    const [classesList, setClassesList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [btnDisable1, setBtnDisable1] = useState(false);
    const [auditLogList, setAuditLogList] = useState([]);
    const [commentsList, setCommentsList] = useState([]);
    const [itemId, setItemId] = useState([]);
    const [showselectedVendorId, setShowselectedVendorId] = useState("");
    const [editItemId, setEditItemId] = useState([]);
    const [editable, SetEditable] = useState(false);
    const [notEdit, setNotEdit] = useState(false);
    const [taxStore, setTaxStore] = useState(0);
    const [subTotStore, setSubTotStore] = useState(0);
    const [issues, setIssues] = useState([])
    const [TotalStore, setTotalStore] = useState(total);
    const [resetData, setResetData] = useState(false);
    const [documentSource, setDocumnetSource] = useState('');
    const [documentStatus, setDocumentStatus] = useState('');
    const [finalSelect, setFinalSelect] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [openQboSync, setOpenQboSync] = useState(false);
    const [openQboSyncAskClient, setOpenQboSyncAskClient] = useState(false);
    const [extention, setExtention] = useState('')
    const [itemListResponse, SetItemListResponse] = useState({});
    const [vendorerrormsg, setVendorerrormsg] = useState('');
    const [vendorerror, setVendorerror] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [moveApprovel, setMoveApprovel] = useState(false);
    const [Approve, setApprove] = useState(true);
    const [AskClients, setAskClients] = useState(true);
    const [SelectedRows, setSelectedRows] = useState([]);
    const [ErrorMessages, setErrorMessage] = useState("");
    const [showErrorMoveSnackbar, setShowErrorMoveSnackbar] = useState(false);
    const [loadingCircul, setLoadingCircul] = useState(false);
    const [{ isLoading: isMoveMenuLoading, isError: isMoveMenuError, response: MoveMenuResponse }, MoveMenuListAPICall] = useAPICall(MoveMenuInvoice, { onLoad: false });
    const [{ isError, isLoading, response }, setAPICall] = useAPICall(UpdateInvoiceDetails, { onLoad: false });
    const [{ isLoading: isDetailsLoading, isError: isDetailsError, response: DetailsResponse }, DetailsUserAPICall] = useAPICall(GetInvoiceDetails, { onLoad: false });
    const [{ isLoading: isCommentsLoading, isError: isCommentsError, response: CommentsResponse }, CommentsListAPICall] = useAPICall(GetCommentsList, { onLoad: false });
    const [{ isLoading: isDeleteItemLoading, isError: isDeleteItemError, response: DeleteItemResponse }, DeleteItemListAPICall] = useAPICall(DeleteItem, { onLoad: false });
    const [{ isLoading: isGetItemListLoading, isError: isGetItemListError, response: GetItemListResponse }, GetItemListListAPICall] = useAPICall(GetItemList, { onLoad: false });
    const [{ isLoading: isDeleteInvoiceLoading, isError: isDeleteInvoiceError, response: DeleteInvoiceResponse }, DeleteInvoiceListAPICall] = useAPICall(DeleteInvoice, { onLoad: false });
    const [{ isLoading: isCreateComentsLoading, isError: isCreateComentsError, response: CreateComentsResponse }, CreateComentsListAPICall] = useAPICall(CreateComments, { onLoad: false });
    const [{ isLoading: isVendorLoading, isError: isVendorError, response: VendorResponse }, VendorListAPICall] = useAPICall(GetVendorList, { onLoad: false });
    const [{ isLoading: isItemEditLoading, isError: isItemEditError, response: ItemEditResponse }, ItemEditListAPICall] = useAPICall(EditClientUserItem, { onLoad: false });
    const [{ response: clientDetailsDataResponse }, getClientDetailsAPICall] = useAPICall(GetuserClientDetails, { onLoad: false });
    const [{ isLoading: isCategoryLoading, isError: isCategoryError, response: CategoryResponse }, CategoryListAPICall] = useAPICall(GetCategoryList, { onLoad: false });
    const [{ isLoading: isClassesLoading, isError: isClassesError, response: ClassesResponse }, ClassesListAPICall] = useAPICall(GetClassesList, { onLoad: false });
    const [{ isLoading: isCustomerLoading, isError: isCustomerError, response: CustomerResponse }, CustomerListAPICall] = useAPICall(GetCustomerList, { onLoad: false });
    const [{ isLoading: isProductsLoading, isError: isProductsError, response: ProductsResponse }, ProductsListAPICall] = useAPICall(GetProductsList, { onLoad: false });
    const [{ isError: isEditeItemError, isLoading: isEditeItemLoading, response: EditeItemResponse }, setEditItemAPICall] = useAPICall(EditItem, { onLoad: false });
    const [{ isLoading: isIssueLoading, isError: isIssueError, response: IssueResponse }, IssueListAPICall] = useAPICall(GetIssueList, { onLoad: false });
    const [{ isError: isGetUserError, isLoading: isGetUserLoading, response: GetUserResponse }, GetUsersetAPICall] = useAPICall(GetclientUserList, { onLoad: false });

    useEffect(() => {
        if (isGetUserLoading) {
            return () => null;
        }
        if (isGetUserError) {
            response?.code === "DATA401" ? navigate('/user/logout') : console.log("somting else");
            return () => null;
        }
        if (GetUserResponse && GetUserResponse.data.isSuccess === true) {
            const data = GetUserResponse?.data?.data?.records.map((prop, key) => {
                return {
                    id: prop?._id,
                    display: prop?.fname,
                };
            }
            );
            setUsers(data);
        }
    }, [GetUserResponse]);
    useEffect(() => {
        IssueListAPICall({
            payload: [{ "businessId": `${businessId}`, "documentId": `${invoiceId}` }]
        });
    }, [response]);
    useEffect(() => {
        if (isIssueLoading) {
            return () => null;
        }
        if (isIssueError) {
            setLoading(false);
            return () => null;
        }
        if (IssueResponse && IssueResponse.data.isSuccess === true) {
            GetItemListListAPICall({
                payload: [{ "documentId": `${invoiceId}` }]
            })
            if (IssueResponse?.data?.data?.totalRecords === 0) {
                setNoVendorIssue(false)
                setPriceMismatch(false)
                setTotalMismatch(false)
                setOnlyTotalMismatch(false)
                setInvoiceDateMismatch(false)
                setNoProduct(false)
                setNoCategory(false)
            } else if (editable == false) {
                let results = IssueResponse?.data?.data?.records.filter((e) => e.moduleType !== 'ITEM');
                IssueResponse?.data?.data?.records?.map((prop, key) => {
                    prop?.issueType === 'NOVENDOR' ? setNoVendorIssue(true) || setNoVendorIssueMsg(`${prop?.message}`) : setNoVendorIssue(false);
                    prop?.issueType === 'NOSUBTOTAL' ? setPriceMismatch(true) || setPriceMismatchMsg(`${prop?.message}`) : setPriceMismatch(false);
                    prop?.issueType === 'TOTALMISMATCH' ? setTotalMismatch(true) || setTotalMismatchMsg(`${prop?.message}`) : setTotalMismatch(false);
                    prop?.issueType === 'PRICEMISMATCH' ? setPriceMismatch(true) || setPriceMismatchMsg(`${prop?.message}`) : setPriceMismatch(false);
                    prop?.issueType === 'NOTOTAL' ? setOnlyTotalMismatch(true) : setOnlyTotalMismatch(false);
                    prop?.issueType === 'NOINVOICE_DATE' ? setInvoiceDateMismatch(true) : setInvoiceDateMismatch(false);
                    prop?.issueType == 'NOPRODUCT' ? setNoProduct(true) : setNoProduct(false);
                    prop?.issueType === 'NOCATEGORY' ? setNoCategory(true) : setNoCategory(false);
                })
            }
        }
    }, [IssueResponse]);

    useEffect(() => {
        const results = IssueResponse?.data?.data?.records.filter((e) => e.moduleType === 'DOCUMENT');
        results?.map((prop, key) => {
            prop?.issueType === 'NOVENDOR' ? setNoVendorIssue(true) : '';
            prop?.issueType === 'NOSUBTOTAL' ? setPriceMismatch(true) : '';
            prop?.issueType === 'TOTALMISMATCH' ? setTotalMismatch(true) : '';
            prop?.issueType === 'PRICEMISMATCH' ? setPriceMismatch(true) : '';
            prop?.issueType === 'NOTOTAL' ? setOnlyTotalMismatch(true) : '';
            prop?.issueType === 'NOINVOICE_DATE' ? setInvoiceDateMismatch(true) : '';
            prop?.issueType == 'NOPRODUCT' ? setNoProduct(true) : '';
            prop?.issueType === 'NOCATEGORY' ? setNoCategory(true) : '';
        })

    }, [IssueResponse, editable, itemList])


    useEffect(() => {
        finalSelect == 'To Review' ? setNotEdit(true) : setNotEdit(false)
    }, [finalSelect])

    useEffect(async () => {
        if (isEditeItemLoading) {
            return () => null;
        }
        if (isEditeItemError) {
            setLoading(false);
            GetItemListListAPICall({
                payload: [{ "documentId": `${invoice_id}` }]
            })
            if (EditeItemResponse?.data?.message) {
                setErrorMessage(EditeItemResponse.data.message)
            }
            setShowErrorMoveSnackbar(true);
            setTimeout(() => {
                setShowErrorMoveSnackbar(false);
            }, 4000);
            return () => null;
        }
        if (EditeItemResponse && EditeItemResponse.data.isSuccess === true) {
            IssueListAPICall({
                payload: [{ "businessId": `${businessId}`, "documentId": `${invoiceId}` }]
            })
            setTimeout(() => {
                GetItemListListAPICall({
                    payload: [{ "documentId": `${invoice_id}` }]
                })
                DetailsUserAPICall({
                    payload: [{ "documentId": `${invoice_id}`, "businessId": `${businessId}` }]
                })
            }, 2000);
        }
    }, [EditeItemResponse]);
    var resource = '';
    var actions = '';
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);


    const [cellModesModel, setCellModesModel] = React.useState({});

    const handleCellClick = React.useCallback((params, event) => {
        if (!params.isEditable) {
            return;
        }

        // Ignore portal
        if (!event.currentTarget.contains(event.target)) {
            return;
        }

        setCellModesModel((prevModel) => {
            return {
                // Revert the mode of the other cells from other rows
                ...Object.keys(prevModel).reduce(
                    (acc, id) => ({
                        ...acc,
                        [id]: Object.keys(prevModel[id]).reduce(
                            (acc2, field) => ({
                                ...acc2,
                                [field]: { mode: GridCellModes.View },
                            }),
                            {},
                        ),
                    }),
                    {},
                ),
                [params.id]: {
                    // Revert the mode of other cells in the same row
                    ...Object.keys(prevModel[params.id] || {}).reduce(
                        (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
                        {},
                    ),
                    [params.field]: { mode: GridCellModes.Edit },
                },
            };
        });
    }, []);

    const handleCellModesModelChange = React.useCallback((newModel) => {
        setCellModesModel(newModel);
    }, []);

    useEffect(async () => {
        await IssueListAPICall({
            payload: [{ "businessId": `${businessId}`, "documentId": `${invoiceId}` }]
        });
        await ClassesListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
        await CategoryListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
        await CustomerListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
        await ProductsListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
        await GetUsersetAPICall({
            payload: [{ "keyword": `` }]
        })
    }, [])

    useEffect(() => {
        if (isClassesLoading) {
            return () => null;
        }
        if (isClassesError) {
            setLoading(false);
            return () => null;
        }
        if (ClassesResponse && ClassesResponse.data.isSuccess === true) {
            const data = ClassesResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    classId: prop._id
                };
            }
            );
            setClassesList(data);
            setLoading(false);
        }
    }, [ClassesResponse]);

    useEffect(() => {
        if (isCategoryLoading) {
            return () => null;
        }
        if (isCategoryError) {
            setLoading(false);
            return () => null;
        }
        if (CategoryResponse && CategoryResponse.data.isSuccess === true) {
            const data = CategoryResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    categoryId: prop._id
                };
            }
            );
            setCategoryListData(data);
            setLoading(false);
        }
    }, [CategoryResponse]);

    useEffect(() => {
        if (isProductsLoading) {
            return () => null;
        }
        if (isProductsError) {
            setLoading(false);
            return () => null;
        }
        if (ProductsResponse && ProductsResponse.data.isSuccess === true) {
            const data = ProductsResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    productId: prop._id,
                    accountingId: prop.accountingId,
                    description: prop.description,
                    unitPrice: prop.unitPrice,
                    sku: prop.sku,
                    unitOfMeasure: prop.unitOfMeasure,
                };
            }
            );
            setProductsList(data);
            setLoading(false);
        }
    }, [ProductsResponse]);


    useEffect(() => {
        if (isCustomerLoading) {
            return () => null;
        }
        if (isCustomerError) {
            setLoading(false);
            return () => null;
        }
        if (CustomerResponse && CustomerResponse.data.isSuccess === true) {
            const data = CustomerResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop?.name,
                    customerId: prop?._id,
                    productId: prop?._id,
                    description: prop?.description,
                    unitPrice: prop?.unitPrice,
                    sku: prop?.sku,
                    unitOfMeasure: prop?.unitOfMeasure,
                };
            }
            );
            setCustomerList(data);
            setLoading(false);
        }
    }, [CustomerResponse]);

    useEffect(() => {
        if (clientDetailsDataResponse && clientDetailsDataResponse.data.isSuccess === true) {
            SetShowClasses(clientDetailsDataResponse?.data?.data?.client?.preferences?.AccountingInfoPrefs?.ClassTrackingPerTxnLine);
        }
    }, [clientDetailsDataResponse]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        getClientDetailsAPICall({
            payload: [{ "bussinessId": `${businessId}` }]
        });
        GetItemListListAPICall({
            payload: [{ "documentId": `${invoice_id}` }]
        })
    }, [])
    const AprroveFun = async (itemID) => {
        const ItemResp = GetItemListResponse ? GetItemListResponse : itemListResponse
        const founds = ItemResp?.data?.data?.records.find(obj => {
            return obj._id === itemID;
        });
        if (founds) {
            setLoading(true);
            setApprove(!founds?.clientApproved);
            setAskClients(!founds?.askClient)
            setTimeout(async () => {
                await ItemEditListAPICall({
                    payload: [{
                        "documentId": `${invoiceId}`,
                        "ItemId": itemID,
                        "askClient": founds?.askClient === true ? false : true,
                        "itemType": founds?.itemType,
                        "clientApproved": founds?.clientApproved === true ? false : true,
                        "unitPrice": founds?.unitPrice,
                        "amount": founds?.amount,
                        "discount": founds?.discount,
                        "categoryId": founds?.category?._id,
                        "productId": founds?.product?._id,
                        "quantity": founds?.quantity ? founds?.quantity : 1,
                        "description": founds?.description,
                        "classId": founds?.class?._id,
                        "customerId": founds?.customer?._id,
                    }]
                });
            }, 1000);
        }

    };
    useEffect(() => {
        if (isItemEditLoading) {
            return () => null;
        }
        if (isItemEditError) {
            setLoading(false);
            return () => null;
        }
        if (ItemEditResponse && ItemEditResponse.data.isSuccess === true) {
            setLoading(false);
            GetItemListListAPICall({
                payload: [{ "documentId": `${invoice_id}` }]
            })
        }
    }, [ItemEditResponse]);
    useEffect(() => {
        if (isVendorLoading) {
            return () => null;
        }
        if (isVendorError) {
            setLoading(false);
            return () => null;
        }
        if (VendorResponse && VendorResponse.data.isSuccess === true) {
            setVendorList(VendorResponse.data.data.records);
        }
    }, [VendorResponse]);
    useEffect(() => {
        if (isMoveMenuLoading) {
            return () => null;
        }
        if (isMoveMenuError) {
            setLoading(false);
            return () => null;
        }
        if (MoveMenuResponse && MoveMenuResponse.data.isSuccess === true) {
            closeMoveDialog();
            setLoading(true);
            setShowSnackbar(true);
            setTimeout(() => {
                onClose();
            }, 700);
        }
    }, [MoveMenuResponse]);

    useEffect(() => {
        if (isCreateComentsLoading) {
            return () => null;
        }
        if (isCreateComentsError) {
            setBtnDisable1(false);
            setLoading(false);
            return () => null;
        }
        if (CreateComentsResponse && CreateComentsResponse.data.isSuccess === true) {
            setTextAreaVal('');
            setBtnDisable1(false);
            CommentsListAPICall({
                payload: [{ "documentId": `${invoice_id}`, "businessId": `${businessId}` }]
            })
        }
    }, [CreateComentsResponse]);

    useEffect(() => {
        if (isDeleteInvoiceLoading) {
            return () => null;
        }
        if (isDeleteInvoiceError) {
            setLoading(false);
            return () => null;
        }
        if (DeleteInvoiceResponse && DeleteInvoiceResponse.data.isSuccess === true) {
            eventBus.$emit("closeDeleteItemPopup", DeleteInvoiceResponse);
            setLoading(true);

        }
    }, [DeleteInvoiceResponse]);

    useEffect(() => {
        if (isGetItemListLoading) {
            return () => null;
        }
        if (isGetItemListError) {
            return () => null;
        }
        if (GetItemListResponse && GetItemListResponse.data.isSuccess === true) {
            SetItemListResponse(GetItemListResponse);
            const data = GetItemListResponse.data.data.records.map((prop, key) => {
                const Error = IssueResponse?.data?.data?.records.find(obj => {
                    return obj.moduleId === prop._id;
                })
                return {
                    item: prop?.description ? prop?.description : '---',
                    qty: prop?.quantity ? prop?.quantity : '---',
                    unit: prop?.unitOfMeasure ? prop?.unitOfMeasure : '---',
                    product: prop?.product?.name ? prop?.product?.name : '---',
                    price: prop?.unitPrice ? prop?.unitPrice : '0',
                    total: prop?.amount ? prop?.amount : '---',
                    customer: prop?.customer?.name ? prop?.customer?.name : '---',
                    category: prop?.category?.name ? prop?.category?.name : '---',
                    class: prop?.class?.name ? prop?.class?.name : '---',
                    proderror: Error ? true : false,
                    proderrorMsg: Error ? Error?.message : '---',
                    id: prop._id,
                    sku: prop.sku,
                    itemType: prop.itemType,
                    askClient: prop?.askClient,
                };
            });
            const found = data.find(obj => {
                return obj.askClient === true;
            });
            setCheckFound(found === undefined);
            // setItemList(data);
            let itemsss = data.find(item => item.clientApproved === false);
            var obj = {};
            data.forEach(function (item) {
                obj[item.clientApproved] ? obj[item.clientApproved]++ : obj[item.clientApproved] = 1;
            });
            setNotApprovedCount(obj?.false)
            if (itemsss === undefined) {
                setNotApproved(false);
            } else {
                setNotApproved(true);
            }
            const Categoryresult = data.filter(data => data.itemType === 'AccountBasedExpenseLineDetail');
            const Productresult = data.filter(data => data.itemType === 'ItemBasedExpenseLineDetail');
            setCategoryList(data);
            setProductList(data);
            setLoading(false);
            setShowDetails(true);
            setLoadingCircul(false);
            if (firstTime === true) {
                if (Categoryresult.length === 0) {
                    // setToggleShow(true),
                    setItemList(data),
                        setLoading(false),
                        setFirstTime(false);
                } else {
                    // setToggleShow(false),
                    setItemList(data),
                        setLoading(false),
                        setFirstTime(false);
                }
            } else {
                setLoading(false),
                    toggleShow === true ? setItemList(data) : setItemList(data)
            }
        }
    }, [GetItemListResponse]);


    useEffect(() => {
        if (productList || categoryList) {
            { toggleShow === true ? setItemList(productList) : setItemList(categoryList) }
        }
    }, [toggleShow])

    // useEffect(() => {
    //     { toggleShow === true ? setItemList(productList) : setItemList(categoryList) }
    // }, [GetItemListResponse])


    const closeProductEdit = async () => {
        setOpenEditItem(false);
    }

    useEffect(() => {
        if (isDeleteItemLoading) {
            return () => null;
        }
        if (isDeleteItemError) {
            setLoading(false);
            return () => null;
        }
        if (DeleteItemResponse && DeleteItemResponse.data.isSuccess === true) {
            GetItemListListAPICall({
                payload: [{ "documentId": `${invoice_id}` }]
            })
            setLoading(false);
        }
    }, [DeleteItemResponse]);

    useEffect(() => {
        if (isCommentsLoading) {
            return () => null;
        }
        if (isCommentsError) {
            return () => null;
        }
        if (CommentsResponse && CommentsResponse.data.isSuccess === true) {
            const LogCommentdata = CommentsResponse.data.data.records.map((prop, key) => {
                const commentBy = prop.commentBy.fname.charAt(0).toUpperCase() + prop.commentBy.fname.slice(1) + " " + prop.commentBy.lname
                const comments = prop.comment;
                var found = [],
                    rxp = /{{([^}]+)}}/g,
                    str = comments,
                    curMatch;

                while (curMatch = rxp.exec(str)) {
                    found.push(curMatch[1]);
                }
                function findReplaceString(string, found) {
                    let results = string.match(/{{([^}]+)}}/g);
                    if (results) {
                        let results = string.match(/{{([^}]+)}}/g);
                        const final = results.map((prop, key) => {
                            const Name = users.find(obj => {
                                return obj.id == found[key];
                            });
                            string = string.replace(new RegExp('\{\{(?:\\s+)?(' + found[key] + ')(?:\\s+)?\}\}'), `@${Name?.display}`);
                            return string;
                        });
                        return final.slice(-1);
                    }
                }
                const datacom = findReplaceString(comments, found)
                return (
                    <Paper style={{ padding: "30px 20px" }}>
                        <Grid container wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Avatar alt="Remy Sharp" sx={{ width: 33, height: 33 }} />
                            </Grid>
                            <Grid justifyContent="left" item xs zeroMinWidth>
                                <div style={{ display: "flex" }}>
                                    <h4 style={{
                                        margin: 0,
                                        textAlign: "left",
                                        fontFamily: "Arial",
                                        fontSize: "0.875rem",
                                        fontWeight: "bold",
                                        lineHeight: 1.5,
                                        textTransform: "uppercase",
                                        letterSpacing: "0.02857em",
                                        opacity: 1,
                                        textTransform: "none",
                                        verticalAlign: "unset",
                                        textAlign: "left",
                                        marginBottom: "5px",
                                    }}>{commentBy}</h4>
                                    <MDTypography sx={{ mt: 0.5, ml: 1 }} variant="caption" color="text">
                                        {inLocalTime(prop.createdAt)}
                                    </MDTypography>
                                </div>
                                <p style={{
                                    margin: 0,
                                    fontFamily: "Arial",
                                    fontSize: "0.875rem",
                                    fontWeight: 300,
                                    lineHeight: 1.5,
                                    textTransform: "uppercase",
                                    letterSpacing: "0.02857em",
                                    opacity: 1,
                                    textTransform: "none",
                                    verticalAlign: "unset",
                                    textAlign: "left"
                                }}>
                                    {datacom ? datacom[0] : comments}
                                </p>
                            </Grid>
                        </Grid>
                    </Paper >
                )
            });
            setCommentsList(LogCommentdata);
            setLoading(false);
        }
    }, [CommentsResponse]);

    useEffect(() => {
        if (isLoading) {
            return () => null;
        }
        if (isError) {
            setLoading(false);
            return () => null;
        }
        if (response && response.data.isSuccess === true) {
            eventBus.$emit("refreshListAfterUpdate", response);
            DetailsUserAPICall({
                payload: [{ "documentId": `${invoice_id}`, "businessId": `${businessId}` }]
            })
            setShowSnackbar(false);
            setTimeout(() => {
                setShowSnackbar(false);
            }, 10000);
            setLoading(true);
        }
    }, [response]);

    useEffect(async () => {
        setSelectedRows([invoice_id]);
        await DetailsUserAPICall({
            payload: [{ "documentId": `${invoice_id}`, "businessId": `${businessId}` }]
        })
        await VendorListAPICall({
            payload: [{ "businessId": `${businessId}`, IsSynced: true }]
        });
    }, []);

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (openAddItem) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openAddItem]);

    useEffect(() => {
        if (isDetailsLoading) {
            return () => null;
        }

        if (isDetailsError) {
            return () => null;
        }

        if (DetailsResponse && DetailsResponse.data.isSuccess === true) {
            setDocumentStatus(DetailsResponse?.data?.data?.document?.documentStatus ? DetailsResponse?.data?.data?.document?.documentStatus : '')
            DetailsResponse?.data?.data?.document?.itemType === 'ItemBasedExpenseLineDetail' ? setToggleShow(true) : setToggleShow(false);
            DetailsResponse?.data?.data?.document?.documentSource === "QBO" ? setEditbleData(false) : setEditbleData(true)
            setItemType(DetailsResponse?.data?.data?.document?.itemType);
            setOcrstatus(DetailsResponse?.data?.data?.document?.ocrStatus === 'INPROGRESS' ? true : false);
            setIsSynced(DetailsResponse?.data?.data?.document?.isSynced);
            setVendorname(DetailsResponse.data.data.document.vendor ? DetailsResponse.data.data.document.vendor.name : '');
            setVendorname1(DetailsResponse.data.data.document.vendor ? DetailsResponse.data.data.document.vendor.name : '');
            setInvoiceNumber(DetailsResponse.data.data.document.invoice_number ? DetailsResponse.data.data.document.invoice_number : DetailsResponse.data.data.document.docNumber);
            setPoNumber((DetailsResponse.data.data.document.order_no) ? DetailsResponse.data.data.document.order_no : '')
            setInvoiceDate(Moment(DetailsResponse.data.data.document.invoice_date).format('YYYY-MM-DD'));
            setTransactionDate(Moment(DetailsResponse.data.data.document.transactionDate).format('YYYY-MM-DD'))
            setUploadDate(Moment(DetailsResponse.data.data.document.createdAt).format('YYYY-MM-DD'));
            setTotal(DetailsResponse.data.data.document.total);
            setTotalStore(DetailsResponse.data.data.document.total);
            setTax(DetailsResponse.data.data.document.tax);
            setTaxStore(DetailsResponse.data.data.document.tax);
            setDiscount(DetailsResponse?.data?.data?.document?.discount);
            setDiscountStore(DetailsResponse?.data?.data?.document?.discount);
            setPoNumber(DetailsResponse.data.data.document.purchaseOrderNo);
            setIssues(DetailsResponse.data.data.document.issues);
            setShippingprice(DetailsResponse?.data?.data?.document?.shippingPrice);
            setSubTotal(DetailsResponse.data.data.document.sub_total ? DetailsResponse.data.data.document.sub_total : DetailsResponse.data.data.document.subTotal);
            setSubTotStore(DetailsResponse.data.data.document.sub_total ? DetailsResponse.data.data.document.sub_total : DetailsResponse.data.data.document.subTotal);
            setCurrencySymbol(DetailsResponse.data.data.document.currencySymbol);
            setDocumnetSource(DetailsResponse.data.data.document.documentSource ? DetailsResponse.data.data.document.documentSource : '')
            setDocumentType(DetailsResponse.data.data.document.documentType ? DetailsResponse.data.data.document.documentType : '');
            setCreateObjectURL(DetailsResponse.data.data.document.uploadedFileUrl ? DetailsResponse.data.data.document.uploadedFileUrl : '')
            setImageUrl(DetailsResponse?.data?.data?.document?.uploadedFileUrl ? DetailsResponse?.data?.data?.document?.uploadedFileUrl : '')
            setActualSubtotal(DetailsResponse?.data?.data?.document?.actualSubtotal);
            setActualTotal(DetailsResponse?.data?.data?.document?.actualTotal);
            // setShowDetails(true);
            setLoading(false);
            setShowDetailsForm(true);
            setResetData(false);
            setInitialValues({
                vendorname: DetailsResponse.data.data.document.vendor ? DetailsResponse.data.data.document.vendor.name : '',
                documenttype: DetailsResponse.data.data.document.documentType ? DetailsResponse.data.data.document.documentType : '',
                invoicenumber: DetailsResponse.data.data.document.docNumber,
                ponumber: DetailsResponse.data.data.document.purchaseOrderNo,
                invoicedate: Moment(DetailsResponse.data.data.document.invoice_date).format('YYYY-MM-DD'),
                uploaddate: Moment(DetailsResponse.data.data.document.createdAt).format('YYYY-MM-DD'),
                total: `${total}`,
                tax: `${tax}`,
                transactionDate: `${transactionDate}`,
                subtotal: `${subtotal}`
            })
            IssueListAPICall({
                payload: [{ "businessId": `${businessId}`, "documentId": `${invoiceId}` }]
            });
        }
    }, [DetailsResponse]);

    useEffect(() => {
        setInitialValues({
            documenttype: documentType,
            invoicenumber: invoicenumber,
            ponumber: ponumber,
            invoicedate: invoicedate,
            uploaddate: uploaddate,
            total: `${total}`,
            tax: `${tax}`,
            transactionDate: `${transactionDate}`,
            subtotal: `${subtotal}`
        })
    }, [resetData]);

    const closeMoveDialog = async () => {
        setOpenQboSync(false);
        setMoveApprovel(false);
        setOpenQboSyncAskClient(false);
    }

    const successInoiceMove = async () => {
        setLoading(true);
        closeMoveDialog();
        const MoveTo = issues?.documents?.count === 0 && issues?.items?.count === 0 && issues?.vendor?.count === 0 ? 'ToReview' : 'Classify'
        await MoveMenuListAPICall({
            payload: [
                {
                    "documentIds": {
                        "documentIds": SelectedRows,
                        "documentStatus": `${MoveTo}`,
                    },
                    "businessId": `${businessId}`,
                }
            ]
        })
        setLoading(true);
    }

    function splitCamelCaseToString(s) {
        return s.split(/(?=[A-Z])/).join(' ');
    }

    const handleClosebtn = async () => {
        setVendorname(vendorname1);
        setResetData(true);
        SetEditable(false);
        await DetailsUserAPICall({
            payload: [{ "documentId": `${invoice_id}`, "businessId": `${businessId}` }]
        })
    }

    const handleChange = async (event, newValue) => {
        setFirstTime(true);
        setValue(newValue);
        // if (newValue === 0) {
        //     setShowDetailsForm(false);
        //     setLoading(true);
        //     DetailsUserAPICall({
        //         payload: [{ "InvoiceId": `${invoice_id}`, "businessId": `${businessId}` }]
        //     })
        // }
        // else
        if (newValue === 1) {
            setLoading(true);
            await GetItemListListAPICall({
                payload: [{ "documentId": `${invoice_id}` }]
            })
        } else if (newValue === 2) {
            setLoading(true);
            await CommentsListAPICall({
                payload: [{ "documentId": `${invoice_id}`, "businessId": `${businessId}` }]
            })
        }
    };
    const handleSubmit = async (event, values) => {
        SetonChanges(false);
        await setAPICall({
            payload: [
                {
                    "txnDate": `${event.transactionDate}`,
                    "createdAt": `${event.uploaddate}`,
                    "documentType": `${event.documenttype}`,
                    "docNumber": `${event.invoicenumber}`,
                    "vendorId": `${showselectedVendorId}`,
                    "subTotal": `${subTotStore}`,
                    "tax": `${taxStore}`,
                    "total": `${TotalStore}`,
                    "purchaseOrderNo": `${event.ponumber}`,
                    "businessId": `${businessId}`,
                    "documentId": `${invoice_id}`
                }
            ]
        },
        );
        eventBus.$emit("refreshApiInvoiceList", event);

    }

    const handleToggle = () => {
        setToggleShow(!toggleShow)
        setLoading(true),
            setAPICall({
                payload: [
                    {
                        "itemType": toggleShow === false ? `ItemBasedExpenseLineDetail` : `AccountBasedExpenseLineDetail`,
                        "businessId": `${businessId}`,
                        "documentId": `${invoice_id}`
                    }
                ]
            },
            );
    }
    const handleAddItem = () => {
        setOpenAddItem(true)
    }
    const handleCategort = () => {
        setOpenCategoryAddItem(true)
    }
    const handleEditItem = () => {
        setOpenEditItem(true)
    }

    const handleEditVendor = () => {
        handleClose()
        setOpenEditVendor(true);
    }

    const warningWithConfirmMessage = () => {
        setAlert(true);
    };
    const hideAlert = () => {
        setAlert(false);
    };
    const successDelete = () => {
        DeleteItemListAPICall({
            payload: [
                {
                    "adjustInvoice": false,
                    "documentId": `${invoice_id}`,
                    "ItemId": `${itemId}`,
                }
            ]
        });
        setAlert(false);
        setLoading(true);
    }


    const hideInvoiceAlert = () => {
        setAlertDeleteInvoice(false);
    };
    const successInoiceDelete = () => {
        DeleteInvoiceListAPICall({
            payload: [
                {
                    "documentIds": {
                        'documentIds': [invoiceId]
                    },
                    "businessId": `${businessId}`,
                }
            ]
        });
        setAlert(false);
        setLoading(true);
    }

    function SelectCategoryEditInputCell(props) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleCategoryChanges = async (event) => {
            setLoadingCircul(true);
            if (event.target.textContent == '+ Add Category') {
                setOpenAddNewCategory(true);
            } else {
                await apiRef.current.setEditCellValue({ id, field, value: event.target.textContent });
                apiRef.current.stopCellEditMode({ id, field });
                const result = itemListResponse?.data?.data?.records.map((prop, key) => {
                    {
                        return {
                            id: prop._id,
                            item: prop.description,
                            qty: prop.quantity,
                            unit: prop.unitOfMeasure,
                            price: prop.unitPrice,
                            total: prop.total,
                            sku: prop.sku,
                            amount: prop?.amount,
                            unitPrice: prop.unitPrice,
                            results: prop.results,
                            askClients: prop.askClient,
                            category: prop.category ? prop.category.name : '',
                            categoryId: prop.category ? prop.category._id : '',
                            class: prop.class ? prop.class.name : '',
                            product: prop.product ? prop.product.name : '',
                            productId: prop.product ? prop.product._id : '',
                            classId: prop.class ? prop.class._id : '',
                            customer: prop.customer ? prop.customer.name : '',
                            customerId: prop.customer ? prop.customer._id : '',
                        }
                    }
                });
                const found = categoryListData.find(obj => {
                    return obj.name === event.target.textContent;
                });
                const results = result.find(item => item.id === id);
                var Str = results?.amount
                var newStr = Str ? Str.replace(/,/g, '') : 0;
                var Str1 = results?.unitPrice ? results?.unitPrice : results?.price
                var newStr1 = Str1 ? Str1.replace(/,/g, '') : 0;
                const Qty = results?.quantity ? results?.quantity : results?.qty;
                if (results) {
                    toggleShow ?
                        setEditItemAPICall({
                            payload: [{
                                "itemType": 'ItemBasedExpenseLineDetail',
                                "unitPrice": Number(newStr1 ? newStr1 === 0 ? newStr : newStr1 : newStr),
                                "amount": Number(newStr ? newStr === 0 ? newStr1 : newStr : newStr1),
                                "discount": 0,
                                "sku": results?.sku,
                                "description": results?.item,
                                "unitOfMeasure": results?.unit ? results?.unit : null,
                                "productId": results?.productId ? results?.productId : null,
                                "InvoiceId": invoiceId ? invoiceId : documentsId,
                                "classId": results?.classId ? results?.classId : null,
                                "customerId": results?.customerId ? results?.customerId : null,
                                "quantity": Number(Qty ? Qty : 1),
                                "askClient": results.askClients,
                                "ItemId": results.id,
                            }]
                        }) :
                        setEditItemAPICall({
                            payload: [{
                                "itemType": 'AccountBasedExpenseLineDetail',
                                "unitPrice": Number(newStr1 ? newStr1 === 0 ? newStr : newStr1 : newStr),
                                "amount": Number(newStr ? newStr === 0 ? newStr1 : newStr : newStr1),
                                "unitOfMeasure": results?.unit ? results?.unit : null,
                                "quantity": Number(Qty ? Qty : 1),
                                "discount": 0,
                                "description": results?.item,
                                "InvoiceId": invoiceId ? invoiceId : documentsId,
                                "classId": results?.classId ? results?.classId : null,
                                "customerId": results?.customerId ? results?.customerId : null,
                                "categoryId": found?.categoryId,
                                'askClient': results.askClients,
                                "ItemId": results.id,
                            }]
                        })
                }
            }
        };

        return (
            <>
                <Autocomplete
                    id="combo-box-demo"
                    classes={{
                        option: styles.option
                    }}
                    fullWidth
                    open={true}
                    value={{ name: value }}
                    options={categoryListData}
                    getOptionLabel={(option) => option.name}
                    disableClearable
                    ListboxProps={{
                        sx: {
                            fontSize: `0.8125rem`,
                            '@media (max-width: 1440px)': {
                                fontSize: `0.775rem`
                            },
                        },
                    }}
                    sx={{
                        '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                            fontSize: `0.8125rem`,
                            '@media (max-width: 1440px)': {

                                fontSize: `0.775rem`
                            },
                        },
                    }}
                    onChange={handleCategoryChanges}
                    renderInput={(params) => <TextField {...params} />}
                />
            </>
        );
    }

    function getPrice(params) {
        return `$${params?.row?.total}`;
    }
    function getPriceProduct(params) {
        return `$${params?.row?.price}`;
    }
    function getTotalProduct(params) {
        return `$${params?.row?.total}`;
    }

    function getQuantity(params) {
        const finalQty = params?.row?.itemType === "AccountBasedExpenseLineDetail" ? params?.row?.qty : params?.row?.qty;
        return finalQty;
    }

    const handleDeleteInvoice = () => {
        // setOpenMenu(false)
        handleClose()

        setAlertDeleteInvoice(true);
    }

    function SelectProductEditInputCell(props) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleProductChanges = async (event) => {
            setLoadingCircul(true);
            if (event.target.textContent == '+ Add Product') {
                setOpenAddOption(true);
            } else {
                await apiRef.current.setEditCellValue({ id, field, value: event.target.textContent });
                apiRef.current.stopCellEditMode({ id, field });
                const result = itemListResponse?.data?.data?.records.map((prop, key) => {
                    {
                        return {
                            id: prop._id,
                            item: prop.description,
                            qty: prop.quantity,
                            unit: prop.unitOfMeasure,
                            price: prop.unitPrice,
                            total: prop.total,
                            sku: prop.sku,
                            amount: prop?.amount,
                            unitPrice: prop?.unitPrice,
                            results: prop.results,
                            askClients: prop.askClient,
                            category: prop.category ? prop.category.name : '',
                            categoryId: prop.category ? prop.category._id : '',
                            class: prop.class ? prop.class.name : '',
                            product: prop.product ? prop.product.name : '',
                            productId: prop.product ? prop.product._id : '',
                            classId: prop.class ? prop.class._id : '',
                            customer: prop.customer ? prop.customer.name : '',
                            customerId: prop.customer ? prop.customer._id : '',
                        }
                    }
                });
                const found = productsList.find(obj => {
                    return obj.name === event.target.textContent;
                });
                const results = result.find(item => item.id === id);
                var Str = results?.amount
                var newStr = Str ? Str.replace(/,/g, '') : 0;
                var Str1 = results?.unitPrice ? results?.unitPrice : results?.price
                var newStr1 = Str1 ? Str1.replace(/,/g, '') : 0;
                const Qty = results?.quantity ? results?.quantity : results?.qty;
                if (results) {
                    toggleShow ?
                        setEditItemAPICall({
                            payload: [{
                                "itemType": 'ItemBasedExpenseLineDetail',
                                "unitPrice": Number(newStr1 ? newStr1 === 0 ? newStr : newStr1 : newStr),
                                "amount": Number(newStr ? newStr === 0 ? newStr1 : newStr : newStr1),
                                "discount": 0,
                                "sku": results?.sku,
                                "description": results?.item,
                                "unitOfMeasure": results?.unit ? results?.unit : results?.unit,
                                "productId": found?.productId,
                                "InvoiceId": invoiceId ? invoiceId : documentsId,
                                "classId": results?.classId ? results?.classId : null,
                                "customerId": results?.customerId ? results?.customerId : null,
                                "quantity": Number(Qty ? Qty : 1),
                                "askClient": results.askClients,
                                "ItemId": results.id,
                            }]
                        }) :
                        setEditItemAPICall({
                            payload: [{
                                "itemType": 'AccountBasedExpenseLineDetail',
                                "unitPrice": Number(newStr1 ? newStr1 === 0 ? newStr : newStr1 : newStr),
                                "amount": Number(newStr ? newStr === 0 ? newStr1 : newStr : newStr1),
                                "unitOfMeasure": results?.unit ? results?.unit : null,
                                "quantity": Number(Qty ? Qty : 1),
                                "discount": 0,
                                "description": results?.item,
                                "InvoiceId": invoiceId ? invoiceId : documentsId,
                                "classId": results?.classId ? results?.classId : null,
                                "customerId": results?.customerId ? results?.customerId : null,
                                "categoryId": results?.categoryId ? results?.categoryId : null,
                                'askClient': results.askClients,
                                "ItemId": results.id,
                            }]
                        })
                    // setDescription(found.description);
                    // setUnitPrice(found.unitPrice)
                }
            }
        };

        return (
            <Autocomplete
                id="combo-box-demo"
                fullWidth
                open={true}
                value={{ name: value }}
                options={productsList}
                classes={{
                    option: styles.option
                }}
                getOptionLabel={(option) => option.name}
                disableClearable
                ListboxProps={{
                    sx: {
                        fontSize: `0.8125rem`,
                        '@media (max-width: 1440px)': {

                            fontSize: `0.775rem`
                        },
                    },
                }}
                sx={{
                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                        fontSize: `0.8125rem`,
                        '@media (max-width: 1440px)': {

                            fontSize: `0.775rem`
                        },
                    },
                }}
                onChange={handleProductChanges}
                renderInput={(params) => <TextField {...params} />}
            />
        );
    }

    function SelectClassEditInputCell(props) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleClassChanges = async (event) => {
            if (event.target.textContent == '+ Add Class') {
                setOpenAddNewClass(true);
            } else {
                await apiRef.current.setEditCellValue({ id, field, value: event.target.textContent });
                apiRef.current.stopCellEditMode({ id, field });
                const result = itemListResponse?.data?.data?.records.map((prop, key) => {
                    {
                        return {
                            id: prop._id,
                            item: prop.description,
                            qty: prop.quantity,
                            unit: prop.unitOfMeasure,
                            price: prop.unitPrice,
                            total: prop.total,
                            sku: prop.sku,
                            amount: prop?.amount,
                            unitPrice: prop.unitPrice,
                            results: prop.results,
                            askClients: prop.askClient,
                            category: prop.category ? prop.category.name : '',
                            categoryId: prop.category ? prop.category._id : '',
                            class: prop.class ? prop.class.name : '',
                            product: prop.product ? prop.product.name : '',
                            productId: prop.product ? prop.product._id : '',
                            classId: prop.class ? prop.class._id : '',
                            customer: prop.customer ? prop.customer.name : '',
                            customerId: prop.customer ? prop.customer._id : '',
                        }
                    }
                });
                const found = classesList.find(obj => {
                    return obj.name === event.target.textContent;
                });
                const results = result.find(item => item.id === id);
                var Str = results?.amount
                var newStr = Str ? Str.replace(/,/g, '') : 0;
                var Str1 = results?.unitPrice ? results?.unitPrice : results?.price
                var newStr1 = Str1 ? Str1.replace(/,/g, '') : 0;
                const Qty = results?.quantity ? results?.quantity : results?.qty;
                if (results) {
                    toggleShow ?
                        setEditItemAPICall({
                            payload: [{
                                "itemType": 'ItemBasedExpenseLineDetail',
                                "unitPrice": Number(newStr1 ? newStr1 === 0 ? newStr : newStr1 : newStr),
                                "amount": Number(newStr ? newStr === 0 ? newStr1 : newStr : newStr1),
                                "discount": 0,
                                "sku": results?.sku,
                                "description": results?.item,
                                "unitOfMeasure": results?.unit ? results?.unit : null,
                                "productId": results?.productId ? results?.productId : null,
                                "InvoiceId": invoiceId ? invoiceId : documentsId,
                                "classId": found?.classId,
                                "customerId": results?.customerId ? results?.customerId : null,
                                "quantity": Number(Qty ? Qty : 1),
                                "askClient": results.askClients,
                                "ItemId": results.id,
                            }]
                        }) :
                        setEditItemAPICall({
                            payload: [{
                                "itemType": 'AccountBasedExpenseLineDetail',
                                "unitPrice": Number(newStr1 ? newStr1 === 0 ? newStr : newStr1 : newStr),
                                "amount": Number(newStr ? newStr === 0 ? newStr1 : newStr : newStr1),
                                "unitOfMeasure": results?.unit ? results?.unit : null,
                                "quantity": Number(Qty ? Qty : 1),
                                "discount": 0,
                                "description": results?.item,
                                "InvoiceId": invoiceId ? invoiceId : documentsId,
                                "classId": found?.classId,
                                "customerId": results?.customerId ? results?.customerId : null,
                                "categoryId": results?.categoryId ? results?.categoryId : null,
                                'askClient': results.askClients,
                                "ItemId": results.id,
                            }]
                        })
                }
            }
        };

        return (
            <Autocomplete
                id="combo-box-demo"
                fullWidth
                open={true}
                value={{ name: value }}
                classes={{
                    option: styles.option
                }}
                options={classesList}
                getOptionLabel={(option) => option.name}
                disableClearable
                ListboxProps={{
                    sx: {
                        fontSize: `0.8125rem`,
                        '@media (max-width: 1440px)': {

                            fontSize: `0.775rem`
                        },
                    },
                }}
                sx={{
                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                        fontSize: `0.8125rem`,
                        '@media (max-width: 1440px)': {

                            fontSize: `0.775rem`
                        },
                    },
                }}
                onChange={handleClassChanges}
                renderInput={(params) => <TextField {...params} />}
            />
        );
    }

    function SelectCustomerEditInputCell(props) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleCustomerChanges = async (event) => {
            if (event.target.textContent == '+ Add Customer') {
                setOpenAddNewCustomer(true);
            } else {
                await apiRef.current.setEditCellValue({ id, field, value: event.target.textContent });
                apiRef.current.stopCellEditMode({ id, field });
                const result = itemListResponse?.data?.data?.records.map((prop, key) => {
                    {
                        return {
                            id: prop._id,
                            item: prop.description,
                            qty: prop.quantity,
                            unit: prop.unitOfMeasure,
                            price: prop.unitPrice,
                            total: prop.total,
                            sku: prop.sku,
                            amount: prop?.amount,
                            unitPrice: prop.unitPrice,
                            askClients: prop.askClient,
                            category: prop.category ? prop.category.name : '',
                            categoryId: prop.category ? prop.category._id : '',
                            class: prop.class ? prop.class.name : '',
                            product: prop.product ? prop.product.name : '',
                            productId: prop.product ? prop.product._id : '',
                            classId: prop.class ? prop.class._id : '',
                            customer: prop.customer ? prop.customer.name : '',
                            customerId: prop.customer ? prop.customer._id : '',
                        }
                    }
                });
                const found = customerList.find(obj => {
                    return obj.name === event.target.textContent;
                });
                const results = result.find(item => item.id === id);
                var Str = results?.amount
                var newStr = Str ? Str.replace(/,/g, '') : 0;
                var Str1 = results?.unitPrice ? results?.unitPrice : results?.price
                var newStr1 = Str1 ? Str1.replace(/,/g, '') : 0;
                const Qty = results?.quantity ? results?.quantity : results?.qty;
                if (results) {
                    toggleShow ?
                        setEditItemAPICall({
                            payload: [{
                                "itemType": 'ItemBasedExpenseLineDetail',
                                "unitPrice": Number(newStr1 ? newStr1 === 0 ? newStr : newStr1 : newStr),
                                "amount": Number(newStr ? newStr === 0 ? newStr1 : newStr : newStr1),
                                "discount": 0,
                                "sku": results?.sku,
                                "description": results?.item,
                                "unitOfMeasure": results?.unit ? results?.unit : null,
                                "productId": results?.productId ? results?.productId : null,
                                "InvoiceId": invoiceId ? invoiceId : documentsId,
                                "classId": results?.classId ? results?.classId : null,
                                "customerId": found?.customerId,
                                "quantity": Number(Qty ? Qty : 1),
                                "askClient": results.askClients,
                                "ItemId": results.id,
                            }]
                        }) :
                        setEditItemAPICall({
                            payload: [{
                                "itemType": 'AccountBasedExpenseLineDetail',
                                "unitPrice": Number(newStr1 ? newStr1 === 0 ? newStr : newStr1 : newStr),
                                "amount": Number(newStr ? newStr === 0 ? newStr1 : newStr : newStr1),
                                "unitOfMeasure": results?.unit ? results?.unit : null,
                                "quantity": Number(Qty ? Qty : 1),
                                "discount": 0,
                                "description": results?.item,
                                "InvoiceId": invoiceId ? invoiceId : documentsId,
                                "classId": results?.classId ? results?.classId : null,
                                "customerId": found?.customerId,
                                "categoryId": results?.categoryId ? results?.categoryId : null,
                                'askClient': results.askClients,
                                "ItemId": results.id,
                            }]
                        })
                    // setDescription(found.description);
                    // setUnitPrice(found.unitPrice)
                }
            }
        };

        function getPriceProduct(params) {
            const finalPrice = params?.row?.itemType === "AccountBasedExpenseLineDetail" ? params?.row?.price : params?.row?.price;
            return `$${finalPrice}`;
        }
        function getTotalProduct(params) {
            return `$${params?.row?.total}`;
        }
        function getQuantity(params) {
            const finalQty = params?.row?.itemType === "AccountBasedExpenseLineDetail" ? params?.row?.qty : params?.row?.qty;
            return finalQty;
        }

        return (
            <Autocomplete
                id="combo-box-demo"
                fullWidth
                open={true}
                editable={true}
                classes={{
                    option: styles.option
                }}
                value={{ name: value }}
                options={customerList}
                getOptionLabel={(option) => option.name}
                disableClearable
                ListboxProps={{
                    sx: {
                        fontSize: `0.8125rem`,
                        '@media (max-width: 1440px)': {

                            fontSize: `0.775rem`
                        },
                    },
                }}
                sx={{
                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                        fontSize: `0.8125rem`,
                        '@media (max-width: 1440px)': {

                            fontSize: `0.775rem`
                        },
                    },
                }}
                onChange={handleCustomerChanges}
                renderInput={(params) => <TextField {...params} />}
            />
        );
    }

    const columns = [
        {
            headerName: "PRODUCTS/SERVICE",
            field: "product",
            minWidth: 110,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            renderEditCell: SelectProductEditInputCell,
            editable: editbleData && notEdit,
            flex: 0.2,
            renderCell: (params) => (
                params?.row?.proderror === true ?
                    <Tooltip title={params?.row?.proderrorMsg} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", color: '#7b809a', padding: 2
                                }, cursor: "pointer", color: '#7b809a', padding: 2
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>
                    :
                    <Tooltip title={params?.formattedValue} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                                },
                                // "& .MuiDataGrid-column:hover": {
                                //     backgroundColor: "skyblue",
                                //     color: "red"
                                // },
                                cursor: "pointer", color: '#7b809a'
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>),
        },
        {
            field: 'item',
            headerName: 'DESCRIPTION',
            minWidth: 30,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem",
                            },
                        }}
                    > {textEllipsis(params?.formattedValue, 15)}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'class',
            headerName: 'CLASS',
            renderEditCell: SelectClassEditInputCell,
            editable: editbleData && notEdit,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            minWidth: 65,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, cursor: "pointer", color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 15)}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'customer',
            headerName: 'CUSTOMER',
            renderEditCell: SelectCustomerEditInputCell,
            editable: editbleData && notEdit,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            minWidth: 60,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, cursor: "pointer", color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 11)}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "QTY",
            field: "qty",
            minWidth: 20,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.1,
            valueGetter: getQuantity,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "UNIT",
            field: "unit",
            minWidth: 40,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.1,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "PRICE",
            field: "price",
            minWidth: 55,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getPriceProduct,
            flex: 0.1,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "TOTAL",
            field: "total",
            headerAlign: 'center',
            minWidth: 55,
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getTotalProduct,
            flex: 0.1,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'ACTION',
            minWidth: 75,
            flex: 0.1,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const result = itemListResponse?.data?.data?.records.map((prop, key) => {
                    {
                        return {
                            id: prop._id,
                            item: prop.description,
                            qty: prop.quantity,
                            unit: prop.unitOfMeasure,
                            price: prop.unitPrice,
                            total: prop.total,
                            sku: prop.sku,
                            unitPrice: prop.unitPrice,
                            results: prop.results,
                            askClients: prop.askClient,
                            clientApproved: prop?.clientApproved,
                            category: prop.category ? prop.category.name : '',
                            categoryId: prop.category ? prop.category._id : '',
                            class: prop.class ? prop.class.name : '',
                            product: prop.product ? prop.product.name : '',
                            productId: prop.product ? prop.product._id : '',
                            classId: prop.class ? prop.class._id : '',
                            customer: prop.customer ? prop.customer.name : '',
                            customerId: prop.customer ? prop.customer._id : '',
                        }
                    }
                });
                const found = result.find(obj => {
                    return obj.id === id;
                });
                return [
                    <>
                        {found?.askClients === true ?
                            <Button sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: `0.7rem`
                                },
                            }} onClick={() => AprroveFun(id)}> Approve</Button>
                            :
                            found?.clientApproved === true ?
                                <Icon color="success" fontSize="medium" >check</Icon> : ''
                        }
                        {
                            isSynced === true
                                ?
                                <>
                                    <GridActionsCellItem
                                        icon={<EditIcon />}
                                        label="Edit"
                                        className="textPrimary"
                                        onClick={(value) => {
                                            setOpenQboSync(true);
                                        }}
                                        color="inherit"
                                    />
                                </>
                                :
                                <>
                                    <GridActionsCellItem
                                        icon={<EditIcon fontSize="small" />}
                                        label="Edit"
                                        className="textPrimary"
                                        onClick={() => {
                                            // setAccountantId(prop._id);
                                            setEditItemId(id);
                                            handleEditItem();
                                        }}
                                        color="inherit"
                                    />
                                </>
                        }
                    </>

                ]
            }
        },
    ];

    const columnsWithoutAction = [
        {
            headerName: "PRODUCTS/SERVICE",
            field: "product",
            minWidth: 110,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            renderEditCell: SelectProductEditInputCell,
            editable: editbleData && notEdit,
            flex: 0.2,
            renderCell: (params) => (
                params?.row?.proderror === true ?
                    <Tooltip title={params?.row?.proderrorMsg} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", color: '#7b809a', padding: 2
                                }, cursor: "pointer", color: '#7b809a', padding: 2
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>
                    :
                    <Tooltip title={params?.formattedValue} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                                },
                                // "& .MuiDataGrid-column:hover": {
                                //     backgroundColor: "skyblue",
                                //     color: "red"
                                // },
                                cursor: "pointer", color: '#7b809a'
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>),
        },
        {
            field: 'item',
            headerName: 'DESCRIPTION',
            minWidth: 30,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem",
                            },
                        }}
                    > {textEllipsis(params?.formattedValue, 15)}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'class',
            headerName: 'CLASS',
            renderEditCell: SelectClassEditInputCell,
            editable: editbleData && notEdit,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            minWidth: 65,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, cursor: "pointer", color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 15)}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'customer',
            headerName: 'CUSTOMER',
            renderEditCell: SelectCustomerEditInputCell,
            editable: editbleData && notEdit,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            minWidth: 60,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, cursor: "pointer", color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 11)}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "QTY",
            field: "qty",
            minWidth: 20,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.1,
            valueGetter: getQuantity,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "UNIT",
            field: "unit",
            minWidth: 40,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.1,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "PRICE",
            field: "price",
            minWidth: 55,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getPriceProduct,
            flex: 0.1,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "TOTAL",
            field: "total",
            headerAlign: 'center',
            minWidth: 55,
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getTotalProduct,
            flex: 0.1,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
    ];

    const columnsWitoutClass = [
        {
            headerName: "PRODUCTS/SERVICE",
            field: "product",
            minWidth: 60,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            renderEditCell: SelectProductEditInputCell,
            editable: editbleData && notEdit,
            flex: 0.2,
            renderCell: (params) => (
                params?.row?.proderror === true ?
                    <Tooltip title={params?.row?.proderrorMsg} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", color: '#7b809a', padding: 2
                                }, cursor: "pointer", color: '#7b809a', padding: 2
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>
                    :
                    <Tooltip title={params?.formattedValue} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                                }, cursor: "pointer", color: '#7b809a'
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>),
        },
        {
            field: 'item',
            headerName: 'DESCRIPTION',
            minWidth: 30,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 15)}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'customer',
            headerName: 'CUSTOMER',
            renderEditCell: SelectCustomerEditInputCell,
            editable: editbleData && notEdit,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            minWidth: 60,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, cursor: "pointer", color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 11)}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "QTY",
            field: "qty",
            minWidth: 25,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.1,
            valueGetter: getQuantity,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "UNIT",
            field: "unit",
            minWidth: 40,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.1,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "PRICE",
            field: "price",
            minWidth: 40,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getPriceProduct,
            flex: 0.1,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "TOTAL",
            field: "total",
            headerAlign: 'center',
            minWidth: 40,
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getTotalProduct,
            flex: 0.1,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'ACTION',
            minWidth: 55,
            flex: 0.1,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const result = itemListResponse?.data?.data?.records.map((prop, key) => {
                    {
                        return {
                            id: prop._id,
                            item: prop.description,
                            qty: prop.quantity,
                            unit: prop.unitOfMeasure,
                            price: prop.unitPrice,
                            total: prop.total,
                            sku: prop.sku,
                            unitPrice: prop.unitPrice,
                            results: prop.results,
                            askClients: prop.askClient,
                            clientApproved: prop?.clientApproved,
                            category: prop.category ? prop.category.name : '',
                            categoryId: prop.category ? prop.category._id : '',
                            class: prop.class ? prop.class.name : '',
                            product: prop.product ? prop.product.name : '',
                            productId: prop.product ? prop.product._id : '',
                            classId: prop.class ? prop.class._id : '',
                            customer: prop.customer ? prop.customer.name : '',
                            customerId: prop.customer ? prop.customer._id : '',
                        }
                    }
                });
                const found = result.find(obj => {
                    return obj.id === id;
                });
                return [
                    <>
                        {found?.askClients === true ?
                            <Button sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: `0.7rem`
                                },
                            }} onClick={() => AprroveFun(id)}> Approve</Button>
                            :
                            found?.clientApproved === true ?
                                <Icon color="success" fontSize="medium" >check</Icon> : ''
                        }
                        {
                            isSynced === true
                                ?
                                <>
                                    <GridActionsCellItem
                                        icon={<EditIcon />}
                                        label="Edit"
                                        className="textPrimary"
                                        onClick={(value) => {
                                            setOpenQboSync(true);
                                        }}
                                        color="inherit"
                                    />
                                </>
                                :
                                <>
                                    <GridActionsCellItem
                                        icon={<EditIcon fontSize="small" />}
                                        label="Edit"
                                        className="textPrimary"
                                        onClick={() => {
                                            // setAccountantId(prop._id);
                                            setEditItemId(id);
                                            handleEditItem();
                                        }}
                                        color="inherit"
                                    />
                                </>
                        }
                    </>

                ]
            }
        },
    ];

    const columnsWitoutClassAction = [
        {
            headerName: "PRODUCTS/SERVICE",
            field: "product",
            minWidth: 60,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            renderEditCell: SelectProductEditInputCell,
            editable: editbleData && notEdit,
            flex: 0.2,
            renderCell: (params) => (
                params?.row?.proderror === true ?
                    <Tooltip title={params?.row?.proderrorMsg} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", color: '#7b809a', padding: 2
                                }, cursor: "pointer", color: '#7b809a', padding: 2
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>
                    :
                    <Tooltip title={params?.formattedValue} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                                }, cursor: "pointer", color: '#7b809a'
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>),
        },
        {
            field: 'item',
            headerName: 'DESCRIPTION',
            minWidth: 30,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 15)}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'customer',
            headerName: 'CUSTOMER',
            renderEditCell: SelectCustomerEditInputCell,
            editable: editbleData && notEdit,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            minWidth: 60,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, cursor: "pointer", color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 11)}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "QTY",
            field: "qty",
            minWidth: 25,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.1,
            valueGetter: getQuantity,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "UNIT",
            field: "unit",
            minWidth: 40,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.1,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "PRICE",
            field: "price",
            minWidth: 40,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getPriceProduct,
            flex: 0.1,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "TOTAL",
            field: "total",
            headerAlign: 'center',
            minWidth: 40,
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getTotalProduct,
            flex: 0.1,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
    ];

    const cetagorycolumns = [
        {
            field: 'category',
            headerName: 'CATEGORY',
            renderEditCell: SelectCategoryEditInputCell,
            editable: editbleData && notEdit,
            sortable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            minWidth: 120,
            flex: 0.4,
            renderCell: (params) => (
                params?.row?.proderror === true ?
                    <Tooltip title={params?.row?.proderrorMsg} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", color: '#7b809a', padding: 2
                                }, cursor: "pointer", color: '#7b809a', padding: 2
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>
                    :
                    <Tooltip title={params?.formattedValue} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", color: '#7b809a'
                                }, cursor: "pointer", color: '#7b809a'
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>),
        },
        {
            field: 'item',
            headerName: 'DESCRIPTION',
            minWidth: 120,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false,
            flex: 0.5,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 15)}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'class',
            headerName: 'CLASS',
            renderEditCell: SelectClassEditInputCell,
            editable: editbleData && notEdit,
            minWidth: 70,
            disableColumnMenu: true,
            headerAlign: 'center',
            sortable: false,
            flex: 0.3,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, cursor: "pointer", color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 15)}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'customer',
            headerName: 'CUSTOMER',
            renderEditCell: SelectCustomerEditInputCell,
            editable: editbleData && notEdit,
            minWidth: 100,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false,
            flex: 0.4,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, cursor: "pointer", color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 11)}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "QTY",
            field: "qty",
            minWidth: 20,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.2,
            valueGetter: getQuantity,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "UNIT",
            field: "unit",
            minWidth: 40,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.3,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "PRICE",
            field: "price",
            minWidth: 55,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getPriceProduct,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "TOTAL",
            field: "total",
            headerAlign: 'center',
            minWidth: 55,
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getTotalProduct,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'ACTIONS',
            maxWidth: 71,
            flex: 0.2,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const result = itemListResponse?.data?.data?.records.map((prop, key) => {
                    {
                        return {
                            id: prop._id,
                            item: prop.description,
                            qty: prop.quantity,
                            unit: prop.unitOfMeasure,
                            price: prop.unitPrice,
                            total: prop.total,
                            sku: prop.sku,
                            unitPrice: prop.unitPrice,
                            results: prop.results,
                            askClients: prop.askClient,
                            clientApproved: prop?.clientApproved,
                            category: prop.category ? prop.category.name : '',
                            categoryId: prop.category ? prop.category._id : '',
                            class: prop.class ? prop.class.name : '',
                            product: prop.product ? prop.product.name : '',
                            productId: prop.product ? prop.product._id : '',
                            classId: prop.class ? prop.class._id : '',
                            customer: prop.customer ? prop.customer.name : '',
                            customerId: prop.customer ? prop.customer._id : '',
                        }
                    }
                });
                const found = result.find(obj => {
                    return obj.id === id;
                });
                return [
                    <>
                        {found?.askClients === true ?
                            <Button sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: `0.7rem`
                                },
                            }} onClick={() => AprroveFun(id)}> Approve</Button>
                            :
                            found?.clientApproved === true ?
                                <Icon color="success" fontSize="medium" >check</Icon> : ''
                        }
                        {
                            isSynced === true
                                ?
                                <>
                                    <GridActionsCellItem
                                        icon={<EditIcon />}
                                        label="Edit"
                                        className="textPrimary"
                                        onClick={(value) => {
                                            setOpenQboSync(true);
                                        }}
                                        color="inherit"
                                    />
                                </>
                                :
                                <>
                                    <GridActionsCellItem
                                        icon={<EditIcon fontSize="small" />}
                                        label="Edit"
                                        className="textPrimary"
                                        onClick={() => {
                                            // setAccountantId(prop._id);
                                            setEditItemId(id);
                                            handleEditItem();
                                        }}
                                        color="inherit"
                                    />
                                </>
                        }
                    </>

                ]
            }
        },
    ];

    const cetagorycolumnsWithotAction = [
        {
            field: 'category',
            headerName: 'CATEGORY',
            renderEditCell: SelectCategoryEditInputCell,
            editable: editbleData && notEdit,
            sortable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            minWidth: 120,
            flex: 0.4,
            renderCell: (params) => (
                params?.row?.proderror === true ?
                    <Tooltip title={params?.row?.proderrorMsg} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", color: '#7b809a', padding: 2
                                }, cursor: "pointer", color: '#7b809a', padding: 2
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>
                    :
                    <Tooltip title={params?.formattedValue} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", color: '#7b809a'
                                }, cursor: "pointer", color: '#7b809a'
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>),
        },
        {
            field: 'item',
            headerName: 'DESCRIPTION',
            minWidth: 120,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false,
            flex: 0.5,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 15)}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'class',
            headerName: 'CLASS',
            renderEditCell: SelectClassEditInputCell,
            editable: editbleData && notEdit,
            minWidth: 70,
            disableColumnMenu: true,
            headerAlign: 'center',
            sortable: false,
            flex: 0.3,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, cursor: "pointer", color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 15)}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'customer',
            headerName: 'CUSTOMER',
            renderEditCell: SelectCustomerEditInputCell,
            editable: editbleData && notEdit,
            minWidth: 100,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false,
            flex: 0.4,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, cursor: "pointer", color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 11)}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "QTY",
            field: "qty",
            minWidth: 20,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.2,
            valueGetter: getQuantity,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "UNIT",
            field: "unit",
            minWidth: 40,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.3,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "PRICE",
            field: "price",
            minWidth: 55,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getPriceProduct,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "TOTAL",
            field: "total",
            headerAlign: 'center',
            minWidth: 55,
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getTotalProduct,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
    ];

    const cetagorycolumnsWithoutClassAction = [
        {
            field: 'category',
            headerName: 'CATEGORY',
            renderEditCell: SelectCategoryEditInputCell,
            editable: editbleData && notEdit,
            sortable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            minWidth: 120,
            flex: 0.4,
            renderCell: (params) => (
                params?.row?.proderror === true ?
                    <Tooltip title={params?.row?.proderrorMsg} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", color: '#7b809a', padding: 2
                                }, cursor: "pointer", color: '#7b809a', padding: 2
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>
                    :
                    <Tooltip title={params?.formattedValue} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", color: '#7b809a'
                                }, cursor: "pointer", color: '#7b809a'
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>),
        },
        {
            field: 'item',
            headerName: 'DESCRIPTION',
            minWidth: 120,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false,
            flex: 0.5,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 15)}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'customer',
            headerName: 'CUSTOMER',
            renderEditCell: SelectCustomerEditInputCell,
            editable: editbleData && notEdit,
            minWidth: 100,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false,
            flex: 0.4,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, cursor: "pointer", color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 11)}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "QTY",
            field: "qty",
            minWidth: 20,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.2,
            valueGetter: getQuantity,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "UNIT",
            field: "unit",
            minWidth: 40,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.3,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "PRICE",
            field: "price",
            minWidth: 55,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getPriceProduct,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "TOTAL",
            field: "total",
            headerAlign: 'center',
            minWidth: 55,
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getTotalProduct,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
    ];

    const cetagorycolumnsWithoutClass = [
        {
            field: 'category',
            headerName: 'CATEGORY',
            renderEditCell: SelectCategoryEditInputCell,
            editable: editbleData && notEdit,
            sortable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            minWidth: 120,
            flex: 0.4,
            renderCell: (params) => (
                params?.row?.proderror === true ?
                    <Tooltip title={params?.row?.proderrorMsg} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", color: '#7b809a', padding: 2
                                }, cursor: "pointer", color: '#7b809a', padding: 2
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>
                    :
                    <Tooltip title={params?.formattedValue} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", color: '#7b809a'
                                }, cursor: "pointer", color: '#7b809a'
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>
            ),
        },
        {
            field: 'item',
            headerName: 'DESCRIPTION',
            minWidth: 120,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false,
            flex: 0.5,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 15)}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'customer',
            headerName: 'CUSTOMER',
            renderEditCell: SelectCustomerEditInputCell,
            editable: editbleData && notEdit,
            minWidth: 100,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false,
            flex: 0.4,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, cursor: "pointer", color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 11)}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "QTY",
            field: "qty",
            minWidth: 20,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.2,
            valueGetter: getQuantity,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "UNIT",
            field: "unit",
            minWidth: 40,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.3,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "PRICE",
            field: "price",
            minWidth: 55,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getPriceProduct,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "TOTAL",
            field: "total",
            headerAlign: 'center',
            minWidth: 55,
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getTotalProduct,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'ACTIONS',
            minWidth: 60,
            flex: 0.3,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const result = itemListResponse?.data?.data?.records.map((prop, key) => {
                    {
                        return {
                            id: prop._id,
                            item: prop.description,
                            qty: prop.quantity,
                            unit: prop.unitOfMeasure,
                            price: prop.unitPrice,
                            total: prop.total,
                            sku: prop.sku,
                            unitPrice: prop.unitPrice,
                            results: prop.results,
                            askClients: prop.askClient,
                            clientApproved: prop?.clientApproved,
                            category: prop.category ? prop.category.name : '',
                            categoryId: prop.category ? prop.category._id : '',
                            class: prop.class ? prop.class.name : '',
                            product: prop.product ? prop.product.name : '',
                            productId: prop.product ? prop.product._id : '',
                            classId: prop.class ? prop.class._id : '',
                            customer: prop.customer ? prop.customer.name : '',
                            customerId: prop.customer ? prop.customer._id : '',
                        }
                    }
                });
                const found = result.find(obj => {
                    return obj.id === id;
                });
                return [
                    <>
                        {found?.askClients === true ?
                            <Button sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: `0.7rem`
                                },
                            }} onClick={() => AprroveFun(id)}> Approve</Button>
                            :
                            found?.clientApproved === true ?
                                <Icon color="success" fontSize="medium" >check</Icon> : ''
                        }
                        {
                            isSynced === true
                                ?
                                <>
                                    <GridActionsCellItem
                                        icon={<EditIcon />}
                                        label="Edit"
                                        className="textPrimary"
                                        onClick={(value) => {
                                            setOpenQboSync(true);
                                        }}
                                        color="inherit"
                                    />
                                </>
                                :
                                <>
                                    <GridActionsCellItem
                                        icon={<EditIcon fontSize="small" />}
                                        label="Edit"
                                        className="textPrimary"
                                        onClick={() => {
                                            // setAccountantId(prop._id);
                                            setEditItemId(id);
                                            handleEditItem();
                                        }}
                                        color="inherit"
                                    />
                                </>
                        }
                    </>

                ]
            }
        },
    ];

    const dataTableData = {
        columns: [
            {
                Header: "Category",
                accessor: "category",
                width: 90,
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Description",
                accessor: "item",
                width: 90,
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Class",
                accessor: "class",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Customer",
                accessor: "customer",
                width: 90,
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            // {
            //     Header: "Qty",
            //     accessor: "qty",
            //     Cell: ({ value }) => <DefaultCell value={value} />,
            // },
            {
                Header: "Price",
                accessor: "total",
                Cell: ({ value }) => <DefaultCell value={currencySymbol + value} />,
            },
            {
                Header: "Actions",
                accessor: "action",
                Cell: ({ value }) => <DefaultCell value={value} />,
            }
        ],
        rows: itemList

    }
    const dataTableData1 = {

        columns: [
            {
                Header: "Products/Service",
                accessor: "product",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Description",
                accessor: "item",
                width: 90,
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Class",
                accessor: "class",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Customer",
                accessor: "customer",
                width: 90,
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Qty",
                accessor: "qty",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Unit",
                accessor: "unit",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Price",
                accessor: "price",
                Cell: ({ value }) => <DefaultCell value={currencySymbol + value} />,
            },
            {
                Header: "Total",
                accessor: "total",
                Cell: ({ value }) => <DefaultCell value={currencySymbol + value} />,
            },
            {
                Header: "Actions",
                accessor: "action",
                Cell: ({ value }) => <DefaultCell value={value} />,
            }
        ],

        rows: itemList


    }



    const InvoiceEditSchema2 = Yup.object().shape({
        name: Yup.string()
            .trim()
            .required('Comment is required.')
            .min(3, 'Comment should be more than 3 characters.'),
    });


    eventBus.$on('SendToAccountant', (data) => SendToAccountant(data));
    function SendToAccountant(data) {
        if (data === 'move') {
            setOpenQboSync(true)
        }
    }

    eventBus.$on('reloadDetailsTab', (data) => reloadDetailsTab(data));
    function reloadDetailsTab(data) {
        setShowDetailsForm(false);
        setOpenAddItem(false);
        setLoading(true);
        DetailsUserAPICall({
            payload: [{ "documentId": `${invoice_id}`, "businessId": `${businessId}` }]
        })
    }

    eventBus.$on('closeAddNewItemPopup', (data) => fetchNewInvoicesAdd(data));

    function fetchNewInvoicesAdd(data) {
        setOpenAddItem(false);
        setLoading(true);
        setOpenCategoryAddItem(false);
        GetItemListListAPICall({
            payload: [{ "documentId": `${invoice_id}` }]
        })
        DetailsUserAPICall({
            payload: [{ "documentId": `${invoice_id}`, "businessId": `${businessId}` }]
        })
    }

    eventBus.$on('closeEditItemPopup', (data) => fetchNewInvoices(data));

    function fetchNewInvoices(data) {
        setOpenEditItem(false);
        setLoading(true);
        GetItemListListAPICall({
            payload: [{ "documentId": `${invoice_id}` }]
        })
        DetailsUserAPICall({
            payload: [{ "documentId": `${invoice_id}`, "businessId": `${businessId}` }]
        })
    }


    // commented this code as discounted value is not there and total amount are provided in api after discount
    useEffect(() => {
        let taxNow = (typeof (taxStore) == 'string') ? taxStore.replaceAll(',', '') : taxStore;
        let subTotalNow = (typeof (subTotStore) == 'string') ? subTotStore.replaceAll(',', '') : subTotStore;
        let discountNow = (typeof (discountStore) == 'string') ? discountStore.replaceAll(',', '') : discountStore;
        const MyTotal = (Number.parseFloat(subTotalNow, 10) - Number.parseFloat(discountNow, 10));
        if (onChanges === true) {
            const timeOutId = setTimeout(async () =>
                setTotalStore(MyTotal + Number.parseFloat(taxNow, 10)),
                setTotal(MyTotal + Number.parseFloat(taxNow, 10)),
                500);
            return () => clearTimeout(timeOutId);
        }

    }, [taxStore, subTotStore, discountStore]);
    // }, [TotalStore, taxStore, subTotStore, tax, subtotal, total]);

    const getFormattedNumber = (n) => {
        let unFormatted = (typeof (n) == 'string') ? n.replaceAll(',', '') : n;
        const numberFormatter = Intl.NumberFormat('en-US',

            {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        const formatted = numberFormatter.format(unFormatted);
        return formatted;
    }

    const handleChangeBusiness = (event, newValue) => {
        if (newValue) {
            setVendorerrormsg('');
            setVendorerror(false);
        } else {
            setVendorerrormsg('Vendor name required');
            setVendorerror(true);
        }
        const found = vendorList.find(obj => {
            return obj.name === newValue;
        });
        if (found) {
            setShowselectedVendorId(found._id);
        }
    };

    useEffect(() => {
        setStyles(value == 1 ? { display: 'grid', overflowY: 'scroll', overflowX: 'scroll', height: '80%' } : { height: '80%', overflow: 'auto' })
    }, [value])

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    useEffect(() => {
        if (createObjectURL) {
            setExtention(createObjectURL.split(".").pop())
        }
    }, [createObjectURL])
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { CurrentPageLabel } = pageNavigationPluginInstance;
    const QboSyncMsg = () => {
        return <MDBox sx={{ ml: 8, mb: -1 }}>
            {notApproved === true ? <MDTypography >
                {notApprovedCount} items are awaiting your approval. Are you certain you want to proceed with sending them to the accountant?
            </MDTypography> :
                <>
                    <MDTypography >
                        Are you sure want to proceed with sending them
                    </MDTypography>
                    <MDTypography display='flex' justifyContent='center' mr={6} >
                        to the accountant?
                    </MDTypography>
                </>
            }
        </MDBox>
    }

    const moveOptionName = {
        Classify: 'Classify',
        AskClient: 'To Review',
        ToReview: 'To Review',
        PendingApproval: 'Pending Approval',
        Approved: 'Approved',
        Excluded: 'Excluded',
        Synced: 'Quickbook',
    }

    useEffect(() => {
        Object.keys(moveOptionName).map(function (k) {
            k === documentStatus ? setFinalSelect(moveOptionName[k]) : ''
        })
    }, [documentStatus])

    const handleClickImgDownload = () => {
        window.open(`${createObjectURL}`, '_blank');
    }

    async function createFile() {
        if (imageUrl && counts === 0) {
            setCounts(counts + 1)
            let response = await fetch(imageUrl);
            let data = await response.blob();
            let metadata = {
                type: 'image/jpeg'
            };
            let file = new File([data], "test.jpg", metadata);
            const imageDataUrl = URL.createObjectURL(file);
            setImageUrl1(imageDataUrl)
        }
    }
    useEffect(() => {
        createFile()
    }, [imageUrl])

    return (
        <>
            {
                !showDetails ?
                    <>
                        <MDBox mt={'-10%'} display="flex" justifyContent="center" >
                            {<LoaderDailog />}
                        </MDBox>
                    </> :
                    <>
                        <MDBox mb={3} display={'flex'} >
                            <Typography variant="h6" className={classes.title}>
                                <MDBox display={'flex'}>
                                    <MDTypography fontSize={'1rem'} >Document in</MDTypography>
                                    <MDTypography sx={{ ml: 0.5 }} fontSize={'1rem'} fontWeight={'bold'} >{finalSelect ? finalSelect : documentStatus}</MDTypography>
                                </MDBox>
                            </Typography>
                            <MDBox>
                                <Icon sx={{ cursor: "pointer" }} onClick={reloadApiAfterClose}>close</Icon>
                            </MDBox>
                        </MDBox>
                        <MDBox mb={3} style={{ height: '95%', overflow: 'auto', display: 'block', marginBottom: 0 }}>
                            <Grid container style={{ height: '100%', overflow: 'auto' }}>
                                {createObjectURL ?
                                    <Grid item xs={12} sm={4} lg={4.5} ml={-2} style={{ height: '100%', overflow: 'auto' }}>
                                        {
                                            extention === 'pdf' ?
                                                <div
                                                    className="rpv-core__viewer"
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        height: '97%',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            alignItems: 'center',
                                                            backgroundColor: '#eeeeee',
                                                            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                                            display: 'flex',
                                                            padding: '0.25rem',
                                                        }}
                                                    >
                                                        <Toolbar>
                                                            {(props) => {
                                                                const {
                                                                    CurrentPageInput,
                                                                    NumberOfPages,
                                                                    ZoomIn,
                                                                    ZoomOut,
                                                                    EnterFullScreen,
                                                                    Download,
                                                                } = props;
                                                                return (
                                                                    <>
                                                                        <div style={{ padding: '0px 2px', width: '4rem', marginLeft: '10px' }}>
                                                                            <CurrentPageInput />
                                                                        </div>
                                                                        <div style={{ padding: '0px 2px' }}>
                                                                            of <NumberOfPages />
                                                                        </div>
                                                                        <div style={{ padding: '0px 2px', marginLeft: '35%' }}>
                                                                            <ZoomOut />
                                                                        </div>
                                                                        <div style={{ padding: '0px 2px', marginLeft: '10px' }}>
                                                                            <ZoomIn />
                                                                        </div>
                                                                        <div style={{ padding: '0px 2px', display: 'flex', marginLeft: 'auto' }}>
                                                                            <Download />
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                alignItems: 'center',
                                                                                display: 'flex',
                                                                                justifyContent: "end",
                                                                                paddingBottom: '2px',
                                                                                marginLeft: '8px'
                                                                            }}
                                                                        >
                                                                            <img style={{ marginRight: '20px', cursor: 'pointer' }} width="19" height="19" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAjUlEQVR4nO2VQQqAIBBF3yUmOlJXqePmor0dxAgMhGoc01b1YHYz/4kMCj+FDMAKBKUOLD0nfGYw1AqyDRcIML8l6AAXZ1xrgSQnX4C+pUAuwmklkJvwJgJRwk1oAqkN1wRiDH90RVJw8keCKdnzfe9L500NoyG8SmDlAwJveK5DpvYM9cOpkfiY8YOZDdq3j/znzI3XAAAAAElFTkSuQmCC" alt="fullscreen" onClick={() => window.open(`${createObjectURL}`, '_blank')
                                                                            } title="Full Screen" /></div>
                                                                    </>
                                                                );
                                                            }}
                                                        </Toolbar>
                                                    </div>
                                                    <div
                                                        style={{
                                                            flex: 1,
                                                            overflow: 'hidden',
                                                        }}
                                                    >
                                                        {extention === 'pdf' && <Viewer fileUrl={createObjectURL} plugins={[toolbarPluginInstance]} />
                                                        }

                                                    </div>
                                                </div>
                                                :
                                                <div
                                                    className="rpv-core__viewer"
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        height: '97%',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            alignItems: 'center',
                                                            backgroundColor: '#eeeeee',
                                                            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                                            display: 'flex',
                                                            justifyContent: "end",
                                                            padding: '0.25rem',
                                                        }}
                                                    >
                                                        <a href={imageUrl1} download={"downloaded-image.jpeg"} >
                                                            <img style={{ marginRight: '20px', cursor: 'pointer', marginBottom: '-6px' }} width="18" height="18" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAfklEQVR4nO2SUQqAIBAF5xJK3bcumB9eJb8MwQUTC8ItiBpYUFbeyCp8hRkIQMyV1pOmIBThUqumIOY62v8C/hHtMMDS+JaxONPqeWCgUyJ0hbckHhhPepfDzyRGK1ywgMuBrlqnngqmehOVmx9J/B3hgtUcy/uIyvW8gFeyAeoqduPSsPEqAAAAAElFTkSuQmCC" title="Download" />
                                                        </a>
                                                        <img style={{ marginRight: '20px', cursor: 'pointer' }} width="19" height="19" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAjUlEQVR4nO2VQQqAIBBF3yUmOlJXqePmor0dxAgMhGoc01b1YHYz/4kMCj+FDMAKBKUOLD0nfGYw1AqyDRcIML8l6AAXZ1xrgSQnX4C+pUAuwmklkJvwJgJRwk1oAqkN1wRiDH90RVJw8keCKdnzfe9L500NoyG8SmDlAwJveK5DpvYM9cOpkfiY8YOZDdq3j/znzI3XAAAAAElFTkSuQmCC" alt="fullscreen" onClick={handleClickImgDownload} title="Full Screen" />
                                                    </div>
                                                    <div>
                                                        {/(jpg|jpeg|png)$/.test(extention) && <img src={createObjectURL}
                                                            width={`100%`}
                                                            height={'100%'}
                                                        />}
                                                    </div>
                                                    <div
                                                        style={{
                                                            alignItems: 'center',
                                                            backgroundColor: 'transparent',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <CurrentPageLabel>
                                                            {(props) => (
                                                                <span>{`${props.currentPage + 1} of ${props.numberOfPages}`}</span>
                                                            )}
                                                        </CurrentPageLabel>
                                                    </div>
                                                </div>
                                        }


                                    </Grid>
                                    :
                                    <Grid item xs={12} sm={4} lg={4.5} ml={-2} style={{ height: '100%', overflow: 'auto' }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                height: '100%',
                                                border: '0.5px solid gray'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    flex: 1,
                                                    overflow: 'hidden',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    marginTop: '50%'
                                                }}
                                            >
                                                <h2>{documentSource === 'QBO' ?
                                                    'No image available from Quickbooks' : 'No image available from Paynthr'}</h2>
                                            </div>
                                        </div>
                                    </Grid>
                                }

                                <Grid item xs={12} sm={6} lg={7.59} style={{
                                    height: '100%', overflow: 'auto'
                                }}>
                                    <Card style={{ height: '100%', overflow: 'auto', }}>
                                        <Tabs value={value} onChange={handleChange} aria-label="tabs">
                                            <Tab label={issues.documents ? (<MDBadge
                                                overlap="circular"
                                                badgeContent={issues.documents.count}
                                                sx={{
                                                    "& .MuiBadge-badge": {
                                                        color: '#ef5350',
                                                        background: 'transparent'
                                                    },
                                                }}
                                            >Details &nbsp;  &nbsp; &nbsp;</MDBadge>) : 'Details'} {...a11yProps(0)} />
                                            <Tab label={issues.items ? (<MDBadge overlap="circular" badgeContent={issues.items.count} sx={{
                                                "& .MuiBadge-badge": {
                                                    color: '#ef5350',
                                                    background: 'transparent '
                                                },
                                            }}>Items &nbsp;  &nbsp; &nbsp;</MDBadge>) : 'Items'} {...a11yProps(1)} />
                                            {/* <Tab label="Audit Log" {...a11yProps(2)} /> */}
                                            <Tab label="Comments" {...a11yProps(2)} />
                                            <div>
                                                {canAccess(actions = 'update', resource = 'invoices') &&
                                                    <MDButton
                                                        id="fade-button"
                                                        aria-controls={open ? 'fade-menu' : undefined}
                                                        aria-haspopup="true"
                                                        aria-expanded={open ? 'true' : undefined}
                                                        onClick={handleClick}
                                                        color="info"
                                                    >More <Icon sx={{ ml: 0.8 }}>
                                                            expand_more
                                                        </Icon>
                                                    </MDButton>
                                                }
                                                <Menu
                                                    id="fade-menu"
                                                    MenuListProps={{
                                                        'aria-labelledby': 'fade-button',
                                                    }}
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    TransitionComponent={Fade}
                                                >
                                                    {/* <MenuItem onClick={handleEditVendor} >Edit Vendor</MenuItem> */}
                                                    {canAccess(actions = 'delete', resource = 'invoices') &&
                                                        documentStatus !== 'Synced' ?
                                                        <MenuItem style={{ color: 'red' }} onClick={handleDeleteInvoice}>Delete Invoice</MenuItem>
                                                        :
                                                        <MenuItem style={{ color: 'red' }} onClick={() => setOpenQboSync(true)}>Delete Invoice</MenuItem>
                                                    }
                                                </Menu>
                                            </div>
                                        </Tabs>
                                        {/* <Divider /> */}
                                        <CardHeader
                                            subheader={
                                                <Grid item sx={12} sm={12} md={12} style={{ display: 'flex', marginLeft: '70px', fontSize: '1rem' }}>
                                                    <Grid item sx={12} xs={4}>
                                                        <span>Subtotal</span><br />
                                                        <span>{currencySymbol}{actualSubtotal}</span>
                                                    </Grid>
                                                    <Grid item sx={12} xs={4}>
                                                        <span>Taxes & Fees</span><br />
                                                        <span>{currencySymbol}{(taxStore) ? getFormattedNumber(taxStore) : getFormattedNumber(tax)}</span>
                                                    </Grid>
                                                    <Grid item sx={12} xs={4}>
                                                        <span>Total</span><br />
                                                        <span>{currencySymbol}{actualTotal}</span>
                                                    </Grid>
                                                </Grid>}
                                        />
                                        <Divider />
                                        <CardContent style={styles} >
                                            <TabPanel value={value} index={0}>
                                                {showDetailsForm &&
                                                    <MDBox my={4} >
                                                        <Box >
                                                            <Grid item xs={12} sm={12} md={12}>
                                                                <Formik
                                                                    enableReinitialize
                                                                    initialValues={initialValues}
                                                                    validationSchema={documentStatus !== 'Synced' ? InvoiceEditSchema : ''}
                                                                    onSubmit={handleSubmit}
                                                                >
                                                                    {({ values,
                                                                        errors,
                                                                        touched,
                                                                        handleChange,
                                                                        handleBlur,
                                                                        handleSubmit,
                                                                        isSubmitting,
                                                                        setFieldValue }) => (
                                                                        <Form autoComplete="off">
                                                                            {/* <Card sx={{ height: "100%" }}> */}
                                                                            <Grid container spacing={3}>
                                                                                <Grid item xs={12} sm={6}>
                                                                                    <Autocomplete
                                                                                        sx={{
                                                                                            "& .MuiInputBase-input.Mui-disabled": {
                                                                                                WebkitTextFillColor: "black",
                                                                                            },
                                                                                        }}
                                                                                        forcePopupIcon={editable ? true : false}
                                                                                        options={vendorList}
                                                                                        getOptionLabel={(option) => option.name}
                                                                                        value={{ name: vendorname }}
                                                                                        onInputChange={(event, newInputValue) => {
                                                                                            setVendorname(newInputValue),
                                                                                                handleChangeBusiness(event, newInputValue);
                                                                                        }}
                                                                                        id="controllable-states-demo"
                                                                                        disableCloseOnSelect={false}
                                                                                        disabled={!editable}
                                                                                        renderInput={(params) => (
                                                                                            vendorerror || novendorIssue ?
                                                                                                <TextField {...params}
                                                                                                    label="Vendor Name *"
                                                                                                    variant="standard"
                                                                                                    error={vendorerror || novendorIssue}
                                                                                                    helperText={vendorerrormsg}
                                                                                                    InputProps={{
                                                                                                        endAdornment: (
                                                                                                            <Tooltip title={novendorIssueMsg} placement="top">
                                                                                                                <InputAdornment position="end">
                                                                                                                    <ErrorOutlineIcon sx={{ fontSize: '1rem !important', mr: '14px', color: 'red' }} />
                                                                                                                </InputAdornment>
                                                                                                            </Tooltip>
                                                                                                        ),
                                                                                                    }}
                                                                                                /> :
                                                                                                <TextField {...params}
                                                                                                    label="Vendor Name *"
                                                                                                    variant="standard"
                                                                                                    helperText={vendorerrormsg}
                                                                                                />
                                                                                        )}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={6}>
                                                                                    <FormControl fullWidth variant="standard" sx={{ mt: 1, mb: 1 }} >
                                                                                        <InputLabel style={{ fontSize: '0.8rem', lineHeight: 2 }} sx={{ mt: -3, }} >Document Type</InputLabel>
                                                                                        <Field
                                                                                            as="select"
                                                                                            name="documenttype"
                                                                                            variant="standard"
                                                                                            disabled={!editable}
                                                                                            style={{
                                                                                                borderBottom: `dotted 0.8px`,
                                                                                                marginTop: "23px",
                                                                                                borderTop: "0px",
                                                                                                borderLeft: "0px",
                                                                                                appearance: "none",
                                                                                                borderRight: "0px",
                                                                                                color: "black",
                                                                                                fontSize: '1rem',
                                                                                                width: '100%',
                                                                                            }}
                                                                                        >
                                                                                            <option value="">Select Type</option>
                                                                                            {Object.keys(DocumnetType).map((key) => (
                                                                                                <option value={key
                                                                                                }>{DocumnetType[key]}</option>
                                                                                            ))}
                                                                                        </Field>
                                                                                        <MDBox mt={0.75}>
                                                                                            <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                                                                                <ErrorMessage name="documenttype" />
                                                                                            </MDTypography>
                                                                                        </MDBox>
                                                                                    </FormControl>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container spacing={3}>
                                                                                <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                                                                                    <FormField
                                                                                        type="text"
                                                                                        label="Invoice Number"
                                                                                        name="invoicenumber"
                                                                                        disabled={!editable}
                                                                                        editable={!editable}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                                                                                    <FormField
                                                                                        type="text"
                                                                                        label="PO Number"
                                                                                        name="ponumber"
                                                                                        disabled={!editable}
                                                                                        editable={!editable}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container spacing={3}>
                                                                                {documentSource === 'QBO' ?
                                                                                    <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                                                                                        <FormFieldDate
                                                                                            type="date"
                                                                                            label="Transaction Date"
                                                                                            name="transactionDate"
                                                                                            disabled={!editable}
                                                                                            editable={!editable}
                                                                                        />
                                                                                    </Grid> : <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                                                                                        <FormFieldDate
                                                                                            type="date"
                                                                                            label="Invoice Date"
                                                                                            dateMismatch={invoiceDateMismatch}
                                                                                            noInvoiceDateMsg={'invoiceDateMismatch'}
                                                                                            name="invoicedate"
                                                                                            disabled={!editable}
                                                                                            editable={!editable}
                                                                                        />
                                                                                    </Grid>}

                                                                                <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                                                                                    <FormFieldDate
                                                                                        type="date"
                                                                                        label="Upload Date"
                                                                                        name="uploaddate"
                                                                                        disabled={!editable}
                                                                                        editable={!editable}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container spacing={3}>
                                                                                <Grid item xs={12} sm={2.4} sx={{ mt: 1 }}>
                                                                                    <CurrencyFieldText
                                                                                        label="Subtotal"
                                                                                        name="subtotal"
                                                                                        error={priceMismatch}
                                                                                        disabled={!editable}
                                                                                        value={subtotal}
                                                                                        ErrorToolTip={priceMismatchMsg}
                                                                                        priceMismatch={priceMismatch}
                                                                                        onValueChange={(event) => {
                                                                                            setSubTotal(event.value)
                                                                                            setSubTotStore(event.value)
                                                                                            SetonChanges(true);
                                                                                        }}
                                                                                    />
                                                                                    <MDBox mt={0.75}>
                                                                                        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                                                                            {subtotalErrorMsg}
                                                                                        </MDTypography>
                                                                                    </MDBox>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={2.4} sx={{ mt: 1 }}>
                                                                                    <CurrencyFieldText
                                                                                        label="Discount"
                                                                                        name="discount"
                                                                                        disabled={!editable}
                                                                                        value={discount}
                                                                                        onValueChange={(event) => {
                                                                                            setDiscount(event.value)
                                                                                            setDiscountStore(event.value)
                                                                                            SetonChanges(true)
                                                                                        }}

                                                                                    />
                                                                                    <MDBox mt={0.75}>
                                                                                        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                                                                            {taxErrorMsg}
                                                                                        </MDTypography>
                                                                                    </MDBox>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={2.4} sx={{ mt: 1 }}>
                                                                                    <CurrencyFieldText
                                                                                        label="Shipping Price"
                                                                                        name="shippingprice"
                                                                                        disabled={!editable}
                                                                                        value={shippingprice}
                                                                                    />
                                                                                    <MDBox mt={0.75}>
                                                                                        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                                                                            {taxErrorMsg}
                                                                                        </MDTypography>
                                                                                    </MDBox>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={2.4} sx={{ mt: 1 }}>
                                                                                    <CurrencyFieldText
                                                                                        label="Taxes & Fees"
                                                                                        name="tax"
                                                                                        disabled={!editable}
                                                                                        value={tax}
                                                                                        // onValueChange = { handleValueChange("tax", setFieldValue)}
                                                                                        onValueChange={(event) => {
                                                                                            setTax(event.value)
                                                                                            setTaxStore(event.value)
                                                                                            SetonChanges(true);
                                                                                        }}

                                                                                    />
                                                                                    <MDBox mt={0.75}>
                                                                                        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                                                                            {taxErrorMsg}
                                                                                        </MDTypography>
                                                                                    </MDBox>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={2.4} sx={{ mt: 1 }}>
                                                                                    <CurrencyFieldText
                                                                                        label="Total"
                                                                                        name="total"
                                                                                        // type='number'
                                                                                        disabled={!editable}
                                                                                        ErrorToolTip={totalMismatchMsg}
                                                                                        error={totalMismatch}
                                                                                        value={TotalStore}
                                                                                        priceMismatch={totalMismatch}
                                                                                        // onValueChange={handleValueChange("total", setFieldValue)}
                                                                                        onValueChange={(event) => {
                                                                                            setTotal(event.value)
                                                                                            setTotalStore(event.value)
                                                                                            SetonChanges(true);
                                                                                        }}

                                                                                    />
                                                                                    <MDBox mt={0.75}>
                                                                                        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                                                                            {totalErrorMsg}
                                                                                        </MDTypography>
                                                                                    </MDBox>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
                                                                                {selectTab == "AskClient" && checkFound === true &&
                                                                                    <MDBox mt={6}>
                                                                                        <MDButton
                                                                                            type="submit"
                                                                                            variant="gradient"
                                                                                            color="info"
                                                                                            onClick={() => setOpenQboSyncAskClient(true)}
                                                                                        >
                                                                                            Send to Accountant
                                                                                        </MDButton>
                                                                                    </MDBox>}
                                                                            </Grid>
                                                                        </Form>
                                                                    )}
                                                                </Formik>
                                                            </Grid>
                                                        </Box>
                                                        <MDBox>
                                                            {documentStatus !== 'Synced' ?
                                                                <MDSnackbar
                                                                    color="success"
                                                                    icon="done"
                                                                    // autoHideDuration={3000}
                                                                    title="Success"
                                                                    content="Document Moved Successfully."
                                                                    open={showSnackbar}
                                                                    close={() => setShowSnackbar(false)}
                                                                /> : ''}
                                                        </MDBox>
                                                    </MDBox>
                                                }
                                            </TabPanel>
                                            <TabPanel value={value} index={1}>
                                                <MDBox mt={-3} >
                                                    <MDBox display="flex" alignItems="center" ml={-1} justifyContent="end">
                                                        <Switch
                                                            checked={toggleShow}
                                                            onChange={handleToggle}
                                                            value="toggleShow"
                                                        />
                                                        <MDTypography
                                                            variant="button"
                                                            fontWeight="regular"
                                                            color="text"
                                                            onClick={handleToggle}
                                                            sx={{ cursor: "pointer", userSelect: "none", }}
                                                        >
                                                            &nbsp;&nbsp;Split by Products/Services
                                                        </MDTypography>
                                                        {finalSelect == 'To Review' &&
                                                            <>
                                                                {canAccess(actions = 'update', resource = 'documents') && isSynced === true ?
                                                                    <MDButton disabled={ocrStatus}
                                                                        variant='contained' color='info' sx={{ ml: 2 }}
                                                                        onClick={() => setOpenQboSync(true)}>
                                                                        Add New Item
                                                                    </MDButton>
                                                                    :
                                                                    <MDButton disabled={ocrStatus}
                                                                        variant='contained' color='info' sx={{ ml: 2 }}
                                                                        onClick={toggleShow ? handleAddItem : handleCategort}
                                                                    // onClick={handleAddItem}
                                                                    >
                                                                        Add New Item
                                                                    </MDButton>
                                                                }</>}
                                                    </MDBox>
                                                    <MDBox mt={1.5} mb={-5} ml={-6} mr={-6}>
                                                        {
                                                            !toggleShow ? dataTableData?.rows?.length === 0 ? <MDBox my={5} sx={{ mt: 1 }}>
                                                                <ThemeProvider theme={myTheme}>
                                                                    <DataGrid
                                                                        rows={itemList}
                                                                        columns={finalSelect == 'To Review' ? showClasses ? cetagorycolumns : cetagorycolumnsWithoutClass : showClasses ? cetagorycolumnsWithotAction : cetagorycolumnsWithoutClassAction}
                                                                        components={{
                                                                            NoRowsOverlay: () => (
                                                                                <Stack height="100%" alignItems="center" justifyContent="center">
                                                                                    No records found
                                                                                </Stack>
                                                                            ),
                                                                        }}
                                                                        sx={{
                                                                            '@media (max-width: 1440px)': {
                                                                                fontWeight: `0  !important`,
                                                                                fontSize: `0.775rem`
                                                                            },
                                                                            '.MuiDataGrid-columnSeparator': {
                                                                                display: 'none',
                                                                            },
                                                                            '.MuiDataGrid-columnHeaderDraggableContainer': {
                                                                                width: '112% !important'
                                                                            },
                                                                            border: 0,
                                                                            '.MuiDataGrid-columnHeaderTitle': {
                                                                                color: 'rgb(123, 128, 154)',
                                                                                fontWeight: 'bold !important',
                                                                            }
                                                                        }}
                                                                        pageSizeOptions={[5, 10, 25, 50, 100]}
                                                                        paginationModel={paginationModel}
                                                                        onPaginationModelChange={setPaginationModel} />
                                                                </ThemeProvider>
                                                            </MDBox> :
                                                                <ThemeProvider theme={myTheme}>
                                                                    <Box
                                                                        sx={{
                                                                            '& .caterror': {
                                                                                borderBottom: `0.5px solid red !important`
                                                                            },
                                                                        }}
                                                                    >
                                                                        <DataGrid
                                                                            rows={itemList}
                                                                            columns={finalSelect == 'To Review' ? showClasses ? cetagorycolumns : cetagorycolumnsWithoutClass : showClasses ? cetagorycolumnsWithotAction : cetagorycolumnsWithoutClassAction}
                                                                            getCellClassName={(params) => {
                                                                                return params?.row?.proderror == true && params?.field === 'category' ? 'caterror' : '';
                                                                            }}
                                                                            disableRowSelectionOnClick
                                                                            sx={{
                                                                                '@media (max-width: 1440px)': {
                                                                                    fontWeight: `0  !important`,
                                                                                    fontSize: `0.775rem`
                                                                                },
                                                                                '.MuiDataGrid-columnSeparator': {
                                                                                    display: 'none',
                                                                                },
                                                                                '.MuiDataGrid-columnHeaderDraggableContainer': {
                                                                                    width: '112% !important'
                                                                                },
                                                                                border: 0,
                                                                                '.MuiDataGrid-columnHeaderTitle': {
                                                                                    color: 'rgb(123, 128, 154)',
                                                                                    fontWeight: 'bold !important',
                                                                                    overflow: 'visible !important'
                                                                                }
                                                                            }}
                                                                            pageSizeOptions={[5, 10, 25, 50, 100]}
                                                                            cellModesModel={cellModesModel}
                                                                            onCellModesModelChange={handleCellModesModelChange}
                                                                            onCellClick={handleCellClick}
                                                                            paginationModel={paginationModel}
                                                                            onPaginationModelChange={setPaginationModel} />
                                                                    </Box>
                                                                </ThemeProvider>
                                                                : dataTableData1?.rows?.length === 0 ? <MDBox my={5} sx={{ mt: 1 }}>
                                                                    <ThemeProvider theme={myTheme}>
                                                                        < DataGrid rows={itemList} columns={finalSelect == 'To Review' ? showClasses ? columns : columnsWitoutClass : showClasses ? columnsWithoutAction : columnsWitoutClassAction}
                                                                            components={{
                                                                                NoRowsOverlay: () => (
                                                                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                                                                        No records found
                                                                                    </Stack>
                                                                                ),
                                                                            }}
                                                                            sx={{
                                                                                '@media (max-width: 1440px)': {
                                                                                    fontWeight: `0  !important`,
                                                                                    fontSize: `0.775rem`
                                                                                },
                                                                                '.MuiDataGrid-columnSeparator': {
                                                                                    display: 'none',
                                                                                },
                                                                                border: 0,
                                                                                '.MuiDataGrid-columnHeaderTitle': {
                                                                                    color: 'rgb(123, 128, 154)',
                                                                                    fontWeight: 'bold !important',
                                                                                    overflow: 'visible !important'
                                                                                }
                                                                            }}
                                                                            pageSizeOptions={[5, 10, 25, 50, 100]}
                                                                            paginationModel={paginationModel}
                                                                            onPaginationModelChange={setPaginationModel} />
                                                                    </ThemeProvider>
                                                                </MDBox> :
                                                                    <ThemeProvider theme={myTheme}>
                                                                        <Box
                                                                            sx={{
                                                                                '& .proerror': {
                                                                                    borderBottom: `0.5px solid red !important`
                                                                                },
                                                                                '& .proerror.tooltiptext': {
                                                                                    visibility: `hidden`,
                                                                                    width: `120px`,
                                                                                    backgroundColor: `black`,
                                                                                    color: `#fff`,
                                                                                    textAlign: `center`,
                                                                                    borderRadius: `6px`,
                                                                                    padding: `5px 0`,
                                                                                    position: `absolute`,
                                                                                    zIndex: 1,
                                                                                    bottom: `100%`,
                                                                                    left: `50%`,
                                                                                    marginLeft: `-60px`,
                                                                                },
                                                                                '& .proerror:hover .tooltiptext': {
                                                                                    visibility: `visible`
                                                                                }
                                                                            }}
                                                                        >
                                                                            <DataGrid
                                                                                rows={itemList}
                                                                                columns={finalSelect == 'To Review' ? showClasses ? columns : columnsWitoutClass : showClasses ? columnsWithoutAction : columnsWitoutClassAction}
                                                                                getCellClassName={(params) => {
                                                                                    return params?.row?.proderror == true && params?.field === 'product' ? 'proerror' : '';
                                                                                }}
                                                                                disableRowSelectionOnClick
                                                                                sx={{
                                                                                    '@media (max-width: 1440px)': {
                                                                                        fontWeight: `0  !important`,
                                                                                        fontSize: `0.775rem`
                                                                                    },
                                                                                    '.MuiDataGrid-columnSeparator': {
                                                                                        display: 'none',
                                                                                    },
                                                                                    border: 0,
                                                                                    '.MuiDataGrid-columnHeaderTitle': {
                                                                                        color: 'rgb(123, 128, 154)',
                                                                                        fontWeight: 'bold !important',
                                                                                        overflow: 'visible !important',
                                                                                        '@media (max-width: 1440px)': {
                                                                                            fontSize: `0.580rem`
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                pageSizeOptions={[5, 10, 25, 50, 100]}
                                                                                cellModesModel={cellModesModel}
                                                                                onCellModesModelChange={handleCellModesModelChange}
                                                                                onCellClick={handleCellClick}
                                                                                paginationModel={paginationModel}
                                                                                onPaginationModelChange={setPaginationModel}
                                                                            />
                                                                        </Box>
                                                                    </ThemeProvider>
                                                        }
                                                    </MDBox>
                                                    {selectTab == "AskClient" && checkFound === true &&
                                                        <MDBox display='flex' justifyContent='end' mt={6}>
                                                            <MDButton
                                                                type="submit"
                                                                variant="gradient"
                                                                color="info"
                                                                onClick={() => setOpenQboSyncAskClient(true)}
                                                            >
                                                                Send to Accountant
                                                            </MDButton>
                                                        </MDBox>}
                                                    {/* Delete Dialog */}

                                                    <MDBox>
                                                        <Dialog
                                                            open={alert}
                                                            onClose={hideAlert}
                                                            aria-labelledby="alert-dialog-title"
                                                            sx={{
                                                                "& .MuiDialog-container": {
                                                                    "& .MuiPaper-root": {
                                                                        width: "100%",
                                                                        maxWidth: "450px",
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <DialogContent sx={{ overflowY: 'hidden' }}>
                                                                <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                                                                    <h3>Are you sure?</h3>
                                                                </DialogContentText>
                                                                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                                                                    <MDTypography
                                                                        variant="body2"
                                                                        color="secondary"
                                                                        sx={{ fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 4 }}
                                                                    >
                                                                        Once you delete this Item there is no way of getting any of the documents or data back.
                                                                    </MDTypography>
                                                                </DialogContentText>
                                                            </DialogContent>
                                                            <DialogActions sx={{ justifyContent: "center", mt: 2 }} >
                                                                <MDButton
                                                                    variant="gradient"
                                                                    color="success"
                                                                    onClick={successDelete} >
                                                                    Yes, delete it!
                                                                </MDButton>
                                                                <MDButton
                                                                    variant="gradient"
                                                                    color="error"
                                                                    onClick={hideAlert}
                                                                >Cancel
                                                                </MDButton>
                                                            </DialogActions>
                                                        </Dialog>
                                                    </MDBox>
                                                    {/* Edit Item Dialog */}
                                                    <MDBox>
                                                        <Dialog
                                                            open={openEditItem}
                                                            scroll="body"
                                                            aria-labelledby="scroll-dialog-title"
                                                            aria-describedby="scroll-dialog-description"
                                                            sx={{
                                                                "& .MuiDialog-container": {
                                                                    "& .MuiPaper-root": {
                                                                        width: "100%",
                                                                        maxWidth: "570px",
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <DialogTitle id="scroll-dialog-title"
                                                                sx={{
                                                                    justifyContent: "center",
                                                                    display: 'flex'
                                                                }}>Edit Item</DialogTitle>
                                                            <DialogContent>
                                                                {toggleShow || itemType === 'ItemBasedExpenseLineDetail' ? <EditProductItemDialog noProduct={noProduct} showClasses={showClasses} businessId={businessId} itemListResponse={itemListResponse ? itemListResponse : GetItemListResponse} currencySymbol={currencySymbol} invoiceId={invoice_id} editItemId={editItemId} itemList={itemList} documentsId={invoice_id} onClose={closeProductEdit}
                                                                /> : <EditCategoryItemDialog noCategory={noCategory} showClasses={showClasses} businessId={businessId} itemListResponse={itemListResponse} currencySymbol={currencySymbol} invoiceId={invoice_id} editItemId={editItemId} itemList={itemList} onClose={closeProductEdit}
                                                                />}
                                                            </DialogContent>
                                                        </Dialog>
                                                    </MDBox>
                                                    <MDBox>
                                                        <Dialog
                                                            open={openAddItem}
                                                            scroll="body"
                                                            aria-labelledby="scroll-dialog-title"
                                                            aria-describedby="scroll-dialog-description"
                                                            sx={{
                                                                "& .MuiDialog-container": {
                                                                    "& .MuiPaper-root": {
                                                                        width: "100%",
                                                                        maxWidth: "570px",
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <DialogTitle id="scroll-dialog-title" sx={{
                                                                justifyContent: "center",
                                                                display: 'flex'
                                                            }}>Add New Item</DialogTitle>
                                                            <DialogContent
                                                            >  <AddItemDialog showClasses={showClasses} toggleShow={toggleShow} itemList={itemList} businessId={businessId} currencySymbol={currencySymbol} invoiceId={invoice_id} onClose={() => setOpenAddItem(false)} />
                                                            </DialogContent>
                                                        </Dialog>
                                                    </MDBox>
                                                    <MDBox>
                                                        <MDBox>
                                                            <Dialog
                                                                open={openCategoryAddItem}
                                                                scroll="body"
                                                                aria-labelledby="scroll-dialog-title"
                                                                aria-describedby="scroll-dialog-description"
                                                                sx={{
                                                                    "& .MuiDialog-container": {
                                                                        "& .MuiPaper-root": {
                                                                            width: "100%",
                                                                            maxWidth: "570px",
                                                                        },
                                                                    },
                                                                }}
                                                            >
                                                                <DialogTitle id="scroll-dialog-title" sx={{
                                                                    justifyContent: "center",
                                                                    display: 'flex'
                                                                }}>Add New Item</DialogTitle>
                                                                <DialogContent
                                                                >
                                                                    <AddCategoryItemDialog showClasses={showClasses} itemList={itemList} businessId={businessId} currencySymbol={currencySymbol} invoiceId={invoiceId} toggleShow={toggleShow} onClose={() => setOpenCategoryAddItem(false)} />
                                                                </DialogContent>
                                                            </Dialog>
                                                        </MDBox>
                                                    </MDBox>
                                                </MDBox>
                                            </TabPanel>
                                            <TabPanel value={value} index={2}>
                                                <MDBox>
                                                    {canAccess(actions = 'create', resource = 'comments') &&
                                                        <Formik
                                                            initialValues={{ name: '' }}
                                                            // validationSchema={InvoiceEditSchema2}
                                                            onSubmit={async (values, actions) => {
                                                                var newStr = textAreaVal.replace(/@/g, '');
                                                                const newstr2 = newStr.replace(/\(/g, '{{').replace(/\)/g, '}}');
                                                                const newStr3 = newstr2.replace(/(?:\[.*?\]|[\[\]])/g, "")
                                                                await CreateComentsListAPICall({
                                                                    payload: [{ "documentId": `${invoice_id}`, "businessId": `${businessId}`, "comment": `${newStr3}` }]
                                                                })
                                                                setLoading(true);
                                                                setBtnDisable1(true);
                                                                setsubmitcomment(true);
                                                                setTimeout(() => {

                                                                    actions.setSubmitting(false);
                                                                    actions.resetForm();
                                                                }, 1000);
                                                            }}
                                                        >
                                                            {props => (
                                                                <form onSubmit={props.handleSubmit}>
                                                                    <MentionsInput
                                                                        style={mentionsInputStyle}
                                                                        placeholder="Leave Your Comment Here"
                                                                        value={textAreaVal}
                                                                        onChange={(e) => setTextAreaVal(e.target.value)}
                                                                    >
                                                                        <Mention
                                                                            data={users}
                                                                            displayTransform={(id, display) => `@${display}`}
                                                                        />
                                                                    </MentionsInput>
                                                                    {textAreaVal.length === 0 && submitcomment === true &&
                                                                        <MDTypography sx={{ mt: 1 }} component="div" variant="caption" color="error" fontWeight="regular">Comment is required.</MDTypography>
                                                                    }
                                                                    <Grid item xs={12} sx={{ mt: 1, display: "flex", justifyContent: "end" }}>
                                                                        <MDButton
                                                                            variant="gradient"
                                                                            color="info"
                                                                            type="submit">
                                                                            {btnDisable1 ? "Submiting..." : "Submit"}
                                                                        </MDButton>
                                                                    </Grid>
                                                                </form>
                                                            )}
                                                        </Formik>
                                                    }
                                                </MDBox>
                                                {canAccess(actions = 'read', resource = 'comments') &&
                                                    <>
                                                        <MDBox>
                                                            Comments
                                                        </MDBox>
                                                        <MDBox my={3} >

                                                            {CommentsResponse &&
                                                                commentsList.length === 0 ? <MDTypography
                                                                    variant="body2"
                                                                    color="secondary"
                                                                    sx={{ marginLeft: "43%", fontSize: "1.20rem", marginTop: "10%" }}
                                                                >
                                                                No Comments
                                                            </MDTypography> : commentsList}
                                                        </MDBox>
                                                    </>
                                                }
                                            </TabPanel>
                                        </CardContent>
                                    </Card>
                                    <MDBox>
                                        {loading && <LoaderDailog />}
                                    </MDBox>
                                </Grid>
                            </Grid>
                            {/* Edit Vendor Dialog */}
                            <MDBox>
                                <MDBox>
                                    <Dialog
                                        open={openEditVendor}
                                        onClose={() => setOpenEditVendor(false)}
                                        aria-labelledby="alert-dialog-title"
                                        sx={{
                                            "& .MuiDialog-container": {
                                                "& .MuiPaper-root": {
                                                    width: "100%",
                                                    maxWidth: "570px",
                                                    minHeight: '30%'
                                                },
                                            },
                                        }}
                                    >   <DialogTitle sx={{
                                        justifyContent: "center",
                                        display: 'flex'
                                    }}>Edit Vendor</DialogTitle>
                                        <DialogContent>
                                            <EditVendorDialog invoiceId={invoiceId} businessId={businessId} onClose={() => setOpenEditVendor(false)}
                                            />
                                        </DialogContent>
                                    </Dialog>
                                </MDBox>
                            </MDBox>
                            <MDBox>
                                <Dialog
                                    open={openQboSync}
                                    onClose={closeMoveDialog}
                                    aria-labelledby="alert-dialog-title"
                                    sx={{
                                        "& .MuiDialog-container": {
                                            "& .MuiPaper-root": {
                                                width: "100%",
                                                // height: "40%",
                                                maxWidth: "500px",
                                            },
                                        },
                                    }}
                                >
                                    <DialogContent>
                                        <DialogContentText sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                                            {notApproved === true ? <h3 >
                                                {notApprovedCount} items are awaiting your approval. Are you certain you want to proceed with sending them to the accountant?
                                            </h3> :
                                                <>
                                                    <h3 >
                                                        Are you sure want to proceed with sending them
                                                    </h3>
                                                </>
                                            }
                                        </DialogContentText>
                                        <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                                            {
                                                <>
                                                    <h3 display='flex' justifyContent='center' mr={6} >
                                                        to the accountant?
                                                    </h3>
                                                </>
                                            }
                                        </DialogContentText>
                                        <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 1 }}  >
                                            <MDTypography
                                                variant="body1"
                                                color="info"
                                                sx={{ p: 2, justifyContent: "center", display: 'flex', flexDirection: 'column' }}
                                            >
                                                To Review
                                            </MDTypography>
                                            <MDTypography
                                                variant="body2"
                                                color="#037ffd"
                                                sx={{ fontSize: 40, justifyContent: "center", display: 'flex', flexDirection: 'column' }}
                                            > <DriveFileMoveIcon sx={{ color: "#037ffd", fontSize: 40 }} />
                                            </MDTypography>
                                            <MDTypography
                                                variant="body1"
                                                color="info"
                                                sx={{ p: 2, justifyContent: "center", display: 'flex', flexDirection: 'column' }}
                                            >
                                                {splitCamelCaseToString('Classify')}
                                            </MDTypography>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions sx={{ justifyContent: "center" }} >
                                        <MDButton
                                            variant="gradient"
                                            color="success"
                                            onClick={successInoiceMove} >
                                            Yes, Move it!
                                        </MDButton>
                                        <MDButton
                                            variant="gradient"
                                            color="error"
                                            onClick={closeMoveDialog}
                                        >Cancel
                                        </MDButton>
                                    </DialogActions>
                                </Dialog>
                            </MDBox>
                            <MDBox>
                                <Dialog
                                    open={openQboSyncAskClient}
                                    onClose={closeMoveDialog}
                                    aria-labelledby="alert-dialog-title"
                                    sx={{
                                        "& .MuiDialog-container": {
                                            "& .MuiPaper-root": {
                                                width: "100%",
                                                // height: "40%",
                                                maxWidth: "500px",
                                            },
                                        },
                                    }}
                                >
                                    <DialogContent>
                                        <DialogContentText sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                                            {notApproved === true ? <h3 >
                                                {notApprovedCount} items are awaiting your approval. Are you certain you want to proceed with sending them to the accountant?
                                            </h3> :
                                                <>
                                                    <h3 >
                                                        Are you sure want to proceed with sending them
                                                    </h3>
                                                </>
                                            }
                                        </DialogContentText>
                                        <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                                            {
                                                <>
                                                    <h3 display='flex' justifyContent='center' mr={6} >
                                                        to the accountant?
                                                    </h3>
                                                </>
                                            }
                                        </DialogContentText>
                                        <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 1 }}  >
                                            <MDTypography
                                                variant="body1"
                                                color="info"
                                                sx={{ p: 2, justifyContent: "center", display: 'flex', flexDirection: 'column' }}
                                            >
                                                To Review
                                            </MDTypography>
                                            <MDTypography
                                                variant="body2"
                                                color="#037ffd"
                                                sx={{ fontSize: 40, justifyContent: "center", display: 'flex', flexDirection: 'column' }}
                                            > <DriveFileMoveIcon sx={{ color: "#037ffd", fontSize: 40 }} />
                                            </MDTypography>
                                            <MDTypography
                                                variant="body1"
                                                color="info"
                                                sx={{ p: 2, justifyContent: "center", display: 'flex', flexDirection: 'column' }}
                                            >
                                                {issues?.documents?.count === 0 && issues?.items?.count === 0 && issues?.vendor?.count === 0 ? splitCamelCaseToString('To Accountant') : splitCamelCaseToString('Classify')}
                                            </MDTypography>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions sx={{ justifyContent: "center" }} >
                                        <MDButton
                                            variant="gradient"
                                            color="success"
                                            onClick={successInoiceMove} >
                                            Yes, Move it!
                                        </MDButton>
                                        <MDButton
                                            variant="gradient"
                                            color="error"
                                            onClick={closeMoveDialog}
                                        >Cancel
                                        </MDButton>
                                    </DialogActions>
                                </Dialog>
                            </MDBox>
                            {/* Delete invoice Dialog */}
                            <MDBox>
                                <Dialog
                                    open={alertDeleteInvoice}
                                    onClose={hideInvoiceAlert}
                                    aria-labelledby="alert-dialog-title"
                                    sx={{
                                        "& .MuiDialog-container": {
                                            "& .MuiPaper-root": {
                                                width: "100%",
                                                maxWidth: "450px",
                                            },
                                        },
                                    }}
                                >
                                    <DialogContent sx={{ overflowY: 'hidden' }}>
                                        <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                                            <h3>Are you sure?</h3>
                                        </DialogContentText>
                                        <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                                            <MDTypography
                                                variant="body2"
                                                color="secondary"
                                                sx={{ fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 4 }}
                                            >
                                                Once you delete this Invoice there is no way of getting any of the documents or data back.
                                            </MDTypography>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions sx={{ justifyContent: "center", mt: 2 }} >
                                        <MDButton
                                            variant="gradient"
                                            color="success"
                                            onClick={successInoiceDelete} >
                                            Yes, delete it!
                                        </MDButton>
                                        <MDButton
                                            variant="gradient"
                                            color="error"
                                            onClick={hideInvoiceAlert}
                                        >Cancel
                                        </MDButton>
                                    </DialogActions>
                                </Dialog>
                            </MDBox>
                            <MDBox>
                                {loadingCircul && (
                                    <CircularProgress
                                        size={50}
                                        sx={{
                                            color: green[500],
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </MDBox>
                            <MDBox>
                                <MDSnackbar
                                    color="error"
                                    icon="warning"
                                    title="Failed"
                                    content={ErrorMessages}
                                    open={showErrorMoveSnackbar}
                                    close={() => setShowErrorMoveSnackbar(false)}
                                />
                            </MDBox>
                            {/* <MDBox>
                                <CustomDialog
                                    DialogContentText={() => QboSyncMsg()}
                                    open={openQboSync}
                                    height={notApproved ? '30%' : '30%'}
                                    onClose={closeMoveDialog}
                                    onSuccess={successInoiceMove}
                                    Title={''}
                                    buttonOk={'Move'}
                                    button={'Cancel'}
                                />
                            </MDBox> */}
                            {/* <MDBox>
                                <CustomDialog
                                    DialogContentText={() => QboSyncMsg()}
                                    open={openQboSyncAskClient}
                                    height={notApproved ? '30%' : '30%'}
                                    onClose={closeMoveDialog}
                                    onSuccess={successInoiceMove}
                                    Title={''}
                                    buttonOk={'Move'}
                                    button={'Cancel'}
                                />
                            </MDBox> */}
                        </MDBox >
                    </>
            }
        </>
    )
}

export default EditInvoiceDialog;