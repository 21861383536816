import * as React from "react";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import MDBox from "components/MDBox";
import Box from "@mui/material/Box";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDSnackbar from "components/MDSnackbar";
import DashboardLayout from "../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../components/Navbars/DashboardNavbar";
import { NavLink } from "react-router-dom";
import DataTable from "../components/Tables/DataTable";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from '@mui/material/CircularProgress';
import { green, yellow } from '@mui/material/colors';
import Icon from "@mui/material/Icon";
import { useAPICall } from "../../utils/useapicall";
import { GetuserClientList, AccountantClientEdit, GetuserList, AccountantClientDelete } from "../../services/userservices";
import { GetUserProfile } from "services/userservices";
import Toolbar from '@material-ui/core/Toolbar';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import UserHoc from 'hoc/userHoc';
import { canAccess } from '../../helpers';
import ConnectToQuickBooksOnlineButton from "./components/QBOButton";
import Cookies from 'universal-cookie';
import { SITE_URL } from "helpers/config";
import AddOwnerDetailsDialog from "./add-owner";
import { eventBus } from 'utils/eventbus';
import { useSearchParams } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { Button, TextField, Typography } from "@mui/material";
import logo from "assets/images/logos/paynthr-logo-colored.png"
import Vendor from "./setting/vendor";
import LoaderPng from "assets/images/loders/Loading-screen-smalls.gif"
import Loader from "layouts/accountants/user/components/imgLoader/loader";
import image from "layouts/accountants/user/invoice/output-onlinegiftools.gif";
import DataTable1 from "components/NewTable/table";

function Clients() {
    const [qboData, setqboData] = useState("");
    const [reCallApi, setReCallApi] = useState(1);
    const [syncPercent, setSyncPercent] = useState(0);
    const qboData1 = localStorage.getItem("queryParameters")
    useEffect(() => {
        const timeOutApi = setTimeout(() => {
            if (qboData1 !== null && qboData1 !== 'null') {
                var nameArr = qboData1.split(',');
                window.location.replace(`${SITE_URL}/accountant/client/list?clientId=${nameArr[0]}&status=${nameArr[1]}&message=${nameArr[2]}`);
                localStorage.setItem("queryParameters", 'null')
                setReCallApi(reCallApi + 1);
            } else {
                setReCallApi(reCallApi + 1);
            }
        }, 1000);
        return () => clearTimeout(timeOutApi);
    }, [reCallApi]);


    const cookies = new Cookies();
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 1);
    const navigate = useNavigate();
    const action = { route: "", edittooltip: "Edit", deletetooltip: "Delete", addowner: "Add Owner" };
    const [adminList, setAdminList] = useState([]);
    const [alert, setAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingCircul, setLoadingCircul] = useState(false);
    const [tableEmpty, setTableEmpty] = useState("");
    const [search, setSearch] = useState('');
    const [accountantId, setAccountantId] = useState();
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [ownerName, setOwnerName] = useState('');
    const [openVendorList, setOpenVendorList] = useState(false);
    const [documentSource, setDocumentSource] = useState('');
    const [invoiceId, setInvoiceId] = useState(0);
    const [openEmploye, setOpenEmploye] = useState(false);
    const [qboState, setQboState] = useState('');
    const [clientAssigned, setClientAssigned] = useState([]);
    const [qboClientId, setQboClientId] = useState();
    const [totalEmployes, setTotalEmployes] = useState();
    const [showQboErrorSnackbar, setShowQboErrorSnackbar] = useState(false);
    const [showQboSuccessSnackbar, setShowQboSuccessSnackbar] = useState(false);
    const [openAddOwner, setOpenAddOwner] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [showAddSnackbar, setShowAddSnackbar] = useState(false);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [openNewclientPopup, setOpenNewclientPopup] = useState(false);
    const [addErrorMessage, setAddErrorMessage] = useState('');
    const [qboErrorMessage, setQboErrorMessage] = useState('');
    const [qboSuccessMessage, setQboSuccessMessage] = useState('');
    const [openNoClient, setOpenNoClient] = useState(false);
    const [openNoClients, setOpenNoClients] = useState(false);
    const [employessList, setemployessList] = useState([]);
    const [reloadApiClientApi, setReloadApiClientApi] = useState(0);
    const [tableHeight, setTableHeight] = useState({ marginTop: '8px' })
    const [loaderClass, setLoaderClass] = useState({});
    const [show, setShow] = useState(false);
    const [showBilling, setshowbilling] = useState(false);
    const [profileInfo, setProfileInfo] = useState(cookies.get('user_profile_info'));
    const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetuserClientList, { onLoad: false });
    const [{ isLoading: isRemoveLoading, isError: isRemoveError, response: removeResponse }, removeClientAPICall] = useAPICall(AccountantClientDelete, { onLoad: false });
    const [{ isLoading: isGetUserLoading, isError: isGetUserError, response: userDetailsDataResponse }, getUserListAPICall] = useAPICall(GetuserList, { onLoad: false });
    const [{ isLoading: isGetLoading, isError: isGetError, response: UserprofileResponse }, getUserProfileAPICall] = useAPICall(GetUserProfile, { onLoad: false });

    var resource = '';
    var actions = '';

    const { pathname } = useLocation();

    eventBus.$on('onclickchangePages', (data) => OnclickchangePages(data));
    function OnclickchangePages(data) {
        setLoading(true);
        const dataStr = data.toString() + 0
        localStorage.setItem("offset", Number(dataStr))
        setAPICall({
            payload: [{ "keyword": `${search}`, 'offset': Number(dataStr) }]
        });
    }


    useEffect(async () => {
        const queryParameters = new URLSearchParams(window.location.search)
        const keyword = queryParameters.get("keyword");
        const ThisOffsetuse = localStorage.getItem("offset");
        if (keyword) {
            setSearch(keyword);
            setLoading(true);
        } else {
            setLoading(true);
            await setAPICall({
                payload: [{ "keyword": `${search}`, 'offset': ThisOffsetuse }]
            })
        }
    }, []);

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const keyword = queryParameters.get("keyword");
        if (!keyword) {
            window.history.pushState('data', 'title', `${pathname}`);
        }
    }, [search]);

    // stop reload api
    useEffect(() => {
        const ThisOffsetuse = localStorage.getItem("offset");
        const fetchData = async () => {
            await setAPICall({
                payload: [{ "keyword": `${search}`, 'offset': ThisOffsetuse }]
            })
        };

        const timer = setTimeout(() => {
            fetchData();
        }, 30000);
        console.log("reloadApiClientApi---------------->", reloadApiClientApi)
        return () => clearTimeout(timer);
    }, [reloadApiClientApi]);

    useEffect(() => {
        getUserProfileAPICall({ payload: [] });
    }, [])

    useEffect(async () => {
        if (isGetLoading) {
            return () => null;
        }

        if (isGetError) {
            return () => null;
        }

        if (UserprofileResponse && UserprofileResponse.data.isSuccess === true) {
            setShow(UserprofileResponse?.data?.data?.user?.isPrimary === true ? true : false);
            localStorage.setItem("ShowBilling", UserprofileResponse?.data?.data?.user?.isPrimary === true ? true : false)
        }
    }, [UserprofileResponse]);

    useEffect(() => {
        eventBus.$emit("OnSetBlur", loading);
        loading === true ? setLoaderClass({ opacity: '0.4', borderRadius: '13px' }) : setLoaderClass({})
    }, [loading])

    useEffect(() => {

        if (isGetUserLoading) {
            return () => null;
        }

        if (isGetUserError) {
            setLoading(false);
            setLoadingCircul(false);
            return () => null;
        }

        if (userDetailsDataResponse && userDetailsDataResponse.data.isSuccess === true) {
            setTotalEmployes(userDetailsDataResponse?.data?.data?.totalRecords);
            const data = userDetailsDataResponse?.data?.data?.records.map((prop, key) => {
                return {
                    firstName: prop?.fname,
                    lastName: prop?.lname,
                    email: prop?.email,
                };
            }
            );
            setemployessList(data);
            setLoading(false);
            setLoadingCircul(false);
        }
    }, [userDetailsDataResponse]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '0',
        boxShadow: 24,
        p: 4,
    };

    const Modalstyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '0',
        boxShadow: 24,
        p: 4,
        borderRadius: '15px',
        outline: 'none',
    };
    useEffect(() => {
        setProfileInfo(cookies.get('user_profile_info'))
    }, []);

    eventBus.$on('closeQboWindowApiCall', (data) => closeQboWindowApi(data));
    function closeQboWindowApi(data) {
        const ThisOffsetuse = localStorage.getItem("offset");
        setAPICall({
            payload: [{ "keyword": `${search}`, 'offset': ThisOffsetuse }]
        })
    }

    eventBus.$on('errorInAddOwner', (data) => errorInAddOwner(data));
    function errorInAddOwner(data) {
        setShowErrorSnackbar(true);
        setAddErrorMessage(data);
    }

    eventBus.$on('successInAddOwner', (data) => successInAddOwner(data));
    function successInAddOwner(data) {
        const ThisOffsetuse = localStorage.getItem("offset");
        setShowAddSnackbar(true);
        setTimeout(() => {
            setShowAddSnackbar(false);
        }, 10000);
        setOpenAddOwner(false);
        setLoading(true);
        setAPICall({
            payload: [{ "keyword": `${search}`, 'offset': ThisOffsetuse }]
        })
    }

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const status = queryParameters.get("status");
        const message = queryParameters.get("message");
        const clientId = queryParameters.get("clientId");
        setQboErrorMessage(message);
        setQboSuccessMessage(message);
        setQboState(status);
        setQboClientId(clientId);
        if (status === 'Failed') {
            const param = searchParams.get('status');
            if (param) {
                searchParams.delete('status');
                searchParams.delete('message');
                searchParams.delete('clientId');
                setSearchParams(searchParams);
            }
            setShowQboErrorSnackbar(true);
            // history.pushState(null, ``, `${SITE_URL}/accountant/client/list`);
            setTimeout(() => {
                setShowQboErrorSnackbar(false);
            }, 10000);
        }
    }, [])

    useEffect(() => {
        if (isRemoveLoading) {
            return () => null;
        }

        if (isRemoveError) {
            setLoading(false);
            response?.code === "DATA401" ? navigate('/user/logout') : console.log("somting else");
            return () => null;
        }

        if (removeResponse && removeResponse.data.isSuccess === true) {
            const ThisOffsetuse = localStorage.getItem("offset");
            setShowSnackbar(true);
            setTimeout(() => {
                setShowSnackbar(false);
            }, 3000);
            setLoading(true);
            setLoadingCircul(false);
            setAPICall({
                payload: [{ "keyword": `${search}`, 'offset': ThisOffsetuse }]
            })
            // navigate('/accountants/list');
        }
    }, [removeResponse]);

    const warningWithConfirmMessage = () => {
        setAlert(true);
    };

    const openAddNewButton = () => {
        setOpenNewclientPopup(true);
        // setLoading(true);
        // setTimeout(() => {
        //     setLoading(false);
        // }, 1000)
    };

    const successDelete = async () => {
        setLoadingCircul(true);
        await removeClientAPICall({
            payload: [
                {
                    "bussinessId": `${accountantId}`
                }
            ]
        })
        setAlert(false);
    };

    const hideAlert = () => {
        setAlert(false);
    };

    const AssignClient = () => {
        eventBus.$emit("clientAssignedDetail", clientAssigned);
    };

    useEffect(() => {
        const ThisOffsetuse = localStorage.getItem("offset");
        const timeOutId = setTimeout(async () =>
            await setAPICall({
                payload: [{ "keyword": `${search}`, 'offset': ThisOffsetuse }]
            }), 500);
        return () => clearTimeout(timeOutId);
    }, [search]);


    useEffect(() => {
        if (isLoading) {
            return () => null;
        }
        if (isError) {
            setLoading(false);
            setLoadingCircul(false);
            return () => null;
        }
        if (response && response.data.isSuccess === true) {
            if (response?.data?.data?.records[0]?.syncPercent !== 100) {
                setReloadApiClientApi(reloadApiClientApi + 1);
            }
            search.length === 0 && response?.data?.data?.totalRecords === 0 ? setOpenNoClients(true) : setOpenNoClients(false)
            setTableEmpty(response?.data?.data?.totalRecords);
            const totalHeight = `${18 - response?.data?.data?.totalRecords * 2}%`
            setTableHeight(response?.data?.data?.totalRecords > 4 ? { marginTop: '8px' } : { marginTop: '8px', marginBottom: totalHeight })
            const data = response.data.data.records.map((prop, key) => {
                setDocumentSource(prop.documentSource);
                setClientAssigned(prop?.ownerAssigned);
                console.log(" prop.ownerAssigned----------->", prop.ownerAssigned)
                qboClientId === prop._id ? setOwnerName(prop.name) : ''
                return {
                    snum: (key + 1),
                    client:
                        (
                            prop?.syncPercent !== 100 ?
                                <MDBox>
                                    <Link to={`/accountant/document/${prop._id}`}>
                                        <MDBox sx={{
                                            width: '100%', display: 'flex',
                                            justifyContent: 'center', fontSize: "1.20rem"
                                        }}>
                                            <div>
                                                <img width={13} height={13} style={{ marginBottom: '3px', marginRight: '5px' }} src={image} />
                                            </div>
                                            <MDTypography
                                                variant="caption" fontWeight="regular" fontSize="0.875rem"
                                                color="info"
                                                onClick={() => setClientAssigned(prop?.ownerAssigned)}
                                            >
                                                {prop.name}
                                            </MDTypography>
                                            <MDTypography sx={{ ml: 1, mt: -0.4 }} fontSize="0.80rem" color={'warning'} >({prop?.syncPercent}%)</MDTypography>
                                        </MDBox>
                                    </Link>
                                </MDBox>
                                :
                                <MDBox>
                                    <Link to={`/accountant/document/${prop._id}`}>
                                        <MDTypography
                                            variant="caption" fontWeight="regular" fontSize="0.875rem"
                                            color="info"
                                            onClick={() => setClientAssigned(prop?.ownerAssigned)}
                                        >
                                            {prop.name}
                                        </MDTypography>
                                    </Link>
                                </MDBox>
                        ),
                    doctoreview: prop.toReview,
                    unmappedvendors: (prop.unmappedVendor !== 0 ?
                        <MDBox sx={{ cursor: "pointer" }}>
                            <MDTypography
                                variant="caption" fontWeight="regular" fontSize="0.875rem" color="info"
                                onClick={async (value) => {
                                    setLoading(true);
                                    setInvoiceId(prop._id);
                                    setOpenVendorList(true);
                                }}
                            >
                                {prop.unmappedVendor}
                            </MDTypography>
                        </MDBox> : prop.unmappedVendor
                    ),
                    clientapproval: prop.askClient ? prop.askClient : 0,
                    yourapproval: prop.approved,
                    pendingapproval: prop.pendingApproval,
                    unreconciled: "-",
                    numberOfEmployees: (prop?.numberOfEmployees !== 0 ?
                        <MDBox>
                            <MDTypography sx={{ cursor: "pointer" }}
                                variant="caption" fontWeight="regular" fontSize="0.875rem" color="info"
                                onClick={async (value) => {
                                    setLoadingCircul(true);
                                    setOpenEmploye(true);
                                    await getUserListAPICall({
                                        payload: [{ "userIds": `${prop.employees}` }]
                                    })
                                }}
                            >
                                {prop.numberOfEmployees}
                            </MDTypography>
                        </MDBox> : prop.numberOfEmployees),
                    switch: (
                        prop.ownerAssigned === true ?
                            prop.isBlocked ?
                                <MDBox>
                                    <MDTypography
                                        variant="caption" fontWeight="regular" fontSize="0.875rem" color="error"
                                    >  Inactive
                                    </MDTypography>
                                </MDBox>
                                :
                                <MDBox>
                                    <MDTypography
                                        variant="caption" fontWeight="regular" fontSize="0.875rem" color="success"
                                    >
                                        Active
                                    </MDTypography>
                                </MDBox> : <MDBox>
                                <MDTypography
                                    variant="caption" fontWeight="regular" fontSize="0.875rem" color="warning"
                                >  Owner Not Assigned
                                </MDTypography>
                            </MDBox>
                    ),
                    action: (
                        prop.ownerAssigned == true ?
                            <MDBox display="flex">
                                {/* {canAccess(actions = 'update', resource = 'clients') && */}
                                <Link to={`/accountant/client/${prop._id}/edit-client`}>
                                    <MDTypography
                                        variant="body2"
                                        color="secondary"
                                        sx={{ marginLeft: "-4px", fontSize: "1.20rem" }}
                                    >
                                        <Tooltip title={action.edittooltip} placement="top">
                                            <Icon>edit</Icon>
                                        </Tooltip>
                                    </MDTypography>
                                </Link>
                                {/* }  */}
                                {prop.documentSource === 'Paynthr' ? canAccess(actions = 'delete', resource = 'clients') &&
                                    <MDTypography variant="body2" color="secondary" sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: 'pointer' }}
                                    >
                                        <Tooltip title={action.deletetooltip} placement="top"
                                            onClick={(value) => {
                                                // setAccountantId(prop._id);
                                                warningWithConfirmMessage();
                                                setAccountantId(prop._id);
                                            }}
                                        >
                                            <Icon>delete</Icon>
                                        </Tooltip>
                                    </MDTypography> : prop.syncPercent === 100 ? canAccess(actions = 'delete', resource = 'clients') &&
                                        <MDTypography variant="body2" color="secondary" sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: 'pointer' }}
                                        >
                                            <Tooltip title={action.deletetooltip} placement="top"
                                                onClick={(value) => {
                                                    // setAccountantId(prop._id);
                                                    warningWithConfirmMessage();
                                                    setAccountantId(prop._id);
                                                }}
                                            >
                                                <Icon>delete</Icon>
                                            </Tooltip>
                                        </MDTypography> : ''
                                }

                            </MDBox>
                            :
                            <MDBox display="flex">
                                {setAccountantId(qboClientId)}
                                <Link to={`/accountant/client/${prop._id}/edit-client`}>
                                    <MDTypography
                                        variant="body2"
                                        color="secondary"
                                        sx={{ marginLeft: "-4px", fontSize: "1.20rem" }}
                                    >
                                        <Tooltip title={action.edittooltip} placement="top">
                                            <Icon>edit</Icon>
                                        </Tooltip>
                                    </MDTypography>
                                </Link>
                                {canAccess(actions = 'delete', resource = 'clients') &&
                                    < MDTypography variant="body2" color="secondary" sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: 'pointer' }}
                                    >
                                        <Tooltip title={action.addowner} placement="top"
                                            onClick={(value) => {
                                                setOwnerName(prop.name);
                                                setAccountantId(prop._id);
                                                setOpenAddOwner(true);
                                            }}
                                        >
                                            <Icon>person</Icon>
                                        </Tooltip>
                                    </MDTypography>
                                }
                                {prop.syncPercent === 100 && canAccess(actions = 'delete', resource = 'clients') &&
                                    <MDTypography variant="body2" color="secondary" sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: 'pointer' }}
                                    >
                                        <Tooltip title={action.deletetooltip} placement="top"
                                            onClick={(value) => {
                                                // setAccountantId(prop._id);
                                                warningWithConfirmMessage();
                                                setAccountantId(prop._id);
                                            }}
                                        >
                                            <Icon>delete</Icon>
                                        </Tooltip>
                                    </MDTypography>
                                }
                            </MDBox >

                    )
                };
            }
            );
            setAdminList(data);
            setLoading(false);
            setLoadingCircul(false);
        }
    }, [response]);


    const dataTableData = {
        columns: [
            {
                Header: "CLIENT NAME",
                accessor: "client",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "To Review",
                accessor: "doctoreview",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Unmapped Vendors", accessor: "unmappedvendors",
                Cell: ({ value }) => <DefaultCell value={value} />
            },
            {
                Header: "Client Approval", accessor: "clientapproval",
                Cell: ({ value }) => <DefaultCell value={value} />
            },
            {
                Header: "Your Approval", accessor: "yourapproval",
                Cell: ({ value }) => <DefaultCell value={value} />
            },
            {
                Header: "Pending Approval", accessor: "pendingapproval",
                Cell: ({ value }) => <DefaultCell value={value} />
            },
            {
                Header: "Unreconciled Statements", accessor: "unreconciled",
                Cell: ({ value }) => <DefaultCell value={value} />
            },
            {
                Header: "Users",
                accessor: "numberOfEmployees",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Status",
                accessor: "switch",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Action", accessor: "action",
                disableSortBy: true,
                Cell: ({ value }) => <DefaultCell value={value} />
            },
        ],

        rows: adminList
    };

    const dataTableDataEmploye = {
        columns: [
            {
                Header: "First NAME",
                accessor: "firstName",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Last Name",
                accessor: "lastName",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Email",
                accessor: "email",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
        ],

        rows: employessList
    };


    useEffect(() => {
        if (qboState === 'Success') {
            setShowQboSuccessSnackbar(true);
            const param = searchParams.get('status');
            if (param) {
                searchParams.delete('status');
                searchParams.delete('message');
                searchParams.delete('clientId');
                setSearchParams(searchParams);
            }
            setTimeout(() => {
                setOpenAddOwner(true);
            }, 1000);
            setTimeout(() => {
                setShowQboSuccessSnackbar(false);
            }, 10000);
        }
    }, [qboState])

    const RemindMeFun = async () => {
        // cookies.set('remind_add_client', true, { path: '/', expires: expirationDate });
        cookies.set('remind_add_client', true, { path: '/', maxAge: 3600 });
        cookies.set('backup_remind_add_client', true, { path: '/', maxAge: 3600 });
        setOpenNoClient(false)
    };

    useEffect(() => {
        if (openNoClients === true) {
            const profileInfo11 = cookies.get('remind_add_client');
            const profileInfo12 = cookies.get('backup_remind_add_client');
            setOpenNoClient(!profileInfo11)
            if (profileInfo12 === undefined) {
                cookies.set('remind_add_client', false, { path: '/', maxAge: 3600 });
            }
            if (profileInfo12 === undefined) {
                setOpenNoClient(true)
            } else if (profileInfo11 === false) {
                setOpenNoClient(true)
            }
        }

    }, [openNoClients])

    const closeBox = async (event) => {
        setOpenVendorList(false)
        setLoading(false);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    const handleSearch = async (event) => {
        localStorage.setItem("offset", 0)
        setSearch(event.target.value);
        window.history.pushState('data', 'title', `${pathname}?keyword=${encodeURIComponent(event.target.value)}`);
        setLoadingCircul(true);
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
        }
    }

    const ReturnUrl = encodeURI(`${SITE_URL}/qbo-cb`);

    return (
        <>
            <DashboardLayout >
                <DashboardNavbar showBilling={show} />
                <MDBox my={4} >
                    <Card sx={loaderClass}>
                        <MDBox pt={3} px={2} sx={{ pr: 3, mb: 2 }} component="form" display="flex" justifyContent="space-between" alignItems="center">
                            <MDTypography variant="h5" fontWeight="medium">
                                Clients
                            </MDTypography>
                            <MDBox px={2} display="flex" >
                                <MDBox sx={{ pr: 3, mb: 2 }}>
                                    <MDInput type="search" label="Search Client" onKeyDown={handleKeyDown} variant="standard" onChange={handleSearch} />
                                </MDBox>
                                <MDBox>
                                    {canAccess(actions = 'create', resource = 'clients') &&
                                        <ConnectToQuickBooksOnlineButton
                                            buttonType={'custom'}
                                            accountantId={encodeURI(profileInfo?.user?._id)}
                                            accountantDomain={encodeURI(profileInfo.user.domainKey)}
                                            accountantReturnUrl={ReturnUrl}
                                            loading={loading}
                                        />
                                    }
                                    {/* {canAccess(actions = 'create', resource = 'clients') &&
                                        <MDButton
                                            variant="gradient"
                                            color="info"
                                            onclick={intuit.ipp.anywhere.controller.onConnectToIntuitClicked()}
                                        >  Add New Client
                                        </MDButton>
                                    } */}
                                </MDBox>
                            </MDBox>
                        </MDBox>
                        <MDBox>
                            {alert}
                        </MDBox>
                        <Box my={5} style={tableHeight} >
                            <DataTable1 table={dataTableData} entriesPerPage={false} pagination={true} showTotalEntries={true} response={tableEmpty} showCheck={false} ClientHeigth={true} />
                        </Box>
                        <MDBox>
                            <MDSnackbar
                                color="success"
                                icon="delete"
                                title="Success"
                                // autoHideDuration={3000}
                                content="Client Deleted Successfully."
                                open={showSnackbar}
                                close={() => setShowSnackbar(false)}
                            />
                        </MDBox>
                        <MDBox>
                            <MDSnackbar
                                color="success"
                                icon="done"
                                title="Success"
                                content="Owner Add Successfully."
                                open={showAddSnackbar}
                                close={() => setShowAddSnackbar(false)}
                            />
                        </MDBox>
                        <MDBox>
                            <MDSnackbar
                                color="success"
                                icon="done"
                                title="Success"
                                content={qboSuccessMessage}
                                open={showQboSuccessSnackbar}
                                close={() => setShowQboSuccessSnackbar(false)}
                            />
                        </MDBox>
                        <MDBox>
                            <MDSnackbar
                                color="error"
                                icon="warning"
                                title="Failed"
                                content={addErrorMessage}
                                open={showErrorSnackbar}
                                close={() => setShowErrorSnackbar(false)}
                            />
                        </MDBox>
                        <MDBox>
                            <MDSnackbar
                                color="error"
                                icon="warning"
                                title="Failed"
                                content={qboErrorMessage}
                                open={showQboErrorSnackbar}
                                close={() => setShowQboErrorSnackbar(false)}
                            />
                        </MDBox>
                        <MDBox>
                            <Dialog
                                open={alert}
                                onClose={hideAlert}
                                aria-labelledby="alert-dialog-title"
                                sx={{
                                    "& .MuiDialog-container": {
                                        "& .MuiPaper-root": {
                                            width: "100%",
                                            maxWidth: "450px",
                                        },
                                    },
                                }}
                            >
                                <DialogContent sx={{ overflowY: 'hidden' }}>
                                    <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                                        <h3>Are you sure?</h3>
                                    </DialogContentText>
                                    <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                                        <MDTypography
                                            variant="body2"
                                            color="secondary"
                                            sx={{ fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 4 }}
                                        >
                                            Once you delete this Client there is no way of getting any of the documents or data back.
                                        </MDTypography>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions sx={{ justifyContent: "center", mt: 2 }} >
                                    <MDButton
                                        variant="gradient"
                                        color="success"
                                        onClick={successDelete} >
                                        Yes, delete it!
                                    </MDButton>
                                    <MDButton
                                        variant="gradient"
                                        color="error"
                                        onClick={hideAlert}
                                    >Cancel
                                    </MDButton>
                                </DialogActions>
                            </Dialog>
                        </MDBox>
                        <MDBox>
                            <Dialog
                                open={openAddOwner}
                                onClose={() => setOpenAddOwner(false)}
                                aria-labelledby="alert-dialog-title"
                                sx={{
                                    "& .MuiDialog-container": {
                                        "& .MuiPaper-root": {
                                            width: "100%",
                                            maxWidth: "500px",
                                            // height: "100%"
                                        },
                                    },
                                }}
                            >   <DialogTitle sx={{
                                justifyContent: "center",
                                display: 'flex'
                            }}>Add Owner For - {ownerName}</DialogTitle>
                                <DialogContent>
                                    <AddOwnerDetailsDialog businessId={accountantId} />
                                </DialogContent>
                            </Dialog>
                        </MDBox>
                        <MDBox>
                            <Dialog
                                open={openVendorList}
                                onClose={closeBox}
                                aria-labelledby="alert-dialog-title"
                                sx={{
                                    "& .MuiDialog-container": {
                                        "& .MuiPaper-root": {
                                            width: "100%",
                                            maxWidth: "1370px",
                                            minHeight: "40%",
                                        },
                                    },
                                }}
                            >
                                <Toolbar style={{ minHeight: '45px', backgroundColor: 'white', display: 'flex', justifyContent: 'end' }}>
                                    <Icon sx={{ cursor: "pointer" }} onClick={closeBox}>close</Icon>
                                </Toolbar>
                                <DialogContent>
                                    <Vendor businessId={invoiceId} syncPercent={100} documentSource={documentSource} IsSynced={false} />
                                </DialogContent>
                            </Dialog>
                        </MDBox>
                        <MDBox display="flex" justifyContent="center" >
                            {loading && <Loader />}
                        </MDBox>
                    </Card>
                </MDBox>
                <MDBox>
                    <Modal
                        open={openNoClient}
                        // onClose={() => setOpenNoClient(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={Modalstyle}>
                            <MDBox display='flex' justifyContent="end" style={{ cursor: 'pointer' }}>
                                <MDTypography
                                    component="span"
                                    variant="body2"
                                    color="dark"
                                    opacity={1}
                                    sx={{ lineHeight: 0 }}
                                    onClick={() => setOpenNoClient(false)}
                                >
                                    <Icon>close</Icon>
                                </MDTypography>
                            </MDBox>
                            <MDBox display='flex' justifyContent="center" mb={3} >
                                <img
                                    className="small"
                                    src={logo}
                                    alt="no image"
                                    height='80px'
                                    width='80px'
                                />
                            </MDBox>
                            <Typography sx={{ mt: 2 }} display={'flex'} justifyContent={'center'} id="modal-modal-title" variant="h5" component="h2">
                                Let’s add your first client to get the most use out of Paynthr
                            </Typography>
                            <Typography variant="body2" id="modal-modal-description" sx={{ mt: 4, ml: 1 }}>
                                To initiate the synchronization with QuickBooks, we'll need to obtain a set of default accounts, vendors, and items for your QuickBooks account. Our system will seamlessly map your QuickBooks data to the corresponding Paynthr account. The mapping process can start after establishing the
                            </Typography>
                            <Typography display={'flex'} justifyContent={'center'} variant="body2" id="modal-modal-description">
                                connection.
                            </Typography>
                            <MDBox display='flex' justifyContent='center' sx={{ mt: 4 }}>
                                <ConnectToQuickBooksOnlineButton buttonType={'Icon'} accountantId={encodeURI(profileInfo?.user?._id)} accountantDomain={encodeURI(profileInfo?.user?.domainKey)} accountantReturnUrl={ReturnUrl} />
                            </MDBox>
                            <MDBox display='flex' justifyContent='center' >
                                <MDButton onClick={RemindMeFun}>
                                    Remind me later
                                </MDButton>
                            </MDBox>
                        </Box>
                    </Modal>
                </MDBox>
                <MDBox>
                    <Dialog
                        open={openEmploye}
                        onClose={() => setOpenEmploye(false)}
                        aria-labelledby="responsive-dialog-title"
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    minHeight: "40%",
                                    maxWidth: "1000px",
                                },
                            },
                        }}
                    >
                        <DialogContent>
                            <MDBox my={5}>
                                <MDBox display="flex" justifyContent="center" >
                                    {loadingCircul && <Loader />}
                                </MDBox>
                                <MDBox my={5} sx={{ mt: 1 }}>
                                    <DataTable1 table={dataTableDataEmploye} entriesPerPage={false} pagination={true} showTotalEntries={true} response={totalEmployes} showCheck={false} ClientHeigth={true} />
                                </MDBox>
                            </MDBox>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenEmploye(false)} >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </MDBox>
                <MDBox>
                    {loadingCircul && (
                        <CircularProgress
                            size={50}
                            sx={{
                                color: green[500],
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                </MDBox>
                <Modal
                    open={openNewclientPopup}
                    onClose={() => setOpenNewclientPopup(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}  >
                        <MDTypography display="flex" justifyContent="center">
                            Add New Client
                        </MDTypography>
                        <MDBox sx={{ pl: 0, pt: 5 }} display="flex" justifyContent="space-around">
                            <MDBox>
                                {canAccess(actions = 'create', resource = 'clients') &&
                                    <MDButton component={NavLink} to="/accountant/client/new-client"
                                        variant="gradient"
                                        color="info"
                                    >  Through Paynthr
                                    </MDButton>
                                }
                            </MDBox>
                            <MDBox>
                                {canAccess(actions = 'create', resource = 'clients') &&
                                    <ConnectToQuickBooksOnlineButton buttonType={'custom'} accountantId={encodeURI(profileInfo?.user?._id)} accountantDomain={encodeURI(profileInfo.user.domainKey)} accountantReturnUrl={ReturnUrl} />
                                }
                            </MDBox>
                        </MDBox>
                    </Box>
                </Modal>
            </DashboardLayout >
        </>
    );
}

export default UserHoc(Clients);