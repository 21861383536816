import * as React from "react";
import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import { BASE_URL_API } from "helpers/config";
import { getuserToken } from 'helpers/getusertoken';
import { styled } from '@material-ui/core';
import axios from 'axios';
import { Line, Circle } from 'rc-progress';
import MDSnackbar from "components/MDSnackbar";
import { eventBus } from 'utils/eventbus';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MDTypography from "components/MDTypography";
import { ConstructionOutlined } from "@mui/icons-material";
import { DropzoneArea } from 'material-ui-dropzone';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { GetProcessIdApi } from "../../../../services/userservices";
import { useAPICall } from "utils/useapicall";


const theme = createMuiTheme({
    overrides: {
        MuiDropzoneArea: {
            icon: {
                opacity: 1,
                background: 'transparent',
                color: '#7b809a',
                borderRadius: 'none',
                boxShadow: 'none',
                height: '35px'
            },
            textContainer: {
                width: '100%'
            },
            text: {
                fontWeight: 300,
                fontSize: '1rem',
                marginLeft: '120px',
                marginRight: '120px'
            },
            root: {
                minHeight: '120px',
                padding: '0px'
            }
        }
    }
});


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function NewInvoiceDialog({ classes, businessId, invoiceId }) {
    const [invoice_id, setInvoiceId] = useState(invoiceId);
    const [business_id, setBusinessId] = useState(businessId);
    const [createObjectURL, setCreateObjectURL] = useState(null);
    const [value, setValue] = useState(0);
    const [fileUp, setFileUp] = useState();
    const [fileObjects, setfileObjects] = useState([]);
    const [fileArray, setFileArray] = useState([]);
    const [myArray, setMyArray] = useState([]);
    const [fileLength, setFileLength] = useState(0);
    const [keys, setKeys] = useState(0);
    const [notFound, setNotFound] = useState(true);
    const [showLoder, setShowLoder] = useState(false);
    const [hideUpload, setHideUpload] = useState(true);
    const [progresBar, setProgresBar] = useState(0);
    const [uploadText, setUploadText] = useState("Please Wait...");
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [counter, setCounter] = useState(0);
    const [addNum, setAddNum] = useState(1);
    const [processId, setProcessId] = useState("");
    const MAX_SIZE = 5242880;


    useEffect(() => {

        if (progresBar === 33) {
            setCounter(20)
        } else if (progresBar === 66) {
            setCounter(50)
        } else if (progresBar === 99 && fileLength === keys) {
            setAddNum(0)
            setCounter(75)
        }
    }, [progresBar])


    const handlErrorInvoice = (error) => {
        eventBus.$emit("closeNewInvoicePopup", error)
    }
    const handleFile = (file) => {
        setfileObjects(file);

        setFileLength(file.length - 1);
        file.map((prop, key) => {
            handleUploadChange(prop, key, file)
        });

    }

    function handleUploadChange(prop, key, file) {
        eventBus.$emit('disableclosebutton', true);
        {
            setInterval(() => {
                setCounter((counter) => counter < 95 ? counter + addNum : counter + 0);
            }, 500);
        }

        // event.preventDefault();
        setShowLoder(true);
        setHideUpload(false);
        setFileLength(file.length - 1);
        setKeys(key);

        // file.map((prop, key) => {
        setFileUp(prop);

        const url = BASE_URL_API + `/accountants/profiles/uploader`;

        const dataRaw = {
            'scopeType': 'INVOICE',
            'scopeId': business_id,
            'extension': prop.type.split("/").pop()
        }
        const config = {

            headers: {
                'Authorization': `${getuserToken()}`,
                'content-type': 'application/json',
            },
            onUploadProgress: progressEvent => { setProgresBar(Math.floor(((progressEvent.loaded / progressEvent.total) * 100) / 3)) }
        };
        axios.post(url, dataRaw, config).then((response) => {

            const url2 = `${response.data.data.SignedObject.postData.url}`;

            let createData = {
                uploadFileUrl: `${response.data.data.SignedObject.uploadUrl}`,
                mimetype: `${response.data.data.ContentType}`
            };

            let formData = new FormData();

            for (const [key, value] of Object.entries(response.data.data.SignedObject.postData.fields)) {
                formData.append(key, value);
            }
            formData.append('file', prop);

            if (response.data.isSuccess) {
                // Upload the file
                uploadFileOnS3(url2, formData, createData)
            }
            else {
                setProgresBar(0);
                setShowLoder(false);
            }
        })
            .catch((error) => handlErrorInvoice(error));
        // });

    }
    function uploadFileOnS3(url, formData, createData) {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => setProgresBar(33 + Math.floor(((progressEvent.loaded / progressEvent.total) * 100) / 3))
        };

        axios.post(url, formData, config).then((response) => {
            if (response.status == 200 || response.status == 204) {
                setFileArray(prevArray => [...prevArray, createData]);
            }
        })
            .catch((error) => handlErrorInvoice(error));
    }

    useEffect(() => {
        if (fileLength + 1 === fileArray.length) {
            createInvoice(fileArray);
            console.log("fileArray------->", fileArray)
        }
    }, [fileArray]);

    function createInvoice(createData) {
        const url = BASE_URL_API + `/accountants/clients/${business_id}/documents`;

        const dataRaw = {
            "files": createData,
        }

        const config = {

            headers: {
                'Authorization': `${getuserToken()}`,
                // 'content-type': 'application/json',
            },
            onUploadProgress: progressEvent => setProgresBar(66 + Math.floor(((progressEvent.loaded / progressEvent.total) * 100) / 3))
        };

        axios.post(url, dataRaw, config).then((response) => {
            if (response.status == 200 || response.status == 204) {
                setUploadText("Document Uploded");
                setShowLoder(false);
                setHideUpload(true);
                setShowSnackbar(true);
                setTimeout(() => {
                    setShowSnackbar(false);
                }, 3000);
                eventBus.$emit("closeNewInvoicePopup", response)
            }
        })
            .catch((error) => handlErrorInvoice(error));
    }

    const Input = styled('input')({
        display: 'none',
    });
    const dropzoneStyle = {
        width: "100%",
        height: "20%",
        border: "1px solid black"
    };
    return (
        <MDBox mb={3}>
            <MDBox my={2} display="flex" justifyContent="center">
                <Box style={{ overflow: "hidden" }}>
                    <Grid item xs={12} sm={12} md={12}>
                        <MDBox sx={{ fontSize: "2rem" }}>
                            {showLoder &&
                                <MDBox sx={{ mt: -3 }}>
                                    <Line percent={progresBar} strokeWidth={4} strokeColor="#0d87f6" width={175} trailWidth={4} />
                                    <MDTypography
                                        variant="caption" fontWeight="regular" fontSize="0.875rem" sx={{ ml: 1 }}
                                    >
                                        {progresBar === 99 ? `100%` : counter + '%'}
                                    </MDTypography>
                                </MDBox>
                            }
                        </MDBox>
                        {showLoder &&
                            <MDBox sx={{ marginLeft: 4 }}>
                                <MDTypography
                                    variant="h5" fontWeight="regular"
                                >
                                    {uploadText}
                                </MDTypography>
                            </MDBox>
                        }
                        <MDBox>
                            <label style={{ minHeight: '150px' }} htmlFor="icon-button-file">
                                {hideUpload && <>
                                    <MuiThemeProvider theme={theme}>
                                        <DropzoneArea
                                            filesLimit={100}
                                            acceptedFiles={['image/*', 'application/*']}
                                            onDrop={handleFile}
                                            maxFileSize={MAX_SIZE}
                                        />
                                    </MuiThemeProvider>
                                </>
                                }
                            </label>
                        </MDBox>
                    </Grid>
                </Box>
            </MDBox>
            <MDBox>
                <MDSnackbar
                    color="success"
                    icon="done"
                    title="success"
                    content="Invoice has been successfully updated."
                    // autoHideDuration={3000}
                    open={showSnackbar}
                    close={() => setShowSnackbar(false)}
                />
            </MDBox>
            {/* <DialogContentText sx={{ display: 'flex', justifyContent: 'center' }}>
                Upload type must be pdf, png, jpg or jpeg.
            </DialogContentText> */}
        </MDBox >

    )
}

export default NewInvoiceDialog;