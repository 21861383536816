import * as React from "react";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { NavLink, useLocation } from "react-router-dom";
import DashboardLayout from "../../clientuser/user/components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../clientuser/user/components/Navbars/DashboardNavbar";
import DataTable from "../../components/Tables/DataTable";
import dataTableData from "layouts/clientuser/user/data/dataTableData";
import { Link, useNavigate } from 'react-router-dom';
import MDSnackbar from "components/MDSnackbar";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import { useAPICall } from "../../../utils/useapicall";
import { GetclientRestaurantList, EditRestaurantInfo, removeRestaurantAPI } from "../../../services/clientservices";
import CircularProgress from '@mui/material/CircularProgress';
import { green, yellow } from '@mui/material/colors';
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { canAccess } from '../../../helpers';
import UserHoc from "hoc/userHoc";
import Cookies from 'universal-cookie';
import Loader from "layouts/accountants/user/components/imgLoader/loader";
import { eventBus } from "utils/eventbus";
import AddNewRestaurant from "./new-restaurants"
import DialogTitle from "@mui/material/DialogTitle";
import EditRestaurant from "./edit-restaurant";
import { isMobile } from 'react-device-detect';
import DataTable1 from "components/NewTable/table";

const cookies = new Cookies();


function Restaurant() {
    const navigate = useNavigate();
    const [alert, setAlert] = useState(false);
    const [restaurantList, setRestaurantList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tableEmpty, setTableEmpty] = useState("");
    const [search, setSearch] = useState('');
    const [massage, setMassage] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [restaurantId, setRestaurantId] = useState();
    const [loaderClass, setLoaderClass] = useState({});
    const [loadingCircul, setLoadingCircul] = useState(false);
    const [openAddNewRestarant, setOpenOpenAddNewRestarant] = useState(false);
    const [openEditRestarant, setOpenEditRestarant] = useState(false);
    const [profileInfo, setProfileInfo] = useState(cookies.get('client_profile_info'));
    const action = { route: "", edittooltip: "Edit", deletetooltip: "Delete" };
    const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetclientRestaurantList, { onLoad: false });
    const [{ isLoading: isRemoveLoading, isError: isRemoveError, response: removeResponse }, removeRestaurantAPICall] = useAPICall(removeRestaurantAPI, { onLoad: false });
    var resource = '';
    var actions = '';
    const { pathname } = useLocation();

    eventBus.$on('FailedToRestaurants', (data) => FailedToRestaurants(data));
    function FailedToRestaurants(data) {
        setMassage('Failed to Add New Restaurant');
        setShowErrorSnackbar(true);
        setTimeout(() => {
            setShowErrorSnackbar(false);
        }, 3000);
    }

    eventBus.$on('IsSuccessToRestaurants', (data) => IsSuccessToRestaurants(data));
    function IsSuccessToRestaurants(data) {
        setMassage('New Restaurant Added Successfully');
        setShowSnackbar(true);
        setTimeout(() => {
            setShowSnackbar(false);
        }, 3000);
    }

    eventBus.$on('onclickchangePages', (data) => OnclickchangePages(data));
    function OnclickchangePages(data) {
        setLoading(true);
        const dataStr = data.toString() + 0
        localStorage.setItem("offset", Number(dataStr))
        setAPICall({
            payload: [{ "keyword": `${search}`, 'offset': Number(dataStr) }]
        });
    }


    useEffect(async () => {
        const queryParameters = new URLSearchParams(window.location.search)
        const keyword = queryParameters.get("keyword");
        const ThisOffsetuse = localStorage.getItem("offset");
        if (keyword) {
            setSearch(keyword);
            setLoading(true);
        } else {
            setLoading(true);
            await setAPICall({
                payload: [{ "keyword": `${search}`, 'offset': ThisOffsetuse }]
            })
        }
    }, []);

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const keyword = queryParameters.get("keyword");
        if (!keyword) {
            window.history.pushState('data', 'title', `${pathname}`);
        }
    }, [search]);

    useEffect(() => {
        eventBus.$emit("OnSetBlur", loading);
        loading === true ? setLoaderClass({ opacity: '0.6', borderRadius: '13px' }) : setLoaderClass({})
    }, [loading])

    useEffect(() => {
        if (isRemoveLoading) {
            return () => null;
        }

        if (isRemoveError) {
            setAlert(false);
            setMassage('Failed To Remove Restaurant');
            setShowErrorSnackbar(true);
            setTimeout(() => {
                setShowErrorSnackbar(false);
            }, 3000);
            setLoadingCircul(true);
            return () => null;
        }

        if (removeResponse && removeResponse.data.isSuccess === true) {
            const ThisOffsetuse = localStorage.getItem("offset");
            setMassage('Restaurant Removed Successfully');
            setShowSnackbar(true);
            setTimeout(() => {
                setShowSnackbar(false);
            }, 4000);
            setLoading(true);
            setAPICall({
                payload: [{ "keyword": `${search}`, 'offset': ThisOffsetuse }]
            })
            // navigate('/accountants/list');
        }
    }, [removeResponse]);


    useEffect(() => {
        const ThisOffsetuse = localStorage.getItem("offset");
        const timeOutId = setTimeout(async () =>
            await setAPICall({
                payload: [{ "keyword": `${search}`, 'offset': ThisOffsetuse }]
            })
            , 500);
        return () => clearTimeout(timeOutId);
    }, [search]);

    const warningWithConfirmMessage = () => {
        setAlert(true);
    };
    const successDelete = async () => {
        setLoading(true);
        setLoadingCircul(true);
        setTimeout(() => {
            setAlert(false);
        }, 2000);
        await removeRestaurantAPICall({
            payload: [{ "restaurantId": restaurantId }]
        })
    };

    const hideAlert = () => {
        setAlert(false);
    };

    useEffect(() => {
        if (isLoading) {
            return () => null;
        }
        if (isError) {
            setLoadingCircul(false);
            setLoading(false);
            response?.code === "DATA401" ? navigate('/client/logout') : console.log("somting else");
            return () => null;
        }
        if (response && response?.data?.isSuccess === true) {
            setTableEmpty(response?.data?.data?.totalRecords);
            const data = response?.data?.data?.records.map((prop, key) => {
                return {
                    snum: (key + 1),
                    restaurantsName: (
                        < MDBox >
                            <MDTypography
                                variant="caption" fontWeight="regular" fontSize="0.875rem" color="info"
                                sx={{ cursor: "pointer" }}
                                onClick={async (value) => {
                                    navigate(`/client/inventory/${prop?._id}/list`)
                                }}
                            >
                                {prop?.name ? prop?.name : '-'}
                            </MDTypography>
                        </MDBox >
                    ),
                    clientName: prop?.business?.name ? prop?.business?.name : '-',
                    outOfStock: prop?.totalOutOfStock,
                    lowStock: prop?.totalLowStock,
                    undarPar: prop?.totalUnderPar,
                    par: prop?.totalPar,
                    action: (
                        <MDBox display="flex" justifyContent="center">
                            {/* <Link to={{ pathname: `/client/restaurant/${prop?._id}/edit-restaurant`, state: { fromDashboard: true } }} > */}
                            <MDTypography
                                variant="body2"
                                color="secondary"
                                sx={{ marginLeft: "-4px" }}
                            >
                                <Tooltip title={action.edittooltip} sx={{
                                    cursor: 'pointer'
                                }} placement="top"
                                    onClick={(value) => {
                                        setOpenEditRestarant(true);
                                        setRestaurantId(prop?._id);
                                    }}
                                >
                                    <Icon>edit</Icon>
                                </Tooltip>
                            </MDTypography>
                            {/* </Link> */}
                            <MDTypography variant="body2" color="secondary" sx={{ cursor: 'pointer', marginLeft: "8px" }}>
                                <Tooltip title={action.deletetooltip} placement="top"
                                    onClick={(value) => {
                                        warningWithConfirmMessage();
                                        setRestaurantId(prop?._id);
                                    }}
                                >
                                    <Icon>delete</Icon>
                                </Tooltip>
                            </MDTypography>
                        </MDBox>
                    )
                };
            }
            );
            setRestaurantList(data);
            setLoading(false);
            setLoadingCircul(false);
        }
    }, [response]);

    const restaurantsList = {
        columns: [
            {
                Header: "Restaurant Name",
                accessor: "restaurantsName",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Business Name",
                accessor: "clientName",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Out of Stock",
                accessor: "outOfStock",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Low Stock",
                accessor: "lowStock",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "under Par",
                accessor: "undarPar",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Par",
                accessor: "par",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Action",
                accessor: "action",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
        ],

        rows: restaurantList
    };
    const handleSearch = async (event) => {
        localStorage.setItem("offset", 0);
        setLoadingCircul(true);
        window.history.pushState('data', 'title', `${pathname}?keyword=${encodeURIComponent(event.target.value)}`);
        setSearch(event.target.value);
        // setLoading(true);
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
        }
    }

    const handleCloseAddRestaurant = async () => {
        setOpenOpenAddNewRestarant(false);
        const ThisOffsetuse = localStorage.getItem("offset");
        await setAPICall({
            payload: [{ "keyword": `${search}`, 'offset': ThisOffsetuse }]
        })
    }
    const handleCloseEditRestaurant = async () => {
        const ThisOffsetuse = localStorage.getItem("offset");
        setOpenEditRestarant(false);
        await setAPICall({
            payload: [{ "keyword": `${search}`, 'offset': ThisOffsetuse }]
        })
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={4}>
                <Card sx={loaderClass}>
                    {!isMobile ? <MDBox pt={3} px={2} sx={{ pr: 3, mb: 2 }} component="form" display="flex" justifyContent="space-between" alignItems="center">
                        <MDTypography variant="h5" fontWeight="medium">
                            Restaurants
                        </MDTypography>
                        <MDBox px={2} display="flex" >
                            <MDBox sx={{ pr: 3, mb: 2 }}>
                                <MDInput type="search" label="Search Restaurant" onKeyDown={handleKeyDown} variant="standard" onChange={handleSearch} />
                            </MDBox>
                            {canAccess(actions = 'create', resource = 'restaurants') &&
                                <MDBox>
                                    {/* component={NavLink} to="/client/restaurant/new-restaurant" */}
                                    <MDButton
                                        variant="gradient"
                                        color="info"
                                        onClick={() => setOpenOpenAddNewRestarant(true)}
                                        disabled={loading}
                                    > Add New Restaurant
                                    </MDButton>
                                </MDBox>
                            }
                        </MDBox>
                    </MDBox> :
                        <MDBox pt={2} px={2}>
                            <MDBox mt={1} display="flex" justifyContent='space-between' >
                                <MDTypography variant="h5" fontWeight="medium">
                                    Restaurants
                                </MDTypography>
                                <MDBox display='flex' sx={{
                                    mb: 1, '@media(max-width: 440px)': {
                                        marginLeft: "-20px"
                                    }
                                }}>
                                    {canAccess(actions = 'create', resource = 'restaurants') &&
                                        <MDBox ml={6} mr={1} >
                                            <img onClick={() => setOpenOpenAddNewRestarant(true)} width="25" height="25" style={{ mt: 1.3 }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAM0lEQVR4nGNgGC4gC4qpBq5C8aiBQz0Ms5A0ouMfUIxLPosuBg7+MMQHRg0chMUXw4ABAOOQLyG/lpv1AAAAAElFTkSuQmCC" title="Add Restaurant" ></img>
                                        </MDBox>}
                                    <MDBox display="flex" >
                                        <MDBox mt={-1.2}>
                                            <input variant="standard" type="search" class="search-field" onKeyDown={handleKeyDown} onChange={handleSearch} placeholder="Search" title="Search Restaurant" />
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                        </MDBox>}
                    <MDBox>
                        {loadingCircul && (
                            <CircularProgress
                                size={50}
                                sx={{
                                    color: green[500],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </MDBox>
                    <MDBox>
                        <MDSnackbar
                            color="success"
                            icon="done"
                            title="success"
                            content={massage ? massage : 'Restaurant Added Successfully'}
                            // autoHideDuration={3000}
                            open={showSnackbar}
                            close={() => setShowSnackbar(false)}
                        />
                        <MDSnackbar
                            color="error"
                            icon="warning"
                            title="Failed."
                            content={massage ? massage : 'Failed to Add New Restaurant'}
                            // autoHideDuration={3000}
                            open={showErrorSnackbar}
                            close={() => setShowErrorSnackbar(false)}
                        />
                    </MDBox>
                    <MDBox>
                        <Dialog
                            open={alert}
                            onClose={hideAlert}
                            aria-labelledby="alert-dialog-title"
                            sx={{
                                "& .MuiDialog-container": {
                                    "& .MuiPaper-root": {
                                        width: "100%",
                                        maxWidth: "500px",
                                    },
                                },
                            }}
                        >
                            <DialogContent>
                                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}  >
                                    <MDTypography
                                        variant="body2"
                                        color="secondary"
                                        sx={{ fontSize: 50 }}
                                    >
                                        <WarningAmberIcon sx={{ color: yellow[500], fontSize: 50 }} />
                                    </MDTypography>

                                </DialogContentText>
                                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                                    <h3>Are you sure?</h3>
                                </DialogContentText>
                                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                                    <MDTypography
                                        variant="body2"
                                        color="secondary"
                                        sx={{ fontWeight: 300, fontSize: "0.90rem", textAlign: "center" }}
                                    >
                                        If deleted, you will not be able to recover this records.
                                    </MDTypography>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions sx={{ justifyContent: "center" }} >
                                <MDButton
                                    variant="gradient"
                                    color="success"
                                    onClick={successDelete} >
                                    Yes, delete it!
                                </MDButton>
                                <MDButton
                                    variant="gradient"
                                    color="error"
                                    onClick={hideAlert}
                                >Cancel
                                </MDButton>
                            </DialogActions>
                        </Dialog>
                    </MDBox>
                    <MDBox>
                        <Dialog
                            open={openAddNewRestarant}
                            onClose={() => setOpenOpenAddNewRestarant(false)}
                            aria-labelledby="alert-dialog-title"
                            sx={{
                                "& .MuiDialog-container": {
                                    "& .MuiPaper-root": {
                                        width: "100%",
                                        maxWidth: "570px",
                                        minHeight: "30%"
                                    },
                                },
                            }}
                        >   <DialogTitle sx={{
                            justifyContent: "center",
                            display: 'flex'
                        }}>Add New Restaurant</DialogTitle>
                            <DialogContent>
                                <AddNewRestaurant onClose={handleCloseAddRestaurant} />
                            </DialogContent>
                        </Dialog>
                    </MDBox>
                    <MDBox>
                        <Dialog
                            open={openEditRestarant}
                            onClose={() => setOpenEditRestarant(false)}
                            aria-labelledby="alert-dialog-title"
                            sx={{
                                "& .MuiDialog-container": {
                                    "& .MuiPaper-root": {
                                        width: "100%",
                                        maxWidth: "570px",
                                        minHeight: "30%"
                                    },
                                },
                            }}
                        >   <DialogTitle sx={{
                            justifyContent: "center",
                            display: 'flex'
                        }}>Edit Restaurant</DialogTitle>
                            <DialogContent>
                                <EditRestaurant restaurantId={restaurantId} onClose={handleCloseEditRestaurant}
                                />
                            </DialogContent>
                        </Dialog>
                    </MDBox>
                    <MDBox my={5} sx={{ mt: 1 }}>
                        <DataTable1 table={restaurantsList} entriesPerPage={false} pagination={true} showTotalEntries={true} response={tableEmpty} showCheck={false} ClientHeigth={true} />
                    </MDBox>
                </Card>
                <MDBox display="flex" justifyContent="center" >
                    {loading && <Loader />}
                </MDBox>
            </MDBox >
        </DashboardLayout >
    );
}

export default UserHoc(Restaurant);