// ProductsList page components
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Chip } from "@mui/material";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";

import { Link } from "react-router-dom";
import { useState } from "react";
// NewUser layout schemas for form and form feilds
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
const date_diff_indays = function (date2) {
    const dt1 = new Date();
    const dt2 = new Date();
    return Math.floor(
        (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
            Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
        (1000 * 60 * 60 * 24)
    );
};
const action = { route: "", edittooltip: "Edit", deletetooltip: "Delete", billingtooltip: "Billing" };
const ontrial = `Trial:${date_diff_indays(Date.now())}days`;

const dataTableData = {
    columns: [
        {
            Header: "Client Business Name",
            accessor: "business",
            disableSortBy: true,
            Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
            Header: "Full Name",
            accessor: "firstName",
            Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
            Header: "Email Address",
            accessor: "email",
            disableSortBy: true,
            Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
            Header: "Phone Number",
            accessor: "phone",
            Cell: ({ value }) => <DefaultCell value={value} />,
        },
        { Header: "Total Restaurants", accessor: "totalrestaurants", Cell: ({ value }) => <DefaultCell value={value} /> },
        { Header: "Total Users", accessor: "totalusers", Cell: ({ value }) => <DefaultCell value={value} /> },
        { Header: "Monthly Cost", accessor: "monthlycost", Cell: ({ value }) => <DefaultCell value={value} /> },
        {
            Header: "Status",
            accessor: "switch",
            Cell: ({ value }) => <DefaultCell value={value} />,
        },
        { Header: "Created Date", accessor: "createdat", Cell: ({ value }) => <DefaultCell value={value} /> },
        {
            Header: "Action", accessor: "action", Cell: ({ value }) => <DefaultCell value={value} />
        },
    ],

    rows: [
        {
            id: "#10421",
            firstName: "Orlando Imieto",
            business: "Imieto orific",
            email: "Orlando@mailinator.com",
            phone: "8436331724",
            createdat: "30/11/2022",
            totalaccountants: "10",
            totalrestaurants: "2",
            totalclients: "4",
            monthlycost: "$400",
            switch: (
                <MDBox>
                    <MDTypography
                        variant="caption" fontWeight="regular" fontSize="0.875rem" color="success"
                    >
                        Active
                    </MDTypography>
                    <MDBox sx={{
                        width: '100%', display: 'flex',
                        justifyContent: 'center', fontSize: "1.20rem"
                    }}>

                        <Chip sx={{ marginLeft: "3px", fontSize: '0.6125rem', height: '17px', color: '#ffffff' }} label={ontrial} color="warning" />
                    </MDBox>
                </MDBox>
            ),
            action: (
                <MDBox display="flex">
                    <Link to={{ pathname: "/accountants/edit-accountant", state: { fromDashboard: true } }} >
                        <MDTypography
                            variant="body2"
                            color="secondary"
                            sx={{ marginLeft: "-4px" }}
                        >
                            <Tooltip title={action.edittooltip} placement="top">
                                <Icon>edit</Icon>
                            </Tooltip>
                        </MDTypography>
                    </Link>
                    <MDTypography variant="body2" color="secondary" sx={{ marginLeft: "8px" }}>
                        <Tooltip title={action.deletetooltip} placement="top"
                            onClick={(value) => {
                                warningWithConfirmMessage();
                            }}
                        >
                            <Icon>delete</Icon>
                        </Tooltip>
                    </MDTypography>
                </MDBox>
            )

        },
        {
            id: "#10422",
            firstName: "Alice ",
            business: "Murinho jQuery ",
            email: "Alice@mailinator.com",
            phone: "9993298123",
            createdat: "20/11/2022",
            totalaccountants: "11",
            totalrestaurants: "4",
            totalclients: "10",
            monthlycost: "$350",
            switch: (
                <MDBox>
                    <MDTypography
                        variant="caption" fontWeight="regular" fontSize="0.875rem" color="success"
                    >
                        Active
                    </MDTypography>
                    <MDBox sx={{
                        width: '100%', display: 'flex',
                        justifyContent: 'center', fontSize: "1.20rem"
                    }}>

                        <Chip sx={{ marginLeft: "3px", fontSize: '0.6125rem', height: '17px', color: '#ffffff' }} label={ontrial} color="warning" />
                    </MDBox>
                </MDBox>
            ),
            action: (
                <MDBox display="flex">
                    <MDTypography
                        variant="body2"
                        color="secondary"
                    >
                        <Tooltip title={action.edittooltip} placement="top">
                            <Icon>edit</Icon>
                        </Tooltip>
                    </MDTypography>
                    <MDTypography variant="body2" color="secondary">
                        <Tooltip title={action.deletetooltip} placement="top">
                            <Icon>delete</Icon>
                        </Tooltip>
                    </MDTypography>
                </MDBox>
            )
        },
        {
            id: "#10423",
            firstName: "Michael ",
            business: "Mirra knowledge ",
            email: "Michael@mailinator.com",
            phone: "1234567898",
            createdat: "29/11/2022",
            totalaccountants: "15",
            totalclients: "9",
            totalrestaurants: "6",
            monthlycost: "$900",
            switch: (
                <MDBox>
                    <MDTypography
                        variant="caption" fontWeight="regular" fontSize="0.875rem" color="success"
                    >
                        Active
                    </MDTypography>
                    <MDBox sx={{
                        width: '100%', display: 'flex',
                        justifyContent: 'center', fontSize: "1.20rem"
                    }}>

                        <Chip sx={{ marginLeft: "3px", fontSize: '0.6125rem', height: '17px', color: '#ffffff' }} label={ontrial} color="warning" />
                    </MDBox>
                </MDBox>
            ),
            action: (
                <MDBox display="flex">
                    <MDTypography
                        variant="body2"
                        color="secondary"
                    >
                        <Tooltip title={action.edittooltip} placement="top">
                            <Icon>edit</Icon>
                        </Tooltip>
                    </MDTypography>
                    <MDTypography variant="body2" color="secondary">
                        <Tooltip title={action.deletetooltip} placement="top">
                            <Icon>delete</Icon>
                        </Tooltip>
                    </MDTypography>
                </MDBox>
            )
        },
        {
            id: "#10424",
            firstName: "Andrew ",
            business: "Nichel highlighted ",
            email: "Andrew@mailinator.com",
            phone: "1234456789",
            createdat: "29/11/2022",
            totalaccountants: "5",
            totalclients: "7",
            totalrestaurants: "1",
            monthlycost: "$700",
            switch: (
                <MDBox>
                    <MDTypography
                        variant="caption" fontWeight="regular" fontSize="0.875rem" color="error"
                    >
                        Inactive
                    </MDTypography>
                    <MDBox sx={{
                        width: '100%', display: 'flex',
                        justifyContent: 'center', fontSize: "1.20rem"
                    }}>
                        <Chip sx={{ marginLeft: "3px", fontSize: '0.6125rem', height: '17px', color: '#ffffff' }} label={ontrial} color="warning" />
                    </MDBox>
                </MDBox>
            ),
            action: (
                <MDBox display="flex">
                    <MDTypography
                        variant="body2"
                        color="secondary"
                    >
                        <Tooltip title={action.edittooltip} placement="top">
                            <Icon>edit</Icon>
                        </Tooltip>
                    </MDTypography>
                    <MDTypography variant="body2" color="secondary">
                        <Tooltip title={action.deletetooltip} placement="top">
                            <Icon>delete</Icon>
                        </Tooltip>
                    </MDTypography>
                </MDBox>
            )
        },
        {
            id: "#10425",
            firstName: "Sebastian ",
            business: "Koga information",
            email: "Sebastian@mailinator.com",
            phone: "837888333",
            createdat: "29/11/2022",
            totalaccountants: "20",
            totalrestaurants: "7",
            totalclients: "6",
            monthlycost: "$200",
            switch: (
                <MDBox>
                    <MDTypography
                        variant="caption" fontWeight="regular" fontSize="0.875rem" color="success"
                    >
                        Active
                    </MDTypography>
                    <MDBox sx={{
                        width: '100%', display: 'flex',
                        justifyContent: 'center', fontSize: "1.20rem"
                    }}>

                        <Chip sx={{ marginLeft: "3px", fontSize: '0.6125rem', height: '17px', color: '#ffffff' }} label={ontrial} color="warning" />
                    </MDBox>
                </MDBox>
            ),
            action: (
                <MDBox display="flex">
                    <MDTypography
                        variant="body2"
                        color="secondary"
                    >
                        <Tooltip title={action.edittooltip} placement="top">
                            <Icon>edit</Icon>
                        </Tooltip>
                    </MDTypography>
                    <MDTypography variant="body2" color="secondary">
                        <Tooltip title={action.deletetooltip} placement="top">
                            <Icon>delete</Icon>
                        </Tooltip>
                    </MDTypography>
                </MDBox>
            )
        },
    ],
};

export default dataTableData;