import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import NumberFormat from "react-number-format";

export const handleValueChange = (name, setFieldValue) => (val) =>
    setFieldValue(name, val.floatValue);

const NumberFieldText = ({ currencySymbol, ...props }) => {
    const [displayValue, setDisplayValue] = useState();
    return (
        <NumberFormat
            customInput={TextField}
            variant="standard"
            isNumericString={true}
            value={displayValue}
            fixedDecimalScale={true}
            sx={{ color: '#000' }}
            onValueChange={(vals) => setDisplayValue({ value: vals.formattedValue })}
            InputProps={{
                style: { color: '#000' }
            }}
            {...props}
        />
    );
};


export default NumberFieldText;
