import * as React from "react";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import Icon from "@mui/material/Icon";
import MDSnackbar from "components/MDSnackbar";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import { alpha } from '@mui/material/styles';
import DashboardLayout from "../../../clientuser/user/components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../clientuser/user/components/Navbars/DashboardNavbar";
import DataTable from "../../../components/Tables/DocTable";
import DataTable1 from "layouts/accountants/user/invoice/invoiceTable.js"
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useAPICall } from "../../../../utils/useapicall";
import { GetInvoiceList, GetStatusCount, MoveMenuInvoice, DeleteInvoice, GetIssueList, GetInvoiceDetails } from "../../../../services/clientservices";
import { GetuserClientList } from "../../../../services/clientservices";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import { eventBus } from 'utils/eventbus';
import CircularProgress from '@mui/material/CircularProgress';
import { green, yellow } from '@mui/material/colors';
import UserHoc from 'hoc/userHoc';
import NewInvoiceDialog from "./newinvoicedialog";
import EditInvoiceDialog from "./editinvoicedialog";
import ErrorEditInvoiceDialog from "./erroreditinvoicedialog";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { canAccess } from '../../../../helpers';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import LoaderPng from "assets/images/loders/Loading-screen-smalls.gif"
import Loader from "layouts/accountants/user/components/imgLoader/loader";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function AccountantInvoice() {
    const classes = useStyles();
    const [value, setValue] = useState("AskClient");
    const navigate = useNavigate();
    const action = { Movetooltip: "Move", showtooltip: "View", edittooltip: "Edit", deletetooltip: "Delete" };
    const [InvoiceList, setInvoiceList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tableEmpty, setTableEmpty] = useState("");
    const [search, setSearch] = useState('');
    const [checksynced, setChecksynced] = useState(false);
    const [reloadApi, setReloadApi] = useState(0);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showMoveSnackbar, setShowMoveSnackbar] = useState(false);
    const [showDeleteSnackbar, setShowDeleteSnackbar] = useState(false);
    const [showErrorMoveSnackbar, setShowErrorMoveSnackbar] = useState(false);
    const [showErrorDeleteSnackbar, setShowErrorDeleteSnackbar] = useState(false);
    const [moveErrorMessage, setMoveErrorMessage] = useState('');
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [openErrorEditInvoice, setErrorOpenEditInvoice] = useState(false);
    const [showSelectBusiness, setShowSelectBusiness] = useState(false);
    const [accounatArray, setAccounatArray] = useState([]);
    const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetInvoiceList, { onLoad: false });
    const [{ isLoading: isCountLoading, isError: isCountError, response: CountResponse }, CountUserAPICall] = useAPICall(GetStatusCount, { onLoad: false });
    const [{ isLoading: isClientLoading, isError: isClientError, response: ClientResponse }, ClientUserAPICall] = useAPICall(GetuserClientList, { onLoad: false });
    const [openInvoice, setOpenInvoice] = useState(false);
    const [openEditInvoice, setOpenEditInvoice] = useState(false);
    const [countAll, setCountAll] = useState(0);
    const [countApproved, setCountApproved] = useState(0);
    const [countAskClient, setCountAskClient] = useState(0);
    const [countClassify, setCountClassify] = useState(0);
    const [countExcluded, setCountExcluded] = useState(0);
    const [countPendingApproval, setCountPendingApproval] = useState(0);
    const [countToReview, setCountToReview] = useState(0);
    const [invoiceId, setInvoiceId] = useState("");
    const [invoiceIssueId, setInvoiceIssueId] = useState();
    const [showrowsData, setShowrowsData] = useState([]);
    const [autoCompleteObj, setAutoCompleteObj] = useState([]);
    const [showselectedrowsData, setShowSelectedrowsData] = useState("");
    const [clientData, setClientData] = useState([]);
    const [businessId, setBusinessId] = useState(location.pathname.split("/").slice(-1)[0]);
    const [onChangeBusinessId, setOnchageBusinessId] = useState("");
    const [openSelectInvoice, setOpenSelectInvoice] = useState(false);
    const [openNoInvoice, setOpenNoInvoice] = useState(false);
    const [clientEvent, setClientEvent] = useState('');
    const [clientNewValue, setClientNewValue] = useState('');
    const [selectTab, setSelectTab] = useState('AskClient');
    const [SelectedRows, setSelectedRows] = useState([]);
    const [issueList, setIssueList] = useState([]);
    const [selectUseEffect, setSelectUseEffect] = useState();
    const [alertDeleteInvoice, setAlertDeleteInvoice] = useState(false);
    const [alertMoveInvoice, setalertMoveInvoice] = useState(false);
    const [alertIssueInvoice, setalertIssueInvoice] = useState(false);
    const [tabsMove, setTabsMove] = useState('');
    const [showCompleteSnackbar, setShowCompleteSnackbar] = useState(false);
    const [reCallApi, setReCallApi] = useState(true);
    const [completemassage, setCompletemassage] = useState('');
    const [titleShow, setTitleShow] = useState('success');
    const [iconShow, setIconShow] = useState('done');
    const [documentStatusId, setdocumentStatusId] = useState('');
    const [selectedInvoice, setSelectedInvoice] = useState(0);
    const [StoreInvoiceNumber, setStoreInvoiceNumber] = useState('');
    const [storeResponce, setStoreResponce] = useState({});
    const [invalidDocumnet, setInvalidDocumnet] = useState(false);
    const [loaderClass, setLoaderClass] = useState({});
    const [loadingCircul, setLoadingCircul] = useState(false);
    const [{ isLoading: isDetailsLoading, isError: isDetailsError, response: DetailsResponse }, DetailsUserAPICall] = useAPICall(GetInvoiceDetails, { onLoad: false });
    const [{ isLoading: isDeleteInvoiceLoading, isError: isDeleteInvoiceError, response: DeleteInvoiceResponse }, DeleteInvoiceListAPICall] = useAPICall(DeleteInvoice, { onLoad: false });
    const [{ isLoading: isMoveMenuLoading, isError: isMoveMenuError, response: MoveMenuResponse }, MoveMenuListAPICall] = useAPICall(MoveMenuInvoice, { onLoad: false });
    const [{ isLoading: isIssueLoading, isError: isIssueError, response: IssueResponse }, IssueListAPICall] = useAPICall(GetIssueList, { onLoad: false });
    const [searchParams, setSearchParams] = useSearchParams();
    var resource = '';
    var actions = '';
    const { pathname } = useLocation();

    useEffect(async () => {
        const queryParameters = new URLSearchParams(window.location.search)
        const keyword = queryParameters.get("keyword");
        const ThisOffsetuse = localStorage.getItem("offset");
        if (keyword) {
            setSearch(keyword);
            setLoading(true);
        } else {
            if (businessId !== ':id') {
                setLoading(true);
                await setAPICall({
                    payload: [{ "businessId": businessId, "documentStatus": selectTab, "keyword": `${search}`, 'offset': ThisOffsetuse }]
                })
            } else {
                setOpenSelectInvoice(true);
            }
        }
    }, []);

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const keyword = queryParameters.get("keyword");
        if (!keyword) {
            window.history.pushState('data', 'title', `${pathname}`);
        }
    }, [search]);

    useEffect(() => {
        eventBus.$emit("OnSetBlur", loading);
        loading === true ? setLoaderClass({ opacity: '0.6', borderRadius: '13px' }) : setLoaderClass({})
    }, [loading]);

    useEffect(() => {
        const urlParams = new URL(window.location.href).searchParams;
        const DocId = urlParams.get('id');
        if (DocId) {
            setLoading(true);
            DetailsUserAPICall({
                payload: [{ "documentId": `${DocId}`, "businessId": `${businessId}` }]
            })
            setInvoiceId(DocId);
        }
    }, []);

    const removeQueryParams = () => {
        const param = new URL(window.location.href).searchParams;
        if (param) {
            searchParams.delete('id');
            setSearchParams(searchParams);
        }
    };

    useEffect(() => {
        if (isDetailsLoading) {
            return () => null;
        }

        if (isDetailsError) {
            removeQueryParams();
            setInvalidDocumnet(true);
            setTimeout(() => {
                setInvalidDocumnet(false);
            }, 5000);
            return () => null;
        }

        if (DetailsResponse && DetailsResponse.data.isSuccess === true) {
            setOpenEditInvoice(true);
        }
    }, [DetailsResponse])

    useEffect(() => {
        if (isIssueLoading) {
            return () => null;
        }
        if (isIssueError) {
            setLoading(false);
            return () => null;
        }
        if (IssueResponse && IssueResponse.data.isSuccess === true) {
            setIssueList(IssueResponse.data.data.records);
            setalertIssueInvoice(true);
            setLoading(false);
        }
    }, [IssueResponse]);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '0',
        boxShadow: 24,
        p: 4,
    };

    function generate(element) {
        return issueList.map((values) =>
            <ListItem>
                <ListItemText
                    primary={`${values.message}`}
                />
            </ListItem>
        );
    }

    const Demo = styled('div')(({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
    }));

    useEffect(() => {
        if (isMoveMenuLoading) {
            return () => null;
        }
        if (isMoveMenuError) {
            setLoading(false);
            if (response?.data?.message) {
                setMoveErrorMessage(response.data.message)
            }
            setShowErrorMoveSnackbar(true);
            setTimeout(() => {
                setShowErrorMoveSnackbar(false);
            }, 3000);
            setalertMoveInvoice(false);
            return () => null;
        }
        if (MoveMenuResponse && MoveMenuResponse.data.isSuccess === true) {
            setLoading(false);
            const ThisOffsetuse = localStorage.getItem("offset");
            setAPICall({
                payload: [{ businessId: `${businessId}`, "documentStatus": selectTab, "keyword": `${search}`, 'offset': ThisOffsetuse }]
            });
            CountUserAPICall({
                payload: [{ "businessId": `${businessId}` }]
            })
            setalertMoveInvoice(false);
            setShowMoveSnackbar(true);
            setTimeout(() => {
                setShowMoveSnackbar(false);
            }, 3000);
        }
    }, [MoveMenuResponse]);

    useEffect(() => {
        if (isDeleteInvoiceLoading) {
            return () => null;
        }
        if (isDeleteInvoiceError) {
            setLoading(false);
            if (response?.data?.message) {
                setDeleteErrorMessage(response.data.message)
            }
            setShowErrorDeleteSnackbar(true);
            setTimeout(() => {
                setShowErrorDeleteSnackbar(false);
            }, 3000);
            setAlertDeleteInvoice(false);
            return () => null;
        }
        if (DeleteInvoiceResponse && DeleteInvoiceResponse.data.isSuccess === true) {
            const ThisOffsetdelete = localStorage.getItem("offset");
            setAPICall({
                payload: [{ businessId: `${businessId}`, "documentStatus": selectTab, "keyword": `${search}`, 'offset': ThisOffsetdelete }]
            });
            CountUserAPICall({
                payload: [{ "businessId": `${businessId}` }]
            })
            setLoading(false);
            setAlertDeleteInvoice(false);
            setShowDeleteSnackbar(true);
            setTimeout(() => {
                setShowDeleteSnackbar(false);
            }, 3000);
        }
    }, [DeleteInvoiceResponse]);

    const [anchor, setAnchor] = useState(null);
    const [selected, setSelected] = useState(-1);

    const openMenu = (event) => {
        setAnchor(event.currentTarget);
    };
    const closeAfterMove = async () => {
        setOpenEditInvoice(false);
        setLoading(true);
        const ThisOffsetuse = localStorage.getItem("offset");
        await setAPICall({
            payload: [{ "businessId": businessId, "documentStatus": selectTab, "keyword": `${search}`, 'offset': ThisOffsetuse }]
        })
        await CountUserAPICall({
            payload: [{ "businessId": `${businessId}` }]
        })
    }
    const closeMenu = () => {
        setAnchor(null);
    };

    const onMenuItemClick = async (event, index) => {
        setAnchor(null);
        setSelected(index);
        var tabs = '';
        if (value === 1) {
            if (index === 0) {
                var tabs = 'ToReview';
            } else if (index === 1) {
                var tabs = 'PendingApproval';
            } else if (index === 2) {
                var tabs = '';
            }
        } else {
            if (index === 1) {
                var tabs = '';
            } else if (index === 0) {
                var tabs = 'PendingApproval';
            }
        }


        setTabsMove(tabs);
        setalertMoveInvoice(true);
        // setLoading(true);

    };

    var tabRowsData = [];

    if (value === 1) {
        var tabRowsData = [
            'To Review',
            'Pending Approval',
        ];
    } else {
        var tabRowsData = [
            'Pending Approval',
        ];

    }

    const moveOptionName = {
        'ToReview': 'To Review',

    }
    useEffect(() => {
        const ThisOffsetuse = localStorage.getItem("offset");
        if (businessId !== ':id') {
            const timeOutId = setTimeout(async () =>
                await setAPICall({
                    payload: [{ "businessId": businessId, "documentStatus": selectTab, "keyword": `${search}`, 'offset': ThisOffsetuse }]
                }), 500);
            return () => clearTimeout(timeOutId);
        }
    }, [search]);

    useEffect(async () => {
        const ThisOffsetuse = localStorage.getItem("offset");
        if (onChangeBusinessId) {
            await setAPICall({
                payload: [{ businessId: `${onChangeBusinessId}`, "documentStatus": selectTab, "keyword": `${search}`, 'offset': ThisOffsetuse }]
            });
            await CountUserAPICall({
                payload: [{ "businessId": `${onChangeBusinessId}` }]
            })
            setBusinessId(onChangeBusinessId);
            setLoading(true);
            setValue(selectTab);
            navigate(`/client/document/${onChangeBusinessId}`);
        }

    }, [onChangeBusinessId]);

    eventBus.$on('refreshListAfterUpdate', (data) => RefreshListAfterUpdate(data));
    function RefreshListAfterUpdate(data) {
        const ThisOffsetuse = localStorage.getItem("offset");
        setLoading(false);
        setAPICall({
            payload: [{ businessId: `${businessId}`, "documentStatus": selectTab, "keyword": `${search}`, 'offset': ThisOffsetuse }]
        });
        CountUserAPICall({
            payload: [{ "businessId": `${businessId}` }]
        })
    }

    eventBus.$on('onclickchangePage', (data) => OnclickchangePage(data));
    function OnclickchangePage(data) {
        setLoading(true);
        const dataStr = data.toString() + 0
        localStorage.setItem("offset", Number(dataStr))
        setAPICall({
            payload: [{ businessId: `${businessId}`, "documentStatus": selectTab, "keyword": `${search}`, 'offset': Number(dataStr) }]
        });
    }

    eventBus.$on('closeDeleteItemPopup', (data) => CloseInvoicesPopup(data));
    function CloseInvoicesPopup(data) {
        setOpenEditInvoice(false);
        setLoading(false);
        const ThisOffsetuse = localStorage.getItem("offset");
        setAPICall({
            payload: [{ businessId: `${businessId}`, "keyword": `${search}`, "documentStatus": selectTab, 'offset': ThisOffsetuse }]
        });
        CountUserAPICall({
            payload: [{ "businessId": `${businessId}` }]
        })
        // (data.status == 200 || data.status == 204) ? showSuccesPopup() : showErrorPopup();
        // setShowInvoicePopup(true);

    }

    eventBus.$on('closeNewInvoicePopup', (data) => fetchNewInvoices(data));


    function fetchNewInvoices(data) {
        const ThisOffsetuse = localStorage.getItem("offset");
        if (data.code === 'ERR_BAD_REQUEST') {
            setShowSnackbar(true);
            setTimeout(() => {
                setShowSnackbar(false);
            }, 3000);
            setTimeout(() => {
                setOpenInvoice(false);
            }, 1000);
        } else {
            setTimeout(() => {
                setOpenInvoice(false);
            }, 1000);
            setLoading(true);
            setAPICall({
                payload: [{ "businessId": businessId, "keyword": `${search}`, "documentStatus": selectTab, 'offset': ThisOffsetuse }]
            })
            CountUserAPICall({
                payload: [{ "businessId": businessId }]
            })
        }
        setReCallApi(true);
    }


    const handleChangeBusiness = (event, newValue) => {
        var eventValue = '';
        if (event.target.value) {
            var eventValue = event.target.value;
        } else {
            var eventValue = newValue.label;
        }
        ClientResponse.data.data.records.map((prop, key) => {
            (prop.name == eventValue) ?
                setOnchageBusinessId(prop._id)
                : ''
        })
        const arraybusinessId = showrowsData.map(obj => Object.values(obj));
        // {
        //     arraybusinessId.map((prop, key) => (
        //             (prop[1] == eventValue) ?
        //             setOnchageBusinessId(prop[0])
        //             : ''
        //     ))
        // }
        setShowSelectedrowsData(eventValue);
        setOpenSelectInvoice(false);
        setLoading(true);
        const found1 = ClientResponse?.data?.data?.records.find(obj => {
            return obj.name === eventValue;
        });
        if (found1?.syncPercent === 100) {
            setChecksynced(false)
            ClientUserAPICall({
                payload: [{ "keyword": `` }]
            })
        } else {
            setChecksynced(true)
            ClientUserAPICall({
                payload: [{ "keyword": `` }]
            })
        }
    };

    const handleClose = () => {
        setOpenInvoice(false);
    };

    const handleCloseSelect = () => {
        if (showselectedrowsData) {
            setOpenSelectInvoice(false);
        } else {
            navigate('/client/client/list');
        }
    };

    const handleChange = async (event, newValue) => {
        const ThisOffsetuse = localStorage.getItem("offset");
        setValue(newValue);
        setLoading(true);
        setSelectTab(newValue);
        await setAPICall({
            payload: [{
                "businessId": businessId, "keyword": `${search}`, "documentStatus": newValue, 'offset': ThisOffsetuse
            }]
        })
        await CountUserAPICall({
            payload: [{ "businessId": businessId }]
        })
    };

    useEffect(async () => {
        setLoading(true);
        await ClientUserAPICall({
            payload: [{ "keyword": `` }]
        })
        if (businessId !== ':id') {
            await CountUserAPICall({
                payload: [{ "businessId": businessId }]
            })
        } else {
            setOpenSelectInvoice(true);
        }
        // eventBus.on("couponApply", (data) =>
        //     this.setState({ message: data.message })
        // );
        eventBus.$on('invoiceRowSelected', (data) => {

            setSelectUseEffect(data.selectedRowIds.length);

            if (data.selectedRowIds.length) {
                setSelectedRows(data.selectedRowIds);
            }
        });
        return () => { eventBus.$off('invoiceRowSelected') };
    }, []);

    // useEffect((selectUseEffect) => {
    //     if (selectUseEffect.selectedRowIds.length) {
    //         setSelectedRows(selectUseEffect.selectedRowIds);
    //     }
    // }, [selectUseEffect])
    const [employees, setEmployees] = useState([]);

    const removeSecond = (current) => {
        current.filter(employee => {
            return employee.accountant != null;
        })
    };

    useEffect(() => {
        if (isClientLoading) {
            return () => null;
        }

        if (isClientError) {
            setLoading(false);
            setLoadingCircul(false);
            return () => null;
        }

        if (ClientResponse && ClientResponse.data.isSuccess === true) {
            if (businessId == ':id') {
                setOpenSelectInvoice(true);
                setLoading(false);
                setLoadingCircul(false);
            }
            setClientData(ClientResponse)
            setEmployees(ClientResponse?.data?.data?.records)
            const filteredUsers = ClientResponse?.data?.data?.records.filter(user => user.accountant != null);
            // const Accountingpermission = ClientResponse?.data?.data?.records.find(p => p.accountant !== null);
            // const data = Accountingpermission.map((prop, key) => {
            //     return prop
            // });
            // const finalArr = data.flat(1);
            // console.log("ClientResponse.data.data.records----->", ClientResponse.data.data.records, finalArr)
            ClientResponse.data.data.records.map((prop, key) => {
                if (businessId == ':id' && prop) {
                    // setValue(0);
                    (prop._id === businessId) ? setShowSelectedrowsData(prop.name) : ''
                    if (prop.length === 0) {
                        navigate('/client/client/list');
                        // setOpenNoInvoice(true);
                    } else if (prop.length === 1) {
                        setShowSelectBusiness(false);
                        setBusinessId(prop._id);
                        setOnchageBusinessId(prop._id);
                        setShowSelectedrowsData(prop.name);
                        navigate(`/client/document/${prop._id}`);
                    } else {
                        setShowSelectBusiness(true);
                        // setBusinessId(prop._id);
                        setOpenSelectInvoice(true);
                    }
                    // setOnchageBusinessId(array[0][0]);
                    // setShowSelectedrowsData(array[0][1]);
                    // navigate(`/client/document/${array[0][0]}`);
                }
                // array.map((prop, key) => (
                //     (prop[0] === businessId) ? setShowSelectedrowsData(prop[2]) : ''
                // ));
                const found2 = ClientResponse?.data?.data?.records.find(obj => {
                    return obj._id === onChangeBusinessId;
                });
                if (found2?.syncPercent === 100) {
                    setChecksynced(false)
                } else {
                    setChecksynced(true)
                }
            })
            const array = filteredUsers?.map(obj => Object.values(obj));
            setShowrowsData(array);
            var obj = array.map(x => ({
                label: x[1]
            }));
            if (array.length > 1) {
                setShowSelectBusiness(true);
            }
            setAutoCompleteObj(obj);
            setLoading(false);
            setLoadingCircul(false);
        }
    }, [ClientResponse]);

    useEffect(() => {
        if (isCountLoading) {
            return () => null;
        }

        if (isCountError) {
            return () => null;
        }

        if (CountResponse && CountResponse.data.isSuccess === true) {
            setCountAll(CountResponse?.data?.data?.countAll);
            setCountApproved(CountResponse?.data?.data?.countApproved);
            setCountAskClient(CountResponse?.data?.data?.countAskClient);
            setCountClassify(CountResponse?.data?.data?.countClassify);
            setCountExcluded(CountResponse?.data?.data?.countExcluded);
            setCountPendingApproval(CountResponse?.data?.data?.countPendingApproval);
            setCountToReview(CountResponse?.data?.data?.countToReview);
        }
    }, [CountResponse]);

    //stop reloadApi
    // useEffect(() => {
    //     // if (reCallApi === true) {
    //     const timeOutApi = setTimeout(async () =>
    //         await setAPICall({
    //             payload: [{ "businessId": businessId, "documentStatus": selectTab }]
    //         }), 5000);
    //     return () => clearTimeout(timeOutApi);
    //     // }

    // }, [reCallApi]);

    useEffect(() => {
        if (documentStatusId === 'ERROR') {
            setTitleShow('error');
            setIconShow('warning');
            setCompletemassage('Unable to read invoice');
        } else {
            setTitleShow('success');
            setIconShow('done');
            setCompletemassage(`Invoice Upload ${documentStatusId ? documentStatusId.charAt(0).toUpperCase() + documentStatusId.slice(1).toLowerCase() : documentStatusId
                }`);
        }
    }, [documentStatusId])

    useEffect(() => {
        if (isLoading) {
            return () => null;
        }
        if (isError) {
            return () => null;
        }
        if (response && response.data.isSuccess === true) {
            eventBus.$emit("showdocumentStatus", response?.data?.data?.records);
            setStoreResponce(response);
            setTableEmpty(response.data.data.totalRecords);
            setdocumentStatusId(response?.data?.data?.totalRecords === 0 ? 0 : response?.data?.data?.records[0]?.status);
            const data = response?.data?.data?.records?.map((prop, key) => {
                const VnadorName = prop.vendor;
                var FiVendorName = "";
                if (VnadorName == undefined) {
                    var FiVendorName = "-"
                }
                else {
                    var FiVendorName = VnadorName.name ? VnadorName.name : '-'
                }
                if (prop.ocrStatus === 'INPROGRESS' || prop.ocrStatus === 'PENDING') {
                    setReloadApi(reloadApi + 1);
                }

                return {
                    vendor: (
                        <MDBox>
                            <MDTypography
                                variant="caption" fontWeight="bold" fontSize="0.875rem"
                            >
                                {FiVendorName}
                            </MDTypography>
                        </MDBox>
                    ),
                    invoice: (
                        <MDBox>
                            <MDTypography
                                variant="caption" fontWeight="regular" fontSize="0.875rem" color="info"
                                onClick={async (value) => {
                                    setInvoiceId(prop._id);
                                    setOpenEditInvoice(true);
                                }}
                            >
                                {prop.invoice_number ? prop.invoice_number : prop.docNumber ? prop.docNumber : "-"}
                            </MDTypography>
                        </MDBox>
                    ),
                    phone: prop.phone,
                    uplaoddate: prop.createdAt,
                    status: (prop.documentSource === 'QBO' ? <MDBox>
                        <MDTypography
                            variant="caption" fontWeight="regular" fontSize="0.875rem" style={{
                                color: "#2566e8"
                            }}
                        >
                            {"Synced to Quickbooks"}
                        </MDTypography>
                    </MDBox> : prop.ocrStatus === 'COMPLETED' ?
                        <MDBox>
                            <MDTypography
                                variant="caption" fontWeight="regular" fontSize="0.875rem" color="success"
                            >
                                {'Completed'}
                            </MDTypography>
                        </MDBox> : prop.ocrStatus === 'ERROR' ?
                            <MDBox>
                                <MDTypography
                                    variant="caption" fontWeight="regular" fontSize="0.875rem" color="error"
                                >
                                    {"Error"}
                                </MDTypography>
                            </MDBox> : prop.ocrStatus === 'INPROGRESS' ?
                                <MDBox>
                                    <MDTypography
                                        variant="caption" fontWeight="regular" fontSize="0.875rem" style={{
                                            color: "#ff9800"
                                        }}
                                    >
                                        {"In Progress"}
                                    </MDTypography>
                                </MDBox> :
                                <MDBox>
                                    <MDTypography
                                        variant="caption" fontWeight="regular" fontSize="0.875rem"
                                    >
                                        {"Pending"}
                                    </MDTypography>
                                </MDBox>
                    ),
                    issue: (prop.totalIssues >= 1 ?
                        <MDBox>
                            <MDTypography
                                variant="caption" fontWeight="regular" fontSize="0.875rem" color="error"
                                sx={{ cursor: "pointer" }}
                                onClick={async (value) => {
                                    setLoading(true);
                                    setInvoiceIssueId(prop._id);
                                    IssueListAPICall({
                                        payload: [{ "businessId": `${businessId}`, "documentId": `${prop._id}` }]
                                    });
                                }}
                            >
                                {prop.totalIssues}
                            </MDTypography>
                        </MDBox> : prop.totalIssues
                    ),
                    action: (
                        prop.status === 'COMPLETED' ?
                            <MDBox>
                                <MDTypography
                                    variant="caption" fontWeight="regular" fontSize="0.875rem" color="info"
                                    onClick={async (value) => {
                                        setInvoiceId(prop._id);
                                        setOpenEditInvoice(true);
                                    }}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <Tooltip title={action.showtooltip} placement="top">
                                        <Icon fontSize="small">visibility</Icon>
                                    </Tooltip>
                                </MDTypography>
                            </MDBox>
                            :
                            <MDBox>
                                <MDTypography
                                    variant="caption" fontWeight="regular" fontSize="0.875rem" color="info"
                                    onClick={async (value) => {
                                        setInvoiceId(prop._id);
                                        setErrorOpenEditInvoice(true);
                                    }}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <Tooltip title={action.showtooltip} placement="top">
                                        <Icon fontSize="small">visibility</Icon>
                                    </Tooltip>
                                </MDTypography>
                            </MDBox>
                    ),
                    id: prop._id,

                };
            }
            );
            setInvoiceList(data);
            setLoading(false);
            setLoadingCircul(false);
        }
    }, [response]);

    //stop reload Api
    // useEffect(() => {
    //     const fetchData = async () => {
    //         await setAPICall({
    //             payload: [{ "businessId": businessId, "documentStatus": selectTab }]
    //         })
    //     };

    //     const timer = setTimeout(() => {
    //         fetchData();
    //     }, 30000);

    //     return () => clearTimeout(timer);
    // }, [reloadApi]);

    const reloadApiAfterClose = () => {
        setOpenEditInvoice(false);
        removeQueryParams();
    };

    const dataTableData = {

        columns: [
            // { Header: "Vendor", accessor: "vendor", Cell: ({ value }) => <IdCell id={value} /> },
            { Header: "Vendor", accessor: "vendor", Cell: ({ value }) => <DefaultCell value={value} /> },

            {
                Header: "Invoice",
                accessor: "invoice",
                Cell: ({ value }) => <a style={{ cursor: 'pointer', color: 'blue' }} >{value} </a>,
            },
            {
                Header: "Upload Date",
                accessor: "uplaoddate",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            // {
            //     Header: "Issue",
            //     accessor: "issue",
            //     Cell: ({ value }) => <DefaultCell value={value} />,
            // },
        ],
        rows: InvoiceList
    }


    const handleSearch = async (event) => {
        localStorage.setItem("offset", 0)
        setSearch(event.target.value);
        window.history.pushState('data', 'title', `${pathname}?keyword=${encodeURIComponent(event.target.value)}`);
        setLoadingCircul(true);
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
        }
    }


    const deleteInvoiceConfirmMessage = () => {
        setAlertDeleteInvoice(true);
    };
    const hideInvoiceAlert = () => {
        setAlertDeleteInvoice(false);
    };
    const successInoiceDelete = () => {
        DeleteInvoiceListAPICall({
            payload: [
                {
                    "documentIds": {
                        "documentIds": SelectedRows,
                    },
                    "businessId": `${businessId}`,
                }
            ]
        });
        setLoading(true);
    }

    const handleDeleteInvoice = () => {
        deleteInvoiceConfirmMessage();
    }

    const hideMoveAlert = () => {
        setalertMoveInvoice(false);
    };
    const successInoiceMove = async () => {
        await MoveMenuListAPICall({
            payload: [
                {
                    "invoiceIds": {
                        "invoiceIds": SelectedRows,
                        "documentStatus": tabsMove,
                    },
                    "businessId": `${businessId}`,
                }
            ]
        })
        setLoading(true);
    }

    useEffect(() => {
        setSelectedInvoice(SelectedRows.length);
        if (Object.keys(storeResponce).length > 0) {
            storeResponce.data.data.records.map((prop, key) => {
                if (prop._id === SelectedRows[0]) {
                    setStoreInvoiceNumber(prop.invoice_number ? prop.invoice_number : prop.docNumber);
                }
            })
        }
    }, [SelectedRows, storeResponce])

    const hideIssueAlert = () => {
        setalertIssueInvoice(false);
    };
    function EnhancedTableToolbar(props) {
        const { numSelected } = props;
        return (
            <>
                {selectUseEffect > 0 ? (
                    <Toolbar
                        sx={{
                            pl: { sm: 2 },
                            pr: { xs: 1, sm: 1 },
                            ...(numSelected > 0 && {
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                            }),
                        }}
                    >
                        {canAccess(actions = 'delete', resource = 'invoices') && selectUseEffect > 0 ? (
                            <Tooltip title="Delete">
                                <IconButton onClick={handleDeleteInvoice}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        ) : null}
                    </Toolbar>) : null}
            </>
        );
    }

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    };

    function splitCamelCaseToString(s) {
        return s.split(/(?=[A-Z])/).join(' ');
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={4}>
                <Card sx={loaderClass}>
                    <MDBox sx={{ my: 2 }} component="form" display="flex" justifyContent="space-between">
                        <MDBox mb={0} display="flex" >
                            {showSelectBusiness ?
                                <FormControl variant="standard" sx={{ pl: 3, minWidth: 150 }} fullWidth >
                                    <InputLabel sx={{ pl: 4 }} htmlFor="demo-simple-select-standard" id="demo-simple-select-standard-label">
                                        Business
                                    </InputLabel>
                                    <Select
                                        aria-required="true"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={showselectedrowsData}
                                        onChange={handleChangeBusiness}
                                        label="Select Business"
                                    >
                                        {/* <MenuItem value="" disabled>
                                        Select Client
                                    </MenuItem> */}
                                        {showrowsData.map((prop, key) => (
                                            <MenuItem
                                                value={prop[1]}
                                            >
                                                {prop[1]}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl> :
                                <MDTypography pt={3} px={2} sx={{ pr: 3, mb: 2 }} variant="h5" fontWeight="medium">
                                    Documents
                                </MDTypography>
                            }
                            {canAccess(actions = 'update', resource = 'invoices') && selectUseEffect > 0 ? (
                                <MDBox>
                                    <Tooltip title={action.Movetooltip}>
                                        <IconButton
                                            onClick={openMenu}
                                            sx={{ ml: 2, mt: 1.5 }}
                                            aria-controls={openMenu ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openMenu ? 'true' : undefined}
                                        >
                                            <DriveFileMoveIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        open={Boolean(anchor)}
                                        anchorEl={anchor}
                                        onClose={closeMenu}
                                        keepMounted
                                    >
                                        {tabRowsData.map((option, index) => (
                                            <MenuItem
                                                key={index}
                                                onClick={(event) => onMenuItemClick(event, index)}
                                                selected={index === selected}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </MDBox>

                            ) : null}
                            <EnhancedTableToolbar numSelected={SelectedRows.length} />
                        </MDBox>
                        <MDBox sx={{ pl: 3 }} display="flex" >
                            {canAccess(actions = 'update', resource = 'invoices') && selectUseEffect > 0 ? (
                                <MDBox>
                                    <Tooltip title={action.Movetooltip}>
                                        <IconButton
                                            onClick={openMenu}
                                            sx={{ ml: 2, mt: 1.5 }}
                                            aria-controls={openMenu ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openMenu ? 'true' : undefined}
                                        >
                                            <DriveFileMoveIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        open={Boolean(anchor)}
                                        anchorEl={anchor}
                                        onClose={closeMenu}
                                        keepMounted
                                    >
                                        {tabRowsData.map((option, index) => (
                                            <MenuItem
                                                key={index}
                                                onClick={(event) => onMenuItemClick(event, index)}
                                                selected={index === selected}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </MDBox>

                            ) : null}
                            <EnhancedTableToolbar numSelected={SelectedRows.length} />
                        </MDBox>

                        <MDBox px={2} display="flex" >

                        </MDBox>
                        <MDBox px={2} display="flex" >
                            <MDBox sx={{ pr: 3, mb: 2 }}>
                                <MDInput type="search" label="Search Document" onKeyDown={handleKeyDown} variant="standard" onChange={handleSearch} />
                            </MDBox>
                            {canAccess(actions = 'create', resource = 'documents') &&
                                <MDBox>
                                    <MDButton
                                        variant="gradient"
                                        color="info"
                                        disabled={checksynced || loading}
                                        onClick={() => setOpenInvoice(true)}
                                    >  Upload Documents
                                    </MDButton>
                                </MDBox>
                            }
                        </MDBox>
                    </MDBox>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ mx: '10px', borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label={`To Review (${(countAskClient)})`} value="AskClient" />
                                <Tab label={`All Documents (${(countAll)})`} value="" />
                                <Tab label={``} {...a11yProps(0)} disabled />
                                <Tab label={``} {...a11yProps(1)} disabled />
                                <Tab label={``} {...a11yProps(3)} disabled />
                                <Tab label={``} {...a11yProps(4)} disabled />
                                <Tab label={``} {...a11yProps(5)} disabled />
                                <Tab label={``} {...a11yProps(6)} disabled />
                                <Tab label={``} {...a11yProps(7)} disabled />
                                <Tab label={``} {...a11yProps(8)} disabled />
                                <Tab label={``} {...a11yProps(9)} disabled />
                            </Tabs>
                        </Box>
                        <TabPanel>
                            <MDBox>
                                <MDBox display="flex" justifyContent="center" >
                                    {loading && <Loader />}
                                </MDBox>
                                <MDBox my={5} sx={{ mt: 1 }}>
                                    <DataTable1 table={dataTableData} entriesPerPage={false} pagination={true} showTotalEntries={true} selectTab={selectTab} response={tableEmpty} showCheck={false} ClientHeigth={true} />
                                </MDBox>
                            </MDBox>
                            <MDBox>
                                {loadingCircul && (
                                    <CircularProgress
                                        size={50}
                                        sx={{
                                            color: green[500],
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </MDBox>
                        </TabPanel>
                    </Box>
                </Card>
            </MDBox>
            <MDBox>
                <MDBox>
                    <Modal
                        open={openSelectInvoice}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}  >
                            <MDBox sx={{ pl: 0, pb: 5 }} display="flex" justifyContent="center">
                                <FormControl variant="standard" sx={{ minWidth: 150, mt: 1.5 }}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        ListboxProps={{ style: { maxHeight: "15rem" }, position: "bottom-start" }}
                                        options={autoCompleteObj}
                                        sx={{ width: 300 }}
                                        onChange={(event, newValue) => {
                                            setClientNewValue(newValue);
                                            setClientEvent(event);
                                        }}

                                        renderInput={(params) =>
                                            <TextField {
                                                ...params
                                            }
                                                label="Select Business"
                                                variant="standard"
                                                sx={{
                                                    "& .MuiFormLabel-root": {
                                                        marginTop: '5px',
                                                        // marginLeft: '30%'
                                                    }
                                                }
                                                }
                                            />}
                                    />
                                </FormControl>
                            </MDBox>
                            <MDBox sx={{
                                justifyContent: "end",
                                display: "flex"
                            }}>
                                <MDButton onClick={handleCloseSelect}>Close</MDButton>
                                <MDButton variant="gradient"
                                    color="info" onClick={() => handleChangeBusiness(clientEvent, clientNewValue)}>
                                    submit
                                </MDButton>
                            </MDBox>
                        </Box>
                    </Modal>
                </MDBox>
                <MDBox>
                    <Dialog
                        fullWidth='true'
                        maxWidth='sm'
                        TransitionComponent={Transition}
                        open={openNoInvoice}
                    // onClose={handleNoClose}
                    >
                        <DialogTitle>
                            {/* <h3>Select client</h3> */}
                        </DialogTitle>
                        <DialogContentText sx={{ px: 2 }}>
                            Add a client to start managing their documnents with Paynthr.
                        </DialogContentText>

                        <DialogContent>
                            <MDBox display="flex" justifyContent='space-evenly' sx={{ mt: 3 }}>
                                <MDButton
                                    type="submit"
                                    variant="gradient"
                                    color="info"
                                    sx={{ ml: 12 }}
                                    onClick={() => navigate('/accountant/client/new-client')}
                                >
                                    Add New Client
                                </MDButton>
                                <MDButton onClick={() => navigate(-1)}>Close</MDButton>
                            </MDBox>
                        </DialogContent>
                    </Dialog>
                </MDBox>
                <MDBox>
                    <Dialog
                        fullWidth='true'
                        maxWidth='sm'
                        TransitionComponent={Transition}
                        open={openInvoice}
                        onClose={handleClose}
                    >
                        <DialogTitle align="center">
                            <h3>Upload Document</h3>
                        </DialogTitle>
                        <DialogContent >
                            <NewInvoiceDialog invoiceId={invoiceId} businessId={businessId} />
                            <DialogContentText align="center" ><span style={{ fontSize: `0.99rem` }}>Upload type must be pdf .png .jpg or .jpeg</span></DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <MDButton onClick={handleClose}>Close</MDButton>
                        </DialogActions>
                    </Dialog>
                </MDBox>
                <MDBox>
                    <Dialog
                        fullScreen
                        open={openEditInvoice}
                        onClose={() => setOpenEditInvoice(false)}
                        TransitionComponent={Transition}
                    >
                        <DialogContent>
                            <EditInvoiceDialog invoiceId={invoiceId} businessId={businessId} onClose={closeAfterMove} selectTab={selectTab} reloadApiAfterClose={reloadApiAfterClose} />
                        </DialogContent>
                    </Dialog>
                </MDBox>
                <MDBox>
                    <Dialog
                        fullScreen
                        open={openErrorEditInvoice}
                        onClose={() => setErrorOpenEditInvoice(false)}
                        TransitionComponent={Transition}
                    >
                        <Toolbar style={{ minHeight: '45px', backgroundColor: 'white' }}>
                            <Typography variant="h6" className={classes.title}>

                            </Typography>
                            <Icon sx={{ cursor: "pointer" }} onClick={() => setErrorOpenEditInvoice(false)}>close</Icon>
                        </Toolbar>
                        <DialogContent>
                            <ErrorEditInvoiceDialog invoiceId={invoiceId} businessId={businessId} />
                        </DialogContent>
                    </Dialog>
                </MDBox>
                <MDBox>
                    <MDSnackbar
                        color="error"
                        icon="warning"
                        title="Failed"
                        // autoHideDuration={3000}
                        content="Please Select Valid Invoice."
                        open={showSnackbar}
                        close={() => setShowSnackbar(false)}
                    />
                </MDBox>
                <MDBox>
                    <Dialog
                        open={alertDeleteInvoice}
                        onClose={hideInvoiceAlert}
                        aria-labelledby="alert-dialog-title"
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    // height: "50%",
                                    maxWidth: "510px",
                                },
                            },
                        }}
                    >
                        <DialogContent>
                            <DialogContentText sx={{ display: "flex", justifyContent: "center" }}  >
                                <MDTypography
                                    variant="body2"
                                    color="secondary"
                                    sx={{ fontSize: 70 }}
                                >
                                    <WarningAmberIcon sx={{ color: yellow[500], fontSize: 50 }} />
                                </MDTypography>
                            </DialogContentText>
                            <DialogContentText sx={{ mt: 8, display: "flex", justifyContent: "center" }}>
                                {selectedInvoice > 1 ? <h3> Are you sure you want to delete {selectedInvoice} invoices?</h3> : StoreInvoiceNumber ? <h3> Are you sure you want to delete invoice {StoreInvoiceNumber}? </h3> : <h3> Are you sure you want to delete this invoice? </h3>}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "center" }} >
                            <MDButton
                                variant="gradient"
                                color="success"
                                onClick={successInoiceDelete} >
                                Yes, delete it!
                            </MDButton>
                            <MDButton
                                variant="gradient"
                                color="error"
                                onClick={hideInvoiceAlert}
                            >Cancel
                            </MDButton>
                        </DialogActions>
                    </Dialog>
                </MDBox>
                <MDBox>
                    <Dialog
                        open={alertMoveInvoice}
                        onClose={hideMoveAlert}
                        aria-labelledby="alert-dialog-title"
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    // height: "50%",
                                    maxWidth: "510px",
                                },
                            },
                        }}
                    >
                        <DialogContent>
                            <DialogContentText sx={{ mt: 8, display: "flex", justifyContent: "center" }}>
                                {selectedInvoice > 1 ? <h3>Are you sure you want to move {selectedInvoice} invoices?</h3> : StoreInvoiceNumber ? <h3> Are you sure you want to move invoice {StoreInvoiceNumber}? </h3> : <h3> Are you sure you want to move this invoice? </h3>}
                            </DialogContentText>
                            <DialogContentText sx={{ display: "flex", justifyContent: "center" }}  >
                                <MDTypography
                                    variant="body1"
                                    color="info"
                                    sx={{ p: 2, justifyContent: "center", display: 'flex', flexDirection: 'column' }}
                                >
                                    {splitCamelCaseToString(selectTab.length > 0 ? selectTab : 'All Documents')}

                                </MDTypography>
                                <MDTypography
                                    variant="body2"
                                    color="#037ffd"
                                    sx={{ fontSize: 40, justifyContent: "center", display: 'flex', flexDirection: 'column' }}
                                > <DriveFileMoveIcon sx={{ color: "#037ffd", fontSize: 40 }} />

                                </MDTypography>
                                <MDTypography
                                    variant="body1"
                                    color="info"
                                    sx={{ p: 2, justifyContent: "center", display: 'flex', flexDirection: 'column' }}
                                >
                                    {splitCamelCaseToString(tabsMove)}
                                </MDTypography>
                            </DialogContentText>
                        </DialogContent>



                        <DialogActions sx={{ justifyContent: "center" }} >
                            <MDButton
                                variant="gradient"
                                color="success"
                                onClick={successInoiceMove} >
                                Yes, Move it!
                            </MDButton>
                            <MDButton
                                variant="gradient"
                                color="error"
                                onClick={hideMoveAlert}
                            >Cancel
                            </MDButton>
                        </DialogActions>
                    </Dialog>
                </MDBox>
                <MDBox>
                    <Dialog
                        open={alertIssueInvoice}
                        onClose={hideIssueAlert}
                        aria-labelledby="alert-dialog-title"
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    height: "50%",
                                    maxWidth: "500px",
                                },
                            },
                        }}
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Issue"}
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Demo>
                                        <List sx={{ mt: 4, mb: 2 }} >
                                            {generate()}
                                        </List>
                                    </Demo>
                                </Grid>

                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <MDButton onClick={hideIssueAlert}>Close</MDButton>
                        </DialogActions>
                    </Dialog>
                </MDBox>
                <MDBox>
                    <MDSnackbar
                        color="success"
                        // autoHideDuration={3000}
                        icon="done"
                        title="Success"
                        content="Document Move Successfully."
                        open={showMoveSnackbar}
                        close={() => setShowMoveSnackbar(false)}
                    />
                </MDBox>
                <MDBox>
                    <MDSnackbar
                        color="success"
                        icon="done"
                        // autoHideDuration={3000}
                        title="Success"
                        content="Document Deleted Successfully."
                        open={showDeleteSnackbar}
                        close={() => setShowDeleteSnackbar(false)}
                    />
                </MDBox>
                <MDBox>
                    <MDSnackbar
                        color="error"
                        icon="warning"
                        title="Failed"
                        content={"Invalid Document"}
                        open={invalidDocumnet}
                        close={() => setInvalidDocumnet(false)}
                    />
                </MDBox>
                <MDBox>
                    <MDSnackbar
                        color="error"
                        icon="warning"
                        title="Failed"
                        content={moveErrorMessage}
                        open={showErrorMoveSnackbar}
                        close={() => setShowErrorMoveSnackbar(false)}
                    />
                </MDBox>
                <MDBox>
                    <MDSnackbar
                        color="error"
                        icon="warning"
                        title="Failed"
                        // autoHideDuration={3000}
                        content={deleteErrorMessage}
                        open={showErrorDeleteSnackbar}
                        close={() => setShowErrorDeleteSnackbar(false)}
                    />
                </MDBox>
                <MDBox>
                    <MDSnackbar
                        color={titleShow.toLowerCase()}
                        icon={iconShow}
                        title={titleShow}
                        content={completemassage}
                        // autoHideDuration={3000}
                        open={showCompleteSnackbar}
                        close={() => setShowCompleteSnackbar(false)}
                    />
                </MDBox>
            </MDBox>
        </DashboardLayout >
    );


}
export default UserHoc(AccountantInvoice);