import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Grid from "@mui/material/Grid";
import FormControlLabel from '@mui/material/FormControlLabel';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// import { addCategoryschema } from "./components/Itemschema/addCategoryschema";
import FormField from "layouts/admin/accountants/components/FormField";
import { useAPICall } from "utils/useapicall";
import { AddNewItem, GetCustomerList, GetClassesList, GetCategoryList, GetProductsList } from "services/clientservices";
import Checkbox from '@mui/material/Checkbox';
import { eventBus } from 'utils/eventbus';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CurrencyFieldText from "../../user/components/CurrencyField-Text";
import MDTypography from "components/MDTypography";
import NumberFieldText from "layouts/accountants/user/components/NumberField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";


function AddCurrontInvetory({ invoiceId, documentId, onCloseAdd, currencySymbol, businessId, itemList, showClasses }) {
    const [invoiceIds, setInvoiceIds] = useState(invoiceId);
    const [isDisable, setBtnDisable] = useState(false);
    const [value, setValue] = useState('ItemBasedExpenseLineDetail');
    const [customerList, setCustomerList] = useState([]);
    const [description, setDescription] = useState('');
    const [unitPrice, setUnitPrice] = useState('');
    const [selectProduct, setSelectProduct] = useState(false);
    const [classesList, setClassesList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [accountingIds, setAccountingId] = useState('');
    const [productIds, setProductId] = useState('');
    const [price, setPrice] = useState(0);
    const [TotalPrice, setTotalPrice] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [classIdIds, setClassIdId] = useState('');
    const [priceerror, setPriceerror] = useState(false);
    const [customerIds, setCustomerIdId] = useState('');
    const [categoryIds, setCategoryIds] = useState('');
    const [adjustInvoice, setAdjustInvoice] = useState(false);
    const [categoryerrormsg, setCategoryerrormsg] = useState('');
    const [categoryerror, setCategoryerror] = useState(false);
    const [quantityerror, setQuantityrror] = useState(false);
    const [quantityErrorMsg, setQuantityErrorMsg] = useState('');
    const [priceErrorMsg, setPriceErrorMsg] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [showerror, setShowerror] = useState(false);
    const [showerrorqty, setShowerrorqty] = useState(false);
    const [className, setClassName] = useState('');
    const [errorButtonDis, setErrorButtonDis] = useState(false);
    const [editable, seteditable] = useState(true);
    const [addinventory, setAddinventory] = useState(false);
    const [{ isError, isLoading, response }, setAPICall] = useAPICall(AddNewItem, { onLoad: false });
    const [{ isLoading: isCustomerLoading, isError: isCustomerError, response: CustomerResponse }, CustomerListAPICall] = useAPICall(GetCustomerList, { onLoad: false });
    const [{ isLoading: isClassesLoading, isError: isClassesError, response: ClassesResponse }, ClassesListAPICall] = useAPICall(GetClassesList, { onLoad: false });
    const [{ isLoading: isCategoryLoading, isError: isCategoryError, response: CategoryResponse }, CategoryListAPICall] = useAPICall(GetCategoryList, { onLoad: false });
    const [{ isLoading: isProductsLoading, isError: isProductsError, response: ProductsResponse }, ProductsListAPICall] = useAPICall(GetProductsList, { onLoad: false });

    useEffect(() => {
        let prices = (typeof (price) == 'string') ? price.replaceAll(',', '') : price
        let quantitys = (typeof (quantity) == 'string') ? quantity.replaceAll(',', '') : quantity
        if (showerror === true) {
            if (!prices) {
                setPriceErrorMsg('Price is required');
                setPriceerror(true);
            } else if (prices.length === 0 || Number(prices) === 0) {
                setPriceErrorMsg('Price Must be more than 0');
                setPriceerror(true);
            } else {
                setPriceErrorMsg('');
                setPriceerror(false);
            }
        }

        if (prices?.length === 0 || quantitys?.length === 0 || Number(quantitys) === 0) {
            setErrorButtonDis(true);
        } else {
            setErrorButtonDis(false);
        }

        if (showerrorqty === true) {
            if (!quantitys) {
                setQuantityErrorMsg('Quantity is required');
                setQuantityrror(true);
            } else if (quantitys.length === 0 || Number(quantitys) === 0) {
                setQuantityErrorMsg('Quantity Must be more than 0');
                setQuantityrror(true);
            } else {
                setQuantityErrorMsg('');
                setQuantityrror(false);
            }
        }

        const timeOutId = setTimeout(async () =>
            setTotalPrice(Number(prices) * Number(quantitys)),
            500);
        return () => clearTimeout(timeOutId);
    }, [price, quantity]);

    useEffect(() => {
        if (price.length === 0 || Number(price) === 0.00 && quantity.length === 0 || Number(quantity) === 0) {
            setErrorButtonDis(true);
        } else {
            setErrorButtonDis(false);
        }

    }, []);

    useEffect(async () => {
        await CustomerListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
        await ClassesListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
        await CategoryListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
        await ProductsListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
    }, []);

    const initialValues = {
        product: "",
        checked: "",
        item: description,
        sku: "",
        price: unitPrice,
        total: "",
        qty: "",
        services: "",
        class: "",
        customer: "",
        unit: "",
        category: ""
    };

    useEffect(async () => {
        if (isLoading) {
            return () => null;
        }
        if (isError) {
            setBtnDisable(false);
            return () => null;
        }
        if (response && response.data.isSuccess === true) {
            eventBus.$emit("closeAddNewItemPopup", response);
            setBtnDisable(false);
        }
    }, [response]);

    useEffect(() => {
        if (isCustomerLoading) {
            return () => null;
        }
        if (isCustomerError) {
            return () => null;
        }
        if (CustomerResponse && CustomerResponse.data.isSuccess === true) {
            const data = CustomerResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    customerId: prop._id
                };
            }
            );
            setCustomerList(data);
        }
    }, [CustomerResponse]);

    useEffect(() => {
        if (isClassesLoading) {
            return () => null;
        }
        if (isClassesError) {
            return () => null;
        }
        if (ClassesResponse && ClassesResponse.data.isSuccess === true) {
            const data = ClassesResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    classId: prop._id
                };
            }
            );
            setClassesList(data);
        }
    }, [ClassesResponse]);


    useEffect(() => {
        if (isCategoryLoading) {
            return () => null;
        }
        if (isCategoryError) {
            return () => null;
        }
        if (CategoryResponse && CategoryResponse.data.isSuccess === true) {
            const data = CategoryResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    categoryId: prop._id
                };
            }
            );
            setCategoryList(data);
        }
    }, [CategoryResponse]);

    useEffect(() => {
        if (isProductsLoading) {
            return () => null;
        }
        if (isProductsError) {
            return () => null;
        }
        if (ProductsResponse && ProductsResponse.data.isSuccess === true) {
            const data = ProductsResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    productId: prop._id,
                    accountingId: prop.accountingId,
                    description: prop.description,
                    unitPrice: prop.unitPrice,
                };
            }
            );
            setProductsList(data);
        }
    }, [ProductsResponse]);

    const handleSubmit = async (values) => {
        if (price.length === 0 || Number(price) === 0) {
            setPriceErrorMsg('Price is required');
            setPriceerror(true);
        } else {
            setPriceErrorMsg('');
            setPriceerror(false);
        }

        if (quantity.length === 0 || Number(quantity) === 0) {
            setQuantityErrorMsg('Quantity is required');
            setQuantityrror(true);
        } else {
            setQuantityErrorMsg('');
            setQuantityrror(false);
        }

        if (categoryIds) {
            setCategoryerrormsg('');
            setCategoryerror(false);
            setBtnDisable(true);
        } else {
            setCategoryerrormsg('Category name required');
            setCategoryerror(true);
        }

        if (price === 0) {
            setPriceerror(true);
        } else {
            setPriceerror(false);
        }

        const finalprice = (typeof (price) == 'string') ? price.replaceAll(',', '') : price;
        const finalTotalPrice = (typeof (TotalPrice) == 'string') ? TotalPrice.replaceAll(',', '') : TotalPrice;
        const finalquantity = (typeof (quantity) == 'string') ? quantity.replaceAll(',', '') : quantity;

        await setAPICall({
            payload: [
                {
                    "itemType": 'AccountBasedExpenseLineDetail',
                    "unitOfMeasure": values.unit,
                    "discount": 0,
                    "description": values.item,
                    "documentId": `${invoiceIds}`,
                    "classId": classIdIds,
                    "customerId": customerIds,
                    "categoryId": categoryIds,
                    "adjustDocument": adjustInvoice,
                    "unitPrice": Number(finalprice),
                    "amount": Number(finalTotalPrice),
                    "quantity": Number(finalquantity),
                }
            ]
        })
    };

    const handleChange = () => {
        setAdjustInvoice(!adjustInvoice)
    }

    const handleChangeRedio = (event) => {
        setValue(event.target.value);
    };
    const handleChangeCategory = (event, newValue) => {
        if (newValue) {
            setCategoryerrormsg('');
            setCategoryerror(false);
        } else {
            setCategoryerrormsg('Category name required');
            setCategoryerror(true);
        }

        setCategoryName(newValue)
        const found = categoryList.find(obj => {
            return obj.name === newValue;
        });
        if (found) {
            setCategoryIds(found.categoryId);
            setSelectProduct(true);
            setDescription(found.description);
            setUnitPrice(found.unitPrice)
        }

    };

    const handleChangeClass = (event, newValue) => {
        setClassName(newValue);
        const found1 = classesList.find(obj => {
            return obj.name === newValue;
        });
        if (found1) {
            setClassIdId(found1.classId);
        }

    };

    const handleChangeCustomer = (event, newValue) => {
        setCustomerName(newValue);
        const found2 = customerList.find(obj => {
            return obj.name === newValue;
        });
        if (found2) {
            setCustomerIdId(found2.customerId);
        }

    };


    return (
        <>
            <MDBox width='auto'>
                <Grid item xs={12} lg={12}>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        // validationSchema={addCategoryschema}
                        onSubmit={handleSubmit}
                    >
                        {({ values, errors, touched, isSubmitting }) => (
                            <Form autoComplete="off">
                                <MDBox p={2} pt={2}>
                                    <MDBox >
                                        <Grid container spacing={3} sx={{ mb: 1 }}>
                                            <Grid item xs={12} sm={12}>
                                                <FormField
                                                    type="text"
                                                    label="Quantity"
                                                    name="quantity"
                                                />
                                            </Grid>
                                        </Grid>
                                        <MDBox mb={-2} mt={5} display="flex" justifyContent="end">
                                            <MDButton
                                                type="submit"
                                                variant="gradient"
                                                color="light"
                                                onClick={onCloseAdd}
                                            >
                                                Cancel
                                            </MDButton>
                                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                <MDButton
                                                    disabled={isDisable}
                                                    variant="gradient"
                                                    color="info"
                                                    onClick={() => setAddinventory(true)}
                                                >
                                                    Submit
                                                </MDButton>
                                            </MDBox>
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </MDBox>
        </>
    );
}

export default AddCurrontInvetory;

