import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from "@mui/material/IconButton";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import { EditNewClientSchema } from "layouts/admin/accountants/components/schemas/editNewClientSchema";
import FormField from "layouts/admin/accountants/components/FormField";
import { Link, useNavigate } from "react-router-dom";
import Hoc from "hoc";
import { useState } from "react";
import { useAPICall } from "../../../../utils/useapicall";
import { EditAccountantInfo, GetuserDetailsList } from "../../../../services/accountantservices";
import { GetClientDetailsData, EditClientInfo } from "../../../../services/adminservices";
import { useMaterialUIController } from "context";
import { CreatePassSchema } from "layouts/admin/accountants/components/schemas/edit-accountant";
import Checkbox from '@mui/material/Checkbox';
import {
    makeStyles,
} from '@material-ui/core/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import NumberFormat from "react-number-format";
import { eventBus } from "utils/eventbus";
import LoaderProfile from "layouts/accountants/user/components/imgLoader/LoaderProfile";
import { Settings } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
    backgroundColorOnInnerBoxOfCustomIcon: {
        '&.Mui-disabled': {
            pointerEvents: 'auto',
            '&:hover': {
                backgroundColor: 'transparent',
            },
            cursor: 'not-allowed',
            '& .MuiSvgIcon-root .innerBox': {
                fill: '#eee',
            },
        },
    },
}));

function EditAdminClient() {
    const classes = useStyles();
    const navigate = useNavigate();
    const [profileEmail, setprofileEmail] = useState('');
    const [profileFname, setprofileFname] = useState('');
    const [profileLname, setprofileLname] = useState('');
    const [profileBuinessName, setprofileBusinessName] = useState('');
    const [profileNumber, setprofileNumber] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [clientbussinessId, setBusinessId] = useState(location.pathname.split("/").slice(-2)[0]);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showPassSnackbar, setPassShowSnackbar] = useState(false);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [showPassErrorSnackbar, setShowPassErrorSnackbar] = useState(false);
    const [btnDisable, setBtnDisable] = useState(false);
    const [activeUser, setActiveUser] = useState(false);
    const [passBtnDisable, setPassBtnDisable] = useState(false);
    const [waitForApi, setWaitForApi] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState("Failed");
    const [isArchived, setIsArchived] = useState(false);
    const [editable, SetEditable] = useState(false)
    const [changePassword, SetChangePassword] = useState(false)
    const [controller] = useMaterialUIController();
    const [showNPassword, setShowNPassword] = useState(false);
    const [showRePassword, setShowRePassword] = useState(false);
    const [phone, setPhone] = useState('');
    const [loaderClass, setLoaderClass] = useState({});
    const handleNewClickShowPassword = () => setShowNPassword(true);
    const handleNewClickClosePassword = () => setShowNPassword(false)
    const handleReClickShowPassword = () => setShowRePassword(true);
    const handleReClickClosePassword = () => setShowRePassword(false);

    useEffect(() => {
        eventBus.$emit("OnSetBlur", !showForm);
        !showForm === true ? setLoaderClass({ opacity: '0.6', borderRadius: '13px' }) : setLoaderClass({})
    }, [showForm]);

    const initialValues = {
        name: "",
        firstname: profileFname,
        lastname: profileLname,
        businessname: profileBuinessName,
        phone: profileNumber,
        company: "",
        email: profileEmail,
        password: "",
        repeatPassword: "",
        curpassword: "",
        address1: "",
        address2: "",
        city: "",
        zip: "",
        twitter: "",
        facebook: "",
        instagram: "",
        publicEmail: "",
        bio: "",
        role: "",
    };

    const [{ isError, isLoading, response }, setAPICall] = useAPICall(EditClientInfo, { onLoad: false });
    const [{ isLoading: isUpdatePassLoading, isError: isUpdatePassError, response: UpadatePassResponse }, UpdatePassAPICall] = useAPICall(EditAccountantInfo, { onLoad: false });
    const [{ isLoading: isGetUserLoading, isError: isGetUserError, response: userDetailsDataResponse }, getClientDteailsAPICall] = useAPICall(GetClientDetailsData, { onLoad: false });

    useEffect(async () => {
        await getClientDteailsAPICall({
            payload: [{ "bussinessId": `${clientbussinessId}` }]
        })
    }, []);

    useEffect(() => {

        if (isGetUserLoading) {
            return () => null;
        }

        if (isGetUserError) {
            return () => null;
        }

        if (userDetailsDataResponse && userDetailsDataResponse.data.isSuccess === true) {
            const clientresult = userDetailsDataResponse?.data?.data?.clientOwner;
            setprofileEmail(clientresult?.email);
            setprofileFname(clientresult?.fname);
            setprofileLname(clientresult?.lname);
            setprofileBusinessName(clientresult?.businessName ? clientresult.businessName : 'test client 01');
            setprofileNumber(clientresult?.phone);
            setPhone(clientresult?.phone);
            setActiveUser(clientresult?.isBlocked);
            setIsArchived(clientresult?.isArchived);
            setPassBtnDisable(clientresult?.isArchived);
            setBtnDisable(clientresult?.isArchived);
            setShowForm(true);
            setWaitForApi(true);
        }
    }, [userDetailsDataResponse]);

    useEffect(() => {
        if (isLoading) {
            return () => null;
        }
        if (isError) {
            setBtnDisable(false);
            return () => null;
        }
        if (response && response.data.isSuccess === true) {

            setPassShowSnackbar(true);
            setTimeout(() => {
                setPassShowSnackbar(false);
            }, 3000);

            setBtnDisable(false);
            SetEditable(false)
            // navigate('/accountants/list');
        }
    }, [response]);

    useEffect(() => {

        if (isUpdatePassLoading) {
            return () => null;
        }

        if (isUpdatePassError) {
            response?.code === "DATA401" ? navigate('/admin/logout') : console.log("somting else");
            if (response?.data?.message) {
                setErrorMessage(response.data.message)
            }
            setBtnDisable(false);
            setPassBtnDisable(false);
            setShowPassErrorSnackbar(true);
            setTimeout(() => {
                setShowPassErrorSnackbar(false);
            }, 3000);
            return () => null;
        }

        if (UpadatePassResponse && UpadatePassResponse.data.isSuccess === true) {
            setPassShowSnackbar(true);
            setTimeout(() => {
                setPassShowSnackbar(false);
            }, 3000);
            setPassBtnDisable(false);
            SetChangePassword(false)
        }
    }, [UpadatePassResponse]);

    const handlePassSubmit = async (values) => {
        setPassBtnDisable(true);


        await UpdatePassAPICall({
            payload: [
                {
                    "password": `${values.password}`,
                    "confirmPassword": `${values.repeatPassword}`,
                    "bussinessId": `${clientbussinessId}`
                }
            ]
        })
    };

    const handleSubmit = async (values) => {
        setBtnDisable(true);
        await setAPICall({
            payload: [
                {
                    "email": `${values.email}`,
                    "businessName": `${values.businessname}`,
                    "phone": phone,
                    "fname": `${values.firstname}`,
                    "lname": `${values.lastname}`,
                    "bussinessId": `${clientbussinessId}`,
                    "isBlocked": activeUser,
                }
            ]
        })

    };
    const handleClose = () => {
        window.location.reload();
    }

    const ToggleChange = async () => {
        setActiveUser(!activeUser);
    }

    return (
        <>
            {
                showForm ?
                    <>
                        < MDBox >
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={12}>
                                    <MDBox mb={3}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Formik
                                                    initialValues={initialValues}
                                                    validationSchema={EditNewClientSchema}
                                                    onSubmit={handleSubmit}
                                                >
                                                    {({ values, errors, touched, isSubmitting }) => (
                                                        <Form autoComplete="off">
                                                            <MDBox >
                                                                <MDBox>
                                                                    <MDBox lineHeight={0}>
                                                                        <MDTypography variant="h5">Edit Client</MDTypography>
                                                                    </MDBox>
                                                                    <MDBox mt={1.625}>
                                                                        {/* <Grid item xs={12} mb={1}>
                                                                            <FormField
                                                                                type="text"
                                                                                label="Client Business Name"
                                                                                name="businessname"
                                                                                disabled
                                                                            />
                                                                        </Grid>
                                                                        <MDBox lineHeight={0} mb={1} mt={1}>
                                                                            <MDTypography variant="h6" textTransform="capitalize">Client Info</MDTypography>
                                                                        </MDBox> */}
                                                                        <Grid container spacing={3}>
                                                                            <Grid item xs={12} sm={6}>
                                                                                <FormField
                                                                                    type="text"
                                                                                    label="First Name *"
                                                                                    name="firstname"
                                                                                    disabled={!editable}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12} sm={6}>
                                                                                <FormField
                                                                                    type="text"
                                                                                    label="Last Name *"
                                                                                    disabled={!editable}
                                                                                    name="lastname"
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <FormField
                                                                                type="email"
                                                                                label="Email"
                                                                                name="email"
                                                                                disabled={!editable}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <NumberFormat
                                                                                customInput={TextField}
                                                                                variant="standard"
                                                                                type="tel"
                                                                                fullWidth
                                                                                value={phone}
                                                                                disabled={!editable}
                                                                                InputProps={!editable && { disableUnderline: true }}
                                                                                label="Phone Number"
                                                                                sx={{
                                                                                    "& .MuiInputLabel-root.Mui-disabled": {
                                                                                        color: '#7b809a !important',
                                                                                    },
                                                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                                                        color: '#7b809a !important',
                                                                                        WebkitTextFillColor: "black !important",
                                                                                        borderBottom: "1px solid #d2d6da !important",
                                                                                    },
                                                                                    mb: 1, mt: 0.5, fontSize: `0.823rem !important`, color: '#7b809a !important',
                                                                                }}
                                                                                format="(###) ###-####"
                                                                                mask=""
                                                                                onValueChange={value => setPhone(value.formattedValue)}
                                                                            />
                                                                        </Grid>
                                                                    </MDBox>
                                                                    <Grid sx={{ display: "flex" }}>
                                                                        <Grid item xs={3}  >
                                                                            <MDBox mt={{ xs: 2, sm: 3 }} justifyContent='left'>
                                                                                <Grid sx={{ pr: 11 }}>
                                                                                    <FormControlLabel control={
                                                                                        <Checkbox
                                                                                            checked={!activeUser}
                                                                                            className={classes.backgroundColorOnInnerBoxOfCustomIcon}
                                                                                            onChange={ToggleChange}
                                                                                            disabled={!editable}
                                                                                        />
                                                                                    }
                                                                                        label="Is Active"

                                                                                    //disabled={isArchived}
                                                                                    />
                                                                                </Grid>
                                                                                <MDTypography
                                                                                    variant="caption"
                                                                                    sx={{ cursor: 'pointer' }}
                                                                                    color="info"
                                                                                    onClick={() => SetChangePassword(true)}
                                                                                >
                                                                                    Change Password
                                                                                </MDTypography>
                                                                            </MDBox>
                                                                        </Grid>
                                                                        <Grid item xs={9} mt={4} >
                                                                            <MDBox sx={{ display: "flex" }} mt={{ xs: 2, sm: 3 }} justifyContent='end' >
                                                                                {!editable && <MDButton

                                                                                    type="submit"
                                                                                    variant="gradient"
                                                                                    color="info"
                                                                                    onClick={() => SetEditable(true)}
                                                                                >
                                                                                    Edit
                                                                                </MDButton>}
                                                                                {editable &&
                                                                                    <MDBox display="flex" >
                                                                                        <MDButton
                                                                                            disabled={btnDisable}
                                                                                            variant="gradient"
                                                                                            color="light"
                                                                                            onClick={handleClose}
                                                                                        >
                                                                                            Cancel
                                                                                        </MDButton>
                                                                                        <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                                                            <MDButton
                                                                                                disabled={btnDisable}
                                                                                                type="submit"
                                                                                                variant="gradient"
                                                                                                color="info"
                                                                                            >
                                                                                                {isArchived ? "update" : btnDisable ? "updating..." : "update"}
                                                                                            </MDButton>
                                                                                        </MDBox>
                                                                                    </MDBox>
                                                                                }
                                                                            </MDBox>
                                                                        </Grid>
                                                                    </Grid>
                                                                </MDBox>
                                                            </MDBox>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                </Grid>
                            </Grid>
                        </MDBox>
                        <MDBox>
                            <Dialog
                                open={changePassword}
                                onClose={() => SetChangePassword(false)}

                                aria-labelledby="alert-dialog-title"
                                sx={{
                                    "& .MuiDialog-container": {
                                        "& .MuiPaper-root": {
                                            width: "100%",
                                            maxWidth: "570px",
                                        },
                                    },
                                }}
                            >
                                <DialogTitle id="scroll-dialog-title"
                                    sx={{
                                        justifyContent: "center",
                                        display: 'flex'
                                    }}>Change Password</DialogTitle>
                                <DialogContent>
                                    <Grid item xs={12}>
                                        {/* <Card id="change-password"> */}
                                        <Formik
                                            initialValues={initialValues}
                                            validationSchema={CreatePassSchema}
                                            onSubmit={handlePassSubmit}
                                        >
                                            {({ values, errors, touched, isSubmitting }) => (
                                                <Form autoComplete="off">
                                                    {/* <Card sx={{ height: "100%" }}> */}
                                                    <MDBox p={3}>
                                                        <MDBox>
                                                            <MDBox mt={1.625}>
                                                                <Grid item xs={12}>
                                                                    <FormField
                                                                        type={showNPassword ? "text" : "password"}
                                                                        label="New Password *"
                                                                        name="password"
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <IconButton sx={{ fontSize: '0.9rem' }} >
                                                                                        {!showNPassword ? <VisibilityOff onClick={handleNewClickShowPassword} /> : <Visibility onClick={handleNewClickClosePassword} />}
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <FormField
                                                                        type={showRePassword ? "text" : "password"}
                                                                        label="Confirm New Password *"
                                                                        name="repeatPassword"
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <IconButton sx={{ fontSize: '0.9rem' }} >
                                                                                        {!showRePassword ? <VisibilityOff onClick={handleReClickShowPassword} /> : <Visibility onClick={handleReClickClosePassword} />}
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </MDBox>
                                                            <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                                                                <MDBox mt={5} display="flex" justifyContent="end">
                                                                    <MDButton
                                                                        variant="gradient"
                                                                        color="light"
                                                                        onClick={() => SetChangePassword(false)}
                                                                    >
                                                                        Cancel
                                                                    </MDButton>
                                                                    <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                                        <MDButton
                                                                            disabled={passBtnDisable}
                                                                            type="submit"
                                                                            variant="gradient"
                                                                            color="info"
                                                                        >
                                                                            {isArchived ? "update password" : passBtnDisable ? "updating..." : "update password"}
                                                                        </MDButton>
                                                                    </MDBox>

                                                                </MDBox>
                                                            </MDBox>
                                                        </MDBox>
                                                    </MDBox>
                                                    {/* </Card> */}
                                                </Form>
                                            )}
                                        </Formik>
                                        {/* </Card> */}
                                    </Grid>
                                </DialogContent>
                            </Dialog>
                        </MDBox>
                        <MDBox>
                            <MDSnackbar
                                color="success"
                                icon="done"
                                title="success"
                                // autoHideDuration={3000}
                                content="Client's has been successfully updated."
                                open={showSnackbar}
                                close={() => setShowSnackbar(false)}

                            />
                            <MDSnackbar
                                color="error"
                                icon="warning"
                                title="Failed."
                                // autoHideDuration={3000}
                                content="Client's Updation failed!"
                                open={showErrorSnackbar}

                                close={() => setShowErrorSnackbar(false)}
                            />
                            <MDSnackbar
                                color="success"
                                icon="done"
                                title="success"
                                content="Client's has been successfully updated."
                                open={showPassSnackbar}
                                // autoHideDuration={3000}
                                close={() => setPassShowSnackbar(false)}
                            />
                            <MDSnackbar
                                color="error"
                                icon="warning"
                                title="Failed."
                                content={ErrorMessage}
                                // autoHideDuration={3000}
                                open={showPassErrorSnackbar}
                                close={() => setShowPassErrorSnackbar(false)}
                            />
                        </MDBox>
                    </>
                    :
                    <MDBox mt={4} display="flex" justifyContent="center" >
                        <LoaderProfile />
                    </MDBox>
            }
        </>
    );
}

export default Hoc(EditAdminClient);