import React from 'react'
import Layout from "layouts/auth/components/BasicLayout/404layout";

const PageNotFound = () => {
    return (
        <Layout image={`https://i.imgur.com/qIufhof.png`}>
            <div id="wrapper">
                <div id="info">
                    <h3>This page could not be found</h3>
                </div>
            </div >
        </Layout>
    )
}

export default PageNotFound;