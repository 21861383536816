import * as React from "react";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import Icon from "@mui/material/Icon";
import MDSnackbar from "components/MDSnackbar";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import Modal from '@mui/material/Modal';
import DashboardLayout from "../../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../components/Navbars/DashboardNavbar";
import DataTable from "./invoiceTable";
import DataTable1 from "../../../components/Tables/DocTable";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useAPICall } from "../../../../utils/useapicall";
import { GetInvoiceList, GetuserClientList, GetStatusCount, DeleteInvoice, MoveMenuInvoice, GetIssueList, GetInvoiceDetails } from "../../../../services/userservices";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import { eventBus } from 'utils/eventbus';
import { yellow } from '@mui/material/colors';
import UserHoc from 'hoc/userHoc';
import NewInvoiceDialog from "./newinvoicedialog";
import EditInvoiceDialog from "./editinvoicedialog";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import CircleIcon from '@mui/icons-material/Circle';
import ErrorEditInvoiceDialog from "./erroreditinvoicedialog";
import { canAccess } from '../../../../helpers';
import { getAccountantUserType, getAccountantPermmison } from "helpers/getaccountantusertype";
import { User, User2 } from 'helpers/getUser';
import logo from "assets/images/logos/paynthr-logo-colored.png"
import ConnectToQuickBooksOnlineButton from "layouts/clients/components/QBOButton";
import Cookies from 'universal-cookie';
import { SITE_URL } from "helpers/config";
import AddOwnerDetailsDialog from "layouts/clients/add-owner";
import CustomDialog2 from "components/CustomDialog/customDialog2";
import CustomDialog from "components/CustomDialog";
import Loader from "../components/imgLoader/loader";
import image from './output-onlinegiftools.gif'
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function AccountantInvoice() {
    const classes = useStyles();
    const cookies = new Cookies();
    const AccountantUserType = getAccountantUserType();
    const AccountantPermmison = getAccountantPermmison();
    const [value, setValue] = useState('Classify');
    const navigate = useNavigate();
    const action = { COMPLETED: "Completed", Qbotooltip: "QBO", INPROGRESS: "In Progress", PENDING: "Pendding", ERROR: "Error", Movetooltip: "Move", showtooltip: "View", edittooltip: "Edit", deletetooltip: "Delete" };
    const [InvoiceList, setInvoiceList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tableEmpty, setTableEmpty] = useState("");
    const [clientAssigned, setClientAssigned] = useState([]);
    const [search, setSearch] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showMoveSnackbar, setShowMoveSnackbar] = useState(false);
    const [showDeleteSnackbar, setShowDeleteSnackbar] = useState(false);
    const [showErrorMoveSnackbar, setShowErrorMoveSnackbar] = useState(false);
    const [showErrorDeleteSnackbar, setShowErrorDeleteSnackbar] = useState(false);
    const [showCompleteSnackbar, setShowCompleteSnackbar] = useState(false);
    const [moveErrorMessage, setMoveErrorMessage] = useState('');
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [reloadApi, setReloadApi] = useState(0);
    const [reloadApiClientApi, setReloadApiClientApi] = useState(0);
    const [uploadPermison, setUploadPermison] = useState(false);
    const [profileInfo, setProfileInfo] = useState(cookies.get('user_profile_info'));
    const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetInvoiceList, { onLoad: false });
    const [{ isLoading: isCountLoading, isError: isCountError, response: CountResponse }, CountUserAPICall] = useAPICall(GetStatusCount, { onLoad: false });
    const [{ isLoading: isClientLoading, isError: isClientError, response: ClientResponse }, ClientUserAPICall] = useAPICall(GetuserClientList, { onLoad: false });
    const [openInvoice, setOpenInvoice] = useState(false);
    const [openEditInvoice, setOpenEditInvoice] = useState(false);
    const [countAll, setCountAll] = useState(0);
    const [completemassage, setCompletemassage] = useState('');
    const [countApproved, setCountApproved] = useState(0);
    const [countAskClient, setCountAskClient] = useState(0);
    const [countClassify, setCountClassify] = useState(0);
    const [countExcluded, setCountExcluded] = useState(0);
    const [countPendingApproval, setCountPendingApproval] = useState(0);
    const [countToReview, setCountToReview] = useState(0);
    const [invoiceId, setInvoiceId] = useState("");
    const [issueType, setIssueType] = useState("");
    const [selectedId, setSelectId] = useState("");
    const [IssuesExist, setIssuesExist] = useState(false);
    const [showrowsData, setShowrowsData] = useState([]);
    const [clientResponce, setClientResponce] = useState([]);
    const [autoCompleteObj, setAutoCompleteObj] = useState([]);
    const [showselectedrowsData, setShowSelectedrowsData] = useState("");
    const [businessId, setBusinessId] = useState(location.pathname.split("/").slice(-1)[0]);
    const [onChangeBusinessId, setOnchageBusinessId] = useState("");
    const [openSelectInvoice, setOpenSelectInvoice] = useState(false);
    const [openNoInvoice, setOpenNoInvoice] = useState(false);
    const [uploadInvoice, setUploadInvoice] = useState(false);
    const [clientEvent, setClientEvent] = useState('');
    const [clientNewValue, setClientNewValue] = useState('');
    const [selectTab, setSelectTab] = useState('Classify');
    const [syncPercent, setSyncPercent] = useState(0);
    const [selectTab1, setSelectTab1] = useState('');
    const [SelectedRows, setSelectedRows] = useState([]);
    const [issueList, setIssueList] = useState([]);
    const [onIssueClick, setOnIssueClick] = useState(false);
    const [onIssueClickData, setOnIssueClickData] = useState([]);
    const [documentsId, setDocumentsId] = useState();
    const [selectUseEffect, setSelectUseEffect] = useState();
    const [alertDeleteInvoice, setAlertDeleteInvoice] = useState(false);
    const [alertMoveInvoice, setalertMoveInvoice] = useState(false);
    const [alertIssueInvoice, setalertIssueInvoice] = useState(false);
    const [tabsMove, setTabsMove] = useState('');
    const [titleShow, setTitleShow] = useState('success');
    const [disButton, setDisButton] = useState(false);
    const [showSelectBusiness, setShowSelectBusiness] = useState(false);
    const [iconShow, setIconShow] = useState('done');
    const [invoiceStatusId, setInvoiceStatusId] = useState('');
    const [selectedInvoice, setSelectedInvoice] = useState(0);
    const [StoreInvoiceNumber, setStoreInvoiceNumber] = useState('');
    const [storeResponce, setStoreResponce] = useState({});
    const [moduleTypeEdit, setModuleTypeEdit] = useState('');
    const [issueExist, setIssueExist] = useState(false);
    const [movePermmison, setMovePermmison] = useState('');
    const [invalidDocumnet, setInvalidDocumnet] = useState(false);
    const [openErrorEditInvoice, setErrorOpenEditInvoice] = useState(false);
    const [openNoClient, setOpenNoClient] = useState(false);
    const [ownerAssigned, setOwnerAssigned] = useState(false);
    const [assignNewClient, setAssignNewClient] = useState(false);
    const [assignNewClient1, setAssignNewClient1] = useState(false);
    const [ownerName, setOwnerName] = useState('');
    const [{ isLoading: isDeleteInvoiceLoading, isError: isDeleteInvoiceError, response: DeleteInvoiceResponse }, DeleteInvoiceListAPICall] = useAPICall(DeleteInvoice, { onLoad: false });
    const [{ isLoading: isDetailsLoading, isError: isDetailsError, response: DetailsResponse }, DetailsUserAPICall] = useAPICall(GetInvoiceDetails, { onLoad: false });
    const [{ isLoading: isMoveMenuLoading, isError: isMoveMenuError, response: MoveMenuResponse }, MoveMenuListAPICall] = useAPICall(MoveMenuInvoice, { onLoad: false });
    const [{ isLoading: isIssueLoading, isError: isIssueError, response: IssueResponse }, IssueListAPICall] = useAPICall(GetIssueList, { onLoad: false });
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [searchParams, setSearchParams] = useSearchParams();
    const ReturnUrl = encodeURI(`${SITE_URL}/qbo-cb`);
    const [loaderClass, setLoaderClass] = useState({});
    const { pathname } = useLocation();
    const checkIdDevServer = process.env.REACT_APP_CHECK_IS_DEV_SERVER;

    useEffect(async () => {
        const queryParameters = new URLSearchParams(window.location.search)
        const keyword = queryParameters.get("keyword");
        if (keyword) {
            setSearch(keyword);
            setLoading(true);
        } else {
            if (businessId !== ':id') {
                setLoading(true);
                await setAPICall({
                    payload: [{ "businessId": businessId, "invoiceStatus": selectTab, "keyword": `${search}` }]
                })
            }
        }
    }, []);

    useEffect(() => {
        const found1 = clientResponce.find(obj => {
            return obj.name === clientNewValue.label;
        });
        setSyncPercent(found1?.syncPercent);
    }, [clientNewValue]);

    // async function getListApiCall() {
    //     const ThisOffsetuse = localStorage.getItem("offset");
    //     await setAPICall({
    //         payload: [{ businessId: `${businessId}`, "invoiceStatus": selectTab, 'offset': ThisOffsetuse }]
    //     });


    //     console.log("response------------>", response)
    //     // if (response.status == 502) {
    //     //     // Connection timeout
    //     //     // happens when the connection was pending for too long
    //     //     // let's reconnect
    //     //     await getListApiCall();
    //     // } else if (response.status != 200) {
    //     //     // Show Error
    //     //     showMessage(response.statusText);
    //     //     // Reconnect in one second
    //     //     await new Promise(resolve => setTimeout(resolve, 1000));
    //     //     await getListApiCall();
    //     // } else {
    //     //     // Got message
    //     //     let message = await response.text();
    //     //     showMessage(message);
    //     //     await getListApiCall();
    //     // }
    // }
    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const keyword = queryParameters.get("keyword");
        if (!keyword) {
            window.history.pushState('data', 'title', `${pathname}`);
        }
    }, [search]);

    //stop reload Api
    useEffect(() => {
        const fetchData = async () => {
            await ClientUserAPICall({
                payload: [{ "keyword": `` }]
            })
        };

        const timer = setTimeout(() => {
            fetchData();
        }, 180000);

        return () => clearTimeout(timer);
    }, [reloadApiClientApi]);

    useEffect(() => {
        eventBus.$emit("OnSetBlur", loading);
        loading === true ? setLoaderClass({ opacity: '0.4', borderRadius: '13px' }) : setLoaderClass({})
    }, [loading])

    var resource = '';
    var actions = '';
    useEffect(() => {
        const urlParams = new URL(window.location.href).searchParams;
        const DocId = urlParams.get('id');
        if (DocId) {
            setLoading(true);
            DetailsUserAPICall({
                payload: [{ "InvoiceId": `${DocId}`, "businessId": `${businessId}` }]
            })
            setInvoiceId(DocId);
        }
    }, []);

    const removeQueryParams = () => {
        const param = new URL(window.location.href).searchParams;
        if (param) {
            searchParams.delete('id');
            setSearchParams(searchParams);
        }
    };

    useEffect(() => {
        Object.keys(User2).map((key) => (
            AccountantPermmison[0]?.role === key ? setMovePermmison(User2[key]) : ''
        ))
    }, [])

    const Modalstyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '0',
        boxShadow: 24,
        p: 4,
        borderRadius: '15px',
        outline: 'none',
    };

    useEffect(() => {
        if (isDetailsLoading) {
            return () => null;
        }

        if (isDetailsError) {
            removeQueryParams();
            setInvalidDocumnet(true);
            setTimeout(() => {
                setInvalidDocumnet(false);
            }, 5000);
            return () => null;
        }

        if (DetailsResponse && DetailsResponse.data.isSuccess === true) {
            setOpenEditInvoice(true);
        }
    }, [DetailsResponse])
    useEffect(() => {
        if (isIssueLoading) {
            return () => null;
        }
        if (isIssueError) {
            setLoading(false);
            return () => null;
        }
        if (IssueResponse && IssueResponse.data.isSuccess === true) {
            setIssueList(IssueResponse?.data?.data?.records);
            setalertIssueInvoice(true);
            setLoading(false);
        }
    }, [IssueResponse]);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        const found1 = issueList.find(obj => {
            return obj._id === index;
        });
        setSelectId(found1?._id)
        setIssueType(found1?.issueType)
        setInvoiceId(found1?.documentId)
        setOnIssueClickData(found1);
        setOpenEditInvoice(true);
        setOnIssueClick(true);
        setModuleTypeEdit(found1?.moduleType)
    };

    function generate(element) {
        return issueList.map((values, index) =>
            <ListItemButton
                selected={selectedIndex === values._id}
                onClick={(event) => handleListItemClick(event, values._id)}
            >
                <CircleIcon sx={{ mr: 1, fontSize: 'small' }} />
                <ListItemText
                    primary={`${values.message}`}
                />
            </ListItemButton>
        );
    }

    const Demo = styled('div')(({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
    }));


    useEffect(() => {
        if (isMoveMenuLoading) {
            return () => null;
        }
        if (isMoveMenuError) {
            setLoading(false);
            if (response?.data?.message) {
                setMoveErrorMessage(response.data.message)
            }
            setShowErrorMoveSnackbar(true);
            setTimeout(() => {
                setShowErrorMoveSnackbar(false);
            }, 10000);
            setalertMoveInvoice(false);
            return () => null;
        }
        if (MoveMenuResponse && MoveMenuResponse.data.isSuccess === true) {
            setLoading(true);
            const ThisOffsetuse = localStorage.getItem("offset");
            CountUserAPICall({
                payload: [{ "businessId": `${businessId}` }]
            })
            setalertMoveInvoice(false);
            setShowMoveSnackbar(true);
            setTimeout(() => {
                setShowMoveSnackbar(false);
            }, 10000);
            setAPICall({
                payload: [{ businessId: `${businessId}`, "invoiceStatus": selectTab, 'offset': ThisOffsetuse }]
            });
        }
    }, [MoveMenuResponse]);



    useEffect(() => {
        if (isDeleteInvoiceLoading) {
            return () => null;
        }
        if (isDeleteInvoiceError) {
            setLoading(false);
            if (response?.data?.message) {
                setDeleteErrorMessage(response.data.message)
            }
            setShowErrorDeleteSnackbar(true);
            setTimeout(() => {
                setShowErrorDeleteSnackbar(false);
            }, 10000);
            setAlertDeleteInvoice(false);
            return () => null;
        }
        if (DeleteInvoiceResponse && DeleteInvoiceResponse.data.isSuccess === true) {
            const ThisOffsetdelete = localStorage.getItem("offset");
            setAPICall({
                payload: [{ businessId: `${businessId}`, "invoiceStatus": selectTab, 'offset': ThisOffsetdelete }]
            });
            CountUserAPICall({
                payload: [{ "businessId": `${businessId}` }]
            })
            setLoading(false);
            setAlertDeleteInvoice(false);
            setShowDeleteSnackbar(true);
            setTimeout(() => {
                setShowDeleteSnackbar(false);
            }, 10000);
        }
    }, [DeleteInvoiceResponse]);

    const [anchor, setAnchor] = useState(null);
    const [selected, setSelected] = useState(-1);
    const [showApprovedModel, setShowApprovedModel] = useState(false);
    const [loadingCircul, setLoadingCircul] = useState(false);



    useEffect(async () => {
        const ThisOffsetuse = localStorage.getItem("offset");
        if (onChangeBusinessId) {
            await setAPICall({
                payload: [{ businessId: `${onChangeBusinessId}`, "invoiceStatus": selectTab, 'offset': ThisOffsetuse }]
            });
            await CountUserAPICall({
                payload: [{ "businessId": `${onChangeBusinessId}` }]
            })
            setBusinessId(onChangeBusinessId);
            setLoading(true);
            setValue(selectTab);
            navigate(`/accountant/document/${onChangeBusinessId}`);
        }
    }, [onChangeBusinessId]);

    eventBus.$on('onclickchangePage', (data) => OnclickchangePage(data));
    function OnclickchangePage(data) {
        setLoading(true);
        const dataStr = data.toString() + 0
        localStorage.setItem("offset", Number(dataStr))
        setAPICall({
            payload: [{ businessId: `${businessId}`, "invoiceStatus": selectTab, 'offset': Number(dataStr), "keyword": `${search}` }]
        });
    }

    eventBus.$on('ChangePaginationPage', (data) => ChangePaginationPage(data));
    function ChangePaginationPage(data) {
        // console.log("pageination", data);
    }

    eventBus.$on('refreshApiInvoiceList', (data) => refreshApiInvoiceList(data));
    function refreshApiInvoiceList(data) {
        setLoading(false);
        const Offsetrefresh = localStorage.getItem("offset")
        setAPICall({
            payload: [{ businessId: `${businessId}`, "invoiceStatus": selectTab, 'offset': Offsetrefresh }]
        });
    }

    eventBus.$on('disableclosebutton', (data) => disableclosebutton(data));
    function disableclosebutton(data) {
        setDisButton(data);
    }

    eventBus.$on('refreshListAfterUpdate', (data) => RefreshListAfterUpdate(data));
    function RefreshListAfterUpdate(data) {
        setLoading(false);
        const OffsetRe = localStorage.getItem("offset")
        setAPICall({
            payload: [{ businessId: `${businessId}`, "invoiceStatus": selectTab, 'offset': OffsetRe }]
        });
        CountUserAPICall({
            payload: [{ "businessId": `${businessId}` }]
        })
    }

    eventBus.$on('closeDeleteItemPopup', (data) => CloseInvoicesPopup(data));
    function CloseInvoicesPopup(data) {
        setOpenEditInvoice(false);
        const ThisOffsetevent = localStorage.getItem("offset");
        setLoading(false);
        setAPICall({
            payload: [{ businessId: `${businessId}`, "invoiceStatus": selectTab, 'offset': ThisOffsetevent }]
        });
        CountUserAPICall({
            payload: [{ "businessId": `${businessId}` }]
        })
    }

    eventBus.$on('closeNewInvoicePopup', (data) => fetchNewInvoices(data));
    function fetchNewInvoices(data) {
        eventBus.$emit('disableclosebutton', false);
        if (data?.code === 'ERR_BAD_REQUEST') {
            setShowSnackbar(true);
            setTimeout(() => {
                setShowSnackbar(false);
            }, 5000);
            setTimeout(() => {
                setOpenInvoice(false);
            }, 5000);
        } else {
            // setTimeout(() => {
            setUploadInvoice(true);
            // }, 1000);
            setOpenInvoice(false);
            setLoading(true);
            const ThisOffseteventclose = localStorage.getItem("offset");
            setAPICall({
                payload: [{ "businessId": businessId, "invoiceStatus": selectTab, 'offset': ThisOffseteventclose }]
            })
            CountUserAPICall({
                payload: [{ "businessId": businessId }]
            })
        }
    }

    const handleChangeBusiness = (event, newValue) => {

        var eventValue = '';
        if (event.target.value) {
            var eventValue = event.target.value;
        } else {
            var eventValue = newValue.label;
        }
        const found1 = clientResponce.find(obj => {
            return obj.name === eventValue;
        });
        setSyncPercent(found1?.syncPercent);
        if (found1?.syncPercent === 100) {
            setUploadPermison(true)
            ClientUserAPICall({
                payload: [{ "keyword": `` }]
            })
        } else {
            setUploadPermison(false)
            ClientUserAPICall({
                payload: [{ "keyword": `` }]
            })
        }
        const arraybusinessId = showrowsData.map(obj => Object.values(obj));
        {
            arraybusinessId.map((prop, key) => (
                (prop[1] == eventValue) ?
                    setOnchageBusinessId(prop[0])
                    : ''
            ))
        }
        setShowSelectedrowsData(eventValue);
        setOpenSelectInvoice(false);
        setLoading(true);
    };

    const handleClose = () => {
        setOpenInvoice(false);
    };

    const handleCloseSelect = () => {
        if (showselectedrowsData) {
            setOpenSelectInvoice(false);
        } else {
            navigate(-1);
        }
    };

    useEffect(() => {
        localStorage.setItem("Tabs", selectTab)
    }, [])

    const IssueIsExisttext = () => {
        return <MDBox sx={{ ml: 3, mr: 3, mb: -1 }}>
            <MDTypography >
                We are unable to approve this document due to the presence of certain issues that have been identified during the review process. . We kindly request that you take the necessary steps to address and resolve these issues before resubmitting the document for approval.
            </MDTypography>
        </MDBox>
    }

    const handleChange = async (event, newValue) => {
        localStorage.setItem("Tabs", newValue)
        setValue(newValue);
        setLoading(true);
        setSelectTab(newValue);
        setSelectTab1(newValue);
        localStorage.setItem("offset", 0);
        eventBus.$emit("showSelectTabs", newValue);
        await setAPICall({
            payload: [{ "businessId": businessId, "invoiceStatus": newValue, 'offset': 0 }]
        })
        await CountUserAPICall({
            payload: [{ "businessId": businessId }]
        })
    };



    useEffect(async () => {
        setLoading(true);
        const ThisOffsetSearch = localStorage.getItem("offset");
        const ThisnewpageIndex = localStorage.getItem("newpageIndex");
        const finaloffset = ThisnewpageIndex == 0 ? 0 : ThisOffsetSearch;
        await ClientUserAPICall({
            payload: [{ "keyword": `` }]
        })
        if (businessId !== ':id') {
            await setAPICall({
                payload: [{ "businessId": businessId, "invoiceStatus": selectTab, 'offset': finaloffset }]
            });
            await CountUserAPICall({
                payload: [{ "businessId": businessId }]
            });
        } else {
            setOpenSelectInvoice(true);
        }

        eventBus.$on('invoiceRowSelected', (data) => {
            setSelectUseEffect(data.selectedRowIds.length);
            if (data?.selectedRowIds.length) {
                setSelectedRows(data?.selectedRowIds);
            }
        });
        return () => { eventBus.$off('invoiceRowSelected') };
    }, []);

    useEffect(() => {
        const ThisOffsetSearch = localStorage.getItem("offset");
        const ThisnewpageIndex = localStorage.getItem("newpageIndex");
        const finaloffset = ThisnewpageIndex == 0 ? 0 : ThisOffsetSearch;
        console.log("search----------->", search)
        if (businessId !== ':id') {
            const timeOutId = setTimeout(async () =>
                await setAPICall({
                    payload: [{ "businessId": businessId, "invoiceStatus": selectTab, "keyword": `${search}`, 'offset': finaloffset }]
                }), 900);
            return () => clearTimeout(timeOutId);
        }
    }, [search]);

    eventBus.$on('successInAddOwner', (data) => successInAddOwner(data));
    function successInAddOwner(data) {
        navigate('/accountant/client/list');
    }

    useEffect(() => {
        if (isClientLoading) {
            return () => null;
        }

        if (isClientError) {
            return () => null;
        }

        if (ClientResponse && ClientResponse.data.isSuccess === true) {
            if (businessId == ":id") {
                ClientResponse?.data?.data?.totalRecords > 1 ? setOpenSelectInvoice(true) : '';
            }
            if (ClientResponse?.data?.data?.records[0]?.syncPercent !== 100) {
                setReloadApiClientApi(reloadApiClientApi + 1);
            }
            setClientResponce(ClientResponse?.data?.data?.records);
            const found2 = ClientResponse?.data?.data?.records.find(obj => {
                return obj._id === businessId;
            });
            setSyncPercent(found2?.syncPercent);
            setOwnerAssigned(found2?.ownerAssigned);
            setOwnerName(found2?.name);
            if (found2?.syncPercent === 100) {
                setUploadPermison(true)
            } else {
                setUploadPermison(false)
            }
            const array = ClientResponse.data.data.records.map(obj => Object.values(obj));
            setShowrowsData(array);
            var obj = array.map(x => ({
                label: x[1]
            }));
            if (array.length > 1) {
                setShowSelectBusiness(true);
            }
            setAutoCompleteObj(obj);
            {
                if (businessId == ':id' && array) {
                    // setValue(selectTab);
                    if (array.length === 0) {
                        setOpenNoInvoice(true);
                    } else if (array.length === 1) {
                        if (ClientResponse?.data?.data?.records[0]?.syncPercent == 100) {
                            setUploadPermison(true)
                        } else {
                            setUploadPermison(false)
                        }
                        setBusinessId(array[0][0]);
                        setOnchageBusinessId(array[0][0]);
                        setShowSelectedrowsData(array[0][1]);
                        navigate(`/accountant/document/${array[0][0]}`);
                    } else {
                        setBusinessId(array[0][0]);
                        setOpenSelectInvoice(true);
                    }
                }

                array.map((prop, key) => (
                    (prop[0] == businessId) ? setShowSelectedrowsData(prop[1]) : ''
                ));
                setLoading(false);
                setLoadingCircul(false);
            }
        }
    }, [ClientResponse]);

    useEffect(() => {
        if (isCountLoading) {
            return () => null;
        }
        if (isCountError) {
            return () => null;
        }
        if (CountResponse && CountResponse?.data?.isSuccess === true) {
            setCountAll(CountResponse?.data?.data?.countAll);
            setCountApproved(CountResponse?.data?.data?.countApproved);
            setCountAskClient(CountResponse?.data?.data?.countAskClient);
            setCountClassify(CountResponse?.data?.data?.countClassify);
            setCountExcluded(CountResponse?.data?.data?.countExcluded);
            setCountPendingApproval(CountResponse?.data?.data?.countPendingApproval);
            setCountToReview(CountResponse?.data?.data?.countToReview);
        }
    }, [CountResponse]);

    useEffect(() => {
        if (invoiceStatusId === 'ERROR') {
            setTitleShow('Error');
            setIconShow('warning');
            setCompletemassage('Unable to read invoice');
        } else {
            setTitleShow('Success');
            setIconShow('done');
            setCompletemassage(`Documents uploaded Successfully`);
        }
    }, [invoiceStatusId])

    useEffect(() => {
        if (isLoading) {
            return () => null;
        }
        if (isError) {
            setLoading(false);
            return () => null;
        }
        if (response && response.data.isSuccess === true) {
            const ThisOffsetuse = localStorage.getItem("offset");
            if (ThisOffsetuse >= 10) {
                if (response?.data?.data?.records.length === 0 && ThisOffsetuse !== 0) {
                    setLoading(true);
                    localStorage.setItem('offset', ThisOffsetuse - 10)
                    setAPICall({
                        payload: [{ businessId: `${businessId}`, "invoiceStatus": selectTab, 'offset': ThisOffsetuse - 10 }]
                    });
                    eventBus.$emit("MoveMenuResponseComplete", (ThisOffsetuse));
                }
            }

            eventBus.$emit("showInvoiceStatus", response?.data?.data?.records);
            setStoreResponce(response);
            setTableEmpty(response?.data?.data?.totalRecords);
            setInvoiceStatusId(response?.data?.data?.totalRecords === 0 ? 0 : response?.data?.data?.records[0]?.status);
            const data = response?.data?.data?.records.map((prop, key) => {
                const VnadorName = prop.vendor;
                var FiVendorName = "";
                if (VnadorName == undefined) {
                    var FiVendorName = "-"
                } else {
                    var FiVendorName = VnadorName.name ? VnadorName.name : '-'
                }
                if (prop.ocrStatus === 'INPROGRESS' || prop.ocrStatus === 'PENDING') {
                    setReloadApi(reloadApi + 1);
                    // getListApiCall()
                }

                return {
                    vendor: (
                        <MDBox>
                            <MDTypography
                                variant="caption" fontWeight="bold" fontSize="0.875rem"
                            >
                                {FiVendorName}
                            </MDTypography>
                        </MDBox>
                    ),
                    invoice: (
                        <MDBox>
                            <MDTypography
                                variant="caption" fontWeight="regular" fontSize="0.875rem" color="info"
                                onClick={async (value) => {
                                    setInvoiceId(prop._id);
                                    setOpenEditInvoice(true);
                                }}
                            >
                                {prop.invoice_number ? prop.invoice_number : prop.docNumber ? prop.docNumber : "-"}
                            </MDTypography>
                        </MDBox>
                    ),
                    phone: prop.phone,
                    uplaoddate: prop.createdAt,
                    status: (prop.documentSource === 'QBO' ? <MDBox>
                        <MDTypography
                            variant="caption" fontWeight="regular" fontSize="0.875rem" style={{
                                color: "#2566e8"
                            }}
                        >
                            {"Synced to Quickbooks"}
                        </MDTypography>
                    </MDBox> : prop.ocrStatus === 'COMPLETED' ?
                        <MDBox>
                            <MDTypography
                                variant="caption" fontWeight="regular" fontSize="0.875rem" color="success"
                            >
                                {'Completed'}
                            </MDTypography>
                        </MDBox> : prop.ocrStatus === 'ERROR' ?
                            <MDBox>
                                <MDTypography
                                    variant="caption" fontWeight="regular" fontSize="0.875rem" color="error"
                                >
                                    {"Error"}
                                </MDTypography>
                            </MDBox> : prop.ocrStatus === 'INPROGRESS' ?
                                <MDBox>
                                    <MDTypography
                                        variant="caption" fontWeight="regular" fontSize="0.875rem" style={{
                                            color: "#ff9800"
                                        }}
                                    >
                                        {"In Progress"}
                                    </MDTypography>
                                </MDBox> :
                                <MDBox>
                                    <MDTypography
                                        variant="caption" fontWeight="regular" fontSize="0.875rem"
                                    >
                                        {"Pending"}
                                    </MDTypography>
                                </MDBox>
                    ),
                    issue: (prop.totalIssues >= 1 ?
                        <MDBox>
                            <MDTypography
                                variant="caption" fontWeight="regular" fontSize="0.875rem" color="error"
                                sx={{ cursor: "pointer" }}
                                onClick={async (value) => {
                                    setLoading(true);
                                    IssueListAPICall({
                                        payload: [{ "businessId": `${businessId}`, "InvoiceId": `${prop._id}` }]
                                    });
                                }}
                            >
                                {prop.totalIssues}
                            </MDTypography>
                        </MDBox> : prop.totalIssues
                    ),
                    action: (
                        prop.status === 'COMPLETED' ?
                            <MDBox>
                                <MDTypography
                                    variant="caption" fontWeight="regular" fontSize="0.875rem" color="info"
                                    onClick={async (value) => {
                                        setInvoiceId(prop._id);
                                        setOpenEditInvoice(true);
                                    }}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <Tooltip title={action.showtooltip} placement="top">
                                        <Icon fontSize="small">visibility</Icon>
                                    </Tooltip>
                                </MDTypography>
                            </MDBox>
                            :
                            <MDBox>
                                <MDTypography
                                    variant="caption" fontWeight="regular" fontSize="0.875rem" color="info"
                                    onClick={async (value) => {
                                        setInvoiceId(prop._id);
                                        setErrorOpenEditInvoice(true);
                                    }}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <Tooltip title={action.showtooltip} placement="top">
                                        <Icon fontSize="small">visibility</Icon>
                                    </Tooltip>
                                </MDTypography>
                            </MDBox>
                    ),
                    id: prop._id,
                };
            }
            );
            setInvoiceList(data);
            setLoading(false);
            setLoadingCircul(false);
        }
    }, [response]);

    // stop realod Api
    useEffect(() => {
        const fetchData = async () => {
            const ThisOffsetuse = localStorage.getItem("offset");
            const select = localStorage.getItem("Tabs")
            const selectActive = select ? select : selectTab;
            await setAPICall({
                payload: [{ businessId: `${businessId}`, "invoiceStatus": selectActive, 'offset': ThisOffsetuse }]
            });
        };

        const timer = setTimeout(() => {
            if (checkIdDevServer !== 'true') {
                fetchData();
            }
        }, 180000);

        return () => clearTimeout(timer);
    }, [reloadApi]);

    const dataTableData = {

        columns: [
            {
                Header: "Vendor",
                accessor: "vendor",
                width: 90,
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Invoice",
                accessor: "invoice",
                Cell: ({ value }) => <a style={{ cursor: 'pointer', color: 'blue' }} >{value} </a>,
            },
            {
                Header: "Upload Date",
                accessor: "uplaoddate",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Issue",
                accessor: "issue",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
        ],
        rows: InvoiceList
    }


    const handleSearch = async (event) => {
        localStorage.setItem("offset", 0);
        setSearch(event.target.value);
        window.history.pushState('data', 'title', `${pathname}?keyword=${encodeURIComponent(event.target.value)}`);
        setLoadingCircul(true);
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
        }
    }

    const deleteInvoiceConfirmMessage = () => {
        setAlertDeleteInvoice(true);
    };
    const hideInvoiceAlert = () => {
        setAlertDeleteInvoice(false);
    };
    const successInoiceDelete = () => {
        DeleteInvoiceListAPICall({
            payload: [
                {
                    "documentIds": {
                        "documentIds": SelectedRows,
                    },
                    "businessId": `${businessId}`,
                }
            ]
        });
        setLoading(true);
    }

    const handleDeleteInvoice = () => {
        deleteInvoiceConfirmMessage();
    }

    const moveInvoiceConfirmMessage = () => {
        setalertMoveInvoice(true);
    };
    const hideMoveAlert = () => {
        setalertMoveInvoice(false);
    };
    const successInoiceMove = async () => {
        await MoveMenuListAPICall({
            payload: [
                {
                    "documentIds": {
                        "documentIds": SelectedRows,
                        "documentStatus": tabsMove,
                    },
                    "businessId": `${businessId}`,
                }
            ]
        })
        setLoading(true);
    }


    useEffect(() => {
        setSelectedInvoice(SelectedRows.length);
        if (Object.keys(storeResponce).length > 0) {
            storeResponce.data.data.records.map((prop, key) => {
                if (prop._id === SelectedRows[0]) {
                    setStoreInvoiceNumber(prop?.docNumber !== '---' ? prop?.docNumber : '');
                }
            })
        }
    }, [SelectedRows, storeResponce])

    const hideIssueAlert = () => {
        const ThisOffsethide = localStorage.getItem("offset");
        setAPICall({
            payload: [{ businessId: `${businessId}`, "invoiceStatus": selectTab, 'offset': ThisOffsethide }]
        });
        setLoading(true);
        setalertIssueInvoice(false);
    };

    eventBus.$on('closeandremovealldata', (data) => Closeandremovealldata(data));
    function Closeandremovealldata(data) {
        setOnIssueClick(false);
        setalertIssueInvoice(false);
    }

    const reloadApiAfterClose = () => {
        setOnIssueClick(false);
        setalertIssueInvoice(false);
        setOpenEditInvoice(false);
        removeQueryParams();
    };

    const closeIssuePopup = () => {
        setOnIssueClick(false);
        setalertIssueInvoice(false);
        setOpenEditInvoice(false)
    };
    function EnhancedTableToolbar(props) {
        const { numSelected } = props;

        return (
            <>
                {tableEmpty !== 0 && ShowMoveIcon && selectUseEffect > 0 ? (
                    <Toolbar
                        style={{ marginLeft: '-15px' }}
                    >
                        {canAccess(actions = 'delete', resource = 'documents') && selectUseEffect > 0 ? (
                            <Tooltip title="Delete">
                                <IconButton onClick={handleDeleteInvoice}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        ) : null}
                    </Toolbar>) : null}
            </>
        );
    }

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    };
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '0',
        boxShadow: 24,
        outline: 'none',
        p: 4,
    };

    function splitCamelCaseToString(s) {
        return s.split(/(?=[A-Z])/).join(' ');
    }

    const openMenu = (event) => {
        setAnchor(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchor(null);
    };

    eventBus.$on('ShowAsignClientPopup', (data) => ShowAsignClientPopup(data));
    function ShowAsignClientPopup(data) {
        setLoading(true);
        setOpenEditInvoice(false);
        eventBus.$emit("ShowAsignClientPopup1", true);
    }


    const oncloseDialog = () => {
        setLoading(true);
        setOpenEditInvoice(false);
        // ClientUserAPICall({
        //     payload: [{ "keyword": `` }]
        // })
        const ThisOffsetclose = localStorage.getItem("offset");
        setAPICall({
            payload: [{ "businessId": businessId, "invoiceStatus": selectTab, 'offset': ThisOffsetclose }]
        });
        CountUserAPICall({
            payload: [{ "businessId": businessId }]
        });
    };

    const oncloseDialog1 = () => {
        setLoading(true);
        const ThisOffsetclose1 = localStorage.getItem("offset");
        setAPICall({
            payload: [{ "businessId": businessId, "invoiceStatus": selectTab, 'offset': ThisOffsetclose1 }]
        });
        CountUserAPICall({
            payload: [{ "businessId": businessId }]
        });
    };

    const onMenuItemClick = async (event, key) => {
        setAnchor(null);
        if (selectTab == "Approved") {
            setShowApprovedModel(true);
        } else {
            const selectMoveTab = (event.target.textContent).replace(/ /g, "");
            const IssueTotal = SelectedRows.map((prop, key) => (
                response?.data?.data?.records.find(obj => {
                    return obj._id === prop
                })
            ))
            const finalIssue = IssueTotal.find(obj => {
                return obj?.totalIssues > 0
            })
            if (ownerAssigned === false && selectMoveTab === 'AskClient') {
                setAssignNewClient(true);
            } else if (selectMoveTab === 'Approved' && finalIssue) {
                setIssueExist(true);
            } else {
                setTabsMove(selectMoveTab);
                setalertMoveInvoice(true);
            }
        }

    };

    const AsignNewClientMsg = () => {
        return <MDBox sx={{ ml: 4, mb: -1 }}>
            <MDTypography >
                No email has been associated with this client account.
            </MDTypography>
            <MDTypography display='flex' justifyContent='center'>
                Click here to add and email for this client
            </MDTypography>
        </MDBox>
    }

    const successAssignNewClient = (move) => {
        setAssignNewClient1(true);
        setAssignNewClient(false);
        // eventBus.$emit("refreshApiInvoiceList", true);
        // eventBus.$emit("ShowAsignClientPopup", true);
    }
    useEffect(() => {
        const arr = [];
        SelectedRows.forEach(function (i) {
            something(i);
        });
        function something(i) {
            const Issue = response?.data?.data?.records.find(obj => {
                return obj._id === i;
            });
            arr.push(Issue);
        }
        const FinalIssues = arr.find(obj => {
            return obj?.totalIssues > 0;
        });
        setIssuesExist(FinalIssues === undefined)
    }, [SelectedRows])

    const tabRowsDataToReview =
        [
            {
                key: {
                    AskClient: "Ask Client",
                    ToReview: 'To Review',
                    Excluded: 'Excluded',
                },
                Tab: 'Classify',
                Permission: ["Owner", "Admin", "Manager", "User"]
            },
            {
                key: {
                    Classify: 'Classify',
                },
                Tab: 'AskClient',
                Permission: ["Owner", "Admin", "Manager"]
            },
            {
                key: {
                    Classify: 'Classify',
                    PendingApproval: 'Pending Approval',
                },
                Tab: 'ToReview',
                Permission: ["Owner", "Admin", "Manager"]
            },
            {
                key: {
                    Classify: 'Classify',
                    Approved: 'Approved',
                },
                Tab: 'PendingApproval',
                Permission: ["Owner", "Admin", "Manager"]
            },
            {
                key: {
                    Classify: 'Classify',
                },
                Tab: 'Approved',
                Permission: ["Owner", "Admin", "Manager"]
            },
            {
                key: {
                    Classify: 'Classify',
                },
                Tab: 'Excluded',
                Permission: ["Owner", "Admin", "Manager"]
            },
            {
                key: {

                },
                Tab: 'AllDoc',
                Permission: [""]
            },
        ]

    const tabRowsData =
        [
            {
                key: {
                    AskClient: "Ask Client",
                    Excluded: 'Excluded',
                },
                Tab: 'Classify',
                Permission: ["Owner", "Admin", "Manager", "User"]
            },
            {
                key: {
                    Classify: 'Classify',
                },
                Tab: 'AskClient',
                Permission: ["Owner", "Admin", "Manager"]
            },
            {
                key: {
                    Classify: 'Classify',
                    PendingApproval: 'Pending Approval',
                },
                Tab: 'ToReview',
                Permission: ["Owner", "Admin", "Manager"]
            },
            {
                key: {
                    Classify: 'Classify',
                    Approved: 'Approved',
                },
                Tab: 'PendingApproval',
                Permission: ["Owner", "Admin", "Manager"]
            },
            {
                key: {
                    Classify: 'Classify',
                },
                Tab: 'Approved',
                Permission: ["Owner", "Admin", "Manager"]
            },
            {
                key: {
                    Classify: 'Classify',
                },
                Tab: 'Excluded',
                Permission: ["Owner", "Admin", "Manager"]
            },
            {
                key: {

                },
                Tab: 'AllDoc',
                Permission: [""]
            },
        ]

    const FinalTabData = IssuesExist ? tabRowsDataToReview : tabRowsData;
    const moveSelect = FinalTabData.find(obj => {
        return obj.Tab === selectTab ? obj.Tab === selectTab : obj.Tab === 'AllDoc';
    });
    const ShowMoveIcon = moveSelect.Permission.includes(movePermmison);

    const RemindMeFun = async () => {
        // cookies.set('remind_add_client', true, { path: '/', expires: expirationDate });
        cookies.set('remind_add_client', true, { path: '/', maxAge: 3600 });
        cookies.set('backup_remind_add_client', true, { path: '/', maxAge: 3600 });
        setOpenNoClient(false)
    };

    useEffect(() => {
        if (openNoInvoice === true) {
            const profileInfo11 = cookies.get('remind_add_client');
            const profileInfo12 = cookies.get('backup_remind_add_client');
            setOpenNoClient(!profileInfo11)
            if (profileInfo12 === undefined) {
                cookies.set('remind_add_client', false, { path: '/', maxAge: 3600 });
            }
            if (profileInfo12 === undefined) {
                setOpenNoClient(true)
            } else if (profileInfo11 === false) {
                setOpenNoClient(true)
            }
        }

    }, [openNoInvoice])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={4}>
                <Card sx={loaderClass}>
                    <MDBox display="flex" justifyContent="center" >
                        {loading && <Loader />}
                    </MDBox>
                    <MDBox sx={{ mt: 2 }} component="form" display="flex" justifyContent="space-between">
                        <MDBox mb={0} display="flex" >
                            {showSelectBusiness ?
                                <FormControl variant="standard" sx={{ pl: 3, minWidth: 150 }} fullWidth >
                                    <InputLabel sx={{ pl: 4 }} htmlFor="demo-simple-select-standard" id="demo-simple-select-standard-label">
                                        Client
                                    </InputLabel>
                                    <Select
                                        aria-required="true"
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={showselectedrowsData}
                                        onChange={handleChangeBusiness}
                                        label="Select Client"
                                    >
                                        {showrowsData.map((prop, key) => (
                                            <MenuItem
                                                value={prop[1]}
                                            >
                                                {prop[1]}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl> :
                                <MDTypography pt={3} px={2} sx={{ pr: 3, mb: 2 }} variant="h5" fontWeight="medium">
                                    Documents
                                </MDTypography>
                            }
                            {canAccess(actions = 'update', resource = 'documents') && tableEmpty !== 0 && ShowMoveIcon && selectUseEffect > 0 ? (
                                <MDBox>
                                    <Tooltip title={action.Movetooltip}>
                                        <IconButton
                                            onClick={openMenu}
                                            sx={{ ml: 2, mt: 1.5 }}
                                            aria-controls={openMenu ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openMenu ? 'true' : undefined}
                                        >
                                            <DriveFileMoveIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        open={Boolean(anchor)}
                                        anchorEl={anchor}
                                        onClose={closeMenu}
                                        keepMounted
                                    >
                                        {
                                            Object.keys(moveSelect.key).map((key, index) => (
                                                <MenuItem
                                                    key={index}
                                                    onClick={(event) => onMenuItemClick(event, key)}
                                                // selected={index === selected}
                                                >
                                                    {moveSelect.key[key]}
                                                </MenuItem>
                                            ))
                                        }
                                        {/* {moveSelect?.Key.map((option, index) => (
                                            <MenuItem
                                                key={index}
                                                onClick={(event) => onMenuItemClick(event, index)}
                                            // selected={index === selected}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))} */}
                                    </Menu>
                                </MDBox>

                            ) : null}
                            <EnhancedTableToolbar numSelected={SelectedRows.length} />
                        </MDBox>

                        <MDBox px={2} display="flex" ></MDBox>

                        <MDBox px={2} display="flex" >
                            <MDBox sx={{ pr: 3, mb: 2 }}>
                                <MDInput type="search" label="Search Document" onKeyDown={handleKeyDown} variant="standard" onChange={handleSearch} />
                            </MDBox>
                            <MDBox>
                                {canAccess(actions = 'create', resource = 'documents') && <MDButton
                                    variant="gradient"
                                    color="info"
                                    disabled={!uploadPermison}
                                    onClick={() => setOpenInvoice(true)}
                                >  Upload Documents
                                </MDButton>
                                }

                            </MDBox>

                        </MDBox>
                    </MDBox>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label={`Classify (${(countClassify)})`} value="Classify" />
                                <Tab label={`Ask Client (${(countAskClient)})`} value="AskClient" />
                                <Tab label={`To Review (${(countToReview)})`} value="ToReview" />
                                <Tab label={`Pending Approval (${(countPendingApproval)})`} value="PendingApproval" />
                                <Tab label={`Approved (${(countApproved)})`} value="Approved" />
                                <Tab label={`Excluded (${(countExcluded)})`} value="Excluded" />
                                <Tab label={`All Documents (${(countAll)})`} value="" />
                            </Tabs>
                        </Box>
                        <TabPanel>
                            <MDBox >
                                {
                                    tableEmpty == '0' ?
                                        <MDBox my={3} sx={{ mt: 1 }}>
                                            <DataTable1 table={dataTableData} entriesPerPage={false} pagination={false} showTotalEntries={true} />
                                            {uploadPermison ?
                                                <MDTypography
                                                    variant="body2"
                                                    color="secondary"
                                                    sx={{ marginLeft: "43%", fontSize: "1.20rem", mt: 2 }}
                                                >
                                                    {selectTab.length === 0 ? '' : 'No Document in this tab'}
                                                </MDTypography>
                                                :
                                                <MDBox>
                                                    <MDTypography
                                                        variant="body2"
                                                        color="secondary"
                                                        sx={{
                                                            width: '100%', fontSize: "1.20rem", display: 'flex',
                                                            justifyContent: 'center', mt: 2
                                                        }}
                                                    >
                                                        {`Please bear with us while we retrieve information from QBO. This process may take a few moments...`}
                                                    </MDTypography>
                                                    <MDBox sx={{
                                                        width: '100%', display: 'flex',
                                                        justifyContent: 'center', fontSize: "1.20rem"
                                                    }}>
                                                        <div>
                                                            <img width={23} height={23} style={{ marginBottom: '-6px', marginRight: '8px' }} src={image} />
                                                        </div>
                                                        <MDTypography sx={{ mr: 2 }} fontSize="1.20rem" color={'warning'} >({syncPercent}% completed)</MDTypography>
                                                    </MDBox>
                                                </MDBox>
                                            }
                                        </MDBox>
                                        :
                                        <MDBox my={3} sx={{ mt: 1, mb: -2 }}>
                                            <DataTable table={dataTableData} entriesPerPage={false} pagination={true} showTotalEntries={true} selectTab={selectTab} response={tableEmpty} />
                                        </MDBox>
                                }
                            </MDBox>
                        </TabPanel>
                    </Box>
                </Card>
            </MDBox >
            <MDBox>
                <Modal
                    open={openSelectInvoice}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}  >
                        <MDBox sx={{ pl: 0, pb: 5 }} display="flex" justifyContent="center">
                            <FormControl variant="standard" sx={{ minWidth: 150, mt: 1.5 }}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    ListboxProps={{ style: { maxHeight: "15rem" }, position: "bottom-start" }}
                                    options={autoCompleteObj}
                                    sx={{
                                        width: 300
                                    }}
                                    onChange={(event, newValue) => {
                                        setClientNewValue(newValue);
                                        setClientEvent(event);
                                    }}

                                    renderInput={(params) =>
                                        <TextField {
                                            ...params
                                        }
                                            label="Select Client"
                                            variant="standard"
                                            sx={{
                                                "& .MuiFormLabel-root": {
                                                    marginTop: '5px',
                                                }
                                            }
                                            }
                                        />}
                                />
                            </FormControl>
                        </MDBox>
                        <MDBox sx={{
                            justifyContent: "end",
                            display: "flex"
                        }}>
                            <MDButton onClick={handleCloseSelect}>Close</MDButton>
                            <MDButton variant="gradient"
                                color="info" onClick={() => handleChangeBusiness(clientEvent, clientNewValue)}>
                                submit
                            </MDButton>
                        </MDBox>
                    </Box>
                </Modal>
                <Modal
                    open={showApprovedModel}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}  >
                        <MDBox sx={{ pl: 0, pb: 5, mb: -1 }} display="flex" justifyContent="center">
                            <MDTypography
                                component="span"
                                variant="body2"
                                color="dark"
                            >
                                To move this document you must delete it from your QBO account.
                            </MDTypography>
                        </MDBox>
                        <MDBox sx={{
                            justifyContent: "end",
                            display: "flex",
                            mb: -2.5
                        }}>
                            <MDButton onClick={() => setShowApprovedModel(false)}>Close</MDButton>
                        </MDBox>
                    </Box>
                </Modal>
                <MDBox>
                    <Modal
                        open={openNoInvoice}
                        // onClose={() => setOpenNoInvoice(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={Modalstyle}>
                            <MDBox display='flex' justifyContent="end" style={{ cursor: 'pointer' }} >
                                <MDTypography
                                    component="span"
                                    variant="body2"
                                    color="dark"
                                    opacity={1}
                                    sx={{ lineHeight: 0, outline: 'none' }}
                                    onClick={() => navigate('/accountant/client/list')}
                                >
                                    <Icon>close</Icon>
                                </MDTypography>
                            </MDBox>
                            <MDBox display='flex' justifyContent="center" mb={3} >
                                <img
                                    className="small"
                                    src={logo}
                                    alt="no image"
                                    height='80px'
                                    width='80px'
                                />
                            </MDBox>
                            <Typography sx={{ mt: 2 }} display={'flex'} justifyContent={'center'} id="modal-modal-title" variant="h5" component="h2">
                                Let’s add your first client to get the most use out of Paynthr
                            </Typography>
                            <Typography variant="body2" id="modal-modal-description" sx={{ mt: 4, ml: 1 }}>
                                To initiate the synchronization with QuickBooks, we'll need to obtain a set of default accounts, vendors, and items for your QuickBooks account. Our system will seamlessly map your QuickBooks data to the corresponding Paynthr account. The mapping process can start after establishing the
                            </Typography>
                            <Typography display={'flex'} justifyContent={'center'} variant="body2" id="modal-modal-description">
                                connection.
                            </Typography>
                            <MDBox display='flex' justifyContent='center' sx={{ mt: 4 }}>
                                <ConnectToQuickBooksOnlineButton buttonType={'Icon'} accountantId={encodeURI(profileInfo?.user?._id)} accountantDomain={encodeURI(profileInfo?.user?.domainKey)} accountantReturnUrl={ReturnUrl} />
                            </MDBox>
                        </Box>
                    </Modal>
                </MDBox>
                <MDBox>
                    <Modal
                        open={uploadInvoice}
                        // onClose={() => setOpenNoInvoice(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={Modalstyle}>
                            <MDBox display='flex' justifyContent="end" style={{ cursor: 'pointer' }} >
                                <MDTypography
                                    component="span"
                                    variant="body2"
                                    color="dark"
                                    opacity={1}
                                    sx={{ lineHeight: 0, outline: 'none' }}
                                    onClick={() => setUploadInvoice(false)}
                                >
                                    <Icon>close</Icon>
                                </MDTypography>
                            </MDBox>
                            <MDBox display='flex' justifyContent="center" mb={3} >
                                <img
                                    className="small"
                                    src={logo}
                                    alt="no image"
                                    height='50px'
                                    width='50px'
                                />
                            </MDBox>
                            <Typography sx={{ mt: 2 }} display={'flex'} justifyContent={'center'} id="modal-modal-title" variant="h5" component="h2">
                                Document upload is successful
                            </Typography>
                            <Typography variant="body2" id="modal-modal-description" sx={{ mt: 4, ml: 1, mb: 4 }}>
                                We are now processing your documents. Once all your documents are processed we will send you an email at your registered email address. You can also check the Document list page as it auto refreshes every 3 mins to check the processing status of your uploaded document.
                            </Typography>
                        </Box>
                    </Modal>
                </MDBox>
                <MDBox>
                    <Dialog
                        fullWidth='true'
                        maxWidth='sm'
                        TransitionComponent={Transition}
                        open={openInvoice}
                    // onClose={handleClose}
                    >
                        <DialogTitle align="center">
                            <h3>Upload Document</h3>
                        </DialogTitle>
                        <DialogContent >
                            <NewInvoiceDialog invoiceId={invoiceId} businessId={businessId} />
                            <DialogContentText align="center" ><span style={{ fontSize: `0.99rem` }}>Upload type must be pdf .png .jpg or .jpeg</span></DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <MDButton disabled={disButton} onClick={handleClose}>Close</MDButton>
                        </DialogActions>
                    </Dialog>
                </MDBox>
                <MDBox>
                    <Dialog
                        fullScreen
                        open={openEditInvoice}
                        onClose={() => setOpenEditInvoice(false)}
                        TransitionComponent={Transition}
                    >
                        <DialogContent sx={{ padding: '10px 0px 0px 0px' }}>
                            <EditInvoiceDialog selectedIndex={selectedId} issueType={issueType} clientAssigned={clientAssigned} invoiceIds={invoiceId} businessId={businessId} onIssueClick={onIssueClick} onIssueClickData={onIssueClickData} documentsId={documentsId} onClickClose={() => closeIssuePopup()} moduleTypeEdit={moduleTypeEdit} selectTab={selectTab} onClose={oncloseDialog} onClose1={oncloseDialog1} storeResponce={storeResponce} reloadApiAfterClose={reloadApiAfterClose} />
                        </DialogContent>
                    </Dialog>
                </MDBox>
                <MDBox>
                    <Dialog
                        fullScreen
                        open={openErrorEditInvoice}
                        onClose={() => setErrorOpenEditInvoice(false)}
                        TransitionComponent={Transition}
                    >
                        <Toolbar style={{ minHeight: '45px', backgroundColor: 'white' }}>
                            <Typography variant="h6" className={classes.title}>

                            </Typography>
                            <Icon sx={{ cursor: "pointer" }} onClick={() => setErrorOpenEditInvoice(false)}>close</Icon>
                        </Toolbar>
                        <DialogContent>
                            <ErrorEditInvoiceDialog invoiceId={invoiceId} businessId={businessId} />
                        </DialogContent>
                    </Dialog>
                </MDBox>
                <MDBox>
                    <MDSnackbar
                        color="error"
                        icon="warning"
                        title="Failed"
                        content="Please Select Valid Document."
                        open={showSnackbar}
                        close={() => setShowSnackbar(false)}
                    />
                </MDBox>
                <MDBox>
                    <Dialog
                        open={alertDeleteInvoice}
                        onClose={hideInvoiceAlert}
                        aria-labelledby="alert-dialog-title"
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    // height: "40%",
                                    maxWidth: "500px",
                                },
                            },
                        }}
                    >
                        <DialogContent>
                            <DialogContentText sx={{ mt: -2, display: "flex", justifyContent: "center" }}  >
                                <MDTypography
                                    variant="body2"
                                    color="secondary"
                                    sx={{ fontSize: 70 }}
                                >
                                    <WarningAmberIcon sx={{ color: yellow[500], fontSize: 50 }} />
                                </MDTypography>

                            </DialogContentText>
                            <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                                {selectedInvoice > 1 ? <h3>Are you sure you want to delete {selectedInvoice} invoices?</h3> : StoreInvoiceNumber ? <h3> Are you sure you want to delete invoice {StoreInvoiceNumber}? </h3> : <h3> Are you sure you want to delete this invoice? </h3>}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "center" }} >
                            <MDButton
                                variant="gradient"
                                color="success"
                                onClick={successInoiceDelete} >
                                Yes, delete it!
                            </MDButton>
                            <MDButton
                                variant="gradient"
                                color="error"
                                onClick={hideInvoiceAlert}
                            >Cancel
                            </MDButton>
                        </DialogActions>
                    </Dialog>
                </MDBox>
                <MDBox>
                    <Dialog
                        open={alertMoveInvoice}
                        onClose={hideMoveAlert}
                        aria-labelledby="alert-dialog-title"
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    // height: "40%",
                                    maxWidth: "500px",
                                },
                            },
                        }}
                    >
                        <DialogContent>
                            <DialogContentText sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                                {selectedInvoice > 1 ? <h3>Are you sure you want to move {selectedInvoice} invoices?</h3> : StoreInvoiceNumber ? <h3> Are you sure you want to move invoice {StoreInvoiceNumber}? </h3> : <h3> Are you sure you want to move this invoice? </h3>}
                            </DialogContentText>
                            <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 1 }}  >
                                <MDTypography
                                    variant="body1"
                                    color="info"
                                    sx={{ p: 2, justifyContent: "center", display: 'flex', flexDirection: 'column' }}
                                >
                                    {splitCamelCaseToString(selectTab)}
                                </MDTypography>
                                <MDTypography
                                    variant="body2"
                                    color="#037ffd"
                                    sx={{ fontSize: 40, justifyContent: "center", display: 'flex', flexDirection: 'column' }}
                                > <DriveFileMoveIcon sx={{ color: "#037ffd", fontSize: 40 }} />
                                </MDTypography>
                                <MDTypography
                                    variant="body1"
                                    color="info"
                                    sx={{ p: 2, justifyContent: "center", display: 'flex', flexDirection: 'column' }}
                                >
                                    {splitCamelCaseToString(tabsMove)}
                                </MDTypography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "center" }} >
                            <MDButton
                                variant="gradient"
                                color="success"
                                onClick={successInoiceMove} >
                                Yes, Move it!
                            </MDButton>
                            <MDButton
                                variant="gradient"
                                color="error"
                                onClick={hideMoveAlert}
                            >Cancel
                            </MDButton>
                        </DialogActions>
                    </Dialog>
                </MDBox>
                <MDBox>
                    <Dialog
                        open={alertIssueInvoice}
                        onClose={hideIssueAlert}
                        aria-labelledby="alert-dialog-title"
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    maxHeight: "50%",
                                    maxWidth: "500px",
                                },
                            },
                        }}
                    >
                        <DialogTitle id="alert-dialog-title" sx={{
                            justifyContent: "center",
                            display: 'flex'
                        }}>
                            {"Issues"}
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <Demo>
                                        <List sx={{ mt: 4, mb: 2, flexBasis: '95%!important' }} >
                                            {generate()}
                                        </List>
                                    </Demo>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <MDButton onClick={hideIssueAlert}>Close</MDButton>
                        </DialogActions>
                    </Dialog>
                </MDBox>
                <MDBox>
                    <Dialog
                        open={assignNewClient1}
                        onClose={() => setAssignNewClient1(false)}
                        aria-labelledby="alert-dialog-title"
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    maxWidth: "500px",
                                },
                            },
                        }}
                    >   <DialogTitle sx={{
                        justifyContent: "center",
                        display: 'flex'
                    }}>Add Owner For - {ownerName}</DialogTitle>
                        <DialogContent>
                            <AddOwnerDetailsDialog businessId={businessId} />
                        </DialogContent>
                    </Dialog>
                </MDBox>
                <MDBox>
                    <MDSnackbar
                        color="success"
                        icon="done"
                        title="Success"
                        content="Documents moved successfully."
                        open={showMoveSnackbar}
                        close={() => setShowMoveSnackbar(false)}
                    />
                </MDBox>
                <MDBox>
                    <MDSnackbar
                        color="success"
                        icon="done"
                        title="Success"
                        content="Document Deleted Successfully."
                        open={showDeleteSnackbar}
                        close={() => setShowDeleteSnackbar(false)}
                    />
                </MDBox>
                <MDBox>
                    <MDSnackbar
                        color="error"
                        icon="warning"
                        title="Failed"
                        content={"Invalid Document"}
                        open={invalidDocumnet}
                        close={() => setInvalidDocumnet(false)}
                    />
                </MDBox>
                <MDBox>
                    <MDSnackbar
                        color="error"
                        icon="warning"
                        title="Failed"
                        content={moveErrorMessage}
                        open={showErrorMoveSnackbar}
                        close={() => setShowErrorMoveSnackbar(false)}
                    />
                </MDBox>
                <MDBox>
                    <MDSnackbar
                        color="error"
                        icon="warning"
                        title="Failed"
                        content={deleteErrorMessage}
                        open={showErrorDeleteSnackbar}
                        close={() => setShowErrorDeleteSnackbar(false)}
                    />
                </MDBox>
                <MDBox>
                    <MDSnackbar
                        color={titleShow.toLowerCase()}
                        icon={iconShow}
                        title={titleShow}
                        content={completemassage}
                        open={showCompleteSnackbar}
                        close={() => setShowCompleteSnackbar(false)}
                    />
                </MDBox>
                <MDBox>
                    <CustomDialog2
                        DialogContentText={() => AsignNewClientMsg()}
                        open={assignNewClient}
                        onClose={() => setAssignNewClient(false)}
                        onSuccess={successAssignNewClient}
                        Title={''}
                        center={true}
                        height={'27%'}
                        buttonOk={'Assign client'}
                        button={'Cancel'}
                    />
                </MDBox>
                <MDBox>
                    <CustomDialog
                        DialogContentText={() => IssueIsExisttext()}
                        open={issueExist}
                        onClose={() => setIssueExist(false)}
                        onSuccess={null}
                        Title={''}
                        // height={'27%'}
                        button={'Close'}
                    />
                </MDBox>
                <MDBox>
                    {loadingCircul && (
                        <CircularProgress
                            size={50}
                            sx={{
                                color: green[500],
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                </MDBox>
            </MDBox>
        </DashboardLayout >
    );


}
export default UserHoc(AccountantInvoice);