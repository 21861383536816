// export default function QboAccountTypes() {
export const data = [{
    "classifications": [
        {
            "name": "Asset",
            "accountTypes": [
                {
                    "name": "Bank",
                    "subTypes": [
                        { "name": "CashOnHand" },
                        { "name": "Checking" },
                        { "name": "MoneyMarket" },
                        { "name": "RentsHeldInTrust" },
                        { "name": "Savings" },
                        { "name": "TrustAccounts" }
                    ]
                },
                {
                    "name": "Other Current Asset",
                    "subTypes": [
                        { "name": "EmployeeCashAdvances" },
                        { "name": "AllowanceForBadDebts" },
                        { "name": "DevelopmentCosts" },
                        { "name": "OtherCurrentAssets" },
                        { "name": "Inventory" },
                        { "name": "Investment_MortgageRealEstateLoans" },
                        { "name": "Investment_Other" },
                        { "name": "Investment_TaxExemptSecurities" },
                        { "name": "Investment_USGovernmentObligations" },
                        { "name": "LoansToOfficers" },
                        { "name": "LoansToOthers" },
                        { "name": "LoansToStockholders" },
                        { "name": "PrepaidExpenses" },
                        { "name": "Retainage" },
                        { "name": "UndepositedFunds" }
                    ]
                },
                {
                    "name": "Fixed Asset",
                    "subTypes": [
                        { "name": "FurnitureAndFixtures" },
                        { "name": "AccumulatedDepletion" },
                        { "name": "AccumulatedDepreciation" },
                        { "name": "DepletableAssets" },
                        { "name": "FixedAssetComputers" },
                        { "name": "FixedAssetCopiers" },
                        { "name": "FixedAssetFurniture" },
                        { "name": "FixedAssetPhone" },
                        { "name": "FixedAssetPhotoVideo" },
                        { "name": "FixedAssetSoftware" },
                        { "name": "FixedAssetOtherToolsEquipment" },
                        { "name": "Land" },
                        { "name": "LeaseholdImprovements" },
                        { "name": "OtherFixedAssets" },
                        { "name": "AccumulatedAmortization" },
                        { "name": "Buildings" },
                        { "name": "IntangibleAssets" },
                        { "name": "MachineryAndEquipment" },
                        { "name": "Vehicles" }
                    ]
                },
                {
                    "name": "Other Asset",
                    "subTypes": [
                        { "name": "Licenses" },
                        { "name": "LeaseBuyout" },
                        { "name": "OtherLongTermAssets" },
                        { "name": "SecurityDeposits" },
                        { "name": "AccumulatedAmortizationOfOtherAssets" },
                        { "name": "Goodwill" },
                        { "name": "OrganizationalCosts" }
                    ]
                },
                {
                    "name": "Accounts Receivable",
                    "subTypes": [
                        { "name": "Accounts Receivable" }
                    ]
                }
            ]
        },
        {
            "name": "Equity",
            "accountTypes": [
                {
                    "name": "Equity",
                    "subTypes": [
                        { "name": "OpeningBalanceEquity" },
                        { "name": "PartnersEquity" },
                        { "name": "RetainedEarnings" },
                        { "name": "AccumulatedAdjustment" },
                        { "name": "OwnersEquity" },
                        { "name": "PaidInCapitalOrSurplus" },
                        { "name": "​PartnerContributions" },
                        { "name": "PartnerDistributions" },
                        { "name": "PreferredStock" },
                        { "name": "CommonStock" },
                        { "name": "TreasuryStock" },
                        { "name": "EstimatedTaxes" },
                        { "name": "Healthcare" },
                        { "name": "PersonalIncome" },
                        { "name": "PersonalExpense" }
                    ]
                }
            ]
        },
        {
            "name": "Expense",
            "accountTypes": [
                {
                    "name": "Expense",
                    "subTypes": [
                        { "name": "Travel" },
                        { "name": "AdvertisingPromotional" },
                        { "name": "BadDebts" },
                        { "name": "BankCharges" },
                        { "name": "CharitableContributions" },
                        { "name": "CommissionsAndFees" },
                        { "name": "Entertainment" },
                        { "name": "EntertainmentMeals" },
                        { "name": "EquipmentRental" },
                        { "name": "FinanceCosts" },
                        { "name": "GlobalTaxExpense" },
                        { "name": "Insurance" },
                        { "name": "InterestPaid" },
                        { "name": "LegalProfessionalFees" },
                        { "name": "OfficeExpenses" },
                        { "name": "OfficeGeneralAdministrativeExpenses" },
                        { "name": "OtherBusinessExpenses" },
                        { "name": "OtherMiscellaneousServiceCost" },
                        { "name": "PromotionalMeals" },
                        { "name": "RentOrLeaseOfBuildings" },
                        { "name": "RepairMaintenance" },
                        { "name": "ShippingFreightDelivery" },
                        { "name": "SuppliesMaterials" },
                        { "name": "TravelMeals" },
                        { "name": "Utilities" },
                        { "name": "Auto" },
                        { "name": "CostOfLabor" },
                        { "name": "DuesSubscriptions" },
                        { "name": "PayrollExpenses" },
                        { "name": "TaxesPaid" },
                        { "name": "UnappliedCashBillPaymentExpense" },
                        { "name": "Utilities" }
                    ]
                },
                {
                    "name": "Other Expense",
                    "subTypes": [
                        { "name": "Depreciation" },
                        { "name": "ExchangeGainOrLoss" },
                        { "name": "OtherMiscellaneousExpense" },
                        { "name": "PenaltiesSettlements" },
                        { "name": "Amortization" },
                        { "name": "GasAndFuel" },
                        { "name": "HomeOffice" },
                        { "name": "HomeOwnerRentalInsurance" },
                        { "name": "OtherHomeOfficeExpenses" },
                        { "name": "MortgageInterest" },
                        { "name": "RentAndLease" },
                        { "name": "RepairsAndMaintenance" },
                        { "name": "ParkingAndTolls" },
                        { "name": "Vehicle" },
                        { "name": "VehicleInsurance" },
                        { "name": "VehicleLease" },
                        { "name": "VehicleLoanInterest" },
                        { "name": "VehicleLoan" },
                        { "name": "VehicleRegistration" },
                        { "name": "VehicleRepairs" },
                        { "name": "OtherVehicleExpenses" },
                        { "name": "Utilities" },
                        { "name": "WashAndRoadServices" }
                    ]
                },
                {
                    "name": "Cost of Goods Sold",
                    "subTypes": [
                        { "name": "CostOfLaborCos" },
                        { "name": "EquipmentRentalCos" },
                        { "name": "OtherCostsOfServiceCos" },
                        { "name": "EquipmentRentalCos" },
                        { "name": "ShippingFreightDeliveryCos" },
                        { "name": "SuppliesMaterialsCogs" }
                    ]
                }
            ]
        },
        {
            "name": "Liability",
            "accountTypes": [
                {
                    "name": "Accounts Payable",
                    "subTypes": [
                        { "name": "Accounts Payable" }
                    ]
                },
                {
                    "name": "Credit Card",
                    "subTypes": [
                        { "name": "Credit Card" }
                    ]
                },
                {
                    "name": "Long Term Liability",
                    "subTypes": [
                        { "name": "NotesPayable" },
                        { "name": "OtherLongTermLiabilities" },
                        { "name": "ShareholderNotesPayable" }
                    ]
                },
                {
                    "name": "Other Current Liability",
                    "subTypes": [
                        { "name": "OtherCurrentLiabilities" },
                        { "name": "DirectDepositPayable" },
                        { "name": "LineOfCredit" },
                        { "name": "LoanPayable" },
                        { "name": "GlobalTaxPayable" },
                        { "name": "GlobalTaxSuspense" },
                        { "name": "PayrollClearing" },
                        { "name": "PayrollTaxPayable" },
                        { "name": "PrepaidExpensesPayable" },
                        { "name": "RentsInTrustLiability" },
                        { "name": "TrustAccountsLiabilities" },
                        { "name": "FederalIncomeTaxPayable" },
                        { "name": "InsurancePayable" },
                        { "name": "SalesTaxPayable" },
                        { "name": "StateLocalIncomeTaxPayable" }
                    ]
                }
            ]
        },
        {
            "name": "Revenue",
            "accountTypes": [
                {
                    "name": "Income",
                    "subTypes": [
                        { "name": "OtherPrimaryIncome" },
                        { "name": "NonProfitIncome" },
                        { "name": "SalesOfProductIncome" },
                        { "name": "ServiceFeeIncome" },
                        { "name": "DiscountsRefundsGiven" },
                        { "name": "UnappliedCashPaymentIncome" }
                    ]
                },
                {
                    "name": "Other Income",
                    "subTypes": [
                        { "name": "OtherInvestmentIncome" },
                        { "name": "DividendIncome" },
                        { "name": "InterestEarned" },
                        { "name": "OtherMiscellaneousIncome" },
                        { "name": "TaxExemptInterest" }
                    ]
                }
            ]
        }
    ]
}]
// }