

const initialValues = {
  name: "",
  firstname: "",
  lastname: "",
  businessname: "",
  phone: "",
  company: "",
  email: "",
  password: "",
  repeatPassword: "",
  address1: "",
  address2: "",
  city: "",
  zip: "",
  twitter: "",
  facebook: "",
  instagram: "",
  publicEmail: "",
  bio: "",
  role: "",
  checked: "",
  item: "",
  sku: "",
  price: "",
  total: "",
  qty: "",
  services: "",
  class: "",
  customer: "",
  unit: "",
  category: "",
  features: "",
};

export default initialValues;
