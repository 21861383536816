var moment = require('moment-timezone');
export function inLocalTime(utcTime, format = '') {
    let timeFormat = 'MM/DD/YYYY hh:mm A z';

    if (format != null && format != '') {
        timeFormat = format;
    }
    var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // var testDateUtc = moment(utcTime, 'MM-DD-YYYY HH:mm').utc().format("YYYY-MM-DD HH:mm")
    var testDateUtc = moment.tz(utcTime, tz).format(timeFormat);

    return testDateUtc
}
