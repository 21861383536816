import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography, createTheme } from "@mui/material";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import { GridCellModes, GridToolbarContainer, GridToolbarExport, useGridApiContext } from '@mui/x-data-grid';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import MDBox from "components/MDBox";
import UserHoc from "hoc/userHoc";
import Loader from "layouts/accountants/user/components/imgLoader/loader";
import 'layouts/accountants/user/invoice/styles.css';
import PropTypes from 'prop-types';
import * as React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { EditRapidInvenory, GetInventoryList, UpdateRestaurantOrderList } from "services/clientservices";
import { eventBus } from "utils/eventbus";
import { useAPICall } from "utils/useapicall";
import './styles.css';


const useStyles = makeStyles((theme) => ({
    '@media(max-width: 440px)': {
        tabs: {
            "& button": {
                padding: 8,
                paddingLeft: '5px !important',
                paddingRight: '6px !important',
                fontSize: `12px !important`,
                fontWeight: 400,
                textTransform: 'none',
                color: theme.palette.text.primary,
                border: "1px solid gray",
                borderRadius: '1px !important'
            },

            "& button[aria-selected='true']": {
                position: "relative",

                "&:before": {
                    content: '""',
                    position: "absolute",
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    border: "1px solid black",
                    zIndex: 0
                },

                "& > *": { zIndex: 0 },
                "& > .MuiTab-wrapper": {
                    background: "#fff",
                    height: "100%"
                }
            }
        }
    },
    tabs: {

        "& button": {
            padding: 8,
            paddingLeft: '20px',
            paddingRight: '20px',
            fontSize: 14,
            fontWeight: 500,
            textTransform: 'none',
            color: theme.palette.text.primary,
            border: "1px solid gray",
            borderRadius: '1px !important'
        },

        "& button[aria-selected='true']": {
            position: "relative",

            "&:before": {
                content: '""',
                position: "absolute",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                border: "1px solid black",
                zIndex: 0
            },

            "& > *": { zIndex: 0 },
            "& > .MuiTab-wrapper": {
                background: "#fff",
                height: "100%"
            }
        }
    }
}));
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const myTheme = createTheme({
    palette: {
        type: 'dark'
    },
    typography: {
        fontSize: 14,
        fontWeight: 400,
        backgroundColor: 'rgb(123, 128, 154)'
    },
    components: {
        //@ts-ignore - this isn't in the TS because DataGird is not exported from `@mui/material`
        MuiDataGrid: {
            styleOverrides: {
                row: {
                    '& .MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    '& .MuiDataGrid-actionsCell': {
                        gridGap: '0px'
                    },
                    '& .MuiDataGrid-cell': {
                        justifyContent: "center",
                        outline: ' none',
                        fontWeight: 'bold !important',
                        color: 'rgb(123 128 154)',
                        // border: 0,
                    },
                    '& .MuiDataGrid-cell:focus': {
                        outline: ' none',
                    },
                    "&.Mui-selected": {
                        backgroundColor: "white",
                        color: "black",
                        "&:hover": {
                            backgroundColor: "white"
                        }
                    }
                }
            }
        }
    }
});


function RapidInventoryList({ businessId, restaurantId, locationId }) {
    const resId = location.pathname.split("/").slice(-2)[0];
    const gridRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [loadingCircul, setLoadingCircul] = useState(false);
    const [search, setSearch] = useState('');
    const [loaderClass, setLoaderClass] = useState({});
    const { pathname } = useLocation();
    const [paginationModel, setPaginationModel] = React.useState({ pageSize: 10, page: 0, });
    const [openDetailsInventory, setOpenDetailsInventory] = useState(false);
    const [itemId, setItemId] = useState('');
    const [cellModesModel, setCellModesModel] = React.useState({});
    const [rowManage, setRowManage] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [updateInventory, setUpdateInventory] = useState('0');
    const [loadPage, setLoadPage] = useState(true);
    const [updateLoader, setUpdateLoader] = useState();
    const [totalRecords, setTotalRecords] = useState(0);
    const [showPagination, setShowPagination] = useState(false);
    const [updateinventoryId, setUpdateinventoryId] = useState([]);
    const [moveStartIndex, setMoveStartIndex] = useState();
    const [moveStopIndex, setMoveStopIndex] = useState();
    const [inventoryList, setInventoryList] = useState([]);
    const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetInventoryList, { onLoad: false });
    const [{ isLoading: isEditRapidLoading, isError: isEditRapidError, response: EditRapidResponse }, EditRapidListAPICall] = useAPICall(EditRapidInvenory, { onLoad: false });
    const [{ isLoading: isUpdateListOrderLoading, isError: isUpdateListOrderError, response: UpdateListOrderResponse }, UpdateListOrderListAPICall] = useAPICall(UpdateRestaurantOrderList, { onLoad: false });
    const inputRefs = useRef([]);
    const [inventoryIDsArray, setInventoryIDsArray] = useState([]);
    const uniqInventoryIds = [...new Set(inventoryIDsArray)];
    // console.log('InventoryIDsArray', uniqInventoryIds)

    // returns[2, 1, 3]
    useEffect(async () => {
        const reorder = (array, sourceIndex, destinationIndex) => {
            const smallerIndex = Math.min(sourceIndex, destinationIndex);
            const largerIndex = Math.max(sourceIndex, destinationIndex);

            return [
                ...array.slice(0, smallerIndex),
                ...(sourceIndex < destinationIndex
                    ? array.slice(smallerIndex + 1, largerIndex + 1)
                    : []),
                array[sourceIndex],
                ...(sourceIndex > destinationIndex
                    ? array.slice(smallerIndex, largerIndex)
                    : []),
                ...array.slice(largerIndex + 1),
            ];
        }
        // console.log("================================", moveStartIndex, moveStopIndex)
        var finalArr = reorder(uniqInventoryIds, moveStartIndex, moveStopIndex);
        // console.log('----arraymove>', finalArr);
        await UpdateListOrderListAPICall({
            payload: [{ "orderedlist": finalArr, "restaurantId": resId ? resId : restaurantId, "locationId": locationId }]
        });
    }, [moveStopIndex, moveStartIndex])

    useEffect(() => {
        eventBus.$emit("updateinventoryId", [...new Set(updateinventoryId)]);
    }, [updateinventoryId]);

    useEffect(() => {
        if (totalRecords > 5) {
            setShowPagination(true);
        } else {
            setShowPagination(false);
        }
    }, [response, totalRecords])

    useEffect(() => {
        if (isUpdateListOrderLoading) {
            return () => null;
        }
        if (isUpdateListOrderError) {
            setLoadingCircul(false);
            setLoading(false);
            return () => null;
        }
        if (UpdateListOrderResponse && UpdateListOrderResponse.data.isSuccess === true) {
            setLoadingCircul(false);
            setLoading(false);
        }
    }, [UpdateListOrderResponse]);

    useEffect(async () => {
        setLoadingCircul(true);
        await setAPICall({
            payload: [{ "keyword": `${search}`, "restaurantId": resId ? resId : restaurantId, "locationId": locationId }]
        });
    }, []);

    useEffect(() => {
        if (isEditRapidLoading) {
            return () => null;
        }
        if (isEditRapidError) {
            setLoadingCircul(false);
            setLoading(false);
            eventBus.$emit("startUpdateLoading", false);
            return () => null;
        }
        if (EditRapidResponse && EditRapidResponse.data.isSuccess === true) {
            setUpdateinventoryId([...updateinventoryId, `${EditRapidResponse?.data?.data?.inventory?._id}`])
            // if (loadPage) {
            //     setAPICall({
            //         payload: [{ "keyword": `${search}`, "restaurantId": resId ? resId : restaurantId, "locationId": locationId }]
            //     });
            // }
            eventBus.$emit("startUpdateLoading", false);
            setLoadingCircul(false);
            setLoading(false);
        }
    }, [EditRapidResponse]);

    const onBtStartEditing = useCallback((key, pinned) => {
        gridRef.current.api.setFocusedCell(0, 'updateInventory', pinned);
        gridRef.current.api.startEditingCell({
            rowIndex: 0,
            colKey: 'updateInventory',
            // set to 'top', 'bottom' or undefined
            rowPinned: pinned,
            key: key,
        });
    }, []);

    useEffect(() => {
        if (isLoading) {
            return () => null;
        }
        if (isError) {
            setLoading(false);
            setLoadingCircul(false);
            return () => null;
        }
        if (response && response.data.isSuccess === true) {
            const data = response?.data?.data?.records.map((prop, key) => {
                setInventoryIDsArray(current => [...current, prop?._id]);
                // setShowInventoryList(true);
                setTotalRecords(response?.data?.data?.totalRecords)
                const latsDate = new Date(prop?.updatedAt);
                return {
                    id: prop?._id,
                    productId: prop?.productId,
                    inventoryUnitIds: prop?.unit?._id,
                    locationId: prop?.location?._id,
                    vendorId: prop?.vendor?._id,
                    restaurantId: prop?.restaurant?._id,
                    itemName: prop?.name ? prop?.name : '-',
                    currentInventory: prop?.currentInventory ? prop?.currentInventory : '-',
                    updateInventory: prop?.currentInventory ? prop?.currentInventory : '-',
                    inventoryUnits: prop?.unit?.name ? prop?.unit?.name : '-',
                    lowStockFlag: prop?.lowStockFlag ? prop?.lowStockFlag : '-',
                    parFlag: prop?.parFlag ? prop?.parFlag : '-',
                    location: prop?.location?.name ? prop?.location?.name : '-',
                    estimateddaysofSupply: prop?.estimatedSupplyDate ? prop?.estimatedSupplyDate : '-',
                    lastPurchaseDate: prop?.lastPurchaseDate ? prop?.lastPurchaseDate : '-',
                };
            }
            );
            setRowData(data);
            setLoadingCircul(false);
            setLoading(false);
        }
    }, [response]);

    let timeoutFilter = null;
    var nietos = [];
    var obj = {};

    function objectToQueryString(obj) {
        const keys = Object.keys(obj);
        const keyValuePairs = keys.map(key => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
        });
        return keyValuePairs.join('&');
    }

    const SliderFloatingFilter = (props, ref) => {
        const [currentValue, setCurrentValue] = useState('');

        const valueChanged = (event,) => {
            clearTimeout(timeoutFilter);
            setCurrentValue(event.target.value);
            if (props?.filterName == 'keyword') {
                obj["keyword"] = event.target.value.trim();
            } else if (props?.filterName == 'currentInventory') {
                setLoadingCircul(true);
                obj["currentInventory"] = event.target.value.trim();
            }

            if (props?.filterName == 'keyword' || props?.filterName == 'currentInventory') {
                nietos.push(obj);
                let finalQuery = objectToQueryString(...new Set(nietos));
                setLoadingCircul(true);
                timeoutFilter = setTimeout(function () {
                    setAPICall({
                        payload: [{ "restaurantId": resId ? resId : restaurantId, "locationId": locationId, "finalQuery": finalQuery }]
                    })
                }, 1000);
            }
        };
        return (
            <input
                type="text"
                value={currentValue}
                onChange={() => valueChanged(event)}
                style={{
                    width: '100%',
                    padding: '6px',
                    minHeight: '28px',
                    fontSize: 'inherit',
                    lineHeight: 'inherit',
                    borderRadius: '3px',
                    color: 'inherit',
                    border: `0.5px solid #babfc7`
                }}

            />
        );
    };

    const setNewCurrentInventory = useCallback((value, changeValue) => {
        const rowNode = gridRef.current.api.getRowNode(`${value}`);
        rowNode.setDataValue('currentInventory', `${changeValue}`);
    }, []);


    eventBus.$on('saveAndClose', (data) => saveAndClose(data));
    function saveAndClose(data) {
        let uniqueObjArray = [
            ...new Map(inventoryList.map((item) => [item["productId"], item])).values(),
        ];

        console.log("uniqueObjArray", uniqueObjArray);
        EditRapidListAPICall({
            payload: [{ "updatables": uniqueObjArray, "restaurantId": resId ? resId : restaurantId }]
        })
    }


    var gridOptions = {
        onCellKeyDown: params => {
            setNewCurrentInventory(params?.data?.id, params?.event?.target?.value !== undefined ? params?.event?.target?.value : params.data.updateInventory);
            setInventoryList(current => [...current, {
                "productId": params?.data?.productId,
                "locationName": params?.data?.location,
                "currentInventory": params?.event?.target?.value !== undefined ? Number(params?.event?.target?.value) : Number(params.data.updateInventory),
                // "restaurantId": params?.data?.restaurantId,
            }]);

            // timeout = setTimeout(async function () {
            //     eventBus.$emit("startUpdateLoading", true);
            //     await EditRapidListAPICall({
            //         payload: [
            //             {
            //                 "productId": params?.data?.productId,
            //                 "locationName": params?.data?.location,
            //                 "currentInventory": params?.event?.target?.value !== undefined ? params?.event?.target?.value : params.data.updateInventory,
            //                 "restaurantId": params?.data?.restaurantId,
            //             }
            //         ]
            //     })
            // }, 500);
        },
    }

    let timeout = null;

    const TextFieldEditor = (props) => {
        const [value, setValue] = useState(props.value);

        const handleValueChange = (event) => {
            setValue(event.target.value);
        };

        const handleBlur = () => {
            // Save the changes when the TextField loses focus
            // props.api.stopEditing();
            // props.api.setValue(value);
        };

        return (
            <TextField
                value={value}
                onChange={handleValueChange}
                onBlur={handleBlur}
                fullWidth
                inputProps={{
                    inputProps: { tabIndex: -1 },
                    style: { textAlign: 'center' },
                }}
                style={{ width: 120 }}
                variant="standard"
            />
        );
    };


    const ActionCellRenderer = (params) => {
        console.log('params?.data----------->', params?.data)
        setUpdateInventory(params?.data?.currentInventory);
        return <MDBox display="flex">
            <TextField
                margin="dense"
                id="name"
                // autoFocus
                focused
                // onChange={async (value) => {
                //     clearTimeout(timeout);
                //     setUpdateLoader(params?.data?.id);
                //     setNewCurrentInventory(params?.data?.id, value?.target?.value);
                //     timeout = setTimeout(async function () {
                //         eventBus.$emit("startUpdateLoading", true);
                //         await EditRapidListAPICall({
                //             payload: [
                //                 {
                //                     "productId": params?.data?.productId,
                //                     "locationName": params?.data?.location,
                //                     "currentInventory": value?.target?.value,
                //                     "restaurantId": params?.data?.restaurantId,
                //                 }
                //             ]
                //         })
                //     }, 500);
                //     // return () => clearTimeout(timeOutId);
                // }}
                label=""
                type="text"
                // sx={{
                //     "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                //         display: "none",
                //     },
                //     "& input[type=number]": {
                //         MozAppearance: "textfield",
                //     },
                // }}
                inputProps={{
                    inputProps: { tabIndex: -1 },
                    style: { textAlign: 'center' },
                }}
                style={{ width: 120 }}
                variant="standard"
            />
        </MDBox>
    }

    function suppressNavigation(params) {

        var KEY_TAB = 'Tab';

        var event = params.event;
        var key = event.key;

        var keysToSuppress = [
            KEY_TAB
        ];


        var suppress = keysToSuppress.some(function (suppressedKey) {
            return suppressedKey === key || key.toUpperCase() === suppressedKey;
        });

        return suppress;
    }

    const [columnDefs, setColumnDefs] = useState([
        {
            field: 'itemName',
            rowDrag: true,
            headerName: "ITEM NAME",
            suppressMovable: true,
            sortable: false,
            editable: false,
            suppressKeyboardEvent: suppressNavigation,
            floatingFilterComponent: SliderFloatingFilter,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
                filterName: 'keyword'
            },
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#7b809a",
            })
        },
        {
            field: 'currentInventory',
            headerName: "CURRENT INVENTORY",
            suppressMovable: true,
            width: 170,
            sortable: false,
            editable: false,
            suppressKeyboardEvent: suppressNavigation,
            floatingFilterComponent: SliderFloatingFilter,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
                filterName: 'currentInventory'
            },
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#7b809a",
            })
        },
        {
            field: 'updateInventory',
            headerName: "UPDATE INVENTORY",
            filter: false,
            editable: true,
            suppressMovable: true,
            cellRenderer: TextFieldEditor,
            width: 170,
            sortable: false,
            cellStyle: () => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "0.875rem",
                fontWeight: "400",
                color: "#7b809a",
            })
        }
    ]);

    const defaultColDef = useMemo(() => {
        return {
            suppressCellFlash: true,
            flex: 1,
            minWidth: 100,
            resizable: true,
            width: 200,
            sortable: true, // Enable sorting by default
            filter: true,
            floatingFilter: true,
            suppressMenu: true,
            floatingFilterComponentParams: { suppressFilterButton: true },
        };
    }, []);

    // const applyDefaultColumnState = () => {
    //     if (gridRef.current) {
    //         console.log("gridRef.current", gridRef.current)
    //         const gridColumnApi = gridRef.current.api.columnController;
    //         gridColumnApi.applyColumnState({ defaultState: { sort: null } });
    //     }
    // };

    // // useEffect to apply default column state after the grid is initialized
    // useEffect(() => {
    //     applyDefaultColumnState();
    // }, []);


    const handleCellClick = React.useCallback((params, event) => {
        if (!params.isEditable) {
            return;
        }

        // Ignore portal
        if (!event.currentTarget.contains(event.target)) {
            return;
        }

        setCellModesModel((prevModel) => {
            return {
                // Revert the mode of the other cells from other rows
                ...Object.keys(prevModel).reduce(
                    (acc, id) => ({
                        ...acc,
                        [id]: Object.keys(prevModel[id]).reduce(
                            (acc2, field) => ({
                                ...acc2,
                                [field]: { mode: GridCellModes.View },
                            }),
                            {},
                        ),
                    }),
                    {},
                ),
                [params.id]: {
                    // Revert the mode of other cells in the same row
                    ...Object.keys(prevModel[params.id] || {}).reduce(
                        (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
                        {},
                    ),
                    [params.field]: { mode: GridCellModes.Edit },
                },
            };
        });
    }, []);

    function SelectCategoryEditInputCell(props) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleCategoryChanges = async (event) => {
            await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
            apiRef.current.stopCellEditMode({ id, field });
        };
        return (
            <TextField
                type="number"
                variant="outlined"
                defaultValue={value}
                onChange={handleCategoryChanges}
            />
        );
    }

    // set background colour on every row, this is probably bad, should be using CSS classes
    const rowStyle = { background: 'transparent' };

    // set background colour on even rows again, this looks bad, should be using CSS classes
    const getRowStyle = params => {
        if (params.node.rowIndex % 2 === 0) {
            return { background: 'transparent' };
        }
    };

    const onRowDragEnter = useCallback((e) => {
        setRowManage(true);
        setMoveStartIndex(e?.overIndex);
        console.log('onRowDragEnter', e?.overNode?.data?.id, e);
    }, []);

    const onRowDragEnd = useCallback((e) => {
        setRowManage(false);
        setMoveStopIndex(e?.overIndex);
        console.log('onRowDragEnd', e?.overNode?.data?.id, e);
    }, []);

    const onRowDragMove = useCallback((e) => {
        console.log('onRowDragMove', e.columnApi.getColumnState());
    }, []);

    const onRowDragLeave = useCallback((e) => {
        console.log('onRowDragLeave', e.columnApi.getColumnState());
    }, []);



    const handleSortChanged = () => {
        // Get the current sorted order from ag-Grid
        const sortedRows = gridOptions.api.getRenderedNodes().map(node => node.data);

        // Update your data source to match the sorted order
        rowData.length = 0; // Clear the array
        rowData.push(...sortedRows); // Update with sorted data

        // Force a refresh of the grid to reflect the updated data source
        gridOptions.api.refreshCells();
    };

    const getRowId = useMemo(() => {
        return (params) => {
            return params.data.id;
        };
    }, []);


    return (
        <>
            <MDBox my={2} mx={2}>
                <MDBox sx={{ mt: 1, mb: -1 }}>
                    {/* <button onClick={() => onBtStartEditing(undefined)}>
                        edit (0)
                    </button> */}
                    <div className="ag-theme-alpine" style={{ height: 400, width: 'auto' }}>
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData}
                            gridOptions={gridOptions}
                            domLayout="autoHeight"
                            getRowId={getRowId}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            rowDragManaged={rowManage}
                            rowStyle={rowStyle}
                            rowHeight={55}
                            suppressMoveWhenRowDragging={true}
                            getRowStyle={getRowStyle}
                            animateRows={true}
                            pagination={showPagination}
                            paginationPageSize={10}
                            rowDragMultiRow={true}
                            onRowDragEnter={onRowDragEnter}
                            onRowDragEnd={onRowDragEnd}
                            enableCellChangeFlash={true}
                            singleClickEdit={true}
                        // onRowDragMove={onRowDragMove}
                        // onRowDragLeave={onRowDragLeave}
                        />
                    </div>
                </MDBox>
                <MDBox>
                    {loadingCircul && (
                        <CircularProgress
                            size={50}
                            sx={{
                                color: green[500],
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                </MDBox>
                <MDBox display="flex" justifyContent="center" >
                    {loading && <Loader />}
                </MDBox>
            </MDBox>
        </ >
    );
}


export default UserHoc(RapidInventoryList);