import ClientsUser from "layouts/clientuser/user";
import UserClientlist from "layouts/clientuser/clients";
import Dashboards from "layouts/accountants/user/dashboards";
import ClientUserInvoice from "layouts/clientuser/user/invoice/invoice";
import Icon from "@mui/material/Icon";
import NewClientsUser from 'layouts/clientuser/user/new-user';
import Restaurant from 'layouts/clientuser/restaurants';
import Inventory from 'layouts/clientuser/inventory';

const clientRoutes = [
    // { type: "divider", key: "divider-0" },
    {
        type: "collapse",
        name: "Users",
        key: "user",
        module: "employees",
        action: "read",
        icon: <Icon fontSize="medium">groups</Icon>,
        route: "/client/user/list",
        noCollapse: true,
        component: <ClientsUser />,
        icon: <Icon fontSize="medium">groups</Icon>,
        collapse: [
            {
                name: "All",
                key: "clients-list",
                module: "employees",
                action: "read",
                route: "/client/user/list",
                component: <ClientsUser />,
            },
            {
                name: "Add New",
                key: "new-clients",
                module: "employees",
                action: "create",
                route: "/client/user/new-user",
                component: <NewClientsUser />,
            },
        ],
    },
    // { type: "divider", key: "divider-1" },
    {
        type: "collapse",
        name: "Business",
        key: "client",
        module: "clients",
        action: "read",
        icon: <Icon fontSize="medium">workspaces</Icon>,
        route: "/client/client/list",
        component: <UserClientlist />,
        noCollapse: true,
    },
    // { type: "divider", key: "divider-0" },
    {
        type: "collapse",
        name: "Documents",
        key: "document",
        module: "documents",
        action: "read",
        route: "/client/document/:id",
        component: <ClientUserInvoice />,
        icon: <Icon fontSize="medium">receipt</Icon>,
        noCollapse: true,
    },
    // { type: "divider", key: "divider-2" },
    {
        type: "collapse",
        name: "Restaurants",
        key: "restaurant",
        module: "restaurants",
        action: "read",
        route: "/client/restaurant/list",
        component: <Restaurant />,
        icon: <Icon fontSize="medium">restaurant</Icon>,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "Inventory",
        key: "inventory",
        module: "inventories",
        action: "read",
        route: "/client/inventory/:id/list",
        component: <Inventory />,
        icon: <Icon fontSize="medium">inventory</Icon>,
        noCollapse: true,
    },
];

export default clientRoutes;