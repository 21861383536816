import Cookies from 'universal-cookie';
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import BasicLayout from "layouts/auth/components/BasicLayout";
import bgImage from "assets/images/backgroundimage.jpg";

const cookies = new Cookies();

function AdminLogout() {
    const navigate = useNavigate();

    useEffect(() => {
        cookies.remove('admin_access_token', { path: '/' });
        cookies.remove('accountant_profile_info', { path: '/admin' });
        navigate('/admin/sign-in');
    }, []);
    return (
        <BasicLayout image={bgImage}>

        </BasicLayout>
    )
}

export default AdminLogout;
