import { useEffect } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Card from "@mui/material/Card";
import { NavLink, useLocation } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import CircularProgress from '@mui/material/CircularProgress';
import DashboardLayout from "../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../components/Navbars/DashboardNavbar";
import DataTable from "../components/Tables/DataTable";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAPICall } from "../../../utils/useapicall";
import { GetList, EditAccountantInfo, GetEmployeesList, GetClientsList, GetTotalEmplyessList, GetAdminClientsList, GetAdminRestaurantList } from "../../../services/accountantservices";
import { EditClientInfo } from "../../../services/adminservices";
import Hoc from "hoc";
import TextField from '@mui/material/TextField';
import Cookies from 'universal-cookie';
import { Chip, Switch } from "@mui/material";
import Loader from "../accountants/components/imgLoader/loader";
import { eventBus } from "utils/eventbus";
import { green } from '@mui/material/colors';
import dataTableData from 'layouts/admin/client/data/dataTable.js'
import DataTable1 from "components/NewTable/table";

const cookies = new Cookies();

function AdminClients() {
    const navigate = useNavigate();
    const action = { route: "", edittooltip: "Edit", deletetooltip: "Delete", billingtooltip: "Billing" };
    const [openAccount, setOpenAccount] = useState(false);
    const [openClientPopup, setOpenClientPopup] = useState(false);
    const [alert, setAlert] = useState(false);
    const [profileInfo, setProfileInfo] = useState(cookies.get('accountant_profile_info'));
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetList, { onLoad: false });
    const [adminList, setAdminList] = useState([]);
    const [clientsList, setClientsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [{ isLoading: isRemoveLoading, isError: isRemoveError, response: removeResponse }, removeClientAPICall] = useAPICall(EditClientInfo, { onLoad: false });
    const [clientId, setClientId] = useState();
    const [activeUser, setActiveUser] = useState(false);
    const [{ isLoading: isUserStatusLoading, isError: isUserStatusError, response: UserStatusResponse }, UserStatusAPICall] = useAPICall(EditClientInfo, { onLoad: false });
    const [AccbussinessId, setBusinessId] = useState();
    const [loadingCircul, setLoadingCircul] = useState(false);
    const [tableEmpty, setTableEmpty] = useState("");
    const [employeeTableEmplty, setEmployeeTableEmpty] = useState("");
    const [clientTableEmpty, setClientTableEmpty] = useState("");
    const [search, setSearch] = useState('');
    const [searchBusinessName, setSearchBusinessName] = useState('');
    const [typeBusiness, setTypeBusiness] = useState(false);
    const [businessName, setBusinessName] = useState('');
    const [clientName, setClientName] = useState('');
    const [disabledBusinessName, setDisabledBusinessName] = useState(true);
    const [helperText, setHelperText] = useState('');
    const [employessList, setemployessList] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [employessId, setEmployessId] = useState();
    const [openTotalUserPopup, setOpenTotalUserPopup] = useState(false);
    const { pathname } = useLocation();
    const [restaurantList, setRestaurantList] = useState([]);
    const [empEmployessList, setEmpEmployessList] = useState([]);
    const [{ isError: isEmployeesError, isLoading: isEmployeesLoading, response: Employeesresponse }, EmployeessetAPICall] = useAPICall(GetEmployeesList, { onLoad: false });
    const [{ isError: isClientsError, isLoading: isClientsLoading, response: Clientsresponse }, AdminClientsetAPICall] = useAPICall(GetAdminClientsList, { onLoad: false });
    const [{ isError: isRestrurantsError, isLoading: isRestrurantsLoading, response: Restrurantsresponse }, AdminRestrurantsetAPICall] = useAPICall(GetAdminRestaurantList, { onLoad: false });
    const [{ isError: istotalEmployesError, isLoading: istotalEmployesLoading, response: totalEmployesresponse }, TotalEmplyessApiCall] = useAPICall(GetTotalEmplyessList, { onLoad: false });

    eventBus.$on('onclickchangePages', (data) => OnclickchangePages(data));
    function OnclickchangePages(data) {
        setLoading(true);
        const dataStr = data.toString() + 0
        localStorage.setItem("offset", Number(dataStr))
        AdminClientsetAPICall({
            payload: [{ "keyword": `${search}`, 'offset': Number(dataStr) }]
        });
    }

    useEffect(() => {
        if (istotalEmployesLoading) {
            return () => null;
        }
        if (istotalEmployesError) {
            setLoading(false);
            return () => null;
        }
        if (totalEmployesresponse && totalEmployesresponse.data.isSuccess === true) {
            const data = totalEmployesresponse?.data?.data?.records.map((prop, key) => {
                return {
                    userName: prop?.fname + ' ' + prop?.lname,
                    EmpStatus: prop?.isBlocked ?
                        <MDBox>
                            <MDTypography
                                variant="caption" fontWeight="regular" fontSize="0.875rem" color="error"
                            >
                                Inactive
                            </MDTypography>
                        </MDBox>
                        :
                        <MDBox>
                            <MDTypography
                                variant="caption" fontWeight="regular" fontSize="0.875rem" color="success"
                            >
                                Active
                            </MDTypography>
                        </MDBox>,
                    EmpEmail: prop?.email,
                    Empphone: prop?.phone,
                };
            }
            );
            setEmpEmployessList(data);
            setLoadingCircul(false);
            setOpenTotalUserPopup(true);
            setLoading(false);
        }
    }, [totalEmployesresponse]);

    const dataTableDataTotalUser = {
        columns: [
            {
                Header: "User Name",
                accessor: "userName",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Email",
                accessor: "EmpEmail",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Phone",
                accessor: "Empphone",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Status",
                accessor: "EmpStatus",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
        ],

        rows: empEmployessList
    };


    useEffect(() => {
        if (isRestrurantsLoading) {
            return () => null;
        }
        if (isRestrurantsError) {
            setLoading(false);
            response?.code === "DATA401" ? navigate('/admin/logout') : console.log("somting else");
            return () => null;
        }
        if (Restrurantsresponse && Restrurantsresponse.data.isSuccess === true) {
            const data = Restrurantsresponse?.data?.data?.records.map((prop, key) => {
                const Createdate = new Date(prop?.createdAt);
                return {
                    firstName: prop?.name,
                    totalInventories: prop?.totalInventories,
                    createdAt: Createdate.toLocaleDateString(),
                };
            }
            );
            setRestaurantList(data);
            setLoadingCircul(false);
            setLoading(false);
        }
    }, [Restrurantsresponse]);

    const [loaderClass, setLoaderClass] = useState({});


    useEffect(async () => {
        const queryParameters = new URLSearchParams(window.location.search)
        const keyword = queryParameters.get("keyword");
        const ThisOffsetuse = localStorage.getItem("offset");
        if (keyword) {
            setSearch(keyword);
            setLoading(true);
        } else {
            setLoading(true);
            await AdminClientsetAPICall({
                payload: [{ "keyword": `${search}`, 'offset': ThisOffsetuse }]
            })
        }
    }, []);

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const keyword = queryParameters.get("keyword");
        if (!keyword) {
            window.history.pushState('data', 'title', `${pathname}`);
        }
    }, [search]);

    useEffect(() => {
        eventBus.$emit("OnSetBlur", loading);
        loading === true ? setLoaderClass({ opacity: '0.6', borderRadius: '13px' }) : setLoaderClass({})
    }, [loading]);


    useEffect(() => {
        const ThisOffsetuse = localStorage.getItem("offset");
        const timeOutId = setTimeout(async () =>
            await AdminClientsetAPICall({
                payload: [{ "keyword": `${search}`, 'offset': ThisOffsetuse }]
            }), 500);
        return () => clearTimeout(timeOutId);
    }, [search]);

    useEffect(() => {
        const timeOutId = setTimeout(async () => {
            console.log("clientName", clientName)
            if (searchBusinessName == clientName?.toUpperCase() && searchBusinessName.length >= 1) {
                setDisabledBusinessName(false);
                setHelperText("");
            } else {
                setDisabledBusinessName(true);
                // setHelperText("Incorrect Business name.");
            }
        }, 500)
        // await setAPICall({
        //     payload: [{ "keyword": `${search}` }]
        // }), 500);
        return () => clearTimeout(timeOutId);
    }, [searchBusinessName]);

    useEffect(() => {
        if (isClientsLoading) {
            return () => null;
        }
        if (isClientsError) {
            setLoadingCircul(false);
            response?.code === "DATA401" ? navigate('/admin/logout') : console.log("somting else");
            return () => null;
        }
        if (Clientsresponse && Clientsresponse.data.isSuccess === true) {
            setClientTableEmpty(Clientsresponse.data.data.totalRecords);
            const data = Clientsresponse.data.data.records.map((prop, key) => {
                const Createdate = new Date(prop?.createdAt);
                const HasJoined = prop.joinedAt;
                const ontrial = prop?.subscriptions[0]?.ontrial === true ? `Trial:${date_diff_indays(prop?.subscriptions[0]?.trialEndDate)}days` : '';
                return {
                    firstName: prop?.fname + ' ' + prop.lname ?
                        <MDBox>
                            <Link to={`/admin/client/${prop?._id}/edit-client`}>
                                <MDTypography
                                    variant="caption" fontWeight="regular" fontSize="0.875rem" color="info"
                                >
                                    {prop?.fname + ' ' + prop.lname}
                                </MDTypography>
                            </Link>
                        </MDBox> : '-',
                    // business: prop?.businesses[0]?.name,
                    email: prop?.email ? prop?.email : '-',
                    phone: prop?.phone == null ? "-" : prop?.phone,
                    Numberofuser: prop?.numberOfEmployees ? prop?.numberOfEmployees : '-',
                    switch: (
                        HasJoined == null ?
                            <MDBox>
                                <MDTypography
                                    variant="caption" fontWeight="regular" fontSize="0.875rem" color="warning"
                                >
                                    Pending Confirmation
                                </MDTypography>
                                <MDBox sx={{
                                    width: '100%', display: 'flex',
                                    justifyContent: 'center', fontSize: "1.20rem"
                                }}>

                                    {prop?.subscriptions[0]?.ontrial === true ? <Chip sx={{ marginLeft: "3px", fontSize: '0.6125rem', height: '17px', color: '#ffffff' }} label={ontrial} color="warning" /> : ''}
                                </MDBox>
                            </MDBox>
                            :
                            prop?.isBlocked ?
                                <MDBox>
                                    <MDTypography
                                        variant="caption" fontWeight="regular" fontSize="0.875rem" color="error"
                                    >
                                        Inactive
                                    </MDTypography>
                                </MDBox>
                                :
                                <MDBox>
                                    <MDTypography
                                        variant="caption" fontWeight="regular" fontSize="0.875rem" color="success"
                                    >
                                        Active
                                    </MDTypography>
                                </MDBox>
                    ),
                    createdat: Createdate.toLocaleDateString() ? Createdate.toLocaleDateString() : '-',
                    totalrestaurants: (
                        prop?.totalRestaurants == 0 ?
                            prop?.totalRestaurants
                            :
                            <MDBox>
                                <MDTypography
                                    variant="caption" fontWeight="regular" fontSize="0.875rem" color="info"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={async (value) => {
                                        setLoadingCircul(true);
                                        AdminRestrurantsetAPICall({
                                            payload: [{ "clientId": prop?._id }]
                                        })
                                        setOpenClientPopup(true);
                                    }}
                                >
                                    {prop?.totalRestaurants ? prop?.totalRestaurants : '-'}
                                </MDTypography>
                            </MDBox >
                    ),
                    totalusers: (
                        prop?.totalEmployees == 0 ?
                            prop?.totalEmployees :
                            <MDBox>
                                <MDTypography
                                    variant="caption" fontWeight="regular" fontSize="0.875rem" color="info"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={async (value) => {
                                        setLoadingCircul(true);
                                        TotalEmplyessApiCall({
                                            payload: [{ "clientId": prop?._id }]
                                        })
                                    }}
                                >
                                    {prop?.totalEmployees ? prop?.totalEmployees : '-'}
                                </MDTypography>
                            </MDBox >
                    ),
                    monthlycost: prop?.subscriptions[0]?.monthlyRate ? `$${prop?.subscriptions[0]?.monthlyRate}` : '-',
                    accountlevel: "-",
                    // (prop.role.template == 'executive' ? "User" : "Admin"),
                    action:
                        (profileInfo && profileInfo.user && profileInfo?.user?._id == prop?._id ? ("") : (
                            <MDBox display="flex">
                                <Link to={`/admin/client/${prop?._id}/edit-client`}>
                                    <MDTypography
                                        variant="body2"
                                        color="secondary"
                                        sx={{ marginLeft: "-4px", fontSize: "1.20rem", cursor: 'pointer' }}
                                    >
                                        <Tooltip title={action.edittooltip} placement="top">
                                            <Icon>edit</Icon>
                                        </Tooltip>
                                    </MDTypography>
                                </Link>
                                <MDTypography variant="body2" color="secondary" sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: 'pointer' }}>
                                    <Tooltip title={action.deletetooltip} placement="top"
                                        onClick={(value) => {
                                            console.log("prop?.businesses", prop?.fname + ' ' + prop.lname)
                                            // setBusinessName(prop?.businesses[0].name);
                                            setClientName(prop?.fname + ' ' + prop.lname);
                                            setClientId(prop?._id);
                                            setTypeBusiness(true);
                                        }}
                                    >
                                        <Icon>delete</Icon>
                                    </Tooltip>
                                </MDTypography>
                            </MDBox>
                        ))

                };
            }
            );
            setClientList(data);
            setLoading(false);
            setLoadingCircul(false);
        }
    }, [Clientsresponse]);


    const ToggleChange = async () => {
        setActiveUser(!activeUser);
        // await UserStatusAPICall({
        //     payload: [
        //         {
        //             "isBlocked": activeUser,
        //             "bussinessId": `${bussinessId}`
        //         }
        //     ]
        // });

    }

    useEffect(() => {
        setProfileInfo(cookies.get('accountant_profile_info'));
    }, []);


    const handleCloseAccount = () => {
        setOpenAccount(false);
    };

    const date_diff_indays = function (date2) {
        const dt1 = new Date();
        const dt2 = new Date(date2);
        return Math.floor(
            (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
                Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
            (1000 * 60 * 60 * 24)
        );
    };

    useEffect(() => {

        if (isUserStatusLoading) {
            return () => null;
        }

        if (isUserStatusError) {
            response?.code === "DATA401" ? navigate('/admin/logout') : console.log("somting else");
            return () => null;
        }

        if (UserStatusResponse && UserStatusResponse.data.isSuccess === true) {

        }
    }, [UserStatusResponse]);

    useEffect(() => {

        if (isRemoveLoading) {
            return () => null;
        }

        if (isRemoveError) {
            response?.code === "DATA401" ? navigate('/admin/logout') : console.log("somting else");
            setLoading(false);
            return () => null;
        }

        if (removeResponse && removeResponse.data.isSuccess === true) {
            const ThisOffsetuse = localStorage.getItem("offset");
            setShowSnackbar(true);
            setTimeout(() => {
                setShowSnackbar(false);
            }, 10000);
            setLoading(true);
            AdminClientsetAPICall({
                payload: [{ "keyword": `${search}`, 'offset': ThisOffsetuse }]
            })
            // navigate('/accountants/list');
        }
    }, [removeResponse]);


    const warningWithConfirmMessage = () => {
        setAlert(true);
    };
    const successDelete = async () => {
        setTypeBusiness(false);
        setLoading(true);
        await removeClientAPICall({
            payload: [
                {
                    "isBlocked": true,
                    "isArchived": true,
                    "bussinessId": `${clientId}`
                }
            ]
        })
        setAlert(false);
    };
    const hideAlert = () => {
        setAlert(false);
        setTypeBusiness(false);
    };

    const dataTableData = {
        columns: [
            // {
            //     Header: "Client Business Name",
            //     accessor: "business",
            //     disableSortBy: true,
            //     Cell: ({ value }) => <DefaultCell value={value} />,
            // },
            {
                Header: "Full Name",
                accessor: "firstName",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Email Address",
                accessor: "email",
                disableSortBy: true,
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Phone Number",
                accessor: "phone",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            { Header: "Total Restaurants", accessor: "totalrestaurants", Cell: ({ value }) => <DefaultCell value={value} /> },
            { Header: "Total Users", accessor: "totalusers", Cell: ({ value }) => <DefaultCell value={value} /> },
            { Header: "Monthly Cost", accessor: "monthlycost", Cell: ({ value }) => <DefaultCell value={value} /> },
            {
                Header: "Status",
                accessor: "switch",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            { Header: "Created Date", accessor: "createdat", Cell: ({ value }) => <DefaultCell value={value} /> },
            {
                Header: "Action", accessor: "action", Cell: ({ value }) => <DefaultCell value={value} />
            },
        ],

        rows: clientList
    };


    const handleCloseClientPopup = () => {
        setOpenClientPopup(false);
        setLoading(false);
    };


    const dataTableData2 = {
        columns: [
            {
                Header: "Name",
                accessor: "firstName",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Email",
                accessor: "email",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Phone Number",
                accessor: "phone",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            { Header: "Account Created Date", accessor: "createdat", Cell: ({ value }) => <DefaultCell value={value} /> },
            { Header: "Account Level", accessor: "accountlevel", Cell: ({ value }) => <DefaultCell value={value} /> },
            { Header: "Accountant to Clients", accessor: "accountanttoclients", Cell: ({ value }) => <a >{value} </a> },
            // {
            //     Header: "Action", accessor: "action", Cell: ({ value }) => <DefaultCell value={value} />
            // },
        ],

        rows: employessList
    };

    const dataTableData4 = {
        columns: [
            {
                Header: "Restaurant Name",
                accessor: "firstName",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Total Inventories",
                accessor: "totalInventories",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Created At",
                accessor: "createdAt",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
        ],

        rows: restaurantList,
    };



    const handleSearch = async (event) => {
        localStorage.setItem("offset", 0);
        setSearch(event.target.value);
        setLoadingCircul(true);
        window.history.pushState('data', 'title', `${pathname}?keyword=${encodeURIComponent(event.target.value)}`);
        // window.location.replace(`?${event.target.value}`);
        // setLoading(true);
    };

    const handleBusiness = async (event) => {
        setSearchBusinessName((event.target.value).toUpperCase());
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={4}>
                <Card sx={loaderClass}>
                    <MDBox display="flex" justifyContent="center" >
                        {loading && <Loader />}
                    </MDBox>
                    <MDBox pt={3} px={2} sx={{ pr: 3, mb: 2 }} component="form" display="flex" justifyContent="space-between" alignItems="center">
                        <MDTypography variant="h5" fontWeight="medium">
                            Clients
                        </MDTypography>
                        <MDBox px={2} display="flex" >
                            <MDBox sx={{ pr: 3, mb: 2 }}>
                                <MDInput type="search" label="Search Client" onKeyDown={handleKeyDown} variant="standard" onChange={handleSearch} />
                            </MDBox>
                            <MDBox>
                                <MDButton component={NavLink} to="/admin/client/new-clients"
                                    variant="gradient"
                                    color="info"
                                    disabled={loading}

                                > Add New Client
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                    <MDBox>
                        {loadingCircul && (
                            <CircularProgress
                                size={50}
                                sx={{
                                    color: green[500],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </MDBox>
                    <Dialog
                        open={openAccount}
                        onClose={handleCloseAccount}
                        aria-labelledby="responsive-dialog-title"
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    height: "auto",
                                    maxWidth: "1000px",
                                },
                            },
                        }}
                    >
                        <DialogContent>
                            <MDBox my={5}>
                                {
                                    clientTableEmpty == '0' ?
                                        <MDBox my={5} sx={{ mt: 1 }}>
                                            <DataTable table={dataTableData2} entriesPerPage={false} pagination={false} showTotalEntries={true} />
                                            <MDTypography
                                                variant="body2"
                                                color="secondary"
                                                sx={{ marginLeft: "43%", fontSize: "1.20rem", marginTop: "5%", marginBottom: "5%" }}
                                            >
                                                No records found
                                            </MDTypography>
                                        </MDBox>
                                        :
                                        <MDBox my={5} sx={{ mt: 1 }}>
                                            <DataTable table={dataTableData2} entriesPerPage={false} pagination={true} showTotalEntries={true} />
                                        </MDBox>
                                }
                            </MDBox>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseAccount} >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={openClientPopup}
                        onClose={handleCloseClientPopup}
                        aria-labelledby="responsive-dialog-title"
                        maxWidth="xl"
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    maxHeight: "400px",
                                    maxWidth: "600px",
                                },
                            },
                        }}
                    >
                        <DialogTitle sx={{
                            justifyContent: "center",
                            display: 'flex'
                        }}>RESTAURANTS</DialogTitle>
                        <DialogContent>
                            <MDBox my={3}>
                                <DataTable table={dataTableData4} entriesPerPage={false} pagination={false} showTotalEntries={true} />
                            </MDBox>
                            <MDBox>
                                {loadingCircul && (
                                    <CircularProgress
                                        size={50}
                                        sx={{
                                            color: green[500],
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </MDBox>
                        </DialogContent>
                        <DialogActions>
                            <Button sx={{ mt: -2 }} onClick={handleCloseClientPopup} >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={openTotalUserPopup}
                        onClose={() => setOpenTotalUserPopup(false)}
                        aria-labelledby="responsive-dialog-title"
                        maxWidth="xl"
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    height: "auto",
                                    maxWidth: "750px",
                                },
                            },
                        }}
                    >
                        <DialogTitle sx={{
                            justifyContent: "center",
                            display: 'flex'
                        }}>Users</DialogTitle>
                        <DialogContent>
                            <MDBox my={5}>
                                <DataTable table={dataTableDataTotalUser} entriesPerPage={false} pagination={false} showTotalEntries={true} />
                            </MDBox>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenTotalUserPopup(false)} >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* {alert} */}
                    <Dialog
                        open={typeBusiness}
                        onClose={hideAlert}
                        // aria-labelledby="alert-dialog-title"
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    minHeight: "30%",
                                    maxWidth: "500px",
                                },
                            },
                        }}
                    >
                        <DialogTitle>Delete {clientName}?</DialogTitle>
                        <DialogContent>
                            <DialogContentText sx={{ mb: 4, mt: 4 }}>
                                Are you sure want to delete this client?
                            </DialogContentText>
                            <TextField
                                margin="dense"
                                id="name"
                                label="Client name"
                                type="text"
                                inputProps={{ style: { textTransform: 'uppercase' } }}
                                style={{ width: 250 }}
                                error={disabledBusinessName}
                                helperText={helperText}
                                variant="outlined"
                                onChange={handleBusiness}
                            />
                        </DialogContent>
                        <DialogActions>
                            <MDButton
                                variant="gradient"
                                color="error"
                                onClick={() => setTypeBusiness(false)}
                            >Cancel
                            </MDButton>
                            <MDButton
                                variant="gradient"
                                color="success"
                                onClick={() => warningWithConfirmMessage()}
                                disabled={disabledBusinessName}
                            >
                                Delete Client
                            </MDButton>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={alert}
                        onClose={hideAlert}
                        aria-labelledby="alert-dialog-title"
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    maxWidth: "500px",
                                },
                            },
                        }}
                    >
                        <DialogContent sx={{ overflowY: 'hidden' }}>
                            <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                                <h3>Are you sure?</h3>
                            </DialogContentText>
                            <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                                <MDTypography
                                    variant="body2"
                                    color="secondary"
                                    sx={{ fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 4 }}
                                >
                                    Once you delete this Client there is no way of getting any of the documents or data back.
                                </MDTypography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "center", mt: 2 }} >
                            <MDButton
                                variant="gradient"
                                color="success"
                                onClick={successDelete} >
                                Yes, delete it!
                            </MDButton>
                            <MDButton
                                variant="gradient"
                                color="error"
                                onClick={hideAlert}
                            >Cancel
                            </MDButton>
                        </DialogActions>
                    </Dialog>
                    <MDBox>
                        <MDSnackbar
                            color="success"
                            icon="done"
                            title="Success"
                            // autoHideDuration={3000}
                            content="Client Deleted Successfully."
                            open={showSnackbar}
                            close={() => setShowSnackbar(false)}
                        />
                    </MDBox>
                    <MDBox my={5} sx={{ mt: 1 }}>
                        <DataTable1 table={dataTableData} entriesPerPage={false} pagination={true} showTotalEntries={true} response={clientTableEmpty} showCheck={false} ClientHeigth={true} />
                    </MDBox>
                </Card>
            </MDBox>
        </DashboardLayout>
    );
}

export default Hoc(AdminClients);