import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import { SITE_URL } from "helpers/config";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "../../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../components/Navbars/DashboardNavbar";
import { EditSchema } from "layouts/accountants/user/components/schemas/edituser";
import FormField from "layouts/admin/accountants/components/FormField";

import { Link, useNavigate } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MDSnackbar from "components/MDSnackbar";
import { useAPICall } from "../../../../utils/useapicall";
import { EditUserInfo, GetuserDetailsList } from "../../../../services/userservices";
import { GetRolescope, GetuserClientList } from "../../../../services/userservices";
import UserHoc from "hoc/userHoc";
import { useMaterialUIController } from "context";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DataTable from "../../../components/Tables/DataTable";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import DialogTitle from '@mui/material/DialogTitle';
import { User1 } from "helpers/getUser";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";
import LoaderPng from "assets/images/loders/Loading-screen-smalls.gif"

function EditUsers() {
    let newObj = {};
    const navigate = useNavigate();
    const [bussinessId, setBusinessId] = useState(location.pathname.split("/").slice(-2)[0]);
    const [btnDisable, setBtnDisable] = useState(false);
    const [allClient, setAllClient] = useState(true);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [ErrorMessages, setErrorMessage] = useState("Failed");
    const [profileEmail, setprofileEmail] = useState("");
    const [profileFname, setprofileFname] = useState("");
    const [profileLname, setprofileLname] = useState("");
    const [profileBuinessName, setprofileBusinessName] = useState("");
    const [profileNumber, setprofileNumber] = useState("");
    const [UserRole, setUserRole] = useState("");
    const [showForm, setShowForm] = useState(false);
    const [adminRoleId, setAdminRoleId] = useState("");
    const [clientRoleId, setclientRoleId] = useState("");
    const [ownerRoleId, setOwnerRoleId] = useState("");
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const [activeUser, setActiveUser] = useState(false);
    const [selectedClientId, setSelectedClientId] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openClient, setOpenClient] = useState(false);
    const [clientRecord, setClientRecord] = useState([]);
    const [permissionsData, setPermissionsData] = useState({});
    const [checked, setChecked] = useState(newObj);
    const [adminList, setAdminList] = useState([]);
    const [search, setSearch] = useState('');
    const [tableEmpty, setTableEmpty] = useState("");
    const [selectedRole, setSelectedRole] = useState('');
    const [isDisable, setIsDisable] = useState(false);
    const [showCheck, setShowCheck] = useState(false);
    const [phone, setPhone] = useState('');
    const [managerRoleId, setManagerRoleId] = useState("");
    const [selectedClientLength, setSelectedClientLength] = useState('');
    const [totalSelectedClient, setTotalSelectedClient] = useState();
    const [pushPopFun, setPushPopFun] = useState(false);
    const sidenavItems = [
        { icon: "person", label: "profile", href: "profile" },
        { icon: "receipt_long", label: "basic info", href: "basic-info" },
        // { icon: "lo  ck", label: "change password", href: "change-password" },
    ];

    const renderSidenavItems = sidenavItems.map(({ icon, label, href, path }, key) => {
        const itemKey = `item-${key}`;

        return (
            <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
                <MDTypography
                    component="a"
                    href={`#${href}`}
                    path={path}
                    variant="button"
                    fontWeight="regular"
                    textTransform="capitalize"
                    sx={({
                        borders: { borderRadius },
                        functions: { pxToRem },
                        palette: { light },
                        transitions,
                    }) => ({
                        display: "flex",
                        alignItems: "center",
                        borderRadius: borderRadius.md,
                        padding: `${pxToRem(10)} ${pxToRem(16)}`,
                        transition: transitions.create("background-color", {
                            easing: transitions.easing.easeInOut,
                            duration: transitions.duration.shorter,
                        }),

                        "&:hover": {
                            backgroundColor: light.main,
                        },
                    })}
                >
                    <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
                        <Icon fontSize="small">{icon}</Icon>
                    </MDBox>
                    {label}
                </MDTypography>
            </MDBox>
        );
    });

    const initialValues = {
        name: "",
        firstname: profileFname,
        lastname: profileLname,
        businessname: profileBuinessName,
        phone: profileNumber,
        company: "",
        email: profileEmail,
        password: "",
        repeatPassword: "",
        curpassword: "",
        address1: "",
        address2: "",
        city: "",
        zip: "",
        twitter: "",
        facebook: "",
        instagram: "",
        publicEmail: "",
        bio: "",
        role: UserRole,
    };

    const [{ isError, isLoading, response }, setAPICall] = useAPICall(EditUserInfo, { onLoad: false });
    const [{ isLoading: isRoleLoading, isError: isRoleError, response: roleResponse }, getRoleAPICall] = useAPICall(GetRolescope, { onLoad: false });
    const [{ isLoading: isGetUserLoading, isError: isGetUserError, response: userDetailsDataResponse }, getUserDteailsAPICall] = useAPICall(GetuserDetailsList, { onLoad: false });
    const [{ isLoading: isUserStatusLoading, isError: isUserStatusError, response: UserStatusResponse }, UserStatusAPICall] = useAPICall(EditUserInfo, { onLoad: false });
    const [{ isLoading: isClientError, isError: isClientLoading, response: Clientresponse }, setClientAPICall] = useAPICall(GetuserClientList, { onLoad: false });

    useEffect(async () => {
        await getRoleAPICall({
            payload: []
        });
        setTimeout(async () => {
            await getUserDteailsAPICall({
                payload: [{ "bussinessId": `${bussinessId}` }]
            })
        }, 1000);
    }, []);

    useEffect(() => {
        if (isClientLoading) {
            return () => null;
        }
        if (isClientError) {
            response?.code === "DATA401" ? navigate('/user/logout') : console.log("somting else");
            return () => null;
        }
        if (Clientresponse && Clientresponse.data.isSuccess === true) {
            setClientRecord(Clientresponse.data.data.records);
            setTableEmpty(Clientresponse.data.data.totalRecords);
            const data = Clientresponse.data.data.records.map((prop, key) => {
                const Createdate = new Date(prop.createdAt);
                if (userDetailsDataResponse?.data?.data?.employee?.permissions[0]?.allScopes == true) {
                    toggleCheck(prop._id, prop.name)
                }
                return {
                    id: (
                        <MDBox display="flex" alignItems="center">
                            <input
                                type="checkbox"
                                id={prop._id}
                                onChange={() => toggleCheck(prop._id, prop.name) && setPushPopFun(true)}
                                checked={checked[prop._id]}
                            />
                        </MDBox>
                    ),
                    snum: (key + 1),
                    client: prop.name,
                    approve: (
                        <MDBox display="flex" alignItems="center">
                            <input
                                type="checkbox"
                                id={prop._id}
                                onChange={() => toggleCheck(prop._id, prop.name)}
                            // checked={checked[prop._id]}
                            />
                        </MDBox>
                    ),
                    doctoreview: prop.toReview,
                    unmappedvendors: prop.unmappedVendor,
                    clientapproval: Createdate.toLocaleDateString(),
                    yourapproval: prop.approved,
                    pendingapproval: prop.pendingApproval,
                    unreconciled: "-",
                };
            }
            );
            setAdminList(data);
            setLoading(false);
        }
    }, [Clientresponse]);

    useEffect((e) => {
        const data = clientRecord.map((prop, key) => {
            const Createdate = new Date(prop.createdAt);
            return {
                id: (
                    <MDBox display="flex" alignItems="center">
                        <input
                            type="checkbox"
                            id={prop._id}
                            onChange={() => toggleCheck(prop._id, prop.name) && setPushPopFun(true)}
                            checked={checked[prop._id]}
                        />
                    </MDBox>
                ),
                snum: (key + 1),
                client: prop.name,
                approve: (
                    <MDBox display="flex" alignItems="center">
                        <input
                            type="checkbox"
                            id={prop._id}
                            onChange={() => toggleCheck(prop._id, prop.name)}
                        // checked={checked[prop._id]}
                        />
                    </MDBox>
                ),
                doctoreview: prop.toReview,
                unmappedvendors: prop.unmappedVendor,
                clientapproval: Createdate.toLocaleDateString(),
                yourapproval: prop.approved,
                pendingapproval: prop.pendingApproval,
                unreconciled: "-",
            };
        }
        );
        setAdminList(data);
    }, [checked]);


    useEffect(() => {

        if (isUserStatusLoading) {
            return () => null;
        }

        if (isUserStatusError) {
            response?.code === "DATA401" ? navigate('/user/logout') : console.log("somting else");
            return () => null;
        }

        if (UserStatusResponse && UserStatusResponse.data.isSuccess === true) {
            // getAccountanatAPICall({
            //     payload: []
            // })
        }
    }, [UserStatusResponse]);


    useEffect(() => {

        if (isRoleLoading) {
            return () => null;
        }

        if (isRoleError) {
            // response?.code === "DATA401" ? navigate('/user/logout') : console.log("somting else");
            if (response?.data?.message) {
                setErrorMessage(response.data.message)
            }
            return () => null;
        }

        if (roleResponse && roleResponse.data.isSuccess === true) {
            const adminresult = roleResponse.data.data.records.find(({ template }) => template === "admin");
            const managerresult = roleResponse.data.data.records.find(({ template }) => template === "manager");
            const clientresult = roleResponse.data.data.records.find(({ template }) => template === "user");
            const ownerresult = roleResponse.data.data.records.find(({ template }) => template === "owner");
            setAdminRoleId(adminresult?._id);
            setclientRoleId(clientresult?._id);
            setOwnerRoleId(ownerresult?._id);
            setManagerRoleId(managerresult?._id);
            // navigate('/accountants/list');
        }
    }, [roleResponse]);

    useEffect(() => {

        if (isGetUserLoading) {
            return () => null;
        }

        if (isGetUserError) {
            // response?.code === "DATA401" ? navigate('/user/logout') : console.log("somting else");
            return () => null;
        }

        if (userDetailsDataResponse && userDetailsDataResponse.data.isSuccess === true) {
            const adminresult = userDetailsDataResponse.data.data.employee;
            console.log("adminresult?.permissions[0]?.role------------->", adminresult?.permissions[0]?.role)
            setSelectedClientLength(adminresult?.totalClients);
            setTotalSelectedClient(adminresult?.assignedClients.length);
            setprofileEmail(adminresult?.email);
            setprofileFname(adminresult?.fname);
            setprofileLname(adminresult?.lname);
            setprofileBusinessName(adminresult?.businessName);
            setprofileNumber(adminresult?.phone);
            setPhone(adminresult?.phone);
            setActiveUser(adminresult?.isBlocked);
            setAllClient(adminresult?.permissions[0]?.allScopes);
            setUserRole(adminresult?.permissions[0]?.role == 'user' ? "client" : adminresult?.permissions[0]?.role === 'admin' ? 'domain1' : 'domain');
            if (adminresult?.permissions[0]?.scopeIds) {
                if (roleResponse && roleResponse.data.isSuccess === true) {
                    adminresult?.assignedClients.map((v) => {
                        selectedClientId.push(v._id);
                        toggleCheck(v._id, v.name);
                        permissionsData[v._id] = {
                            "clientId": v._id,
                            "name": v.name,
                            "roleId": adminresult?.role?.template === 'domain1' ? ownerRoleId : selectedRole === 'domain' ? adminRoleId : clientRoleId,
                        }
                    });
                }
            }
            setShowForm(true);
        }
    }, [userDetailsDataResponse]);


    useEffect(() => {
        if (isLoading) {
            return () => null;
        }
        if (isError) {
            if (response?.data?.message) {
                setErrorMessage(response.data.message)
            }
            setShowErrorSnackbar(true);
            setTimeout(() => {
                setShowErrorSnackbar(false);
            }, 10000);
            setBtnDisable(false);
            return () => null;
        }
        if (response && response.data.isSuccess === true) {
            setShowSnackbar(true);
            setBtnDisable(false);
            navigate('/accountant/users/list');
            setTimeout(() => {
                setShowSnackbar(false);
            }, 2000);
        }
    }, [response]);


    const toggleCheck = async (clientId, clientName) => {
        setChecked((prevState) => {
            const newState = { ...prevState };
            newState[clientId] = !prevState[clientId];
            return newState;
        });

        if (pushPopFun) {
            if (selectedClientId.includes(clientId)) {
                var index = selectedClientId.indexOf(clientId);
                selectedClientId.splice(index, 1);
                delete permissionsData[clientId];
            } else {
                selectedClientId.push(clientId);
                permissionsData[clientId] = {
                    "clientId": clientId,
                    "name": clientName,
                    "roleId": selectedRole === 'domain1' ? ownerRoleId : selectedRole === 'domain' ? adminRoleId : clientRoleId,
                }
            }
            setSelectedClientLength(selectedClientId.length)
            setPermissionsData(permissionsData);
        }


    };

    const handleSubmit = async (values) => {
        setSelectedRole(values.role);
        setIsDisable(true);

        // const roleScope = values.role === 'domain1' ? 'domain' : values.role;
        {
            values.role === 'domain1' ?
                await setAPICall({
                    payload: [
                        {
                            "email": `${values.email}`,
                            "bussinessId": bussinessId,
                            // "businessName": `${ values.businessname }`,
                            "phone": phone,
                            "fname": `${values.firstname}`,
                            "lname": `${values.lastname}`,
                            // "scope": `${roleScope}`,
                            "returnLink": `${SITE_URL}/user/create-password`,
                            // "roleId": RoleId,
                            // "allClient": true,
                            "permissions": [
                                {
                                    "category": "accounting",
                                    "role": "admin",
                                    "allScopes": true
                                }
                            ]
                            // "permissions": Object.values(permissionsData),
                        }
                    ]
                })
                :
                allClient == true ?
                    roleScope === 'domain' ?
                        await setAPICall({
                            payload: [
                                {
                                    "email": `${values.email}`,
                                    "bussinessId": bussinessId,
                                    "phone": phone,
                                    "fname": `${values.firstname}`,
                                    "lname": `${values.lastname}`,
                                    "returnLink": `${SITE_URL}/user/create-password`,
                                    "permissions": [
                                        {
                                            "category": "accounting",
                                            "role": "manager",
                                            "allScopes": true
                                        }
                                    ]
                                }
                            ]
                        }) :
                        await setAPICall({
                            payload: [
                                {
                                    "email": `${values.email}`,
                                    "bussinessId": bussinessId,
                                    "phone": phone,
                                    "fname": `${values.firstname}`,
                                    "lname": `${values.lastname}`,
                                    "returnLink": `${SITE_URL}/user/create-password`,
                                    "permissions": [
                                        {
                                            "category": "accounting",
                                            "role": "user",
                                            "allScopes": true
                                        }
                                    ]
                                }
                            ]
                        })
                    :
                    values.role === 'domain' ?
                        await setAPICall({
                            payload: [
                                {
                                    "email": `${values.email}`,
                                    "bussinessId": bussinessId,
                                    "phone": phone,
                                    "fname": `${values.firstname}`,
                                    "lname": `${values.lastname}`,
                                    "returnLink": `${SITE_URL}/user/create-password`,
                                    "permissions": [
                                        {
                                            "category": "accounting",
                                            "role": "manager",
                                            "scopeIds": selectedClientId
                                        }
                                    ]
                                }
                            ]
                        }) :
                        await setAPICall({
                            payload: [
                                {
                                    "email": `${values.email}`,
                                    "bussinessId": bussinessId,
                                    "phone": phone,
                                    "fname": `${values.firstname}`,
                                    "lname": `${values.lastname}`,
                                    "returnLink": `${SITE_URL}/user/create-password`,
                                    "permissions": [
                                        {
                                            "category": "accounting",
                                            "role": "user",
                                            "scopeIds": selectedClientId
                                        }
                                    ]
                                }
                            ]
                        })
        }
    };

    const ToggleChange = async () => {
        setActiveUser(!activeUser);

        await UserStatusAPICall({
            payload: [
                {
                    "isBlocked": !activeUser,
                    // "isBlocked": `${!activeUser}`,
                    "bussinessId": `${bussinessId}`
                }
            ]
        });

    }
    const handleChange = () => {
        setAllClient(!allClient)

    }

    const handleClickOpenClient = async () => {
        setPushPopFun(true);
        setLoading(true);
        await setClientAPICall({
            payload: [{ "keyword": `${search}` }]
        })
        setOpenClient(true);
    };

    const handleCloseClient = () => {
        setOpenClient(false);
    };


    const dataTableData = {
        columns: [
            { Header: "Select", accessor: "id", Cell: ({ value }) => <DefaultCell value={value} /> },
            {
                Header: "Client",
                accessor: "client",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            //   { Header: "Can Approve", accessor: "approve", Cell: ({ value }) => <DefaultCell value={value} /> },
        ],

        rows: adminList
    };

    return (
        <>
            {showForm ?
                <DashboardLayout>
                    < DashboardNavbar />
                    < MDBox mt={4} >
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={12}>
                                <MDBox mb={3}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Card id="basic-info" sx={{ overflow: "visible" }}>
                                                <Formik
                                                    initialValues={initialValues}
                                                    validationSchema={EditSchema}
                                                    onSubmit={handleSubmit}
                                                >
                                                    {({ values, errors, touched, isSubmitting }) => (
                                                        <Form autoComplete="off">
                                                            <Card sx={{ height: "100%" }}>
                                                                <MDBox p={3}>
                                                                    <MDBox>
                                                                        <MDBox lineHeight={0}>
                                                                            <MDTypography variant="h5">Edit User</MDTypography>
                                                                        </MDBox>
                                                                        <MDBox mt={1.625}>
                                                                            <Grid container spacing={3}>
                                                                                <Grid item xs={12} sm={6}>
                                                                                    <FormField
                                                                                        type="text"
                                                                                        label="First Name *"
                                                                                        name="firstname"
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={6}>
                                                                                    <FormField
                                                                                        type="text"
                                                                                        label="Last Name *"
                                                                                        name="lastname"
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <FormField
                                                                                    type="email"
                                                                                    label="Email *"
                                                                                    name="email"
                                                                                    disabled
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <NumberFormat
                                                                                    customInput={TextField}
                                                                                    variant="standard"
                                                                                    type="tel"
                                                                                    value={phone}
                                                                                    fullWidth
                                                                                    label="Phone Number"
                                                                                    sx={{
                                                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                                                            WebkitTextFillColor: "black",
                                                                                        }, mb: 1, mt: -0.3, fontSize: `0.823rem !important`
                                                                                    }}
                                                                                    format="(###) ###-####"
                                                                                    mask=""
                                                                                    onValueChange={value => setPhone(value.formattedValue)}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <FormControl fullWidth variant="standard" sx={{ mt: 1 }} >
                                                                                    <InputLabel sx={{ mt: -3 }} >Role *</InputLabel>
                                                                                    <Field
                                                                                        as="select"
                                                                                        name="role"
                                                                                        variant="standard"
                                                                                        style={{
                                                                                            marginTop: "18px",
                                                                                            borderTop: "0px",
                                                                                            borderLeft: "0px",
                                                                                            borderRight: "0px",
                                                                                            color: "slategray",
                                                                                        }}
                                                                                    >
                                                                                        <option value="">Select Role</option>
                                                                                        {Object.keys(User1).map((key) => (
                                                                                            <option value={key
                                                                                            }>{User1[key]}</option>
                                                                                        ))}
                                                                                    </Field>
                                                                                    <MDBox mt={0.75}>
                                                                                        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                                                                            <ErrorMessage name="role" />
                                                                                        </MDTypography>
                                                                                    </MDBox>

                                                                                    <>{(values.role == 'domain1') ? <MDTypography component="div" variant="caption" color="info" fontWeight="regular">This user will have access to the entire Paynthr account and be able to add/edit users.</MDTypography> : ''}
                                                                                        {(values.role == 'domain') ? <MDTypography component="div" variant="caption" color="info" fontWeight="regular">This user will have access to the entire account except the billing page and be able to add/edit users</MDTypography> : ''}
                                                                                        {(values.role == 'client') ? <MDTypography component="div" variant="caption" color="info" fontWeight="regular"
                                                                                        >This user will have only have access to the Documents & Inventory page</MDTypography> : ''}
                                                                                    </>
                                                                                    <>{setSelectedRole(values.role)}</>

                                                                                </FormControl>
                                                                            </Grid>
                                                                            <>{(values.role === 'client' || values.role === 'domain') ? setShowCheck(true) : setShowCheck(false)}</>
                                                                            <>
                                                                                <Grid item xs={12}>

                                                                                    {
                                                                                        showCheck &&
                                                                                        <>
                                                                                            <MDBox>
                                                                                                <Field
                                                                                                    as={FormControlLabel}
                                                                                                    type="checkbox"
                                                                                                    name="allClient"
                                                                                                    control={<Checkbox />}
                                                                                                    label="All Clients"
                                                                                                    checked={allClient}
                                                                                                    onChange={handleChange}
                                                                                                    sx={{ mt: 1.5 }}
                                                                                                />
                                                                                            </MDBox>


                                                                                            {!allClient ? <MDBox sx={{ mt: 1 }}>
                                                                                                <Button
                                                                                                    // color="info"
                                                                                                    style={{ color: "white" }}
                                                                                                    onClick={() => {
                                                                                                        handleClickOpenClient();
                                                                                                    }} variant="contained"
                                                                                                >
                                                                                                    {selectedClientLength > 0 && !allClient ? `${selectedClientLength} Selected Clients` : `Select Clients`}
                                                                                                </Button>
                                                                                            </MDBox> : ''}
                                                                                        </>
                                                                                    }
                                                                                </Grid>
                                                                                <Grid item xs={3} sx={{ pr: 11 }}>
                                                                                    <MDBox sx={{ mt: 1 }}>
                                                                                        <FormControlLabel control={<Checkbox checked={!activeUser} onChange={ToggleChange} />} label="Is Active"
                                                                                        // disabled={allClient}
                                                                                        />
                                                                                    </MDBox>
                                                                                </Grid>
                                                                            </>
                                                                        </MDBox>
                                                                        <MDBox mt={2} width="100%" display="flex" justifyContent="end">

                                                                            <MDButton
                                                                                disabled={btnDisable}
                                                                                variant="gradient"
                                                                                color="light"
                                                                                component={Link}
                                                                                to="/accountant/users/list"
                                                                            >
                                                                                Cancel
                                                                            </MDButton>
                                                                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                                                <MDButton
                                                                                    disabled={isDisable}
                                                                                    type="submit"
                                                                                    variant="gradient"
                                                                                    color="info"
                                                                                >
                                                                                    {isDisable ? "updating..." : "update"}
                                                                                </MDButton>
                                                                            </MDBox>
                                                                        </MDBox>
                                                                    </MDBox>
                                                                </MDBox>
                                                            </Card>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </MDBox >
                    <MDBox>
                        <Dialog
                            open={openClient}
                            onClose={handleCloseClient}
                            fullWidth='true'
                        >
                            <DialogTitle sx={{
                                justifyContent: "center",
                                display: 'flex'
                            }}>SELECT CLIENTS</DialogTitle>
                            <DialogContent>
                                <MDBox my={5} sx={{ mt: 1 }}>
                                    <MDBox>
                                        {loading && (
                                            <CircularProgress
                                                size={50}
                                                sx={{
                                                    color: green[500],
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    marginTop: '-12px',
                                                    marginLeft: '-12px',
                                                }}
                                            />
                                        )}
                                    </MDBox>
                                    <DataTable table={dataTableData} entriesPerPage={false} pagination={false} showTotalEntries={true} />
                                </MDBox>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseClient} autoFocus>
                                    Ok
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </MDBox>
                    <MDBox>
                        <MDSnackbar
                            color="success"
                            icon="done"
                            title="success"
                            content="User Updated Successfully!"
                            open={showSnackbar}
                        // close={() => setShowSnackbar(false)}
                        // autoHideDuration={3000}
                        />
                        <MDSnackbar
                            color="error"
                            icon="warning"
                            title="Failed."
                            content={ErrorMessages}
                            // autoHideDuration={3000}
                            open={showErrorSnackbar}
                            close={() => setShowErrorSnackbar(false)}
                        />
                    </MDBox>
                </DashboardLayout >
                :
                <DashboardLayout>
                    <MDBox display="flex" justifyContent="center" >
                        {loading && <img src={LoaderPng} width="600" height='auto'
                            style={{
                                position: 'absolute',
                                top: '350%',
                                bottom: '100%',
                                left: '20%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }} />}
                    </MDBox>
                </DashboardLayout>
            }
        </>
    );
}

export default UserHoc(EditUsers);