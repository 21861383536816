import React from 'react'
import Layout from "layouts/auth/components/BasicLayout/404layout";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import { useNavigate } from 'react-router-dom';


const Forbidden = () => {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    return (
        <Layout >
            <MDBox mt={5} mb={9}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} lg={8}>
                        <MDBox mt={6} mb={8} textAlign="center">
                            <MDBox mb={1}>
                                <MDTypography variant="h1" fontWeight="bold">
                                    403
                                </MDTypography>
                            </MDBox>
                            <MDTypography variant="h5" fontWeight="regular" color="secondary">
                                Forbidden Page
                            </MDTypography>
                        </MDBox>
                        <MDButton variant="gradient" color="light" onClick={goBack}
                        >
                            back
                        </MDButton>
                    </Grid>
                </Grid>
            </MDBox>
        </Layout>
    )
}

export default Forbidden;