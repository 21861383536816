import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { eventBus } from 'utils/eventbus';
import FormField from "layouts/admin/accountants/components/FormField";
import { useAPICall } from "utils/useapicall";
import { AddCategoryApi } from "services/userservices";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import { EditCategorySchema } from "layouts/clients/components/schemas/editcategory.js";
import { data } from "layouts/clients/QboAccountTypes.js";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";

function AddNewCategoryDialog({ onClose, invoiceId, businessId }) {
    const [btnDisable, setBtnDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [categoryName, setCategoryName] = useState('');
    const [classification, setClassification] = useState('');
    const [accountType, setAccountType] = useState('');
    const [jsondata] = useState(data[0].classifications);
    const [jsonName, setJsonName] = useState('');
    const [accountSubTypeJson, setAccountSubTypeJson] = useState([]);
    const [classificationJson, setClassificationJson] = useState([]);
    const [accountTypeName, setAccountTypeName] = useState('');
    const [accountSubTypeName, setAccountSubTypeName] = useState('');
    const [{ isLoading: isAddCategoryLoading, isError: isAddCategoryError, response: AddCategoryResponse }, AddCategoryListAPICall] = useAPICall(AddCategoryApi, { onLoad: false });
    const [errorMessage, setErrorMessage] = useState('');
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);

    useEffect(() => {
        if (isAddCategoryLoading) {
            return () => null;
        }
        if (isAddCategoryError) {
            setErrorMessage(AddCategoryResponse?.data?.message);
            setLoading(false);
            setBtnDisable(false);
            setShowErrorSnackbar(true);
            setTimeout(() => {
                setShowErrorSnackbar(false);
            }, 3000);
            return () => null;
        }
        if (AddCategoryResponse && AddCategoryResponse?.data?.isSuccess === true) {
            setLoading(false);
            setBtnDisable(false);
            eventBus.$emit("AddnewCategoryComplete", AddCategoryResponse?.data?.data);
            onClose();
        }
    }, [AddCategoryResponse]);


    useEffect(async () => {
        jsondata.map((x) => (
            x.name === jsonName ?
                setClassificationJson(x.accountTypes)
                : ''
        ))
    }, [jsonName]);

    useEffect(() => {
        const obj = {};
        classificationJson.map((x, index) => (
            x.name === accountTypeName ?
                x.subTypes.forEach((element, index) => {
                    obj[`${index}`] = element;
                })
                : '',
            setAccountSubTypeJson(Object.values(obj))
        ))

    }, [accountTypeName])


    let initialValues = {
        category: categoryName,
        accountType: accountType,
        classification: classification,
    };

    const handleSubmit = async (values) => {
        setBtnDisable(true);
        await AddCategoryListAPICall({
            payload: [
                {
                    "name": values.category,
                    "subAccount": false,
                    "parentId": null,
                    "classification": values.classification,
                    "accountType": values.accountType,
                    "accountSubType": values.accountSubType,
                    "clientId": `${businessId}`,
                }]
        })
    };

    return (
        <>{
            <>
                < MDBox mt={0} >
                    <Grid spacing={3}>
                        <Grid item xs={12}>
                            <Formik
                                enableReinitialize
                                initialValues={initialValues}
                                validationSchema={EditCategorySchema}
                                onSubmit={handleSubmit}
                            >
                                {({ values, errors, touched, isSubmitting }) => (
                                    <Form autoComplete="off">
                                        <MDBox p={1}>
                                            <MDBox>
                                                <MDBox mt={1.625}>
                                                    <Grid container spacing={3} sx={{ mb: 2 }}>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Name *"
                                                                name="category"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormControl fullWidth variant="standard" sx={{ mt: 1 }} >
                                                                <InputLabel sx={{ mt: -3 }} >Classification *</InputLabel>
                                                                <Field
                                                                    as="select"
                                                                    name="classification"
                                                                    variant="standard"
                                                                    style={{
                                                                        marginTop: "22px",
                                                                        borderTop: "0px",
                                                                        borderLeft: "0px",
                                                                        borderRight: "0px",
                                                                        color: "slategray",
                                                                    }}
                                                                >
                                                                    <option value="">Select Classification</option>
                                                                    {jsondata.map((x) => (
                                                                        <option value={x.name
                                                                        }>{x.name}</option>
                                                                    ))}
                                                                </Field>
                                                                <MDBox mt={0.75}>
                                                                    <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                                                        <ErrorMessage name="classification" />
                                                                    </MDTypography>
                                                                </MDBox>
                                                                <>{setJsonName(values.classification)}</>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormControl fullWidth variant="standard" sx={{ mt: 1 }} >
                                                                <InputLabel sx={{ mt: -3, p: '2px' }} >AccountType *</InputLabel>
                                                                <Field
                                                                    as="select"
                                                                    name="accountType"
                                                                    variant="standard"
                                                                    style={{
                                                                        marginTop: "18px",
                                                                        borderTop: "0px",
                                                                        borderLeft: "0px",
                                                                        borderRight: "0px",
                                                                        color: "slategray",
                                                                    }}
                                                                >
                                                                    <option value="">Select AccountType</option>
                                                                    {classificationJson.map((x) => (
                                                                        <option value={x.name
                                                                        }>{x.name}</option>
                                                                    ))}
                                                                </Field>
                                                                <MDBox mt={0.75}>
                                                                    <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                                                        <ErrorMessage name="accountType" />
                                                                    </MDTypography>
                                                                </MDBox>
                                                                <>{setAccountTypeName(values.accountType)}</>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormControl fullWidth variant="standard" sx={{ mt: 1 }} >
                                                                <InputLabel sx={{ mt: -3, p: '2px' }} >AccountSubType</InputLabel>
                                                                <Field
                                                                    as="select"
                                                                    name="accountSubType"
                                                                    variant="standard"
                                                                    style={{
                                                                        marginTop: "18px",
                                                                        borderTop: "0px",
                                                                        borderLeft: "0px",
                                                                        borderRight: "0px",
                                                                        color: "slategray",
                                                                    }}
                                                                >
                                                                    <option value="">Select AccountSubType</option>
                                                                    {accountSubTypeJson.map((x) =>
                                                                    (
                                                                        <option value={x.name
                                                                        }>{x.name}</option>
                                                                    ))}
                                                                </Field>
                                                                <MDBox mt={0.75}>
                                                                    <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                                                        <ErrorMessage name="accountSubType" />
                                                                    </MDTypography>
                                                                </MDBox>
                                                                <>{setAccountSubTypeName(values.accountSubType)}</>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </MDBox>
                                                <MDBox mt={5} width="100%" display="flex" justifyContent="end">
                                                    <MDButton
                                                        disabled={btnDisable}
                                                        variant="gradient"
                                                        color="light"
                                                        onClick={onClose}
                                                    >
                                                        Cancel
                                                    </MDButton>
                                                    <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                        <MDButton
                                                            disabled={btnDisable}
                                                            type="submit"
                                                            variant="gradient"
                                                            color="info"
                                                        >
                                                            {btnDisable ? "Submitting..." : "Submit"}
                                                        </MDButton>
                                                    </MDBox>
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>

                                    </Form>
                                )}
                            </Formik>

                        </Grid>

                    </Grid>
                </MDBox>
                <MDBox>
                    <MDSnackbar
                        color="error"
                        icon="warning"
                        title="Failed."
                        content={errorMessage}
                        // autoHideDuration={3000}
                        open={showErrorSnackbar}
                        close={() => setShowErrorSnackbar(false)}
                    />
                </MDBox>
            </>
        }
            <MDBox>
                {loading && (
                    <CircularProgress
                        size={50}
                        sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </MDBox>
        </>
    );
}

export default AddNewCategoryDialog;
