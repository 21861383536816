import * as Yup from "yup";

export const EditProductSchema = Yup.object().shape({
    product: Yup.string()
        .trim()
        .required('Product name is required.')
        .min(3, 'Product  name should be more than 3 characters.'),
    price: Yup.string()
        .required("Price is required"),
    // productType: Yup.string()
    //     .required("ProductType is required"),
});