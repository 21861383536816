import * as Yup from "yup";

export const addproductschema = Yup.object().shape({
    price: Yup.number()
        // .positive("Price Must be more than 0")
        // .integer("Price Must be more than 0")
        .required("Price is required"),
    qty: Yup.number()
        .positive("Quantity Must be more than 0")
        // .integer("Quantity Must be more than 0")
        .required("Quantity is required"),
});
