import * as React from "react";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { NavLink, useLocation } from "react-router-dom";
import DashboardLayout from "../../clientuser/user/components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../clientuser/user/components/Navbars/DashboardNavbar";
import DataTable from "../../components/Tables/DataTable";
import dataTableData from "layouts/clientuser/user/data/dataTableData";
import { Link, useNavigate } from 'react-router-dom';
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import { useAPICall } from "../../../utils/useapicall";
import { GetclientUserList, EditClientUserInfo } from "../../../services/clientservices";
import CircularProgress from '@mui/material/CircularProgress';
import { green, yellow } from '@mui/material/colors';
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { canAccess } from '../../../helpers';
import UserHoc from "hoc/userHoc";
import Cookies from 'universal-cookie';
import Loader from "layouts/accountants/user/components/imgLoader/loader";
import { eventBus } from "utils/eventbus";
import DataTable1 from "components/NewTable/table";
const cookies = new Cookies();

function User() {
    const navigate = useNavigate();
    const [alert, setAlert] = useState(false);
    const [clientList, setClientList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tableEmpty, setTableEmpty] = useState("");
    const [search, setSearch] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [clientId, setClientId] = useState();
    const [loaderClass, setLoaderClass] = useState({});
    const [loadingCircul, setLoadingCircul] = useState(false);
    const [profileInfo, setProfileInfo] = useState(cookies.get('client_profile_info'));
    const action = { route: "", edittooltip: "Edit", deletetooltip: "Delete" };
    const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetclientUserList, { onLoad: false });
    const [{ isLoading: isRemoveLoading, isError: isRemoveError, response: removeResponse }, removeClientAPICall] = useAPICall(EditClientUserInfo, { onLoad: false });
    var resource = '';
    var actions = '';
    const { pathname } = useLocation();

    eventBus.$on('onclickchangePages', (data) => OnclickchangePages(data));
    function OnclickchangePages(data) {
        setLoading(true);
        const dataStr = data.toString() + 0
        localStorage.setItem("offset", Number(dataStr))
        setAPICall({
            payload: [{ "keyword": `${search}`, 'offset': Number(dataStr) }]
        });
    }

    useEffect(async () => {
        const queryParameters = new URLSearchParams(window.location.search)
        const keyword = queryParameters.get("keyword");
        const ThisOffsetuse = localStorage.getItem("offset");
        if (keyword) {
            setSearch(keyword);
            setLoading(true);
        } else {
            setLoading(true);
            await setAPICall({
                payload: [{ "keyword": `${search}`, 'offset': ThisOffsetuse }]
            })
        }
    }, []);

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const keyword = queryParameters.get("keyword");
        if (!keyword) {
            window.history.pushState('data', 'title', `${pathname}`);
        }
    }, [search]);

    useEffect(() => {
        eventBus.$emit("OnSetBlur", loading);
        loading === true ? setLoaderClass({ opacity: '0.6', borderRadius: '13px' }) : setLoaderClass({})
    }, [loading])

    useEffect(() => {
        if (isRemoveLoading) {
            return () => null;
        }

        if (isRemoveError) {
            setLoading(true);
            return () => null;
        }

        if (removeResponse && removeResponse.data.isSuccess === true) {
            const ThisOffsetuse = localStorage.getItem("offset");
            setShowSnackbar(true);
            setTimeout(() => {
                setShowSnackbar(false);
            }, 10000);
            setLoading(true);
            setAPICall({
                payload: [{ "keyword": `${search}`, 'offset': ThisOffsetuse }]
            })
            // navigate('/accountants/list');
        }
    }, [removeResponse]);


    useEffect(() => {
        const ThisOffsetuse = localStorage.getItem("offset");
        const timeOutId = setTimeout(async () =>
            await setAPICall({
                payload: [{ "keyword": `${search}`, 'offset': ThisOffsetuse }]
            }), 500);
        return () => clearTimeout(timeOutId);
    }, [search]);

    const warningWithConfirmMessage = () => {
        setAlert(true);
    };
    const successDelete = async () => {
        await removeClientAPICall({
            payload: [
                {
                    "isBlocked": true,
                    "isArchived": true,
                    "bussinessId": clientId
                }
            ]
        })
        setAlert(false);
    };

    const hideAlert = () => {
        setAlert(false);
    };

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    useEffect(() => {
        if (isLoading) {
            return () => null;
        }
        if (isError) {
            setLoadingCircul(false);
            setLoading(false);
            response?.code === "DATA401" ? navigate('/client/logout') : console.log("somting else");
            return () => null;
        }
        if (response && response.data.isSuccess === true) {
            setTableEmpty(response.data.data.totalRecords);
            const data = response.data.data.records.map((prop, key) => {
                const Createdate = new Date(prop.createdAt);
                return {
                    snum: (key + 1),
                    firstName: prop.fname,
                    business: prop.businessName,
                    email: prop.email,
                    phone: prop.phone,
                    createdat: Createdate.toLocaleDateString(),
                    totalaccountants: "-",
                    totalclients: "-",
                    monthlycost: "-",
                    switch: (
                        prop.joinedAt !== null ?
                            prop.isBlocked ?
                                <MDBox>
                                    <MDTypography
                                        variant="caption" fontWeight="regular" fontSize="0.875rem" color="error"
                                    // sx={{ marginLeft: "-4px", fontSize: "1.20rem" }}
                                    >
                                        Inactive
                                    </MDTypography>
                                </MDBox>
                                :
                                <MDBox>
                                    <MDTypography
                                        variant="caption" fontWeight="regular" fontSize="0.875rem" color="success"
                                    // sx={{ marginLeft: "-4px", fontSize: "1.20rem" }}
                                    >
                                        Active
                                    </MDTypography>
                                </MDBox>
                            :
                            <MDBox>
                                <MDTypography
                                    variant="caption" fontWeight="regular" fontSize="0.875rem" color="warning"
                                >
                                    Pending Confirmation
                                </MDTypography>
                            </MDBox>

                    ),
                    role: (capitalizeFirstLetter(prop?.permissions[0]?.role)),
                    action: (profileInfo && profileInfo.user && profileInfo?.user?._id == prop._id ? ("") : (
                        <MDBox display="flex" justifyContent="center">
                            {canAccess(actions = 'update', resource = 'employees') &&
                                <Link to={`/clients/employees/${prop._id}/edit-user`}>
                                    <MDTypography
                                        variant="body2"
                                        color="secondary"
                                        sx={{ marginLeft: "-4px", fontSize: "1.20rem" }}
                                    >
                                        <Tooltip title={action.edittooltip} placement="top">
                                            <Icon>edit</Icon>
                                        </Tooltip>
                                    </MDTypography>
                                </Link>
                            }
                            {canAccess(actions = 'delete', resource = 'employees') &&
                                <MDTypography variant="body2" color="secondary" sx={{ marginLeft: "8px", fontSize: "1.20rem" }}>
                                    <Tooltip title={action.deletetooltip} placement="top"
                                        onClick={(value) => {
                                            setClientId(prop._id);
                                            warningWithConfirmMessage();
                                        }}
                                    >
                                        <Icon>delete</Icon>
                                    </Tooltip>
                                </MDTypography>
                            }
                        </MDBox>
                    )
                    )
                };
            }
            );
            setClientList(data);
            setLoading(false);
            setLoadingCircul(false);
        }
    }, [response]);

    const clientEmployeeList = {
        columns: [
            {
                Header: "Name",
                accessor: "firstName",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Email",
                accessor: "email",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Phone Number",
                accessor: "phone",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Role",
                accessor: "role",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Status",
                accessor: "switch",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Created Date",
                accessor: "createdat",
                Cell: ({ value }) => <DefaultCell value={value} />,

            },
            {
                Header: "Action",
                accessor: "action",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
        ],

        rows: clientList
    };

    const handleSearch = async (event) => {
        localStorage.setItem("offset", 0)
        setLoadingCircul(true);
        window.history.pushState('data', 'title', `${pathname}?keyword=${encodeURIComponent(event.target.value)}`);
        setSearch(event.target.value);
        // setLoading(true);
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={4}>
                <Card sx={loaderClass}>
                    <MDBox pt={3} px={2} sx={{ pr: 3, mb: 2 }} component="form" display="flex" justifyContent="space-between" alignItems="center">
                        <MDTypography variant="h5" fontWeight="medium">
                            Users
                        </MDTypography>
                        <MDBox px={2} display="flex" >
                            <MDBox sx={{ pr: 3, mb: 2 }}>
                                <MDInput type="search" label="Search Users" onKeyDown={handleKeyDown} variant="standard" onChange={handleSearch} />
                            </MDBox>
                            {canAccess(actions = 'create', resource = 'employees') &&
                                <MDBox>
                                    <MDButton component={NavLink} to="/client/user/new-user"
                                        variant="gradient"
                                        color="info"
                                        disabled={loading}
                                    > Add New User
                                    </MDButton>
                                </MDBox>
                            }
                        </MDBox>
                    </MDBox>
                    <MDBox>
                        {loadingCircul && (
                            <CircularProgress
                                size={50}
                                sx={{
                                    color: green[500],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </MDBox>
                    <MDBox>
                        <Dialog
                            open={alert}
                            onClose={hideAlert}
                            aria-labelledby="alert-dialog-title"
                            sx={{
                                "& .MuiDialog-container": {
                                    "& .MuiPaper-root": {
                                        width: "100%",
                                        height: "45%",
                                        maxWidth: "450px",
                                    },
                                },
                            }}
                        >
                            <DialogContent>
                                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}  >
                                    <MDTypography
                                        variant="body2"
                                        color="secondary"
                                        sx={{ fontSize: 50 }}
                                    >
                                        <WarningAmberIcon sx={{ color: yellow[500], fontSize: 50 }} />
                                    </MDTypography>

                                </DialogContentText>
                                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                                    <h3>Are you sure?</h3>
                                </DialogContentText>
                                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                                    <MDTypography
                                        variant="body2"
                                        color="secondary"
                                        sx={{ fontWeight: 300, fontSize: "0.90rem", textAlign: "center" }}
                                    >
                                        Once you delete this User there is no way of getting any of the documents or data back?
                                    </MDTypography>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions sx={{ justifyContent: "center" }} >
                                <MDButton
                                    variant="gradient"
                                    color="success"
                                    onClick={successDelete} >
                                    Yes, delete it!
                                </MDButton>
                                <MDButton
                                    variant="gradient"
                                    color="error"
                                    onClick={hideAlert}
                                >Cancel
                                </MDButton>
                            </DialogActions>
                        </Dialog>
                    </MDBox>
                    <MDBox my={5} sx={{ mt: 1 }}>
                        <DataTable1 table={clientEmployeeList} entriesPerPage={false} pagination={true} showTotalEntries={true} response={tableEmpty} showCheck={false} ClientHeigth={true} />
                    </MDBox>
                </Card>
                <MDBox display="flex" justifyContent="center" >
                    {loading && <Loader />}
                </MDBox>
            </MDBox>
        </DashboardLayout>
    );
}

export default UserHoc(User);