import { Formik, Form, Field, ErrorMessage } from "formik";
import { useState, useEffect } from "react";
import { SITE_URL } from "helpers/config";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "../../../clientuser/user/components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../clientuser/user/components/Navbars/DashboardNavbar";
import { AddNewSchema, AddNewSchema2 } from "layouts/clientuser/user/components/schemas/addnewvalidations";
import initialValues from "layouts/accountants/user/components/schemas/initialValues";
import FormField from "layouts/admin/accountants/components/FormField";
import { Link, useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useAPICall } from "../../../../utils/useapicall";
import { AddNewClientUser, GetClientUserRolescope, checkEmail, EditClientUserInfo } from "../../../../services/clientservices";
import UserHoc from "hoc/userHoc";
import MDSnackbar from "components/MDSnackbar";
import { User, UserM, UserU, UserA } from "helpers/getUser";
import { TextField } from "@mui/material";
import NumberFormat from "react-number-format";
import { getUserPermissions } from 'helpers/getUserPermissions';

function AddNewUser() {
    const navigate = useNavigate();
    const permission = getUserPermissions();
    const [disable, setDisable] = useState(false);
    const [disable1, setDisable1] = useState(false);
    const [clientRoleId, setclientRoleId] = useState("");
    const [adminRoleId, setAdminRoleId] = useState("");
    const [ownerRoleId, setOwnerRoleId] = useState("");
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [selectedRole, setSelectedRole] = useState('');
    const [SnackbarErrorMessage, setErrorMessage] = useState("");
    const [emailId, setemailId] = useState('');
    const [selectedFeatures, setSelectedFeatures] = useState('');
    const [emailStatus, setEmailStatus] = useState('');
    const [errorMessages, setErrorMessages] = useState('');
    const [styleMt, setStyleMt] = useState('0px');
    const [btnDisable1, setBtnDisable1] = useState(false);
    const [userId, setUserId] = useState();
    const [phone, setPhone] = useState('');
    const [showFeature, setShowFeature] = useState('');
    const [showRole, setShowRole] = useState('');
    const [{ isError, isLoading, response }, setAPICall] = useAPICall(AddNewClientUser, { onLoad: false });
    const [{ isLoading: isRoleLoading, isError: isRoleError, response: roleResponse }, getRoleAPICall] = useAPICall(GetClientUserRolescope, { onLoad: false });
    const [{ isError: isCheckEmError, isLoading: isCheckEmLoading, response: checkEmResponse }, checkEmailAPICall] = useAPICall(checkEmail, { onLoad: false });
    const [{ isLoading: isUpdateLoading, isError: isUpdateError, response: UpadateResponse }, UpdateAPICall] = useAPICall(EditClientUserInfo, { onLoad: false });

    useEffect(() => {
        console.log('selectedRole', selectedRole);
        console.log('selectedFeatures', selectedFeatures);
    }, [selectedRole, selectedFeatures, permission])

    useEffect(() => {

        if (isUpdateLoading) {
            return () => null;
        }

        if (isUpdateError) {
            return () => null;
        }

        if (UpadateResponse && UpadateResponse.data.isSuccess === true) {
            navigate('/client/user/list');
        }
    }, [UpadateResponse]);

    useEffect(async () => {
        const getInvCategories = () => {
            const arr = permission;
            return arr.filter((obj, index, self) =>
                index === self.findIndex((o) => o.category === "inventory")
            );
        }
        const getAccCategories = () => {
            const arr = permission;
            return arr.filter((obj, index, self) =>
                index === self.findIndex((o) => o.category === "accounting")
            );
        }

        const getUserMangerRole = () => {
            const arr = permission;
            return arr.filter((obj, index, self) =>
                index === self.findIndex((o) => o.role === "manager")
            );
        }

        const getUserClientRole = () => {
            const arr = permission;
            return arr.filter((obj, index, self) =>
                index === self.findIndex((o) => o.role === "user")
            );
        }

        const getUserAdminRole = () => {
            const arr = permission;
            return arr.filter((obj, index, self) =>
                index === self.findIndex((o) => o.role === "admin")
            );
        }

        const getUserOwnerRole = () => {
            const arr = permission;
            return arr.filter((obj, index, self) =>
                index === self.findIndex((o) => o.role === "owner")
            );
        }
        const isAdmin = getUserAdminRole();
        const isOwner = getUserOwnerRole();
        const isManager = getUserMangerRole();
        const isClient = getUserClientRole();


        if (isOwner.length !== 0) {
            setShowRole("owner")
        }
        if (isAdmin.length !== 0) {
            setShowRole("admin")
        } else if (isManager.length !== 0) {
            setShowRole("manager")
        } else if (isClient.length !== 0) {
            setShowRole("user")
        }
        console.log("isOwner----------->", isOwner)
        console.log("isAdmin---------->", isAdmin)
        console.log("isManager---------->", isManager)
        console.log("isClient---------->", isClient)
        const finalInventoryPermision = getInvCategories();
        const finalAccounatntPermision = getAccCategories();
        if (finalInventoryPermision.length !== 0 && finalAccounatntPermision.length !== 0) {
            setShowFeature('Both');
            console.log("-----both------------")
        } else if (finalAccounatntPermision.length !== 0) {
            setShowFeature('accounting');
            console.log("-----Accounatnt------------")
        } else if (finalInventoryPermision.length !== 0) {
            setShowFeature('inventory');
            console.log("-----Inventory------------")
        }
        console.log('finalPermision', finalInventoryPermision, "--A---", finalAccounatntPermision);
        await getRoleAPICall({
            payload: []
        });
    }, []);

    useEffect(() => {
        if (isCheckEmError) {
            return () => null;
        }
        if (checkEmResponse && checkEmResponse.data.isSuccess === true) {
            setEmailStatus(checkEmResponse.data.data.status);
            setUserId(checkEmResponse.data.data.userId);
            if (checkEmResponse.data.data.status === 'ARCHIVED') {
                setStyleMt('-8px');
                setErrorMessages(`${checkEmResponse?.data?.data?.message} - Click here to restore`);
            } else if (checkEmResponse.data.data.status === 'ACTIVE') {
                setStyleMt('-8px');
                setErrorMessages(checkEmResponse?.data?.data?.message);
            } else if (checkEmResponse.data.data.status === 'BLOCKED') {
                setStyleMt('-8px');
                setErrorMessages(`${checkEmResponse?.data?.data?.message} - Click here to unblock`);
            } else {
                setStyleMt('0px');
                setErrorMessages('');
                setDisable(false);
            }
        }

    }, [checkEmResponse]);

    useEffect(() => {
        const timeOutId = setTimeout(async () => {
            if (emailId.length !== 0) {
                setDisable(true);
                await checkEmailAPICall({
                    payload: [
                        {
                            "email": `${emailId}`,
                            "checkBy": "email"
                        }
                    ]
                })
            }
        }, 2000)
        return () => clearTimeout(timeOutId);
    }, [emailId]);

    useEffect(async () => {
        if (isLoading) {
            return () => null;
        }
        if (isError) {
            if (response?.data?.message) {
                response?.code === "DATA401" ? navigate('/client/logout') : console.log("somting else");
                setBtnDisable1(false);
                setErrorMessage(response.data.message)

                setShowErrorSnackbar(true);
                setTimeout(() => {
                    setShowErrorSnackbar(false);
                }, 10000);
            }
            setDisable(false);
            return () => null;
        }
        if (response && response.data.isSuccess === true) {
            navigate('/client/user/list');
        }
    }, [response]);

    useEffect(() => {

        if (isRoleLoading) {
            return () => null;
        }

        if (isRoleError) {
            return () => null;
        }

        if (roleResponse && roleResponse.data.isSuccess === true) {
            // const adminresult = roleResponse.data.data.records.find(({ template }) => template === "admin");
            // const clientresult = roleResponse.data.data.records.find(({ template }) => template === "executive");
            // const ownerresult = roleResponse.data.data.records.find(({ template }) => template === "owner");
            // { adminresult ? setAdminRoleId(adminresult._id) : "" }
            // { clientresult ? setclientRoleId(clientresult._id) : "" }
            // { ownerresult ? setOwnerRoleId(ownerresult._id) : "" }
            // navigate('/accountants/list');
        }
    }, [roleResponse])


    const handleSubmit = async (values) => {
        console.log("showFeature-------------->", showFeature, selectedRole, selectedFeatures)
        setSelectedRole(values.role);
        setBtnDisable1(true);
        setDisable1(true);
        setDisable(true);
        if (selectedRole == 'admin' && showFeature === 'Both') {
            await setAPICall({
                payload: [
                    {
                        "email": emailId,
                        "phone": phone,
                        "fname": `${values.firstname}`,
                        "lname": `${values.lastname}`,
                        "returnLink": `${SITE_URL}/user/create-password`,
                        "permissions": [
                            {
                                "category": "inventory",
                                "role": "admin",
                                "allScopes": true
                            },
                            {
                                "category": "accounting",
                                "role": "admin",
                                "allScopes": true
                            }
                        ]
                    }
                ]
            })
        } else if (selectedRole == 'admin' && showFeature === 'accounting') {
            await setAPICall({
                payload: [
                    {
                        "email": emailId,
                        "phone": phone,
                        "fname": `${values.firstname}`,
                        "lname": `${values.lastname}`,
                        "returnLink": `${SITE_URL}/user/create-password`,
                        "permissions": [
                            {
                                "category": "accounting",
                                "role": "admin",
                                "allScopes": true
                            },
                        ]
                    }
                ]
            })
        } else if (selectedRole == 'admin' && showFeature === 'inventory') {
            await setAPICall({
                payload: [
                    {
                        "email": emailId,
                        "phone": phone,
                        "fname": `${values.firstname}`,
                        "lname": `${values.lastname}`,
                        "returnLink": `${SITE_URL}/user/create-password`,
                        "permissions": [
                            {
                                "category": "inventory",
                                "role": "admin",
                                "allScopes": true
                            }
                        ]
                    }
                ]
            })
        } else if (showFeature === 'accounting') {
            await setAPICall({
                payload: [
                    {
                        "email": emailId,
                        "phone": phone,
                        "fname": `${values.firstname}`,
                        "lname": `${values.lastname}`,
                        "returnLink": `${SITE_URL}/user/create-password`,
                        "permissions": [
                            {
                                "category": "accounting",
                                "role": selectedRole,
                                "allScopes": true
                            }
                        ]
                    }
                ]
            })
        } else if (showFeature === 'inventory') {
            await setAPICall({
                payload: [
                    {
                        "email": emailId,
                        "phone": phone,
                        "fname": `${values.firstname}`,
                        "lname": `${values.lastname}`,
                        "returnLink": `${SITE_URL}/user/create-password`,
                        "permissions": [
                            {
                                "category": "inventory",
                                "role": selectedRole,
                                "allScopes": true
                            }
                        ]
                    }
                ]
            })
        } else if (showFeature === 'Both') {
            await setAPICall({
                payload: [
                    {
                        "email": emailId,
                        "phone": phone,
                        "fname": `${values.firstname}`,
                        "lname": `${values.lastname}`,
                        "returnLink": `${SITE_URL}/user/create-password`,
                        "permissions": [
                            {
                                "category": selectedFeatures,
                                "role": selectedRole,
                                "allScopes": true
                            }
                        ]
                    }
                ]
            })
        }


    };

    const handleRestore = async () => {
        if (emailStatus === 'ARCHIVED') {
            await UpdateAPICall({
                payload: [
                    {
                        "bussinessId": `${userId}`,
                        "isArchived": false,
                    }
                ]
            })
        } else if (emailStatus === 'BLOCKED') {
            await UpdateAPICall({
                payload: [
                    {
                        "bussinessId": `${userId}`,
                        "isBlocked": false,
                    }
                ]
            })
        } else {
            navigate('/client/user/list');
        }

    }

    let emails = ''
    const handleChange = (e) => {
        setErrorMessages('');
        setemailId(e.target.value);
        emails = e.target.value;
    }
    const validate = (value) => {
        const finalEmail = emails ? emails : emailId.slice(0, -1);
        let errorMessage;
        if (!finalEmail) {
            errorMessage = 'Email address is required'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(finalEmail)) {
            errorMessage = 'Your email address is invalid';
        } else {
            errorMessage = ''
        }
        return errorMessage;
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={3} mb={20} height="65vh">
                <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
                    <Grid item xs={12} lg={8}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={(selectedRole == 'admin' || showFeature !== 'Both') ? AddNewSchema : AddNewSchema2}
                            onSubmit={handleSubmit}
                        >
                            {({ values, errors, touched, isSubmitting, setFieldTouched, handleBlur }) => (
                                <Form autoComplete="off">
                                    <Card sx={{ height: "100%" }}>
                                        <MDBox p={3}>
                                            <MDBox>
                                                <MDBox lineHeight={0}>
                                                    <MDTypography variant="h5">Add New User
                                                    </MDTypography>
                                                </MDBox>
                                                <MDBox mt={1.625}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="First Name *"
                                                                name="firstname"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Last Name *"
                                                                name="lastname"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Field
                                                            type="email"
                                                            name="email"
                                                            as={FormField}
                                                            label="Email *"
                                                            validate={validate}
                                                            value={emailId}
                                                            onChange={e => {
                                                                setFieldTouched('type');
                                                                handleChange(e);
                                                                handleBlur(e)
                                                            }}
                                                        />
                                                        <MDBox>
                                                            <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                                                {<div style={{
                                                                    color: '#fb8c00',
                                                                    cursor: 'pointer',
                                                                    marginTop: styleMt
                                                                }} onClick={emailStatus != 'ACTIVE' ? handleRestore : ''}>{errorMessages}</div>}
                                                            </MDTypography>
                                                        </MDBox>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <NumberFormat
                                                            customInput={TextField}
                                                            variant="standard"
                                                            type="tel"
                                                            fullWidth
                                                            value={phone}
                                                            label="Phone Number"
                                                            sx={{
                                                                "& .MuiInputBase-input.Mui-disabled": {
                                                                    WebkitTextFillColor: "black",
                                                                },
                                                                mb: 1, fontSize: `0.823rem !important`
                                                            }}
                                                            format="(###) ###-####"
                                                            mask=""
                                                            onValueChange={value => setPhone(value.formattedValue)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth variant="standard" sx={{ mt: 1 }} >
                                                            <InputLabel sx={{ mt: -3 }} >Role *</InputLabel>
                                                            <Field
                                                                as="select"
                                                                name="role"
                                                                variant="standard"
                                                                style={{
                                                                    marginTop: "18px",
                                                                    borderTop: "0px",
                                                                    borderLeft: "0px",
                                                                    borderRight: "0px",
                                                                    color: "slategray",
                                                                }}
                                                            >
                                                                <option value="">Select Role</option>

                                                                {showRole == 'owner' && Object.keys(User).map((key) => (
                                                                    <option value={key
                                                                    }>{User[key]}</option>
                                                                ))}

                                                                {showRole == 'admin' && Object.keys(UserA).map((key) => (
                                                                    <option value={key
                                                                    }>{UserA[key]}</option>
                                                                ))}

                                                                {showRole == 'manager' && Object.keys(UserM).map((key) => (
                                                                    <option value={key
                                                                    }>{UserM[key]}</option>
                                                                ))}

                                                                {showRole == 'user' && Object.keys(UserU).map((key) => (
                                                                    <option value={key
                                                                    }>{UserU[key]}</option>
                                                                ))}
                                                            </Field>
                                                            <MDBox mt={0.75}>
                                                                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                                                    <ErrorMessage name="role" />
                                                                </MDTypography>
                                                                <>
                                                                    {(values.role == 'admin') ? <MDTypography component="div" variant="caption" color="info" fontWeight="regular">This user will have access to the entire Paynthr account and be able to add/edit users.</MDTypography> : ''}
                                                                    {(values.role == 'manager') ? <MDTypography component="div" variant="caption" color="info" fontWeight="regular">This user will have access to the entire account except the billing page and be able to add/edit users</MDTypography> : ''}
                                                                    {(values.role == 'user') ? <MDTypography component="div" variant="caption" color="info" fontWeight="regular"
                                                                    >This user will have only have access to the Documents & Inventory page</MDTypography> : ''}
                                                                </>
                                                                <>
                                                                    {setSelectedRole(values.role)}</>
                                                            </MDBox>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {(selectedRole !== "" && selectedRole !== 'admin' && showFeature == 'Both') && <FormControl fullWidth variant="standard" sx={{ mt: 1.5 }} >
                                                            <InputLabel sx={{ mt: -3 }} >Features *</InputLabel>
                                                            <Field
                                                                as="select"
                                                                name="features"
                                                                variant="standard"
                                                                style={{
                                                                    marginTop: "18px",
                                                                    borderTop: "0px",
                                                                    borderLeft: "0px",
                                                                    borderRight: "0px",
                                                                    color: "slategray",
                                                                }}
                                                            >
                                                                <option value="">Select Features</option>
                                                                <option value="inventory">Inventory</option>
                                                                <option value="accounting">Accounting</option>
                                                                <option value="both">Both</option>
                                                            </Field>
                                                            <MDBox mt={0.75}>
                                                                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                                                    <ErrorMessage name="features" />
                                                                </MDTypography>
                                                                {setSelectedFeatures(values.features)}
                                                            </MDBox>
                                                        </FormControl>}
                                                    </Grid>
                                                </MDBox>
                                                <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                                                    <MDButton
                                                        disabled={disable1}
                                                        variant="gradient"
                                                        color="light"
                                                        component={Link}
                                                        to="/client/user/list"
                                                    >
                                                        Cancel
                                                    </MDButton>
                                                    <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                        <MDButton
                                                            disabled={disable}
                                                            type="submit"
                                                            variant="gradient"
                                                            color="info"
                                                        >
                                                            {btnDisable1 ? "creating..." : "create"}
                                                        </MDButton>
                                                    </MDBox>
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>
                                    </Card>
                                </Form>
                            )}
                        </Formik>
                        <MDBox>
                            <MDSnackbar
                                color="error"
                                icon="warning"
                                title="Failed."
                                // autoHideDuration={3000}
                                content={SnackbarErrorMessage}
                                // content="Somthing Went to Wrong"
                                open={showErrorSnackbar}
                                close={() => setShowErrorSnackbar(false)}
                            />
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default UserHoc(AddNewUser);