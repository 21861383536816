const initState = {
    permission: [
    ],
    role: "",
    token: '',
    profileInfo: '',
    userType: '',
    qboData: [
    ],
}

export default (state = initState, action) => {
    switch (action.type) {
        case "permission":
            return {
                ...state,
                permission: action.payload
            };
        case "role":
            return {
                ...state,
                role: action.payload
            };
        case "token":
            return {
                ...state,
                token: action.payload
            };
        case "profileInfo":
            return {
                ...state,
                profileInfo: action.payload
            };
        case "userType":
            return {
                ...state,
                userType: action.payload
            };
        case "qboData":
            return {
                ...state,
                qboData: action.payload
            };
        default:
            return state;
    }
};