

import * as Yup from "yup";

// const validations =
//   Yup.object().shape({
//     firstName: Yup.string().required("First name is required."),
//     lastName: Yup.string().required("Last name is required."),
//     email: Yup.string().required("Email address is required.").email("Your email address is invalid"),
//     password: Yup.string().required("Password is required.").min(6, "Your password should be more than 6 characters."),
//     repeatPassword: Yup.string()
//       .required("Password is required.")
//       .oneOf([Yup.ref("password"), null], "Your password doesn't match."),
//     address1: Yup.string().required("Address is required."),
//     city: Yup.string().required("City is required."),
//     zip: Yup.string().required("Zip is required.").min(6, "Zipcode is not valie (e.g. 70000)."),
//     twitter: Yup.string().required("Twitter profile is required."),
//   });

export const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .email('Your email address is invalid')
    .required('Email address is required.'),
  password: Yup.string()
    .trim()
    .required('Password is required.')
});

export const SignUpSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Name is required.')
    .min(3, 'Name should be more than 3 characters.'),
  email: Yup.string()
    .email('Your email address is invalid')
    .required('Email address is required.'),
  password: Yup.string()
    .trim()
    .required('Password is required.')
    .min(6, 'Your password should be more than 6 characters.'),
});

export const ResetPassSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email is invalid.')
    .required('Email is required.'),
});

export const CreatePassSchema = Yup.object().shape({
  password: Yup
    .string()
    .required('New password is required')
    .matches(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{6,}$",
      `Password must contain at least 1 uppercase , 1 lowercase , 1 number, 1 special character and length between 6 to 20`
    ),
  // password: Yup
  //   .string()
  //   .trim()
  //   .min(8, 'Password must be 8 characters long')
  //   .matches(/[0-9]/, 'Password requires a number')
  //   .matches(/[a-z]/, 'Password requires a lowercase letter')
  //   .matches(/[A-Z]/, 'Password requires an uppercase letter')
  //   .matches(/[^\w]/, 'Password requires a symbol')
  //   .required('Password is required.'),
  // .min(6, 'Your password should be more than 6 characters.'),
  repeatPassword: Yup.string()
    .trim()
    .required('Confirm new password is required.')
    .oneOf([Yup.ref("password"), null], "Your password doesn't match."),
});