import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import FormField from "layouts/admin/accountants/components/FormField";
import { useAPICall } from "utils/useapicall";
import { AddNewProducts, GetCategoryList, GetIncomeAccountList, GetExpenseAccountList, GetAssetAccountList, GetExpenseAccountListForInv, GetIncomeAccountListForInv } from "../../../../services/userservices";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import { eventBus } from "utils/eventbus";
import AddNewProduct from "./components/schemas/AddNewProduct";
import AddNewProduct1 from "./components/schemas/AddNewProduct1";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { makeStyles } from "@material-ui/core/styles";
import AddNewCategoryDialog from "./addnewcategory";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import AddNewIncomeAccount from "./addnewIncomeAccount";
import AddNewExpense from "./addnewexpense";
import AddNewAsset from "./addnewasset";
import MDSnackbar from "components/MDSnackbar";
import FormFieldDate from "layouts/accountants/user/components/FormField";

const useStyles = makeStyles({
    InputRoot: {
        '&::-webkit-calendar-picker-indicator': {
            display: 'none !important'
        }
    },
    option: {
        "&:first-child": {
            color: "#0000ff",
        },
        "&:hover:first-child": {
            color: "#0000ff",
            backgroundColor: "none !important"
        }
    }
});

function AddNewProductDialog({ addProductSelectType, onClose, invoiceId, businessId }) {
    const styles = useStyles();
    const [business_id, setBusiness] = useState(businessId);
    const [btnDisable, setBtnDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [categoryName, setCategoryName] = useState('');
    const [categoryerrormsg, setCategoryerrormsg] = useState('');
    const [categoryerror, setCategoryerror] = useState(false);
    const [incomeAccounterrormsg, setincomeAccounterrormsg] = useState('');
    const [incomeAccounterror, setincomeAccounterror] = useState(false);
    const [expenseAccounterrormsg, setexpenseAccounterrormsg] = useState('');
    const [expenseAccounterror, setexpenseAccounterror] = useState(false);
    const [inventoryerrormsg, setinventoryerrormsg] = useState('');
    const [inventoryerror, setinventoryerror] = useState(false);
    const [categoryIds, setCategoryIds] = useState('');
    const [incomeAccountList, setincomeAccountList] = useState([]);
    const [expenseAccountList, setexpenseAccountList] = useState([]);
    const [assetAccountList, setassetAccountList] = useState([]);
    const [incomeAccountId, setincomeAccountId] = useState('');
    const [incomeInventoryAssetId, setincomeInventoryAssetId] = useState('');
    const [incomeExpenseAccountId, setincomeExpenseAccountId] = useState('');
    const [openAddNewCategory, setOpenAddNewCategory] = useState(false);
    const [openExpenseAccount, setOpenExpenseAccount] = useState(false);
    const [openAddInventoryAsset, setOpenAddInventoryAsset] = useState(false);
    const [openAddIncomeAccount, setOpenAddIncomeAccount] = useState(false);
    const [incomeAccountName, setincomeAccountName] = useState('');
    const [expenseAccountName, setexpenseAccountName] = useState('');
    const [assetAccountName, setassetAccountName] = useState('');

    const AddIncome = { name: '+ Add Income Account' }
    const NewIncomeAccountList = [AddIncome, ...incomeAccountList]
    const AddExpense = { name: '+ Add Expense' }
    const NewExpenseAccountList = [AddExpense, ...expenseAccountList]
    const Addasset = { name: '+ Add Asset' }
    const NewAssetAccountList = [Addasset, ...assetAccountList]
    const AddCategory = { name: '+ Add Category' }
    const NewCategoryList = [AddCategory, ...categoryList]

    let initialValues = {
        product: "",
        qtyOnHand: "",
        unitprice: "",
        producttype: "",
        inventoryStartDate: "",
    };

    const [{ isLoading: isAddNewProductLoading, isError: isAddNewProductError, response: AddNewProductResponse }, AddNewProductListAPICall] = useAPICall(AddNewProducts, { onLoad: false });
    const [{ isLoading: isCategoryLoading, isError: isCategoryError, response: CategoryResponse }, CategoryListAPICall] = useAPICall(GetCategoryList, { onLoad: false });
    const [{ isLoading: isincomeAccountListLoading, isError: isincomeAccountListError, response: incomeAccountListResponse }, incomeAccountListListAPICall] = useAPICall(GetIncomeAccountListForInv, { onLoad: false });
    const [{ isLoading: isexpenseAccountListLoading, isError: isexpenseAccountListError, response: expenseAccountListResponse }, expenseAccountListListAPICall] = useAPICall(GetExpenseAccountListForInv, { onLoad: false });
    const [{ isLoading: isassetAccountListLoading, isError: isassetAccountListError, response: assetAccountListResponse }, assetAccountListListAPICall] = useAPICall(GetAssetAccountList, { onLoad: false });
    const [errorMessage, setErrorMessage] = useState('');
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);

    useEffect(() => {
        if (isassetAccountListLoading) {
            return () => null;
        }
        if (isassetAccountListError) {
            return () => null;
        }
        if (assetAccountListResponse && assetAccountListResponse.data.isSuccess === true) {
            const data = assetAccountListResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    assetAccountListId: prop._id
                };
            }
            );
            setassetAccountList(data);
        }
    }, [assetAccountListResponse]);

    useEffect(() => {
        if (isexpenseAccountListLoading) {
            return () => null;
        }
        if (isexpenseAccountListError) {
            return () => null;
        }
        if (expenseAccountListResponse && expenseAccountListResponse.data.isSuccess === true) {
            const data = expenseAccountListResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    expenseAccountListId: prop._id
                };
            }
            );
            setexpenseAccountList(data);
        }
    }, [expenseAccountListResponse]);

    useEffect(() => {
        if (isincomeAccountListLoading) {
            return () => null;
        }
        if (isincomeAccountListError) {
            return () => null;
        }
        if (incomeAccountListResponse && incomeAccountListResponse.data.isSuccess === true) {
            const data = incomeAccountListResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    incomeAccountListId: prop._id
                };
            }
            );
            setincomeAccountList(data);
        }
    }, [incomeAccountListResponse]);

    eventBus.$on('AddnewCategoryComplete', (data) => AddnewCategoryComplete(data));
    async function AddnewCategoryComplete(data) {
        await CategoryListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
        setCategoryName(data?.account?.name);
        setCategoryIds(data?.account?._id);
    }

    eventBus.$on('AddnewIncomeAccountComplete', (data) => AddnewIncomeAccountComplete(data));
    async function AddnewIncomeAccountComplete(data) {
        await incomeAccountListListAPICall({
            payload: [{ "clientId": `${business_id}`, }]
        })
        setincomeAccountName(data?.account?.name);
        setincomeAccountId(data?.account?._id);
    }

    eventBus.$on('AddnewExpenseAccountComplete', (data) => AddnewExpenseAccountComplete(data));
    async function AddnewExpenseAccountComplete(data) {
        await expenseAccountListListAPICall({
            payload: [{ "clientId": `${business_id}`, }]
        })
        setexpenseAccountName(data?.account?.name);
        setincomeExpenseAccountId(data?.account?._id);
    }

    eventBus.$on('AddnewassetAccountComplete', (data) => AddnewassetAccountComplete(data));
    async function AddnewassetAccountComplete(data) {
        await assetAccountListListAPICall({
            payload: [{ "clientId": `${business_id}`, }]
        })
        setassetAccountName(data?.account?.name);
        setincomeInventoryAssetId(data?.account?._id);
    }



    useEffect(async () => {
        // await CategoryListAPICall({
        //     payload: [{ "businessId": `${businessId}` }]
        // });
        await incomeAccountListListAPICall({
            payload: [{ "clientId": `${business_id}`, }]
        })
        await expenseAccountListListAPICall({
            payload: [{ "clientId": `${business_id}`, }]
        })
        await assetAccountListListAPICall({
            payload: [{ "clientId": `${business_id}`, }]
        })
    }, []);

    useEffect(() => {
        if (isCategoryLoading) {
            return () => null;
        }
        if (isCategoryError) {
            return () => null;
        }
        if (CategoryResponse && CategoryResponse.data.isSuccess === true) {
            const data = CategoryResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    categoryId: prop._id
                };
            }
            );
            setCategoryList(data);
        }
    }, [CategoryResponse]);

    useEffect(() => {
        if (isAddNewProductLoading) {
            return () => null;
        }
        if (isAddNewProductError) {
            setErrorMessage(AddNewProductResponse?.data?.message);
            setLoading(false);
            setBtnDisable(false);
            setShowErrorSnackbar(true);
            setTimeout(() => {
                setShowErrorSnackbar(false);
            }, 3000);
            return () => null;
        }
        if (AddNewProductResponse && AddNewProductResponse.data.isSuccess === true) {
            setLoading(false);
            setBtnDisable(false);
            eventBus.$emit("AddnewProductComplete", AddNewProductResponse?.data?.data);
            onClose();
        }
    }, [AddNewProductResponse]);

    const handleSubmits = async (values) => {
        if (incomeAccountId) {
            setincomeAccounterrormsg('');
            setincomeAccounterror(false);
        } else {
            setincomeAccounterrormsg('Income Account name required');
            setincomeAccounterror(true);
        }
        if (incomeExpenseAccountId) {
            setexpenseAccounterrormsg('');
            setexpenseAccounterror(false);
        } else {
            setexpenseAccounterrormsg('Expense name required');
            setexpenseAccounterror(true);
        }
        if (incomeInventoryAssetId) {
            setinventoryerrormsg('');
            setinventoryerror(false);
        } else {
            setinventoryerrormsg('Inventory Asset name required');
            setinventoryerror(true);
        }
    }

    const handleSubmit = async (values) => {

        if (incomeAccountId && incomeExpenseAccountId) {
            setBtnDisable(true);
            addProductSelectType === 'Inventory' ?
                await AddNewProductListAPICall({
                    payload: [
                        {
                            "clientId": `${business_id}`,
                            "name": values?.product,
                            "unitPrice": Number(values?.unitprice),
                            "productType": "Inventory",
                            "trackQtyOnHand": true,
                            "qtyOnHand": Number(values?.qtyOnHand),
                            "incomeAccountId": incomeAccountId,
                            "assetAccountId": incomeInventoryAssetId,
                            "expenseAccountId": incomeExpenseAccountId,
                            "inventoryStartDate": values?.inventoryStartDate,
                        }
                    ]
                })
                :
                addProductSelectType === 'Service' ?
                    await AddNewProductListAPICall({
                        payload: [
                            {
                                "clientId": `${business_id}`,
                                "name": values?.product,
                                "unitPrice": Number(values?.unitprice),
                                "productType": 'Service',
                                "incomeAccountId": incomeAccountId,
                                "expenseAccountId": incomeExpenseAccountId,
                                "trackQtyOnHand": false,
                                "qtyOnHand": Number(values?.qtyOnHand),
                            }
                        ]
                    })
                    :
                    await AddNewProductListAPICall({
                        payload: [
                            {
                                "clientId": `${business_id}`,
                                "name": values?.product,
                                "unitPrice": Number(values?.unitprice),
                                "productType": "NonInventory",
                                "incomeAccountId": incomeAccountId,
                                "expenseAccountId": incomeExpenseAccountId,
                                "trackQtyOnHand": false,
                                "qtyOnHand": Number(values?.qtyOnHand),
                            }
                        ]
                    })
        }
    };


    const handleChangeIncomeAccount = (event, newValue) => {
        if (newValue) {
            setincomeAccounterrormsg('');
            setincomeAccounterror(false);
        } else {
            setincomeAccounterrormsg('Income Account name required');
            setincomeAccounterror(true);
        }
        if (newValue == '+ Add Income Account') {
            setOpenAddIncomeAccount(true);
        } else {
            setincomeAccountName(newValue)
            const found = incomeAccountList.find(obj => {
                return obj.name === newValue;
            });
            if (found) {
                setincomeAccountId(found.incomeAccountListId);
            }
        }
    };

    const handleChangeExpenseAccount = (event, newValue) => {
        if (newValue) {
            setexpenseAccounterrormsg('');
            setexpenseAccounterror(false);
        } else {
            setexpenseAccounterrormsg('Expense name required');
            setexpenseAccounterror(true);
        }
        if (newValue == '+ Add Expense') {
            setOpenExpenseAccount(true);
        } else {
            setexpenseAccountName(newValue);
            const found = expenseAccountList.find(obj => {
                return obj.name === newValue;
            });
            if (found) {
                setincomeExpenseAccountId(found.expenseAccountListId);
            }
        }
    };

    const handleChangeInventoryAsset = (event, newValue) => {
        if (newValue) {
            setinventoryerrormsg('');
            setinventoryerror(false);
        } else {
            setinventoryerrormsg('Inventory Asset name required');
            setinventoryerror(true);
        }
        if (newValue == '+ Add Asset') {
            setOpenAddInventoryAsset(true);
        } else {
            setassetAccountName(newValue);
            const found = assetAccountList.find(obj => {
                return obj.name === newValue;
            });
            if (found) {
                setincomeInventoryAssetId(found.assetAccountListId);
            }
        }
    };

    return (
        <>{
            <>
                <MDBox mt={0} >
                    <Grid spacing={3}>
                        <Grid item xs={12}>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={addProductSelectType === 'Inventory' ? AddNewProduct : AddNewProduct1}
                                onSubmit={handleSubmit}
                            >
                                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                                    <Form autoComplete="off">
                                        <MDBox pl={3} pr={3} >
                                            <MDBox>
                                                <MDBox mt={1.625}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Product *"
                                                                name="product"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Quantity On Hand *"
                                                                name="qtyOnHand"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    {addProductSelectType !== 'Inventory' &&
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} sm={6}>
                                                                <FormField
                                                                    type="text"
                                                                    label="Unit Price *"
                                                                    name="unitprice"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <Autocomplete
                                                                    sx={{
                                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                                            WebkitTextFillColor: "black",
                                                                        },
                                                                    }}
                                                                    classes={{
                                                                        option: styles.option
                                                                    }}
                                                                    value={{ name: incomeAccountName }}
                                                                    options={NewIncomeAccountList}
                                                                    getOptionLabel={(option) => option.name}
                                                                    onInputChange={(event, newInputValue) => {
                                                                        handleChangeIncomeAccount(event, newInputValue);
                                                                    }}
                                                                    id="controllable-states-demo"
                                                                    disableCloseOnSelect={false}
                                                                    renderInput={(params) => (
                                                                        <TextField {...params}
                                                                            label="Income account *"
                                                                            variant="standard"
                                                                            helperText={incomeAccounterrormsg}
                                                                            error={incomeAccounterror}
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    {addProductSelectType === 'Inventory' ?
                                                        <>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormField
                                                                        type="text"
                                                                        label="Unit Price *"
                                                                        name="unitprice"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <Autocomplete
                                                                        sx={{
                                                                            "& .MuiInputBase-input.Mui-disabled": {
                                                                                WebkitTextFillColor: "black",
                                                                            },
                                                                        }}
                                                                        classes={{
                                                                            option: styles.option
                                                                        }}
                                                                        value={{ name: assetAccountName }}
                                                                        options={NewAssetAccountList}
                                                                        getOptionLabel={(option) => option.name}
                                                                        onInputChange={(event, newInputValue) => {
                                                                            handleChangeInventoryAsset(event, newInputValue);
                                                                        }}
                                                                        id="controllable-states-demo"
                                                                        disableCloseOnSelect={false}
                                                                        renderInput={(params) => (
                                                                            <TextField {...params}
                                                                                label="Inventory asset account *"
                                                                                variant="standard"
                                                                                helperText={inventoryerrormsg}
                                                                                error={inventoryerror}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} sm={6}>
                                                                    <Autocomplete
                                                                        sx={{
                                                                            "& .MuiInputBase-input.Mui-disabled": {
                                                                                WebkitTextFillColor: "black",
                                                                            },
                                                                        }}
                                                                        classes={{
                                                                            option: styles.option
                                                                        }}
                                                                        value={{ name: incomeAccountName }}
                                                                        options={NewIncomeAccountList}
                                                                        getOptionLabel={(option) => option.name}
                                                                        onInputChange={(event, newInputValue) => {
                                                                            handleChangeIncomeAccount(event, newInputValue);
                                                                        }}
                                                                        id="controllable-states-demo"
                                                                        disableCloseOnSelect={false}
                                                                        renderInput={(params) => (
                                                                            <TextField {...params}
                                                                                label="Income account *"
                                                                                variant="standard"
                                                                                helperText={incomeAccounterrormsg}
                                                                                error={incomeAccounterror}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <Autocomplete
                                                                        sx={{
                                                                            "& .MuiInputBase-input.Mui-disabled": {
                                                                                WebkitTextFillColor: "black",
                                                                            },
                                                                        }}
                                                                        classes={{
                                                                            option: styles.option
                                                                        }}
                                                                        value={{ name: expenseAccountName }}
                                                                        options={NewExpenseAccountList}
                                                                        getOptionLabel={(option) => option.name}
                                                                        onInputChange={(event, newInputValue) => {
                                                                            handleChangeExpenseAccount(event, newInputValue);
                                                                        }}
                                                                        id="controllable-states-demo"
                                                                        disableCloseOnSelect={false}
                                                                        renderInput={(params) => (
                                                                            <TextField {...params}
                                                                                label="Expense account *"
                                                                                variant="standard"
                                                                                helperText={expenseAccounterrormsg}
                                                                                error={expenseAccounterror}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} sm={6} mt={3}>
                                                                    <FormFieldDate
                                                                        classes={{ root: styles.InputRoot }}
                                                                        type="date"
                                                                        label="Inventory Start Date *"
                                                                        name="inventoryStartDate"
                                                                    // dateMismatch={invoiceDateMismatch}
                                                                    // error={invoiceDateMismatch}
                                                                    // autoFocus={invoiceDateMismatch}
                                                                    // disabled={!editable}
                                                                    // editable={!editable}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </> :
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} sm={6}>
                                                                <Autocomplete
                                                                    sx={{
                                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                                            WebkitTextFillColor: "black",
                                                                        },
                                                                    }}
                                                                    classes={{
                                                                        option: styles.option
                                                                    }}
                                                                    value={{ name: expenseAccountName }}
                                                                    options={NewExpenseAccountList}
                                                                    getOptionLabel={(option) => option.name}
                                                                    onInputChange={(event, newInputValue) => {
                                                                        handleChangeExpenseAccount(event, newInputValue);
                                                                    }}
                                                                    id="controllable-states-demo"
                                                                    disableCloseOnSelect={false}
                                                                    renderInput={(params) => (
                                                                        <TextField {...params}
                                                                            label="Expense account *"
                                                                            variant="standard"
                                                                            helperText={expenseAccounterrormsg}
                                                                            error={expenseAccounterror}
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    }

                                                </MDBox>
                                                <MDBox mt={5} mb={-0.2} width="100%" display="flex" justifyContent="end">
                                                    <MDButton
                                                        disabled={btnDisable}
                                                        variant="gradient"
                                                        color="light"
                                                        onClick={onClose}
                                                    >
                                                        Cancel
                                                    </MDButton>
                                                    <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                        <MDButton
                                                            disabled={btnDisable}
                                                            type="submit"
                                                            variant="gradient"
                                                            onClick={handleSubmits}
                                                            color="info"
                                                        >
                                                            {btnDisable ? "Submitting..." : "Submit"}
                                                        </MDButton>
                                                    </MDBox>
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                    <MDBox>
                        <Dialog
                            open={openAddNewCategory}
                            onClose={() => setOpenAddNewCategory(false)}
                            aria-labelledby="alert-dialog-title"
                            sx={{
                                "& .MuiDialog-container": {
                                    "& .MuiPaper-root": {
                                        width: "100%",
                                        maxWidth: "570px",
                                    },
                                },
                            }}
                        >   <DialogTitle sx={{
                            justifyContent: "center",
                            display: 'flex'
                        }}>Add Category</DialogTitle>
                            <DialogContent>
                                <AddNewCategoryDialog invoiceId={invoiceId} businessId={businessId} onClose={() => setOpenAddNewCategory(false)}
                                />
                            </DialogContent>
                        </Dialog>
                    </MDBox>
                    <MDBox>
                        <Dialog
                            open={openAddIncomeAccount}
                            onClose={() => setOpenAddIncomeAccount(false)}
                            aria-labelledby="alert-dialog-title"
                            sx={{
                                "& .MuiDialog-container": {
                                    "& .MuiPaper-root": {
                                        width: "100%",
                                        maxWidth: "570px",
                                    },
                                },
                            }}
                        >   <DialogTitle sx={{
                            justifyContent: "center",
                            display: 'flex'
                        }}>Add Income Account</DialogTitle>
                            <DialogContent>
                                <AddNewIncomeAccount invoiceId={invoiceId} businessId={businessId} onClose={() => setOpenAddIncomeAccount(false)}
                                />
                            </DialogContent>
                        </Dialog>
                    </MDBox>
                    <MDBox>
                        <Dialog
                            open={openExpenseAccount}
                            onClose={() => setOpenExpenseAccount(false)}
                            aria-labelledby="alert-dialog-title"
                            sx={{
                                "& .MuiDialog-container": {
                                    "& .MuiPaper-root": {
                                        width: "100%",
                                        maxWidth: "570px",
                                    },
                                },
                            }}
                        >   <DialogTitle sx={{
                            justifyContent: "center",
                            display: 'flex'
                        }}>Add Expense</DialogTitle>
                            <DialogContent>
                                <AddNewExpense invoiceId={invoiceId} businessId={businessId} onClose={() => setOpenExpenseAccount(false)}
                                />
                            </DialogContent>
                        </Dialog>
                    </MDBox>
                    <MDBox>
                        <Dialog
                            open={openAddInventoryAsset}
                            onClose={() => setOpenAddInventoryAsset(false)}
                            aria-labelledby="alert-dialog-title"
                            sx={{
                                "& .MuiDialog-container": {
                                    "& .MuiPaper-root": {
                                        width: "100%",
                                        maxWidth: "570px",
                                    },
                                },
                            }}
                        >   <DialogTitle sx={{
                            justifyContent: "center",
                            display: 'flex'
                        }}>Add Asset</DialogTitle>
                            <DialogContent>
                                <AddNewAsset invoiceId={invoiceId} businessId={businessId} onClose={() => setOpenAddInventoryAsset(false)}
                                />
                            </DialogContent>
                        </Dialog>
                    </MDBox>
                </MDBox>
                <MDBox>
                    <MDSnackbar
                        color="error"
                        icon="warning"
                        title="Failed."
                        content={errorMessage}
                        // autoHideDuration={3000}
                        open={showErrorSnackbar}
                        close={() => setShowErrorSnackbar(false)}
                    />
                </MDBox>
            </>
        }
            <MDBox>
                {loading && (
                    <CircularProgress
                        size={50}
                        sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </MDBox>
        </>
    );
}

export default AddNewProductDialog;
