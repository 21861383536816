import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { SITE_URL } from "helpers/config";
import { green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import BasicLayout from "layouts/auth/components/BasicLayout";
import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { ResetPassSchema } from "layouts/auth/components/schemas/validations";
import FormField from "layouts/auth/components/FormField";
import initialValues from "layouts/auth/components/schemas/initialValues";
import bgImage from "assets/images/backgroundimage.jpg";
import { Link, useNavigate, redirect } from "react-router-dom";
import MDSnackbar from "components/MDSnackbar";
import { AccountantResetPassword } from "../../../services/authservices";
import { useAPICall } from "../../../utils/useapicall";
import ReactRecaptcha3 from 'react-google-recaptcha3';

function ResetPassword() {
  const navigate = useNavigate();
  const [isDisable, setIsDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showErrorSnackbar, setErrorShowSnackbar] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("Failed");
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(AccountantResetPassword, { onLoad: false });
  const [googleToken, setGoogleToken] = useState('');


  useEffect(async () => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      if (response?.data?.message) {
        setErrorMessage(response?.data?.message)
      }
      setErrorShowSnackbar(true);
      setIsDisable(false);
      setLoading(false);
      setTimeout(() => {
        setErrorShowSnackbar(false);
      }, 5000);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      // const token = new URLSearchParams(response.data.data.link).get("token");
      // const Key = new URLSearchParams(response.data.data.link).get(`${SITE_URL}/user/Create-password?key`);
      setLoading(false);
      setShowSnackbar(true);
      setTimeout(() => {
        navigate('/sign-in');
      }, 2000);
      // navigate(`/user/Create-password?key=${Key}&token=${token}`);
    }
  }, [response]);

  const onSubmit = async (values) => {
    setIsDisable(true);
    setLoading(true);
    ReactRecaptcha3.getToken().then(
      (resp) => {
        setAPICall({
          payload: [
            {
              "email": `${values.email}`,
              "returnLink": `${SITE_URL}/create-password`,
              "siteToken": `${resp}`,
            }
          ]
        })
        setGoogleToken(resp);
      },
      (error) => {
        console.log(error);
        setIsDisable(false);
        setLoading(false);
        setErrorMessage("Google reCAPTCHA verification failed.");
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 3000);
      }
    );
    // await setAPICall({
    //   payload: [
    //     {
    //       "email": `${values.email}`,
    //       "returnLink": `${SITE_URL}/create-password`,
    //     }
    //   ]
    // })
  };


  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          sx={{
            backgroundColor: "#80D6C3",
          }}
          textAlign="center"
        >
          <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
            Forgot Your Password?
          </MDTypography>
        </MDBox>
        <MDTypography fontWeight="200" color="black" fontSize="small" textAlign="center"
          padding="10px"  >
          Enter Your email address below to reset your password. Check your inbox for a reset link.
        </MDTypography>
        <Formik
          initialValues={initialValues}
          validationSchema={ResetPassSchema}
          onSubmit={onSubmit}
        >
          <Form autoComplete="off">
            <div>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox mb={4}>
                  <FormField
                    type="email"
                    label="Email"
                    name="email"
                  />
                </MDBox>
                <MDBox mt={6} mb={1}>
                  <MDButton
                    disabled={isDisable}
                    type="submit"
                    variant="gradient"
                    color="info"
                    fullWidth
                  >
                    reset
                  </MDButton>
                </MDBox>
                <MDBox textAlign="center">
                  <MDTypography
                    disabled={isDisable}
                    component={Link}
                    to="/sign-in"
                    variant="button"
                    fontWeight="bold"
                    color="info"
                    textGradient
                  >
                    Back
                  </MDTypography>
                </MDBox>
              </MDBox>
            </div>
          </Form>
        </Formik>
        <MDBox>
          {loading && (
            <CircularProgress
              size={50}
              sx={{
                color: green[500],
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </MDBox>
      </Card>
      <MDBox>
        <MDSnackbar
          color="success"
          icon="done"
          title="Reset password success :!"
          content="The link has been sent, Please check your email to reset your password."
          open={showSnackbar}
          // autoHideDuration={3000}
          close={() => setShowSnackbar(false)}
        />
        <MDSnackbar
          color="error"
          icon="warning"
          title="Failed!"
          // autoHideDuration={3000}
          content={ErrorMessage}
          open={showErrorSnackbar}
          close={() => setErrorShowSnackbar(false)}
        />
      </MDBox>
    </BasicLayout>
  );
}

export default ResetPassword;
