import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { eventBus } from 'utils/eventbus';
import AddNewCustomer from 'layouts/accountants/user/invoice/components/schemas/AddNewCustomer';
import FormField from "layouts/admin/accountants/components/FormField";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useAPICall } from "utils/useapicall";
import { GetCustomerDetails, EditCustomers } from "services/userservices";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import { TextField } from "@mui/material";
import NumberFormat from "react-number-format";
import Checkbox from '@mui/material/Checkbox';

function EditCustomer({ onClose, customerId, businessId }) {
    const [btnDisable, setBtnDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [address, setAddress] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [phone, setPhone] = useState('');
    const [emailId, setEmailId] = useState('');
    const [netTerms, setNetTerms] = useState('');
    const [city, setCity] = useState('');
    const [state, setstate] = useState('');
    const [zip, setzip] = useState('');
    const [shippingAddress, setshippingAddress] = useState('');
    const [shippingcity, setshippingcity] = useState('');
    const [shippingstate, setshippingstate] = useState('');
    const [shippingzip, setshippingzip] = useState('');
    const [trackPayment1, setTrackPayment1] = useState(true);
    const [trackPayment, setTrackPayment] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [{ isLoading: isEditCustomerLoading, isError: isEditCustomerError, response: EditCustomerResponse }, EditCustomerListAPICall] = useAPICall(EditCustomers, { onLoad: false });
    const [{ isLoading: isCustomerLoading, isError: isCustomerError, response: CustomerResponse }, CustomerDetailsAPICall] = useAPICall(GetCustomerDetails, { onLoad: false });


    useEffect(() => {
        if (address || city || state || zip) {
            const BillingAdd = address + city + state + zip
            const shippingAdd = shippingAddress + shippingcity + shippingstate + shippingzip
            let result = BillingAdd.localeCompare(shippingAdd);
            if (shippingAdd) {
                if (result === -1 || result === 1 || result !== 0) {
                    setTrackPayment1(false)
                }
            }
        }


    }, [shippingAddress, shippingcity, shippingstate, shippingzip, address, city, state, zip])

    useEffect(async () => {
        setLoading(true);
        await CustomerDetailsAPICall({
            payload: [{ "customerId": `${customerId}` }]
        });
    }, []);

    useEffect(() => {
        if (isCustomerLoading) {
            return () => null;
        }
        if (isCustomerError) {
            setLoading(false);
            return () => null;
        }
        if (CustomerResponse && CustomerResponse.data.isSuccess === true) {
            setCustomerName(CustomerResponse?.data?.data?.customer?.name !== '---' ? CustomerResponse?.data?.data?.customer?.name : '');
            setAddress(CustomerResponse?.data?.data?.customer?.billingAddress?.line1 !== '---' ? CustomerResponse?.data?.data?.customer?.billingAddress?.line1 : '');
            setshippingAddress(CustomerResponse?.data?.data?.customer?.shippingAddress?.line1 !== '---' ? CustomerResponse?.data?.data?.customer?.shippingAddress?.line1 : '');
            setshippingcity(CustomerResponse?.data?.data?.customer?.shippingAddress?.city !== '---' ? CustomerResponse?.data?.data?.customer?.shippingAddress?.city : '');
            setshippingstate(CustomerResponse?.data?.data?.customer?.shippingAddress?.state !== '---' ? CustomerResponse?.data?.data?.customer?.shippingAddress?.state : '');
            setshippingzip(CustomerResponse?.data?.data?.customer?.shippingAddress?.zip !== '---' ? CustomerResponse?.data?.data?.customer?.shippingAddress?.zip : '')
            setEmailId(CustomerResponse?.data?.data?.customer?.email !== '---' ? CustomerResponse?.data?.data?.customer?.email : '');
            setPhone(CustomerResponse?.data?.data?.customer?.phone !== '---' ? CustomerResponse?.data?.data?.customer?.phone : '');
            setCity(CustomerResponse?.data?.data?.customer?.billingAddress?.city !== '---' ? CustomerResponse?.data?.data?.customer?.billingAddress?.city : '');
            setstate(CustomerResponse?.data?.data?.customer?.billingAddress?.state !== '---' ? CustomerResponse?.data?.data?.customer?.billingAddress?.state : '');
            setzip(CustomerResponse?.data?.data?.customer?.billingAddress?.zip !== '---' ? CustomerResponse?.data?.data?.customer?.billingAddress?.zip : '')
            setNetTerms(CustomerResponse?.data?.data?.customer?.balance);
            setShowDetails(true);
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    }, [CustomerResponse]);

    useEffect(() => {
        if (isEditCustomerLoading) {
            return () => null;
        }
        if (isEditCustomerError) {
            setLoading(false);
            setBtnDisable(false);
            return () => null;
        }
        if (EditCustomerResponse && EditCustomerResponse.data.isSuccess === true) {
            setLoading(false);
            setBtnDisable(false);
            onClose();
            eventBus.$emit("reloadCustomerDetailsList", EditCustomerResponse);
        }
    }, [EditCustomerResponse]);

    let initialValues = {
        customer: customerName,
        address: address,
        email: emailId,
        phone: phone,
        net: netTerms,
        city: city,
        state: state,
        zip: zip,
        shippingzip: shippingzip,
        shippingstate: shippingstate,
        shippingcity: shippingcity,
        shippingAddress: shippingAddress
    };

    const handleSubmit = async (values) => {
        setBtnDisable(true);
        !trackPayment1 ?
            await EditCustomerListAPICall({
                payload: [
                    {
                        "name": values?.customer,
                        "phone": values?.phone,
                        "email": values?.email,
                        "billingAddress": {
                            "line1": values?.address,
                            "city": values?.city,
                            "state": values?.state,
                            "zip": values?.zip,
                        },
                        "shippingAddress": {
                            "line1": values?.shippingAddress,
                            "city": values?.shippingcity,
                            "state": values?.shippingstate,
                            "zip": values?.shippingzip,
                        },
                        "customerId": `${customerId}`,
                    }]
            })
            :
            await EditCustomerListAPICall({
                payload: [
                    {
                        "name": values?.customer,
                        "phone": values?.phone,
                        "email": values?.email,
                        "billingAddress": {
                            "line1": values?.address,
                            "city": values?.city,
                            "state": values?.state,
                            "zip": values?.zip,
                        },
                        "shippingAddress": {
                            "line1": values?.address,
                            "city": values?.city,
                            "state": values?.state,
                            "zip": values?.zip,
                        },
                        "customerId": `${customerId}`,
                    }]
            })
    };

    const handleChange = () => {
        setTrackPayment(!trackPayment)
    }

    useEffect(() => {
        if (address) {
            setTrackPayment(true)
        }
        // if (shippingAddress) {
        //     setTrackPayment1(false)
        // }
    }, [address, shippingAddress])

    const handleChangeship = () => {
        setTrackPayment1(!trackPayment1)
    }


    return (
        <>{showDetails &&
            <>
                < MDBox mt={0} >
                    <Grid spacing={3}>
                        <Grid item xs={12}>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={AddNewCustomer}
                                onSubmit={handleSubmit}
                            >
                                {({ values, errors, touched, isSubmitting }) => (
                                    <Form autoComplete="off">
                                        <MDBox p={1}>
                                            <MDBox>
                                                <MDBox mt={1.625}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Customer *"
                                                                name="customer"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <NumberFormat
                                                                customInput={TextField}
                                                                variant="standard"
                                                                type="tel"
                                                                fullWidth
                                                                value={phone}
                                                                label="Phone Number"
                                                                sx={{
                                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                                        WebkitTextFillColor: "black",
                                                                    },
                                                                    mb: 1, fontSize: `0.823rem !important`
                                                                }}
                                                                format="(###) ###-####"
                                                                mask=""
                                                                onValueChange={value => setPhone(value.formattedValue)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="email"
                                                                label="Email"
                                                                name="email"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>

                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={12}>
                                                            <MDBox>
                                                                <Field
                                                                    as={FormControlLabel}
                                                                    type="checkbox"
                                                                    name="checkbox"
                                                                    control={<Checkbox />}
                                                                    label="Address"
                                                                    checked={trackPayment}
                                                                    onChange={handleChange}
                                                                    sx={{ mt: 1.5, mb: 2 }}
                                                                />
                                                            </MDBox>
                                                        </Grid>
                                                    </Grid>
                                                    {trackPayment &&
                                                        <>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormField
                                                                        type="text"
                                                                        label="Address"
                                                                        name="address"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormField
                                                                        type="text"
                                                                        label="City"
                                                                        name="city"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormField
                                                                        type="text"
                                                                        label="State"
                                                                        name="state"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormField
                                                                        type="text"
                                                                        label="Zip"
                                                                        name="zip"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} sm={12} mb={2}>
                                                                    <MDBox>
                                                                        <Field
                                                                            as={FormControlLabel}
                                                                            type="checkbox"
                                                                            name="checkbox"
                                                                            control={<Checkbox />}
                                                                            label="Shipping Address same as Billing Address"
                                                                            checked={trackPayment1}
                                                                            onChange={handleChangeship}
                                                                            sx={{ mt: 1.5 }}
                                                                        />
                                                                    </MDBox>
                                                                </Grid>
                                                            </Grid>
                                                        </>}
                                                    {!trackPayment1 &&
                                                        <>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormField
                                                                        type="text"
                                                                        label="Shipping Address"
                                                                        name="shippingAddress"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormField
                                                                        type="text"
                                                                        label="City"
                                                                        name="shippingcity"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormField
                                                                        type="text"
                                                                        label="State"
                                                                        name="shippingstate"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormField
                                                                        type="text"
                                                                        label="Zip"
                                                                        name="shippingzip"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </>}
                                                </MDBox>
                                                <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                                                    <MDButton
                                                        disabled={btnDisable}
                                                        variant="gradient"
                                                        color="light"
                                                        onClick={onClose}
                                                    >
                                                        Cancel
                                                    </MDButton>
                                                    <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                        <MDButton
                                                            disabled={btnDisable}
                                                            type="submit"
                                                            variant="gradient"
                                                            color="info"
                                                        >
                                                            {btnDisable ? "updating..." : "update"}
                                                        </MDButton>
                                                    </MDBox>
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>

                                    </Form>
                                )}
                            </Formik>

                        </Grid>

                    </Grid>
                </MDBox>

            </>
        }
            <MDBox>
                {loading && (
                    <CircularProgress
                        size={50}
                        sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </MDBox>
        </>
    );
}

export default EditCustomer;
