import * as Yup from "yup";

export const EditItemSchema = Yup.object().shape({
    item: Yup.string()
        .trim()
        .required('Item  name is required.')
        .min(3, 'Item name should be more than 3 characters.'),
    // sku: Yup.string()
    //     .trim()
    //     .nullable()
    //     .required('SKU is required.'),
    // services: Yup.string().trim().required("Services is required"),
    // class: Yup.string().trim().required('Class is required.'),
    // customer: Yup.string().trim().required("Customer is required"),
    unit: Yup.string().trim().nullable().required("Unit is required"),
    price: Yup
        .string()
        .matches(
            /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/,
            "Must be a number type"
        )
        // .number("Must be a number type")
        .test(
            "maxDigitsAfterDecimal",
            "number field must have 2 digits after decimal or less",
            (number) => /^\d+(\.\d{1,2})?$/.test(number)
        )
        .nullable()
        .required("Price is required"),
    qty: Yup.string("Quantity must be a number type")
        .matches(/^(0*[1-9][0-9]*(\[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/, "Quantity must be a number type")
        .nullable()
        .required("Quantity is required"),
    category:Yup.string().trim().nullable().required("Category is required")
    // total: Yup.string("Total is required")
    //     .matches(/^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/, "Total must be a number type")
    //     .nullable()
    //     .required("Total is required"),
});
