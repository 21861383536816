import * as Yup from "yup";

export const EditVendorSchema = Yup.object().shape({
    vendor: Yup.string()
        .trim()
        .required('Vendor name is required.')
        .min(3, 'Vendor name should be more than 3 characters.'),
    // phone: Yup.string()
    //     .nullable()
    //     .min(10, "Mobile number is invalid")
    //     // .max(10, "Mobile Number is invalid")
    //     .required("Mobile number is requried")
    //     .matches(
    //         /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
    //         "Mobile number is not valid"
    //     ),
    // accountno: Yup.string().matches(/^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/, "Account no must be a number type")
    //     .nullable()
    //     .required("Account number is required."),
    // address: Yup.string().trim().required("Address is required"),
    email: Yup.string()
        .email('Your email address is invalid')
        .required('Email address is required.'),
    // businessid: Yup.string().trim().required("Bussiness id is required")
    // .matches(/^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/, "Business id must be a number type"),    
    //     phone: Yup.string()
    //     .nullable()
    //     .min(10, "Mobile Number is invalid")
    //     .max(10, "Mobile Number is invalid")
    //     .required("Mobile Number is requried")
    //     .matches(
    //         /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //         "Mobile Number is not valid"
    //     ),
    // net: Yup.string()        
    //     .required("Net terms is required"),

});