import * as Yup from "yup";

export const addNewInventory = Yup.object().shape({
    itemName: Yup.string()
        .trim()
        .required('Item  name is required.')
        .min(3, 'Item name should be more than 3 characters.'),
    location: Yup.string()
        .nullable()
        .required('Location is required'),
    vendors: Yup.string()
        .nullable()
        .required('Vendor is required'),
    inventoryUnit: Yup.string()
        .nullable()
        .required('Inventory Unit is required'),
    currentInventory: Yup.string("Current Inventory must be a number type")
        .matches(/^(0*[1-9][0-9]*(\[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/, "Current Inventory must be a number type")
        .nullable()
        .required("Current Inventory is required"),
    lowStockFlag: Yup.string("Low Stock Flag must be a number type")
        .matches(/^(0*[1-9][0-9]*(\[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/, "Low Stock Flag must be a number type")
        .nullable()
        .required("Low Stock Flag is required"),
    parflag: Yup.string("Par Flag be a number type")
        .matches(/^(0*[1-9][0-9]*(\[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/, "Par Flag must be a number type")
        .nullable()
        .required("Par Flag is required"),
});
