
const defaultLineChartData = {
  labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  datasets: [
    {
      label: "All Document",
      color: "info",
      data: [50, 100, 200, 190, 400, 350, 500, 450, 700],
    },
    {
      label: "Pending Approval",
      color: "dark",
      data: [10, 30, 40, 120, 150, 220, 280, 250, 280],
    },
    {
      label: "to review",
      color: "warning",
      data: [30, 60, 250, 220, 230, 270, 320, 300, 500],
    },
  ],
};

export default defaultLineChartData;
