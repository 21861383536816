import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PolicyBasicLayout from "layouts/auth/components/BasicLayout/policypage";
import React from 'react';


function TermsOfAgreement() {
    return (
        <PolicyBasicLayout bgColor={'#fffff'}>
            <MDBox mb={7}>
                <MDTypography sx={{ fontWeight: 300, display: "flex", justifyContent: 'center' }} variant='h1'>Terms of Agreement</MDTypography>
            </MDBox>
            <MDBox mb={7}>
                <MDTypography>These Terms of Agreement ("Agreement") govern your use of the application connected to QuickBooks (“Paynthr”) provided by Paynthr LLC ("we," "us," or "our"). Please read this Agreement carefully before using the Application. By accessing or using the Application, you agree to be bound by the terms and conditions set forth in this Agreement. If you do not agree to these terms, you may not use the Application.</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>1. License and Use of the Application:</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>1.1. Grant of License:</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography>
                    Subject to your compliance with this Agreement, we grant you a limited, non-exclusive, non-transferable, revocable license to use the Application solely for your internal business purposes. This license does not transfer any ownership rights to you.
                </MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>1.2. Restrictions:</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>You agree not to:</MDTypography>
            </MDBox>
            <MDBox mb={7}>
                <MDTypography sx={{ mb: 1 }}>(a) Copy, modify, or distribute the Application or any portion of it;</MDTypography>
                <MDTypography sx={{ mb: 1 }}>(b) Reverse engineer, decompile, or disassemble the Application;</MDTypography>
                <MDTypography sx={{ mb: 1 }}>(c) Remove or alter any proprietary notices or labels on the Application;</MDTypography>
                <MDTypography sx={{ mb: 1 }}>(d) Use the Application in any unlawful or unauthorized manner;</MDTypography>
                <MDTypography sx={{ mb: 1 }}>(e) Use the Application to infringe upon any intellectual property rights or violate any applicable laws or regulations;</MDTypography>
                <MDTypography sx={{ mb: 1 }}>(f) Use the Application to transmit any viruses, malware, or other harmful code;</MDTypography>
                <MDTypography sx={{ mb: 1 }}>(g) Interfere with or disrupt the integrity or performance of the Application or its associated servers or networks.</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>2. Integration with QuickBooks:</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>2.1. QuickBooks Access:</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography>To use the Application, you must have a valid QuickBooks account. The Application integrates with your QuickBooks account to access and sync your QuickBooks data. By using the Application, you authorize us to access, retrieve, and manipulate your QuickBooks data as necessary for the proper functioning of the Application.</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>2.2. Data Accuracy:</MDTypography>
            </MDBox>
            <MDBox mb={7}>
                <MDTypography>While we strive to provide accurate and reliable information through the Application, we cannot guarantee the accuracy, completeness, or timeliness of the data retrieved from your QuickBooks account. You are solely responsible for verifying the accuracy and integrity of your QuickBooks data.</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>3. Intellectual Property:</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>3.1. Ownership:</MDTypography>
            </MDBox>
            <MDBox mb={7}>
                <MDTypography>The Application and all associated intellectual property rights, including but not limited to copyrights, trademarks, trade secrets, and patents, are owned by us or our licensors. This Agreement does not grant you any rights or licenses except for the limited use rights expressly stated herein.</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>3.2. User Content:</MDTypography>
            </MDBox>
            <MDBox mb={7}>
                <MDTypography>By using the Application, you may provide or upload content, such as feedback, suggestions, or data ("User Content"). You retain ownership of your User Content. However, by providing User Content, you grant us a worldwide, royalty-free, non-exclusive, perpetual, irrevocable, and sublicensable right to use, reproduce, modify, adapt, publish, translate, distribute, perform, and display such User Content for any purpose.</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>4. Privacy:</MDTypography>
            </MDBox>
            <MDBox mb={7}>
                <MDTypography>Your privacy is important to us. Our collection, use, and disclosure of your personal information are governed by our Privacy Policy. By using the Application, you consent to our collection and use of your personal information as described in the Privacy Policy.</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>5. Disclaimer of Warranties:</MDTypography>
            </MDBox>
            <MDBox mb={7}>
                <MDTypography>THE APPLICATION IS PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. WE DISCLAIM ALL WARRANTIES, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AND ACCURACY OF DATA. WE DO NOT WARRANT THAT THE APPLICATION WILL BE ERROR-FREE, UNINTERRUPTED, SECURE, OR FREE FROM VIRUSES OR OTHER HARMFUL COMPONENTS.</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>6. Limitation of Liability:</MDTypography>
            </MDBox>
            <MDBox mb={7}>
                <MDTypography>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE APPLICATION, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR TOTAL LIABILITY TO YOU FOR ANY CLAIMS ARISING OUT OF THIS AGREEMENT SHALL NOT EXCEED THE AMOUNTS PAID BY YOU, IF ANY, FOR USING THE APPLICATION.</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>7. Modification and Termination:</MDTypography>
            </MDBox>
            <MDBox mb={7}>
                <MDTypography>We reserve the right to modify, suspend, or terminate the Application or any part thereof, at any time and for any reason without prior notice. We may also modify this Agreement at any time by posting the revised terms on our website or within the Application. Your continued use of the Application after any modifications to this Agreement will constitute your acceptance of the revised terms.</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>8. Governing Law and Jurisdiction:</MDTypography>
            </MDBox>
            <MDBox mb={7}>
                <MDTypography>This Agreement shall be governed by and construed in accordance with the laws of South Carolina. Any disputes arising under or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts located in South Carolina.</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>9. Entire Agreement:
                </MDTypography>
            </MDBox>
            <MDBox mb={7}>
                <MDTypography>This Agreement constitutes the entire agreement between you and us regarding the subject matter hereof and supersedes all prior or contemporaneous agreements, understandings, or representations, whether written or oral.</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>10. Contact Us:
                </MDTypography>
            </MDBox>
            <MDBox mb={7}>
                <MDTypography>If you have any questions or concerns regarding this Agreement or the Application, please contact us at Support@paynthr.com.</MDTypography>
            </MDBox>
            <MDBox mb={7}>
                <MDTypography>By using the Application, you acknowledge that you have read, understood, and agreed to be bound by this Agreement.</MDTypography>
            </MDBox>
        </PolicyBasicLayout >
    );
}

export default TermsOfAgreement;