import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import BaseLayout from "layouts/pages/profile/profile-overview/components/BaseLayout/adminBaseLayout";
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from "@mui/material/IconButton";
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from "formik";
import { EditProfileSchema } from "layouts/pages/profile/profile-overview/components/schemas/editprofile";
import { CreatePassSchema } from "layouts/pages/profile/profile-overview/components/schemas/editprofile";
import FormField from "layouts/auth/components/FormField";
import { useAPICall } from "utils/useapicall";
import { EditProfile } from "../../../../services/accountantservices";
import { green } from '@mui/material/colors';
import { GetProfile } from "../../../../services/accountantservices";
import Hoc from "hoc";
import { useMaterialUIController } from "context";
import NumberFormat from "react-number-format";
import LoaderProfile from "layouts/admin/accountants/components/imgLoader/LoaderProfile";
import { eventBus } from "utils/eventbus";

function Overview() {
  const navigate = useNavigate();
  const [profileEmail, setprofileEmail] = useState('');
  const [profileFname, setprofileFname] = useState('');
  const [profileLname, setprofileLname] = useState('');
  const [profileNumber, setprofileNumber] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [passBtnDisable, setPassBtnDisable] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [showPassSnackbar, setShowPassSnackbar] = useState(false);
  const [showPassErrorSnackbar, setShowPassErrorSnackbar] = useState(false);
  const [controller] = useMaterialUIController();
  const [showCPassword, setShowCPassword] = useState(false);
  const [showNPassword, setShowNPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [editable, SetEditable] = useState(false);
  const [phone, setPhone] = useState('');
  const [changePassword, SetChangePassword] = useState(false)
  const handleCerClickShowPassword = () => setShowCPassword(true);
  const handleCerClickClosePassword = () => setShowCPassword(false)
  const handleNewClickShowPassword = () => setShowNPassword(true);
  const handleNewClickClosePassword = () => setShowNPassword(false)
  const handleReClickShowPassword = () => setShowRePassword(true);
  const handleReClickClosePassword = () => setShowRePassword(false);

  useEffect(() => {
    eventBus.$emit("OnSetBlur", !showForm);
  }, [showForm]);

  const initialValues = {
    name: "",
    firstname: profileFname,
    lastname: profileLname,
    businessname: '',
    phone: profileNumber,
    company: "",
    email: profileEmail,
    password: "",
    repeatPassword: "",
    curpassword: "",
    address1: "",
    address2: "",
    city: "",
    zip: "",
    twitter: "",
    facebook: "",
    instagram: "",
    publicEmail: "",
    bio: "",
    role: "",
  };
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(EditProfile, { onLoad: false });
  const [{ isLoading: isAddLoading, isError: isAddError, response: profileResponse }, getProfileAPICall] = useAPICall(GetProfile, { onLoad: false });

  useEffect(async () => {
    await getProfileAPICall({ payload: [] });
  }, []);

  useEffect(() => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      response?.code === "DATA401" ? navigate('/admin/logout') : console.log("somting else");
      if (response.data.message == 'WRONG_PASSWORD') {
        setShowPassErrorSnackbar(true);
        setTimeout(() => {
          setShowPassErrorSnackbar(false);
        }, 3000);
      } else {
        setShowErrorSnackbar(true);
        setTimeout(() => {
          setShowErrorSnackbar(false);
        }, 3000);
      }
      setPassBtnDisable(false);
      setBtnDisable(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 3000);
      setBtnDisable(false);
      SetEditable(false)
      SetChangePassword(false)
      setPassBtnDisable(false);
    }
  }, [response]);

  useEffect(() => {

    if (isAddLoading) {
      return () => null;
    }

    if (isAddError) {
      if (response?.data?.message) {
        setErrorMessage(response.data.message)
      }
      return () => null;
    }

    if (profileResponse && profileResponse.data.isSuccess === true && profileResponse.data.data && profileResponse.data.data.user) {
      setprofileEmail(profileResponse.data.data.user.email);
      setprofileFname(profileResponse.data.data.user.fname);
      setprofileLname(profileResponse.data.data.user.lname);
      // setprofileBusinessName(profileResponse.data.data.user.domain.name);
      setprofileNumber(profileResponse.data.data.user.phone);
      setPhone(profileResponse.data.data.user.phone);
      setShowForm(true);
    }
  }, [profileResponse]);

  const handleSubmit = async (values) => {
    setBtnDisable(true);

    await setAPICall({
      payload: [
        {
          "fname": `${values.firstname}`,
          "lname": `${values.lastname}`,
          "phone": phone,
        }
      ]
    })
  };

  const handlePassSubmit = async (values) => {
    setPassBtnDisable(true);
    SetChangePassword(false)
    await setAPICall({
      payload: [
        {
          "password": `${values.repeatPassword}`,
          "oldPassword": `${values.curpassword}`
        }
      ]
    })
  };
  const handleClose = () => {
    window.location.reload();
  }

  return (
    <>
      {showForm ?
        <BaseLayout>
          < MDBox mt={4} >
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <MDBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Card id="basic-info" sx={{ overflow: "visible" }}>
                        <Formik
                          initialValues={initialValues}
                          validationSchema={EditProfileSchema}
                          onSubmit={handleSubmit}
                        >
                          {({ values, errors, touched, isSubmitting }) => (
                            <Form autoComplete="off">
                              <Card sx={{ height: "100%" }}>
                                <MDBox p={3}>
                                  <MDBox>
                                    <MDBox lineHeight={0}>
                                      <MDTypography variant="h5">Update Basic Info</MDTypography>
                                    </MDBox>
                                    <MDBox mt={1.625}>
                                      <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                          <FormField
                                            type="text"
                                            label="First Name *"
                                            name="firstname"
                                            disabled={!editable}
                                            variant="standard"
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <FormField
                                            type="text"
                                            label="Last Name *"
                                            name="lastname"
                                            disabled={!editable}
                                            variant="standard"
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <FormField
                                          type="email"
                                          label="Email"
                                          name="email"
                                          variant="standard"
                                          disabled
                                        // value="admin@gmail.com"
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <NumberFormat
                                          customInput={TextField}
                                          variant="standard"
                                          type="tel"
                                          fullWidth
                                          disabled={!editable}
                                          value={phone}
                                          label="Phone Numberr"
                                          sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                              WebkitTextFillColor: "black",
                                            },
                                            mb: 1, fontSize: `0.823rem !important`
                                          }}
                                          format="(###) ###-####"
                                          mask=""
                                          onValueChange={value => setPhone(value.formattedValue)}
                                        />
                                      </Grid>
                                    </MDBox>
                                    <Grid sx={{ display: "flex" }}>
                                      <Grid item xs={12} >
                                        <MDBox mt={{ xs: 2, sm: 3 }} justifyContent='left'>
                                          <MDTypography
                                            variant="caption"
                                            sx={{ cursor: 'pointer' }}
                                            color="info"
                                            onClick={() => SetChangePassword(true)}
                                          >
                                            Change Password
                                          </MDTypography>
                                        </MDBox>
                                      </Grid>
                                      <Grid >
                                        <MDBox mt={{ xs: 2, sm: 3 }}>
                                          {!editable && <MDButton

                                            type="submit"
                                            variant="gradient"
                                            color="info"
                                            onClick={() => SetEditable(true)}
                                          >
                                            Edit
                                          </MDButton>}
                                        </MDBox>
                                        {editable && <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                                          <MDButton
                                            disabled={btnDisable}
                                            variant="gradient"
                                            color="light"
                                            onClick={handleClose}

                                          >
                                            Cancel
                                          </MDButton>
                                          <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                            <MDButton
                                              disabled={btnDisable}
                                              type="submit"
                                              variant="gradient"
                                              color="info"
                                              autoFocus
                                            >
                                              {btnDisable ? "Updating..." : "Update"}
                                            </MDButton>
                                          </MDBox>
                                        </MDBox>
                                        }
                                      </Grid>
                                    </Grid>
                                  </MDBox>
                                </MDBox>
                              </Card>
                            </Form>
                          )}
                        </Formik>
                      </Card>
                    </Grid>
                  </Grid>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox >
          {/* Change password Dialog */}
          <MDBox>
            <MDBox>
              <Dialog
                open={changePassword}
                onClose={() => SetChangePassword(false)}

                aria-labelledby="alert-dialog-title"
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "570px",
                    },
                  },
                }}
              >
                <DialogTitle id="scroll-dialog-title"
                  sx={{
                    justifyContent: "center",
                    display: 'flex'
                  }}>Change Password</DialogTitle>
                <DialogContent>
                  <Grid item xs={12}>
                    {/* <Card id="change-password"> */}
                    <Formik
                      initialValues={initialValues}
                      validationSchema={CreatePassSchema}
                      onSubmit={handlePassSubmit}
                    >
                      {({ values, errors, touched, isSubmitting }) => (
                        <Form autoComplete="off">
                          {/* <Card sx={{ height: "100%" }}> */}
                          <MDBox p={3}>
                            <MDBox>
                              <MDBox mt={1.625}>
                                <Grid item xs={12}>
                                  <FormField
                                    type={showCPassword ? "text" : "password"}

                                    label="Current Password *"
                                    name="curpassword"
                                    variant="standard"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton sx={{ fontSize: '0.9rem' }} >
                                            {!showCPassword ? <VisibilityOff onClick={handleCerClickShowPassword} /> : <Visibility onClick={handleCerClickClosePassword} />}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormField
                                    type={showNPassword ? "text" : "password"}
                                    label="New Password *"
                                    name="password"
                                    variant="standard"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton sx={{ fontSize: '0.9rem' }} >
                                            {!showNPassword ? <VisibilityOff onClick={handleNewClickShowPassword} /> : <Visibility onClick={handleNewClickClosePassword} />}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormField
                                    type={showRePassword ? "text" : "password"}
                                    label="Confirm New Password *"
                                    name="repeatPassword"
                                    variant="standard"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton sx={{ fontSize: '0.9rem' }} >
                                            {!showRePassword ? <VisibilityOff onClick={handleReClickShowPassword} /> : <Visibility onClick={handleReClickClosePassword} />}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                              </MDBox>
                              <MDBox mt={5} display="flex" justifyContent="end">
                                <MDButton
                                  variant="gradient"
                                  color="light"
                                  onClick={() => SetChangePassword(false)}
                                >
                                  Cancel
                                </MDButton>
                                <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                  <MDButton
                                    disabled={passBtnDisable}
                                    type="submit"
                                    variant="gradient"
                                    color="info"
                                  >
                                    {passBtnDisable ? "updating..." : "update password"}
                                  </MDButton>
                                </MDBox>

                              </MDBox>
                            </MDBox>
                          </MDBox>
                          {/* </Card> */}
                        </Form>
                      )}
                    </Formik>
                    {/* </Card> */}
                  </Grid>
                </DialogContent>
              </Dialog>
            </MDBox>
          </MDBox>

          <MDBox>
            <MDSnackbar
              color="success"
              icon="done"
              title="Success!"
              // autoHideDuration={3000}
              content="Your profile has been successfully updated."
              open={showSnackbar}
              close={() => setShowSnackbar(false)}
            />
          </MDBox>
          <MDSnackbar
            color="error"
            icon="warning"
            title="Failed."
            // autoHideDuration={3000}
            content="Your profile updation Failed."
            open={showErrorSnackbar}
            close={() => setShowErrorSnackbar(false)}
          />
          <MDSnackbar
            color="success"
            icon="done"
            title="Success!"
            content="Your Password has been successfully updated."
            open={showPassSnackbar}
            // autoHideDuration={3000}
            close={() => setShowPassSnackbar(false)}
          />
          <MDSnackbar
            color="error"
            icon="warning"
            title="Failed."
            content="Old Password You Entered Was Incorrect."
            open={showPassErrorSnackbar}
            // autoHideDuration={3000}
            close={() => setShowPassErrorSnackbar(false)}
          />
        </BaseLayout >
        :
        <MDBox mt={4} display="flex" justifyContent="center" >
          <LoaderProfile />
        </MDBox>
      }
    </>
  );
}

export default Hoc(Overview);