import * as React from "react";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DialogTitle from '@mui/material/DialogTitle';
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import DataTable from "../../components/Tables/DataTable";
import { NavLink } from "react-router-dom";
import { useAPICall } from "../../../utils/useapicall";
import { GetuserList, EditUserInfo } from "../../../services/userservices";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import Icon from "@mui/material/Icon";
import MDSnackbar from "components/MDSnackbar";
import Tooltip from "@mui/material/Tooltip";
import UserHoc from 'hoc/userHoc';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import { canAccess } from "helpers";
import { eventBus } from "utils/eventbus";
import Loader from "./components/imgLoader/loader";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import CircleIcon from '@mui/icons-material/Circle';
import DataTable1 from "components/NewTable/table";

function User() {
    const navigate = useNavigate();
    const action = { route: "", edittooltip: "Edit", deletetooltip: "Delete" };
    const [alert, setAlert] = useState(false);
    const [alertClient, setAlertClient] = useState(false);
    const [adminList, setAdminList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loaderClass, setLoaderClass] = useState({});
    const [tableEmpty, setTableEmpty] = useState("");
    const [profileInfo, setProfileInfo] = useState(cookies.get('user_profile_info'));
    const [search, setSearch] = useState('');
    const [userId, setUserId] = useState();
    const [clientList, setClientList] = useState([]);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [loadingCircul, setLoadingCircul] = useState(false);
    const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetuserList, { onLoad: false });
    const [{ isLoading: isRemoveLoading, isError: isRemoveError, response: removeResponse }, removeUserAPICall] = useAPICall(EditUserInfo, { onLoad: false });
    var resource = '';
    var actions = '';
    const { pathname } = useLocation();

    eventBus.$on('onclickchangePages', (data) => OnclickchangePages(data));
    function OnclickchangePages(data) {
        setLoading(true);
        const dataStr = data.toString() + 0
        localStorage.setItem("offset", Number(dataStr))
        setAPICall({
            payload: [{ "keyword": `${search}`, 'offset': Number(dataStr) }]
        });
    }

    useEffect(async () => {
        const queryParameters = new URLSearchParams(window.location.search)
        const keyword = queryParameters.get("keyword");
        const ThisOffsetuse = localStorage.getItem("offset");
        if (keyword) {
            setSearch(keyword);
            setLoading(true);
        } else {
            setLoading(true);
            await setAPICall({
                payload: [{ "keyword": `${search}`, 'offset': ThisOffsetuse }]
            })
        }
    }, []);

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        const keyword = queryParameters.get("keyword");
        if (!keyword) {
            window.history.pushState('data', 'title', `${pathname}`);
        }
    }, [search]);

    useEffect(() => {
        eventBus.$emit("OnSetBlur", loading);
        loading === true ? setLoaderClass({ opacity: '0.4', borderRadius: '13px' }) : setLoaderClass({});
    }, [loading])

    const warningWithConfirmMessage = () => {
        setAlert(true);
    };
    const successDelete = async () => {
        await removeUserAPICall({
            payload: [
                {
                    "isArchived": true,
                    "bussinessId": `${userId}`
                }
            ]
        })
        setAlert(false);
    };
    const hideAlert = () => {
        setAlert(false);
    };


    useEffect(() => {
        const ThisOffsetuse = localStorage.getItem("offset");
        const timeOutId = setTimeout(async () =>
            await setAPICall({
                payload: [{ "keyword": `${search}`, 'offset': ThisOffsetuse }]
            }), 500);
        return () => clearTimeout(timeOutId);
    }, [search]);

    useEffect(() => {

        if (isRemoveLoading) {
            return () => null;
        }

        if (isRemoveError) {
            response?.code === "DATA401" ? navigate('/user/logout') : console.log("somting else");
            return () => null;
        }

        if (removeResponse && removeResponse.data.isSuccess === true) {
            const ThisOffsetuse = localStorage.getItem("offset");
            setShowSnackbar(true);
            setTimeout(() => {
                setShowSnackbar(false);
            }, 3000);
            setLoading(true);
            setAPICall({
                payload: [{ "keyword": `${search}`, 'offset': ThisOffsetuse }]
            })
            // navigate('/accountants/list');
        }
    }, [removeResponse]);

    const hadleOpenClientList = (ClientId) => {
        {
            const result = response.data.data.records.find(({ _id }) => _id == ClientId);
            const count = result.totalClients
            var ClientNameList = [];
            for (var j = 0; j < count; j++) {
                clientList.push(result.assignedClients[j].name);
                ClientNameList.push(result.assignedClients[j].name);
            }
            setClientList(ClientNameList)

        }
        setAlertClient(true);
    }

    const handleClose = () => {
        setAlertClient(false);
        setClientList([]);
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    useEffect(() => {
        if (isLoading) {
            return () => null;
        }
        if (isError) {
            setLoadingCircul(false);
            setLoading(false);
            response?.code === "DATA401" ? navigate('/user/logout') : console.log("somting else");
            return () => null;
        }
        if (response && response.data.isSuccess === true) {
            setTableEmpty(response.data.data.totalRecords);
            const data = response.data.data.records.map((prop, key) => {
                var clientNames = '';
                const Createdate = new Date(prop.createdAt);
                if (prop.allScopes === true) {
                    var clientNames = 'All Clients'
                } else if (prop.role) {
                    var ClientIds = prop.role.hasOwnProperty('clientIds') ? prop.role : ''
                    var ClientName = '';
                    if (ClientIds && ClientIds.clientIds && ClientIds.clientIds.length > 0) {
                        var totalClient = ClientIds.clientIds.length;
                        // totalClient = totalClient >= 2 ? 2 : totalClient;
                        for (var j = 0; j < totalClient; j++) {
                            let glue = (j) ? ', ' : '';
                            ClientName = ClientName + glue + (ClientIds.clientIds ? ClientIds.clientIds[j].name
                                : '');
                        }
                    }
                    var clientNames = ClientName ? ClientName : '-'
                }
                return {
                    snum: (key + 1),
                    firstName: prop.fname,
                    business: prop.businessName,
                    clientIds: (
                        // user.role.template === "executive" ? user.allClient ? "All client" : role.clientIds.length : "All client"
                        prop?.allClients == true ? "All client" :
                            <MDBox>
                                <MDTypography
                                    variant="caption" fontWeight="regular" fontSize="0.875rem"
                                    color="info"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={(value) => {
                                        // setAccountantId(prop._id);
                                        hadleOpenClientList(prop._id);
                                        // setAccountantId(prop._id);
                                    }}
                                >
                                    {prop?.totalClients}
                                </MDTypography>
                            </MDBox>
                    ),
                    email: prop.email,
                    phone: prop.phone,
                    createdat: Createdate.toLocaleDateString(),
                    totalaccountants: "-",
                    totalclients: "-",
                    monthlycost: "-",
                    switch: (
                        prop.joinedAt !== null ?
                            prop.isBlocked ?
                                <MDBox>
                                    <MDTypography
                                        variant="caption" fontWeight="regular" fontSize="0.875rem" color="error"
                                    // sx={{ marginLeft: "-4px", fontSize: "1.20rem" }}
                                    >
                                        Inactive
                                    </MDTypography>
                                </MDBox>
                                :
                                <MDBox>
                                    <MDTypography
                                        variant="caption" fontWeight="regular" fontSize="0.875rem" color="success"
                                    // sx={{ marginLeft: "-4px", fontSize: "1.20rem" }}
                                    >
                                        Active
                                    </MDTypography>
                                </MDBox>
                            :
                            <MDBox>
                                <MDTypography
                                    variant="caption" fontWeight="regular" fontSize="0.875rem" color="warning"
                                >
                                    Pending Confirmation
                                </MDTypography>
                            </MDBox>

                    ),
                    role: (prop.role === null ? "-" : capitalizeFirstLetter(prop?.permissions[0]?.role)),
                    action: (profileInfo?.user?._id == prop._id ? ("") : (
                        <MDBox display="flex" justifyContent="center">
                            {canAccess(actions = 'update', resource = 'employees') &&
                                <Link to={`/accountant/users/${prop._id}/edit-user`}>
                                    <MDTypography
                                        variant="body2"
                                        color="secondary"
                                        sx={{ marginLeft: "-4px", fontSize: "1.20rem", cursor: 'pointer' }}
                                    >
                                        <Tooltip title={action.edittooltip} placement="top">
                                            <Icon>edit</Icon>
                                        </Tooltip>
                                    </MDTypography>
                                </Link>
                            }
                            {canAccess(actions = 'delete', resource = 'employees') &&
                                <MDTypography variant="body2" color="secondary" sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: 'pointer' }}>
                                    <Tooltip title={action.deletetooltip} placement="top"
                                        onClick={(value) => {
                                            // setAccountantId(prop._id);
                                            warningWithConfirmMessage();
                                            setUserId(prop._id);
                                        }}
                                    >
                                        <Icon>delete</Icon>
                                    </Tooltip>
                                </MDTypography>
                            }
                        </MDBox>
                    ))
                };
            }
            );
            setAdminList(data);
            setLoading(false);
            setLoadingCircul(false);
        }
    }, [response]);

    const dataTableData = {
        columns: [
            {
                Header: "Name",
                accessor: "firstName",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Email",
                accessor: "email",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Clients",
                accessor: "clientIds",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Phone Number",
                accessor: "phone",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Role",
                accessor: "role",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Status",
                accessor: "switch",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Created Date",
                accessor: "createdat",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Action",
                accessor: "action",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
        ],
        rows: adminList
    }


    const handleSearch = async (event) => {
        localStorage.setItem("offset", 0)
        setSearch(event.target.value);
        window.history.pushState('data', 'title', `${pathname}?keyword=${encodeURIComponent(event.target.value)}`);
        setLoadingCircul(true);
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={4}>
                <Card sx={loaderClass} >
                    <MDBox pt={3} px={2} sx={{ pr: 3, mb: 2 }} component="form" display="flex" justifyContent="space-between" alignItems="center">
                        <MDTypography variant="h5" fontWeight="medium">
                            Users
                        </MDTypography>
                        <MDBox px={2} display="flex" >
                            <MDBox sx={{ pr: 3, mb: 2 }}>
                                <MDInput type="search" label="Search User" onKeyDown={handleKeyDown} variant="standard" onChange={handleSearch} />
                            </MDBox>
                            {canAccess(actions = 'create', resource = 'employees') &&
                                <MDBox>
                                    <MDButton
                                        component={NavLink} to="/accountants/users/new-user"
                                        variant="gradient"
                                        color="info"
                                        disabled={loading}
                                    > Add New User
                                    </MDButton>
                                </MDBox>
                            }
                        </MDBox>
                    </MDBox>
                    <MDBox>
                        {alert}
                    </MDBox>
                    {
                        <MDBox my={5} sx={{ mt: 1, borderRadius: '13px' }}>
                            <DataTable1 table={dataTableData} entriesPerPage={false} pagination={true} showTotalEntries={true} response={tableEmpty} showCheck={false} ClientHeigth={true} />
                        </MDBox>
                    }
                    <MDBox>
                        <MDBox>
                            <MDSnackbar
                                color="success"
                                icon="done"
                                title="Success"
                                content="User Deleted Successfully."
                                open={showSnackbar}
                                close={() => setShowSnackbar(false)}
                            />
                        </MDBox>
                    </MDBox>
                    <MDBox>
                        <Dialog
                            open={alert}
                            onClose={hideAlert}
                            aria-labelledby="alert-dialog-title"
                            sx={{
                                "& .MuiDialog-container": {
                                    "& .MuiPaper-root": {
                                        width: "100%",
                                        // height: "40%",
                                        maxWidth: "450px",
                                    },
                                },
                            }}
                        >
                            <DialogContent sx={{ overflowY: 'hidden' }}>
                                <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                                    <h3>Are you sure?</h3>
                                </DialogContentText>
                                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                                    <MDTypography
                                        variant="body2"
                                        color="secondary"
                                        sx={{ fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 4 }}
                                    >
                                        Once you delete this User there is no way of getting any of the documents or data back.
                                    </MDTypography>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions sx={{ justifyContent: "center", mt: 2 }} >
                                <MDButton
                                    variant="gradient"
                                    color="success"
                                    onClick={successDelete} >
                                    Yes, delete it!
                                </MDButton>
                                <MDButton
                                    variant="gradient"
                                    color="error"
                                    onClick={hideAlert}
                                >Cancel
                                </MDButton>
                            </DialogActions>
                        </Dialog>
                    </MDBox>

                    <MDBox>
                        <Dialog
                            sx={{
                                "& .MuiDialog-container": {
                                    "& .MuiPaper-root": {
                                        width: "100%",
                                        maxWidth: "450px",
                                    },
                                },
                            }}
                            onClose={handleClose}
                            open={alertClient}
                        >
                            <DialogTitle sx={{
                                justifyContent: "center",
                                display: 'flex'
                            }}>CLIENTS</DialogTitle>
                            <DialogContent>
                                <List sx={{
                                    pt: 0
                                }}>
                                    {clientList.map((client) => (

                                        <ListItem disableGutters >
                                            {/* <ListItemButton key={client}> */}
                                            <CircleIcon sx={{ mb: '-6px', ml: 1.5, mr: 1.5, fontSize: 'small' }} />
                                            <ListItemText primaryTypographyProps={{ fontSize: '0.875rem', fontWeight: 400, mt: 1 }}
                                                primary={client} />
                                            {/* </ListItemButton> */}
                                        </ListItem>
                                    ))}
                                </List>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Close</Button>
                            </DialogActions>
                        </Dialog>
                        <MDBox>
                            {loadingCircul && (
                                <CircularProgress
                                    size={50}
                                    sx={{
                                        color: green[500],
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </MDBox>
                    </MDBox>
                    <MDBox display="flex" justifyContent="center" >
                        {loading && <Loader />}
                    </MDBox>
                </Card>

            </MDBox>
        </DashboardLayout>
    );
}

export default UserHoc(User);