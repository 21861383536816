import * as React from "react";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import MDBox from "components/MDBox";
import { Typography } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DashboardLayout from "../../../clientuser/user/components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../clientuser/user/components/Navbars/DashboardNavbar";
import EditClients from "./edit-client";
import Customer from "./customer";
import UserHoc from "hoc/userHoc";
import Products from "./products";
import Category from "./category";
import Classes from "./classes";
import Vendor from "./vendor";
import { eventBus } from "utils/eventbus";
import MDTypography from "components/MDTypography";
import image from 'layouts/clients/setting/output-onlinegiftools.gif'
import { useAPICall } from "utils/useapicall";
import { GetuserClientDetails } from "services/clientservices";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function VendorClientSettings() {
    const [value, setValue] = useState(localStorage.getItem("val"));
    const [syncPercent, setSyncPercent] = useState(0);
    const [documentSource, setDocumentSource] = useState('');
    const [reloadApi, setReloadApi] = useState(0);
    const [showSyncing, setShowSyncing] = useState(false);
    const [loaderClass, setLoaderClass] = useState({});
    const [businessId, setBusinessId] = useState(location.pathname.split("/").slice(-2)[0]);
    const subNav = ["Business Info", "Vendor", "Customer", "Classes", "Category", "Products"]
    const urlParam = window.location.pathname.split("/")
    const [{ response: clientDetailsDataResponse }, getClientDetailsAPICall] = useAPICall(GetuserClientDetails, { onLoad: false });
    let tabName = urlParam.slice(-1);
    useEffect(async () => {
        handleTab()
        await getClientDetailsAPICall({
            payload: [{ "bussinessId": `${businessId}` }]
        });
    }, []);

    // stop relaod api
    useEffect(() => {
        const fetchData = async () => {
            await getClientDetailsAPICall({
                payload: [{ "bussinessId": `${businessId}` }]
            });
        };

        const timer = setTimeout(() => {
            fetchData();
        }, 180000);

        return () => clearTimeout(timer);
    }, [reloadApi]);

    useEffect(() => {
        if (clientDetailsDataResponse && clientDetailsDataResponse.data.isSuccess === true) {
            if (clientDetailsDataResponse?.data?.data?.client?.syncPercent !== 100) {
                setReloadApi(reloadApi + 1);
            }
            setSyncPercent(clientDetailsDataResponse?.data?.data?.client?.syncPercent);
            setDocumentSource(clientDetailsDataResponse?.data?.data?.client?.documentSource);
            setShowSyncing(true);
        }
    }, [clientDetailsDataResponse]);

    eventBus.$on('OnSetBlurEdit', (data) => data === true ? setLoaderClass({ opacity: '0.4', borderRadius: '13px' }) : setLoaderClass({}));

    const handleTab = () => {
        if (tabName[0] === 'edit-client') {
            setValue(0)
            localStorage.setItem("val", value)
        }
        if (tabName[0] === 'vendor') {
            setValue(1)
            localStorage.setItem("val", value)
        }
        if (tabName[0] === 'customer') {
            setValue(2)
            localStorage.setItem("val", value)
        }
        if (tabName[0] === 'classes') {
            setValue(3)
            localStorage.setItem("val", value)
        }
        if (tabName[0] === 'category') {
            setValue(4)
            localStorage.setItem("val", value)
        }
        if (tabName[0] === 'products') {
            setValue(5)
            localStorage.setItem("val", value)
        }
    }
    const pathnames = window.location.pathname;
    const pathname = pathnames.substr(0, pathnames.lastIndexOf('/'));
    const handleChange = async (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <DashboardLayout>
                <DashboardNavbar />
                <MDBox my={2}>
                    <Card sx={loaderClass}>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs
                                    value={value} onChange={handleChange} aria-label="basic tabs example"
                                    sx={{
                                        '& .MuiButtonBase-root': {
                                            flex: 'none',
                                            //   width:'88px',
                                        },
                                    }}
                                >
                                    {subNav.map((item, i) => {
                                        let uri = (i) ? pathname + ('/') + item.toLowerCase() : pathname + ('/edit-client');
                                        return <Tab key={i}
                                            sx={{ paddingLeft: 2, paddingRight: 2 }}
                                            // icon={i > 0 && <Divider orientation="vertical" variant="middle" flexItem />}
                                            label={`${item.replaceAll('-', ' ')}`}
                                            component={Link}
                                            to={uri}
                                            {...a11yProps(i)}
                                        />
                                    })
                                    }
                                    {showSyncing && syncPercent !== 100 && documentSource !== 'Paynthr' ? <MDBox sx={{
                                        width: '100%', display: 'flex',
                                        justifyContent: 'end'
                                    }}>
                                        <div>
                                            <img width={25} height={25} style={{ marginBottom: '-6px', marginRight: '8px' }} src={image} />
                                        </div>
                                        <MDTypography sx={{ mr: 2 }} fontWeight={'bold'} color={'warning'} >Fetching data from Quickbooks({syncPercent}%)</MDTypography>
                                    </MDBox> : ''}
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <EditClients businessId={businessId} />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Vendor businessId={businessId} IsSynced={true} />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Customer businessId={businessId} />
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <Classes businessId={businessId} />
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <Category businessId={businessId} />
                            </TabPanel>
                            <TabPanel value={value} index={5}>
                                <Products businessId={businessId} />
                            </TabPanel>
                        </Box>
                    </Card>
                    <MDBox>
                    </MDBox>
                </MDBox>
            </DashboardLayout>
        </>
    );
}

export default UserHoc(VendorClientSettings)