import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { eventBus } from 'utils/eventbus';
import FormField from "layouts/admin/accountants/components/FormField";

import { useAPICall } from "utils/useapicall";
import { GetProductsList, EditProductApi } from "services/userservices";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import { EditProductSchema } from "../components/schemas/editproductvalidation";

function EditProduct({ onClose, productId, businessId }) {
    const [btnDisable, setBtnDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [address, setAddress] = useState('');
    const [ProductName, setProductName] = useState('');
    const [price, setPrice] = useState('');
    const [productType, setProductType] = useState('');
    const [netTerms, setNetTerms] = useState('');
    const [description, setDescription] = useState('');
    const [ProductList, setProductList] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const [{ isLoading: isEditProductLoading, isError: isEditProductError, response: EditProductResponse }, EditProductListAPICall] = useAPICall(EditProductApi, { onLoad: false });
    const [{ isLoading: isProductsLoading, isError: isProductsError, response: ProductsResponse }, ProductsListAPICall] = useAPICall(GetProductsList, { onLoad: false });

    useEffect(async () => {
        if (ProductList.length != 0) {
            const Data = ProductList.find(obj => {
                return obj._id === productId;
            });
            setProductName(Data?.name !== '---' ? Data?.name : '');
            setAddress(Data?.billingAddress?.line1 !== '---' ? Data?.billingAddress?.line1 : '');
            setProductType(Data?.productType);
            setPrice(Data?.unitPrice);
            setNetTerms(Data?.balance);
            setDescription(Data?.description !== '---' ? Data?.description : '');
            setShowDetails(true);
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }

        if (productId) {
            setTimeout(() => {
                setShowDetails(true);
                setLoading(false);
            }, 2000);
        }

    }, [ProductList, productId])


    useEffect(async () => {
        setLoading(true);
        await ProductsListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
    }, []);

    useEffect(() => {
        if (isProductsLoading) {
            return () => null;
        }
        if (isProductsError) {
            setLoading(false);
            return () => null;
        }
        if (ProductsResponse && ProductsResponse.data.isSuccess === true) {
            setProductList(ProductsResponse.data.data.records);
        }
    }, [ProductsResponse]);

    useEffect(() => {
        if (isEditProductLoading) {
            return () => null;
        }
        if (isEditProductError) {
            setLoading(false);
            setBtnDisable(false);
            return () => null;
        }
        if (EditProductResponse && EditProductResponse.data.isSuccess === true) {
            setLoading(false);
            setBtnDisable(false);
            onClose();
            eventBus.$emit("reloadProductDetailsList", EditProductResponse);
        }
    }, [EditProductResponse]);

    let initialValues = {
        product: ProductName,
        address: address,
        description: description,
        productType: productType,
        price: price,
        net: netTerms,
    };

    const handleSubmit = async (values) => {
        setBtnDisable(true);
        await EditProductListAPICall({
            payload: [
                {
                    "name": values.product,
                    "description": values.description,
                    "unitPrice": values.price,
                    "productType": productType,
                    "purchaseCost": 0,
                    "trackQtyOnHand": true,
                    "classId": null,
                    "incomeAccountId": "643e1afe8adcd500089039e5",
                    "assetAccountId": null,
                    "expenseAccountId": null,
                    "productId": `${productId}`,
                }]
        })
    };

    return (
        <>{showDetails &&
            <>
                < MDBox mt={0} >
                    <Grid spacing={3}>
                        <Grid item xs={12}>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={EditProductSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ values, errors, touched, isSubmitting }) => (
                                    <Form autoComplete="off">
                                        <MDBox p={1}>
                                            <MDBox>
                                                <MDBox mt={1.625}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Name *"
                                                                name="product"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Description"
                                                                name="description"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Price *"
                                                                name="price"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            {/* <FormField
                                                                type="text"
                                                                label="Address"
                                                                name="address"
                                                            /> */}
                                                        </Grid>
                                                    </Grid>
                                                    {/* <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Net Terms"
                                                                name="net"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>

                                                        </Grid>
                                                    </Grid> */}
                                                </MDBox>
                                                <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                                                    <MDButton
                                                        disabled={btnDisable}
                                                        variant="gradient"
                                                        color="light"
                                                        onClick={onClose}
                                                    >
                                                        Cancel
                                                    </MDButton>
                                                    <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                        <MDButton
                                                            disabled={btnDisable}
                                                            type="submit"
                                                            variant="gradient"
                                                            color="info"
                                                        >
                                                            {btnDisable ? "updating..." : "update"}
                                                        </MDButton>
                                                    </MDBox>
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>

                                    </Form>
                                )}
                            </Formik>

                        </Grid>

                    </Grid>
                </MDBox>

            </>
        }
            <MDBox>
                {loading && (
                    <CircularProgress
                        size={50}
                        sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </MDBox>
        </>
    );
}

export default EditProduct;
