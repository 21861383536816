import * as Yup from "yup";

export const InvoiceEditSchema = Yup.object().shape({
    // vendorname: Yup.string()
    //     .trim()
    //     .required('Vendor  Name is required.')
    //     .min(3, 'Vendor Name should be more than 3 characters.'),
    // documenttype: Yup.string()
    //     .trim()
    //     .required('Document Type is required.')
    //     .min(3, 'Document Type should be more than 3 characters.'),
    // invoicenumber: Yup.string().trim()
    //     .nullable()
    //     .required('Invoice number is required.'),
    // ponumber: Yup.string().trim().required("Po Number is required"),
    invoicedate: Yup.string().trim().nullable().required("Invoice date is required"),
    uploaddate: Yup.string().trim().nullable().required("Upload date is required"),
    // total: Yup
    //     .string()
    //     .matches(
    //         /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/,
    //         "Must be a number type"
    //     )
    //     // .number("Must be a number type")
    //     .test(
    //         "maxDigitsAfterDecimal",
    //         "number field must have 2 digits after decimal or less",
    //         (number) => /^\d+(\.\d{1,2})?$/.test(number)
    //     ),
    // tax: Yup.string()
    //     .matches(
    //         /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/,
    //         "Must be a number type"
    //     )
    //     // .number("Must be a number type")
    //     .test(
    //         "maxDigitsAfterDecimal",
    //         "number field must have 2 digits after decimal or less",
    //         (number) => /^\d+(\.\d{1,2})?$/.test(number)
    //     ),
    // subtotal: Yup.string()
    //     .matches(
    //         /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/,
    //         "Must be a number type"
    //     )
    //     // .number("Must be a number type")
    //     .test(
    //         "maxDigitsAfterDecimal",
    //         "number field must have 2 digits after decimal or less",
    //         (number) => /^\d+(\.\d{1,2})?$/.test(number)
    //     ),

});