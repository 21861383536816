import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";
import pageRoutes from "page.routes";

function PolicyBasicLayout({ image, children, bgColor }) {
    return (
        <PageLayout>
            <DefaultNavbar
                routes={pageRoutes}
                transparent
                light
                marginLeft={13}
            />
            <MDBox
                position="absolute"
                width="100%"
                minHeight="100vh"
                sx={{
                    backgroundColor: bgColor ? bgColor : "#0d87f6",
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        image &&
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
            />
            <MDBox px={1} width="100%" mx="auto" >
                <Grid container spacing={1} justifyContent="center" height="100%">
                    <Grid item xs={11} sm={9} mt={15} >
                        {children}
                    </Grid>
                </Grid>
            </MDBox>
        </PageLayout>
    );
}

PolicyBasicLayout.propTypes = {
    image: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default PolicyBasicLayout;
