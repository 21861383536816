import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Grid from "@mui/material/Grid";
import FormControlLabel from '@mui/material/FormControlLabel';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { addCategoryschema } from "./components/Itemschema/addCategoryschema";
import FormField from "layouts/admin/accountants/components/FormField";
import { useAPICall } from "../../../../utils/useapicall";
import { AddNewItem, GetCustomerList, GetClassesList, GetCategoryList, GetProductsList } from "../../../../services/userservices";
import Checkbox from '@mui/material/Checkbox';
import { eventBus } from 'utils/eventbus';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import AddCustomerDialog from "./addnewcustomer";
import AddClassDialog from "./addnewclass";
import AddNewCategoryDialog from "./addnewcategory";
import { makeStyles } from "@material-ui/core/styles";
import CurrencyFieldText from "../components/CurrencyField-Text";
import MDTypography from "components/MDTypography";
import NumberFieldText from "../components/NumberField";

const useStyles = makeStyles({
    option: {
        "&:first-child": {
            color: "#0000ff",
        },
        "&:hover:first-child": {
            color: "#0000ff",
            backgroundColor: "none !important"
        }
    }
});


function AddCategoryItemDialog({ invoiceId, onClose, currencySymbol, businessId, itemList, toggleShow, showClasses }) {
    const styles = useStyles();
    const [invoiceIds, setInvoiceIds] = useState(invoiceId);
    const [isDisable, setBtnDisable] = useState(false);
    const [value, setValue] = useState('AccountBasedExpenseLineDetail');
    const [customerList, setCustomerList] = useState([]);
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState(0);
    const [TotalPrice, setTotalPrice] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [unitPrice, setUnitPrice] = useState('');
    const [selectProduct, setSelectProduct] = useState(false);
    const [classesList, setClassesList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [classIdIds, setClassIdId] = useState('');
    const [customerIds, setCustomerIdId] = useState('');
    const [categoryIds, setCategoryIds] = useState('');
    const [adjustInvoice, setAdjustInvoice] = useState(false);
    const [categoryerrormsg, setCategoryerrormsg] = useState('');
    const [categoryerror, setCategoryerror] = useState(false);
    const [priceerror, setPriceerror] = useState(false);
    const [openAddNewCustomer, setOpenAddNewCustomer] = useState(false);
    const [openAddNewClass, setOpenAddNewClass] = useState(false);
    const [openAddNewCategory, setOpenAddNewCategory] = useState(false);
    const [categoryName, setCategoryName] = useState('');
    const [className, setClassName] = useState('');
    const [showerror, setShowerror] = useState(false);
    const [showerrorqty, setShowerrorqty] = useState(false);
    const [errorButtonDis, setErrorButtonDis] = useState(false);
    const [customerName, setCustomerName] = useState('');
    const [quantityerror, setQuantityrror] = useState(false);
    const [quantityErrorMsg, setQuantityErrorMsg] = useState('');
    const [priceErrorMsg, setPriceErrorMsg] = useState('');
    const [{ isError, isLoading, response }, setAPICall] = useAPICall(AddNewItem, { onLoad: false });
    const [{ isLoading: isCustomerLoading, isError: isCustomerError, response: CustomerResponse }, CustomerListAPICall] = useAPICall(GetCustomerList, { onLoad: false });
    const [{ isLoading: isClassesLoading, isError: isClassesError, response: ClassesResponse }, ClassesListAPICall] = useAPICall(GetClassesList, { onLoad: false });
    const [{ isLoading: isCategoryLoading, isError: isCategoryError, response: CategoryResponse }, CategoryListAPICall] = useAPICall(GetCategoryList, { onLoad: false });
    const [{ isLoading: isProductsLoading, isError: isProductsError, response: ProductsResponse }, ProductsListAPICall] = useAPICall(GetProductsList, { onLoad: false });

    const AddCategory = { name: '+ Add Category' }
    const NewCategoryList = [AddCategory, ...categoryList]
    const AddCustomer = { name: '+ Add Customer' }
    const NewCustomerList = [AddCustomer, ...customerList]
    const AddClass = { name: '+ Add Class' }
    const NewClassesList = [AddClass, ...classesList]

    useEffect(async () => {
        setValue('AccountBasedExpenseLineDetail')
        await CustomerListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
        await ClassesListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
        await CategoryListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
        await ProductsListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
    }, []);

    const initialValues = {
        product: "",
        checked: "",
        item: '',
        sku: "",
        price: '',
        total: "",
        qty: "",
        services: "",
        class: "",
        customer: "",
        unit: "",
        category: "",
        TotalPrice: ""
    };

    useEffect(() => {
        let prices = (typeof (price) == 'string') ? price.replaceAll(',', '') : price
        let quantitys = (typeof (quantity) == 'string') ? quantity.replaceAll(',', '') : quantity
        if (showerror === true) {
            if (!prices) {
                setPriceErrorMsg('Price is required');
                setPriceerror(true);
            } else if (prices.length === 0 || Number(prices) === 0) {
                setPriceErrorMsg('Price Must be more than 0');
                setPriceerror(true);
            } else {
                setPriceErrorMsg('');
                setPriceerror(false);
            }
        }

        if (prices?.length === 0 || quantitys?.length === 0 || Number(quantitys) === 0) {
            setErrorButtonDis(true);
        } else {
            setErrorButtonDis(false);
        }

        if (showerrorqty === true) {
            if (!quantitys) {
                setQuantityErrorMsg('Quantity is required');
                setQuantityrror(true);
            } else if (quantitys.length === 0 || Number(quantitys) === 0) {
                setQuantityErrorMsg('Quantity Must be more than 0');
                setQuantityrror(true);
            } else {
                setQuantityErrorMsg('');
                setQuantityrror(false);
            }
        }

        const timeOutId = setTimeout(async () =>
            setTotalPrice(Number(prices) * Number(quantitys)),
            500);
        return () => clearTimeout(timeOutId);
    }, [price, quantity]);

    useEffect(() => {
        if (price.length === 0 || Number(price) === 0.00 && quantity.length === 0 || Number(quantity) === 0) {
            setErrorButtonDis(true);
        } else {
            setErrorButtonDis(false);
        }

    }, []);

    useEffect(async () => {
        if (isLoading) {
            return () => null;
        }
        if (isError) {
            setBtnDisable(false);
            return () => null;
        }
        if (response && response.data.isSuccess === true) {
            eventBus.$emit("closeAddNewItemPopup", response);
            setBtnDisable(false);
        }
    }, [response]);

    useEffect(() => {
        if (isCustomerLoading) {
            return () => null;
        }
        if (isCustomerError) {
            return () => null;
        }
        if (CustomerResponse && CustomerResponse.data.isSuccess === true) {
            const data = CustomerResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    customerId: prop._id
                };
            }
            );
            setCustomerList(data);
        }
    }, [CustomerResponse]);

    useEffect(() => {
        if (isClassesLoading) {
            return () => null;
        }
        if (isClassesError) {
            return () => null;
        }
        if (ClassesResponse && ClassesResponse.data.isSuccess === true) {
            const data = ClassesResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    classId: prop._id
                };
            }
            );
            setClassesList(data);
        }
    }, [ClassesResponse]);


    useEffect(() => {
        if (isCategoryLoading) {
            return () => null;
        }
        if (isCategoryError) {
            return () => null;
        }
        if (CategoryResponse && CategoryResponse.data.isSuccess === true) {
            const data = CategoryResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    categoryId: prop._id
                };
            }
            );
            setCategoryList(data);
        }
    }, [CategoryResponse]);

    useEffect(() => {
        if (isProductsLoading) {
            return () => null;
        }
        if (isProductsError) {
            return () => null;
        }
        if (ProductsResponse && ProductsResponse.data.isSuccess === true) {
            const data = ProductsResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    productId: prop._id,
                    accountingId: prop.accountingId,
                    description: prop.description,
                    unitPrice: prop.unitPrice,
                };
            }
            );
            setProductsList(data);
        }
    }, [ProductsResponse]);

    const handleSubmit = async (values) => {
        if (price.length === 0 || Number(price) === 0) {
            setPriceErrorMsg('Price is required');
            setPriceerror(true);
        } else {
            setPriceErrorMsg('');
            setPriceerror(false);
        }

        if (quantity.length === 0 || Number(quantity) === 0) {
            setQuantityErrorMsg('Quantity is required');
            setQuantityrror(true);
        } else {
            setQuantityErrorMsg('');
            setQuantityrror(false);
        }
        if (categoryIds) {
            setCategoryerrormsg('');
            setCategoryerror(false);
            setBtnDisable(true);
        } else {
            setCategoryerrormsg('Category name required');
            setCategoryerror(true);
        }
        const finalAmount = (typeof (values.price) == 'string') ? values.price.replaceAll(',', '') : values.price;
        // if (values?.price.length === 0) {
        //     console.log("values?.price.length---------->", values?.price.length)
        //     setPriceerror(true);
        // } else {
        //     setPriceerror(false);
        // }
        const finalprice = (typeof (price) == 'string') ? price.replaceAll(',', '') : price;
        const finalTotalPrice = (typeof (TotalPrice) == 'string') ? TotalPrice.replaceAll(',', '') : TotalPrice;
        const finalquantity = (typeof (quantity) == 'string') ? quantity.replaceAll(',', '') : quantity;
        await setAPICall({
            payload: [
                {
                    "itemType": 'AccountBasedExpenseLineDetail',
                    // "amount": Number(finalAmount),
                    "unitOfMeasure": values.unit,
                    "discount": 0,
                    "description": values.item,
                    "InvoiceId": `${invoiceIds}`,
                    "classId": classIdIds,
                    "customerId": customerIds,
                    "categoryId": categoryIds,
                    "adjustDocument": adjustInvoice,
                    "unitPrice": Number(finalprice),
                    "amount": Number(finalTotalPrice),
                    "quantity": Number(finalquantity),
                }
            ]
        })
    };

    const handleChange = () => {
        setAdjustInvoice(!adjustInvoice)
    }

    useEffect(() => {
        if (categoryIds) {
            setCategoryerrormsg('');
            setCategoryerror(false);
        } else if (isDisable && !categoryIds) {
            setCategoryerrormsg('Category name required');
            setCategoryerror(true);
        }
    }, [categoryIds])

    const handleChangeCategory = (event, newValue) => {
        if (newValue) {
            setCategoryerrormsg('');
            setCategoryerror(false);
        } else {
            setCategoryerrormsg('Category name required');
            setCategoryerror(true);
        }
        if (newValue == '+ Add Category') {
            setOpenAddNewCategory(true);
        } else {
            setCategoryName(newValue)
            const found = categoryList.find(obj => {
                return obj.name === newValue;
            });
            if (found) {
                setCategoryIds(found.categoryId);
                setSelectProduct(true);
                setDescription(found.description);
                setUnitPrice(found.unitPrice)
            }
        }
    };

    const handleChangeClass = (event, newValue) => {
        if (newValue == '+ Add Class') {
            setOpenAddNewClass(true);
        } else {
            setClassName(newValue);
            const found1 = classesList.find(obj => {
                return obj.name === newValue;
            });
            if (found1) {
                setClassIdId(found1.classId);
            }
        }
    };

    const handleChangeCustomer = (event, newValue) => {
        if (newValue == '+ Add Customer') {
            setOpenAddNewCustomer(true);
        } else {
            setCustomerName(newValue);
            const found2 = customerList.find(obj => {
                return obj.name === newValue;
            });
            if (found2) {
                setCustomerIdId(found2.customerId);
            }
        }
    };

    eventBus.$on('AddnewCategoryComplete', (data) => AddnewCategoryComplete(data));
    async function AddnewCategoryComplete(data) {
        await CategoryListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
        setCategoryName(data?.account?.name);
        setCategoryIds(data?.account?._id);
    }

    eventBus.$on('AddnewCustomerComplete', (data) => AddnewCustomerComplete(data));
    async function AddnewCustomerComplete(data) {
        await CustomerListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
        setCustomerName(data?.customer?.name);
        setCustomerIdId(data?.customer?._id);
    }

    eventBus.$on('AddnewClassComplete', (data) => AddnewClassComplete(data));
    function AddnewClassComplete(data) {
        setClassName(data?.class?.name);
        setClassIdId(data?.class?._id);
        ClassesListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
    }

    return (
        <>
            <MDBox width='auto'>
                <Grid item xs={12} lg={12}>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        // validationSchema={addCategoryschema}
                        onSubmit={handleSubmit}
                    >
                        {({ values, errors, touched, isSubmitting }) => (
                            <Form autoComplete="off">
                                <MDBox p={2} pt={2}>
                                    <MDBox >
                                        <MDBox >
                                            <Grid container spacing={3} sx={{ mb: 1 }}>
                                                <Grid item xs={12} sm={6}>
                                                    <Autocomplete
                                                        sx={{
                                                            "& .MuiInputBase-input.Mui-disabled": {
                                                                WebkitTextFillColor: "black",
                                                            },
                                                            "& .MuiInputBase-input": {
                                                                height: `1.2em !important`
                                                            },
                                                            "& .MuiInput-input": {
                                                                padding: `6px 4px 4px 0px !important`
                                                            },
                                                        }}
                                                        classes={{
                                                            option: styles.option
                                                        }}
                                                        value={{ name: categoryName }}
                                                        options={NewCategoryList}
                                                        getOptionLabel={(option) => option.name}
                                                        onInputChange={(event, newInputValue) => {
                                                            handleChangeCategory(event, newInputValue);
                                                        }}
                                                        id="controllable-states-demo"
                                                        disableCloseOnSelect={false}
                                                        renderInput={(params) => (
                                                            <TextField {...params}
                                                                label="Category *"
                                                                variant="standard"
                                                                helperText={categoryerrormsg}
                                                                error={categoryerror}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Autocomplete
                                                        sx={{
                                                            "& .MuiInputBase-input.Mui-disabled": {
                                                                WebkitTextFillColor: "black",
                                                            },
                                                            "& .MuiInputBase-input": {
                                                                height: `1.2em !important`
                                                            },
                                                            "& .MuiInput-input": {
                                                                padding: `6px 4px 4px 0px !important`
                                                            },
                                                        }}
                                                        classes={{
                                                            option: styles.option
                                                        }}
                                                        options={NewCustomerList}
                                                        value={{ name: customerName }}
                                                        getOptionLabel={(option) => option.name}
                                                        onInputChange={(event, newInputValue) => {
                                                            handleChangeCustomer(event, newInputValue);
                                                        }}
                                                        id="controllable-states-demo"
                                                        disableCloseOnSelect={false}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Customer" variant="standard"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3} sx={{ mb: 1 }} >
                                                {showClasses ?
                                                    <>
                                                        <Grid item xs={12} sm={6} mt={0.5}>
                                                            <Autocomplete
                                                                sx={{
                                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                                        WebkitTextFillColor: "black",
                                                                    },
                                                                    "& .MuiInputBase-input": {
                                                                        height: `1.2em !important`
                                                                    },
                                                                    "& .MuiInput-input": {
                                                                        padding: `6px 4px 4px 0px !important`
                                                                    },
                                                                }}
                                                                classes={{
                                                                    option: styles.option
                                                                }}
                                                                value={{ name: className }}
                                                                options={NewClassesList}
                                                                getOptionLabel={(option) => option.name}
                                                                onInputChange={(event, newInputValue) => {
                                                                    handleChangeClass(event, newInputValue);
                                                                }}
                                                                id="controllable-states-demo"
                                                                disableCloseOnSelect={false}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} label="Class" variant="standard"
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} mt={-0.5}>
                                                            <FormField
                                                                type="text"
                                                                label="Description"
                                                                name="item"
                                                            />
                                                        </Grid>
                                                    </>
                                                    :
                                                    <>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Description"
                                                                name="item"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Unit"
                                                                name="unit"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    </>
                                                }
                                            </Grid>
                                            <Grid container spacing={3} sx={{ mb: 1 }}>
                                                {
                                                    showClasses &&
                                                    <>
                                                        <Grid item xs={12} sm={6} mt={-0.7}>
                                                            <FormField
                                                                type="text"
                                                                label="Unit"
                                                                name="unit"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <CurrencyFieldText
                                                                label="Price *"
                                                                name="price"
                                                                value={price}
                                                                fullWidth
                                                                error={priceerror}
                                                                onValueChange={(event) => {
                                                                    setPrice(event.value)
                                                                    setShowerror(true);
                                                                }}
                                                            />
                                                            <MDBox mt={0.75}>
                                                                <MDTypography
                                                                    component="div"
                                                                    variant="caption"
                                                                    color="error"
                                                                    fontWeight="regular">
                                                                    {priceErrorMsg}
                                                                </MDTypography>
                                                            </MDBox>
                                                        </Grid>
                                                    </>
                                                }

                                            </Grid>
                                            <Grid container spacing={3} sx={{ mb: 1 }}>
                                                {
                                                    !showClasses &&
                                                    <Grid item xs={12} sm={6} mt={1}>
                                                        <CurrencyFieldText
                                                            label="Price *"
                                                            name="price"
                                                            value={price}
                                                            fullWidth
                                                            error={priceerror}
                                                            onValueChange={(event) => {
                                                                setPrice(event.value)
                                                                setShowerror(true);
                                                            }}
                                                        />
                                                        <MDBox mt={0.75}>
                                                            <MDTypography
                                                                component="div"
                                                                variant="caption"
                                                                color="error"
                                                                fontWeight="regular">
                                                                {priceErrorMsg}
                                                            </MDTypography>
                                                        </MDBox>
                                                    </Grid>
                                                }
                                                {
                                                    !showClasses &&
                                                    <Grid item xs={12} sm={6} mt={1}>
                                                        <NumberFieldText
                                                            label="Quantity *"
                                                            name="qty"
                                                            fullWidth
                                                            error={quantityerror}
                                                            value={quantity}
                                                            onValueChange={(event) => {
                                                                setQuantity(event.value)
                                                                setShowerrorqty(true);
                                                            }}
                                                        />
                                                        <MDBox mt={0.75}>
                                                            <MDTypography
                                                                component="div"
                                                                variant="caption"
                                                                color="error"
                                                                fontWeight="regular"
                                                            >
                                                                {quantityErrorMsg}
                                                            </MDTypography>
                                                        </MDBox>
                                                    </Grid>
                                                }
                                                {
                                                    showClasses &&
                                                    <Grid item xs={12} sm={6} >
                                                        <NumberFieldText
                                                            label="Quantity *"
                                                            name="qty"
                                                            fullWidth
                                                            error={quantityerror}
                                                            value={quantity}
                                                            onValueChange={(event) => {
                                                                setQuantity(event.value)
                                                                setShowerrorqty(true);
                                                            }}
                                                        />
                                                        <MDBox mt={0.75}>
                                                            <MDTypography
                                                                component="div"
                                                                variant="caption"
                                                                color="error"
                                                                fontWeight="regular"
                                                            >
                                                                {quantityErrorMsg}
                                                            </MDTypography>
                                                        </MDBox>
                                                    </Grid>
                                                }
                                                {showClasses &&
                                                    <Grid item xs={12} sm={6}>
                                                        <CurrencyFieldText
                                                            label="Total Price"
                                                            name="TotalPrice"
                                                            disabled
                                                            fullWidth
                                                            value={TotalPrice}
                                                        />
                                                    </Grid>
                                                }
                                            </Grid>
                                            <Grid container spacing={3} sx={{ mb: 1 }}>
                                                {!showClasses &&
                                                    <Grid item xs={12} sm={6}>
                                                        <CurrencyFieldText
                                                            label="Total Price"
                                                            name="TotalPrice"
                                                            disabled
                                                            fullWidth
                                                            value={TotalPrice}
                                                        />
                                                    </Grid>
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <MDBox>
                                                    <Field
                                                        as={FormControlLabel}
                                                        type="checkbox"
                                                        name="checkbox"
                                                        control={<Checkbox />}
                                                        label="Adjust Total Price"
                                                        checked={adjustInvoice}
                                                        onChange={handleChange}
                                                        sx={{ mt: 2 }}
                                                    />
                                                </MDBox>
                                            </Grid>
                                        </MDBox>
                                        <MDBox mb={-2} mt={5} display="flex" justifyContent="end">
                                            <MDButton
                                                disabled={isDisable}
                                                variant="gradient"
                                                color="light"
                                                onClick={onClose}
                                            >
                                                Cancel
                                            </MDButton>
                                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                <MDButton
                                                    disabled={isDisable || errorButtonDis}
                                                    type="submit"
                                                    variant="gradient"
                                                    color="info"
                                                >
                                                    {!categoryIds ? "Submit" :
                                                        isDisable ? "Submitting..." : "Submit"}
                                                </MDButton>
                                            </MDBox>
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            </Form>
                        )}
                    </Formik>
                </Grid>
                <MDBox>
                    <Dialog
                        open={openAddNewCustomer}
                        onClose={() => setOpenAddNewCustomer(false)}
                        aria-labelledby="alert-dialog-title"
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    maxWidth: "570px",
                                },
                            },
                        }}
                    >   <DialogTitle sx={{
                        justifyContent: "center",
                        display: 'flex'
                    }}>Add Customer</DialogTitle>
                        <DialogContent  >
                            <AddCustomerDialog invoiceId={invoiceId} businessId={businessId} onClose={() => setOpenAddNewCustomer(false)}
                            />
                        </DialogContent>
                    </Dialog>
                </MDBox>
                <MDBox>
                    <Dialog
                        open={openAddNewClass}
                        onClose={() => setOpenAddNewClass(false)}
                        aria-labelledby="alert-dialog-title"
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    maxWidth: "570px",
                                },
                            },
                        }}
                    >   <DialogTitle sx={{
                        justifyContent: "center",
                        display: 'flex'
                    }}>Add Class</DialogTitle>
                        <DialogContent  >
                            <AddClassDialog invoiceId={invoiceId} businessId={businessId} onClose={() => setOpenAddNewClass(false)}
                            />
                        </DialogContent>
                    </Dialog>
                </MDBox>
                <MDBox>
                    <Dialog
                        open={openAddNewCategory}
                        onClose={() => setOpenAddNewCategory(false)}
                        aria-labelledby="alert-dialog-title"
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    maxWidth: "570px",
                                },
                            },
                        }}
                    >   <DialogTitle sx={{
                        justifyContent: "center",
                        display: 'flex'
                    }}>Add Category</DialogTitle>
                        <DialogContent>
                            <AddNewCategoryDialog invoiceId={invoiceId} businessId={businessId} onClose={() => setOpenAddNewCategory(false)}
                            />
                        </DialogContent>
                    </Dialog>
                </MDBox>
            </MDBox>
        </>
    );
}

export default AddCategoryItemDialog;