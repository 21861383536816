import Cookies from 'universal-cookie';
const cookies = new Cookies();
import { useSelector } from "react-redux";

export const getAccountantUserType = () => {
    const stateProfileInfo = useSelector(state => state.profileInfo);
    if (stateProfileInfo) {
        return stateProfileInfo;
    }

    const profileInfo = cookies.get('user_profile_info');
    return profileInfo;
}

export const getAccountantPermmison = () => {
    const profileInfo = cookies.get('user_permissions');
    return profileInfo;
}