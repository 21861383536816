import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import { display, fontWeight } from "@mui/system";

function DataTableBodyCell({ noBorder, align, children, display, width }) {
    return (
        <MDBox
            component="td"
            textAlign={align}
            py={3}
            px={3}
            // sx={{ verticalAlign: "middle", display: "flex", width: 200 }}
            sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
                lineHeight: "1.25",
                fontSize: " 0.75rem",
                letterSpacing: " 0.03333em",
                color: "rgb(123, 128, 154)",
                fontWeight: 600,
                textTransform: "none",
                verticalAlign: "unset",
                borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`,
            })}
        >
            <MDBox
                display={display}
                width={width}
                color="text"
                sx={{ verticalAlign: "middle" }}
            >
                {children}
            </MDBox>
        </MDBox>
    );
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
    noBorder: false,
    align: "left",
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
    children: PropTypes.node.isRequired,
    noBorder: PropTypes.bool,
    align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableBodyCell;