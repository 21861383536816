import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { InputAdornment } from '@mui/material';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CircularProgress from '@mui/material/CircularProgress';
import { green, yellow } from '@mui/material/colors';
import { addproductschema } from "./components/Itemschema/addproductschema";
import { EditItem, GetCustomerList, GetClassesList, GetCategoryList, GetProductsList } from "../../../../services/clientservices";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useMaterialUIController } from "context";
import FormControlLabel from '@mui/material/FormControlLabel';
import { useAPICall } from "utils/useapicall";
import Checkbox from '@mui/material/Checkbox';
import { eventBus } from 'utils/eventbus';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React from 'react'
import FormField from "layouts/admin/accountants/components/FormField";
import NumberFieldText from "layouts/accountants/user/components/NumberField";
import CurrencyFieldText from "../components/CurrencyField-Text";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Typography } from "@mui/material";
import ListItemButton from '@mui/material/ListItemButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';


function EditProductItemDialog({ noProduct, itemListResponse, invoiceId, editItemId, businessId, onClose, currencySymbol, showClasses }) {
    const [isDisable, setBtnDisable] = useState(false);
    const [controller] = useMaterialUIController();
    const [unit, setunit] = useState('');
    const [errProduct, seterrProduct] = useState(noProduct);
    const [sku, setSku] = useState('');
    const [itemItem, setItemitem] = useState('');
    const [quantity, setQuantity] = useState("");
    const [total, setTotal] = useState("");
    const [price, setPrice] = useState("");
    const [category, SetCategory] = useState("")
    const [id, setId] = useState('');
    const [itemDetails, setItemDetails] = useState();
    const [priceerror, setpriceerror] = useState(false);
    const [priceErrorMsg, setPriceErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [adjustInvoice, setAdjustInvoice] = useState(false);
    const [productIds, setProductId] = useState('');
    const [classIdIds, setClassIdId] = useState('');
    const [customerIds, setCustomerIdId] = useState('');
    const [categoryIds, setCategoryIds] = useState('');
    const [description, setDescription] = useState('');
    const [unitPrice, setUnitPrice] = useState('');
    const [classesList, setClassesList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [categoryName, setCategoryName] = useState('');
    const [className, setClassName] = useState('');
    const [productName, setProductName] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [producterrormsg, setProducterrormsg] = useState('');
    const [producterror, setProducterror] = useState(false);
    const [errorButtonDis, setErrorButtonDis] = useState(false);
    const [askClients, setAskClients] = useState(false);
    const [TotalPrice, setTotalPrice] = useState(0);
    const [quantityErrorMsg, setQuantityErrorMsg] = useState('');
    const [quantityerror, setQuantityrror] = useState(false);
    const [showerror, setShowerror] = useState(false);
    const [showerrorqty, setShowerrorqty] = useState(false);
    const [openAddNewProduct, setOpenAddNewProduct] = useState(false);
    const [openAddNewCustomer, setOpenAddNewCustomer] = useState(false);
    const [openAddNewClass, setOpenAddNewClass] = useState(false);
    const [openAddOption, setOpenAddOption] = useState(false);
    const [addProductSelectType, setAddProductSelectType] = useState('');

    const [{ isError, isLoading, response }, setAPICall] = useAPICall(EditItem, { onLoad: false });
    const [{ isLoading: isCustomerLoading, isError: isCustomerError, response: CustomerResponse }, CustomerListAPICall] = useAPICall(GetCustomerList, { onLoad: false });
    const [{ isLoading: isClassesLoading, isError: isClassesError, response: ClassesResponse }, ClassesListAPICall] = useAPICall(GetClassesList, { onLoad: false });
    const [{ isLoading: isCategoryLoading, isError: isCategoryError, response: CategoryResponse }, CategoryListAPICall] = useAPICall(GetCategoryList, { onLoad: false });
    const [{ isLoading: isProductsLoading, isError: isProductsError, response: ProductsResponse }, ProductsListAPICall] = useAPICall(GetProductsList, { onLoad: false });

    useEffect(async () => {
        seterrProduct(noProduct);
        await CustomerListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
        await ClassesListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
        await CategoryListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
        await ProductsListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
    }, []);

    useEffect(() => {
        if (isCustomerLoading) {
            return () => null;
        }
        if (isCustomerError) {
            return () => null;
        }
        if (CustomerResponse && CustomerResponse.data.isSuccess === true) {
            const data = CustomerResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    customerId: prop._id
                };
            }
            );
            setCustomerList(data);
        }
    }, [CustomerResponse]);

    useEffect(() => {
        if (isClassesLoading) {
            return () => null;
        }
        if (isClassesError) {
            return () => null;
        }
        if (ClassesResponse && ClassesResponse.data.isSuccess === true) {
            const data = ClassesResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    classId: prop._id
                };
            }
            );
            setClassesList(data);
        }
    }, [ClassesResponse]);

    useEffect(() => {
        let prices = (typeof (price) == 'string') ? price.replaceAll(',', '') : price
        let quantitys = (typeof (quantity) == 'string') ? quantity.replaceAll(',', '') : quantity
        if (Number(prices) <= 0.00) {
            setErrorButtonDis(true);
        }
        if (showerror === true) {
            if (!prices) {
                setPriceErrorMsg('Price is required');
                setpriceerror(true);
            } else if (prices.length === 0 || Number(prices) === 0) {
                setPriceErrorMsg('Price Must be more than 0');
                setpriceerror(true);
            } else {
                setPriceErrorMsg('');
                setpriceerror(false);
            }
        }
        if (prices?.length === 0 || quantitys?.length === 0 || Number(quantitys) === 0) {
            setErrorButtonDis(true);
        } else {
            setErrorButtonDis(false);
        }
        if (showerrorqty === true) {
            if (!quantitys) {
                setQuantityErrorMsg('Quantity is required');
                setQuantityrror(true);
            } else if (quantitys.length === 0 || Number(quantitys) === 0) {
                setQuantityErrorMsg('Quantity Must be more than 0');
                setQuantityrror(true);
            } else {
                setQuantityErrorMsg('');
                setQuantityrror(false);
            }
        }

        const timeOutId = setTimeout(async () =>
            setTotalPrice(Number(prices) * Number(quantitys)),
            500);
        return () => clearTimeout(timeOutId);
    }, [price, quantity]);

    useEffect(() => {
        if (price.length === 0 || Number(price) === 0 && quantity.length === 0 || Number(quantity) === 0) {
            setErrorButtonDis(true);
        } else {
            setErrorButtonDis(false);
        }
    }, [])


    useEffect(() => {
        if (isCategoryLoading) {
            return () => null;
        }
        if (isCategoryError) {
            return () => null;
        }
        if (CategoryResponse && CategoryResponse.data.isSuccess === true) {
            const data = CategoryResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    categoryId: prop._id
                };
            }
            );
            setCategoryList(data);
        }
    }, [CategoryResponse]);

    useEffect(() => {
        if (isProductsLoading) {
            return () => null;
        }
        if (isProductsError) {
            return () => null;
        }
        if (ProductsResponse && ProductsResponse.data.isSuccess === true) {
            const data = ProductsResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    productId: prop._id,
                    accountingId: prop.accountingId,
                    description: prop.description,
                    unitPrice: prop.unitPrice,
                };
            }
            );
            setProductsList(data);
        }
    }, [ProductsResponse]);

    useEffect(async () => {
        if (isLoading) {
            return () => null;
        }
        if (isError) {
            setBtnDisable(false);
            return () => null;
        }
        if (response && response.data.isSuccess === true) {
            eventBus.$emit("closeEditItemPopup", response);
            setBtnDisable(false);
        }
    }, [response]);

    useEffect(() => {
        setLoading(true);
        const result = itemListResponse.data.data.records.map((prop, key) => {
            {
                if (editItemId === prop._id) {
                    return {
                        id: prop._id,
                        item: prop.description,
                        qty: prop.quantity,
                        unit: prop.unitOfMeasure,
                        price: prop.unitPrice,
                        amount: prop?.amount,
                        total: prop.total,
                        sku: prop.sku,
                        category: prop.category ? prop.category.name : '',
                        categoryId: prop.category ? prop.category._id : '',
                        class: prop.class ? prop.class.name : '',
                        product: prop.product ? prop.product.name : '',
                        productId: prop.product ? prop.product._id : '',
                        classId: prop.class ? prop.class._id : '',
                        customer: prop.customer ? prop.customer.name : '',
                        customerId: prop.customer ? prop.customer._id : '',
                    }
                } else {
                    return {
                        id: prop.id,
                        item: prop.description,
                        qty: prop.quantity,
                        unit: prop.unitOfMeasure,
                        price: prop.unitPrice,
                        amount: prop?.amount,
                        total: prop.total,
                        sku: prop.sku,
                        category: prop.category ? prop.category.name : '',
                        categoryId: prop.category ? prop.category._id : '',
                        class: prop.class ? prop.class.name : '',
                        classId: prop.class ? prop.class._id : '',
                        customer: prop.customer ? prop.customer.name : '',
                        customerId: prop.customer ? prop.customer._id : '',
                        product: prop.product ? prop.product.name : '',
                        productId: prop.product ? prop.product._id : '',
                    }
                }

            }
        });
        setTimeout(() => {
            const results = result.find(item => item.id === editItemId);
            setItemDetails(results);
            setItemitem(results?.item);
            setQuantity(results?.qty);
            setunit(results?.unit);
            setPrice(results?.price);
            setTotal(results?.amount ? results?.amount : results?.total);
            setId(results?.id);
            setSku(results?.sku);
            setCategoryName(results?.category);
            setCustomerName(results?.customer);
            setClassName(results?.class);
            setCategoryIds(results?.categoryId);
            setClassIdId(results?.classId);
            setProductId(results?.productId);
            setCustomerIdId(results?.customerId);
            setProductName(results?.product);
            setLoading(false);
        }, 1000);

    }, [])


    const initialValues = {
        item: itemItem,
        sku: sku,
        customer: "",
        qty: quantity,
        unit: unit,
        total: total,
        price: price,
        category: ""

    };

    const handleSubmit = (values) => {
        if (productIds) {
            setProducterrormsg('');
            setProducterror(false);
        } else {
            setProducterrormsg('Product name required');
            setProducterror(true);
        }
        if (price.length === 0 || Number(price) === 0) {
            setPriceErrorMsg('Price is required');
            setpriceerror(true);
        } else {
            setPriceErrorMsg('');
            setpriceerror(false);
        }

        if (quantity.length === 0 || Number(quantity) === 0) {
            setQuantityErrorMsg('Quantity is required');
            setQuantityrror(true);
        } else {
            setQuantityErrorMsg('');
            setQuantityrror(false);
        }
        const finalPrice = (typeof (values.price) == 'string') ? values.price.replaceAll(',', '') : values.price;
        const finalTotalPrice = (typeof (TotalPrice) == 'string') ? TotalPrice.replaceAll(',', '') : TotalPrice;
        const finalquantity = (typeof (values.qty) == 'string') ? values.qty.replaceAll(',', '') : values.qty;
        setBtnDisable(true);
        setAPICall({
            payload: [
                {
                    "itemType": 'ItemBasedExpenseLineDetail',
                    "unitPrice": Number(finalPrice),
                    "amount": Number(finalTotalPrice),
                    "discount": 0,
                    "sku": values?.sku,
                    "description": values?.item,
                    "unitOfMeasure": values?.unit,
                    "productId": productIds ? productIds : null,
                    "InvoiceId": invoiceId ? invoiceId : documentsId,
                    "classId": classIdIds ? classIdIds : null,
                    "customerId": customerIds ? customerIds : null,
                    "ItemId": `${editItemId}`,
                    "quantity": Number(finalquantity),
                    'askClient': askClients,
                    "adjustDocument": adjustInvoice
                }
            ]
        });

    };



    const handleChange = () => {
        setAdjustInvoice(!adjustInvoice)
    }
    const handleChangeProduct = (event, newValue) => {
        seterrProduct(false);
        if (newValue) {
            setProducterrormsg('');
            setProducterror(false);
        } else {
            setProducterrormsg('Product name required');
            setProducterror(true);
        }
        const found = productsList.find(obj => {
            return obj.name === newValue;
        });
        if (found) {
            setProductId(found.productId);
            setDescription(found.description);
            setUnitPrice(found.unitPrice)
        }
    };

    const handleChangeClass = (event, newValue) => {
        setClassName(newValue);
        const found1 = classesList.find(obj => {
            return obj.name === newValue;
        });
        if (found1) {
            setClassIdId(found1.classId);
        }
    };

    const handleChangeCustomer = (event, newValue) => {
        setCustomerName(newValue);
        const found2 = customerList.find(obj => {
            return obj.name === newValue;
        });
        if (found2) {
            setCustomerIdId(found2.customerId);
        }
    };

    return (
        <>
            {loading ?
                <MDBox>
                    <Grid sx={{ mt: 40 }}>
                        <Grid item xs={12} lg={12}>
                            <CircularProgress
                                size={50}
                                sx={{
                                    color: green[500],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        </Grid>
                    </Grid>
                </MDBox>
                :
                <MDBox width='auto'>
                    <Grid item xs={12} lg={12}>
                        <Formik
                            enableReinitialize
                            initialValues={initialValues}
                            // validationSchema={addproductschema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, errors, touched, isSubmitting }) => (
                                <Form autoComplete="off">
                                    <MDBox p={2} pt={2}>
                                        <MDBox >
                                            <MDBox >
                                                <Grid container spacing={3} sx={{ mb: 3 }}>
                                                    <Grid item xs={12} sm={6}>
                                                        <Autocomplete
                                                            sx={{
                                                                "& .MuiInputBase-input.Mui-disabled": {
                                                                    WebkitTextFillColor: "black",
                                                                },
                                                            }}
                                                            defaultValue={{ name: productName }}
                                                            options={productsList}
                                                            getOptionLabel={(option) => option.name}
                                                            onInputChange={(event, newInputValue) => {
                                                                handleChangeProduct(event, newInputValue);
                                                            }}
                                                            id="controllable-states-demo"
                                                            disableCloseOnSelect={false}
                                                            renderInput={(params) => (
                                                                <TextField {...params} label="Product *"
                                                                    variant="standard"
                                                                    helperText={producterrormsg}
                                                                    error={errProduct || producterror}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Autocomplete
                                                            sx={{
                                                                "& .MuiInputBase-input.Mui-disabled": {
                                                                    WebkitTextFillColor: "black",
                                                                },
                                                            }}
                                                            options={customerList}
                                                            value={{ name: customerName }}
                                                            getOptionLabel={(option) => option.name}
                                                            onInputChange={(event, newInputValue) => {
                                                                handleChangeCustomer(event, newInputValue);
                                                            }}
                                                            id="controllable-states-demo"
                                                            disableCloseOnSelect={false}
                                                            renderInput={(params) => (
                                                                <TextField {...params} label="Customer" variant="standard"
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3} sx={{ mb: 1 }}>
                                                    {showClasses ?
                                                        <>
                                                            <Grid item xs={12} sm={6}>
                                                                <Autocomplete
                                                                    sx={{
                                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                                            WebkitTextFillColor: "black",
                                                                        },
                                                                    }}
                                                                    options={classesList}
                                                                    value={{ name: className }}
                                                                    getOptionLabel={(option) => option.name}
                                                                    onInputChange={(event, newInputValue) => {
                                                                        handleChangeClass(event, newInputValue);
                                                                    }}
                                                                    id="controllable-states-demo"
                                                                    disableCloseOnSelect={false}
                                                                    renderInput={(params) => (
                                                                        <TextField {...params} label="Class" variant="standard"
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <FormField
                                                                    type="text"
                                                                    label="Description"
                                                                    name="item"
                                                                />
                                                            </Grid>
                                                        </>
                                                        :
                                                        <Grid item xs={12} sm={12}>
                                                            <FormField
                                                                type="text"
                                                                label="Description"
                                                                name="item"
                                                            />
                                                        </Grid>}
                                                </Grid>
                                                <Grid container spacing={3} sx={{ mb: 1 }}>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormField
                                                            type="text"
                                                            label="Unit"
                                                            name="unit"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <CurrencyFieldText
                                                            label="Price *"
                                                            name="price"
                                                            value={price}
                                                            fullWidth
                                                            error={priceerror}
                                                            onValueChange={(event) => {
                                                                setPrice(event.value)
                                                                setShowerror(true);
                                                            }}
                                                        />
                                                        <MDBox mt={0.75}>
                                                            <MDTypography
                                                                component="div"
                                                                variant="caption"
                                                                color="error"
                                                                fontWeight="regular">
                                                                {priceErrorMsg}
                                                            </MDTypography>
                                                        </MDBox>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3} sx={{ mb: 1 }}>
                                                    <Grid item xs={12} sm={6}
                                                    >
                                                        <NumberFieldText
                                                            label="Quantity *"
                                                            name="qty"
                                                            error={quantityerror}
                                                            value={quantity}
                                                            fullWidth
                                                            onValueChange={(event) => {
                                                                setQuantity(event.value)
                                                                setShowerrorqty(true);
                                                            }}
                                                        />
                                                        <MDBox mt={0.75}>
                                                            <MDTypography
                                                                component="div"
                                                                variant="caption"
                                                                color="error"
                                                                fontWeight="regular"
                                                            >
                                                                {quantityErrorMsg}
                                                            </MDTypography>
                                                        </MDBox>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <CurrencyFieldText
                                                            label="Total Price"
                                                            name="TotalPrice"
                                                            disabled
                                                            fullWidth
                                                            value={TotalPrice}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <MDBox>
                                                        <Field
                                                            as={FormControlLabel}
                                                            type="checkbox"
                                                            name="checkbox"
                                                            control={<Checkbox />}
                                                            label="Adjust Total Price"
                                                            checked={adjustInvoice}
                                                            onChange={handleChange}
                                                            sx={{ mt: 1.5 }}
                                                        />
                                                    </MDBox>
                                                </Grid>
                                            </MDBox>
                                            <MDBox mb={-2} mt={5} display="flex" justifyContent="end">
                                                <MDButton
                                                    disabled={isDisable}
                                                    variant="gradient"
                                                    color="light"
                                                    onClick={onClose}
                                                >
                                                    Cancel
                                                </MDButton>
                                                <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                    <MDButton
                                                        disabled={isDisable || errorButtonDis}
                                                        type="submit"
                                                        variant="gradient"
                                                        color="info"
                                                    >
                                                        {!productIds ? "Submit" : isDisable ? "Submitting..." : "Submit"}
                                                    </MDButton>
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>
                                    </MDBox>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </MDBox>
            }
        </>
    );
}

export default EditProductItemDialog;
