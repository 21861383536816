import Cookies from 'universal-cookie';
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import BasicLayout from "layouts/auth/components/BasicLayout";
import bgImage from "assets/images/backgroundimage.jpg";

const cookies = new Cookies();

function AccountantLogout() {
    const navigate = useNavigate();

    useEffect(() => {
        cookies.remove('user_access_token', { path: '/' });
        cookies.remove('user_permissions', { path: '/' });
        cookies.remove('user_profile_info', { path: '/' });
        cookies.remove('backup_remind_add_client', { path: '/' });
        cookies.remove('remind_add_client', { path: '/' });
        localStorage.removeItem('ShowBilling');
        navigate('/sign-in');
    }, []);
    return (
        <BasicLayout image={bgImage}>

        </BasicLayout>
    )
}

export default AccountantLogout;
