import * as React from "react";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { Link, useNavigate } from 'react-router-dom';
import MDBox from "components/MDBox";
import Box from "@mui/material/Box";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDSnackbar from "components/MDSnackbar";
import DashboardLayout from "../../clientuser/user/components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../clientuser/user/components/Navbars/DashboardNavbar";
import { NavLink, useLocation } from "react-router-dom";
import DataTable from "../../components/Tables/DataTable";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import { useAPICall } from "../../../utils/useapicall";
import { GetuserClientList, EditBusinesss, DeleteBusinesss } from "../../../services/clientservices";
import UserHoc from 'hoc/userHoc';
import { canAccess } from "helpers";
import ConnectToQuickBooksOnlineButton from "./components/QBOButton";
import Cookies from 'universal-cookie';
import { SITE_URL } from "helpers/config";
import { eventBus } from 'utils/eventbus';
import { useSearchParams } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Loader from "layouts/accountants/user/components/imgLoader/loader";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import AddBusinessDialog from "./addBusiness";
import EditBusinessDialog from "./editBusiness";
import DataTable1 from "components/NewTable/table";


function UserClientlist() {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const action = { route: "", viewtooltip: "View", edittooltip: "Edit", deletetooltip: "Delete", addowner: "Add Owner" };
    const [adminList, setAdminList] = useState([]);
    const [alert, setAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tableEmpty, setTableEmpty] = useState("");
    const [search, setSearch] = useState('');
    const [clientId, setClientId] = useState('');
    const [openEditClient, setOpenEditClient] = useState(true);
    const [openEditclientPopup, setOpenEditclientPopup] = useState(false);
    const [accountantId, setAccountantId] = useState();
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [ownerName, setOwnerName] = useState('');
    const [isNotOwner, setIsNotOwner] = useState(true);
    const [qboState, setQboState] = useState('');
    const [qboClientId, setQboClientId] = useState();
    const [businessName, setBusinessName] = useState('');
    const [showQboErrorSnackbar, setShowQboErrorSnackbar] = useState(false);
    const [showQboSuccessSnackbar, setShowQboSuccessSnackbar] = useState(false);
    const [openAddOwner, setOpenAddOwner] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [showAddSnackbar, setShowAddSnackbar] = useState(false);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [openNewclientPopup, setOpenNewclientPopup] = useState(false);
    const [addErrorMessage, setAddErrorMessage] = useState('');
    const [qboErrorMessage, setQboErrorMessage] = useState('');
    const [qboSuccessMessage, setQboSuccessMessage] = useState('');
    const [tableHeight, setTableHeight] = useState({ marginTop: '8px' })
    const [profileInfo, setProfileInfo] = useState(cookies.get('user_profile_info'));
    const [loaderClass, setLoaderClass] = useState({});
    const [loadingCircul, setLoadingCircul] = useState(false);
    const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetuserClientList, { onLoad: false });
    const [{ isLoading: isEditBusinessLoading, isError: isEditBusinessError, response: EditBusinessResponse }, EditBusinessAPICall] = useAPICall(EditBusinesss, { onLoad: false });
    const { pathname } = useLocation();
    const [{ isLoading: isDeleteBusinessLoading, isError: isDeleteBusinessError, response: DeleteBusinessResponse }, DeleteBusinessAPICall] = useAPICall(DeleteBusinesss, { onLoad: false });

    eventBus.$on('onclickchangePages', (data) => OnclickchangePages(data));
    function OnclickchangePages(data) {
        setLoading(true);
        const dataStr = data.toString() + 0;
        localStorage.setItem("offset", Number(dataStr))
        setAPICall({
            payload: [{ "keyword": `${search}`, 'offset': Number(dataStr), "businessView": 'client' }]
        });
    }

    useEffect(() => {
        if (isDeleteBusinessLoading) {
            return () => null;
        }
        if (isDeleteBusinessError) {
            setAddErrorMessage("Failed to Remove Business")
            setShowErrorSnackbar(true);
            setTimeout(() => {
                setShowErrorSnackbar(false);
            }, 3000);
            setLoadingCircul(false);
            return () => null;
        }
        if (DeleteBusinessResponse && DeleteBusinessResponse.data.isSuccess === true) {
            const ThisOffsetuse = localStorage.getItem("offset");
            setLoadingCircul(true);
            setQboSuccessMessage("Business Removed Successfully");
            setShowAddSnackbar(true);
            setTimeout(() => {
                setShowAddSnackbar(false);
            }, 3000);
            setAPICall({
                payload: [{ "keyword": `${search}`, "businessView": 'client', 'offset': ThisOffsetuse }]
            })
        }
    }, [DeleteBusinessResponse]);

    useEffect(() => {
        if (isEditBusinessLoading) {
            return () => null;
        }
        if (isEditBusinessError) {
            setAddErrorMessage("Failed to Remove Business")
            setShowErrorSnackbar(true);
            setTimeout(() => {
                setShowErrorSnackbar(false);
            }, 3000);
            setLoadingCircul(false);
            return () => null;
        }
        if (EditBusinessResponse && EditBusinessResponse.data.isSuccess === true) {
            const ThisOffsetuse = localStorage.getItem("offset");
            setLoadingCircul(true);
            setQboSuccessMessage("Business Removed Successfully");
            setShowAddSnackbar(true);
            setTimeout(() => {
                setShowAddSnackbar(false);
            }, 3000);
            setAPICall({
                payload: [{ "keyword": `${search}`, "businessView": 'client', 'offset': ThisOffsetuse }]
            })
        }
    }, [EditBusinessResponse]);


    useEffect(async () => {
        const queryParameters = new URLSearchParams(window.location.search)
        const keyword = queryParameters.get("keyword");
        const ThisOffsetuse = localStorage.getItem("offset");
        if (keyword) {
            setSearch(keyword);
            setLoading(true);
        } else {
            setLoading(true);
            await setAPICall({
                payload: [{ "keyword": `${search}`, "businessView": `client`, 'offset': ThisOffsetuse }]
            })
        }
    }, []);

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const keyword = queryParameters.get("keyword");
        if (!keyword) {
            window.history.pushState('data', 'title', `${pathname}`);
        }
    }, [search]);

    useEffect(() => {
        eventBus.$emit("OnSetBlur", loading);
        loading === true ? setLoaderClass({ opacity: '0.6', borderRadius: '13px' }) : setLoaderClass({})
    }, [loading]);

    var resource = '';
    var actions = '';
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '0',
        boxShadow: 24,
        p: 4,
    };
    useEffect(() => {
        setProfileInfo(cookies.get('user_profile_info'))
    }, []);

    eventBus.$on('AddnewBusinessFailed', (data) => AddnewBusinessFailed(data));
    function AddnewBusinessFailed(data) {
        setAddErrorMessage("Failed to add new Business")
        setShowErrorSnackbar(true);
        setTimeout(() => {
            setShowErrorSnackbar(false);
        }, 3000);
    }

    eventBus.$on('EditInventoryUnitSchemaFailed', (data) => EditInventoryUnitSchemaFailed(data));
    function EditInventoryUnitSchemaFailed(data) {
        setAddErrorMessage("Failed to Update Business")
        setShowErrorSnackbar(true);
        setTimeout(() => {
            setShowErrorSnackbar(false);
        }, 3000);
    }

    eventBus.$on('AddnewBusinessComplete', (data) => AddnewBusinessComplete(data));
    function AddnewBusinessComplete(data) {
        setQboSuccessMessage("Business Created Successfully");
        setShowAddSnackbar(true);
        setTimeout(() => {
            setShowAddSnackbar(false);
        }, 3000);
    }

    eventBus.$on('EditInventoryUnitSchemaComplete', (data) => EditInventoryUnitSchemaComplete(data));
    function EditInventoryUnitSchemaComplete(data) {
        setQboSuccessMessage("Business Updated Successfully");
        setShowAddSnackbar(true);
        setTimeout(() => {
            setShowAddSnackbar(false);
        }, 3000);
    }


    eventBus.$on('closeQboWindowApiCall', (data) => closeQboWindowApi(data));
    function closeQboWindowApi(data) {
        const ThisOffsetuse = localStorage.getItem("offset");
        setAPICall({
            payload: [{ "keyword": `${search}`, 'offset': ThisOffsetuse }]
        })
    }

    eventBus.$on('errorInAddOwner', (data) => errorInAddOwner(data));
    function errorInAddOwner(data) {
        setShowErrorSnackbar(true);
        setAddErrorMessage(data);
    }

    eventBus.$on('successInAddOwner', (data) => successInAddOwner(data));
    function successInAddOwner(data) {
        const ThisOffsetuse = localStorage.getItem("offset");
        setShowAddSnackbar(true);
        setTimeout(() => {
            setShowAddSnackbar(false);
        }, 10000);
        setOpenAddOwner(false);
        setLoading(true);
        setAPICall({
            payload: [{ "keyword": `${search}`, 'offset': ThisOffsetuse }]
        })
    }

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const status = queryParameters.get("status");
        const message = queryParameters.get("message");
        const clientId = queryParameters.get("clientId");
        setQboErrorMessage(message);
        setQboSuccessMessage(message);
        setQboState(status);
        setQboClientId(clientId);
        if (status === 'Failed') {
            const param = searchParams.get('status');
            if (param) {
                searchParams.delete('status');
                searchParams.delete('message');
                searchParams.delete('clientId');
                setSearchParams(searchParams);
            }
            setShowQboErrorSnackbar(true);
            // history.pushState(null, ``, `${SITE_URL}/accountant/client/list`);
            setTimeout(() => {
                setShowQboErrorSnackbar(false);
            }, 10000);
        }
    }, [])

    const warningWithConfirmMessage = () => {
        setAlert(true);
    };

    const successDelete = async () => {
        setLoadingCircul(true);
        console.log("delete buinesssss")
        await DeleteBusinessAPICall({
            payload: [
                {
                    "bussinessId": `${clientId}`
                }
            ]
        })
        setAlert(false);
    };

    const hideAlert = () => {
        setAlert(false);
    };


    useEffect(() => {
        const ThisOffsetuse = localStorage.getItem("offset");
        const timeOutId = setTimeout(async () =>
            await setAPICall({
                payload: [{ "keyword": `${search}`, "businessView": `client`, 'offset': ThisOffsetuse }]
            }), 500);
        return () => clearTimeout(timeOutId);
    }, [search]);


    useEffect(() => {
        if (isLoading) {
            return () => null;
        }
        if (isError) {
            setLoading(false);
            setLoadingCircul(false);
            response?.code === "DATA401" ? navigate('/user/logout') : console.log("somting else");
            return () => null;
        }
        if (response && response.data.isSuccess === true) {
            setTableEmpty(response?.data?.data?.totalRecords);
            const totalHeight = `${18 - response?.data?.data?.totalRecords * 2}%`
            setTableHeight(response?.data?.data?.totalRecords > 4 ? { marginTop: '8px' } : { marginTop: '8px', marginBottom: totalHeight })
            const data = response.data.data.records.map((prop, key) => {
                const Createdate = new Date(prop.createdAt);

                qboClientId === prop._id ? setOwnerName(prop.name) : ''
                return {
                    snum: (key + 1),
                    client:
                        (prop.accountant != null ?
                            <MDBox  >
                                <>
                                    <img height={20} width={20} style={{ marginBottom: '-4px' }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAADYElEQVR4nO2bz48MQRTHPzPIMg5LnFxIWBInNxEHy4UD1j+wszcuItixwYU6WDYuJIhMZJMNsVdHBxIOg7iJSCR2CEcuxK8goaWS2k2ldHdV79bsVPf0N3nZ3q4f8+ql3qvvq+oCN6wEDgKjwKkcyglgCKiRERVgDPgMRAWQT0BDjcuKKjAdgNKdkDsuRhgzGrWBJjARsLxI0LmpnvUy6c6pPq9P+5tAH2FD+vfHFJ3l86ThDrWkzoa0ijM5GLzEPged5bs3Wr39SZ2d1CrdIB845qhz08UNhFZJPucBrjo71ROlAShnQFQAF5Bcpm6wwvu95AIjFkIksnQWOuJ0rlsMMJyls7y6wHACa5Tvq1k6Cx1edRalAShnQFS6AGUMiMogiDWiDqisSv41EcfE5iv1tGWrm6tAW9V7HVNmY2JZpe5JZytEhs5mUgxgY2JZZThEAwyobeeNMWVpTCyrpDK3kghRMkFRUmHKXCDqRhC8AHwDxmPKNgNvPa0Acjt7kyedrRAZOvuq6n2xbK/7kIYnna0QGTobV0Y4nzADzCOp+Uo71BkQCkoDUM4AetoF6o45Q2HT4aoauC1nKGw67BUih+mwV4gcpsNeIXIYBJPijy12FNYAevw50ksGWAIcVjEp0vKUq8DqohugD7ibEkTfA+uKbIDrxoC/Ax+Md09dPpIUOTSAXIV+a3qfBZaqMvmd8w+tbMei0soufCbXiik/rc0KqxtUfdLKRcJF4yvROGwD1lNQHNcM8ApYRo9hixHsbgMr6DFMG0Z4CWy1Nap6TFk7KS6Uth94ZBjhp9pMTWw74jlj66TUHcnQJeCP0fYBsNa25keBSxZOMgi8M9rL/zekGaAVwK0PU1oLIGXSJW4ZRnhiMkEROOvzod8h4K/Wz6DvH+gkfOmnX5c514kf6BSy6LddRfw1MWWjWj8yNZ5DQyuQ10lCw1FH/VYpni/rPYzJ+O5p/cgbcXM4oBW0A7wgtddRv37NAFKm1NmkPE+8pr3/Za4ENXWFbLbCZGBGWG7k9Gn6nXFYSi/HNWzEnMU3A2B/s/IsQT+THcppfyVl8FNqy+w/VNR10iiHEscOdwGPtTrPgd22qVZRkVJ3hzxI0obNHq2OpPvOqKnAKA87us0CF3pYslMF0UT8A7bRGYtXAaNzAAAAAElFTkSuQmCC"></img>
                                    <Link to={`/client/document/${prop._id}`}>
                                        <MDTypography
                                            variant="caption" fontWeight="regular" fontSize="0.875rem"
                                            color="info" ml={1}
                                        >
                                            {prop.name}
                                        </MDTypography>
                                    </Link>
                                </>
                            </MDBox>
                            : prop.name
                        ),
                    doctoreview: prop.toReview ? prop.toReview : 0,
                    unmappedvendors: prop.unmappedVendor ? prop.unmappedVendor : 0,
                    clientapproval: prop.askClient ? prop.askClient : 0,
                    yourapproval: prop.approved ? prop.approved : 0,
                    pendingapproval: prop.pendingApproval ? prop.pendingApproval : 0,
                    unreconciled: "-",
                    switch: (
                        prop.ownerAssigned === true ?
                            prop.isBlocked ?
                                <MDBox>
                                    <MDTypography
                                        variant="caption" fontWeight="regular" fontSize="0.875rem" color="error"
                                    >  Inactive
                                    </MDTypography>
                                </MDBox>
                                :
                                <MDBox>
                                    <MDTypography
                                        variant="caption" fontWeight="regular" fontSize="0.875rem" color="success"
                                    >
                                        Active
                                    </MDTypography>
                                </MDBox> : <MDBox>
                                <MDTypography
                                    variant="caption" fontWeight="regular" fontSize="0.875rem" color="warning"
                                >  Owner Not Assigned
                                </MDTypography>
                            </MDBox>
                    ),
                    action: (
                        <MDBox display="flex" justifyContent="center">
                            <MDTypography
                                variant="body2"
                                color="secondary"
                                sx={{ marginLeft: "-4px", fontSize: "1.20rem", cursor: 'pointer' }}
                            >
                                <Tooltip title={action.edittooltip} placement="top"
                                    onClick={(value) => {
                                        setClientId(prop._id);
                                        setOpenEditclientPopup(true);
                                    }}
                                >
                                    <Icon>edit</Icon>
                                </Tooltip>
                            </MDTypography>
                            {prop.accountant == null ?
                                <MDTypography variant="body2" color="secondary" sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: 'pointer' }}>
                                    <Tooltip title={action.deletetooltip} placement="top"
                                        onClick={(value) => {
                                            setBusinessName(prop?.name);
                                            setClientId(prop._id);
                                            setAlert(true);
                                        }}>
                                        <Icon>delete</Icon>
                                    </Tooltip>
                                </MDTypography >
                                : ''}

                        </MDBox>
                        // <MDBox display="flex">
                        //     <Link to={`/client/client/${prop._id}/edit-client`}>
                        //         <MDTypography
                        //             variant="body2"
                        //             color="secondary"
                        //             sx={{ marginLeft: "-4px", fontSize: "1.20rem" }}
                        //         >
                        //             <Tooltip title={action.viewtooltip} placement="top">
                        //                 <Icon>visibilityIcon</Icon>
                        //             </Tooltip>
                        //         </MDTypography>
                        //     </Link>
                        // </MDBox>
                    )
                };
            }
            );
            setAdminList(data);
            setLoading(false);
            setLoadingCircul(false);
        }
    }, [response]);


    const dataTableData = {
        columns: [
            {
                Header: "Business Name",
                accessor: "client",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "To Review",
                accessor: "doctoreview",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Unmapped Vendors", accessor: "unmappedvendors",
                Cell: ({ value }) => <DefaultCell value={value} />
            },
            {
                Header: "Client Approval", accessor: "clientapproval",
                Cell: ({ value }) => <DefaultCell value={value} />
            },
            {
                Header: "Your Approval", accessor: "yourapproval",
                Cell: ({ value }) => <DefaultCell value={value} />
            },
            {
                Header: "Pending Approval", accessor: "pendingapproval",
                Cell: ({ value }) => <DefaultCell value={value} />
            },
            {
                Header: "Unreconciled Statements", accessor: "unreconciled",
                Cell: ({ value }) => <DefaultCell value={value} />
            },
            // {
            //     Header: "Status",
            //     accessor: "switch",
            //     Cell: ({ value }) => <DefaultCell value={value} />,
            // },
            {
                Header: "Action", accessor: "action",
                disableSortBy: true,
                Cell: ({ value }) => <DefaultCell value={value} />
            },
        ],

        rows: adminList
    };


    useEffect(() => {
        if (qboState === 'Success') {
            setShowQboSuccessSnackbar(true);
            const param = searchParams.get('status');
            if (param) {
                searchParams.delete('status');
                searchParams.delete('message');
                searchParams.delete('clientId');
                setSearchParams(searchParams);
            }
            setTimeout(() => {
                setOpenAddOwner(true);
            }, 1000);
            setTimeout(() => {
                setShowQboSuccessSnackbar(false);
            }, 3000);
        }
    }, [qboState]);

    const handleCreateBusiness = async () => {
        const ThisOffsetuse = localStorage.getItem("offset");
        setOpenNewclientPopup(false);
        setLoadingCircul(true);
        await setAPICall({
            payload: [{ "keyword": `${search}`, "businessView": `client`, 'offset': ThisOffsetuse }]
        })
    }

    const handleEditBusiness = async () => {
        const ThisOffsetuse = localStorage.getItem("offset");
        setOpenEditclientPopup(false);
        setLoadingCircul(true);
        await setAPICall({
            payload: [{ "keyword": `${search}`, "businessView": `client`, 'offset': ThisOffsetuse }]
        })
    }


    const handleSearch = async (event) => {
        localStorage.setItem("offset", 0)
        setSearch(event.target.value);
        window.history.pushState('data', 'title', `${pathname}?keyword=${encodeURIComponent(event.target.value)}`);
        setLoadingCircul(true);
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
        }
    }

    const ReturnUrl = encodeURI(`${SITE_URL}/qbo-cb`);
    return (
        <>
            <DashboardLayout >
                <DashboardNavbar />
                <MDBox my={4} >
                    <Card sx={loaderClass}>
                        <MDBox pt={3} px={2} sx={{ pr: 3, mb: 2 }} component="form" display="flex" justifyContent="space-between" alignItems="center">
                            <MDTypography variant="h5" fontWeight="medium">
                                Business
                            </MDTypography>
                            <MDBox px={2} display="flex" >
                                <MDBox sx={{ pr: 3, mb: 2 }}>
                                    <MDInput type="search" label="Search Business" onKeyDown={handleKeyDown} variant="standard" onChange={handleSearch} />
                                </MDBox>
                                <MDBox>
                                    {canAccess(actions = 'create', resource = 'clients') &&
                                        <MDButton
                                            variant="gradient"
                                            color="info"
                                            onClick={() => setOpenNewclientPopup(true)}
                                        >  Add New Business
                                        </MDButton>
                                    }
                                </MDBox>
                            </MDBox>
                        </MDBox>
                        <MDBox>
                            {alert}
                        </MDBox>
                        <MDBox>
                            {loadingCircul && (
                                <CircularProgress
                                    size={50}
                                    sx={{
                                        color: green[500],
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </MDBox>
                        <Box my={5} style={tableHeight} >
                            <DataTable1 table={dataTableData} entriesPerPage={false} pagination={true} showTotalEntries={true} response={tableEmpty} showCheck={false} ClientHeigth={true} />
                        </Box>
                        <MDBox>
                            <MDSnackbar
                                color="success"
                                icon="delete"
                                title="Success"
                                // autoHideDuration={3000}
                                content="Client Deleted Successfully."
                                open={showSnackbar}
                                close={() => setShowSnackbar(false)}
                            />
                        </MDBox>
                        {/* <MDBox>
                            <MDSnackbar
                                color="success"
                                icon="done"
                                title="Success"
                                content="Owner assigned successfully."
                                open={showAddSnackbar}
                                close={() => setShowAddSnackbar(false)}
                            />
                        </MDBox> */}
                        <MDBox>
                            <MDSnackbar
                                color="success"
                                icon="done"
                                title="Success"
                                content={qboSuccessMessage}
                                open={showAddSnackbar}
                                close={() => setShowAddSnackbar(false)}
                            />
                        </MDBox>
                        <MDBox>
                            <MDSnackbar
                                color="error"
                                icon="warning"
                                title="Failed"
                                content={addErrorMessage}
                                open={showErrorSnackbar}
                                close={() => setShowErrorSnackbar(false)}
                            />
                        </MDBox>
                        <MDBox>
                            <MDSnackbar
                                color="error"
                                icon="warning"
                                title="Failed"
                                content={qboErrorMessage}
                                open={showQboErrorSnackbar}
                                close={() => setShowQboErrorSnackbar(false)}
                            />
                        </MDBox>
                        <MDBox>
                            <Dialog
                                open={alert}
                                onClose={hideAlert}
                                aria-labelledby="alert-dialog-title"
                                sx={{
                                    "& .MuiDialog-container": {
                                        "& .MuiPaper-root": {
                                            width: "100%",
                                            maxWidth: "450px",
                                        },
                                    },
                                }}
                            >
                                <DialogContent sx={{ overflowY: 'hidden' }}>
                                    <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                                        <h3>Are you sure?</h3>
                                    </DialogContentText>
                                    <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                                        <MDTypography
                                            variant="body2"
                                            color="secondary"
                                            sx={{ fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 4 }}
                                        >
                                            Once you delete this Business there is no way of getting any of the documents or data back.
                                        </MDTypography>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions sx={{ justifyContent: "center", mt: 2 }} >
                                    <MDButton
                                        variant="gradient"
                                        color="success"
                                        onClick={successDelete} >
                                        Yes, delete it!
                                    </MDButton>
                                    <MDButton
                                        variant="gradient"
                                        color="error"
                                        onClick={hideAlert}
                                    >Cancel
                                    </MDButton>
                                </DialogActions>
                            </Dialog>
                        </MDBox>
                        <MDBox>
                            <Dialog
                                open={openAddOwner}
                                onClose={() => setOpenAddOwner(false)}
                                aria-labelledby="alert-dialog-title"
                                sx={{
                                    "& .MuiDialog-container": {
                                        "& .MuiPaper-root": {
                                            width: "100%",
                                            maxWidth: "500px",
                                            // height: "100%"
                                        },
                                    },
                                }}
                            >   <DialogTitle sx={{
                                justifyContent: "center",
                                display: 'flex'
                            }}>Add Owner For - {ownerName}</DialogTitle>
                                {/* <DialogContent>
                                    <AddOwnerDetailsDialog businessId={accountantId} />
                                </DialogContent> */}
                            </Dialog>
                        </MDBox>
                        <MDBox>
                            <Dialog
                                // fullScreen
                                open={openNewclientPopup}
                                onClose={() => setOpenNewclientPopup(false)}
                                sx={{
                                    "& .MuiDialog-container": {
                                        "& .MuiPaper-root": {
                                            width: "100%",
                                            maxWidth: "570px",
                                        },
                                    },
                                }}
                            >
                                <DialogTitle sx={{
                                    justifyContent: "center",
                                    display: 'flex'
                                }}>Add Business</DialogTitle>
                                <DialogContent>
                                    <AddBusinessDialog onClose={handleCreateBusiness} />
                                </DialogContent>
                            </Dialog>
                        </MDBox>
                        <MDBox>
                            <Dialog
                                // fullScreen
                                open={openEditclientPopup}
                                onClose={() => setOpenEditclientPopup(false)}
                                sx={{
                                    "& .MuiDialog-container": {
                                        "& .MuiPaper-root": {
                                            width: "100%",
                                            maxWidth: "570px",
                                            minHeight: "30%"
                                        },
                                    },
                                }}
                            >
                                <DialogTitle sx={{
                                    justifyContent: "center",
                                    display: 'flex'
                                }}>
                                    Edit Business
                                </DialogTitle>
                                <DialogContent>
                                    <EditBusinessDialog bussinessId={clientId} onClose={handleEditBusiness} />
                                </DialogContent>
                            </Dialog>
                        </MDBox>
                    </Card>
                    <MDBox display="flex" justifyContent="center" >
                        {loading && <Loader />}
                    </MDBox>
                </MDBox>
                {/* <Modal
                    open={openNewclientPopup}
                    onClose={() => setOpenNewclientPopup(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}  >
                        <MDTypography display="flex" justifyContent="center">
                            Add New Client
                        </MDTypography>
                        <MDBox sx={{ pl: 0, pt: 5 }} display="flex" justifyContent="space-around">
                            <MDBox>
                                {canAccess(actions = 'create', resource = 'clients') &&
                                    <MDButton component={NavLink} to="/accountant/client/new-client"
                                        variant="gradient"
                                        color="info"
                                    >  Through Paynthr
                                    </MDButton>
                                }
                            </MDBox>
                            <MDBox>
                                {canAccess(actions = 'create', resource = 'clients') &&
                                    <ConnectToQuickBooksOnlineButton buttonType={'custom'} accountantId={encodeURI(profileInfo?.user?._id)} accountantDomain={encodeURI(profileInfo.user.domainKey)} accountantReturnUrl={ReturnUrl} />
                                }
                            </MDBox>
                        </MDBox>
                    </Box>
                </Modal> */}
            </DashboardLayout >
        </>
    );
}

export default UserHoc(UserClientlist);