import PropTypes from "prop-types";
import { ErrorMessage, Field } from "formik";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import InputAdornment from "@mui/material/InputAdornment";
import { useState } from "react";
import { useEffect } from "react";

function FormField({ label, dateMismatch = false, name, icon, editable = false, ...rest }) {
  const [dottedStyle, setDottedStyle] = useState({});
  useEffect(() => {
    dateMismatch === true ? editable ? setDottedStyle({ borderBottom: `2px solid red` }) : setDottedStyle({}) : editable ? setDottedStyle({ borderBottom: `dotted 0.8px` }) : setDottedStyle({});
  }, [editable, dateMismatch])
  return (
    <MDBox mb={1.5}>
      {dateMismatch ?
        <Field {...rest} name={name} as={MDInput} variant="standard" style={dottedStyle} label={label} fullWidth autoComplete="off"
          InputLabelProps={{
            style: { color: 'red' },
          }}
          InputProps={{
            disableUnderline: editable,
            startAdornment: (
              <InputAdornment position="start">
                <MDTypography fontSize='0.8rem'>
                  {icon}
                </MDTypography>
              </InputAdornment>
            ),

          }} />
        :
        <Field {...rest} name={name} as={MDInput} variant="standard" style={dottedStyle} label={label} fullWidth autoComplete="off"
          InputProps={{
            disableUnderline: editable,
            startAdornment: (
              <InputAdornment position="start">
                <MDTypography fontSize='0.8rem'>
                  {icon}
                </MDTypography>
              </InputAdornment>
            ),

          }} />}
      <MDBox mt={0.75}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          <ErrorMessage name={name} />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default FormField;
