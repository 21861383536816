import { Card, Grid, Icon, Paper, Divider, Button } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import UserHoc from "hoc/userHoc";
import DashboardLayout from "layouts/components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/components/Navbars/DashboardNavbar";
import visaLogo from 'assets/images/logos/visa.png'
import MDButton from "components/MDButton";
import DataTable from "./Tables/DataTable/index";
import dataTableData from "./datatable";
import DataTableLog from "layouts/admin/components/Tables/DataTable/index.js"
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SetupForm from './SetupForm.js';
import { useAPICall } from "utils/useapicall";
import { GetPaymentagreement, GetCardDetails, GetUserProfile, GetAccountantBilling, GetClientLogsList } from "services/userservices";
import { useEffect } from "react";
import { useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { green, yellow } from '@mui/material/colors';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDSocialButton from "components/MDSocialButton";
import BillingCard from "./BillingCard";
import CardImg from "./CardImg";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import Bill from "layouts/pages/account/billing/components/Bill";
import { Box } from "@mui/system";
import { eventBus } from "utils/eventbus";
import MDInput from "components/MDInput";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import LoaderProfile from "../components/imgLoader/LoaderProfile";
import MDSnackbar from "components/MDSnackbar";
const stripePromise = loadStripe('pk_test_CKGIroRfRgrHtmai0yPyjRiX00gm2IQuo3');

function Billing() {
    const navigate = useNavigate();
    const [clientSecret, setClientSecret] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [showClientLogs, setShowClientLogs] = useState(false);
    const [showBillingTable, setShowBillingTable] = useState(false);
    const [lastDigit, setLastDigit] = useState('');
    const [cardBrand, setCardBrand] = useState('');
    const [country, setCountry] = useState('');
    const [expmonth, setExpmonth] = useState('');
    const [expyear, setExpyear] = useState('');
    const [type, setType] = useState('');
    const [billingList, setBillingList] = useState([]);
    const [invoiceLogId, setInvoiceLogId] = useState('');
    const [clientLogsList, setClientLogsList] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [newCardText, setNewCardText] = useState(false);
    const [nextBillAmount, setNextBillAmount] = useState('');
    const [nextBillingDate, setNextBillingDate] = useState('');
    const [cardHolderName, setCardHolderName] = useState('');
    const [totalClients, settotalClients] = useState('');
    const [loadingLogs, setLoadingLogs] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetPaymentagreement, { onLoad: false });
    const [{ isLoading: isDetailsLoading, isError: isDetailsError, response: DetailsResponse }, DetailsUserAPICall] = useAPICall(GetCardDetails, { onLoad: false });
    const [{ isError: isBillingError, isLoading: isBillingLoading, response: Billingresponse }, AccountantBillingAPICall] = useAPICall(GetAccountantBilling, { onLoad: false });
    const [{ isLoading: isGetUserLoading, isError: isGetUserError, response: userDetailsDataResponse }, getUserDteailsAPICall] = useAPICall(GetUserProfile, { onLoad: false });
    const [{ isLoading: isGetClientLogsLoading, isError: isGetClientLogsError, response: userLogsDataResponse }, GetClientLogsAPICall] = useAPICall(GetClientLogsList, { onLoad: false });

    const appearance = {
        theme: 'stripe'
    };

    useEffect(() => {
        eventBus.$emit("OnSetBlur", loading);
    }, [loading])

    useEffect(() => {
        if (isGetClientLogsLoading) {
            return () => null;
        }

        if (isGetClientLogsError) {
            setErrorMessage(userLogsDataResponse?.data?.message);
            setLoadingLogs(false);
            setShowClientLogs(false);
            setShowErrorSnackbar(true);
            setTimeout(() => {
                setShowErrorSnackbar(false);
            }, 3000);
            userLogsDataresponse?.code === "DATA401" ? navigate('/admin/logout') : console.log("somting else");
            setLoading(false);
            return () => null;
        }

        if (userLogsDataResponse && userLogsDataResponse.data.isSuccess === true) {
            const data = userLogsDataResponse?.data?.data?.records.map((prop, key) => {
                return {
                    clientname: prop?.item?.name ? prop?.item?.name : "-",
                    startdate: prop?.billingPeriodStartDate ? prop?.billingPeriodStartDate : '-',
                    enddate: prop?.billingPeriodEndDate ? prop?.billingPeriodEndDate : "-",
                    totaldays: prop?.billableDays ? prop?.billableDays : '-',
                    amount: prop?.amount ? `$${prop?.amount}` : '-'
                };
            }
            );
            setLoadingLogs(false);
            setClientLogsList(data);
        }
    }, [userLogsDataResponse]);

    useEffect(() => {
        if (isGetUserLoading) {
            return () => null;
        }

        if (isGetUserError) {
            userDetailsDataResponse?.code === "DATA401" ? navigate('/admin/logout') : console.log("somting else");
            return () => null;
        }

        if (userDetailsDataResponse && userDetailsDataResponse.data.isSuccess === true) {
            const BillingDetails = userDetailsDataResponse?.data?.data?.user;
            setNextBillAmount(BillingDetails?.subscriptions[0]?.nextBillAmount);
            setNextBillingDate(BillingDetails?.subscriptions[0]?.nextBillingDate);
            settotalClients(BillingDetails?.totalClients ? BillingDetails?.totalClients : '0');
            // setNextBillAmount(BillingDetails?.nextBillAmount);
            // setNextBillingDate(BillingDetails?.nextBillingDate);
            // settotalClients(BillingDetails?.totalClients ? BillingDetails?.totalClients : '0');
        }
    }, [userDetailsDataResponse]);

    useEffect(() => {
        if (isBillingLoading) {
            return () => null;
        }
        if (isBillingError) {
            setLoading(false);
            return () => null;
        }
        if (Billingresponse && Billingresponse.data.isSuccess === true) {
            const data = Billingresponse?.data?.data?.records.map((prop, key) => {
                return {
                    invoice: prop?.invoiceId ? prop?.invoiceId : "-",
                    billingdate: prop?.periodStartDate ? prop?.periodStartDate : '-',
                    amount: prop?.total ? `$${prop?.total}` : "-",
                    clients: (prop?.invoicedItems >= 1 ?
                        <>
                            {setShowBillingTable(true)}
                            < MDBox >
                                <MDTypography
                                    variant="caption" fontWeight="regular" fontSize="0.875rem" color="info"
                                    sx={{ cursor: "pointer" }}
                                    onClick={async (value) => {
                                        setLoadingLogs(true);
                                        setShowClientLogs(true);
                                        setInvoiceLogId(prop?.invoiceId);
                                        GetClientLogsAPICall({
                                            payload: [{ "accuntantId": `${prop._id}` }]
                                        });
                                    }}
                                >
                                    {prop?.invoicedItems}
                                </MDTypography>
                            </MDBox >
                        </>
                        : prop?.invoicedItems
                    ),
                    paymentStatus: prop?.paymentStatus ? prop?.paymentStatus : '-',
                    clientsShow: prop?.invoicedItems
                };
            }
            );
            setBillingList(data)
            setLoading(false);
        }
    }, [Billingresponse]);

    useEffect(async () => {
        AccountantBillingAPICall({
            payload: []
        })
        await DetailsUserAPICall({
            payload: []
        });
        await setAPICall({
            payload: []
        });
        getUserDteailsAPICall({
            payload: []
        })
        setLoading(true);
    }, [])

    useEffect(() => {
        if (isLoading) {
            return () => null;
        }
        if (isError) {
            setLoading(false);
            return () => null;
        }
        if (response && response.data.isSuccess === true) {
            setLoading(false);
            setClientSecret(response?.data?.data?.clientSecret);
        }
    }, [response]);

    useEffect(() => {
        openForm === true && response?.data?.isSuccess === true ?
            setLoading(false)
            :
            setOpenForm(false)
    }, [response, openForm, showPaymentForm])

    useEffect(() => {
        if (isDetailsLoading) {
            return () => null;
        }

        if (isDetailsError) {
            return () => null;
        }

        if (DetailsResponse && DetailsResponse.data.isSuccess === true) {
            setLastDigit(DetailsResponse?.data?.data?.card?.lastFourDigit);
            setCardBrand(DetailsResponse?.data?.data?.card?.brand);
            setType(DetailsResponse?.data?.data?.card?.type);
            setExpyear(DetailsResponse?.data?.data?.card?.exp_year);
            setExpmonth(DetailsResponse?.data?.data?.card?.exp_month);
            setCountry(DetailsResponse?.data?.data?.card?.country);
            setCardHolderName(DetailsResponse?.data?.data?.card?.cardHolderName ? DetailsResponse?.data?.data?.card?.cardHolderName : '---')
        }
    }, [DetailsResponse])

    const options = {
        // passing the SetupIntent's client secret
        clientSecret: `${clientSecret}`,
        // Fully customizable with appearance API.
        appearance: appearance,
    };

    const handleAddCard = () => {
        // setLoading(true);
        setOpenForm(true);
        setShowPaymentForm(true);
    }

    eventBus.$on('OnSubmitClick', (data) => OnSubmitClick(data));
    function OnSubmitClick(data) {
        setShowPaymentForm(false);
        setOpenForm(false);
        setNewCardText(false);
    }

    eventBus.$on('EditPaymentForm', (data) => EditPaymentForm(data));
    function EditPaymentForm(data) {
        setNewCardText(true);
        if (response?.data?.isSuccess === true) {
            setShowPaymentForm(true);
        }
    }

    const BillingTableData = {
        columns: [
            {
                Header: "Invoice",
                accessor: "invoice",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Billing Date",
                accessor: "billingdate",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Amount",
                accessor: "amount",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Clients",
                accessor: "clients",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Status",
                accessor: "paymentStatus",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
        ],

        rows: billingList,
    };

    const BillingTableData1 = {
        columns: [
            {
                Header: "Invoice",
                accessor: "invoice",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Billing Date",
                accessor: "billingdate",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Amount",
                accessor: "amount",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Clients",
                accessor: "clients",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Status",
                accessor: "paymentStatus",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
        ],

        rows: [],
    };


    const dataTableLogsData = {
        columns: [
            {
                Header: " Client Name",
                accessor: "clientname",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Start Date",
                accessor: "startdate",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "End Date",
                accessor: "enddate",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Total Days",
                accessor: "totaldays",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Amount",
                accessor: "amount",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
        ],

        rows: clientLogsList,
    };

    return (
        <>
            {
                loading ?
                    <MDBox display="flex" justifyContent="center" >
                        <LoaderProfile />
                    </MDBox> :
                    <DashboardLayout>
                        <DashboardNavbar />
                        <MDBox >
                            <MDBox mx={4}>
                                <MDBox lineHeight={0}>
                                    <MDTypography variant="h5"> Billing</MDTypography>
                                </MDBox>
                                <MDBox my={4} >
                                    <MDBox mb={3}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} lg={10}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} md={6} xl={3}>
                                                        <DefaultInfoCard
                                                            icon="payment"
                                                            title="Next payment"
                                                            description={nextBillingDate == null ? `on  -` : `on ${nextBillingDate}`}
                                                            value={nextBillAmount ? `$${nextBillAmount}` : `$0`}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6} xl={3}>
                                                        <DefaultInfoCard
                                                            icon="groups"
                                                            title="Total Clients"
                                                            description="Active"
                                                            value={totalClients ? totalClients : 0}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} xl={6} >
                                                        {DetailsResponse?.data?.data?.card === null ?
                                                            <MDBox >
                                                                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                                                                </MDBox>
                                                                <Card sx={{
                                                                    border: 1, borderColor: '#7239ea', borderStyle: 'dashed', width: `360px`, height: `224px`
                                                                }} >
                                                                    <MDBox
                                                                        component="li"
                                                                        display="flex"
                                                                        justifyContent="space-between"
                                                                        alignItems="flex-start"
                                                                        // bgColor={"#f8f5ff"}
                                                                        borderRadius="lg"
                                                                        p={2}
                                                                        mb={1}
                                                                        mt={1}
                                                                    >
                                                                        <MDBox width="100%" display="flex" flexDirection="column">
                                                                            <MDBox
                                                                                display="flex"
                                                                                justifyContent="center"
                                                                                alignItems={{ xs: "flex-start", sm: "center" }}
                                                                                flexDirection={{ xs: "column", sm: "row" }}
                                                                                mb={2}
                                                                            >
                                                                                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                                                                                    No Card Added Yet!
                                                                                </MDTypography>
                                                                            </MDBox>
                                                                            <MDButton
                                                                                variant="gradient"
                                                                                color="info"
                                                                                size="small"
                                                                                sx={{
                                                                                    mt: '24%', width: '45%', ml: '28%'
                                                                                }}

                                                                                onClick={handleAddCard}
                                                                            >
                                                                                &nbsp;Add New Card
                                                                            </MDButton>
                                                                        </MDBox>
                                                                    </MDBox>
                                                                </Card>
                                                            </MDBox>
                                                            :
                                                            <CardImg number={`************${lastDigit}`} holder={cardHolderName} expires={`${expmonth}/${expyear}`} cardBrand={cardBrand} editCard={true} />
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </MDBox>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12}>
                                            <Card>
                                                <MDBox pt={3} px={2} sx={{ pr: 3, mb: 2, mt: -1 }} component="form" display="flex" justifyContent="space-between" alignItems="center">
                                                    <MDTypography variant="h5" fontWeight="medium">
                                                        Billing history
                                                    </MDTypography>
                                                    <MDBox px={2} display="flex" >
                                                        <MDBox sx={{ pr: 3, mb: 2 }}>
                                                            {/* <MDInput type="search" label="Search" variant="standard" /> */}
                                                        </MDBox>
                                                    </MDBox>
                                                </MDBox>
                                                {BillingTableData?.rows.length === 0 ?
                                                    <MDBox my={2} mt={-0.5}>
                                                        <DataTable table={BillingTableData1} entriesPerPage={false} pagination={false} showTotalEntries={true} />
                                                        <MDTypography
                                                            variant="body2"
                                                            color="secondary"
                                                            sx={{ mb: 5, marginLeft: "43%", fontSize: "1.20rem" }}
                                                        >
                                                            No billing history found!
                                                        </MDTypography>
                                                    </MDBox>
                                                    :
                                                    <MDBox my={2} mt={-0.5}>
                                                        <DataTable table={BillingTableData} entriesPerPage={false} pagination={true} showTotalEntries={true} showCheckBoxs={false} />
                                                    </MDBox>
                                                }
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </MDBox>
                            </MDBox>
                            <MDBox>
                                <Dialog
                                    open={showPaymentForm}
                                    // onClose={() => setShowPaymentForm(false)}
                                    aria-labelledby="alert-dialog-title"
                                    sx={{
                                        "& .MuiDialog-container": {
                                            "& .MuiPaper-root": {
                                                width: "100%",
                                                height: 'auto',
                                                maxWidth: "600px",
                                                // maxHeight: "600px",
                                            },
                                        },
                                    }}
                                >
                                    <DialogTitle>
                                        {newCardText == true ? 'Edit Card Details' : 'Add New Card'}
                                    </DialogTitle>
                                    <Divider sx={{ mt: -1 }} />
                                    <DialogContent >
                                        <Elements stripe={stripePromise} options={options}>
                                            <SetupForm Secret={clientSecret} />
                                        </Elements>
                                    </DialogContent>
                                </Dialog>
                            </MDBox>
                            <MDBox>
                                <Dialog
                                    open={showClientLogs}
                                    onClose={() => setShowClientLogs(false)}
                                    aria-labelledby="alert-dialog-title"
                                    sx={{
                                        "& .MuiDialog-container": {
                                            "& .MuiPaper-root": {
                                                width: "100%",
                                                height: "auto",
                                                maxWidth: "1000px",
                                            },
                                        },
                                    }}
                                >
                                    <DialogTitle>
                                        Invoice #{invoiceLogId}: clients
                                    </DialogTitle>
                                    <Divider sx={{ mt: -1, mb: -2 }} />
                                    <DialogContent sx={{ mb: 2 }} >
                                        {loadingLogs &&
                                            <MDBox display="flex" justifyContent="center" >
                                                <LoaderProfile />
                                            </MDBox>}
                                        <MDBox my={5} sx={{ mt: 2 }}>
                                            <DataTableLog table={dataTableLogsData} entriesPerPage={false} pagination={false} showTotalEntries={true} />
                                        </MDBox>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => setShowClientLogs(false)} >
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </MDBox>
                            <MDBox>
                                <MDSnackbar
                                    color="error"
                                    icon="warning"
                                    title="Failed."
                                    content={errorMessage}
                                    // autoHideDuration={3000}
                                    open={showErrorSnackbar}
                                    close={() => setShowErrorSnackbar(false)}
                                />
                            </MDBox>
                        </MDBox>
                    </DashboardLayout>
            }
        </>
    )
}

export default UserHoc(Billing);