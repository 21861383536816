import * as Yup from "yup";

const AddNewVendor = Yup.object().shape({
    vendor: Yup.string()
        .trim()
        .required('Vendor name is required.')
        .min(3, 'Vendor name should be more than 3 characters.'),
    phone: Yup.string()
        .nullable()
        .min(10, "Mobile number is invalid")
        // .max(10, "Mobile Number is invalid")
        .matches(
            /^[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
            "Mobile number is not valid"
        ),
    accountno: Yup.string().matches(/^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/, "Account no must be a number type")
        .nullable()
        // .min(8, 'Account number must be more than 8 digits.')
        .max(17, 'Account number can’t be more than 17 digits.'),
    // .required("Account number is required."),
    address: Yup.string().trim().required("Address is required"),
    city: Yup.string().trim().required("City is required"),
    state: Yup.string().trim().required("State is required"),
    zip: Yup.string().matches(/^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/, "Zip code must be a number type")
        .nullable()
        .required("Zip Code is required")
        .min(5, 'Zip code must be 5 characters.')
        .max(5, 'Zip code must be 5 characters.'),
    // email: Yup.string()
    //     .email('Your email address is invalid')
    //     .required('Email address is required.'),
    // businessid: Yup.string().trim().required("Bussiness id is required")
    // .matches(/^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/, "Business id must be a number type"),    
    //     phone: Yup.string()
    //     .nullable()
    //     .min(10, "Mobile Number is invalid")
    //     .max(10, "Mobile Number is invalid")
    //     .required("Mobile Number is requried")
    //     .matches(
    //         /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    //         "Mobile Number is not valid"
    //     ),
    // net: Yup.string()        
    //     .required("Net terms is required"),

});

export default AddNewVendor