import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Grid from "@mui/material/Grid";
import Checkbox from '@mui/material/Checkbox';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import AddNewCustomer from './components/schemas/AddNewCustomer'
import FormField from "layouts/admin/accountants/components/FormField";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useAPICall } from "utils/useapicall";
import { AddCustomers } from "../../../../services/userservices";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";
import { eventBus } from "utils/eventbus";
import MDSnackbar from "components/MDSnackbar";

function AddCustomerDialog({ onClose, invoiceId, businessId }) {
    const [business_id, setBusiness] = useState(businessId);
    const [btnDisable, setBtnDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [trackPayment, setTrackPayment] = useState(false);
    const [trackPayment1, setTrackPayment1] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);

    let initialValues = {
        customer: "",
        address: "",
        email: "",
        phone: "",
        businessid: "",
        city: "",
        state: "",
        zip: ""
    };

    const [{ isLoading: isAddCustomerLoading, isError: isAddCustomerError, response: AddCustomerResponse }, AddCustomerListAPICall] = useAPICall(AddCustomers, { onLoad: false });

    useEffect(() => {
        if (isAddCustomerLoading) {
            return () => null;
        }
        if (isAddCustomerError) {
            setErrorMessage(AddCustomerResponse?.data?.message);
            setLoading(false);
            setBtnDisable(false);
            setShowErrorSnackbar(true);
            setTimeout(() => {
                setShowErrorSnackbar(false);
            }, 3000);
            return () => null;
        }
        if (AddCustomerResponse && AddCustomerResponse.data.isSuccess === true) {
            setLoading(false);
            setBtnDisable(false);
            eventBus.$emit("AddnewCustomerComplete", AddCustomerResponse?.data?.data);
            onClose();
        }
    }, [AddCustomerResponse]);

    const handleSubmit = async (values) => {
        setBtnDisable(true);
        !trackPayment1 ?
            await AddCustomerListAPICall({
                payload: [
                    {
                        "name": values?.customer,
                        "phone": values?.phone,
                        "email": values?.email,
                        "billingAddress": {
                            "line1": values?.address,
                            "city": values?.city,
                            "state": values?.state,
                            "zip": values?.zip,
                        },
                        "shippingAddress": {
                            "line1": values?.shippingAddress,
                            "city": values?.shippingcity,
                            "state": values?.shippingstate,
                            "zip": values?.shippingzip,
                        },
                        "clientId": `${business_id}`
                    }]
            })
            :
            await AddCustomerListAPICall({
                payload: [
                    {
                        "name": values?.customer,
                        "phone": values?.phone,
                        "email": values?.email,
                        "billingAddress": {
                            "line1": values?.address,
                            "city": values?.city,
                            "state": values?.state,
                            "zip": values?.zip,
                        },
                        "shippingAddress": {
                            "line1": values?.address,
                            "city": values?.city,
                            "state": values?.state,
                            "zip": values?.zip,
                        },
                        "clientId": `${business_id}`
                    }]
            })
    };
    const handleChange = () => {
        setTrackPayment(!trackPayment)
    }

    const handleChangeship = () => {
        setTrackPayment1(!trackPayment1)
    }

    return (
        <>{
            <>
                <MDBox mt={0} >
                    <Grid spacing={3}>
                        <Grid item xs={12}>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={AddNewCustomer}
                                onSubmit={handleSubmit}
                            >
                                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                                    <Form autoComplete="off">
                                        <MDBox p={3}>
                                            <MDBox>
                                                <MDBox mt={1.625}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Customer *"
                                                                name="customer"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <NumberFormat
                                                                customInput={TextField}
                                                                variant="standard"
                                                                type="tel"
                                                                name="phone"
                                                                fullWidth
                                                                value={values?.phone}
                                                                label="Phone Number"
                                                                sx={{
                                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                                        WebkitTextFillColor: "black",
                                                                    },
                                                                    mb: 1, mt: 0.5, fontSize: `0.823rem !important`
                                                                }}
                                                                format="(###) ###-####"
                                                                mask=""
                                                                onValueChange={value => setFieldValue('phone', value.formattedValue)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={6}>
                                                            <Grid item xs={12}>
                                                                <FormField
                                                                    type="email"
                                                                    label="Email"
                                                                    name="email"
                                                                />
                                                            </Grid>
                                                            <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                                                <ErrorMessage name="phone" />
                                                            </MDTypography>
                                                        </Grid>

                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={12}>
                                                            <MDBox>
                                                                <Field
                                                                    as={FormControlLabel}
                                                                    type="checkbox"
                                                                    name="checkbox"
                                                                    control={<Checkbox />}
                                                                    label="Address"
                                                                    checked={trackPayment}
                                                                    onChange={handleChange}
                                                                    sx={{ mt: 1.5 }}
                                                                />
                                                            </MDBox>
                                                        </Grid>
                                                    </Grid>
                                                    {trackPayment &&
                                                        <>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormField
                                                                        type="text"
                                                                        label="Address"
                                                                        name="address"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormField
                                                                        type="text"
                                                                        label="City"
                                                                        name="city"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormField
                                                                        type="text"
                                                                        label="State"
                                                                        name="state"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormField
                                                                        type="text"
                                                                        label="Zip"
                                                                        name="zip"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} sm={12}>
                                                                    <MDBox>
                                                                        <Field
                                                                            as={FormControlLabel}
                                                                            type="checkbox"
                                                                            name="checkbox"
                                                                            control={<Checkbox />}
                                                                            label="Shipping Address same as Billing Address"
                                                                            checked={trackPayment1}
                                                                            onChange={handleChangeship}
                                                                            sx={{ mt: 1.5 }}
                                                                        />
                                                                    </MDBox>
                                                                </Grid>
                                                            </Grid>
                                                        </>}
                                                    {!trackPayment1 &&
                                                        <>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormField
                                                                        type="text"
                                                                        label="Shipping Address"
                                                                        name="shippingAddress"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormField
                                                                        type="text"
                                                                        label="City"
                                                                        name="shippingcity"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormField
                                                                        type="text"
                                                                        label="State"
                                                                        name="shippingstate"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormField
                                                                        type="text"
                                                                        label="Zip"
                                                                        name="shippingzip"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </>}
                                                </MDBox>
                                                <MDBox mt={4} mb={-2} width="100%" display="flex" justifyContent="end">
                                                    <MDButton
                                                        disabled={btnDisable}
                                                        variant="gradient"
                                                        color="light"
                                                        onClick={onClose}
                                                    >
                                                        Cancel
                                                    </MDButton>
                                                    <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                        <MDButton
                                                            disabled={btnDisable}
                                                            type="submit"
                                                            variant="gradient"
                                                            color="info"
                                                        >
                                                            {btnDisable ? "Submitting..." : "Submit"}
                                                        </MDButton>
                                                    </MDBox>
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>
                                    </Form>
                                )}
                            </Formik>

                        </Grid>

                    </Grid>
                </MDBox>
                <MDBox>
                    <MDSnackbar
                        color="error"
                        icon="warning"
                        title="Failed."
                        content={errorMessage}
                        // autoHideDuration={3000}
                        open={showErrorSnackbar}
                        close={() => setShowErrorSnackbar(false)}
                    />
                </MDBox>
            </>
        }
            <MDBox>
                {loading && (
                    <CircularProgress
                        size={50}
                        sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </MDBox>
        </>
    );
}

export default AddCustomerDialog;
