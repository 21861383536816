import { useState, useEffect } from "react";
import { SITE_URL } from "helpers/config";
import { ErrorMessage, Formik, Form, Field } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Link, useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import { AddNewSchema } from "layouts/admin/accountants/components/schemas/addnewvalidations";
import initialValues from "layouts/admin/accountants/components/schemas/initialValues";
import FormField from "layouts/admin/accountants/components/FormField";
import Hoc from "hoc";
import { CreateAccountant, checkEmail, EditAccountantInfo } from "../../../../services/accountantservices";
import { useAPICall } from "../../../../utils/useapicall";
import { TextField } from "@mui/material";
import NumberFormat from "react-number-format";

function NewAccountant() {
    const navigate = useNavigate();
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [ErrorMessagee, setErrorMessage] = useState("");
    const [btnDisable, setBtnDisable] = useState(false);
    const [btnDisable1, setBtnDisable1] = useState(false);
    const [btnDisable2, setBtnDisable2] = useState(false);
    const [emailId, setemailId] = useState('');
    const [emailStatus, setEmailStatus] = useState('');
    const [errorMessages, setErrorMessages] = useState('');
    const [errorMobileMessages, setErrorMobileMessages] = useState('');
    const [userId, setUserId] = useState();
    const [styleMt, setStyleMt] = useState('0px');
    const [phone, setPhone] = useState('');
    const [{ isError, isLoading, response }, setAPICall] = useAPICall(CreateAccountant, { onLoad: false });
    const [{ isError: isCheckEmError, isLoading: isCheckEmLoading, response: checkEmResponse }, checkEmailAPICall] = useAPICall(checkEmail, { onLoad: false });
    const [{ isLoading: isUpdateLoading, isError: isUpdateError, response: UpadateResponse }, UpdateAPICall] = useAPICall(EditAccountantInfo, { onLoad: false });

    useEffect(() => {

        if (isUpdateLoading) {
            return () => null;
        }

        if (isUpdateError) {
            return () => null;
        }

        if (UpadateResponse && UpadateResponse.data.isSuccess === true) {
            navigate('/admin/accountants/list');
        }
    }, [UpadateResponse]);

    useEffect(() => {
        if (isCheckEmError) {
            return () => null;
        }
        if (checkEmResponse && checkEmResponse.data.isSuccess === true) {
            setEmailStatus(checkEmResponse.data.data.status);
            setUserId(checkEmResponse.data.data.userId);
            if (checkEmResponse.data.data.status === 'ARCHIVED') {
                setStyleMt('-8px');
                setErrorMessages('This user is deleted you want to restore it? Click here');
            } else if (checkEmResponse.data.data.status === 'ACTIVE') {
                setStyleMt('-8px');
                setErrorMessages('This Email already has a Business');
            } else if (checkEmResponse.data.data.status === 'BLOCKED') {
                setStyleMt('-8px');
                setErrorMessages('This user is blocked to unblock it - Click here');
            } else {
                setStyleMt('0px');
                setErrorMessages('');
                setBtnDisable(false);
            }
        }

    }, [checkEmResponse]);

    useEffect(() => {
        const timeOutId = setTimeout(async () => {
            if (emailId.length !== 0) {
                setBtnDisable(true);
                await checkEmailAPICall({
                    payload: [
                        {
                            "email": `${emailId}`,
                            "checkBy": "email"
                        }
                    ]
                })
            }
        }, 2000)
        return () => clearTimeout(timeOutId);
    }, [emailId]);


    useEffect(() => {
        if (isLoading) {
            return () => null;
        }
        if (isError) {
            response?.code === "DATA401" ? navigate('/admin/logout') : console.log("somting else");
            if (response?.data?.message) {
                setErrorMessage(response.data.message)
            }
            setBtnDisable1(false);
            setBtnDisable2(false);
            setShowErrorSnackbar(true);
            setTimeout(() => {
                setShowErrorSnackbar(false);
            }, 5000);
            setBtnDisable(false);
            return () => null;
        }
        if (response && response.data.isSuccess === true) {

            setShowSnackbar(true);
            setTimeout(() => {
                setShowSnackbar(false);
            }, 3000);

            navigate('/admin/accountants/list');
        }
    }, [response]);

    const handleSubmit = async (values) => {
        setBtnDisable1(true);
        setBtnDisable2(true);
        setBtnDisable(true);
        await setAPICall({
            payload: [
                {
                    "email": `${emailId}`,
                    "businessName": `${values.businessname}`,
                    "phone": `${values.phone}`,
                    "fname": `${values.firstname}`,
                    "lname": `${values.lastname}`,
                    "accountLevel": `${values.role}`,
                    "returnLink": `${SITE_URL}/create-password`
                }
            ]
        })
    };
    let emails = ''
    const handleChange = (e) => {
        setemailId(e.target.value);
        emails = e.target.value;
        setErrorMessages('');
    }

    const validate = (value) => {
        const finalEmail = emails ? emails : emailId.slice(0, -1);
        let errorMessage;
        if (!finalEmail) {
            errorMessage = 'Email address is required'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(finalEmail)) {
            errorMessage = 'Your email address is invalid';
        } else {
            errorMessage = ''
        }

        return errorMessage;
    };

    const handleRestoreNone = async () => {
        console.log("none")
    }
    const handleRestore = async () => {
        console.log("emailStatus------>", emailStatus)
        if (emailStatus === 'ARCHIVED') {
            await UpdateAPICall({
                payload: [
                    {
                        "bussinessId": `${userId}`,
                        "isArchived": false,
                    }
                ]
            })
        } else if (emailStatus === 'BLOCKED') {
            await UpdateAPICall({
                payload: [
                    {
                        "bussinessId": `${userId}`,
                        "isBlocked": false,
                    }
                ]
            })
        } else {
            navigate('/admin/accountants/list');
        }

    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={3} mb={20} height="65vh">
                {/* <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 12 }}> */}
                <Grid item xs={12} lg={12}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={AddNewSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ values, errors, touched, isSubmitting, setFieldValue, setFieldTouched, handleBlur }) => (
                            <Form autoComplete="off" >
                                <Card sx={{ height: "100%" }}>
                                    <MDBox p={3}>
                                        <MDBox>
                                            <MDBox lineHeight={0}>
                                                <MDTypography variant="h5">Add New Accountant</MDTypography>
                                            </MDBox>
                                            <MDBox mt={1.625}>
                                                <Grid item xs={12} mb={1}>
                                                    <FormField
                                                        type="text"
                                                        label="Business Name *"
                                                        name="businessname"
                                                    />
                                                </Grid>
                                                <MDBox lineHeight={0} mb={1} mt={1}>
                                                    <MDTypography variant="h6" textTransform="capitalize">Accountant Info</MDTypography>
                                                </MDBox>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormField
                                                            type="text"
                                                            label="First Name *"
                                                            name="firstname"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormField
                                                            type="text"
                                                            label="Last Name *"
                                                            name="lastname"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Field
                                                        type="email"
                                                        name="email"
                                                        as={FormField}
                                                        label="Email *"
                                                        validate={validate}
                                                        value={emailId}
                                                        onChange={e => {
                                                            setFieldTouched('type');
                                                            handleChange(e);
                                                            handleBlur(e)
                                                        }}
                                                    />
                                                    <MDBox>
                                                        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                                            {<div style={{
                                                                color: '#fb8c00',
                                                                cursor: 'pointer',
                                                                marginTop: styleMt
                                                            }}
                                                                onClick={emailStatus != 'ACTIVE' ? handleRestore : handleRestoreNone}
                                                            >{errorMessages}</div>}
                                                        </MDTypography>
                                                    </MDBox>
                                                </Grid>
                                                <Grid sx={{ mt: 2 }}>
                                                </Grid>
                                                <Grid item xs={12} sx={{ mt: 1 }}>
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        variant="standard"
                                                        type="tel"
                                                        name="phone"
                                                        fullWidth
                                                        // onClick={handleSubmit1}
                                                        // value={phone}
                                                        value={values.phone}
                                                        label="Phone Number *"
                                                        sx={{
                                                            "& .MuiInputBase-input.Mui-disabled": {
                                                                WebkitTextFillColor: "black",
                                                            },
                                                            mb: 1, mt: -1, fontSize: `0.823rem !important`
                                                        }}
                                                        format="(###) ###-####"
                                                        mask=""
                                                        onValueChange={value => setFieldValue('phone', value.formattedValue)}
                                                    />
                                                </Grid>
                                                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                                    <ErrorMessage name="phone" />
                                                </MDTypography>
                                            </MDBox>
                                            <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                                                <MDButton
                                                    disabled={btnDisable2}
                                                    variant="gradient"
                                                    color="light"
                                                    component={Link}
                                                    to="/admin/accountants/list"
                                                >
                                                    Cancel
                                                </MDButton>
                                                <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                    <MDButton
                                                        disabled={btnDisable}
                                                        type="submit"
                                                        variant="gradient"
                                                        color="info"
                                                    >
                                                        {btnDisable1 ? "Creating..." : "Create"}
                                                    </MDButton>
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>
                                    </MDBox>
                                    <MDBox>
                                        <MDSnackbar
                                            color="success"
                                            icon="done"
                                            title="Success"
                                            content="Accountant Created Successfully!"
                                            open={showSnackbar}
                                            // autoHideDuration={3000}
                                            close={() => setShowSnackbar(false)}
                                        />
                                        <MDSnackbar
                                            color="error"
                                            icon="warning"
                                            title="Failed!"
                                            content={ErrorMessagee}
                                            // autoHideDuration={3000}
                                            open={showErrorSnackbar}
                                            close={() => setShowErrorSnackbar(false)}
                                        />
                                    </MDBox>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                </Grid>
                {/* </Grid> */}
            </MDBox >
        </DashboardLayout >
    );
}

export default Hoc(NewAccountant);
