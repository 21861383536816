

const initialValues = {
  name: "",
  firstName: "",
  lastName: "",
  company: "",
  email: "",
  password: "",
  repeatPassword: "",
  address1: "",
  address2: "",
  city: "",
  zip: "",
  twitter: "",
  facebook: "",
  instagram: "",
  publicEmail: "",
  bio: "",
};

export default initialValues;
