import { useEffect, useState } from "react";
import { Link, Routes, Route, useNavigate } from 'react-router-dom';
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Cookies from 'universal-cookie';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from "@mui/material/IconButton";
import BasicLayout from "layouts/auth/components/BasicLayout";
import React from 'react';
import { Formik, Form } from 'formik';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import bgImage from "assets/images/backgroundimage.jpg";
import { SignInSchema } from "layouts/auth/components/schemas/validations";
import FormField from "layouts/auth/components/FormField";
import initialValues from "layouts/auth/components/schemas/initialValues";
import { UserLogin } from "../../../services/userservices";
import { useAPICall } from "../../../utils/useapicall";
import { setRole, setProfileInfo, setPermission, setUserType } from "actions/rolePermission";
import { useSelector, useDispatch } from "react-redux";
import { green } from '@mui/material/colors';
import { getUserRole } from "helpers/getUserRole";
import { GetUserProfile } from "../../../services/userservices";
import { GetClientUserProfile } from "services/clientservices";
import ReactRecaptcha3 from 'react-google-recaptcha3';
const cookies = new Cookies();

function UserSignIn() {
    const navigate = useNavigate();
    const [rememberMe, setRememberMe] = useState(false);
    const [loading, setLoading] = useState(false);
    const [disButton, setDisButton] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showErrorMassge, setShowErrorMassge] = useState('')
    const [alert, setAlert] = useState(false);
    const [supportEmail, setSupportEmail] = useState('support@paynthr.com');
    const dispatch = useDispatch();
    const [googleToken, setGoogleToken] = useState('');
    const handleClickShowPassword = () => setShowPassword(true);
    const handleClickClosePassword = () => setShowPassword(false)
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const [{ isError, isLoading, response }, setAPICall] = useAPICall(UserLogin, { onLoad: false });
    const [{ isLoading: isGetLoading, isError: isGetError, response: UserprofileResponse }, getUserProfileAPICall] = useAPICall(GetUserProfile, { onLoad: false });
    const [{ isLoading: isClientGetLoading, isError: isClientGetError, response: ClientprofileResponse }, getclientProfileAPICall] = useAPICall(GetClientUserProfile, { onLoad: false });

    const checkUserLogin = async () => {
        var token = cookies.get('user_access_token');
        if (token) {
            var userRole = await getUserRole();
            if (userRole == "accountant") {
                navigate('/accountant/client/list');
                // navigate('/user/dashboards');
                // switch (userRole) {
                //     case "client": { navigate('/client/user/dashboards'); }
                //     case "accountant": { navigate('/user/dashboards'); }
                //     default: { console.log('signIn userRole Default', userRole); }
                // }
            } else if (userRole == "client") {
                const finalPermission = ClientprofileResponse?.data?.data?.user?.permissions;
                if (finalPermission) {
                    const inventoryPermission = finalPermission?.find(x => x.category == 'inventory');
                    const accountantPermission = finalPermission?.find(x => x.category == 'accountant');
                    console.log('finalPermission', inventoryPermission)
                    if (inventoryPermission) {
                        navigate('/client/restaurant/list');
                    } else if (accountantPermission) {
                        navigate('/client/document/:id');
                    } else {
                        navigate('/client/user/list');
                    }
                }
            }
        } else {
            console.log('no token');
        }
    }

    useEffect(async () => {
        await checkUserLogin();
    }, []);

    useEffect(() => {
        dispatch(setProfileInfo(ClientprofileResponse?.data?.data));
    }, [ClientprofileResponse])

    useEffect(async () => {
        if (isLoading) {
            return () => null;
        }
        if (isError) {
            setDisButton(false);
            setLoading(false);
            if (response?.data?.message) {
                if (response?.code === 'BLOCKED') {
                    setAlert(true);
                } else {
                    setShowErrorMassge(response?.data?.message);
                    setShowSnackbar(true);
                    setTimeout(() => {
                        setShowSnackbar(false);
                    }, 3000);
                }
            }
            return () => null;
        }
        if (response && response.data.isSuccess === true) {
            // console.log("response?.data?.data", response?.data?.data?.access_token)
            cookies.set('user_access_token', response?.data?.data?.token?.access_token, { path: '/' });
            if (response.data.data.userType == 'client') {
                await getclientProfileAPICall({ payload: [] });
            } else {
                await getUserProfileAPICall({ payload: [] });
            }

        }
    }, [response]);

    useEffect(async () => {
        if (isGetLoading) {
            return () => null;
        }

        if (isGetError) {
            if (response?.data?.message) {
                setErrorMessage(response.data.message)
            }
            return () => null;
        }

        if (UserprofileResponse && UserprofileResponse.data.isSuccess === true) {
            localStorage.setItem("ShowBilling", UserprofileResponse?.data?.data?.user?.isPrimary === true ? true : false)

            if (UserprofileResponse?.data?.data?.user &&
                UserprofileResponse?.data?.data?.user?.permissions &&
                UserprofileResponse?.data?.data?.user?.permissions
            ) {
                const adminPermission = UserprofileResponse?.data?.data?.user?.permissions;
                await dispatch(setPermission(adminPermission));
                cookies.set('user_permissions', adminPermission, { path: '/' });
                delete UserprofileResponse.data.data.user.permissions;
            }

            if (UserprofileResponse?.data?.data &&
                UserprofileResponse?.data?.data?.user &&
                UserprofileResponse?.data?.data?.user?.role &&
                UserprofileResponse?.data?.data?.user?.role?.clientIds
            ) {
                delete UserprofileResponse?.data?.data?.user?.role?.clientIds
            }

            cookies.set('user_profile_info', UserprofileResponse?.data?.data, { path: '/' });
            await dispatch(setProfileInfo(UserprofileResponse?.data?.data));
            if (
                UserprofileResponse?.data?.data &&
                UserprofileResponse?.data?.data.user &&
                UserprofileResponse?.data?.data.user.userType &&
                UserprofileResponse?.data?.data.user.userType != ''
            ) {
                await dispatch(setRole(UserprofileResponse?.data?.data.user.userType));
                cookies.set('user_role', UserprofileResponse?.data?.data.user.userType, { path: '/' });
                if (UserprofileResponse?.data?.data.user &&
                    UserprofileResponse?.data?.data.user.userType
                ) {
                    await dispatch(setUserType(UserprofileResponse?.data?.data?.user?.userType))
                    cookies.set('user_type', UserprofileResponse?.data?.data?.user?.userType, { path: '/' });
                }
            }

            setTimeout(() => {
                checkUserLogin();
                // var profileInfo = cookies.get('user_profile_info');
                // navigate('/user/dashboards');
            }, 200);
        }
    }, [UserprofileResponse]);

    useEffect(() => {
        if (isClientGetLoading) {
            return () => null;
        }

        if (isClientGetError) {
            if (response?.data?.message) {
                setErrorMessage(response.data.message)
            }
            return () => null;
        }
        if (ClientprofileResponse && ClientprofileResponse.data.isSuccess === true) {
            cookies.set('user_profile_info', ClientprofileResponse.data.data, { path: '/' });
            dispatch(setProfileInfo(ClientprofileResponse?.data?.data));
            console.log("ClientprofileResponse?.data?.data", ClientprofileResponse?.data?.data)
            if (
                ClientprofileResponse?.data?.data &&
                ClientprofileResponse?.data?.data?.user &&
                ClientprofileResponse?.data?.data?.user?.userType
            ) {
                dispatch(setRole(ClientprofileResponse?.data?.data?.user?.userType));
                cookies.set('user_role', ClientprofileResponse?.data?.data?.user?.userType, { path: '/' });

                if (ClientprofileResponse?.data?.data &&
                    ClientprofileResponse?.data?.data?.user &&
                    ClientprofileResponse?.data?.data?.user?.userType
                ) {
                    dispatch(setUserType(ClientprofileResponse?.data?.data?.user?.userType))
                    cookies.set('user_type', ClientprofileResponse?.data?.data?.user?.userType, { path: '/' });
                }

                if (ClientprofileResponse?.data?.data?.user &&
                    ClientprofileResponse?.data?.data?.user?.permissions.length !== 0
                ) {
                    const ClientPermission = ClientprofileResponse?.data?.data?.user?.permissions;
                    dispatch(setPermission(ClientPermission));
                    cookies.set('user_permissions', ClientPermission, { path: '/' });
                }
            }


            setTimeout(() => {
                checkUserLogin();
            }, 200);
            // navigate('/client/user/dashboards');
        }
    }, [ClientprofileResponse]);

    const onSubmit = async (values) => {
        setLoading(true);
        setDisButton(true);
        ReactRecaptcha3.getToken().then(
            (resp) => {
                setAPICall({
                    payload: [
                        {
                            "email": `${values.email}`,
                            "password": `${values.password}`,
                            "siteToken": `${resp}`,
                        }
                    ]
                })
                setGoogleToken(resp);
            },
            (error) => {
                console.log(error);
                setLoading(false);
                setDisButton(false);
                setShowErrorMassge("Google reCAPTCHA verification failed.");
                setShowSnackbar(true);
                setTimeout(() => {
                    setShowSnackbar(false);
                }, 3000);
            }
        );
        // await setAPICall({
        //     payload: [
        //         {
        //             "email": `${values.email}`,
        //             "password": `${values.password}`,
        //             "token": `${googleToken}`,
        //         }
        //     ]
        // })


    };

    const hideAlert = () => {
        setAlert(false);
    };
    const openMail = () => {
        <a href="mailto:support@paynthr.com" />

    }
    return (
        <BasicLayout image={bgImage}>
            <Card>
                {/* <Box sx={{ display: 'flex' }}>
          <CircularProgress color="inherit" />
        </Box> */}
                <MDBox
                    variant="gradient"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    sx={{
                        backgroundColor: "#80D6C3",
                    }}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Welcome to Paynthr
                    </MDTypography>
                </MDBox>
                <Formik initialValues={initialValues}
                    validationSchema={SignInSchema}
                    onSubmit={onSubmit}
                >
                    {({ values, errors, touched, isSubmitting }) => (
                        <Form autoComplete="off">
                            <div>
                                <MDBox pt={4} pb={3} px={3}>
                                    <MDBox mb={2}>
                                        <MDBox mb={2}>
                                            <FormField
                                                type="email"
                                                label="Email"
                                                name="email"
                                            />
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <FormField
                                                type={showPassword ? "text" : "password"}
                                                label="Password"
                                                name="password"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end" >
                                                            <IconButton sx={{ fontSize: '0.9rem' }} >
                                                                {!showPassword ? <VisibilityOff onClick={handleClickShowPassword} /> : <Visibility onClick={handleClickClosePassword} />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            {/* <InputAdornment sx={{ justifyContent: "end", mr: 2 }} >
                                                <IconButton
                                                    sx={{ mt: -8.5 }}
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment> */}
                                        </MDBox>
                                        <MDBox>
                                            <MDTypography
                                                component={Link}
                                                to="/reset-password"
                                                variant="button"
                                                color="info"
                                                fontWeight="medium"
                                                textGradient
                                                sx={{
                                                    cursor: "pointer", float: "right",
                                                    padding: " 0px 0px 40px "
                                                }}
                                            >
                                                Forgot Password
                                            </MDTypography>
                                        </MDBox>
                                        {/* <MDBox display="flex" alignItems="center" ml={-1}>
                    <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={handleSetRememberMe}
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Remember me
                    </MDTypography>
                  </MDBox> */}
                                        <MDBox mt={4} mb={1}>
                                            <MDButton
                                                disabled={disButton}
                                                type="submit"
                                                variant="gradient"
                                                color="info"
                                                fullWidth>
                                                sign in
                                            </MDButton>
                                            {loading && (
                                                <CircularProgress
                                                    size={35}
                                                    sx={{
                                                        color: green[500],
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: '-12px',
                                                        marginLeft: '-12px',
                                                    }}
                                                />
                                            )}
                                        </MDBox>
                                        {/* <MDBox mt={3} mb={1} textAlign="center">
                    <MDTypography variant="button" color="text">
                      Don&apos;t have an account?{" "}
                      <MDTypography
                        component={Link}
                        to="/sign-up"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Sign up
                      </MDTypography>
                    </MDTypography>
                  </MDBox> */}
                                    </MDBox>
                                </MDBox>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Card>
            <MDBox>
                <MDSnackbar
                    color="error"
                    icon="warning"
                    title="Login Failed."
                    // autoHideDuration={3000}
                    content={showErrorMassge ? showErrorMassge : "Your Email or Password is Incorrect"}
                    open={showSnackbar}
                    onClose={() => setShowSnackbar(false)}
                />
            </MDBox>
            <MDBox>
                <Dialog
                    open={alert}
                    onClose={hideAlert}
                    // PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move', color: "error" }} id="draggable-dialog-title">
                        Error
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Your account has been deactivated. If this is a mistake contact Paynthr support team (support@paynthr.com)
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MDButton onClick={hideAlert}
                            variant="gradient"
                            color="info">
                            Cancel
                        </MDButton>
                        {/* <MDButton
                            variant="gradient"
                            color="info"
                            onClick={() => window.location = 'mailto:support@paynthr.com'
                            }>
                            Contect
                        </MDButton> */}
                    </DialogActions>
                </Dialog>

            </MDBox>
        </BasicLayout >
    );
}

export default UserSignIn;
