import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { eventBus } from 'utils/eventbus';
import FormField from "layouts/admin/accountants/components/FormField";
import { useAPICall } from "utils/useapicall";
import { GetProductsList, EditProductApi, GetIncomeAccountList, GetExpenseAccountList, GetAssetAccountList, GetCategoryList } from "services/userservices";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import { EditProductSchema } from "../components/schemas/editproductvalidation";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import AddNewProduct from "layouts/accountants/user/invoice/components/schemas/AddNewProduct.js";
import AddNewProduct1 from "layouts/accountants/user/invoice/components/schemas/AddNewProduct1";
import FormFieldDate from "layouts/accountants/user/components/FormField";
import Moment from 'moment';

const useStyles = makeStyles({
    InputRoot: {
        '&::-webkit-calendar-picker-indicator': {
            display: 'none !important'
        }
    }
});


function EditProduct({ onClose, productId, businessId }) {
    const styles = useStyles();
    const [inventoryStartDate, setInventoryStartDate] = useState('');
    const [btnDisable, setBtnDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [address, setAddress] = useState('');
    const [ProductName, setProductName] = useState('');
    const [qtyOnHand, setQtyOnHand] = useState('');
    const [price, setPrice] = useState('');
    const [productType, setProductType] = useState('');
    const [netTerms, setNetTerms] = useState('');
    const [description, setDescription] = useState('');
    const [ProductList, setProductList] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [incomeAccountList, setincomeAccountList] = useState([]);
    const [expenseAccountList, setexpenseAccountList] = useState([]);
    const [assetAccountList, setassetAccountList] = useState([]);
    const [incomeAccountId, setincomeAccountId] = useState('');
    const [incomeInventoryAssetId, setincomeInventoryAssetId] = useState('');
    const [incomeExpenseAccountId, setincomeExpenseAccountId] = useState('');
    const [openExpenseAccount, setOpenExpenseAccount] = useState(false);
    const [openAddInventoryAsset, setOpenAddInventoryAsset] = useState(false);
    const [openAddIncomeAccount, setOpenAddIncomeAccount] = useState(false);
    const [incomeAccountName, setincomeAccountName] = useState('');
    const [expenseAccountName, setexpenseAccountName] = useState('');
    const [assetAccountName, setassetAccountName] = useState('');
    const [incomeAccounterrormsg, setincomeAccounterrormsg] = useState('');
    const [incomeAccounterror, setincomeAccounterror] = useState(false);
    const [expenseAccounterrormsg, setexpenseAccounterrormsg] = useState('');
    const [expenseAccounterror, setexpenseAccounterror] = useState(false);
    const [inventoryerrormsg, setinventoryerrormsg] = useState('');
    const [inventoryerror, setinventoryerror] = useState(false);
    const [{ isLoading: isEditProductLoading, isError: isEditProductError, response: EditProductResponse }, EditProductListAPICall] = useAPICall(EditProductApi, { onLoad: false });
    const [{ isLoading: isProductsLoading, isError: isProductsError, response: ProductsResponse }, ProductsListAPICall] = useAPICall(GetProductsList, { onLoad: false });
    const [{ isLoading: isincomeAccountListLoading, isError: isincomeAccountListError, response: incomeAccountListResponse }, incomeAccountListListAPICall] = useAPICall(GetIncomeAccountList, { onLoad: false });
    const [{ isLoading: isexpenseAccountListLoading, isError: isexpenseAccountListError, response: expenseAccountListResponse }, expenseAccountListListAPICall] = useAPICall(GetExpenseAccountList, { onLoad: false });
    const [{ isLoading: isassetAccountListLoading, isError: isassetAccountListError, response: assetAccountListResponse }, assetAccountListListAPICall] = useAPICall(GetAssetAccountList, { onLoad: false });
    const [{ isLoading: isCategoryLoading, isError: isCategoryError, response: CategoryResponse }, CategoryListAPICall] = useAPICall(GetCategoryList, { onLoad: false });

    useEffect(() => {
        if (isCategoryLoading) {
            return () => null;
        }
        if (isCategoryError) {
            return () => null;
        }
        if (CategoryResponse && CategoryResponse.data.isSuccess === true) {
            const data = CategoryResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    categoryId: prop._id
                };
            }
            );
            setCategoryList(data);
        }
    }, [CategoryResponse]);

    useEffect(() => {
        if (isassetAccountListLoading) {
            return () => null;
        }
        if (isassetAccountListError) {
            return () => null;
        }
        if (assetAccountListResponse && assetAccountListResponse.data.isSuccess === true) {
            const data = assetAccountListResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    assetAccountListId: prop._id
                };
            }
            );
            setassetAccountList(data);
        }
    }, [assetAccountListResponse]);


    useEffect(() => {
        if (isexpenseAccountListLoading) {
            return () => null;
        }
        if (isexpenseAccountListError) {
            return () => null;
        }
        if (expenseAccountListResponse && expenseAccountListResponse.data.isSuccess === true) {
            const data = expenseAccountListResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    expenseAccountListId: prop._id
                };
            }
            );
            setexpenseAccountList(data);
        }
    }, [expenseAccountListResponse]);

    useEffect(() => {
        if (isincomeAccountListLoading) {
            return () => null;
        }
        if (isincomeAccountListError) {
            return () => null;
        }
        if (incomeAccountListResponse && incomeAccountListResponse.data.isSuccess === true) {
            const data = incomeAccountListResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    incomeAccountListId: prop._id
                };
            }
            );
            setincomeAccountList(data);
        }
    }, [incomeAccountListResponse]);

    useEffect(async () => {


        // if (productId) {
        //     setTimeout(() => {
        //         setShowDetails(true);
        //         setLoading(false);
        //     }, 2000);
        // }

    }, [ProductList, productId, expenseAccountList, assetAccountList, incomeAccountList])


    useEffect(async () => {
        setLoading(true);
        await CategoryListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
        await incomeAccountListListAPICall({
            payload: [{ "clientId": `${businessId}`, }]
        })
        await expenseAccountListListAPICall({
            payload: [{ "clientId": `${businessId}`, }]
        })
        await assetAccountListListAPICall({
            payload: [{ "clientId": `${businessId}`, }]
        })
    }, []);

    useEffect(() => {
        if (assetAccountList.length !== 0 && expenseAccountList !== 0 && incomeAccountList !== 0) {
            ProductsListAPICall({
                payload: [{ "businessId": `${businessId}` }]
            });
        }
    }, [assetAccountList, expenseAccountList, incomeAccountList])

    useEffect(() => {
        if (isProductsLoading) {
            return () => null;
        }
        if (isProductsError) {
            setLoading(false);
            return () => null;
        }
        if (ProductsResponse && ProductsResponse.data.isSuccess === true) {
            setProductList(ProductsResponse.data.data.records);
            if (ProductsResponse?.data?.data?.records.length != 0) {
                const Data = ProductsResponse?.data?.data?.records.find(obj => {
                    return obj._id === productId;
                });
                const found = incomeAccountList.find(obj => {
                    return obj.incomeAccountListId === Data?.incomeAccountId;
                });
                if (found) {
                    setincomeAccountName(found?.name)
                }
                const found1 = expenseAccountList.find(obj => {
                    return obj.expenseAccountListId === Data?.expenseAccountId;
                });
                if (found1) {
                    setexpenseAccountName(found1?.name);
                }
                const found3 = assetAccountList.find(obj => {
                    return obj.assetAccountListId === Data?.assetAccountId;
                });
                if (found3) {
                    setassetAccountName(found3?.name);
                }
                setProductName(Data?.name !== '---' ? Data?.name : '');
                setAddress(Data?.billingAddress?.line1 !== '---' ? Data?.billingAddress?.line1 : '');
                setInventoryStartDate(Data?.inventoryStartDate ? Moment(Data?.inventoryStartDate).format('YYYY-MM-DD') : null,)
                setProductType(Data?.productType);
                setPrice(Data?.unitPrice);
                setNetTerms(Data?.balance);
                setQtyOnHand(Data?.qtyOnHand);
                setDescription(Data?.description !== '---' ? Data?.description : '');
                setTimeout(() => {
                    setShowDetails(true);
                    setLoading(false);
                }, 500);
            }
        }
    }, [ProductsResponse]);

    useEffect(() => {
        if (isEditProductLoading) {
            return () => null;
        }
        if (isEditProductError) {
            setLoading(false);
            setBtnDisable(false);
            return () => null;
        }
        if (EditProductResponse && EditProductResponse.data.isSuccess === true) {
            setLoading(false);
            setBtnDisable(false);
            onClose();
            eventBus.$emit("reloadProductDetailsList", EditProductResponse);
        }
    }, [EditProductResponse]);

    let initialValues = {
        product: ProductName,
        qtyOnHand: qtyOnHand,
        description: description,
        productType: productType,
        unitprice: price,
        inventoryStartDate: inventoryStartDate,
    };

    const handleSubmits = async (values) => {
        if (incomeAccountId) {
            setincomeAccounterrormsg('');
            setincomeAccounterror(false);
        } else {
            setincomeAccounterrormsg('Income Account name required');
            setincomeAccounterror(true);
        }
        if (incomeExpenseAccountId) {
            setexpenseAccounterrormsg('');
            setexpenseAccounterror(false);
        } else {
            setexpenseAccounterrormsg('Expense name required');
            setexpenseAccounterror(true);
        }
        if (incomeInventoryAssetId) {
            setinventoryerrormsg('');
            setinventoryerror(false);
        } else {
            setinventoryerrormsg('Inventory Asset name required');
            setinventoryerror(true);
        }
    }

    const handleSubmit = async (values) => {

        if (incomeAccountId && incomeExpenseAccountId) {
            setBtnDisable(true);
            productType === 'Inventory' ?
                await EditProductListAPICall({
                    payload: [
                        {
                            "productId": `${productId}`,
                            "clientId": `${businessId}`,
                            "name": values?.product,
                            "unitPrice": Number(values?.unitprice),
                            "productType": "Inventory",
                            "trackQtyOnHand": true,
                            "inventoryStartDate": '',
                            "qtyOnHand": Number(values?.qtyOnHand),
                            "inventoryStartDate": new Date(),
                            "incomeAccountId": incomeAccountId,
                            "assetAccountId": incomeInventoryAssetId,
                            "expenseAccountId": incomeExpenseAccountId
                        }
                    ]
                })
                :
                productType === 'Service' ?
                    await EditProductListAPICall({
                        payload: [
                            {
                                "productId": `${productId}`,
                                "clientId": `${businessId}`,
                                "name": values?.product,
                                "unitPrice": Number(values?.unitprice),
                                "productType": "Service",
                                "incomeAccountId": incomeAccountId,
                                "trackQtyOnHand": false,
                                "expenseAccountId": incomeExpenseAccountId,
                                "qtyOnHand": Number(values?.qtyOnHand),
                            }
                        ]
                    })
                    :
                    await EditProductListAPICall({
                        payload: [
                            {
                                "productId": `${productId}`,
                                "clientId": `${businessId}`,
                                "name": values?.product,
                                "unitPrice": Number(values?.unitprice),
                                "productType": "NonInventory",
                                "incomeAccountId": incomeAccountId,
                                "trackQtyOnHand": false,
                                "expenseAccountId": incomeExpenseAccountId,
                                "qtyOnHand": Number(values?.qtyOnHand),
                            }
                        ]
                    })
        }
    };


    const handleChangeIncomeAccount = (event, newValue) => {
        if (newValue == '+ Add Income Account') {
            setOpenAddIncomeAccount(true);
        } else {
            setincomeAccountName(newValue)
            const found = incomeAccountList.find(obj => {
                return obj.name === newValue;
            });
            if (found) {
                setincomeAccountId(found.incomeAccountListId);
            }
            if (found) {
                setincomeAccounterrormsg('');
                setincomeAccounterror(false);
            } else {
                setincomeAccounterrormsg('Income Account name required');
                setincomeAccounterror(true);
            }
        }
    };

    const handleChangeExpenseAccount = (event, newValue) => {
        if (newValue == '+ Add Expense') {
            setOpenExpenseAccount(true);
        } else {
            setexpenseAccountName(newValue);
            const found = expenseAccountList.find(obj => {
                return obj.name === newValue;
            });
            if (found) {
                setincomeExpenseAccountId(found.expenseAccountListId);
            }
            if (found) {
                setexpenseAccounterrormsg('');
                setexpenseAccounterror(false);
            } else {
                setexpenseAccounterrormsg('Expense name required');
                setexpenseAccounterror(true);
            }
        }
    };

    const handleChangeInventoryAsset = (event, newValue) => {
        if (newValue == '+ Add Asset') {
            setOpenAddInventoryAsset(true);
        } else {
            setassetAccountName(newValue);
            const found = assetAccountList.find(obj => {
                return obj.name === newValue;
            });
            if (found) {
                setincomeInventoryAssetId(found.assetAccountListId);
            }
            if (found) {
                setinventoryerrormsg('');
                setinventoryerror(false);
            } else {
                setinventoryerrormsg('Inventory Asset name required');
                setinventoryerror(true);
            }
        }
    };

    return (
        <>{showDetails &&
            <>
                < MDBox mt={0} >
                    <Grid spacing={3}>
                        <Grid item xs={12}>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={productType === 'Inventory' ? AddNewProduct : AddNewProduct1}
                                onSubmit={handleSubmit}
                            >
                                {({ values, errors, touched, isSubmitting }) => (
                                    <Form autoComplete="off">
                                        <MDBox p={1}>
                                            <MDBox>
                                                <MDBox mt={1.625}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Product *"
                                                                name="product"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Quantity On Hand *"
                                                                name="qtyOnHand"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    {productType !== 'Inventory' &&
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} sm={6}>
                                                                <FormField
                                                                    type="text"
                                                                    label="Unit Price *"
                                                                    name="unitprice"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <Autocomplete
                                                                    sx={{
                                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                                            WebkitTextFillColor: "black",
                                                                        },
                                                                    }}

                                                                    value={{ name: incomeAccountName }}
                                                                    options={incomeAccountList}
                                                                    getOptionLabel={(option) => option.name}
                                                                    onInputChange={(event, newInputValue) => {
                                                                        handleChangeIncomeAccount(event, newInputValue);
                                                                    }}
                                                                    id="controllable-states-demo"
                                                                    disableCloseOnSelect={false}
                                                                    renderInput={(params) => (
                                                                        <TextField {...params}
                                                                            label="Income account *"
                                                                            variant="standard"
                                                                            helperText={incomeAccounterrormsg}
                                                                            error={incomeAccounterror}
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    {productType === 'Inventory' ?
                                                        <>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FormField
                                                                        type="text"
                                                                        label="Unit Price *"
                                                                        name="unitprice"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <Autocomplete
                                                                        sx={{
                                                                            "& .MuiInputBase-input.Mui-disabled": {
                                                                                WebkitTextFillColor: "black",
                                                                            },
                                                                        }}

                                                                        value={{ name: assetAccountName }}
                                                                        options={assetAccountList}
                                                                        getOptionLabel={(option) => option.name}
                                                                        onInputChange={(event, newInputValue) => {
                                                                            handleChangeInventoryAsset(event, newInputValue);
                                                                        }}
                                                                        id="controllable-states-demo"
                                                                        disableCloseOnSelect={false}
                                                                        renderInput={(params) => (
                                                                            <TextField {...params}
                                                                                label="Inventory asset account *"
                                                                                variant="standard"
                                                                                helperText={inventoryerrormsg}
                                                                                error={inventoryerror}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} sm={6}>
                                                                    <Autocomplete
                                                                        sx={{
                                                                            "& .MuiInputBase-input.Mui-disabled": {
                                                                                WebkitTextFillColor: "black",
                                                                            },
                                                                        }}
                                                                        value={{ name: incomeAccountName }}
                                                                        options={incomeAccountList}
                                                                        getOptionLabel={(option) => option.name}
                                                                        onInputChange={(event, newInputValue) => {
                                                                            handleChangeIncomeAccount(event, newInputValue);
                                                                        }}
                                                                        id="controllable-states-demo"
                                                                        disableCloseOnSelect={false}
                                                                        renderInput={(params) => (
                                                                            <TextField {...params}
                                                                                label="Income account *"
                                                                                variant="standard"
                                                                                helperText={incomeAccounterrormsg}
                                                                                error={incomeAccounterror}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6}>
                                                                    <Autocomplete
                                                                        sx={{
                                                                            "& .MuiInputBase-input.Mui-disabled": {
                                                                                WebkitTextFillColor: "black",
                                                                            },
                                                                        }}

                                                                        value={{ name: expenseAccountName }}
                                                                        options={expenseAccountList}
                                                                        getOptionLabel={(option) => option.name}
                                                                        onInputChange={(event, newInputValue) => {
                                                                            handleChangeExpenseAccount(event, newInputValue);
                                                                        }}
                                                                        id="controllable-states-demo"
                                                                        disableCloseOnSelect={false}
                                                                        renderInput={(params) => (
                                                                            <TextField {...params}
                                                                                label="Expense account *"
                                                                                variant="standard"
                                                                                helperText={expenseAccounterrormsg}
                                                                                error={expenseAccounterror}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} sm={6} mt={2}>
                                                                    <FormFieldDate
                                                                        classes={{ root: styles.InputRoot }}
                                                                        type="date"
                                                                        label="Inventory Start Date"
                                                                        name="inventoryStartDate"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                        :
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} sm={6}>
                                                                <Autocomplete
                                                                    sx={{
                                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                                            WebkitTextFillColor: "black",
                                                                        },
                                                                    }}

                                                                    value={{ name: expenseAccountName }}
                                                                    options={expenseAccountList}
                                                                    getOptionLabel={(option) => option.name}
                                                                    onInputChange={(event, newInputValue) => {
                                                                        handleChangeExpenseAccount(event, newInputValue);
                                                                    }}
                                                                    id="controllable-states-demo"
                                                                    disableCloseOnSelect={false}
                                                                    renderInput={(params) => (
                                                                        <TextField {...params}
                                                                            label="Expense account *"
                                                                            variant="standard"
                                                                            helperText={expenseAccounterrormsg}
                                                                            error={expenseAccounterror}
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </MDBox>
                                                <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                                                    <MDButton
                                                        disabled={btnDisable}
                                                        variant="gradient"
                                                        color="light"
                                                        onClick={onClose}
                                                    >
                                                        Cancel
                                                    </MDButton>
                                                    <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                        <MDButton
                                                            disabled={btnDisable}
                                                            type="submit"
                                                            variant="gradient"
                                                            onClick={handleSubmits}
                                                            color="info"
                                                        >
                                                            {btnDisable ? "updating..." : "update"}
                                                        </MDButton>
                                                    </MDBox>
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>

                                    </Form>
                                )}
                            </Formik>

                        </Grid>

                    </Grid>
                </MDBox>

            </>
        }
            <MDBox>
                {loading && (
                    <CircularProgress
                        size={50}
                        sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </MDBox>
        </>
    );
}

export default EditProduct;
