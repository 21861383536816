

import * as Yup from "yup";

export const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .email('Your email address is invalid')
    .required('Email address is required.'),
  password: Yup.string()
    .trim()
    .required('Password is required.')
    .min(6, 'Your password should be more than 6 characters.'),
});

export const SignUpSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Name is required.')
    .min(3, 'Name should be more than 3 characters.'),
  email: Yup.string()
    .email('Your email address is invalid')
    .required('Email address is required.'),
  password: Yup.string()
    .trim()
    .required('Password is required.')
    .min(6, 'Your password should be more than 6 characters.'),
});

export const ResetPassSchema = Yup.object().shape({
  email: Yup.string()
    .email('Your email address is invalid')
    .required('Email address is required.'),
});

export const CreatePassSchema = Yup.object().shape({
  curpassword: Yup.string()
    .trim()
    .required('Password is required.')
    .min(6, 'Your password should be more than 6 characters.'),
  password: Yup
    .string()
    .trim()
    .required('New password is required')
    .matches(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{6,}$",
      "Password must contain at least 1 uppercase , 1 lowercase , 1 number, 1 special character and length between 6 to 20"
    ),
  repeatPassword: Yup.string()
    .trim()
    .required('Confirm new password is required.')
    .oneOf([Yup.ref("password"), null], "Your password doesn't match."),
});
