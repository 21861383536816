import * as Yup from "yup";

export const addCategoryschema = Yup.object().shape({
    price: Yup
        .string()
        .nullable()
        .required("Price is required")
    // .number("Must be a number type"),

});
