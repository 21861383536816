import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { eventBus } from 'utils/eventbus';
import { AddNewOwnerSchema } from "../components/schemas/addowner";
import { AddNewEmailOwnerSchema } from "../components/schemas/addowneremail";
import FormField from "layouts/admin/accountants/components/FormField";

import { useAPICall } from "utils/useapicall";
import { AssignNewOwner, checkEmail } from "../../../services/userservices";
import CircularProgress from '@mui/material/CircularProgress';
import { green, grey } from '@mui/material/colors';
import TextField from '@mui/material/TextField';
import { SITE_URL } from "helpers/config";
import DialogContentText from "@mui/material/DialogContentText";
import NumberFormat from "react-number-format";

function AddOwnerDetailsDialog({ onClose, invoiceId, businessId }) {
    const [invoice_id, setInvoiceId] = useState(invoiceId);
    const [btnDisable, setBtnDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emailExist, setEmailExist] = useState(false);
    const [emailId, setEmailId] = useState('');
    const [emailExistButton, setEmailExistButton] = useState(true);
    const [helperText, setHelperText] = useState('');
    const [searchBusinessName, setSearchBusinessName] = useState('');
    const [disabledBusinessName, setDisabledBusinessName] = useState(false);
    const [showDetails, setShowDetails] = useState(true);
    const [oldOwner, setOldOwner] = useState(false);
    const [phone, setPhone] = useState('');
    const [{ isLoading: isAssignOwnerLoading, isError: isAssignOwnerError, response: AssignOwnerResponse }, AssignOwnerAPICall] = useAPICall(AssignNewOwner, { onLoad: false });
    const [{ isError: isCheckEmError, isLoading: isCheckEmLoading, response: checkEmResponse }, checkEmailAPICall] = useAPICall(checkEmail, { onLoad: false });

    useEffect(() => {
        if (isCheckEmError) {
            setEmailExist(false);
            return () => null;
        }
        if (checkEmResponse && checkEmResponse.data.isSuccess === true) {
            console.log("checkEmResponse?.data?.data", checkEmResponse?.data?.data)
            if (checkEmResponse?.data?.data?.user == null) {
                setOldOwner(false);
                setEmailExist(true);
                setHelperText('');
                setDisabledBusinessName(false);
                setEmailExistButton(false);
            } else {
                setOldOwner(true);
                setEmailExistButton(false);
                setEmailExist(false);
                setHelperText('This owner has another client, Do you want to continue to assign new client?');
                setDisabledBusinessName(true);
            }
        }

    }, [checkEmResponse]);


    useEffect(() => {
        console.log("searchBusinessName", searchBusinessName.length)
        const timeOutId = setTimeout(async () => {
            if (searchBusinessName.length !== 0) {
                await checkEmailAPICall({
                    payload: [
                        {
                            "email": `${searchBusinessName}`
                        }
                    ]
                })
            }
        }, 500)
        return () => clearTimeout(timeOutId);
    }, [searchBusinessName]);

    useEffect(() => {
        if (isAssignOwnerLoading) {
            return () => null;
        }
        if (isAssignOwnerError) {
            if (AssignOwnerResponse?.data?.message) {
                eventBus.$emit("errorInAddOwner", AssignOwnerResponse.data.message);
            }
            setLoading(false);
            setBtnDisable(false);
            return () => null;
        }
        if (AssignOwnerResponse && AssignOwnerResponse.data.isSuccess === true) {
            eventBus.$emit("successInAddOwner", AssignOwnerResponse);
            setLoading(false);
            setBtnDisable(false);
            // onClose();
            // eventBus.$emit("reloadDetailsTab", AssignOwnerResponse);
        }
    }, [AssignOwnerResponse]);

    const initialValues = {
        fname: "",
        lname: "",
        email: "",
        phone: "",
    };
    const handleSubmit = async (values) => {
        setBtnDisable(true);
        await AssignOwnerAPICall({
            payload: [{
                "fname": values.fname,
                "lname": values.lname,
                "phone": phone,
                "email": emailId,
                "businessId": businessId,
                "returnLink": `${SITE_URL}/user/create-password`
            }]
        })
    };


    function isValidEmail(email) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    }
    const handleBusiness = async (event) => {
        const valid = isValidEmail(event.target.value);
        if (valid) {
            console.log("valid------------->", valid)
            setEmailId(event.target.value);
            setSearchBusinessName((event.target.value));
        }
    };
    return (
        <>{showDetails &&
            <>
                < MDBox mt={0} >
                    <Grid spacing={3}>
                        <Grid item xs={12}>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={oldOwner ? AddNewEmailOwnerSchema : AddNewOwnerSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                                    <Form autoComplete="off">
                                        <MDBox p={3}>
                                            <MDBox>
                                                <MDBox mt={1.625}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={12}>
                                                            <TextField
                                                                fullWidth
                                                                margin="dense"
                                                                id="email"
                                                                name="email"
                                                                label="Email *"
                                                                style={{ marginTop: "-2px" }}
                                                                type="text"
                                                                helperText={helperText}
                                                                variant="standard"
                                                                FormHelperTextProps={{
                                                                    style: {
                                                                        color: '#ff6f00',
                                                                        fontSize: '.9em',
                                                                    }
                                                                }}
                                                                onChange={handleBusiness}
                                                            // onChange={(event) => setFieldValue("email", event.target.value)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    {emailExist && <Grid container spacing={3}>
                                                        <Grid item mt={2} xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="First Name *"
                                                                name="fname"
                                                            />
                                                        </Grid>
                                                        <Grid item mt={2} xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Last Name *"
                                                                name="lname"
                                                            />
                                                        </Grid>
                                                    </Grid>}
                                                    {emailExist && <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={12}>
                                                            <NumberFormat
                                                                customInput={TextField}
                                                                variant="standard"
                                                                type="tel"
                                                                fullWidth
                                                                value={phone}
                                                                label="Phone Number"
                                                                sx={{
                                                                    "& .MuiInputBase-input.Mui-disabled": {
                                                                        WebkitTextFillColor: "black",
                                                                    },
                                                                    mb: 1, fontSize: `0.823rem !important`
                                                                }}
                                                                format="(###) ###-####"
                                                                mask=""
                                                                onValueChange={value => setPhone(value.formattedValue)}
                                                            />
                                                        </Grid>
                                                    </Grid>}

                                                </MDBox>
                                                <DialogContentText sx={{ color: '#bdbdbd', fontSize: `0.9rem` }}>
                                                    Add client’s email that you want to have ownership over this account. They will be able to upload and review documents.
                                                </DialogContentText>
                                                <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                                                    {/* <MDButton
                                                            disabled={btnDisable}
                                                            variant="gradient"
                                                            color="light"
                                                            onClick={onClose}
                                                        >
                                                            Cancel
                                                        </MDButton> */}
                                                    <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                        <MDButton
                                                            disabled={emailExistButton}
                                                            type="submit"
                                                            variant="gradient"
                                                            color="info"
                                                        >
                                                            {btnDisable ? "Submitting..." : "submit"}
                                                        </MDButton>
                                                    </MDBox>
                                                </MDBox>

                                            </MDBox>
                                        </MDBox>

                                    </Form>
                                )}
                            </Formik>

                        </Grid>
                        <MDBox>
                            {loading && (
                                <CircularProgress
                                    size={50}
                                    sx={{
                                        color: green[500],
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </MDBox>
                    </Grid>
                </MDBox>

            </>
        }

        </>
    );
}

export default AddOwnerDetailsDialog;