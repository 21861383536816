import { useEffect, useState } from "react";
import { Link, Routes, Route, useNavigate } from 'react-router-dom';
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Cookies from 'universal-cookie';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import BasicLayout from "layouts/auth/components/BasicLayout";
import React from 'react';
import { Formik, Form } from 'formik';
import bgImage from "assets/images/backgroundimage.jpg";
import { SignInSchema } from "layouts/auth/components/schemas/validations";
import FormField from "layouts/auth/components/FormField";
import initialValues from "layouts/auth/components/schemas/initialValues";
import { Login } from "../../../services/authservices";
import { useAPICall } from "../../../utils/useapicall";
import { setRole, setProfileInfo, setPermission } from "actions/rolePermission";
import { useSelector, useDispatch } from "react-redux";
import { green } from '@mui/material/colors';
import { GetProfile } from "../../../services/accountantservices";
import { Cookie } from "@mui/icons-material";

const cookies = new Cookies();
function SignIn() {
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disButton, setDisButton] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const handleClickShowPassword = () => setShowPassword(true);
  const handleClickClosePassword = () => setShowPassword(false)

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const [{ isError, isLoading, response }, setAPICall] = useAPICall(Login, { onLoad: false });
  const [{ isLoading: isGetLoading, isError: isGetError, response: profileResponse }, getProfileAPICall] = useAPICall(GetProfile, { onLoad: false });

  useEffect(() => {
    const Token = cookies.get('admin_access_token');
    if (Token) {
      navigate('/admin/client/list');
    }
  }, []);

  useEffect(async () => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setDisButton(false);
      setLoading(false);
      setShowSnackbar(true);
      setTimeout(() => {
        setShowSnackbar(false);
      }, 3000);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      cookies.set('admin_access_token', response.data.data.access_token, { path: '/' });
      await getProfileAPICall({ payload: [] });
      // navigate('/accountants/list');
    }
  }, [response]);

  useEffect(() => {

    if (isGetLoading) {
      return () => null;
    }

    if (isGetError) {
      return () => null;
    }

    if (profileResponse && profileResponse.data.isSuccess === true) {
      dispatch(setProfileInfo(profileResponse.data.data));
      cookies.set('admin_profile_info', profileResponse.data.data, { path: '/' });

      if (
        profileResponse.data.data &&
        profileResponse.data.data.user &&
        profileResponse.data.data.user.userType &&
        profileResponse.data.data.user.userType != ''
      ) {
        dispatch(setRole(profileResponse.data.data.user.userType));
        if (profileResponse.data.data.user &&
          profileResponse.data.data.user.permissions &&
          profileResponse.data.data.user.permissions[0] &&
          profileResponse.data.data.user.permissions[0].role &&
          profileResponse.data.data.user.permissions[0].role.grants
        ) {
          const adminPermission = profileResponse.data.data.user.permissions[0].role.grants;
          dispatch(setPermission(adminPermission));
          cookies.set('permissions', adminPermission, { path: '/' });
        }
      }
      navigate('/admin/client/list');
    }
  }, [profileResponse])

  const onSubmit = async (values) => {
    setLoading(true);
    setDisButton(true);
    await setAPICall({
      payload: [
        {
          "email": `${values.email}`,
          "password": `${values.password}`
        }
      ]
    })
  };


  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          sx={{
            backgroundColor: "#80D6C3",
          }}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Welcome to Paynthr
          </MDTypography>
        </MDBox>
        <Formik initialValues={initialValues}
          validationSchema={SignInSchema}
          onSubmit={onSubmit}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form autoComplete="off">
              <div>
                <MDBox pt={4} pb={3} px={3}>
                  <MDBox mb={2}>
                    <MDBox mb={2}>
                      <FormField
                        type="email"
                        name="email"
                        label="Email"
                      />

                    </MDBox>
                    <MDBox mb={2}>
                      <FormField
                        type={showPassword ? "text" : "password"}
                        label="Password"
                        name="password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton sx={{ fontSize: '0.9rem' }} >
                                {!showPassword ? <VisibilityOff onClick={handleClickShowPassword} /> : <Visibility onClick={handleClickClosePassword} />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}

                      />
                      {/* <InputAdornment sx={{ justifyContent: "end", mr: 2 }} >
                        <IconButton
                          sx={{ mt: -8.5 }}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment> */}
                    </MDBox>
                    <MDBox>
                      <MDTypography
                        component={Link}
                        to="/admin/reset-password"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                        sx={{
                          cursor: "pointer", float: "right",
                          padding: " 0px 0px 40px "
                        }}
                      >
                        Forgot Password
                      </MDTypography>
                    </MDBox>
                    {/* <MDBox display="flex" alignItems="center" ml={-1}>
                    <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={handleSetRememberMe}
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Remember me
                    </MDTypography>
                  </MDBox> */}
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        disabled={disButton}
                        type="submit"
                        variant="gradient"
                        color="info"
                        fullWidth>
                        sign in
                      </MDButton>
                      {loading && (
                        <CircularProgress
                          size={35}
                          sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                          }}
                        />
                      )}
                    </MDBox>
                    {/* <MDBox mt={3} mb={1} textAlign="center">
                    <MDTypography variant="button" color="text">
                      Don&apos;t have an account?{" "}
                      <MDTypography
                        component={Link}
                        to="/sign-up"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Sign up
                      </MDTypography>
                    </MDTypography>
                  </MDBox> */}
                  </MDBox>
                </MDBox>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
      <MDBox>
        <MDSnackbar
          color="error"
          icon="warning"
          title="Login Failed."
          content="Your Email or Password is Incorrect."
          open={showSnackbar}
          // autoHideDuration={3000}
          close={() => setShowSnackbar(false)}
        />
      </MDBox>
    </BasicLayout >
  );
}

export default SignIn;
