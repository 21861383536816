import React from 'react'
import { useState, useEffect } from "react";
import DataTable from "../../components/Tables/DataTable";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import UserHoc from 'hoc/userHoc';
import { GetClassesList, EditClass } from 'services/userservices';
import { useAPICall } from 'utils/useapicall';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import { green, yellow } from '@mui/material/colors';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useLocation } from 'react-router-dom';
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import MDButton from "components/MDButton";
import EditClasses from './edit-classes';
import { eventBus } from 'utils/eventbus';
import LoaderPng from "assets/images/loders/Loading-screen-smalls.gif"
import { Grid } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import Loader from 'layouts/accountants/user/components/imgLoader/loader';
import image from './output-onlinegiftools.gif'
import AddClassDialog from 'layouts/accountants/user/invoice/addnewclass.js';
import DataTable1 from "components/NewTable/table";

const Classes = ({ businessId, syncPercent, documentSource, showClasses }) => {
  const action = { route: "", edittooltip: "Edit", deletetooltip: "Delete" };
  const [classesList, setClassesList] = useState([]);
  const [classesId, setClassesId] = useState();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [totalRecords, setTotalRecords] = useState('');
  const [alert, setAlert] = useState(false);
  const [showList, setShowList] = useState(true);
  const [openEditClasses, setOpenEditClasses] = useState(false);
  const [openAddNewClass, setOpenAddNewClass] = useState(false);
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [{ isLoading: isClassesLoading, isError: isClassesError, response: ClassesResponse }, ClassesListAPICall] = useAPICall(GetClassesList, { onLoad: false });
  const [{ isLoading: isEditClassLoading, isError: isEditClassError, response: EditClassResponse }, EditClassListAPICall] = useAPICall(EditClass, { onLoad: false });
  const { pathname } = useLocation();

  eventBus.$on('onclickchangePages', (data) => OnclickchangePages(data));
  function OnclickchangePages(data) {
    setLoading(true);
    const dataStr = data.toString() + 0
    localStorage.setItem("offset", Number(dataStr))
    ClassesListAPICall({
      payload: [{ "businessId": `${businessId}`, "keyword": `${search}`, 'offset': Number(dataStr) }]
    });
  }


  useEffect(async () => {
    const queryParameters = new URLSearchParams(window.location.search)
    const keyword = queryParameters.get("keyword");
    const ThisOffsetuse = localStorage.getItem("offset");
    if (keyword) {
      setSearch(keyword);
      setLoading(true);
    } else {
      setLoading(true);
      await ClassesListAPICall({
        payload: [{ "businessId": `${businessId}`, "keyword": `${search}`, 'offset': ThisOffsetuse }]
      })
    }
  }, []);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const keyword = queryParameters.get("keyword");
    if (!keyword) {
      window.history.pushState('data', 'title', `${pathname}`);
    }
  }, [search]);

  useEffect(() => {
    eventBus.$emit("OnSetBlur", loading);
    eventBus.$emit("OnSetBlurEdit", loading);
    // loading === true ? setLoaderClass({ opacity: '0.4', borderRadius: '13px' }) : setLoaderClass({})
  }, [loading])

  eventBus.$on('reloadClassDetailsList', (data) => reloadDetailsTab(data));
  function reloadDetailsTab(data) {
    const ThisOffsetuse = localStorage.getItem("offset");
    setLoading(true);
    ClassesListAPICall({
      payload: [{ "businessId": `${businessId}`, 'offset': ThisOffsetuse }]
    });
  }

  eventBus.$on('AddnewClassComplete', (data) => AddnewClassComplete(data));
  function AddnewClassComplete(data) {
    const ThisOffsetuse = localStorage.getItem("offset");
    setLoading(true);
    ClassesListAPICall({
      payload: [{ "businessId": `${businessId}`, 'offset': ThisOffsetuse }]
    });
  }

  useEffect(() => {
    const ThisOffsetuse = localStorage.getItem("offset");
    const timeOutId = setTimeout(async () =>
      await ClassesListAPICall({
        payload: [{ "businessId": `${businessId}`, "keyword": `${search}`, 'offset': ThisOffsetuse }]
      }), 500);
    return () => clearTimeout(timeOutId);
  }, [search]);

  useEffect(() => {
    if (isEditClassLoading) {
      return () => null;
    }
    if (isEditClassError) {
      setLoading(false);
      setBtnDisable(false);
      return () => null;
    }
    if (EditClassResponse && EditClassResponse.data.isSuccess === true) {
      const ThisOffsetuse = localStorage.getItem("offset");
      setLoading(false);
      ClassesListAPICall({
        payload: [{ "businessId": `${businessId}`, 'offset': ThisOffsetuse }]
      });
    }
  }, [EditClassResponse]);

  useEffect(() => {
    if (isClassesLoading) {
      return () => null;
    }
    if (isClassesError) {
      setLoadingCircul(false);
      setLoading(false);
      return () => null;
    }
    if (ClassesResponse && ClassesResponse.data.isSuccess === true) {
      setTotalRecords(ClassesResponse.data.data.totalRecords);
      const data = ClassesResponse.data.data.records.map((prop, key) => {
        const Createdate = new Date(prop.createdAt);
        return {
          name: prop.name,
          createdat: Createdate.toLocaleDateString(),
          action: ((documentSource === 'Paynthr' ?
            <MDBox display="flex" justifyContent="center">
              <MDTypography
                variant="body2"
                color="secondary"
                sx={{ marginLeft: "-4px", fontSize: "1.20rem", cursor: 'pointer' }}
              >
                <Tooltip title={action.edittooltip} placement="top"
                  onClick={(value) => {
                    setClassesId(prop._id);
                    setOpenEditClasses(true);
                  }}
                >
                  <Icon>edit</Icon>
                </Tooltip>
              </MDTypography>
              <MDTypography variant="body2" color="secondary" sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: 'pointer' }}>
                <Tooltip title={action.deletetooltip} placement="top"
                  onClick={(value) => {
                    setClassesId(prop._id);
                    setAlert(true);
                  }}
                >
                  <Icon>delete</Icon>
                </Tooltip>
              </MDTypography>
            </MDBox> : syncPercent === 100 ? <MDBox display="flex" justifyContent="center">
              <MDTypography
                variant="body2"
                color="secondary"
                sx={{ marginLeft: "-4px", fontSize: "1.20rem", cursor: 'pointer' }}
              >
                <Tooltip title={action.edittooltip} placement="top"
                  onClick={(value) => {
                    setClassesId(prop._id);
                    setOpenEditClasses(true);
                  }}
                >
                  <Icon>edit</Icon>
                </Tooltip>
              </MDTypography>
              <MDTypography variant="body2" color="secondary" sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: 'pointer' }}>
                <Tooltip title={action.deletetooltip} placement="top"
                  onClick={(value) => {
                    setClassesId(prop._id);
                    setAlert(true);
                  }}
                >
                  <Icon>delete</Icon>
                </Tooltip>
              </MDTypography>
            </MDBox> : ''
          ))
        };
      }
      );
      setClassesList(data);
      setTimeout(() => {
        setShowList(true);
        setLoadingCircul(false);
        setLoading(false);
      }, 1000);
    }
  }, [ClassesResponse]);

  const dataTableData1 = {
    columns: [
      {
        Header: "NAME",
        accessor: "name",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Created Date", accessor: "createdat",
        Cell: ({ value }) => <DefaultCell value={value} />
      },
      {
        Header: "Action", accessor: "action",
        Cell: ({ value }) => <DefaultCell value={value} />
      },

    ],
    rows: classesList

  };

  const handleSearch = async (event) => {
    localStorage.setItem("offset", 0)
    setSearch(event.target.value);
    window.history.pushState('data', 'title', `${pathname}?keyword=${encodeURIComponent(event.target.value)}`);
    setLoadingCircul(true);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }
  const successDelete = async () => {
    setLoading(true);
    await EditClassListAPICall({
      payload: [
        {
          "isArchived": true,
          "classesId": `${classesId}`
        }
      ]
    })
    setAlert(false);
  };

  return (
    syncPercent < 100 ?
      <>
        <Grid item xs={12} >
          <MDBox mt={5} mb={5}>
            <MDTypography
              variant="body2"
              color="secondary"
              sx={{
                width: '100%', fontSize: "1.20rem", display: 'flex',
                justifyContent: 'center', mt: 2
              }}
            >
              {`Please bear with us while we retrieve information from QBO. This process may take a few moments...`}
            </MDTypography>
            <MDBox sx={{
              width: '100%', display: 'flex',
              justifyContent: 'center', fontSize: "1.20rem"
            }}>
              <div>
                <img width={23} height={23} style={{ marginBottom: '-6px', marginRight: '8px' }} src={image} />
              </div>
              <MDTypography sx={{ mr: 2 }} fontSize="1.20rem" color={'warning'} >({syncPercent}% completed)</MDTypography>
            </MDBox>
          </MDBox>
        </Grid>
      </> :
      !showClasses
        ?
        <>
          <Grid item xs={12} >
            <MDBox mt={'10%'} mb={'10%'} display='flex' justifyContent='center' >
              <MDTypography color='secondary'>
                The QBO classes feature is currently turned off.
              </MDTypography>
            </MDBox>
          </Grid>
        </>
        :
        showList && <>
          <MDBox px={2} sx={{ pr: 3, mb: 2 }} component="form" display="flex" justifyContent="space-between" alignItems="center">
            <MDTypography variant="h5" fontWeight="medium">
              Classes
            </MDTypography>
            <MDBox px={2} display="flex" >
              <MDBox sx={{ pr: 3, mb: 2 }}>
                <MDInput type="search" label="Search Classes" onKeyDown={handleKeyDown} variant="standard" onChange={handleSearch} />
              </MDBox>
              <MDBox sx={{ pr: 3, mb: 2 }} >
                <MDButton
                  variant="gradient"
                  color="info"
                  disabled={loading}
                  onClick={() => setOpenAddNewClass(true)}
                >
                  Add Class
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
          {
            <MDBox my={5} sx={{ mt: 1 }}>
              <DataTable1 table={dataTableData1} entriesPerPage={false} pagination={true} showTotalEntries={true} response={totalRecords} showCheck={false} ClientHeigth={true} />
            </MDBox>
          }
          <MDBox display="flex" justifyContent="center" >
            {loading && <Loader />}
          </MDBox>
          <MDBox>
            <Dialog
              open={alert}
              onClose={() => setAlert(false)}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "450px",
                  },
                },
              }}
            >
              <DialogContent sx={{ overflowY: 'hidden' }}>
                <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                  <h3>Are you sure?</h3>
                </DialogContentText>
                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                  <MDTypography
                    variant="body2"
                    color="secondary"
                    sx={{ fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 4 }}
                  >
                    Once you delete this Class there is no way of getting data back?
                  </MDTypography>
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "center", mt: 2 }} >
                <MDButton
                  variant="gradient"
                  color="success"
                  onClick={successDelete} >
                  Yes, delete it!
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="error"
                  onClick={() => setAlert(false)}
                >Cancel
                </MDButton>
              </DialogActions>
            </Dialog>
          </MDBox >
          <MDBox>
            {loadingCircul && (
              <CircularProgress
                size={50}
                sx={{
                  color: green[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </MDBox>
          <MDBox>
            <Dialog
              open={openEditClasses}
              onClose={() => setOpenEditClasses(false)}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "570px",
                    minHeight: '20%'
                  },
                },
              }}
            >   <DialogTitle sx={{
              justifyContent: "center",
              display: 'flex'
            }}>Edit Classes</DialogTitle>
              <DialogContent>
                <EditClasses classesId={classesId} businessId={businessId} onClose={() => setOpenEditClasses(false)}
                />
              </DialogContent>
            </Dialog>
          </MDBox>
          <MDBox>
            <Dialog
              open={openAddNewClass}
              onClose={() => setOpenAddNewClass(false)}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "570px",
                  },
                },
              }}
            >   <DialogTitle sx={{
              justifyContent: "center",
              display: 'flex'
            }}>Add Class</DialogTitle>
              <DialogContent  >
                <AddClassDialog invoiceId={''} businessId={businessId} onClose={() => setOpenAddNewClass(false)}
                />
              </DialogContent>
            </Dialog>
          </MDBox>
        </>
  )
}

export default UserHoc(Classes)