import Request from "./adminapicallservice";
const PageLimit = process.env.REACT_APP_PAGE_LIMIT;


export const AddNewAdminclient = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/clients`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetClientDetailsData = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/clients/${params.bussinessId}`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const EditClientInfo = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/clients/${params.bussinessId}`;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetAdminClientBilling = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/clients/${params?.bussinessId}/invoices`;
        delete params.bussinessId;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}
