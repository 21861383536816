import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Grid from "@mui/material/Grid";
import Checkbox from '@mui/material/Checkbox';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { eventBus } from 'utils/eventbus';
import { EditVendorSchema } from "../components/schemas/editvendorvalidation";
import FormField from "layouts/admin/accountants/components/FormField";

import FormControlLabel from "@mui/material/FormControlLabel";
import { useAPICall } from "utils/useapicall";
import { GetVendorDetails, EditVendors } from "services/userservices";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';

function EditVendor({ onClose, vendorId, businessId }) {
    const [btnDisable, setBtnDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [address, setAddress] = useState('');
    const [vendorName, setVendorName] = useState('');
    const [phone, setPhone] = useState('');
    const [emailId, setEmailId] = useState('');
    const [netTerms, setNetTerms] = useState('');
    const [accountNo, setAccountNo] = useState('');
    const [showselectedrowsData, setShowSelectedrowsData] = useState("");
    const [vendor1099, setVendor1099] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [trackPayment, setTrackPayment] = useState(false);
    const [{ isLoading: isEditVendorLoading, isError: isEditVendorError, response: EditVendorResponse }, EditVendorListAPICall] = useAPICall(EditVendors, { onLoad: false });
    const [{ isLoading: isVendorLoading, isError: isVendorError, response: VendorResponse }, VendorDetailsAPICall] = useAPICall(GetVendorDetails, { onLoad: false });



    useEffect(async () => {
        await VendorDetailsAPICall({
            payload: [{ "VendorId": `${vendorId}` }]
        });
        setLoading(true);
    }, []);

    useEffect(() => {
        if (isVendorLoading) {
            return () => null;
        }
        if (isVendorError) {
            setLoading(false);
            return () => null;
        }
        if (VendorResponse && VendorResponse.data.isSuccess === true) {
            setShowSelectedrowsData(VendorResponse?.data?.data?.vendor.name ? VendorResponse?.data?.data?.vendor.name : VendorResponse?.data?.data?.vendor.legalName);
            setVendorName(VendorResponse?.data?.data?.vendor.name !== '---' ? VendorResponse?.data?.data?.vendor.name : '');
            setPhone(VendorResponse?.data?.data?.vendor.phone !== '---' ? VendorResponse?.data?.data?.vendor.phone : '');
            setAddress(VendorResponse?.data?.data?.vendor?.billingAddress?.line1 !== '---' ? VendorResponse?.data?.data?.vendor?.billingAddress?.line1 : '');
            setAccountNo(VendorResponse?.data?.data?.vendor.accountNo !== '---' ? VendorResponse?.data?.data?.vendor.accountNo : '');
            setEmailId(VendorResponse?.data?.data?.vendor.email !== '---' ? VendorResponse?.data?.data?.vendor.email : '');
            setPhone(VendorResponse?.data?.data?.vendor.phone !== '---' ? VendorResponse?.data?.data?.vendor.phone : '');
            setNetTerms(VendorResponse?.data?.data?.vendor.balance);
            setVendor1099(VendorResponse?.data?.data?.vendor.vendor1099);
            setTrackPayment(VendorResponse?.data?.data?.vendor.vendor1099);
            setShowDetails(true);
        }
    }, [VendorResponse]);

    useEffect(() => {
        if (isEditVendorLoading) {
            return () => null;
        }
        if (isEditVendorError) {
            setLoading(false);
            setBtnDisable(false);
            return () => null;
        }
        if (EditVendorResponse && EditVendorResponse.data.isSuccess === true) {
            setLoading(false);
            setBtnDisable(false);
            onClose();
            eventBus.$emit("reloadDetailsList", EditVendorResponse);
        }
    }, [EditVendorResponse]);

    let initialValues = {
        vendor: vendorName,
        accountno: accountNo,
        address: address,
        email: emailId,
        phone: phone,
        businessid: "",
        net: netTerms,
        checkbox: vendor1099,
        account: ""
    };

    useEffect(() => {


        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [showselectedrowsData, address]);

    const handleSubmit = async (values) => {
        setBtnDisable(true);
        await EditVendorListAPICall({
            payload: [
                {
                    "name": values.vendor,
                    "email": values.email,
                    "phone": values.phone,
                    "accountNo": values.accountno,
                    "vendor1099": trackPayment,
                    "billingAddress": {
                        "line1": values.address,
                        "line2": null,
                        "line3": null,
                        "line4": null,
                        "line5": null,
                        "city": "Palo Alto",
                        "state": "CA",
                        "zip": "94303",
                        "location": null
                    },
                    "VendorId": `${vendorId}`,
                }
            ]
        })
    };
    const handleChange = () => {
        setTrackPayment(!trackPayment)
    }
    return (
        <>{showDetails &&
            <>
                < MDBox mt={0} >
                    <Grid spacing={3}>
                        <Grid item xs={12}>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={EditVendorSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ values, errors, touched, isSubmitting }) => (
                                    <Form autoComplete="off">
                                        <MDBox p={1}>
                                            <MDBox>
                                                <MDBox mt={1.625}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Vendor *"
                                                                name="vendor"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Account Number"
                                                                name="accountno"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Address"
                                                                name="address"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="email"
                                                                label="Email *"
                                                                name="email"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Phone Number"
                                                                name="phone"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <MDBox>
                                                                <Field
                                                                    as={FormControlLabel}
                                                                    type="checkbox"
                                                                    name="checkbox"
                                                                    control={<Checkbox />}
                                                                    label="Track payments for 1099"
                                                                    checked={trackPayment}
                                                                    onChange={handleChange}
                                                                    sx={{ mt: 1.5 }}
                                                                />
                                                            </MDBox>
                                                        </Grid>
                                                    </Grid>
                                                    {/* <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Net Terms"
                                                                name="net"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Business ID Number/Social Security Number"
                                                                name="businessid"
                                                            />
                                                        </Grid>
                                                    </Grid> */}
                                                </MDBox>
                                                <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                                                    <MDButton
                                                        disabled={btnDisable}
                                                        variant="gradient"
                                                        color="light"
                                                        onClick={onClose}
                                                    >
                                                        Cancel
                                                    </MDButton>
                                                    <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                        <MDButton
                                                            disabled={btnDisable}
                                                            type="submit"
                                                            variant="gradient"
                                                            color="info"
                                                        >
                                                            {btnDisable ? "updating..." : "update"}
                                                        </MDButton>
                                                    </MDBox>
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>

                                    </Form>
                                )}
                            </Formik>

                        </Grid>

                    </Grid>
                </MDBox>

            </>
        }
            <MDBox>
                {loading && (
                    <CircularProgress
                        size={50}
                        sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </MDBox>
        </>
    );
}

export default EditVendor;
