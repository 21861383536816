import axios from 'axios';
import { BASE_URL_API } from "helpers/config";
import { getAdminToken } from 'helpers/getadmintoken';


class Request {
    constructor(api_url, params) {
        const token = getAdminToken();
        this.url = `${BASE_URL_API}` + `${api_url}`;
        this.params = params;
        this.header = token ? { headers: { Authorization: `${token}` } } : '';
    }

    async get() {
        return new Promise(async (resolve, reject) => {
            axios.get(this.url, this.header).then((response) => {
                resolve(response);
            }).catch(async (error) => {
                reject(error);
            });
        });
    }

    async post() {
        return new Promise(async (resolve, reject) => {
            axios.post(this.url, this.params, this.header).then((response) => {
                resolve(response);
            }).catch(async (error) => {
                reject(error);
            });
        });
    }

    async patch() {
        return new Promise(async (resolve, reject) => {
            axios.patch(this.url, this.params, this.header).then((response) => {
                resolve(response);
            }).catch(async (error) => {
                reject(error);
            });
        });
    }

    async put() {
        return new Promise(async (resolve, reject) => {
            axios.put(this.url, this.params, this.header).then((response) => {
                resolve(response);
            }).catch(async (error) => {
                reject(error);
            });
        });
    }

    async delete() {
        return new Promise(async (resolve, reject) => {
            axios.delete(this.url, this.header).then((response) => {
                resolve(response);
            }).catch(async (error) => {
                reject(error);
            });
        });
    }

}


export default Request;
// export default connect(mapStateToProps, { setToken, setPermission })(Request);