import * as Yup from "yup";

const AddNewProduct1 = Yup.object().shape({
    product: Yup.string()
        .trim()
        .required('Product name is required.')
        .min(3, 'Product name should be more than 3 characters.'),
    unitprice: Yup.string().matches(/^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/, "Unit Price must be a number type")
        .nullable()
        .required("Unit Price is required"),
    qtyOnHand: Yup.string().matches(/^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/, "Qty On Hand must be a number type")
        .nullable()
        .required("Quantity On Hand is required"),
    // producttype: Yup.string().required('Product Type is required.'),
});

export default AddNewProduct1