import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import NumberFormat from "react-number-format";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from "@mui/material/Tooltip";

export const handleValueChange = (name, setFieldValue) => (val) =>
    setFieldValue(name, val.floatValue);

const CurrencyFieldText = ({ currencySymbol, priceMismatch = false, ...props }) => {
    const [displayValue, setDisplayValue] = useState();
    return (
        priceMismatch === true ?
            <NumberFormat
                customInput={TextField}
                variant="standard"
                isNumericString={true}
                thousandSeparator={true}
                value={displayValue}
                decimalScale={2}
                fixedDecimalScale={true}
                sx={{ color: '#000' }}
                onValueChange={(vals) => setDisplayValue({ value: vals.formattedValue })}
                InputProps={{
                    startAdornment: <span>{`$`}</span>,
                    style: { color: '#000' },
                    endAdornment: (
                        <Tooltip title={props.ErrorToolTip} placement="top">
                            <InputAdornment position="end">
                                <ErrorOutlineIcon sx={{ fontSize: '1rem !important', mr: '14px', color: 'red' }} />
                            </InputAdornment>
                        </Tooltip>
                    ),
                }}
                {...props}
            /> :
            <NumberFormat
                customInput={TextField}
                variant="standard"
                isNumericString={true}
                thousandSeparator={true}
                value={displayValue}
                decimalScale={2}
                fixedDecimalScale={true}
                sx={{ color: '#000' }}
                onValueChange={(vals) => setDisplayValue({ value: vals.formattedValue })}
                InputProps={{
                    startAdornment: <span>{`$`}</span>,
                    style: { color: '#000' },
                }}
                {...props}
            />
    );
};


export default CurrencyFieldText;
