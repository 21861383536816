import * as Yup from "yup";

export const EditCategorySchema = Yup.object().shape({
    category: Yup.string()
        .trim()
        .required('Category name is required.')
        .min(3, 'Category  name should be more than 3 characters.'),
    classification: Yup.string()
        .trim()
        .required('Classification is required.'),
    // .min(3, 'Category  name should be more than 3 characters.'),
    accountType: Yup.string()
        .trim()
        .required('AccountType name is required.'),
    // accountSubType: Yup.string()
    //     .trim()
    //     .required('AccountSubType name is required.'),
    // .min(3, 'Category  name should be more than 3 characters.')
});