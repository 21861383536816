import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import NumberFormat from "react-number-format";

export const handleValueChange = (name, setFieldValue) => (val) =>
    setFieldValue(name, val.floatValue)

const CurrencyFieldText = ({ currencySymbol, ...props }) => {
    const [displayValue, setDisplayValue] = useState();

    return (
        <NumberFormat
            customInput={TextField}
            variant="standard"
            isNumericString={true}
            fixedDecimalScale
            thousandSeparator={true}
            value={displayValue}
            decimalScale={2}
            sx={{ color: '#000' }}
            onValueChange={(vals) => setDisplayValue({ value: vals.formattedValue })}
            InputProps={{
                startAdornment: <span>{`$`}</span>,
                style: { color: '#000' }
            }}
            {...props}
        />
    );
};


export default CurrencyFieldText;
