import * as Yup from "yup";

export const EditProfileSchema = Yup.object().shape({
    firstname: Yup.string()
        .trim()
        .nullable()
        .required('First name is required.')
        .min(3, 'First name should be more than 3 characters.'),
    lastname: Yup.string()
        .trim()
        .nullable()
        .required('Last name is required.')
        .min(3, 'Last name should be more than 3 characters.'),
    businessname: Yup.string()
        .trim()
        .min(3, 'Business name should be more than 3 characters.'),
    phone: Yup.string()
        .nullable()
        .min(10, "Phone number is invalid")
        // .max(10, "Phone Number is invalid")
        .matches(
            /^[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
            "Phone number is not valid"
        ),

});

export const CreatePassSchema = Yup.object().shape({
    curpassword: Yup.string()
        .trim()
        .required('Password is required.')
        .min(6, 'Your password should be more than 6 characters.'),
    password: Yup
        .string()
        .required('New password is required')
        .matches(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{6,}$",
            "Password must contain at least 1 uppercase , 1 lowercase , 1 number, 1 special character and length between 6 to 20"
        ),
    repeatPassword: Yup.string()
        .trim()
        .required('Confirm new password is required.')
        .oneOf([Yup.ref("password"), null], "Your password doesn't match."),
});
