import { Formik, Form } from "formik";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "../../../components/LayoutContainers/DashboardLayout";
import { AddNewSchema } from "layouts/clients/components/schemas/editclient";
import FormField from "layouts/clients/components/FormField";
import { Link, useNavigate } from "react-router-dom";
import { useAPICall } from "../../../../utils/useapicall";
import { GetuserClientDetails } from "../../../../services/clientservices";
import { useMaterialUIController } from "context";
import Icon from "@mui/material/Icon";
import MDSnackbar from "components/MDSnackbar";
import VendorSettings from "../setting";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import UserHoc from "hoc/userHoc";
import Loader from "layouts/accountants/user/components/imgLoader/loader";
import { eventBus } from "utils/eventbus";

function EditClients({ businessId }) {
    const navigate = useNavigate();
    const [profileEmail, setprofileEmail] = useState('');
    const [profileFname, setprofileFname] = useState('');
    const [profileLname, setprofileLname] = useState('');
    const [profileBuinessName, setprofileBusinessName] = useState('');
    const [profileNumber, setprofileNumber] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [bussinessId, setBusinessId] = useState(businessId);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState("");
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const [isDisable, setIsDisable] = useState(false);
    const [activeUser, setActiveUser] = useState(false);
    const [isOwnerAsigned, setIsOwnerAsigned] = useState(false);

    useEffect(() => {
        eventBus.$emit("OnSetBlur", !showForm);
        eventBus.$emit("OnSetBlurEdit", !showForm);
    }, [showForm])

    const sidenavItems = [
        { icon: "person", label: "profile", href: "profile" },
        { icon: "receipt_long", label: "basic info", href: "basic-info" },
    ];

    const renderSidenavItems = sidenavItems.map(({ icon, label, href, path }, key) => {
        const itemKey = `item-${key}`;

        return (
            <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
                <MDTypography
                    component="a"
                    href={`#${href}`}
                    path={path}
                    variant="button"
                    fontWeight="regular"
                    textTransform="capitalize"
                    sx={({
                        borders: { borderRadius },
                        functions: { pxToRem },
                        palette: { light },
                        transitions,
                    }) => ({
                        display: "flex",
                        alignItems: "center",
                        borderRadius: borderRadius.md,
                        padding: `${pxToRem(10)} ${pxToRem(16)}`,
                        transition: transitions.create("background-color", {
                            easing: transitions.easing.easeInOut,
                            duration: transitions.duration.shorter,
                        }),

                        "&:hover": {
                            backgroundColor: light.main,
                        },
                    })}
                >
                    <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
                        <Icon fontSize="small">{icon}</Icon>
                    </MDBox>
                    {label}
                </MDTypography>
            </MDBox>
        );
    });

    const initialValues = {
        name: "",
        firstname: profileFname,
        lastname: profileLname,
        clientname: profileBuinessName,
        phone: profileNumber,
        company: "",
        email: profileEmail,
        password: "",
        repeatPassword: "",
        curpassword: "",
        address1: "",
        address2: "",
        city: "",
        zip: "",
        twitter: "",
        facebook: "",
        instagram: "",
        publicEmail: "",
        bio: "",
        role: "",
    };

    const [{ isLoading: isGetDetailsLoading, isError: isGetDetailsError, response: clientDetailsDataResponse }, getClientDetailsAPICall] = useAPICall(GetuserClientDetails, { onLoad: false });

    useEffect(async () => {
        setBusinessId(businessId)
        await getClientDetailsAPICall({
            payload: [{ "bussinessId": `${bussinessId}` }]
        });
    }, []);


    useEffect(() => {

        if (isGetDetailsLoading) {
            return () => null;
        }

        if (isGetDetailsError) {
            return () => null;
        }

        if (clientDetailsDataResponse && clientDetailsDataResponse.data.isSuccess === true) {
            const adminresult = clientDetailsDataResponse?.data?.data?.client;
            setIsOwnerAsigned(adminresult?.ownerAssigned);
            setprofileEmail(adminresult?.owner?.email);
            setprofileFname(adminresult?.owner?.fname);
            setprofileLname(adminresult?.owner?.lname);
            setprofileBusinessName(adminresult?.name);
            setprofileNumber(adminresult?.owner?.phone);
            setActiveUser(adminresult?.isBlocked);
            setShowForm(true);
        }
    }, [clientDetailsDataResponse]);

    const handleSubmit = async (values) => {
        setIsDisable(true);
    };

    const ToggleChange = async () => {
        setActiveUser(!activeUser);
    }

    return (
        <>
            {!showForm ?
                <MDBox display="flex" justifyContent="center" >
                    {<Loader />}
                </MDBox>
                :
                <>
                    < Grid item xs={12}>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                        >
                            {({ values, errors, touched, isSubmitting }) => (
                                <Form autoComplete="off">
                                    <MDBox lineHeight={0}>
                                        <MDTypography variant="h5">Business Info</MDTypography>
                                    </MDBox>
                                    <MDBox mt={1.625}>
                                        <Grid item xs={12}>
                                            <FormField
                                                type="text"
                                                label="Business Name *"
                                                name="clientname"
                                                disabled
                                            />
                                        </Grid>
                                    </MDBox>
                                    <MDBox mt={2} width="100%" display="flex">
                                        <Grid item xs={12} sm={12}>
                                            <FormControlLabel disabled control={<Checkbox checked={!activeUser} onChange={ToggleChange} />} label="Is Active" /></Grid>
                                    </MDBox>
                                </Form>
                            )}
                        </Formik>
                        {/* </Card> */}
                    </Grid >
                    <MDBox>
                        <MDSnackbar
                            color="success"
                            icon="done"
                            title="Success"
                            content="Client Updated Successfully!"
                            open={showSnackbar}
                            // autoHideDuration={3000}
                            close={() => setShowSnackbar(false)}
                        />
                        <MDSnackbar
                            color="error"
                            icon="warning"
                            title="Failed."
                            content={ErrorMessage}

                            open={showErrorSnackbar}
                            close={() => setShowErrorSnackbar(false)}
                        />
                    </MDBox>
                </>
            }
        </>
    );
}

export default UserHoc(EditClients);