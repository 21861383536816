import * as Yup from "yup";

const AddNewCustomer = Yup.object().shape({
    customer: Yup.string()
        .trim()
        .required('Customer name is required.')
        .min(3, 'Customer name should be more than 3 characters.'),
    zip: Yup.string().matches(/^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/, "Zip code must be a number type")
        .nullable()
        .min(5, 'Zip code must be 5 characters.')
        .max(5, 'Zip code must be 5 characters.'),
    shippingzip: Yup.string().matches(/^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/, "Zip code must be a number type")
        .nullable()
        .min(5, 'Zip code must be 5 characters.')
        .max(5, 'Zip code must be 5 characters.'),
});

export default AddNewCustomer