import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import AddNewIncomeAccountSh from './components/schemas/addincomeAccount';
import FormField from "layouts/admin/accountants/components/FormField";
import { useAPICall } from "utils/useapicall";
import { PostIncomeAccountList } from "../../../../services/userservices";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import { eventBus } from "utils/eventbus";

function AddNewIncomeAccount({ onClose, invoiceId, businessId }) {
    const [business_id, setBusiness] = useState(businessId);
    const [btnDisable, setBtnDisable] = useState(false);
    const [loading, setLoading] = useState(false);

    let initialValues = {
        income: "",
    };

    const [{ isLoading: isAddIncomeAccountLoading, isError: isAddIncomeAccountError, response: AddIncomeAccountResponse }, AddIncomeAccountListAPICall] = useAPICall(PostIncomeAccountList, { onLoad: false });

    useEffect(() => {
        if (isAddIncomeAccountLoading) {
            return () => null;
        }
        if (isAddIncomeAccountError) {
            setLoading(false);
            setBtnDisable(false);
            return () => null;
        }
        if (AddIncomeAccountResponse && AddIncomeAccountResponse.data.isSuccess === true) {
            setLoading(false);
            setBtnDisable(false);
            eventBus.$emit("AddnewIncomeAccountComplete", AddIncomeAccountResponse?.data?.data);
            onClose();
        }
    }, [AddIncomeAccountResponse]);

    const handleSubmit = async (values) => {
        setBtnDisable(true);
        await AddIncomeAccountListAPICall({
            payload: [
                {
                    "name": values?.income,
                    "classification": "Revenue",
                    "accountType": "Income",
                    "accountSubType": "SalesOfProductIncome",
                    "clientId": `${business_id}`
                }]
        })
    };

    return (
        <>{
            <>
                <MDBox mt={0} >
                    <Grid spacing={3}>
                        <Grid item xs={12}>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={AddNewIncomeAccountSh}
                                onSubmit={handleSubmit}
                            >
                                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                                    <Form autoComplete="off">
                                        <MDBox p={3} pl={'30%'}>
                                            <MDBox>
                                                <MDBox mt={1.625} display='flex' justifyContent='center' >
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={9}>
                                                            <FormField
                                                                type="text"
                                                                label="Name *"
                                                                name="income"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </MDBox>
                                                <MDBox mt={4} mb={-2} width="100%" display="flex" justifyContent="end">
                                                    <MDButton
                                                        disabled={btnDisable}
                                                        variant="gradient"
                                                        color="light"
                                                        onClick={onClose}
                                                    >
                                                        Cancel
                                                    </MDButton>
                                                    <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                        <MDButton
                                                            disabled={btnDisable}
                                                            type="submit"
                                                            variant="gradient"
                                                            color="info"
                                                        >
                                                            {btnDisable ? "Submitting..." : "Submit"}
                                                        </MDButton>
                                                    </MDBox>
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </MDBox>
            </>
        }
            <MDBox>
                {loading && (
                    <CircularProgress
                        size={50}
                        sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </MDBox>
        </>
    );
}

export default AddNewIncomeAccount;
