import { Formik, Form, Field, ErrorMessage } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import MDBox from "components/MDBox";
import * as Yup from "yup";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "../../clientuser/user/components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../clientuser/user/components/Navbars/DashboardNavbar";
import { AddNewSchema } from "layouts/clientuser/user/components/schemas/addnewvalidations";
import FormField from "layouts/admin/accountants/components/FormField";
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";
import MDSnackbar from "components/MDSnackbar";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useMaterialUIController } from "context";
import { useAPICall } from "utils/useapicall";
import { GetRestaurantdetails, EditRestaurantInfo } from "../../../services/clientservices";
import UserHoc from "hoc/userHoc";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { TextField } from "@mui/material";
import NumberFormat from "react-number-format";
import LoaderPng from "assets/images/loders/Loading-screen-smalls.gif"
import { EditRestorntSchema } from "layouts/components/schemas/editrestorantSchema";
import { isMobile } from 'react-device-detect';

function EditRestaurant({ onClose, restaurantId }) {
    const navigate = useNavigate();
    const [isDisable, setIsDisable] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [restaurantname, setRestaurantname] = useState('');
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [adminRoleId, setAdminRoleId] = useState("");
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [clientname, setClientName] = useState("");
    const [activeUser, setActiveUser] = useState(false);
    const [ownerRoleId, setOwnerRoleId] = useState('');
    const [phone, setPhone] = useState('');
    const [showadress, setShowadress] = useState(false);
    const [businessId, setBusinessId] = useState(location.pathname.split("/").slice(-2)[0]);
    const [controller] = useMaterialUIController();
    const [{ isLoading: isGetLoading, isError: isGetError, response: restaurantDataResponse }, getRestaurantAPICall] = useAPICall(GetRestaurantdetails, { onLoad: false });
    const [{ isError, isLoading, response }, setAPICall] = useAPICall(EditRestaurantInfo, { onLoad: false });


    useEffect(async () => {
        await getRestaurantAPICall({
            payload: [{ "restaurantId": restaurantId }]
        })
    }, [])


    useEffect(() => {
        if (isLoading) {
            return () => null;
        }
        if (isError) {
            setIsDisable(false);
            setShowErrorSnackbar(true);
            return () => null;
        }
        if (response && response.data.isSuccess === true) {
            setShowSnackbar(true);
            setIsDisable(false);
            setTimeout(() => {
                setShowSnackbar(false);
            }, 3000);
            onClose();
            // setTimeout(() => {
            //     navigate('/client/restaurant/list');
            // }, 4000);
        }
    }, [response]);


    useEffect(() => {

        if (isGetLoading) {
            return () => null;
        }

        if (isGetError) {
            return () => null;
        }

        if (restaurantDataResponse && restaurantDataResponse.data.isSuccess === true) {
            const restaurantresult = restaurantDataResponse?.data?.data?.restaurant;
            setRestaurantname(restaurantresult?.name);
            setClientName(restaurantresult?.business?.name);
            setAddress(restaurantresult?.address?.line1);
            setAddress2(restaurantresult?.address?.line2);
            setState(restaurantresult?.address?.state);
            setCity(restaurantresult?.address?.city);
            setZipCode(restaurantresult?.address?.zip);
            setShowadress(restaurantresult?.address?.line1 ? true : false);
            setShowForm(true);
        }
    }, [restaurantDataResponse]);


    const initialValues = {
        clientname: clientname,
        restaurantname: restaurantname,
        address: address,
        address2: address2,
        state: state,
        city: city,
        zip_code: zipCode,
    };

    const handleSubmit = async (values) => {
        setIsDisable(true);
        if (showadress) {
            await setAPICall({
                payload: [
                    {
                        "name": values?.restaurantname,
                        "address": {
                            "line1": values?.address,
                            "line2": values?.address2,
                            "city": values?.city,
                            "state": values?.state,
                            "zip": values?.zip_code
                        },
                        "businessId": restaurantId
                    }
                ]
            })
        } else {
            await setAPICall({
                payload: [
                    {
                        "name": values?.restaurantname,
                        "address": {
                            "line1": null,
                            "line2": null,
                            "city": null,
                            "state": null,
                            "zip": null,
                        },
                        "businessId": restaurantId
                    }
                ]
            })
        }

    };

    const handleChange = async () => {
        setShowadress(!showadress);
    }

    return (
        <>
            {
                showForm ?
                    <>
                        <MDBox mt={0} >
                            <Grid spacing={3}>
                                <Grid item xs={12}>
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={
                                            showadress ?
                                                Yup.object().shape({
                                                    clientname: !clientname ? Yup.string()
                                                        .trim()
                                                        .required('Client name is required.')
                                                        .min(3, 'Client name should be more than 3 characters.') : '',
                                                    restaurantname: Yup.string()
                                                        .trim()
                                                        .required('Restaurant name is required.')
                                                        .min(3, 'Restaurant name should be more than 3 characters.'),
                                                    address: Yup.string()
                                                        .trim()
                                                        .required('Address 1 is required.')
                                                        .min(3, 'Address 1 should be more than 3 characters.'),
                                                    zip_code: Yup.string().matches(/^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/, "Zip code must be a number type")
                                                        .nullable()
                                                        .min(5, 'Zip code must be 5 characters.')
                                                        .max(5, 'Zip code must be 5 characters.'),
                                                }) :
                                                Yup.object().shape({
                                                    clientname: !clientname ? Yup.string()
                                                        .trim()
                                                        .required('Client name is required.')
                                                        .min(3, 'Client name should be more than 3 characters.') : '',
                                                    restaurantname: Yup.string()
                                                        .trim()
                                                        .required('Restaurant name is required.')
                                                        .min(3, 'Restaurant name should be more than 3 characters.'),
                                                    zip_code: Yup.string().matches(/^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/, "Zip code must be a number type")
                                                        .nullable()
                                                        .min(5, 'Zip code must be 5 characters.')
                                                        .max(5, 'Zip code must be 5 characters.'),
                                                })
                                        }
                                        onSubmit={handleSubmit}
                                    >
                                        {({ values, errors, touched, isSubmitting }) => (
                                            <Form autoComplete="off">
                                                <MDBox p={3}>
                                                    <MDBox>
                                                        <MDBox>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} sm={12}>
                                                                    <FormField
                                                                        type="text"
                                                                        label="Business Name *"
                                                                        name="clientname"
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} sm={12}>
                                                                    <FormField
                                                                        type="text"
                                                                        label="Restaurant Name *"
                                                                        name="restaurantname"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} sm={6}>
                                                                    <MDBox>
                                                                        <Field
                                                                            as={FormControlLabel}
                                                                            type="checkbox"
                                                                            name="addressfull"
                                                                            control={<Checkbox />}
                                                                            label="Restaurant Address"
                                                                            checked={showadress}
                                                                            onChange={handleChange}
                                                                            sx={{ mb: 1 }}
                                                                        />
                                                                    </MDBox>
                                                                </Grid>
                                                            </Grid>
                                                            {
                                                                showadress && <>
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={12} sm={12}>
                                                                            <FormField
                                                                                type="text"
                                                                                label="Address 1 *"
                                                                                name="address"
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={12} sm={6}>
                                                                            <FormField
                                                                                type="text"
                                                                                label="Address 2"
                                                                                name="address2"
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={6}>
                                                                            <FormField
                                                                                type="text"
                                                                                label="State"
                                                                                name="state"
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={12} sm={6}>
                                                                            <FormField
                                                                                type="text"
                                                                                label="City"
                                                                                name="city"
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={6}>
                                                                            <FormField
                                                                                type="text"
                                                                                label="Zip Code"
                                                                                name="zip_code"
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            }

                                                        </MDBox>
                                                        <MDBox mt={3} mb={-2} width="100%" display="flex" justifyContent="end">
                                                            {!isMobile ?
                                                                <MDButton
                                                                    variant="gradient"
                                                                    color="light"
                                                                    onClick={onClose}
                                                                // component={Link}
                                                                // to="/client/restaurant/list"
                                                                >
                                                                    Cancel
                                                                </MDButton>
                                                                :
                                                                <MDButton
                                                                    sx={{
                                                                        marginTop: '0px!important',
                                                                        '@media(max-width:575px)': {
                                                                            marginTop: '8px!important',
                                                                        }
                                                                    }}
                                                                    variant="gradient"
                                                                    color="light"
                                                                    onClick={onClose}
                                                                // component={Link}
                                                                // to="/client/restaurant/list"
                                                                >
                                                                    Cancel
                                                                </MDButton>
                                                            }
                                                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                                <MDButton
                                                                    sx={{ marginLeft: '4px!important' }}
                                                                    disabled={isDisable}
                                                                    type="submit"
                                                                    variant="gradient"
                                                                    color="info"
                                                                >
                                                                    {isDisable ? "updating..." : "update"}
                                                                </MDButton>
                                                            </MDBox>
                                                        </MDBox>
                                                    </MDBox>
                                                </MDBox>
                                            </Form>
                                        )}
                                    </Formik>
                                </Grid>
                            </Grid>
                        </MDBox>
                        <MDBox>
                            <MDSnackbar
                                color="success"
                                icon="done"
                                title="Success."
                                content="Restaurant Updated Successfully!"
                                open={showSnackbar}
                                // autoHideDuration={3000}
                                close={() => setShowSnackbar(false)}
                            />
                            <MDSnackbar
                                color="error"
                                icon="warning"
                                title="Failed."
                                content="Restaurant Updation failed!"
                                open={showErrorSnackbar}
                                // autoHideDuration={3000}
                                close={() => setShowErrorSnackbar(false)}
                            />
                        </MDBox>
                    </>
                    :
                    <MDBox>
                        {
                            <CircularProgress
                                size={50}
                                sx={{
                                    color: green[500],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        }
                    </MDBox>

            }
        </>
    );
}

export default UserHoc(EditRestaurant);
