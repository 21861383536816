import React, { useState, useEffect } from "react";
//formik
import { Formik, Form, Field, ErrorMessage } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Checkbox from '@mui/material/Checkbox';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { eventBus } from 'utils/eventbus';

// NewUser layout schemas for form and form feilds
import { EditVendorSchema } from "layouts/accountants/user/components/schemas/editvendorvalidation";
import FormField from "layouts/admin/accountants/components/FormField";

//select

import FormControl from '@mui/material/FormControl';
import FormControlLabel from "@mui/material/FormControlLabel";
import { useAPICall } from "utils/useapicall";
import { EditVendorDetails, GetInvoiceDetails, GetVendorList } from "../../../../services/userservices";
import CircularProgress from '@mui/material/CircularProgress';
import { green, pink, yellow } from '@mui/material/colors';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from "@mui/material/MenuItem";


function EditVendorDialog({ onClose, invoiceId, businessId }) {
    const [invoice_id, setInvoiceId] = useState(invoiceId);
    const [business_id, setBusiness] = useState(businessId);
    const [btnDisable, setBtnDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [address, setAddress] = useState('');
    const [vendorName, setVendorName] = useState('');
    const [phone, setPhone] = useState('');
    const [emailId, setEmailId] = useState('');
    const [website, setWebsite] = useState('');
    const [netTerms, setNetTerms] = useState('');
    const [vendorList, setVendorList] = useState([]);
    const [accountNo, setAccountNo] = useState('');
    const [showselectedrowsData, setShowSelectedrowsData] = useState("");
    const [vendorId, setvendorId] = useState('');
    const [vendor1099, setVendor1099] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [trackPayment, setTrackPayment] = useState(false);
    const [{ isLoading: isEditVendorLoading, isError: isEditVendorError, response: EditVendorResponse }, EditVendorListAPICall] = useAPICall(EditVendorDetails, { onLoad: false });
    const [{ isLoading: isDetailsLoading, isError: isDetailsError, response: DetailsResponse }, DetailsUserAPICall] = useAPICall(GetInvoiceDetails, { onLoad: false });
    const [{ isLoading: isVendorLoading, isError: isVendorError, response: VendorResponse }, VendorListAPICall] = useAPICall(GetVendorList, { onLoad: false });
    const [selectedEnabled, setSelectedEnabled] = useState("map");
    const [selectVendor, setSelectedVendor] = useState("classes");

    useEffect(async () => {
        if (vendorList.length != 0) {
            const Data = vendorList.find(obj => {
                return obj._id === vendorId;
            });
            setShowSelectedrowsData(Data.name ? Data.name : Data.legalName);
            setVendorName(Data.name ? Data.name : Data.legalName);
            setPhone(Data.phone);
            // setWebsite(Data.website);
            setAddress(Data.billingAddress ? Data.billingAddress.line1 : Data.address);
            setAccountNo(Data.accountNo);
            setEmailId(Data.email);
            setPhone(Data.phone);
            setNetTerms(Data.balance);
            setVendor1099(Data.vendor1099);
            setTrackPayment(Data.vendor1099);
            setShowDetails(true);
        }

        if (vendorId) {
            setTimeout(() => {
                setShowDetails(true);
                setLoading(false);
            }, 2000);
        }

    }, [vendorList, vendorId])


    useEffect(async () => {
        await DetailsUserAPICall({
            payload: [{ "InvoiceId": `${invoice_id}`, "businessId": `${businessId}` }]
        })
        await VendorListAPICall({
            payload: [{ "businessId": `${businessId}`, IsSynced: true }]
        });
        setLoading(true);
    }, []);

    useEffect(() => {
        if (isVendorLoading) {
            return () => null;
        }
        if (isVendorError) {
            setLoading(false);
            return () => null;
        }
        if (VendorResponse && VendorResponse.data.isSuccess === true) {
            setVendorList(VendorResponse.data.data.records);
        }
    }, [VendorResponse]);


    useEffect(() => {
        if (isDetailsLoading) {
            return () => null;
        }

        if (isDetailsError) {
            return () => null;
        }

        if (DetailsResponse && DetailsResponse.data.isSuccess === true) {
            // setShowSelectedrowsData(DetailsResponse.data.data.invoice.vendor.name);
            // setVendorName(DetailsResponse.data.data.invoice.vendor.name);
            // setPhone(DetailsResponse.data.data.invoice.vendor.phone);
            // setWebsite(DetailsResponse.data.data.invoice.website);
            setvendorId(DetailsResponse.data.data.invoice.vendorId);
            // setAddress(DetailsResponse.data.data.invoice.vendor.address);
            // setAccountNo(found.accountNo);
            // setEmailId(found.email);
            // setNetTerms(found.balance);
            // setVendor1099(found.vendor1099);
            // setShowDetails(true);
        }
    }, [DetailsResponse]);

    useEffect(() => {
        if (isEditVendorLoading) {
            return () => null;
        }
        if (isEditVendorError) {
            setLoading(false);
            setBtnDisable(false);
            return () => null;
        }
        if (EditVendorResponse && EditVendorResponse.data.isSuccess === true) {
            setLoading(false);
            setBtnDisable(false);
            onClose();
            eventBus.$emit("reloadDetailsTab", EditVendorResponse);
        }
    }, [EditVendorResponse]);

    let initialValues = {
        vendor: vendorName,
        accountno: accountNo,
        address: address,
        email: emailId,
        phone: phone,
        businessid: "",
        net: netTerms,
        checkbox: vendor1099,
        account: ""
    };

    useEffect(() => {
        let initialValues = {
            vendor: vendorName,
            accountno: accountNo,
            address: address,
            email: emailId,
            phone: phone,
            businessid: "",
            net: netTerms,
            checkbox: vendor1099,
            account: ""
        };

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [showselectedrowsData, address]);

    const handleSubmit = async (values) => {
        setBtnDisable(true);
        await EditVendorListAPICall({
            payload: [
                {
                    "isBlocked": false,
                    "isArchived": false,
                    "name": values.vendor,
                    "phone": values.phone,
                    "email": values.email,
                    "sparse": false,
                    "balance": values.net,
                    "accountNo": values.accountno,
                    "vendor1099": trackPayment,
                    "currency": "USD",
                    "billingAddress": {
                        "line1": values.address,
                    },
                    "VendorId": `${vendorId}`,
                    "businessId": `${business_id}`
                }]
        })
    };
    const handleChange = () => {
        setTrackPayment(!trackPayment)
    }
    const handleChangeEnabled = (event) => {
        setSelectedEnabled(event.target.value);
    };
    const handleVendorEnabled = (event) => {
        setSelectedVendor(event.target.value);
    };
    const handleChangeBusiness = (event, newValue) => {
        setLoading(true);
        // setShowDetails(false);
        setShowSelectedrowsData(event.target.value)
        const found = vendorList.find(obj => {
            return obj.name === event.target.value;
        });
        setAddress(found.billingAddress ? found.billingAddress.line1 : '');
        setAccountNo(found.accountNo);
        setEmailId(found.email);
        setPhone(found.phone);
        setNetTerms(found.balance);
        setVendor1099(found.vendor1099);
        setTimeout(() => {
            setShowDetails(true);
        }, 1000);
        // setShowselectedVendorId(found._id);
    };

    return (
        <>{showDetails &&
            <>
                < MDBox mt={0} >
                    <Grid spacing={3}>
                        <Grid item xs={12}>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={EditVendorSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ values, errors, touched, isSubmitting }) => (
                                    <Form autoComplete="off">
                                        <MDBox p={3}>
                                            <MDBox>
                                                <MDBox mt={1.625}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={6}>
                                                            {/* <FormControl variant="standard" sx={{ minWidth: 150 }} fullWidth >
                                                                <InputLabel htmlFor="demo-simple-select-standard" id="demo-simple-select-standard-label">
                                                                    Vendor
                                                                </InputLabel>
                                                                <Select
                                                                    aria-required="true"
                                                                    labelId="demo-simple-select-standard-label"
                                                                    id="demo-simple-select-standard"
                                                                    value={showselectedrowsData}
                                                                    onChange={handleChangeBusiness}
                                                                    label="Select Client"
                                                                >
                                                                    {vendorList.map((prop, key) => (
                                                                        <MenuItem
                                                                            value={prop.name}
                                                                        >
                                                                            {prop.name}
                                                                        </MenuItem>
                                                                    ))}

                                                                </Select>
                                                            </FormControl> */}
                                                            <FormField
                                                                type="text"
                                                                label="Vendor"
                                                                name="vendor"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Account Number"
                                                                name="accountno"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Address"
                                                                name="address"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="email"
                                                                label="Email"
                                                                name="email"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Phone Number"
                                                                name="phone"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Business ID Number/Social Security Number"
                                                                name="businessid"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Net Terms"
                                                                name="net"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <MDBox>
                                                                <Field
                                                                    as={FormControlLabel}
                                                                    type="checkbox"
                                                                    name="checkbox"
                                                                    control={<Checkbox />}
                                                                    label="Track payments for 1099"
                                                                    checked={trackPayment}
                                                                    onChange={handleChange}
                                                                    sx={{ mt: 1.5 }}
                                                                />
                                                            </MDBox>
                                                        </Grid>
                                                    </Grid>
                                                    <MDBox lineHeight={0}>
                                                        <MDTypography variant="h5">Account Mapping</MDTypography>
                                                    </MDBox>
                                                    <MDBox>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} sm={6}>
                                                                <FormControl fullWidth variant="standard" sx={{ mt: 1 }} >
                                                                    <RadioGroup
                                                                    >
                                                                        <FormControlLabel label="Map to a Single Account" control={<Radio
                                                                            value="map"
                                                                            checked={selectedEnabled === "map"}
                                                                            onChange={handleChangeEnabled}
                                                                        />} />
                                                                        {selectedEnabled === "map" &&
                                                                            <Field
                                                                                as="select"
                                                                                name="account"
                                                                                variant="standard"
                                                                                style={{
                                                                                    marginTop: "18px",
                                                                                    borderTop: "0px",
                                                                                    borderLeft: "0px",
                                                                                    borderRight: "0px",
                                                                                    color: "slategray",
                                                                                }}
                                                                            >
                                                                                <option value="1">Chart Account</option>
                                                                                <option value="2">Food</option>
                                                                                <option value="2">Bar</option>
                                                                            </Field>
                                                                        }
                                                                        <MDBox mt={0.75}>
                                                                            <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                                                                <ErrorMessage name="account" />
                                                                            </MDTypography>
                                                                        </MDBox>
                                                                    </RadioGroup>
                                                                </FormControl>

                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <FormControlLabel control={<Radio
                                                                    checked={selectVendor === "classes"}
                                                                    onChange={handleVendorEnabled}
                                                                    value="classes"

                                                                />} label="Classes " />
                                                            </Grid >
                                                        </Grid>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} sm={6}>

                                                                <FormControlLabel control={<Radio
                                                                    checked={selectedEnabled === "split"}
                                                                    onChange={handleChangeEnabled}
                                                                    value="split"

                                                                />} label="Split between multiple accounts" />
                                                            </Grid>

                                                        </Grid>
                                                    </MDBox>

                                                </MDBox>
                                                <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                                                    <MDButton
                                                        disabled={btnDisable}
                                                        variant="gradient"
                                                        color="light"
                                                        onClick={onClose}
                                                    >
                                                        Cancel
                                                    </MDButton>
                                                    <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                        <MDButton
                                                            disabled={btnDisable}
                                                            type="submit"
                                                            variant="gradient"
                                                            color="info"
                                                        >
                                                            {btnDisable ? "updating..." : "update"}
                                                        </MDButton>
                                                    </MDBox>
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>

                                    </Form>
                                )}
                            </Formik>

                        </Grid>

                    </Grid>
                </MDBox>

            </>
        }
            <MDBox>
                {loading && (
                    <CircularProgress
                        size={50}
                        sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </MDBox>
        </>
    );
}

export default EditVendorDialog;
