import { useEffect } from "react";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Card from "@mui/material/Card";
import { NavLink, useLocation } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import CircularProgress from '@mui/material/CircularProgress';
import DashboardLayout from "../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../components/Navbars/DashboardNavbar";
import DataTable from "../components/Tables/DataTable";
import DataTable1 from "components/NewTable/table";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAPICall } from "../../../utils/useapicall";
import { GetList, EditAccountantInfo, GetEmployeesList, GetClientsList } from "../../../services/accountantservices";
import Hoc from "hoc";
import TextField from '@mui/material/TextField';
import Cookies from 'universal-cookie';
import { Chip } from "@mui/material";
import Loader from "./components/imgLoader/loader";
import { eventBus } from "utils/eventbus";
import { green } from '@mui/material/colors';

const cookies = new Cookies();

function Accountants() {
    const navigate = useNavigate();
    const action = { route: "", edittooltip: "Edit", deletetooltip: "Delete", billingtooltip: "Billing" };
    const [openAccount, setOpenAccount] = useState(false);
    const [openClient, setOpenClient] = useState(false);
    const [openClientPopup, setOpenClientPopup] = useState(false);
    const [alert, setAlert] = useState(false);
    const [profileInfo, setProfileInfo] = useState(cookies.get('accountant_profile_info'));
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [{ isError, isLoading, response }, setAPICall] = useAPICall(GetList, { onLoad: false });
    const [adminList, setAdminList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [{ isLoading: isRemoveLoading, isError: isRemoveError, response: removeResponse }, removeaccountantAPICall] = useAPICall(EditAccountantInfo, { onLoad: false });
    const [accountantId, setAccountantId] = useState();
    const [activeUser, setActiveUser] = useState(false);
    const [{ isLoading: isUserStatusLoading, isError: isUserStatusError, response: UserStatusResponse }, UserStatusAPICall] = useAPICall(EditAccountantInfo, { onLoad: false });
    const [AccbussinessId, setBusinessId] = useState();
    const [loadingCircul, setLoadingCircul] = useState(false);
    const [tableEmpty, setTableEmpty] = useState("");
    const [employeeTableEmplty, setEmployeeTableEmpty] = useState("");
    const [clientTableEmpty, setClientTableEmpty] = useState("");
    const [search, setSearch] = useState('');
    const [searchBusinessName, setSearchBusinessName] = useState('');
    const [typeBusiness, setTypeBusiness] = useState(false);
    const [businessName, setBusinessName] = useState('');
    const [disabledBusinessName, setDisabledBusinessName] = useState(true);
    const [helperText, setHelperText] = useState('');
    const [employessList, setemployessList] = useState([]);
    const [clientsList, setClientsList] = useState([]);
    const [employessId, setEmployessId] = useState();
    const { pathname } = useLocation();
    const [{ isError: isEmployeesError, isLoading: isEmployeesLoading, response: Employeesresponse }, EmployeessetAPICall] = useAPICall(GetEmployeesList, { onLoad: false });
    const [{ isError: isClientsError, isLoading: isClientsLoading, response: Clientsresponse }, ClientssetAPICall] = useAPICall(GetClientsList, { onLoad: false });
    const [loaderClass, setLoaderClass] = useState({});

    eventBus.$on('onclickchangePages', (data) => OnclickchangePages(data));
    function OnclickchangePages(data) {
        setLoading(true);
        const dataStr = data.toString() + 0
        localStorage.setItem("offset", Number(dataStr))
        setAPICall({
            payload: [{ "keyword": `${search}`, 'offset': Number(dataStr) }]
        });
    }

    useEffect(async () => {
        const queryParameters = new URLSearchParams(window.location.search)
        const keyword = queryParameters.get("keyword");
        const ThisOffsetuse = localStorage.getItem("offset");
        if (keyword) {
            setSearch(keyword);
            setLoading(true);
        } else {
            setLoading(true);
            await setAPICall({
                payload: [{ 'offset': ThisOffsetuse }]
            })
        }
    }, []);

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const keyword = queryParameters.get("keyword");
        if (!keyword) {
            window.history.pushState('data', 'title', `${pathname}`);
        }
    }, [search]);

    useEffect(() => {
        eventBus.$emit("OnSetBlur", loading);
        loading === true ? setLoaderClass({ opacity: '0.6', borderRadius: '13px' }) : setLoaderClass({})
    }, [loading]);


    useEffect(() => {
        const ThisOffsetuse = localStorage.getItem("offset");
        const timeOutId = setTimeout(async () =>
            await setAPICall({
                payload: [{ "keyword": `${search}`, 'offset': ThisOffsetuse }]
            }), 500);
        return () => clearTimeout(timeOutId);
    }, [search]);

    useEffect(() => {
        const str1 = searchBusinessName.replace(/ /g, '');
        const str2 = businessName.replace(/ /g, '');
        const timeOutId = setTimeout(async () => {
            if (str1 == str2.toUpperCase() && searchBusinessName.length !== 0) {
                setDisabledBusinessName(false);
                setHelperText("");
            } else {
                setDisabledBusinessName(true);
                // setHelperText("Incorrect Business name.");
            }
        }, 500)
        // await setAPICall({
        //     payload: [{ "keyword": `${search}` }]
        // }), 500);
        return () => clearTimeout(timeOutId);
    }, [searchBusinessName]);

    useEffect(() => {
        if (isClientsLoading) {
            return () => null;
        }
        if (isClientsError) {
            response?.code === "DATA401" ? navigate('/admin/logout') : console.log("somting else");
            return () => null;
        }
        if (Clientsresponse && Clientsresponse.data.isSuccess === true) {
            setClientTableEmpty(Clientsresponse.data.data.totalRecords);
            const data = Clientsresponse.data.data.records.map((prop, key) => {
                const Createdate = new Date(prop.createdAt);
                return {
                    firstName: prop.name,
                    business: prop.businessName,
                    email: prop.email,
                    phone: prop.phone == null ? "-" : prop.phone,
                    Numberofuser: prop?.numberOfEmployees,
                    switch: (
                        prop.isBlocked ?
                            <MDBox>
                                <MDTypography
                                    variant="caption" fontWeight="regular" fontSize="0.875rem" color="error"
                                >
                                    Inactive
                                </MDTypography>
                            </MDBox>
                            :
                            <MDBox>
                                <MDTypography
                                    variant="caption" fontWeight="regular" fontSize="0.875rem" color="success"
                                >
                                    Active
                                </MDTypography>
                            </MDBox>
                    ),
                    createdat: Createdate.toLocaleDateString(),
                    totalaccountants: prop.totalEmployees,
                    accountanttoclients: "-",
                    monthlycost: "-",
                    accountlevel: "-",
                    // (prop.role.template == 'executive' ? "User" : "Admin"),
                    action:
                        (profileInfo && profileInfo.user && profileInfo?.user?._id == prop._id ? ("") : (
                            <MDBox display="flex">
                                <Link to={`/admin/admin/accountants/${prop._id}/edit-accountant`}>
                                    <MDTypography
                                        variant="body2"
                                        color="secondary"
                                        sx={{ marginLeft: "-4px", fontSize: "1.20rem", cursor: 'pointer' }}
                                    >
                                        <Tooltip title={action.edittooltip} placement="top">
                                            <Icon>edit</Icon>
                                        </Tooltip>
                                    </MDTypography>
                                </Link>
                                <MDTypography variant="body2" color="secondary" sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: 'pointer' }}>
                                    <Tooltip title={action.deletetooltip} placement="top"
                                        onClick={(value) => {
                                            setBusinessName(prop?.business?.name);
                                            setAccountantId(prop._id);
                                            setTypeBusiness(true);
                                        }}
                                    >
                                        <Icon>delete</Icon>
                                    </Tooltip>
                                </MDTypography>
                            </MDBox>
                        ))

                };
            }
            );
            setClientsList(data);
            setLoading(false);
            setOpenClient(true);
        }
    }, [Clientsresponse]);


    const ToggleChange = async (bussinessId) => {
        setActiveUser(!activeUser);
        await UserStatusAPICall({
            payload: [
                {
                    "isBlocked": activeUser,
                    "bussinessId": `${bussinessId}`
                }
            ]
        });

    }

    useEffect(() => {
        setProfileInfo(cookies.get('accountant_profile_info'));
    }, []);


    useEffect(() => {
        if (isEmployeesLoading) {
            return () => null;
        }
        if (isEmployeesError) {
            setLoading(false);
            response?.code === "DATA401" ? navigate('/admin/logout') : console.log("somting else");
            return () => null;
        }
        if (Employeesresponse && Employeesresponse.data.isSuccess === true) {
            setEmployeeTableEmpty(Employeesresponse.data.data.totalRecords);
            const data = Employeesresponse.data.data.records.map((prop, key) => {
                const Createdate = new Date(prop.createdAt);
                return {
                    firstName: prop.fname,
                    business: prop.businessName,
                    email: prop.email,
                    phone: prop.phone,
                    switch: (
                        prop.isBlocked ?
                            <MDBox>
                                <MDTypography
                                    variant="caption" fontWeight="regular" fontSize="0.875rem" color="error"
                                >
                                    Inactive
                                </MDTypography>
                            </MDBox>
                            :
                            <MDBox>
                                <MDTypography
                                    variant="caption" fontWeight="regular" fontSize="0.875rem" color="success"
                                >
                                    Active
                                </MDTypography>
                            </MDBox>
                    ),
                    createdat: Createdate.toLocaleDateString(),
                    totalaccountants: prop?.totalEmployees ? prop?.totalEmployees : '-',
                    accountanttoclients: prop?.clientsCount ? prop?.clientsCount :
                        <MDBox>
                            <MDTypography
                                variant="caption" fontWeight="regular" fontSize="0.875rem" sx={{ color: '#7b809a' }}
                            >
                                0
                            </MDTypography>
                        </MDBox>,
                    monthlycost: "-",
                    accountlevel: prop?.role?.template == 'executive' ? "User" : prop?.role?.template == 'owner' ? 'Admin' : 'Manager',
                    action:
                        (profileInfo && profileInfo.user && profileInfo?.user?._id == prop._id ? ("") : (
                            <MDBox display="flex">
                                <Link to={`/admin/accountants/${prop._id}/edit-accountant`}>
                                    <MDTypography
                                        variant="body2"
                                        color="secondary"
                                        sx={{ marginLeft: "-4px", fontSize: "1.20rem", cursor: 'pointer' }}
                                    >
                                        <Tooltip title={action.edittooltip} placement="top">
                                            <Icon>edit</Icon>
                                        </Tooltip>
                                    </MDTypography>
                                </Link>
                                <MDTypography variant="body2" color="secondary" sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: 'pointer' }}>
                                    <Tooltip title={action.deletetooltip} placement="top"
                                        onClick={(value) => {
                                            setBusinessName(prop?.business?.name);
                                            setAccountantId(prop._id);
                                            setTypeBusiness(true);
                                        }}
                                    >
                                        <Icon>delete</Icon>
                                    </Tooltip>
                                </MDTypography>
                            </MDBox>
                        ))

                };
            }
            );
            setemployessList(data);
            setLoading(false);
            setOpenAccount(true);
        }
    }, [Employeesresponse]);

    const handleCloseAccount = () => {
        setOpenAccount(false);
    };

    const date_diff_indays = function (date2) {
        const dt1 = new Date();
        const dt2 = new Date(date2);
        return Math.floor(
            (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
                Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
            (1000 * 60 * 60 * 24)
        );
    };

    useEffect(() => {
        if (isLoading) {
            return () => null;
        }
        if (isError) {
            response?.code === "DATA401" ? navigate('/admin/logout') : console.log("somting else");
            setLoading(false);
            setLoadingCircul(false);
            return () => null;
        }
        if (response && response.data.isSuccess === true) {
            setTableEmpty(response.data.data.totalRecords);
            const data = response.data.data.records.map((prop, key) => {
                const Createdate = new Date(prop.createdAt);
                // setActiveUser(!prop.isBlocked);
                const HasJoined = prop.joinedAt;
                const ontrial = prop?.subscriptions[0]?.ontrial === true ? `Trial:${date_diff_indays(prop?.subscriptions[0]?.trialEndDate)}days` : '';
                return {
                    snum: (key + 1),
                    firstName:
                        (<MDBox>
                            <MDTypography
                                variant="caption" fontWeight="regular" fontSize="0.875rem"
                                sx={{ color: '#7b809a' }}
                            >
                                {prop.fname && prop.lname == null ? "-" :
                                    prop.fname + " " + prop.lname + " "}
                            </MDTypography>
                        </MDBox>),
                    business: (
                        <MDBox>
                            <Link to={`/admin/accountants/${prop._id}/edit-accountant`}>
                                <MDTypography
                                    variant="caption" fontWeight="regular" fontSize="0.875rem" color="info"
                                >
                                    {prop?.business?.name}
                                </MDTypography>
                            </Link>
                        </MDBox>
                    ),
                    email: prop.email,
                    phone: prop.phone == null ? "-" : prop.phone,
                    switch: (
                        HasJoined == null ?
                            <MDBox>
                                <MDTypography
                                    variant="caption" fontWeight="regular" fontSize="0.875rem" color="warning"
                                >
                                    Pending Confirmation
                                </MDTypography>
                                <MDBox sx={{
                                    width: '100%', display: 'flex',
                                    justifyContent: 'center', fontSize: "1.20rem"
                                }}>

                                    {prop?.subscriptions[0]?.ontrial === true ? <Chip sx={{ marginLeft: "3px", fontSize: '0.6125rem', height: '17px', color: '#ffffff' }} label={ontrial} color="warning" /> : ''}
                                </MDBox>
                            </MDBox>
                            :
                            prop.isBlocked ?
                                <MDBox>
                                    <MDTypography
                                        variant="caption" fontWeight="regular" fontSize="0.875rem" color="error"
                                    >
                                        Inactive
                                    </MDTypography>
                                    <MDBox sx={{
                                        width: '100%', display: 'flex',
                                        justifyContent: 'center', fontSize: "1.20rem"
                                    }}>
                                        {prop?.subscriptions[0]?.ontrial === true ? <Chip sx={{ marginLeft: "3px", fontSize: '0.6125rem', height: '17px', color: '#ffffff' }} label={ontrial} color="warning" /> : ''}
                                    </MDBox>
                                </MDBox>
                                :
                                <MDBox>
                                    <MDTypography
                                        variant="caption" fontWeight="regular" fontSize="0.875rem" color="success"
                                    >
                                        Active
                                    </MDTypography>
                                    <MDBox sx={{
                                        width: '100%', display: 'flex',
                                        justifyContent: 'center', fontSize: "1.20rem"
                                    }}>

                                        <Chip sx={{ marginLeft: "3px", fontSize: '0.6125rem', height: '17px', color: '#ffffff' }} label={ontrial} color="warning" />
                                    </MDBox>
                                </MDBox>
                        // <MDBox mt={0.5}>
                        //     <Switch checked={!prop.isBlocked} onChange={() => ToggleChange(prop._id)} />
                        // </MDBox>

                    ),
                    createdat: Createdate.toLocaleDateString(),
                    totalaccountants: (prop?.totalEmployees == 0 ?
                        <MDBox>
                            <MDTypography
                                variant="caption" fontWeight="regular" fontSize="0.875rem" sx={{ color: '#7b809a' }}
                            >
                                {prop.totalEmployees}
                            </MDTypography>
                        </MDBox>
                        :
                        <MDBox>
                            <MDTypography
                                variant="caption" fontWeight="regular" fontSize="0.875rem" color="info"
                                onClick={async (value) => {
                                    setLoading(true);
                                    await EmployeessetAPICall({
                                        payload: [{ "employessId": prop._id }]
                                    })
                                }}
                            // sx={{ marginLeft: "-4px", fontSize: "1.20rem" }}
                            >
                                {prop.totalEmployees}
                            </MDTypography>
                        </MDBox>
                    ),
                    totalclients: (prop?.totalClients == 0 ?
                        <MDBox>
                            <MDTypography
                                variant="caption" fontWeight="regular" fontSize="0.875rem" sx={{ color: '#7b809a' }}
                            >
                                {prop.totalClients}
                            </MDTypography>
                        </MDBox>
                        :
                        <MDBox>
                            <MDTypography
                                variant="caption" fontWeight="regular" fontSize="0.875rem" color="info"
                                onClick={async (value) => {
                                    setLoading(true);
                                    await ClientssetAPICall({
                                        payload: [{ "clientsId": prop._id }]
                                    })
                                }}
                            >
                                {prop.totalClients}
                            </MDTypography>
                        </MDBox>
                    ),
                    monthlycost: `$${prop?.subscriptions[0]?.nextBillAmount}`,
                    action: (profileInfo && profileInfo.user && profileInfo?.user?._id == prop._id ? ("") : (
                        <MDBox display="flex">
                            <MDBox sx={{ mr: 2 }} >
                                <Link to={`/admin/billing/${prop._id}`}>
                                    <MDTypography
                                        variant="body2"
                                        color="secondary"
                                        sx={{ marginLeft: "-8px", fontSize: "1.20rem", cursor: 'pointer' }}
                                    >
                                        <Tooltip title={action.billingtooltip} placement="top">
                                            <Icon>receiptLong</Icon>
                                        </Tooltip>
                                    </MDTypography>
                                </Link>
                            </MDBox>
                            <MDBox>
                                <Link to={`/admin/accountants/${prop._id}/edit-accountant`}>
                                    <MDTypography
                                        variant="body2"
                                        color="secondary"
                                        sx={{ marginLeft: "-4px", fontSize: "1.20rem", cursor: 'pointer' }}
                                    >
                                        <Tooltip title={action.edittooltip} placement="top">
                                            <Icon>edit</Icon>
                                        </Tooltip>
                                    </MDTypography>
                                </Link>
                            </MDBox>
                            <MDTypography variant="body2" color="secondary" sx={{ ml: 1, fontSize: "1.20rem", cursor: 'pointer' }}>
                                <Tooltip title={action.deletetooltip} placement="top"
                                    onClick={(value) => {
                                        setBusinessName(prop?.business?.name);
                                        setAccountantId(prop._id);
                                        setTypeBusiness(true);
                                    }}
                                >
                                    <Icon>delete</Icon>
                                </Tooltip>
                            </MDTypography>
                        </MDBox>
                    )),
                };
            }
            );
            setAdminList(data);
            setLoading(false);
            setLoadingCircul(false);
        }
    }, [response]);

    useEffect(() => {

        if (isUserStatusLoading) {
            return () => null;
        }

        if (isUserStatusError) {
            response?.code === "DATA401" ? navigate('/admin/logout') : console.log("somting else");
            return () => null;
        }

        if (UserStatusResponse && UserStatusResponse.data.isSuccess === true) {

        }
    }, [UserStatusResponse]);

    useEffect(() => {

        if (isRemoveLoading) {
            return () => null;
        }

        if (isRemoveError) {
            response?.code === "DATA401" ? navigate('/admin/logout') : console.log("somting else");
            setLoading(false);
            return () => null;
        }

        if (removeResponse && removeResponse.data.isSuccess === true) {
            const ThisOffsetuse = localStorage.getItem("offset");
            setShowSnackbar(true);
            setTimeout(() => {
                setShowSnackbar(false);
            }, 10000);
            setLoading(true);
            setAPICall({
                payload: [{ "keyword": `${search}`, 'offset': ThisOffsetuse }]
            })
            // navigate('/accountants/list');
        }
    }, [removeResponse]);


    const warningWithConfirmMessage = () => {
        setAlert(true);
    };
    const successDelete = async () => {
        setTypeBusiness(false);
        setLoading(true);
        await removeaccountantAPICall({
            payload: [
                {
                    "isBlocked": true,
                    "isArchived": true,
                    "bussinessId": `${accountantId}`
                }
            ]
        })
        setAlert(false);
    };
    const hideAlert = () => {
        setAlert(false);
        setTypeBusiness(false);
    };


    const handleClickOpenClient = () => {
        setOpenClient(true);
    };

    const handleCloseClient = () => {
        setOpenClient(false);
    };

    const handleClickOpenClientPopup = () => {
        setOpenClientPopup(true);
    };

    const handleCloseClientPopup = () => {
        setOpenClientPopup(false);
    };

    const dataTableData = {
        columns: [
            {
                Header: "Business Name",
                accessor: "business",
                disableSortBy: true,
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Full Name",
                accessor: "firstName",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Email Address",
                accessor: "email",
                disableSortBy: true,
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Phone Number",
                accessor: "phone",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            { Header: "Total Users", accessor: "totalaccountants", Cell: ({ value }) => <a style={{ cursor: 'pointer', color: 'blue' }} >{value} </a> },
            { Header: "Total Clients", accessor: "totalclients", Cell: ({ value }) => <a style={{ cursor: 'pointer', color: 'blue' }}  >{value} </a> },
            { Header: "Monthly Cost", accessor: "monthlycost", Cell: ({ value }) => <DefaultCell value={value} /> },
            {
                Header: "Status",
                accessor: "switch",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            { Header: "Created Date", accessor: "createdat", Cell: ({ value }) => <DefaultCell value={value} /> },
            {
                Header: "Action", accessor: "action", Cell: ({ value }) => <DefaultCell value={value} />
            },
        ],

        rows: adminList
    };

    const dataTableData2 = {
        columns: [
            {
                Header: "Name",
                accessor: "firstName",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Email",
                accessor: "email",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Phone Number",
                accessor: "phone",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            { Header: "Account Created Date", accessor: "createdat", Cell: ({ value }) => <DefaultCell value={value} /> },
            { Header: "Account Level", accessor: "accountlevel", Cell: ({ value }) => <DefaultCell value={value} /> },
            { Header: "Accountant to Clients", accessor: "accountanttoclients", Cell: ({ value }) => <a >{value} </a> },
            // {
            //     Header: "Action", accessor: "action", Cell: ({ value }) => <DefaultCell value={value} />
            // },
        ],

        rows: employessList
    };

    const dataTableData3 = {
        columns: [
            {
                Header: "Name",
                accessor: "firstName",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Number of Users Accounts",
                accessor: "Numberofuser",
                Cell: ({ value }) => <a  >{value} </a>,
            },
            {
                Header: "status",
                accessor: "switch",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            // {
            //     Header: "Action", accessor: "action", Cell: ({ value }) => <DefaultCell value={value} />
            // },
        ],

        rows: clientsList

    };

    const dataTableData4 = {
        columns: [
            {
                Header: "Name",
                accessor: "firstName",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Email",
                accessor: "email",
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            { Header: "AccountLevel", accessor: "accountalevel", Cell: ({ value }) => <DefaultCell value={value} /> },
            {
                Header: "Action", accessor: "action", Cell: ({ value }) => <DefaultCell value={value} />
            },
        ],

        rows: [
            {
                firstName: "Orlando Imieto",
                email: "Orlando@mailinator.com",
                accountalevel: "Admin",
                action: (
                    <MDBox display="flex">
                        <MDTypography variant="body2" color="secondary">
                            <Tooltip title={action.edittooltip} placement="top">
                                <Icon>edit</Icon>
                            </Tooltip>
                        </MDTypography>
                        <MDTypography variant="body2" color="secondary">
                            <Tooltip title={action.deletetooltip} placement="top">
                                <Icon>delete</Icon>
                            </Tooltip>
                        </MDTypography>
                    </MDBox>
                )

            },
            {
                firstName: "Alice ",
                email: "Alice@mailinator.com",
                accountalevel: "User",
                action: (
                    <MDBox display="flex">
                        <MDTypography variant="body2" color="secondary">
                            <Tooltip title={action.edittooltip} placement="top">
                                <Icon>edit</Icon>
                            </Tooltip>
                        </MDTypography>
                        <MDTypography variant="body2" color="secondary">
                            <Tooltip title={action.deletetooltip} placement="top">
                                <Icon>delete</Icon>
                            </Tooltip>
                        </MDTypography>
                    </MDBox>
                )
            },
            {
                firstName: "Michael ",
                email: "Michael@mailinator.com",
                accountalevel: "User",
                action: (
                    <MDBox display="flex">
                        <MDTypography variant="body2" color="secondary">
                            <Tooltip title={action.edittooltip} placement="top">
                                <Icon>edit</Icon>
                            </Tooltip>
                        </MDTypography>
                        <MDTypography variant="body2" color="secondary">
                            <Tooltip title={action.deletetooltip} placement="top">
                                <Icon>delete</Icon>
                            </Tooltip>
                        </MDTypography>
                    </MDBox>
                )
            },
        ],
    };

    const accountantListColumns = [
        {
            Header: "Name",
            accessor: "firstName",
            Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
            Header: "Business",
            accessor: "business",
            disableSortBy: true,
            Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
            Header: "Email",
            accessor: "email",
            disableSortBy: true,
            Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
            Header: "Phone Number",
            accessor: "phone",
            Cell: ({ value }) => <DefaultCell value={value} />,
        },
        {
            Header: "Status",
            accessor: "switch",
            Cell: ({ value }) => <DefaultCell value={value} />,
        },
        { Header: "Created Date", accessor: "createdat", Cell: ({ value }) => <DefaultCell value={value} /> },
        { Header: "Total Users", accessor: "totalaccountants", Cell: ({ value }) => <a style={{ cursor: 'pointer', color: 'blue' }} >{value} </a> },
        { Header: "Total Clients", accessor: "totalclients", Cell: ({ value }) => <a style={{ cursor: 'pointer', color: 'blue' }} >{value} </a> },
        { Header: "Monthly Cost", accessor: "monthlycost", Cell: ({ value }) => <DefaultCell value={value} /> },
        {
            Header: "Action", accessor: "action", Cell: ({ value }) => <DefaultCell value={value} />
        },
    ];


    const handleSearch = async (event) => {
        localStorage.setItem("offset", 0)
        setSearch(event.target.value);
        setLoadingCircul(true);
        window.history.pushState('data', 'title', `${pathname}?keyword=${encodeURIComponent(event.target.value)}`);
        // window.location.replace(`?${event.target.value}`);
        // setLoading(true);
    };

    const handleBusiness = async (event) => {
        setSearchBusinessName((event.target.value).toUpperCase());
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={4}>
                <Card sx={loaderClass}>
                    <MDBox display="flex" justifyContent="center" >
                        {loading && <Loader />}
                    </MDBox>
                    <MDBox pt={3} px={2} sx={{ pr: 3, mb: 2 }} component="form" display="flex" justifyContent="space-between" alignItems="center">
                        <MDTypography variant="h5" fontWeight="medium">
                            Accountants
                        </MDTypography>
                        <MDBox px={2} display="flex" >
                            <MDBox sx={{ pr: 3, mb: 2 }}>
                                <MDInput type="search" label="Search Accountant" onKeyDown={handleKeyDown} variant="standard" onChange={handleSearch} />
                            </MDBox>
                            <MDBox>
                                <MDButton component={NavLink} to="/admin/accountants/new-accountant"
                                    variant="gradient"
                                    color="info"
                                    disabled={loading}

                                > Add New Accountant
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                    <MDBox>
                        {loadingCircul && (
                            <CircularProgress
                                size={50}
                                sx={{
                                    color: green[500],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </MDBox>
                    <Dialog
                        open={openAccount}
                        onClose={handleCloseAccount}
                        aria-labelledby="responsive-dialog-title"
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    height: "auto",
                                    maxWidth: "1000px",
                                },
                            },
                        }}
                    >
                        <DialogContent>
                            <MDBox my={5}>
                                {
                                    employeeTableEmplty == '0' ?
                                        <MDBox my={5} sx={{ mt: 1 }}>
                                            <DataTable table={dataTableData2} entriesPerPage={false} pagination={false} showTotalEntries={true} />
                                            <MDTypography
                                                variant="body2"
                                                color="secondary"
                                                sx={{ marginLeft: "43%", fontSize: "1.20rem", marginTop: "5%", marginBottom: "5%" }}
                                            >
                                                No records found
                                            </MDTypography>
                                        </MDBox>
                                        :
                                        <MDBox my={5} sx={{ mt: 1 }}>
                                            <DataTable table={dataTableData2} entriesPerPage={false} pagination={true} showTotalEntries={true} />
                                        </MDBox>
                                }
                            </MDBox>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseAccount} >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={openClient}
                        onClose={handleCloseClient}
                        aria-labelledby="responsive-dialog-title"
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    height: "auto",
                                    maxWidth: "1000px",
                                },
                            },
                        }}
                    >
                        <DialogContent>
                            <MDBox my={5}>
                                {
                                    clientTableEmpty == '0' ?
                                        <MDBox my={5} sx={{ mt: 1 }}>
                                            <DataTable table={dataTableData3} entriesPerPage={false} pagination={false} showTotalEntries={true} />
                                            <MDTypography
                                                variant="body2"
                                                color="secondary"
                                                sx={{ marginLeft: "43%", fontSize: "1.20rem", marginTop: "5%", marginBottom: "5%" }}
                                            >
                                                No records found
                                            </MDTypography>
                                        </MDBox>
                                        :
                                        <MDBox my={5} sx={{ mt: 1 }}>
                                            <DataTable table={dataTableData3} entriesPerPage={false} pagination={true} showTotalEntries={true} />
                                        </MDBox>
                                }
                            </MDBox>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseClient} >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={openClientPopup}
                        onClose={handleCloseClientPopup}
                        aria-labelledby="responsive-dialog-title"
                        maxWidth="xl"
                    >
                        <DialogContent>
                            <MDBox my={5}>
                                <DataTable table={dataTableData4} entriesPerPage={false} pagination={false} showTotalEntries={true} />
                            </MDBox>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseClientPopup} >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* {alert} */}
                    <Dialog
                        open={typeBusiness}
                        onClose={hideAlert}
                        // aria-labelledby="alert-dialog-title"
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    height: "50%",
                                    maxWidth: "500px",
                                },
                            },
                        }}
                    >
                        <DialogTitle>Delete {businessName}?</DialogTitle>
                        <DialogContent>
                            <DialogContentText sx={{ mb: 4, mt: 4 }}>
                                Are you sure want to delete this accountant?
                            </DialogContentText>
                            <TextField
                                margin="dense"
                                id="name"
                                label="Business name"
                                type="text"
                                inputProps={{ style: { textTransform: 'uppercase' } }}
                                style={{ width: 250 }}
                                error={disabledBusinessName}
                                helperText={helperText}
                                variant="outlined"
                                onChange={handleBusiness}
                            />
                        </DialogContent>
                        <DialogActions>
                            <MDButton
                                variant="gradient"
                                color="error"
                                onClick={() => setTypeBusiness(false)}
                            >Cancel
                            </MDButton>
                            <MDButton
                                variant="gradient"
                                color="success"
                                onClick={() => warningWithConfirmMessage()}
                                disabled={disabledBusinessName}
                            >
                                Delete Business
                            </MDButton>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={alert}
                        onClose={hideAlert}
                        aria-labelledby="alert-dialog-title"
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: "100%",
                                    maxWidth: "500px",
                                },
                            },
                        }}
                    >
                        <DialogContent sx={{ overflowY: 'hidden' }}>
                            <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                                <h3>Are you sure?</h3>
                            </DialogContentText>
                            <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                                <MDTypography
                                    variant="body2"
                                    color="secondary"
                                    sx={{ fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 4 }}
                                >
                                    Once you delete this Accountant there is no way of getting any of the documents or data back.
                                </MDTypography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "center", mt: 2 }} >
                            <MDButton
                                variant="gradient"
                                color="success"
                                onClick={successDelete} >
                                Yes, delete it!
                            </MDButton>
                            <MDButton
                                variant="gradient"
                                color="error"
                                onClick={hideAlert}
                            >Cancel
                            </MDButton>
                        </DialogActions>
                    </Dialog>
                    <MDBox>
                        <MDSnackbar
                            color="success"
                            icon="done"
                            title="Success"
                            // autoHideDuration={3000}
                            content="Accountant Deleted Successfully."
                            open={showSnackbar}
                            close={() => setShowSnackbar(false)}
                        />
                    </MDBox>
                    {
                        <MDBox my={5} sx={{ mt: 1 }}>
                            <DataTable1 table={dataTableData} entriesPerPage={false} pagination={true} showTotalEntries={true} response={tableEmpty} showCheck={false} ClientHeigth={true} />
                        </MDBox>
                    }
                </Card>
            </MDBox>
        </DashboardLayout>
    );
}

export default Hoc(Accountants);