import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "layouts/components/Sidenav";
import theme from "assets/theme";
import routes from "routes";
import adminRoutes from "routes/adminRoutes";
import clientRoutes from "routes/clientRoutes";
import { useMaterialUIController, setMiniSidenav } from "context";
import brandLogo from "assets/images/logos/paynthr-logo-colored.png";
import EditAccountant from "layouts/admin/accountants/edit-accountant";
import EditAdminClient from "layouts/admin/client/edit-clients";
import EditUser from "layouts/accountants/user/edit-user";
import SignUpAuth from "layouts/auth/sign-up";
import SignInBasicAuth from "layouts/auth/sign-in";
import UserSignInAuth from "layouts/auth/user-signin";
import ForgotPassAuth from "layouts/auth/reset-password";
import AdminForgotPassAuth from "layouts/auth/admin-reset-password";
import UserForgotPassAuth from "layouts/auth/user-reset-password";
import GeneratePassAuth from "layouts/auth/create-password";
import AdminGeneratePassAuth from "layouts/auth/admin-create-password";
import UserGeneratePassAuth from "layouts/auth/user-create-password";
import EditClient from "layouts/clients/edit-clients";
import EditClientUser from "layouts/clientuser/user/edit-user";
import VendorSettings from "layouts/clients/setting";
import PageNotFound from "layouts/auth/404";
import configureStore from "store";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import UserProfile from "layouts/pages/profile/profile-overview/userprofile";
import ClientProfile from "layouts/pages/profile/profile-overview/clientprofile";
import AdminLogout from "layouts/auth/admin-logout";
import AccountantLogout from "layouts/auth/accountantlogout";
import ClientUserLogout from "layouts/auth/client-logout";
import EditVendorDialog from "layouts/accountants/user/invoice/editvendordialog";
import Accountantdashboards from "layouts/accountants/user/dashboards";
import ClientUserProfile from "layouts/pages/profile/profile-overview/clientprofile";
import CloseQboWindow from "layouts/clients/components/QBO";
import { getAdminUserType } from "helpers/getadminusertype";
import { getAccountantUserType } from "helpers/getaccountantusertype";
import Forbidden from "layouts/auth/forbidden";
import AccountantInvoice from "layouts/accountants/user/invoice/invoice";
import { getUserRole } from "helpers/getUserRole";
import Customer from "layouts/clients/setting/customer";
import ClientUserInvoice from "layouts/clientuser/user/invoice/invoice";
import VendorClientSettings from "layouts/clientuser/clients/setting";
import PolicyPage from "layouts/auth/privacy-policy-page";
import TermsOfAgreement from "layouts/auth/terms-of-agreement.js";
import Billing from "layouts/accountants/user/billing";
import AdminBilling from "layouts/admin/billing";
import AdminClientBilling from "layouts/admin/client/billing";
import AddNewRestaurant from 'layouts/clientuser/restaurants/new-restaurants';
import EditRestaurant from 'layouts/clientuser/restaurants/edit-restaurant';
import ClientUserBilling from "layouts/clientuser/billing";
import Inventory from "layouts/clientuser/inventory";
import AdminClientInfo from 'layouts/admin/clientInfo.js/index.js';
import ReactRecaptcha3 from 'react-google-recaptcha3';

function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const [PathName, setPathName] = useState(pathname.split("/").slice(1)[0]);
  const AdminUserType = getAdminUserType();
  const AccountantUserType = getAccountantUserType();
  const userRoles = getUserRole();
  const [Captcha, setCaptcha] = useState(process.env.REACT_APP_RECAPTCHA_KEY);


  const location = useLocation();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // Perform actions when the path changes
    console.log('Path changed:', location.pathname, ReactRecaptcha3);

    // You can also conditionally trigger actions based on the path
    if (location.pathname === '/sign-in' || location.pathname === '/reset-password' || location.pathname === '/user/reset-password' || location.pathname === '/create-password' || location.pathname === '/user/create-password') {
      ReactRecaptcha3.init(`${Captcha}`).then(
        (status) => {
          console.log(status);
        }
      );
    } else {
      ReactRecaptcha3.destroy()
    }

    // Cleanup function (optional)
    return () => {
      // Perform cleanup if needed
    };
  }, [location.pathname]);

  var setuserRole = (PathName === 'admin') ? AdminUserType : AccountantUserType;
  var mainRoute = [];
  var checkuser = '';

  if (setuserRole && setuserRole.user && (setuserRole.user.userType == 'admin')) {
    mainRoute = adminRoutes;
    checkuser = "admin";
  } else {
    var userRole = (setuserRole && setuserRole.user && setuserRole.user.userType) ? setuserRole.user.userType : userRoles;
    mainRoute = (userRole == 'client') ? clientRoutes : (userRole == 'accountant') ? routes : [];
    checkuser = "user";
  }

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    // if (miniSidenav && !onMouseEnter) {
    //   setMiniSidenav(dispatch, false);
    //   setOnMouseEnter(true);
    // }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    // if (onMouseEnter) {
    //   setMiniSidenav(dispatch, true);
    //   setOnMouseEnter(false);
    // }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <Sidenav
          color={sidenavColor}
          brand={brandLogo}
          brandName="Paynthr"
          routes={mainRoute}
          checkuser={checkuser}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}
      {layout === "vr"}
      <Routes>
        <Route path="/admin/sign-in" element={<SignInBasicAuth />} />
        <Route path="/admin/logout" element={<AdminLogout />} />
        <Route path="/sign-in" element={<UserSignInAuth />} />
        <Route path="/sign-up" element={<SignUpAuth />} />
        <Route path="/reset-password" element={<ForgotPassAuth />} />
        <Route path="/admin/reset-password" element={<AdminForgotPassAuth />} />
        <Route path="/create-password" element={<GeneratePassAuth />} />
        <Route path="/admin/create-password" element={<AdminGeneratePassAuth />} />
        <Route path="/privacy-policy" element={<PolicyPage />} />
        <Route path="/terms-of-agreement" element={<TermsOfAgreement />} />
        {/* <Route path="/user/dashboards" element={<Accountantdashboards />} /> */}
        <Route path="/user/reset-password" element={<UserForgotPassAuth />} />
        <Route path="/user/logout" element={<AccountantLogout />} />
        <Route path="/user/create-password" element={<UserGeneratePassAuth />} />
        <Route path="/admin/profile/profile-overview" element={<ProfileOverview />} />
        <Route path="/admin/client/:id/edit-client" element={<AdminClientInfo />} />
        <Route path="/admin/client/:id/billing" element={<AdminClientInfo />} />
        <Route path="/accountants/profile-overview" element={<UserProfile />} />
        <Route path="/accountants/billing" element={<Billing />} />
        <Route path="/admin/billing/:id" element={<AdminBilling />} />
        <Route path="/client/billing" element={<ClientUserBilling />} />
        <Route path="/admin/client/billing/:id" element={<AdminClientBilling />} />
        <Route path="/client/profile-overview" element={<ClientUserProfile />} />
        <Route path="/admin/accountants/:bussinessId/edit-accountant/" element={<EditAccountant />} />
        <Route path="/admin/client/:bussinessId/edit-client" element={<EditAdminClient />} />
        <Route path="/accountant/users/:bussinessId/edit-user" element={<EditUser />} />
        <Route path="/client/document/:id" element={<ClientUserInvoice />} />
        <Route path="/client/inventory/:id/list" element={<Inventory />} />
        <Route path="/clients/employees/:bussinessId/edit-user" element={<EditClientUser />} />
        <Route path="/client/client/:bussinessId/edit-client" element={<VendorClientSettings />} />
        <Route path="/client/client/:bussinessId/vendor" element={<VendorClientSettings />} />
        <Route path="/client/client/:bussinessId/customer" element={<VendorClientSettings />} />
        <Route path="/client/client/:bussinessId/classes" element={<VendorClientSettings />} />
        <Route path="/client/client/:bussinessId/category" element={<VendorClientSettings />} />
        <Route path="/client/client/:bussinessId/products" element={<VendorClientSettings />} />
        <Route path="/client/restaurant/new-restaurant" element={<AddNewRestaurant />} />
        <Route path="/client/restaurant/:restaurantId/edit-restaurant" element={<EditRestaurant />} />
        {/* <Route path="/client/user/edit-user" element={<EditClientUser />} /> */}
        {/* <Route path="/accountant/client/:bussinessId/edit-client" element={<EditClient />} /> */}
        <Route path="/accountant/client/:bussinessId/edit-vendor" element={<EditVendorDialog />} />
        <Route path="/accountant/client/:bussinessId/edit-client" element={<VendorSettings />} />
        <Route path="/accountant/client/:bussinessId/vendor" element={<VendorSettings />} />
        <Route path="/accountant/client/:bussinessId/customer" element={<VendorSettings />} />
        <Route path="/accountant/client/:bussinessId/classes" element={<VendorSettings />} />
        <Route path="/accountant/client/:bussinessId/category" element={<VendorSettings />} />
        <Route path="/accountant/client/:bussinessId/products" element={<VendorSettings />} />
        <Route path="layouts/auth/404" element={<PageNotFound />} />
        <Route path="/client/logout" element={<ClientUserLogout />} />
        <Route path="/Page-not-found" element={<PageNotFound />} />
        <Route path="/forbidden/page-not-found" element={<Forbidden />} />
        {/* <Route path="/accountant/document/:id" element={<AccountantInvoice />} /> */}
        <Route path='/qbo-cb' element={<CloseQboWindow />} />
        {getRoutes(mainRoute)}
        <Route path="*" element={<Navigate to="/sign-in" />} />

        {/* <Route path="*" element={<Navigate to="/Page-not-found" />} /> */}
        {/* {
          userRole == 'admin' ?
            <Route path="*" element={<Navigate to="/admin/sign-in" />} /> :
        } */}

      </Routes>
    </ThemeProvider>
  );
}

export default App;