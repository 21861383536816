import React from 'react'
import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import DataTable1 from "components/NewTable/table";
import DataTable from "../../components/Tables/DataTable";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import UserHoc from 'hoc/userHoc';
import { GetVendorList, EditVendors } from 'services/userservices';
import { useAPICall } from 'utils/useapicall';
import EditVendor from './edit-vendor';
import CircularProgress from '@mui/material/CircularProgress';
import { green, yellow } from '@mui/material/colors';
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import { eventBus } from 'utils/eventbus';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import MDButton from "components/MDButton";
import Loader from 'layouts/accountants/user/components/imgLoader/loader';
import { Grid } from '@mui/material';
import image from './output-onlinegiftools.gif'
import AddVendorDialog from 'layouts/accountants/user/invoice/addvendor';

const Vendor = ({ businessId, syncPercent, documentSource, IsSynced }) => {
  const action = { route: "", edittooltip: "Edit", deletetooltip: "Delete" };
  const [vendorList, setVendorList] = useState([]);
  const [vendorTotal, setVendorTotal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vendorId, setvendorId] = useState();
  const [search, setSearch] = useState('');
  const [alert, setAlert] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [openEditVendor, setOpenEditVendor] = useState(false);
  const [openAddVendor, setOpenAddVendor] = useState(false)
  const [showList, setShowList] = useState(true);
  const [loadingCircul, setLoadingCircul] = useState(false);
  const [{ isLoading: isVendorLoading, isError: isVendorError, response: VendorResponse }, VendorListAPICall] = useAPICall(GetVendorList, { onLoad: false });
  const [{ isLoading: isEditVendorLoading, isError: isEditVendorError, response: EditVendorResponse }, EditVendorListAPICall] = useAPICall(EditVendors, { onLoad: false });
  const { pathname } = useLocation();

  eventBus.$on('onclickchangePages', (data) => OnclickchangePages(data));
  function OnclickchangePages(data) {
    setLoading(true);
    const dataStr = data.toString() + 0
    localStorage.setItem("offset", Number(dataStr))
    VendorListAPICall({
      payload: [{ "businessId": `${businessId}`, IsSynced: IsSynced, 'offset': Number(dataStr), "keyword": `${search}` }]
    });
  }


  useEffect(async () => {
    const queryParameters = new URLSearchParams(window.location.search)
    const keyword = queryParameters.get("keyword");
    const ThisOffsetuse = localStorage.getItem("offset");
    if (keyword) {
      setSearch(keyword);
      setLoading(true);
    } else {
      setLoading(true);
      await VendorListAPICall({
        payload: [{ "businessId": `${businessId}`, "keyword": `${search}`, IsSynced: IsSynced, 'offset': ThisOffsetuse }]
      })
    }
  }, []);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const keyword = queryParameters.get("keyword");
    if (!keyword) {
      window.history.pushState('data', 'title', `${pathname}`);
    }
  }, [search]);

  useEffect(() => {
    eventBus.$emit("OnSetBlur", loading);
    eventBus.$emit("OnSetBlurEdit", loading);
    // loading === true ? setLoaderClass({ opacity: '0.4', borderRadius: '13px' }) : setLoaderClass({})
  }, [loading])

  eventBus.$on('reloadDetailsList', (data) => reloadDetailsTab(data));
  function reloadDetailsTab(data) {
    const ThisOffsetuse = localStorage.getItem("offset");
    setLoading(true);
    VendorListAPICall({
      payload: [{ "businessId": `${businessId}`, IsSynced: IsSynced, 'offset': ThisOffsetuse }]
    });
  }

  eventBus.$on('AddnewVendorComplete', (data) => AddnewVendorComplete(data));
  async function AddnewVendorComplete(data) {
    setLoading(true);
    const ThisOffsetuse = localStorage.getItem("offset");
    await VendorListAPICall({
      payload: [{ "businessId": `${businessId}`, "keyword": `${search}`, IsSynced: IsSynced, 'offset': ThisOffsetuse }]
    });
  }

  useEffect(() => {
    const ThisOffsetuse = localStorage.getItem("offset");
    const timeOutId = setTimeout(async () =>
      await VendorListAPICall({
        payload: [{ "businessId": `${businessId}`, "keyword": `${search}`, IsSynced: IsSynced, 'offset': ThisOffsetuse }]
      }), 500);
    return () => clearTimeout(timeOutId);
  }, [search]);

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return '---';
  }

  useEffect(() => {
    if (isEditVendorLoading) {
      return () => null;
    }
    if (isEditVendorError) {
      setLoading(false);
      return () => null;
    }
    if (EditVendorResponse && EditVendorResponse.data.isSuccess === true) {
      const ThisOffsetuse = localStorage.getItem("offset");
      setLoading(true);
      VendorListAPICall({
        payload: [{ "businessId": `${businessId}`, IsSynced: IsSynced, 'offset': ThisOffsetuse }]
      });
    }
  }, [EditVendorResponse]);

  useEffect(() => {
    if (isVendorLoading) {
      return () => null;
    }
    if (isVendorError) {
      setLoading(false);
      setLoadingCircul(false);
      return () => null;
    }
    if (VendorResponse && VendorResponse.data.isSuccess === true) {
      setVendorTotal(VendorResponse.data.data.totalRecords);
      const data = VendorResponse.data.data.records.map((prop, key) => {
        const Createdate = new Date(prop.createdAt);
        return {
          name: prop.name,
          email: prop.email ? prop.email : '-',
          vendor1099: prop.vendor1099 === true ? 'Yes' : 'No',
          phone: prop.phone ? formatPhoneNumber(prop.phone) : '-',
          createdat: Createdate.toLocaleDateString(),
          action: (
            documentSource === 'Paynthr' ? <MDBox display="flex" justifyContent="center">
              <MDTypography
                variant="body2"
                color="secondary"
                sx={{ marginLeft: "-4px", fontSize: "1.20rem", cursor: 'pointer' }}
              >
                <Tooltip title={action.edittooltip} placement="top"
                  onClick={(value) => {
                    setvendorId(prop._id),
                      setOpenEditVendor(true)
                  }}
                >
                  <Icon>edit</Icon>
                </Tooltip>
              </MDTypography>
              <MDTypography variant="body2" color="secondary" sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: 'pointer' }}>
                <Tooltip title={action.deletetooltip} placement="top"
                  onClick={(value) => {
                    setvendorId(prop._id);
                    setAlert(true);
                  }}
                >
                  <Icon>delete</Icon>
                </Tooltip>
              </MDTypography>
            </MDBox> : syncPercent === 100 ? <MDBox display="flex" justifyContent="center">
              <MDTypography
                variant="body2"
                color="secondary"
                sx={{ marginLeft: "-4px", fontSize: "1.20rem", cursor: 'pointer' }}
              >
                <Tooltip title={action.edittooltip} placement="top"
                  onClick={(value) => {
                    setvendorId(prop._id),
                      setOpenEditVendor(true)
                  }}
                >
                  <Icon>edit</Icon>
                </Tooltip>
              </MDTypography>
              <MDTypography variant="body2" color="secondary" sx={{ marginLeft: "8px", fontSize: "1.20rem", cursor: 'pointer' }}>
                <Tooltip title={action.deletetooltip} placement="top"
                  onClick={(value) => {
                    setvendorId(prop._id);
                    setAlert(true);
                  }}
                >
                  <Icon>delete</Icon>
                </Tooltip>
              </MDTypography>
            </MDBox> : ''
          )
        };
      }
      );
      setVendorList(data);
      setTimeout(() => {
        setLoadingCircul(false);
        setShowList(true);
        setLoading(false);
      }, 1000);

    }
  }, [VendorResponse]);

  const dataTableData = {
    columns: [
      {
        Header: "Vendor NAME",
        accessor: "name",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Phone Number", accessor: "phone",
        Cell: ({ value }) => <DefaultCell value={value} />
      },
      {
        Header: "Created Date", accessor: "createdat",
        Cell: ({ value }) => <DefaultCell value={value} />
      },
      {
        Header: "Action", accessor: "action",
        Cell: ({ value }) => <DefaultCell value={value} />
      },
    ],
    rows: vendorList
  };

  const handleSearch = async (event) => {
    localStorage.setItem("offset", 0)
    setSearch(event.target.value);
    window.history.pushState('data', 'title', `${pathname}?keyword=${encodeURIComponent(event.target.value)}`);
    setLoadingCircul(true);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  const successDelete = async () => {
    setLoading(true);
    await EditVendorListAPICall({
      payload: [
        {
          "isArchived": true,
          "VendorId": `${vendorId}`
        }
      ]
    })
    setAlert(false);
  };

  return (

    syncPercent < 100 ?
      <>
        <Grid item xs={12} >
          <MDBox mt={5} mb={5}>
            <MDTypography
              variant="body2"
              color="secondary"
              sx={{
                width: '100%', fontSize: "1.20rem", display: 'flex',
                justifyContent: 'center', mt: 2
              }}
            >
              {`Please bear with us while we retrieve information from QBO. This process may take a few moments...`}
            </MDTypography>
            <MDBox sx={{
              width: '100%', display: 'flex',
              justifyContent: 'center', fontSize: "1.20rem"
            }}>
              <div>
                <img width={23} height={23} style={{ marginBottom: '-6px', marginRight: '8px' }} src={image} />
              </div>
              <MDTypography sx={{ mr: 2 }} fontSize="1.20rem" color={'warning'} >({syncPercent}% completed)</MDTypography>
            </MDBox>
          </MDBox>
        </Grid>
      </>
      :
      showList &&
      <>
        <MDBox px={2} sx={{ pr: 3, mb: 2 }} component="form" display="flex" justifyContent="space-between" alignItems="center">
          <MDTypography variant="h5" fontWeight="medium">
            Vendor
          </MDTypography>
          <MDBox display="flex" >
            <MDBox px={2} sx={{ pr: 3, mb: 2 }}>
              <MDInput type="search" label="Search Vendor" onKeyDown={handleKeyDown} variant="standard" onChange={handleSearch} />
            </MDBox>
            <MDBox sx={{ pr: 3, mb: 2 }} >
              <MDButton
                variant="gradient"
                color="info"
                disabled={loading}
                onClick={() => setOpenAddVendor(true)}
              >
                Add vendor
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        {
          <MDBox my={5} sx={{ mt: 1 }}>
            <DataTable1 table={dataTableData} entriesPerPage={false} pagination={true} showTotalEntries={true} response={vendorTotal} showCheck={false} ClientHeigth={true} />
          </MDBox>
        }
        <MDBox>
          <Dialog
            open={alert}
            onClose={() => setAlert(false)}
            aria-labelledby="alert-dialog-title"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "450px",
                },
              },
            }}
          >
            <DialogContent sx={{ overflowY: 'hidden' }}>
              <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <h3>Are you sure?</h3>
              </DialogContentText>
              <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                <MDTypography
                  variant="body2"
                  color="secondary"
                  sx={{ fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 4 }}
                >
                  Once you delete this Vendor there is no way of getting any of the data back.
                </MDTypography>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", mt: 2 }} >
              <MDButton
                variant="gradient"
                color="success"
                onClick={successDelete} >
                Yes, delete it!
              </MDButton>
              <MDButton
                variant="gradient"
                color="error"
                onClick={() => setAlert(false)}
              >Cancel
              </MDButton>
            </DialogActions>
          </Dialog>
        </MDBox>
        <MDBox display="flex" justifyContent="center" >
          {loading && <Loader />}
        </MDBox>
        <MDBox>
          {loadingCircul && (
            <CircularProgress
              size={50}
              sx={{
                color: green[500],
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </MDBox>
        <MDBox>
          <Dialog
            open={openEditVendor}
            onClose={() => setOpenEditVendor(false)}
            aria-labelledby="alert-dialog-title"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "570px",
                  minHeight: '30%'
                },
              },
            }}
          >   <DialogTitle sx={{
            justifyContent: "center",
            display: 'flex'
          }}>Edit Vendor</DialogTitle>
            <DialogContent>
              <EditVendor vendorId={vendorId} businessId={businessId} onClose={() => setOpenEditVendor(false)}
              />
            </DialogContent>
          </Dialog>
        </MDBox>
        <MDBox>
          <MDBox>
            <Dialog
              open={openAddVendor}
              onClose={() => setOpenAddVendor(false)}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "570px",
                    minHeight: "30%"
                  },
                },
              }}
            >   <DialogTitle sx={{
              justifyContent: "center",
              display: 'flex'
            }}>Add Vendor</DialogTitle>
              <DialogContent>
                <AddVendorDialog invoiceId={''} businessId={businessId} onClose={() => setOpenAddVendor(false)}
                />
              </DialogContent>
            </Dialog>
          </MDBox>
        </MDBox>
      </>
  )
}

export default UserHoc(Vendor)