import Request from "./apicallservices";

export const UserLogin = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/users/login`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetuserList = async (params) => {
    if (params.keyword) {
        var apiUrl = `/accountants/employees?keyword=${params.keyword}&isArchived=false&limit=10&offset=${params.offset}`;
    } else if (params.userIds) {
        var apiUrl = `/accountants/employees?userIds=${encodeURIComponent(params.userIds)}&limit=10&offset=${params.offset}`;
    } else {
        var apiUrl = `/accountants/employees?isArchived=false&limit=10&offset=${params.offset}`;
    }
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetVendorList = async (params) => {
    if (params.keyword) {
        var apiUrl = `/accountants/vendors?clientId=${params.businessId}&keyword=${params.keyword}&isArchived=false&isSynced=${params.IsSynced}&limit=10&offset=${params.offset}`;
    } else {
        var apiUrl = `/accountants/vendors?clientId=${params.businessId}&isArchived=false&isSynced=${params.IsSynced}&limit=10&offset=${params.offset}`;
    }
    // `/accountants/clients/${params.businessId}/vendors`;
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetVendorForList = async (params) => {
    if (params.keyword) {
        var apiUrl = `/accountants/vendors?clientId=${params.businessId}&keyword=${params.keyword}&isArchived=false&isSynced=${params.IsSynced}&limit=5000&offset=0`;
    } else {
        var apiUrl = `/accountants/vendors?clientId=${params.businessId}&isArchived=false&isSynced=${params.IsSynced}&limit=5000&offset=0`;
    }
    // `/accountants/clients/${params.businessId}/vendors`;
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}


export const GetCustomerList = async (params) => {
    if (params.keyword) {
        var apiUrl = `/accountants/customers?clientId=${params.businessId}&keyword=${params.keyword}&isArchived=false&isSynced=true&limit=10&offset=${params.offset}`;
    } else {
        apiUrl = `/accountants/customers?clientId=${params.businessId}&isArchived=false&isSynced=true&limit=10&offset=${params.offset}`;
    }
    // `/accountants/clients/${params.businessId}/vendors`;
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetCategoryList = async (params) => {
    if (params.keyword) {
        var apiUrl = `/accountants/finaccounts?clientId=${params.businessId}&keyword=${params.keyword}&isArchived=false&isSynced=true&limit=10&offset=${params.offset}`;
    } else {
        var apiUrl = `/accountants/finaccounts?clientId=${params.businessId}&isArchived=false&isSynced=true&limit=10&offset=${params.offset}`;
    }
    // `/accountants/clients/${params.businessId}/vendors`;
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetClassesList = async (params) => {
    if (params.keyword) {
        var apiUrl = `/accountants/classes?clientId=${params.businessId}&keyword=${params.keyword}&isArchived=false&isSynced=true&limit=10&offset=${params.offset}`;
    } else {
        var apiUrl = `/accountants/classes?clientId=${params.businessId}&isArchived=false&isSynced=true&limit=10&offset=${params.offset}`;
    }
    // `/accountants/clients/${params.businessId}/vendors`;
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetProductsList = async (params) => {
    if (params.keyword) {
        var apiUrl = `/accountants/products?clientId=${params.businessId}&keyword=${params.keyword}&isArchived=false&isSynced=true&limit=10&offset=${params.offset}`;
    } else {
        var apiUrl = `/accountants/products?clientId=${params.businessId}&isArchived=false&isSynced=true&limit=10&offset=${params.offset}`;
    }
    // `/accountants/clients/${params.businessId}/vendors`;
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetDeleteUserList = async (params) => {
    if (params.isArchived && params.keyword) {
        var apiUrl = `/accountants/employees?isArchived=${params.isArchived}&keyword=${params.keyword}`;
    } else if (params.isArchived) {
        var apiUrl = `/accountants/employees?isArchived=${params.isArchived}`;
    } else {
        var apiUrl = `/accountants/employees`;
    }
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}


export const AddNewuser = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/employees`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}


export const GetuserClientList = async (params) => {
    if (params.keyword) {
        var apiUrl = `/accountants/clients?keyword=${params.keyword}&limit=10&offset=${params.offset}`;
    } else {
        var apiUrl = `/accountants/clients?limit=10&offset=${params.offset}`;
    }
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetDeleteuserClientList = async (params) => {
    if (params.isArchived && params.keyword) {
        var apiUrl = `/accountants/clients?isArchived=${params.isArchived}&keyword=${params.keyword}`;
    } else if (params.isArchived) {
        var apiUrl = `/accountants/clients?isArchived=${params.isArchived}`;
    } else {
        var apiUrl = `/accountants/clients`;
    }
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetuserClientDetails = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/clients/${params.bussinessId}`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}


export const AddNewUserClient = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/clients`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetRolescope = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/roles/`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetuserDetailsList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/employees/${params.bussinessId}`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);

        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const EditUserInfo = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/employees/${params.bussinessId}`;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}


export const AccountantClientEdit = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/clients/${params.bussinessId}`;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const EditUserProfile = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/profiles/`;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetUserProfile = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/profiles/`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}


export const GetInvoiceList = async (params) => {
    if (params.keyword) {
        var apiUrl = `/accountants/clients/${params.businessId}/documents?documentStatus=${params.invoiceStatus}&keyword=${params.keyword}&limit=10&offset=${params.offset}`;
    } else {
        var apiUrl = `/accountants/clients/${params.businessId}/documents?documentStatus=${params.invoiceStatus}&limit=10&offset=${params.offset}`
        // `/accountants/documents?clientId=${params.businessId}&invoiceStatus=${params.invoiceStatus}`;
    }
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetStatusCount = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/clients/${params.businessId}/documents-count`
        // `/accountants/clients/${params.businessId}/invoices-count`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetInvoiceDetails = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/clients/${params.businessId}/documents/${params.InvoiceId}`
        // `/accountants/clients/${params.businessId}/invoices/${params.InvoiceId}`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const UpdateInvoiceDetails = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/clients/${params.businessId}/documents/${params.documnetId}`;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetAuditLogList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/clients/${params.businessId}/documents/${params.InvoiceId}/logs`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetCommentsList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/clients/${params.businessId}/documents/${params.InvoiceId}/comments`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const AddNewItem = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/documents/${params.InvoiceId}/items`;
        delete params.InvoiceId;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const DeleteItem = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/documents/${params.InvoiceId}/items/${params.ItemId}`;
        const req = new Request(apiUrl, params);
        req.delete().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetItemList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/documents/${params.InvoiceId}/items?isArchived=false`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const EditItem = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/documents/${params.InvoiceId}/items/${params.ItemId}`;
        delete params.InvoiceId;
        delete params.ItemId;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const DeleteInvoice = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/clients/${params.businessId}/documents/delete`;
        const req = new Request(apiUrl, params.documentIds);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const CreateComments = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/clients/${params.businessId}/documents/${params.InvoiceId}/comments`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const EditVendorDetails = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/clients/${params.businessId}/vendors/${params.VendorId}`;
        delete params.VendorId;
        delete params.businessId;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const EditVendors = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/vendors/${params.VendorId}`;
        delete params.VendorId;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const EditCustomers = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/customers/${params.customerId}`;
        delete params.customerId;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const EditClass = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/classes/${params.classesId}`;
        delete params.classesId;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const EditCategoryApi = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/finaccounts/${params.categoryId}`;
        delete params.categoryId;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const EditProductApi = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/products/${params.productId}`;
        delete params.productId;
        const req = new Request(apiUrl, params);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const MoveMenuInvoice = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/clients/${params.businessId}/documents`;
        const req = new Request(apiUrl, params.documentIds, params.documentStatus);
        req.put().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetIssueList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/clients/${params.businessId}/documents/${params.InvoiceId}/issues`
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const checkEmailUser = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/employees/check`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const checkEmail = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/clients/check`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const AssignNewOwner = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/clients/${params.businessId}/assign-owner`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetVendorDetails = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/vendors/${params.VendorId}`;
        delete params.VendorId;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetCustomerDetails = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/customers/${params.customerId}`;
        delete params.customerId;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetPaymentagreement = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/profiles/payment-intent`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetCardDetails = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/profiles/card`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetAccountantBilling = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/profiles/invoices`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const AddVendors = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/vendors/`;
        delete params.VendorId;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetClientLogsList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/profiles/invoices/${params.accuntantId}/breakups`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const AddNewProducts = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/products`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const AddCustomers = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/customers`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const AddClasses = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/classes`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const AddCategoryApi = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/finaccounts`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetIncomeAccountList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/finaccounts?clientId=${params.clientId}&isArchived=false&accountType=Income`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetExpenseAccountList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/finaccounts?clientId=${params.clientId}&isArchived=false&GetExpenseAccountList`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetAssetAccountList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/finaccounts?clientId=${params.clientId}&isArchived=false&accountSubType=Inventory`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const PostIncomeAccountList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/finaccounts?clientId=${params.clientId}&isArchived=false&accountSubType=SalesOfProductIncome`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const PostExpenseAccountList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/finaccounts?clientId=${params.clientId}&isArchived=false&accountSubType=SuppliesMaterialsCogs`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const PostassetAccountList = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/finaccounts?clientId=${params.clientId}&isArchived=false&accountSubType=Inventory`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const AccountantClientDelete = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/clients/${params.bussinessId}`;
        delete params.bussinessId;
        const req = new Request(apiUrl, params);
        req.delete().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetProcessIdApi = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/profiles/startocr`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetIncomeAccountListForInv = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/finaccounts?clientId=${params.clientId}&isArchived=false&accountType=Income&accountSubType=SalesOfProductIncome`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetExpenseAccountListForInv = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/accountants/finaccounts?clientId=${params.clientId}&isArchived=false&GetExpenseAccountList&accountType=Cost of Goods Sold&accountSubType=SuppliesMaterialsCogs`;
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetCategoryAllList = async (params) => {
    if (params.keyword) {
        var apiUrl = `/accountants/finaccounts?clientId=${params.businessId}&keyword=${params.keyword}&isArchived=false&isSynced=true&limit=5000&offset=0`;
    } else {
        var apiUrl = `/accountants/finaccounts?clientId=${params.businessId}&isArchived=false&isSynced=true&limit=5000&offset=0`;
    }
    // `/accountants/clients/${params.businessId}/vendors`;
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetClassesAllList = async (params) => {
    if (params.keyword) {
        var apiUrl = `/accountants/classes?clientId=${params.businessId}&keyword=${params.keyword}&isArchived=false&isSynced=true&limit=5000&offset=0`;
    } else {
        var apiUrl = `/accountants/classes?clientId=${params.businessId}&isArchived=false&isSynced=true&limit=5000&offset=0`;
    }
    // `/accountants/clients/${params.businessId}/vendors`;
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetCustomerAllList = async (params) => {
    if (params.keyword) {
        var apiUrl = `/accountants/customers?clientId=${params.businessId}&keyword=${params.keyword}&isArchived=false&isSynced=true&limit=5000&offset=0`;
    } else {
        apiUrl = `/accountants/customers?clientId=${params.businessId}&isArchived=false&isSynced=true&limit=5000&offset=0`;
    }
    // `/accountants/clients/${params.businessId}/vendors`;
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const GetProductsAllList = async (params) => {
    if (params.keyword) {
        var apiUrl = `/accountants/products?clientId=${params.businessId}&keyword=${params.keyword}&isArchived=false&isSynced=true&limit=5000&offset=0`;
    } else {
        var apiUrl = `/accountants/products?clientId=${params.businessId}&isArchived=false&isSynced=true&limit=5000&offset=0`;
    }
    // `/accountants/clients/${params.businessId}/vendors`;
    return new Promise(async (resolve, reject) => {
        const req = new Request(apiUrl, params);
        req.get().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}