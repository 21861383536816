import * as Yup from "yup";

export const AddNewSchema = Yup.object().shape({
    firstname: Yup.string()
        .trim()
        .required('First name is required.')
        .min(3, 'First Name should be more than 3 characters.'),
    lastname: Yup.string()
        .trim()
        .required('Last name is required.')
        .min(3, 'Last Name should be more than 3 characters.'),
    role: Yup.string().required('Role is required.'),
});

export const AddNewSchema2 = Yup.object().shape({
    firstname: Yup.string()
        .trim()
        .required('First name is required.')
        .min(3, 'First Name should be more than 3 characters.'),
    lastname: Yup.string()
        .trim()
        .required('Last name is required.')
        .min(3, 'Last Name should be more than 3 characters.'),
    role: Yup.string().required('Role is required.'),
    features: Yup.string().required('Features is required.'),
});
