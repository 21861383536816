import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PolicyBasicLayout from "layouts/auth/components/BasicLayout/policypage";
import React from 'react';


function PolicyPage() {
    return (
        <PolicyBasicLayout bgColor={'#fffff'}>
            <MDBox mb={7}>
                <MDTypography sx={{ fontWeight: 300, display: "flex", justifyContent: 'center' }} variant='h1'>Privacy Policy</MDTypography>
            </MDBox>
            <MDBox mb={7}>
                <MDTypography>Thank you for using our application connected to QuickBooks ("Paynthr"). This Privacy Policy is designed to inform you about the types of information we collect, how we use and protect that information when you use our Application. We are committed to safeguarding your privacy and ensuring that your personal information is handled responsibly and in accordance with applicable data protection laws.</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>1. Information We Collect:</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>1.1. Personal Information:</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography>
                    When you use our Application, we may collect personal information that you provide voluntarily, such as your name, email address, contact information, and QuickBooks login credentials. We only collect this information to provide you with the intended functionality of Paynthr.
                </MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>1.2. QuickBooks Data:</MDTypography>
            </MDBox>
            <MDBox mb={7}>
                <MDTypography>To provide you with the full functionality of Paynthr, we may request access to certain data from your QuickBooks account, such as company information, transaction data, customer information, and other relevant details. We only access this data with your explicit permission and solely for the purpose of integrating and syncing it with Paynthr.</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>2. Use of Information:</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>2.1. Application Usage:</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography>We may use the information we collect to operate, maintain, and improve Paynthr's features and functionality. This includes providing you with access to your QuickBooks data, displaying relevant information within Paynthr, and ensuring the smooth operation of the integration.</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>2.2. Communication:</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography>We may use your contact information to communicate with you regarding updates, notifications, or changes to Paynthr or our services. We may also send you marketing or promotional materials related to Paynthr, but you will always have the option to unsubscribe from such communications.</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>2.3. Data Aggregation:</MDTypography>
            </MDBox>
            <MDBox mb={7}>
                <MDTypography>We may aggregate and anonymize the data we collect from you to generate statistical or analytical insights. These aggregated insights do not personally identify you and may be used for research, analysis, or reporting purposes.</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>3. Data Security:</MDTypography>
            </MDBox>
            <MDBox mb={7}>
                <MDTypography>We take data security seriously and have implemented appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and therefore, we cannot guarantee absolute security of your data.</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>4. Data Sharing:</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>4.1. Service Providers:</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography>We may engage trusted third-party service providers to assist us in operating, managing, or improving Paynthr. These service providers may have access to your personal information only to perform tasks on our behalf and are obligated not to disclose or use it for any other purpose.</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>4.2. Legal Requirements:</MDTypography>
            </MDBox>
            <MDBox mb={7}>
                <MDTypography>We may disclose your personal information if required to do so by law, regulation, or legal process, or if we believe that such disclosure is necessary to protect our rights, property, or safety, or the rights, property, or safety of others.</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>5. Third-Party Links:</MDTypography>
            </MDBox>
            <MDBox mb={7}>
                <MDTypography>Paynthr may contain links to third-party websites or services that are not owned or controlled by us. We are not responsible for the privacy practices or the content of these third-party websites. We encourage you to review the privacy policies of these websites before providing any personal information.</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>6. Children's Privacy:</MDTypography>
            </MDBox>
            <MDBox mb={7}>
                <MDTypography>Paynthr is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If you believe that we may have collected personal information from a child without parental consent, please contact us, and we will take appropriate steps to remove such information from our systems.</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>7. Changes to the Privacy Policy:</MDTypography>
            </MDBox>
            <MDBox mb={7}>
                <MDTypography>We reserve the right to update or modify this Privacy Policy from time to time. If we make any material changes, we will notify you by email or through Paynthr. Your continued use of Paynthr after any modifications to the Privacy Policy will signify your acceptance of the revised terms.</MDTypography>
            </MDBox>
            <MDBox mb={2}>
                <MDTypography sx={{ fontWeight: 400 }} variant='h4'>8. Contact Us:
                </MDTypography>
            </MDBox>
            <MDBox mb={7}>
                <MDTypography>If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at [contact information].</MDTypography>
            </MDBox>
            <MDBox mb={7}>
                <MDTypography>By using Paynthr, you acknowledge that you have read and understood this Privacy Policy and agree to the collection, use, and disclosure of your information as described herein.</MDTypography>
            </MDBox>
        </PolicyBasicLayout >
    );
}

export default PolicyPage;