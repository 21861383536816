

const channelChartData = {
  labels: ["Accountant", "Client", "Total Invoice", "Pending Invoice"],
  datasets: {
    label: "Projects",
    backgroundColors: ["info", "primary", "dark", "secondary", "primary"],
    data: [15, 20, 12, 60],
  },
};

export default channelChartData;
