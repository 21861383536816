import Request from "./apicallservices";

export const Login = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/profiles/login`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const VerifyToken = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/users/verify-email-token`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}


export const CreateAccountantPassword = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/users/reset-password`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const AdminVerifyToken = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/profiles/verify-email-token`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const AdminResetPassword = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/profiles/request-password`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const CreateAdminPassword = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/admins/profiles/reset-password`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}

export const AccountantResetPassword = async (params) => {
    return new Promise(async (resolve, reject) => {
        const apiUrl = `/users/request-password`;
        const req = new Request(apiUrl, params);
        req.post().then((response) => {
            resolve(response);
        }).catch((error) => {
            resolve(error.response)
        });
    })
}