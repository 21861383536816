const setRole = (payload) => {
    return {
        type: "role",
        payload
    }
};
const setPermission = (payload) => {
    return {
        type: "permission",
        payload
    }
};
const setToken = (payload) => {
    return {
        type: "token",
        payload
    }
};
const setProfileInfo = (payload) => {
    return {
        type: "profileInfo",
        payload
    }
};
const setUserType = (payload) => {
    return {
        type: "userType",
        payload
    }
};

const setQboData = (payload) => {
    return {
        type: "qboData",
        payload
    }
};

export {
    setProfileInfo,
    setPermission,
    setRole,
    setToken,
    setUserType,
    setQboData,
}