import React, { useState, useEffect } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";
import MDSnackbar from "components/MDSnackbar";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from "@mui/material/IconButton";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/auth/components/BasicLayout";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { CreatePassSchema } from "layouts/auth/components/schemas/validations";
import FormField from "layouts/auth/components/FormField";
import initialValues from "layouts/auth/components/schemas/initialValues";

// Images
import bgImage from "assets/images/backgroundimage.jpg";
// api 
import { CreateAdminPassword, AdminVerifyToken } from "../../../services/authservices";
import { useAPICall } from "../../../utils/useapicall";
import { green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';

function CreatePassword() {
    const navigate = useNavigate();
    const [pathKey, setPathKey] = useState();
    const [pathToken, setPathToken] = useState();
    const [isDisable, setIsDisable] = useState(false);
    const [emailId, setEmailId] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false)
    const [showRepeatPassword, setShowRepeatPassword] = useState(false)
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState("Failed");
    const [{ isError, isLoading, response }, setAPICall] = useAPICall(CreateAdminPassword, { onLoad: false });
    const [{ isLoading: isCheckTokenLoading, isError: isCheckTokenError, response: CheckTokenResponse }, CheckTokenAPICall] = useAPICall(AdminVerifyToken, { onLoad: false });
    const handleClickShowPassword = () => setShowPassword(true);
    const handleClickClosePassword = () => setShowPassword(false)
    const handleClickShowRepeatPassword = () => setShowRepeatPassword(true);
    const handleClickCloseRepeatPassword = () => setShowRepeatPassword(false)
    useEffect(async () => {
        if (isLoading) {
            return () => null;
        }
        if (isError) {
            if (response?.data?.message) {
                setErrorMessage(response.data.message)
            }
            setShowErrorSnackbar(true);

            setTimeout(() => {
                setShowErrorSnackbar(false);
            }, 3000);
            setIsDisable(false);
            return () => null;
        }
        if (response && response.data.isSuccess === true) {
            setShowSnackbar(true);
            setTimeout(() => {
                setShowSnackbar(false);
            }, 3000);
            setTimeout(() => {
                navigate('/admin/sign-in');
            }, 2000);

        }
    }, [response]);

    useEffect(() => {

        if (isCheckTokenLoading) {
            return () => null;
        }

        if (isCheckTokenError) {
            navigate('/admin/sign-in');
            return () => null;
        }

        if (CheckTokenResponse && CheckTokenResponse.data.isSuccess === true) {
            setLoading(false);
            setEmailId(CheckTokenResponse.data.data.email);
        }
    }, [CheckTokenResponse])

    useEffect(async () => {
        // const Key = new URLSearchParams(location.search).get("key");
        // setPathKey(Key);
        const token = new URLSearchParams(location.search).get("token");
        setPathToken(token);
        setLoading(true);
        await CheckTokenAPICall({
            payload: [
                {
                    "token": `${token}`,
                }
            ]
        })
    }, []);


    const onSubmit = async (values) => {
        setIsDisable(true);
        await setAPICall({
            payload: [
                {
                    "email": `${emailId}`,
                    "token": `${pathToken}`,
                    "password": `${values.password}`
                }
            ]
        })
    };

    return (
        <BasicLayout image={bgImage}>
            {
                !loading &&
                <Card>
                    <MDBox
                        variant="gradient"
                        borderRadius="lg"
                        coloredShadow="info"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={1}
                        sx={{
                            backgroundColor: "#80D6C3",
                        }}
                        textAlign="center"
                    >
                        <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                            Welcome to Paynthr
                        </MDTypography>
                        <MDTypography fontWeight="200" color="white" fontSize="small" textAlign="center">
                            Create New Password
                        </MDTypography>
                    </MDBox>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={CreatePassSchema}
                        onSubmit={onSubmit}
                    >
                        <Form autoComplete="off">
                            <div>
                                <MDBox pt={4} pb={3} px={3}>
                                    <MDBox mb={2}>
                                        <FormField
                                            type={showPassword ? "text" : "password"}
                                            label="New Password"
                                            name="password"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton sx={{ fontSize: '0.9rem' }}>
                                                            {!showPassword ? <VisibilityOff onClick={handleClickShowPassword} /> : <Visibility onClick={handleClickClosePassword} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}

                                        />
                                    </MDBox>
                                    <MDBox mb={2}>
                                        <FormField
                                            type={showRepeatPassword ? "text" : "password"}
                                            label="Confirm Password"
                                            name="repeatPassword"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton sx={{ fontSize: '0.9rem' }}>
                                                            {!showRepeatPassword ? <VisibilityOff onClick={handleClickShowRepeatPassword} /> : <Visibility onClick={handleClickCloseRepeatPassword} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}

                                        />
                                    </MDBox>
                                    {/* <MDBox mt={0.75}>
                                        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                            <ErrorMessage name="repeatPassword" />
                                        </MDTypography>
                                    </MDBox> */}
                                    {/* <MDBox mt={0.75}>
                                        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                            <ErrorMessage name="password" />
                                        </MDTypography>
                                    </MDBox> */}
                                    <MDBox mt={4} mb={1}>
                                        <MDButton
                                            disabled={isDisable}
                                            type="submit"
                                            variant="gradient"
                                            color="info"
                                            fullWidth
                                        >
                                            {isDisable ? "Generating..." : "create"}
                                        </MDButton>
                                    </MDBox>
                                </MDBox>
                            </div>
                        </Form>
                    </Formik>
                    <MDBox>
                        <MDSnackbar
                            color="success"
                            icon="done"
                            title="success"
                            content="Password has been successfully created."
                            open={showSnackbar}
                            close={() => setShowSnackbar(false)}
                        />
                        <MDSnackbar
                            color="error"
                            icon="warning"
                            title="Failed."
                            content={ErrorMessage}
                            open={showErrorSnackbar}
                            close={() => setShowErrorSnackbar(false)}
                        />
                    </MDBox>
                </Card>
            }

            <MDBox>
                {loading && (
                    <CircularProgress
                        size={50}
                        sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </MDBox>
        </BasicLayout>
    );
}

export default CreatePassword;