import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import breakpoints from "assets/theme/base/breakpoints";
import DashboardLayout from "layouts/admin/components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/admin/components/Navbars/DashboardNavbar";
function BaseLayout({ stickyNavbar, children }) {
    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.sm
                ? setTabsOrientation("vertical")
                : setTabsOrientation("horizontal");
        }


        window.addEventListener("resize", handleTabsOrientation);
        handleTabsOrientation();
        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);

    const handleSetTabValue = (event, newValue) => setTabValue(newValue);

    return (
        <DashboardLayout>
            <DashboardNavbar absolute={!stickyNavbar} />
            <MDBox mt={stickyNavbar ? 3 : 10}>
                {/* <Grid container>
                    <Grid item xs={12} sm={8} lg={4}>
                        <AppBar position="static">
                            <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                                <Tab label="Messages" />
                                <Tab label="Social" />
                                <Tab label="Notifications" />
                                <Tab label="Backup" />
                            </Tabs>
                        </AppBar>
                    </Grid>
                </Grid> */}
                {children}
            </MDBox>
        </DashboardLayout>
    );
}

BaseLayout.defaultProps = {
    stickyNavbar: false,
};

BaseLayout.propTypes = {
    stickyNavbar: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

export default BaseLayout;