import * as Yup from "yup";

export const EditRestorntSchema = Yup.object().shape({
    restaurantname: Yup.string()
        .trim()
        .required('Restaurant name is required.')
        .min(3, 'Restaurant name should be more than 3 characters.'),
    zip_code: Yup.string().matches(/^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/, "Zip code must be a number type")
        .nullable()
        .min(5, 'Zip code must be 5 characters.')
        .max(5, 'Zip code must be 5 characters.'),
});
