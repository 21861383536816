import PropTypes from "prop-types";
import { ErrorMessage, Field } from "formik";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import InputAdornment from "@mui/material/InputAdornment";
import { useState } from "react";
import { useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function FormField({ label, dateMismatch = false, name, icon, editable = false, ...rest }) {
  const [dottedStyle, setDottedStyle] = useState({});
  useEffect(() => {
    dateMismatch === true ? editable ? setDottedStyle({ borderBottom: `2px solid red` }) : setDottedStyle({ borderBottom: `2px solid red` }) : editable ? setDottedStyle({ borderBottom: `dotted 0.8px` }) : setDottedStyle({});
  }, [editable, dateMismatch])
  return (
    <MDBox mb={1.5}>
      {dateMismatch ?
        <Field {...rest} name={name} as={MDInput} variant="standard" style={dottedStyle} label={label} fullWidth autoComplete="off"
          InputLabelProps={{
            style: { color: 'red' },
          }}
          InputProps={{
            disableUnderline: dateMismatch,
            endAdornment: (
              <Tooltip title={'Invoice date not found.'} placement="top">
                <InputAdornment position="end">
                  <ErrorOutlineIcon sx={{ fontSize: '1rem !important', mr: '14px', color: 'red', cursor: 'default' }} />
                </InputAdornment>
              </Tooltip>
            ),
            startAdornment: (
              <InputAdornment position="start" sx={{ marginRight: '-1px !important' }}>

              </InputAdornment>
            ),

          }} />
        :
        <Field {...rest} name={name} as={MDInput} variant="standard" style={dottedStyle} label={label} fullWidth autoComplete="off"
          InputProps={{
            disableUnderline: editable,
            startAdornment: (
              <InputAdornment position="start" sx={{ marginRight: '-1px !important' }} >

              </InputAdornment>
            ),

          }} />}
      <MDBox mt={0.75}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          <ErrorMessage name={name} />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

FormField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default FormField;
