import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import FormField from "layouts/admin/accountants/components/FormField";
import { useAPICall } from "utils/useapicall";
import { AddBusinessSchemas } from "./components/schemas/addbusinessschemas.js";
import { CreateClientusersClient } from "services/clientservices.js";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import { eventBus } from "utils/eventbus";
import MDSnackbar from "components/MDSnackbar";
import { isMobile } from 'react-device-detect';

function AddBusinessDialog({ onClose, invoiceId, restaurantsId }) {
    const [btnDisable, setBtnDisable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    let initialValues = {
        businessname: "",
    };

    const [{ isLoading: isAddNewBusinessLoading, isError: isAddNewBusinessError, response: AddNewBusinessResponse }, AddNewBusinessListAPICall] = useAPICall(CreateClientusersClient, { onLoad: false });

    useEffect(() => {
        if (isAddNewBusinessLoading) {
            return () => null;
        }
        if (isAddNewBusinessError) {
            setErrorMessage(AddNewBusinessResponse?.data?.message);
            setLoading(false);
            setBtnDisable(false);
            eventBus.$emit("AddnewBusinessFailed", AddNewBusinessResponse?.data?.data);
            return () => null;
        }
        if (AddNewBusinessResponse && AddNewBusinessResponse.data.isSuccess === true) {
            setLoading(false);
            setBtnDisable(false);
            eventBus.$emit("AddnewBusinessComplete", AddNewBusinessResponse?.data?.data);
            onClose();
        }
    }, [AddNewBusinessResponse]);

    const handleSubmit = async (values) => {
        setBtnDisable(true);
        setLoading(true);
        await AddNewBusinessListAPICall({
            payload: [
                {
                    "name": values?.businessname
                }]
        })
    };

    return (
        <>{
            <>
                <MDBox mt={0} >
                    <Grid spacing={3}>
                        <Grid item xs={12}>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={AddBusinessSchemas}
                                onSubmit={handleSubmit}
                            >
                                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                                    <Form autoComplete="off">
                                        <MDBox p={3} >
                                            <MDBox>
                                                <MDBox mt={1.625} sx={{
                                                    '@media(max-width:575px)': {
                                                        pl: '0%'
                                                    },
                                                    '@media(max-width1400px)': {
                                                        pl: '25%'
                                                    },
                                                    '@media(max-width1900px)': {
                                                        pl: '25%'
                                                    }
                                                }} pl={'25%'}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={9}>
                                                            <FormField
                                                                type="text"
                                                                label="Business Name *"
                                                                name="businessname"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </MDBox>
                                                <MDBox mt={4} mb={-2} width="100%" display="flex" justifyContent="end">
                                                    {!isMobile ?
                                                        <MDButton
                                                            disabled={btnDisable}
                                                            variant="gradient"
                                                            color="light"
                                                            onClick={onClose}
                                                        >
                                                            Cancel
                                                        </MDButton>
                                                        :
                                                        <MDButton
                                                            sx={{
                                                                marginTop: '0px!important',
                                                                '@media(max-width:575px)': {
                                                                    marginTop: '8px!important',
                                                                }
                                                            }}
                                                            disabled={btnDisable}
                                                            variant="gradient"
                                                            color="light"
                                                            onClick={onClose}
                                                        >
                                                            Cancel
                                                        </MDButton>
                                                    }

                                                    <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                        <MDButton
                                                            sx={{ marginLeft: '4px!important' }}
                                                            disabled={btnDisable}
                                                            type="submit"
                                                            variant="gradient"
                                                            color="info"
                                                        >
                                                            {btnDisable ? "Submitting..." : "Submit"}
                                                        </MDButton>
                                                    </MDBox>
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </MDBox>
                <MDBox>
                    <MDSnackbar
                        color="error"
                        icon="warning"
                        title="Failed."
                        content={errorMessage}
                        // autoHideDuration={3000}
                        open={showErrorSnackbar}
                        close={() => setShowErrorSnackbar(false)}
                    />
                </MDBox>
            </>
        }
            <MDBox>
                {loading && (
                    <CircularProgress
                        size={50}
                        sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </MDBox>
        </>
    );
}

export default AddBusinessDialog;
