import * as React from "react";
import { useState, useEffect, useRef } from "react";
import Moment from 'moment';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import PropTypes from 'prop-types';
import axios from 'axios';
import * as Yup from "yup";
import Tooltip from "@mui/material/Tooltip";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from '@mui/material/DialogTitle';
import { eventBus } from 'utils/eventbus';
import CurrencyFieldText, { handleValueChange } from "../../user/components/CurrencyField-Text";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MDBadge from "components/MDBadge";
import DefaultCell from "layouts/admin/accountants/components/DefaultCell";
import EditCategoryItemDialog from "./editcategoryitemdialog";
import FormField from "layouts/admin/accountants/components/FormField";
import FormFieldDate from "layouts/accountants/user/components/FormFieldDate";
import { InvoiceEditSchema } from "../components/schemas/editinvoicevalidation";
import MDSnackbar from "components/MDSnackbar";
import Fade from '@mui/material/Fade';
import { Formik, Form, Field, ErrorMessage, isString } from "formik";
import { useNavigate } from "react-router-dom";
import { DataGrid, useGridApiContext, GridCellModes } from '@mui/x-data-grid';
import { GetInvoiceDetails, UpdateInvoiceDetails, GetAuditLogList, GetCommentsList, DeleteItem, GetItemList, DeleteInvoice, CreateComments, GetVendorForList, EditItem, GetCategoryAllList, GetClassesAllList, GetCustomerAllList, GetProductsAllList, MoveMenuInvoice, GetuserClientList, GetuserClientDetails, GetIssueList, GetuserList, AddVendors } from "../../../../services/userservices";
import { useAPICall } from "../../../../utils/useapicall";
import MDTypography from "components/MDTypography";
import { canAccess } from '../../../../helpers';
import { DocumnetType } from "helpers/documentType";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AddItemDialog from "./additemdialog";
import AddCategoryItemDialog from "./addCategoryItemDialog";
import EditProductItemDialog from "./editproductdialog";
import AddVendorDialog from "./addvendor";
import CardHeader from '@mui/material/CardHeader';
import TimelineItem from "./components/Timeline/TimelineItem";
import { pdfjs } from "react-pdf";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@mui/material/IconButton';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import { GridRowModes, DataGridPro, GridToolbarContainer, GridActionsCellItem } from '@mui/x-data-grid-pro';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/cjs/Page/TextLayer.css'
import { PrimaryButton, SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import CustomDialog from "components/CustomDialog";
import CustomDialog2 from "components/CustomDialog/customDialog2";
import { inLocalTime } from "utils/timeHelper";
import { textEllipsis } from "components/textEllipsis";
import { Button, createTheme, Modal, ThemeProvider } from "@mui/material";
import Stack from '@mui/material/Stack';
import AddOwnerDetailsDialog from "layouts/clients/add-owner";
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import LoaderDailog from "../components/imgLoader/LoaderDailog";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InputAdornment from '@mui/material/InputAdornment';
import AddClassDialog from "./addnewclass";
import AddCustomerDialog from "./addnewcustomer";
import AddNewProductDialog from "./addnewproduct";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddNewCategoryDialog from "./addnewcategory";
import { MentionsInput, Mention } from "react-mentions";
import { saveAs } from "file-saver";
import "./styles.css";
import styled from "styled-components";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import mentionsInputStyle from "./mentionsInputStyle";
import {
    TransformWrapper,
    TransformComponent,
    useControls
} from "react-zoom-pan-pinch";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    option: {
        "&:first-child": {
            color: "#0000ff",
        },
        "&:hover:first-child": {
            color: "#0000ff",
            backgroundColor: "none !important"
        }
    },
    InputRoot: {
        '&::-webkit-calendar-picker-indicator': {
            display: 'none !important'
        }
    }
}));

const myTheme = createTheme({
    palette: {
        type: 'dark'
    },
    typography: {
        fontSize: 13,
        fontWeight: 600,
        backgroundColor: 'rgb(123, 128, 154)'
    },
    components: {
        //@ts-ignore - this isn't in the TS because DataGird is not exported from `@mui/material`
        MuiDataGrid: {
            styleOverrides: {
                row: {
                    '& .MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    '& .MuiDataGrid-actionsCell': {
                        gridGap: '0px'
                    },
                    '& .MuiDataGrid-cell': {
                        justifyContent: "center",
                        outline: ' none',
                        fontWeight: 'bold !important',
                        color: 'rgb(123 128 154)',
                        // border: 0,
                    },
                    '& .MuiDataGrid-cell:focus': {
                        outline: ' none',
                    },
                    "&.Mui-selected": {
                        backgroundColor: "white",
                        color: "black",
                        "&:hover": {
                            backgroundColor: "white"
                        }
                    }
                }
            }
        }
    }
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function EditInvoiceDialog({ invoiceIds, businessId, onIssueClick, onIssueClickData, documentsId, onClickClose, moduleTypeEdit, selectTab, onClose, storeResponce, onClose1, reloadApiAfterClose, issueType, selectedIndex }) {
    let newObj = {};
    const classes = useStyles();
    const navigate = useNavigate();
    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;
    const [textAreaVal, setTextAreaVal] = useState("");
    const [novendorIssue, setNoVendorIssue] = useState(false);
    const [totalIssue, setTotalIssues] = useState(0);
    const [novendorIssueMsg, setNoVendorIssueMsg] = useState('');
    const [priceMismatch, setPriceMismatch] = useState(false);
    const [priceMismatchMsg, setPriceMismatchMsg] = useState('');
    const [totalMismatch, setTotalMismatch] = useState(false);
    const [totalMismatchMsg, setTotalMismatchMsg] = useState(false);
    const [onlytotalMismatch, setOnlyTotalMismatch] = useState(false);
    const [invoiceDateMismatch, setInvoiceDateMismatch] = useState(false);
    const [noProduct, setNoProduct] = useState(false);
    const [noUnitProduct, setNoUnitProduct] = useState(false);
    const [noQuantity, setNoQuantity] = useState(false);
    const [noItem, setNoItem] = useState(false);
    const [itemPriceMismatch, setItemPriceMismatch] = useState(false);
    const [noPrice, setNoPrice] = useState(false);
    const [noCategory, setNoCategory] = useState(false);
    const [anchor, setAnchor] = useState(null);
    const [showApprovedModel, setShowApprovedModel] = useState(false);
    const [invoice_id, setInvoice_Id] = useState(invoiceIds);
    const [invoiceId, setInvoiceId] = useState(invoiceIds);
    const [openQboSyncAskClient, setOpenQboSyncAskClient] = useState(false);
    const [createObjectURL, setCreateObjectURL] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [counts, setCounts] = useState(0);
    const [imageUrl1, setImageUrl1] = useState('');
    const [value, setValue] = useState(1);
    const [ErrorMessages, setErrorMessage] = useState("");
    const [ownerName, setOwnerName] = useState('');
    const [openAddOwner, setOpenAddOwner] = useState(false);
    const [onIssueClick1, setOnIssueClick1] = useState(onIssueClick);
    const [styles, setStyles] = useState({});
    const [itemType, setItemType] = useState('');
    const [editbleData, setEditbleData] = useState(false);
    const [totalErrorMsg, setTotalErrorMsg] = useState('');
    const [taxErrorMsg, setTaxErrorMsg] = useState('');
    const [checkFound, setCheckFound] = useState(false);
    const [subtotalErrorMsg, setSubtotalErrorMsg] = useState('');
    const [showErrorMoveSnackbar, setShowErrorMoveSnackbar] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [vendorSelect, setVendorSelect] = useState("");
    const [vendorSelect1, setVendorSelect1] = useState("");
    const [vendorname, setVendorname] = useState("");
    const [vendorname1, setVendorname1] = useState("");
    const [vendorname2, setVendorname2] = useState("");
    const [addVendoreerror, setAddVendoreerror] = useState(false);
    const [vendorList, setVendorList] = useState([]);
    const [vendorList1, setVendorList1] = useState([]);
    const [showTrueSnackbar, setShowTrueSnackbar] = useState(false);
    const [showFalseSnackbar, setShowFalseSnackbar] = useState(false);
    const [invoicenumber, setInvoiceNumber] = useState("")
    const [invoicedate, setInvoiceDate] = useState("");
    const [totalItemLength, setTotalItemLength] = useState(0);
    const [transactionDate, setTransactionDate] = useState("")
    const [uploaddate, setUploadDate] = useState("")
    const [alert, setAlert] = useState(false);
    const [ocrStatus, setOcrstatus] = useState(true);
    const [ocrStatus1, setOcrstatus1] = useState('');
    const [alertDeleteInvoice, setAlertDeleteInvoice] = useState(false);
    const [toggleShow, setToggleShow] = useState(false)
    const [openEditItem, setOpenEditItem] = useState(false)
    const [openAddItem, setOpenAddItem] = useState(false)
    const [openCategoryAddItem, setOpenCategoryAddItem] = useState(false);
    const [openEditVendor, setOpenEditVendor] = useState(false);
    const [total, setTotal] = useState(0);
    const [dottedStyle, setDottedStyle] = useState({});
    const [orderTotal, setOrderTotal] = useState(0)
    const [orderTotalStore, setOrderTotalStore] = useState(0)
    const [tax, setTax] = useState(0)
    const [rowModesModel, setRowModesModel] = useState({});
    const [discount, setDiscount] = useState(0);
    const [shippingprice, setShippingprice] = useState(0);
    const [onChanges, SetonChanges] = useState(false);
    const [discountStore, setDiscountStore] = useState(0);
    const [shippingpriceStore, setShippingpriceStore] = useState(0);
    const [firstTime, setFirstTime] = useState(true);
    const [ponumber, setPoNumber] = useState("");
    const [documentType, setDocumentType] = useState("");
    const [subtotal, setSubTotal] = useState(0);
    const [itemList, setItemList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingCircul, setLoadingCircul] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [currencySymbol, setCurrencySymbol] = useState('$');
    const [btnDisable, setBtnDisable] = useState(false);
    const [btnDisable1, setBtnDisable1] = useState(false);
    const [auditLogList, setAuditLogList] = useState([]);
    const [commentsList, setCommentsList] = useState([]);
    const [submitcomment, setsubmitcomment] = useState(false);
    const [itemId, setItemId] = useState([]);
    const [oneItemPopup, setOneItemPopup] = useState(false);
    const [unitPrice, setUnitPrice] = useState('');
    const [showselectedVendorId, setShowselectedVendorId] = useState("");
    const [editItemId, setEditItemId] = useState([]);
    const [IssueId, setIssueId] = useState('');
    const [showDetailsForm, setShowDetailsForm] = useState(false);
    const [editable, SetEditable] = useState(false);
    const [taxStore, setTaxStore] = useState(0);
    const [actualSubtotal, setActualSubtotal] = useState(0);
    const [actualTotal, setActualTotal] = useState(0);
    const [matchId, setMatchId] = useState('');
    const [subTotStore, setSubTotStore] = useState(0);
    const [issues, setIssues] = useState([])
    const [TotalStore, setTotalStore] = useState(total);
    const [resetData, setResetData] = useState(false);
    const [documentSource, setDocumnetSource] = useState('');
    const [isSynced, setIsSynced] = useState(false);
    const [documentStatus, setDocumentStatus] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [openQboSync, setOpenQboSync] = useState(false);
    const [extention, setExtention] = useState('')
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [itemListResponse, SetItemListResponse] = useState([]);
    const [vendorerrormsg, setVendorerrormsg] = useState('');
    const [vendorerror, setVendorerror] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [checked, setChecked] = useState(newObj);
    const [pushPopFun, setPushPopFun] = useState(false);
    const [categoryListData, setCategoryListData] = useState([]);
    const [classesList, setClassesList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [productIds, setProductId] = useState('');
    const [ownerAssigned, setOwnerAssigned] = useState(false);
    const [assignNewClient, setAssignNewClient] = useState(false);
    const [tabsMove, setTabsMove] = useState('');
    const [openAddNewProduct, setOpenAddNewProduct] = useState(false);
    const [openAddNewClass, setOpenAddNewClass] = useState(false);
    const [addProductSelectType, setAddProductSelectType] = useState('');
    const [showButton, setShowButton] = useState(false);
    const [show, setShow] = useState(false);
    const [openAddOption, setOpenAddOption] = useState(false);
    const [checkIssue, setCheckIssue] = useState(false);
    const [paginationModel, setPaginationModel] = React.useState({ pageSize: 5, page: 0, });
    const [alertMoveInvoice, setalertMoveInvoice] = useState(false);
    const [finalSelect, setFinalSelect] = useState('');
    const [addNewVendor, setAddNewVendor] = useState(false);
    const [addNewVendorId, setAddNewVendorId] = useState('');
    const [moveTowhere, setmoveTowhere] = useState('');
    const [users, setUsers] = useState([]);
    const [downloadLink, setDownloadLink] = useState('');
    const [openAddNewCategory, setOpenAddNewCategory] = useState(false);
    const [openAddNewCustomer, setOpenAddNewCustomer] = useState(false);
    const [ErrorMessagesVendorError, setErrorMessagesVendorError] = useState('');
    const [Comments, setComments] = useState('');
    const [zoom, setZoom] = useState(1.0);
    var ref = useRef(null);
    const [{ isError: isGetUserError, isLoading: isGetUserLoading, response: GetUserResponse }, GetUsersetAPICall] = useAPICall(GetuserList, { onLoad: false });
    const [{ isLoading: isInvoiceDetailsLoading, isError: isInvoiceDetailsError, response: InvoiceDetailsResponse }, InvoiceDetailsUserAPICall] = useAPICall(GetuserClientList, { onLoad: false });
    const [{ isError, isLoading, response }, setAPICall] = useAPICall(UpdateInvoiceDetails, { onLoad: false });
    const [{ isError: isEditeItemError, isLoading: isEditeItemLoading, response: EditeItemResponse }, setEditItemAPICall] = useAPICall(EditItem, { onLoad: false });
    const [{ isLoading: isDetailsLoading, isError: isDetailsError, response: DetailsResponse }, DetailsUserAPICall] = useAPICall(GetInvoiceDetails, { onLoad: false });
    const [{ isLoading: isAuditLogLoading, isError: isAuditLogError, response: AuditLogResponse }, AuditLogListAPICall] = useAPICall(GetAuditLogList, { onLoad: false });
    const [{ isLoading: isCommentsLoading, isError: isCommentsError, response: CommentsResponse }, CommentsListAPICall] = useAPICall(GetCommentsList, { onLoad: false });
    const [{ isLoading: isDeleteItemLoading, isError: isDeleteItemError, response: DeleteItemResponse }, DeleteItemListAPICall] = useAPICall(DeleteItem, { onLoad: false });
    const [{ isLoading: isGetItemListLoading, isError: isGetItemListError, response: GetItemListResponse }, GetItemListListAPICall] = useAPICall(GetItemList, { onLoad: false });
    const [{ isLoading: isDeleteInvoiceLoading, isError: isDeleteInvoiceError, response: DeleteInvoiceResponse }, DeleteInvoiceListAPICall] = useAPICall(DeleteInvoice, { onLoad: false });
    const [{ isLoading: isCreateComentsLoading, isError: isCreateComentsError, response: CreateComentsResponse }, CreateComentsListAPICall] = useAPICall(CreateComments, { onLoad: false });
    const [{ isLoading: isMoveMenuLoading, isError: isMoveMenuError, response: MoveMenuResponse }, MoveMenuListAPICall] = useAPICall(MoveMenuInvoice, { onLoad: false });
    const [{ isLoading: isCategoryLoading, isError: isCategoryError, response: CategoryResponse }, CategoryListAPICall] = useAPICall(GetCategoryAllList, { onLoad: false });
    const [{ isLoading: isClassesLoading, isError: isClassesError, response: ClassesResponse }, ClassesListAPICall] = useAPICall(GetClassesAllList, { onLoad: false });
    const [{ isLoading: isCustomerLoading, isError: isCustomerError, response: CustomerResponse }, CustomerListAPICall] = useAPICall(GetCustomerAllList, { onLoad: false });
    const [{ isLoading: isVendorLoading, isError: isVendorError, response: VendorResponse }, VendorListAPICall] = useAPICall(GetVendorForList, { onLoad: false });
    const [{ isLoading: isIssueLoading, isError: isIssueError, response: IssueResponse }, IssueListAPICall] = useAPICall(GetIssueList, { onLoad: false });
    const [{ isLoading: isProductsLoading, isError: isProductsError, response: ProductsResponse }, ProductsListAPICall] = useAPICall(GetProductsAllList, { onLoad: false });
    const [{ response: clientDetailsDataResponse }, getClientDetailsAPICall] = useAPICall(GetuserClientDetails, { onLoad: false });
    const [showClasses, SetShowClasses] = useState(true);
    const action = { COMPLETED: "Completed", Qbotooltip: "QBO", INPROGRESS: "In Progress", PENDING: "Pendding", ERROR: "Error", Movetooltip: "Move", showtooltip: "View", edittooltip: "Edit", deletetooltip: "Delete" };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '0',
        boxShadow: 24,
        outline: 'none',
        p: 4,
    };

    useEffect(async () => {
        await GetUsersetAPICall({
            payload: [{ "keyword": `` }]
        })
    }, []);
    useEffect(() => {
        if (isGetUserLoading) {
            return () => null;
        }
        if (isGetUserError) {
            setLoadingCircul(false);
            setLoading(false);
            return () => null;
        }
        if (GetUserResponse && GetUserResponse.data.isSuccess === true) {
            const data = GetUserResponse?.data?.data?.records.map((prop, key) => {
                return {
                    id: prop?._id,
                    display: prop?.fname,
                };
            }
            );
            setUsers(data);
            setLoadingCircul(false);
            setLoading(false);
        }
    }, [GetUserResponse]);

    const [{ isLoading: isAddVendorLoading, isError: isAddVendorError, response: AddVendorResponse }, AddVendorListAPICall] = useAPICall(AddVendors, { onLoad: false });

    useEffect(() => {
        if (isAddVendorLoading) {
            return () => null;
        }
        if (isAddVendorError) {
            setErrorMessage(AddVendorResponse?.data?.message);
            setLoading(false);
            setBtnDisable(false);
            setShowErrorSnackbar(true);
            setTimeout(() => {
                setShowErrorSnackbar(false);
            }, 3000);
            return () => null;
        }

        if (AddVendorResponse && AddVendorResponse.data.isSuccess === true) {
            setAddNewVendorId(AddVendorResponse?.data?.data?.vendor?._id);
            // setVendorname2()
            setOpenEditVendor(false);
            setLoading(true);
            setAddNewVendor(true);
            if (AddVendorResponse?.data?.data?.vendor?._id) {
                VendorListAPICall({
                    payload: [{ "businessId": `${businessId}`, IsSynced: true }]
                });
            }

        }
    }, [AddVendorResponse]);


    const [cellModesModel, setCellModesModel] = React.useState({});

    const handleCellClick = React.useCallback((params, event) => {
        if (!params.isEditable) {
            return;
        }

        // Ignore portal
        if (!event.currentTarget.contains(event.target)) {
            return;
        }

        setCellModesModel((prevModel) => {
            return {
                // Revert the mode of the other cells from other rows
                ...Object.keys(prevModel).reduce(
                    (acc, id) => ({
                        ...acc,
                        [id]: Object.keys(prevModel[id]).reduce(
                            (acc2, field) => ({
                                ...acc2,
                                [field]: { mode: GridCellModes.View },
                            }),
                            {},
                        ),
                    }),
                    {},
                ),
                [params.id]: {
                    // Revert the mode of other cells in the same row
                    ...Object.keys(prevModel[params.id] || {}).reduce(
                        (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
                        {},
                    ),
                    [params.field]: { mode: GridCellModes.Edit },
                },
            };
        });
    }, []);

    const handleCellModesModelChange = React.useCallback((newModel) => {
        setCellModesModel(newModel);
    }, []);

    var resource = '';
    var actions = '';
    const [anchorEl, setAnchorEl] = React.useState(null);
    const AddobjClass = { name: '+ Add Class', classId: '' }
    const NewclassesList = [AddobjClass, ...classesList]
    const AddobjCustomer = { name: '+ Add Customer', customerId: '' }
    const NewcustomerList = [AddobjCustomer, ...customerList]
    const Addvendor = { name: '+ Add Vendor', vendorId: '' }
    const NewVendorList = [Addvendor, ...vendorList]
    const AddProduct = { name: '+ Add Product' }
    const NewProductList = [AddProduct, ...productsList]
    const AddCategory = { name: '+ Add Category' }
    const NewCategoryList = [AddCategory, ...categoryListData]
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function splitCamelCaseToString(s) {
        return s.split(/(?=[A-Z])/).join(' ');
    }
    useEffect(() => {
        IssueListAPICall({
            payload: [{ "businessId": `${businessId}`, "InvoiceId": `${invoice_id}` }]
        });
    }, [response]);

    useEffect(() => {
        if (isIssueLoading) {
            return () => null;
        }
        if (isIssueError) {
            setLoading(false);
            return () => null;
        }
        if (IssueResponse && IssueResponse.data.isSuccess === true) {
            GetItemListListAPICall({
                payload: [{ "InvoiceId": `${invoiceId}` }]
            })
            setTotalIssues(IssueResponse?.data?.data?.totalRecords);
            if (IssueResponse?.data?.data?.totalRecords === 0) {
                setNoVendorIssue(false)
                setPriceMismatch(false)
                setTotalMismatch(false)
                setOnlyTotalMismatch(false)
                setInvoiceDateMismatch(false)
                setNoProduct(false)
                setNoCategory(false)
            } else if (editable == false) {
                let results = IssueResponse?.data?.data?.records.filter((e) => e.moduleType !== 'ITEM');
                IssueResponse?.data?.data?.records?.map((prop, key) => {
                    prop?.issueType === 'NOVENDOR' ? setNoVendorIssue(true) || setNoVendorIssueMsg(`${prop?.message}`) : setNoVendorIssue(false);
                    prop?.issueType === 'NOSUBTOTAL' ? setPriceMismatch(true) || setPriceMismatchMsg(`${prop?.message}`) : setPriceMismatch(false);
                    prop?.issueType === 'TOTALMISMATCH' ? setTotalMismatch(true) || setTotalMismatchMsg(`${prop?.message}`) : setTotalMismatch(false);
                    prop?.issueType === 'PRICEMISMATCH' ? setPriceMismatch(true) || setPriceMismatchMsg(`${prop?.message}`) : setPriceMismatch(false);
                    prop?.issueType === 'NOTOTAL' ? setOnlyTotalMismatch(true) : setOnlyTotalMismatch(false);
                    prop?.issueType === 'NOINVOICE_DATE' ? setInvoiceDateMismatch(true) : setInvoiceDateMismatch(false);
                    prop?.issueType == 'NOPRODUCT' ? setNoProduct(true) : setNoProduct(false);
                    prop?.issueType === 'NOCATEGORY' ? setNoCategory(true) : setNoCategory(false);
                })
            }
        }
    }, [IssueResponse]);


    useEffect(() => {
        IssueResponse?.data?.data?.records.map((prop, key) => {
            prop?.issueType === 'NOPRODUCT' ? setNoProduct(true) : '';
            prop?.issueType === 'NOPRICE' ? setNoPrice(true) : '';
            prop?.issueType === 'ITEMPRICEMISMATCH' ? setNoPrice(true) : '';
            prop?.issueType === 'NOUNITPRICE' ? setNoUnitProduct(true) : '';
            prop?.issueType === 'NOQUANTITY' ? setNoQuantity(true) : '';
            prop?.issueType === 'NOITEMS' ? setNoItem(true) : '';
            prop?.issueType === 'NOCATEGORY' ? setNoCategory(true) : '';
        })
        const found1 = IssueResponse?.data?.data?.records.find(obj => {
            return obj._id === selectedIndex;
        });
        if (onIssueClick) {
            // if (editable === true) {
            //     setNoVendorIssue(false);
            //     setPriceMismatch(false);
            //     setTotalMismatch(false);
            //     setInvoiceDateMismatch(false);
            //     setOnlyTotalMismatch(false);
            // } else 
            if (found1) {
                found1?.issueType === 'NOVENDOR' ? setNoVendorIssue(true) : '';
                found1?.issueType === 'NOSUBTOTAL' ? setPriceMismatch(true) : '';
                found1?.issueType === 'PRICEMISMATCH' ? setTotalMismatch(true) : '';
                found1?.issueType === 'TOTALMISMATCH' ? setPriceMismatch(true) : '';
                found1?.issueType === 'NOTOTAL' ? setOnlyTotalMismatch(true) : '';
                found1?.issueType === 'NOINVOICE_DATE' ? setInvoiceDateMismatch(true) : '';
                found1?.issueType === 'NOPRODUCT' ? setNoProduct(true) : '';
                found1?.issueType === 'NOCATEGORY' ? setNoCategory(true) : '';
                setShow(true);
            }
            else {
                setNoVendorIssue(false);
                setPriceMismatch(false);
                setTotalMismatch(false);
                setInvoiceDateMismatch(false);
                setOnlyTotalMismatch(false);
            }
        } else {
            // if (editable === true) {
            //     setNoVendorIssue(false);
            //     setPriceMismatch(false);
            //     setTotalMismatch(false);
            //     setInvoiceDateMismatch(false);
            //     setOnlyTotalMismatch(false);
            // } else {
            let results = IssueResponse?.data?.data?.records.filter((e) => e.moduleType === 'DOCUMENT');
            results?.map((prop, key) => {
                prop?.issueType === 'NOVENDOR' ? setNoVendorIssue(true) : '';
                prop?.issueType === 'NOSUBTOTAL' ? setPriceMismatch(true) : '';
                prop?.issueType === 'TOTALMISMATCH' ? setTotalMismatch(true) : '';
                prop?.issueType === 'PRICEMISMATCH' ? setPriceMismatch(true) : '';
                prop?.issueType === 'NOTOTAL' ? setOnlyTotalMismatch(true) : '';
                prop?.issueType === 'NOINVOICE_DATE' ? setInvoiceDateMismatch(true) : '';
                prop?.issueType == 'NOPRODUCT' ? setNoProduct(true) : '';
                prop?.issueType === 'NOCATEGORY' ? setNoCategory(true) : '';
            })
            setShow(true);
            // }
        }
    }, [IssueResponse, editable, itemList])


    useEffect(() => {
        IssueListAPICall({
            payload: [{ "businessId": `${businessId}`, "InvoiceId": `${invoice_id}` }]
        });
        getClientDetailsAPICall({
            payload: [{ "bussinessId": `${businessId}` }]
        });
        GetItemListListAPICall({
            payload: [{ "InvoiceId": `${invoiceId}` }]
        })
    }, []);

    useEffect(() => {
        if (clientDetailsDataResponse && clientDetailsDataResponse.data.isSuccess === true) {
            SetShowClasses(clientDetailsDataResponse?.data?.data?.client?.preferences?.AccountingInfoPrefs?.ClassTrackingPerTxnLine);
        }
    }, [clientDetailsDataResponse]);

    useEffect(() => {
        if (DetailsResponse && GetItemListResponse) {
            const data = GetItemListResponse?.data?.data?.records.map((prop, key) => {
                return {
                    item: prop?.description ? prop?.description : '---',
                    qty: prop?.quantity ? prop?.quantity : '---',
                    unit: prop?.unitOfMeasure ? prop?.unitOfMeasure : '---',
                    product: prop?.product?.name ? prop?.product?.name : '---',
                    productCheck: prop?.product?.name ? prop?.product?.name : '---',
                    price: prop?.unitPrice ? prop?.unitPrice : '0',
                    total: prop?.amount ? prop?.amount : '---',
                    customer: prop?.customer?.name ? prop?.customer?.name : '---',
                    category: prop?.category?.name ? prop?.category?.name : '---',
                    class: prop?.class?.name ? prop?.class?.name : '---',
                    id: prop._id,
                    sku: prop.sku,
                    itemType: prop.itemType,
                    CheckAskClient: prop?.askClient,
                };
            });
            setLoadingCircul(false);
            setLoading(false);
            if (DetailsResponse?.data?.data?.document?.itemType === 'ItemBasedExpenseLineDetail') {
                const foundProducts = data?.find(obj => {
                    return obj.product == '---';
                });
                if (foundProducts === undefined) {
                    setShowButton(true);
                } else {
                    setShowButton(false);
                }
            } else if (DetailsResponse?.data?.data?.document?.itemType === 'AccountBasedExpenseLineDetail') {
                const foundCategory = data?.find(obj => {
                    return obj.category == '---';
                });
                if (foundCategory === undefined) {
                    setShowButton(true);
                } else {
                    setShowButton(false);
                }
            }
        }

    }, [GetItemListResponse, DetailsResponse])


    useEffect(() => {
        if (isInvoiceDetailsLoading) {
            return () => null;
        }
        if (isInvoiceDetailsError) {
            setLoading(false);
            setLoadingCircul(false);
            return () => null;
        }
        if (InvoiceDetailsResponse && InvoiceDetailsResponse.data.isSuccess === true) {
            const found2 = InvoiceDetailsResponse?.data?.data?.records.find(obj => {
                return obj._id === businessId;
            });
            setOwnerAssigned(found2?.ownerAssigned);
            setLoading(true);
            setLoadingCircul(false);
        }
    }, [InvoiceDetailsResponse]);


    useEffect(() => {
        if (isMoveMenuLoading) {
            return () => null;
        }
        if (isMoveMenuError) {
            setLoading(false);
            setalertMoveInvoice(false);
            return () => null;
        }
        if (MoveMenuResponse && MoveMenuResponse.data.isSuccess === true) {
            setLoading(true);
            setalertMoveInvoice(false);
            const index = storeResponce?.data?.data?.records.findIndex(x => x._id === invoiceId);
            const LastItem = storeResponce?.data?.data?.records.slice(-1)[0];
            const newInvoiceId = LastItem._id === invoiceId ? storeResponce?.data?.data?.records[0]._id : storeResponce?.data?.data?.records.at(index + 1)._id;
            storeResponce?.data?.data?.totalRecords - 1 === 0 ? onClose() : onClose1()
            setInvoice_Id(newInvoiceId);
            setInvoiceId(newInvoiceId);
            openQboSyncAskClient ? onClose() : onClose1()
            setLoading(true);
            GetItemListListAPICall({
                payload: [{ "InvoiceId": `${newInvoiceId}` }]
            })
            DetailsUserAPICall({
                payload: [{ "InvoiceId": `${newInvoiceId}`, "businessId": `${businessId}` }]
            })
            VendorListAPICall({
                payload: [{ "businessId": `${businessId}`, IsSynced: true }]
            });
            InvoiceDetailsUserAPICall({
                payload: [{ "businessId": `${businessId}` }]
            });
        }
    }, [MoveMenuResponse]);

    useEffect(async () => {
        await ClassesListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
        await CategoryListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
        await CustomerListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
        await ProductsListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
    }, [])

    const successInoiceMove = async () => {
        setLoading(true);
        MoveMenuListAPICall({
            payload: [
                {
                    "documentIds": {
                        "documentIds": [invoiceId],
                        "documentStatus": openQboSyncAskClient ? moveTowhere : tabsMove,
                    },
                    "businessId": `${businessId}`,
                }
            ]
        })
    }

    const successAssignNewClient = (move) => {
        setOpenAddOwner(true);
        setAssignNewClient(false);
        // eventBus.$emit("refreshApiInvoiceList", true);
        // eventBus.$emit("ShowAsignClientPopup", true);
    }

    const setOpenQboSyncAskClients = (move) => {
        setmoveTowhere(move);
        setOpenQboSyncAskClient(true);
    }

    useEffect(() => {
        if (isProductsLoading) {
            return () => null;
        }
        if (isProductsError) {
            setLoading(false);
            setLoadingCircul(false);
            return () => null;
        }
        if (ProductsResponse && ProductsResponse.data.isSuccess === true) {
            const data = ProductsResponse.data.data.records.map((prop, key) => {
                setUnitPrice(prop?.unitPrice)
                return {
                    name: prop.name,
                    productId: prop._id,
                    accountingId: prop.accountingId,
                    description: prop.description,
                    unitPrice: prop.unitPrice,
                    sku: prop.sku,
                    unitOfMeasure: prop.unitOfMeasure,
                };
            }
            );
            setProductsList(data);
            setLoadingCircul(false);
            setLoading(false);
        }
    }, [ProductsResponse]);


    useEffect(() => {
        if (isCustomerLoading) {
            return () => null;
        }
        if (isCustomerError) {
            setLoadingCircul(false);
            setLoading(false);
            return () => null;
        }
        if (CustomerResponse && CustomerResponse.data.isSuccess === true) {
            const data = CustomerResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop?.name,
                    customerId: prop?._id,
                    productId: prop?._id,
                    description: prop?.description,
                    unitPrice: prop?.unitPrice,
                    sku: prop?.sku,
                    unitOfMeasure: prop?.unitOfMeasure,
                };
            }
            );
            setCustomerList(data);
            setLoadingCircul(false);
            setLoading(false);
        }
    }, [CustomerResponse]);

    useEffect(() => {
        if (isCategoryLoading) {
            return () => null;
        }
        if (isCategoryError) {
            setLoading(false);
            setLoadingCircul(false);
            return () => null;
        }
        if (CategoryResponse && CategoryResponse.data.isSuccess === true) {
            setLoading(false);
            setLoadingCircul(false);
            const data = CategoryResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    categoryId: prop._id
                };
            }
            );
            setCategoryListData(data);
        }
    }, [CategoryResponse]);

    function SelectCategoryEditInputCell(props) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleCategoryChanges = async (event) => {
            if (event.target.textContent == '+ Add Category') {
                // setLoadingCircul(true);
                setOpenAddNewCategory(true);
            } else {
                setLoadingCircul(true);
                await apiRef.current.setEditCellValue({ id, field, value: event.target.textContent });
                apiRef.current.stopCellEditMode({ id, field });
                const result = itemListResponse?.data?.data?.records.map((prop, key) => {
                    {
                        return {
                            id: prop._id,
                            item: prop.description,
                            qty: prop.quantity,
                            unit: prop.unitOfMeasure,
                            price: prop.unitPrice,
                            total: prop.total,
                            sku: prop.sku,
                            amount: prop?.amount,
                            unitPrice: prop.unitPrice,
                            results: prop.results,
                            askClients: prop.askClient,
                            category: prop.category ? prop.category.name : '',
                            categoryId: prop.category ? prop.category._id : '',
                            class: prop.class ? prop.class.name : '',
                            product: prop.product ? prop.product.name : '',
                            productId: prop.product ? prop.product._id : '',
                            classId: prop.class ? prop.class._id : '',
                            customer: prop.customer ? prop.customer.name : '',
                            customerId: prop.customer ? prop.customer._id : '',
                        }
                    }
                });
                const found = categoryListData.find(obj => {
                    return obj.name === event.target.textContent;
                });
                const results = result.find(item => item.id === id);
                var Str = results?.amount
                var newStr = Str ? Str.replace(/,/g, '') : 0;
                var Str1 = results?.unitPrice ? results?.unitPrice : results?.price
                var newStr1 = Str1 ? Str1.replace(/,/g, '') : 0;
                const Qty = results?.quantity ? results?.quantity : results?.qty;
                if (results) {
                    setProductId(results.productId);
                    toggleShow ?
                        setEditItemAPICall({
                            payload: [{
                                "itemType": 'ItemBasedExpenseLineDetail',
                                "unitPrice": Number(newStr1 ? newStr1 === 0 ? newStr : newStr1 : newStr),
                                "amount": Number(newStr ? newStr === 0 ? newStr1 : newStr : newStr1),
                                "discount": 0,
                                "sku": results?.sku,
                                "description": results?.item,
                                "unitOfMeasure": results?.unit ? results?.unit : null,
                                "productId": results?.productId ? results?.productId : null,
                                "InvoiceId": invoiceId ? invoiceId : documentsId,
                                "classId": results?.classId ? results?.classId : null,
                                "customerId": results?.customerId ? results?.customerId : null,
                                "quantity": Number(Qty ? Qty : 1),
                                "askClient": results.askClients,
                                "ItemId": results.id,
                            }]
                        }) :
                        setEditItemAPICall({
                            payload: [{
                                "itemType": 'AccountBasedExpenseLineDetail',
                                "unitPrice": Number(newStr1 ? newStr1 === 0 ? newStr : newStr1 : newStr),
                                "amount": Number(newStr ? newStr === 0 ? newStr1 : newStr : newStr1),
                                "unitOfMeasure": results?.unit ? results?.unit : null,
                                "quantity": Number(Qty ? Qty : 1),
                                "discount": 0,
                                "description": results?.item,
                                "InvoiceId": invoiceId ? invoiceId : documentsId,
                                "classId": results?.classId ? results?.classId : null,
                                "customerId": results?.customerId ? results?.customerId : null,
                                "categoryId": found?.categoryId,
                                'askClient': results.askClients,
                                "ItemId": results.id,
                            }]
                        })
                }
            }
        };

        return (
            <>
                <Autocomplete
                    id="combo-box-demo"
                    classes={{
                        option: styles.option
                    }}
                    fullWidth
                    open={true}
                    value={{ name: value }}
                    options={finalSelect == 'Ask Client' || finalSelect == 'Classify' || documentStatus == 'AskClient' || documentStatus == 'Classify' ? NewCategoryList : categoryListData}
                    getOptionLabel={(option) => option.name}
                    disableClearable
                    ListboxProps={{
                        sx: {
                            fontSize: `0.8125rem`,
                            '@media (max-width: 1440px)': {
                                fontSize: `0.775rem`
                            },
                        },
                    }}
                    sx={{
                        '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                            fontSize: `0.8125rem`,
                            '@media (max-width: 1440px)': {

                                fontSize: `0.775rem`
                            },
                        },
                    }}
                    onChange={handleCategoryChanges}
                    renderInput={(params) => <TextField {...params} />}
                />
            </>
        );
    }

    const renderCategorySelectEditInputCell = (params) => {
        return <SelectCategoryEditInputCell {...params} />;
    };

    function SelectClassEditInputCell(props) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleClassChanges = async (event) => {
            if (event.target.textContent == '+ Add Class') {
                setOpenAddNewClass(true);
            } else {
                await apiRef.current.setEditCellValue({ id, field, value: event.target.textContent });
                apiRef.current.stopCellEditMode({ id, field });
                const result = itemListResponse?.data?.data?.records.map((prop, key) => {
                    {
                        return {
                            id: prop._id,
                            item: prop.description,
                            qty: prop.quantity,
                            unit: prop.unitOfMeasure,
                            price: prop.unitPrice,
                            total: prop.total,
                            sku: prop.sku,
                            amount: prop?.amount,
                            unitPrice: prop.unitPrice,
                            results: prop.results,
                            askClients: prop.askClient,
                            category: prop.category ? prop.category.name : '',
                            categoryId: prop.category ? prop.category._id : '',
                            class: prop.class ? prop.class.name : '',
                            product: prop.product ? prop.product.name : '',
                            productId: prop.product ? prop.product._id : '',
                            classId: prop.class ? prop.class._id : '',
                            customer: prop.customer ? prop.customer.name : '',
                            customerId: prop.customer ? prop.customer._id : '',
                        }
                    }
                });
                const found = classesList.find(obj => {
                    return obj.name === event.target.textContent;
                });
                const results = result.find(item => item.id === id);
                var Str = results?.amount
                var newStr = Str ? Str.replace(/,/g, '') : 0;
                var Str1 = results?.unitPrice ? results?.unitPrice : results?.price
                var newStr1 = Str1 ? Str1.replace(/,/g, '') : 0;
                const Qty = results?.quantity ? results?.quantity : results?.qty;
                if (results) {
                    setProductId(results.productId);
                    toggleShow ?
                        setEditItemAPICall({
                            payload: [{
                                "itemType": 'ItemBasedExpenseLineDetail',
                                "unitPrice": Number(newStr1 ? newStr1 === 0 ? newStr : newStr1 : newStr),
                                "amount": Number(newStr ? newStr === 0 ? newStr1 : newStr : newStr1),
                                "discount": 0,
                                "sku": results?.sku,
                                "description": results?.item,
                                "unitOfMeasure": results?.unit ? results?.unit : null,
                                "productId": results?.productId ? results?.productId : null,
                                "InvoiceId": invoiceId ? invoiceId : documentsId,
                                "classId": found?.classId,
                                "customerId": results?.customerId ? results?.customerId : null,
                                "quantity": Number(Qty ? Qty : 1),
                                "askClient": results.askClients,
                                "ItemId": results.id,
                            }]
                        }) :
                        setEditItemAPICall({
                            payload: [{
                                "itemType": 'AccountBasedExpenseLineDetail',
                                "unitPrice": Number(newStr1 ? newStr1 === 0 ? newStr : newStr1 : newStr),
                                "amount": Number(newStr ? newStr === 0 ? newStr1 : newStr : newStr1),
                                "unitOfMeasure": results?.unit ? results?.unit : null,
                                "quantity": Number(Qty ? Qty : 1),
                                "discount": 0,
                                "description": results?.item,
                                "InvoiceId": invoiceId ? invoiceId : documentsId,
                                "classId": found?.classId,
                                "customerId": results?.customerId ? results?.customerId : null,
                                "categoryId": results?.categoryId ? results?.categoryId : null,
                                'askClient': results.askClients,
                                "ItemId": results.id,
                            }]
                        })
                }
            }
        };

        return (
            <Autocomplete
                id="combo-box-demo"
                fullWidth
                open={true}
                value={{ name: value }}
                classes={{
                    option: styles.option
                }}
                options={finalSelect == 'Ask Client' || finalSelect == 'Classify' || documentStatus == 'AskClient' || documentStatus == 'Classify' ? NewclassesList : classesList}
                getOptionLabel={(option) => option.name}
                disableClearable
                ListboxProps={{
                    sx: {
                        fontSize: `0.8125rem`,
                        '@media (max-width: 1440px)': {

                            fontSize: `0.775rem`
                        },
                    },
                }}
                sx={{
                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                        fontSize: `0.8125rem`,
                        '@media (max-width: 1440px)': {

                            fontSize: `0.775rem`
                        },
                    },
                }}
                onChange={handleClassChanges}
                renderInput={(params) => <TextField {...params} />}
            />
        );
    }

    const renderClassSelectEditInputCell = (params) => {
        return <SelectClassEditInputCell {...params} />;
    };

    function SelectCustomerEditInputCell(props) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleCustomerChanges = async (event) => {
            if (event.target.textContent == '+ Add Customer') {
                setOpenAddNewCustomer(true);
            } else {
                await apiRef.current.setEditCellValue({ id, field, value: event.target.textContent });
                apiRef.current.stopCellEditMode({ id, field });
                const result = itemListResponse?.data?.data?.records.map((prop, key) => {
                    {
                        return {
                            id: prop._id,
                            item: prop.description,
                            qty: prop.quantity,
                            unit: prop.unitOfMeasure,
                            price: prop.unitPrice,
                            total: prop.total,
                            sku: prop.sku,
                            amount: prop?.amount,
                            unitPrice: prop.unitPrice,
                            askClients: prop.askClient,
                            category: prop.category ? prop.category.name : '',
                            categoryId: prop.category ? prop.category._id : '',
                            class: prop.class ? prop.class.name : '',
                            product: prop.product ? prop.product.name : '',
                            productId: prop.product ? prop.product._id : '',
                            classId: prop.class ? prop.class._id : '',
                            customer: prop.customer ? prop.customer.name : '',
                            customerId: prop.customer ? prop.customer._id : '',
                        }
                    }
                });
                const found = customerList.find(obj => {
                    return obj.name === event.target.textContent;
                });
                const results = result.find(item => item.id === id);
                var Str = results?.amount
                var newStr = Str ? Str.replace(/,/g, '') : 0;
                var Str1 = results?.unitPrice ? results?.unitPrice : results?.price
                var newStr1 = Str1 ? Str1.replace(/,/g, '') : 0;
                const Qty = results?.quantity ? results?.quantity : results?.qty;
                if (results) {
                    setProductId(results.productId);
                    toggleShow ?
                        setEditItemAPICall({
                            payload: [{
                                "itemType": 'ItemBasedExpenseLineDetail',
                                "unitPrice": Number(newStr1 ? newStr1 === 0 ? newStr : newStr1 : newStr),
                                "amount": Number(newStr ? newStr === 0 ? newStr1 : newStr : newStr1),
                                "discount": 0,
                                "sku": results?.sku,
                                "description": results?.item,
                                "unitOfMeasure": results?.unit ? results?.unit : null,
                                "productId": results?.productId ? results?.productId : null,
                                "InvoiceId": invoiceId ? invoiceId : documentsId,
                                "classId": results?.classId ? results?.classId : null,
                                "customerId": found?.customerId,
                                "quantity": Number(Qty ? Qty : 1),
                                "askClient": results.askClients,
                                "ItemId": results.id,
                            }]
                        }) :
                        setEditItemAPICall({
                            payload: [{
                                "itemType": 'AccountBasedExpenseLineDetail',
                                "unitPrice": Number(newStr1 ? newStr1 === 0 ? newStr : newStr1 : newStr),
                                "amount": Number(newStr ? newStr === 0 ? newStr1 : newStr : newStr1),
                                "unitOfMeasure": results?.unit ? results?.unit : null,
                                "quantity": Number(Qty ? Qty : 1),
                                "discount": 0,
                                "description": results?.item,
                                "InvoiceId": invoiceId ? invoiceId : documentsId,
                                "classId": results?.classId ? results?.classId : null,
                                "customerId": found?.customerId,
                                "categoryId": results?.categoryId ? results?.categoryId : null,
                                'askClient': results.askClients,
                                "ItemId": results.id,
                            }]
                        })
                    // setDescription(found.description);
                    // setUnitPrice(found.unitPrice)
                }
            }
        };

        return (
            <Autocomplete
                id="combo-box-demo"
                fullWidth
                open={true}
                editable={true}
                classes={{
                    option: styles.option
                }}
                value={{ name: value }}
                options={finalSelect == 'Ask Client' || finalSelect == 'Classify' || documentStatus == 'AskClient' || documentStatus == 'Classify' ? NewcustomerList : customerList}
                getOptionLabel={(option) => option.name}
                disableClearable
                ListboxProps={{
                    sx: {
                        fontSize: `0.8125rem`,
                        '@media (max-width: 1440px)': {

                            fontSize: `0.775rem`
                        },
                    },
                }}
                sx={{
                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                        fontSize: `0.8125rem`,
                        '@media (max-width: 1440px)': {

                            fontSize: `0.775rem`
                        },
                    },
                }}
                onChange={handleCustomerChanges}
                renderInput={(params) => <TextField {...params} />}
            />
        );
    }

    const renderCustomerSelectEditInputCell = (params) => {
        return <SelectCustomerEditInputCell {...params} />;
    };
    function getPrice(params) {
        return `$${params?.row?.total}`;
    }
    function getPriceProduct(params) {
        const finalPrice = params?.row?.itemType === "AccountBasedExpenseLineDetail" ? params?.row?.price : params?.row?.price;
        return `$${finalPrice}`;
    }
    function getTotalProduct(params) {
        return `$${params?.row?.total}`;
    }
    function getQuantity(params) {
        const finalQty = params?.row?.itemType === "AccountBasedExpenseLineDetail" ? params?.row?.qty : params?.row?.qty;
        return finalQty;
    }
    function SelectProductEditInputCell(props) {
        const { id, value, field } = props;
        const apiRef = useGridApiContext();

        const handleProductChanges = async (event) => {
            if (event.target.textContent == '+ Add Product') {
                setOpenAddOption(true);
            } else {
                setLoadingCircul(true);
                await apiRef.current.setEditCellValue({ id, field, value: event.target.textContent });
                apiRef.current.stopCellEditMode({ id, field });
                const result = itemListResponse?.data?.data?.records.map((prop, key) => {
                    {
                        return {
                            id: prop._id,
                            item: prop.description,
                            qty: prop.quantity,
                            unit: prop.unitOfMeasure,
                            price: prop.unitPrice,
                            total: prop.total,
                            sku: prop.sku,
                            amount: prop?.amount,
                            unitPrice: prop?.unitPrice,
                            results: prop.results,
                            askClients: prop.askClient,
                            category: prop.category ? prop.category.name : '',
                            categoryId: prop.category ? prop.category._id : '',
                            class: prop.class ? prop.class.name : '',
                            product: prop.product ? prop.product.name : '',
                            productId: prop.product ? prop.product._id : '',
                            classId: prop.class ? prop.class._id : '',
                            customer: prop.customer ? prop.customer.name : '',
                            customerId: prop.customer ? prop.customer._id : '',
                        }
                    }
                });
                const found = productsList.find(obj => {
                    return obj.name === event.target.textContent;
                });
                const results = result.find(item => item.id === id);
                var Str = results?.amount
                var newStr = Str ? Str.replace(/,/g, '') : 0;
                var Str1 = results?.unitPrice ? results?.unitPrice : results?.price
                var newStr1 = Str1 ? Str1.replace(/,/g, '') : 0;
                const Qty = results?.quantity ? results?.quantity : results?.qty;
                if (results) {
                    setProductId(results.productId);
                    toggleShow ?
                        setEditItemAPICall({
                            payload: [{
                                "itemType": 'ItemBasedExpenseLineDetail',
                                "unitPrice": Number(newStr1 ? newStr1 === 0 ? newStr : newStr1 : newStr),
                                "amount": Number(newStr ? newStr === 0 ? newStr1 : newStr : newStr1),
                                "discount": 0,
                                "sku": results?.sku,
                                "description": results?.item,
                                "unitOfMeasure": results?.unit ? results?.unit : results?.unit,
                                "productId": found?.productId,
                                "InvoiceId": invoiceId ? invoiceId : documentsId,
                                "classId": results?.classId ? results?.classId : null,
                                "customerId": results?.customerId ? results?.customerId : null,
                                "quantity": Number(Qty ? Qty : 1),
                                "askClient": results.askClients,
                                "ItemId": results.id,
                            }]
                        }) :
                        setEditItemAPICall({
                            payload: [{
                                "itemType": 'AccountBasedExpenseLineDetail',
                                "unitPrice": Number(newStr1 ? newStr1 === 0 ? newStr : newStr1 : newStr),
                                "amount": Number(newStr ? newStr === 0 ? newStr1 : newStr : newStr1),
                                "unitOfMeasure": results?.unit ? results?.unit : null,
                                "quantity": Number(Qty ? Qty : 1),
                                "discount": 0,
                                "description": results?.item,
                                "InvoiceId": invoiceId ? invoiceId : documentsId,
                                "classId": results?.classId ? results?.classId : null,
                                "customerId": results?.customerId ? results?.customerId : null,
                                "categoryId": results?.categoryId ? results?.categoryId : null,
                                'askClient': results.askClients,
                                "ItemId": results.id,
                            }]
                        })
                    // setDescription(found.description);
                    // setUnitPrice(found.unitPrice)
                }
            }
        };

        return (
            <Autocomplete
                id="combo-box-demo"
                fullWidth
                open={true}
                value={{ name: value }}
                options={finalSelect == 'Ask Client' || finalSelect == 'Classify' || documentStatus == 'AskClient' || documentStatus == 'Classify' ? NewProductList : productsList}
                classes={{
                    option: styles.option
                }}
                getOptionLabel={(option) => option.name}
                disableClearable
                ListboxProps={{
                    sx: {
                        fontSize: `0.8125rem`,
                        '@media (max-width: 1440px)': {

                            fontSize: `0.775rem`
                        },
                    },
                }}
                sx={{
                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                        fontSize: `0.8125rem`,
                        '@media (max-width: 1440px)': {

                            fontSize: `0.775rem`
                        },
                    },
                }}
                onChange={handleProductChanges}
                renderInput={(params) => <TextField {...params} />}
            />
        );
    }

    const renderProductSelectEditInputCell = (params) => {
        return <SelectProductEditInputCell {...params} />;
    };

    useEffect(() => {
        if (isClassesLoading) {
            return () => null;
        }
        if (isClassesError) {
            setLoading(false);
            return () => null;
        }
        if (ClassesResponse && ClassesResponse.data.isSuccess === true) {
            const data = ClassesResponse.data.data.records.map((prop, key) => {
                return {
                    name: prop.name,
                    classId: prop._id
                };
            }
            );
            setClassesList(data);
            setLoading(false);
        }
    }, [ClassesResponse]);

    useEffect(async () => {
        if (isEditeItemLoading) {
            return () => null;
        }
        if (isEditeItemError) {
            setLoading(false);
            GetItemListListAPICall({
                payload: [{ "InvoiceId": `${invoiceId}` }]
            })
            if (EditeItemResponse?.data?.message) {
                setErrorMessage(EditeItemResponse.data.message)
            }
            setShowErrorMoveSnackbar(true);
            setTimeout(() => {
                setShowErrorMoveSnackbar(false);
            }, 4000);
            return () => null;
        }
        if (EditeItemResponse && EditeItemResponse.data.isSuccess === true) {
            eventBus.$emit("refreshListAfterUpdate", response);
            IssueListAPICall({
                payload: [{ "businessId": `${businessId}`, "InvoiceId": `${invoice_id}` }]
            })
            setTimeout(() => {
                GetItemListListAPICall({
                    payload: [{ "InvoiceId": `${invoice_id}` }]
                })
                DetailsUserAPICall({
                    payload: [{ "InvoiceId": `${invoice_id}`, "businessId": `${businessId}` }]
                })
            }, 2000);
        }
    }, [EditeItemResponse]);

    useEffect(() => {
        setLoading(true);
        GetItemListListAPICall({
            payload: [{ "InvoiceId": `${invoice_id}` }]
        })
        DetailsUserAPICall({
            payload: [{ "InvoiceId": `${invoice_id}`, "businessId": `${businessId}` }]
        })
        VendorListAPICall({
            payload: [{ "businessId": `${businessId}`, IsSynced: true }]
        });
        InvoiceDetailsUserAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
    }, [])

    const handleOpenAddNewProduct = (data) => {
        setOpenAddNewProduct(true);
        setAddProductSelectType(data);
        setOpenAddOption(false);
    }

    useEffect(() => {
        if (isCreateComentsLoading) {
            return () => null;
        }
        if (isCreateComentsError) {
            setLoading(false);
            setBtnDisable1(false);
            return () => null;
        }
        if (CreateComentsResponse && CreateComentsResponse.data.isSuccess === true) {
            // setLoading(false);
            setsubmitcomment(false);
            setBtnDisable1(false);
            setTextAreaVal('');
            CommentsListAPICall({
                payload: [{ "InvoiceId": `${invoice_id}`, "businessId": `${businessId}` }]
            })
        }
    }, [CreateComentsResponse]);

    useEffect(() => {
        if (isDeleteInvoiceLoading) {
            return () => null;
        }
        if (isDeleteInvoiceError) {
            setLoading(false);
            return () => null;
        }
        if (DeleteInvoiceResponse && DeleteInvoiceResponse.data.isSuccess === true) {
            eventBus.$emit("closeDeleteItemPopup", DeleteInvoiceResponse);
            setLoading(true);
        }
    }, [DeleteInvoiceResponse]);

    const toggleCheck = async (clientId) => {
        setMatchId(clientId);
        setChecked((prevState) => {
            const newState = { ...prevState };
            newState[clientId] = !prevState[clientId];
            return newState;
        });
        const finalListResponec = GetItemListResponse ? GetItemListResponse : itemListResponse;
        const result = finalListResponec?.data?.data?.records.map((prop, key) => {
            {
                return {
                    id: prop?._id,
                    item: prop?.description,
                    qty: prop?.quantity,
                    unit: prop?.unitOfMeasure,
                    price: prop?.unitPrice,
                    total: prop?.total,
                    amount: prop?.amount,
                    sku: prop?.sku,
                    askClients: prop?.askClient,
                    itemTypes: prop?.itemType,
                    discounts: prop?.discount,
                    category: prop?.category ? prop?.category.name : '',
                    categoryId: prop?.category ? prop?.category._id : '',
                    class: prop?.class ? prop?.class.name : '',
                    product: prop?.product ? prop?.product.name : '',
                    productId: prop?.product ? prop?.product._id : '',
                    classId: prop?.class ? prop?.class._id : '',
                    customer: prop?.customer ? prop?.customer.name : '',
                    customerId: prop?.customer ? prop?.customer._id : '',
                }
            }
        });
        const results = result.find(item => item.id === clientId);
        const found = InvoiceDetailsResponse?.data?.data?.records.find(obj => {
            return obj._id === businessId;
        });
        setOwnerName(found?.name);
        if (found?.ownerAssigned === true) {
            setPushPopFun(true)
            setLoading(true);
            var Str = results?.amount
            var newStr = Str ? Str.replace(/,/g, '') : 0;
            var Str1 = results?.unitPrice ? results?.unitPrice : results?.price
            var newStr1 = Str1 ? Str1.replace(/,/g, '') : 0;
            const Qty = results?.quantity ? results?.quantity : results?.qty;
            {
                results?.itemTypes === 'ItemBasedExpenseLineDetail' ?
                    setEditItemAPICall({
                        payload: [{
                            "itemType": 'ItemBasedExpenseLineDetail',
                            "unitPrice": Number(newStr1 ? newStr1 === 0 ? newStr : newStr1 : newStr),
                            "amount": Number(newStr ? newStr === 0 ? newStr1 : newStr : newStr1),
                            "discount": 0,
                            "sku": results?.sku,
                            "description": results?.item,
                            "unitOfMeasure": results?.unit ? results?.unit : null,
                            "productId": results?.productId ? results?.productId : null,
                            "InvoiceId": invoiceId ? invoiceId : documentsId,
                            "classId": results?.classId ? results?.classId : null,
                            "customerId": results?.customerId ? results?.customerId : null,
                            "quantity": Number(Qty ? Qty : 1),
                            "askClient": results?.askClients === true ? false : true,
                            "ItemId": clientId,
                        }]
                    }) :
                    setEditItemAPICall({
                        payload: [{
                            "itemType": 'AccountBasedExpenseLineDetail',
                            "unitPrice": Number(newStr1 ? newStr1 === 0 ? newStr : newStr1 : newStr),
                            "amount": Number(newStr ? newStr === 0 ? newStr1 : newStr : newStr1),
                            "unitOfMeasure": results?.unit ? results?.unit : null,
                            "quantity": Number(Qty ? Qty : 1),
                            "discount": 0,
                            "description": results?.item,
                            "InvoiceId": invoiceId ? invoiceId : documentsId,
                            "classId": results?.classId ? results?.classId : null,
                            "customerId": results?.customerId ? results?.customerId : null,
                            "categoryId": results?.categoryId ? results?.categoryId : null,
                            'askClient': results?.askClients === true ? false : true,
                            "ItemId": clientId,
                        }]
                    })
            }
        } else if (found?.ownerAssigned === false) {
            setAssignNewClient(true);
            setTimeout(() => {
                GetItemListListAPICall({
                    payload: [{ "InvoiceId": `${invoice_id}` }]
                })
            }, 2000);
        }
    };


    useEffect(() => {
        if (isGetItemListLoading) {
            return () => null;
        }
        if (isGetItemListError) {
            setLoading(false)
            return () => null;
        }
        if (GetItemListResponse && GetItemListResponse.data.isSuccess === true) {
            SetItemListResponse(GetItemListResponse);
            setTotalItemLength(GetItemListResponse?.data?.data?.totalRecords);
            if (pushPopFun) {
                const foundId = GetItemListResponse?.data?.data?.records.find(obj => {
                    return obj._id === matchId;
                });
                if (foundId?.askClient === true) {
                    setShowTrueSnackbar(true);
                    setTimeout(() => {
                        setShowTrueSnackbar(false);
                    }, 5000);
                } else if (foundId?.askClient === false) {
                    setShowFalseSnackbar(true);
                    setTimeout(() => {
                        setShowFalseSnackbar(false);
                    }, 5000);
                }
            }
            setTimeout(() => {
                setPushPopFun(false)
            }, 1000);

            if (onIssueClick1 === true && moduleTypeEdit === 'ITEM') {
                setValue(1);
                setOpenEditItem(true);
                setEditItemId(onIssueClickData?.moduleId);
            } else if (onIssueClick1 === true && moduleTypeEdit === 'DOCUMENT') {
                setValue(0);
            }
            const data = GetItemListResponse?.data?.data?.records.map((prop, key) => {
                const Error = IssueResponse?.data?.data?.records.find(obj => {
                    return obj.moduleId === prop._id;
                })
                return {
                    item: prop?.description ? prop?.description : '---',
                    qty: prop?.quantity ? isString(prop?.quantity) ? Number(prop?.quantity) : prop?.quantity : '---',
                    unit: prop?.unitOfMeasure ? prop?.unitOfMeasure : '---',
                    product: prop?.product?.name ? prop?.product?.name : '---',
                    price: prop?.unitPrice ? prop?.unitPrice : '0',
                    total: prop?.amount ? prop?.amount : '---',
                    customer: prop?.customer?.name ? prop?.customer?.name : '---',
                    category: prop?.category?.name ? prop?.category?.name : '---',
                    class: prop?.class?.name ? prop?.class?.name : '---',
                    proderror: Error ? true : false,
                    proderrorMsg: Error ? Error?.message : '---',
                    id: prop._id,
                    sku: prop.sku,
                    itemType: prop.itemType,
                    askClient: (
                        <MDBox display="flex" alignItems="center">
                            <input
                                type="checkbox"
                                id={prop._id}
                                onChange={() => toggleCheck(prop._id) && setLoading(true)}
                                style={{ height: '1.3em', width: '1.3em', cursor: 'pointer' }}
                                // checked={checked[prop?.askClient]}
                                checked={prop?.askClient}
                            />
                        </MDBox>
                    ),
                    CheckAskClient: prop?.askClient,
                };
            });
            const foundCheck = data.find(obj => {
                return obj.CheckAskClient === true;
            });
            setCheckFound(foundCheck !== undefined);
            const Categoryresult = data.filter(data => data.itemType === 'AccountBasedExpenseLineDetail');
            const Productresult = data.filter(data => data.itemType === 'ItemBasedExpenseLineDetail');
            setCategoryList(data);
            setProductList(data);
            if (firstTime === true) {
                if (Categoryresult.length === 0) {
                    // setToggleShow(true),
                    setItemList(data),
                        setLoading(false),
                        setFirstTime(false);
                } else {
                    // setToggleShow(false),
                    setItemList(data)
                    setLoading(false),
                        setFirstTime(false);
                }
            } else {
                setLoading(false),
                    toggleShow === true ? setItemList(data) : setItemList(data)
            }
            setLoading(false);
            setTimeout(() => {
                setLoading(false);
            }, 2000);
            setTimeout(() => {
                setShowDetails(true)
            }, 3000);
            setLoadingCircul(false);
        }
    }, [GetItemListResponse]);

    useEffect(() => {
        GetItemListResponse && DetailsResponse ?
            setShowDetails(true) : ''
    }, [GetItemListResponse, DetailsResponse])

    useEffect((e) => {
        const data = itemListResponse?.data?.data?.records.map((prop, key) => {
            return {
                item: (prop?.description ?
                    <MDBox>
                        <MDTypography >
                            <Tooltip title={prop?.description} placement="top">
                                <MDTypography
                                    sx={{
                                        '@media (max-width: 1440px)': {
                                            fontSize: "0.775rem", color: '#7b809a'
                                        }, cursor: "pointer", color: '#7b809a'
                                    }}
                                > {textEllipsis(prop?.description, 15)}</MDTypography>
                            </Tooltip>
                        </MDTypography>
                    </MDBox> : '---'
                )
                ,
                qty: prop?.quantity ? isString(prop?.quantity) ? Number(prop?.quantity) : prop?.quantity : '---',
                unit: prop?.unitOfMeasure ? prop?.unitOfMeasure : '---',
                product: (prop?.product?.name ?
                    <MDBox>
                        <MDTypography >
                            <Tooltip title={prop?.product?.name} placement="top">
                                <MDTypography
                                    sx={{
                                        '@media (max-width: 1440px)': {
                                            fontSize: "0.775rem", color: '#7b809a'
                                        }, cursor: "pointer", color: '#7b809a'
                                    }}
                                > {textEllipsis(prop?.product?.name, 18)}</MDTypography>
                            </Tooltip>
                        </MDTypography>
                    </MDBox> : '---'
                ),
                price: prop?.unitPrice ? prop?.unitPrice : '0',
                total: prop?.amount ? prop?.amount : '---',
                customer: (prop?.customer?.name ?
                    <MDBox>
                        <MDTypography  >
                            <Tooltip title={prop?.customer?.name} placement="top">
                                <MDTypography
                                    sx={{
                                        '@media (max-width: 1440px)': {
                                            fontSize: "0.775rem", color: '#7b809a'
                                        }, cursor: "pointer", color: '#7b809a'
                                    }}
                                > {textEllipsis(prop?.customer?.name, 11)}</MDTypography>
                            </Tooltip>
                        </MDTypography>
                    </MDBox> : '---'
                )
                ,
                category: (prop?.category?.name ?
                    <MDBox>
                        <MDTypography >
                            <Tooltip title={prop?.category?.name} placement="top">
                                <MDTypography
                                    sx={{
                                        '@media (max-width: 1440px)': {
                                            fontSize: "0.775rem", color: '#7b809a'
                                        }, cursor: "pointer", color: '#7b809a'
                                    }}
                                > {textEllipsis(prop?.category?.name, 18)}</MDTypography>
                            </Tooltip>
                        </MDTypography>
                    </MDBox> : '---'
                )
                ,
                class: (prop?.class?.name ?
                    <MDBox>
                        <MDTypography
                            fontWeight="400" fontSize="0.775rem" color="secondary"
                            sx={{ cursor: "pointer", ml: -4 }}
                        >
                            <Tooltip title={prop?.class?.name} placement="top">
                                <MDTypography
                                    sx={{
                                        '@media (max-width: 1440px)': {
                                            fontSize: "0.775rem", color: '#7b809a'
                                        }, cursor: "pointer", color: '#7b809a'
                                    }}
                                > {textEllipsis(prop?.class?.name, 18)}</MDTypography>
                            </Tooltip>
                        </MDTypography>
                    </MDBox> : '---'
                )
                ,
                id: prop._id,
                sku: prop.sku,
                itemType: prop.itemType,
                action: (documentStatus === 'Synced' || isSynced === true ? <MDBox display="flex">
                    {canAccess(actions = 'update', resource = 'documents') &&
                        <MDTypography
                            variant="body2"
                            color="secondary"
                        >  <Tooltip title="Edit" placement="top"
                            sx={{ cursor: "pointer" }}
                            onClick={(value) => {
                                setOpenQboSync(true);
                            }}
                        >
                                <Icon>edit</Icon>
                            </Tooltip>
                        </MDTypography>
                    }
                    {canAccess(actions = 'update', resource = 'documents') &&
                        <MDTypography variant="body2" color="secondary">
                            <Tooltip title='Delete' placement="top" sx={{ cursor: "pointer" }}
                                onClick={(value) => {
                                    setOpenQboSync(true);
                                }}
                            >
                                <Icon>delete</Icon>
                            </Tooltip>
                        </MDTypography>
                    }
                </MDBox> :
                    <MDBox display="flex">
                        {canAccess(actions = 'update', resource = 'documents') &&
                            <MDTypography
                                variant="body2"
                                color="secondary"
                            >  <Tooltip title="Edit" placement="top"
                                sx={{ cursor: "pointer" }}
                                onClick={(value) => {
                                    // setAccountantId(prop._id);
                                    handleEditItem();
                                    setEditItemId(prop._id);
                                }}
                            >
                                    <Icon>edit</Icon>
                                </Tooltip>
                            </MDTypography>
                        }
                        {canAccess(actions = 'delete', resource = 'documents') &&
                            <MDTypography variant="body2" color="secondary">
                                <Tooltip title='Delete' placement="top" sx={{ cursor: "pointer" }}
                                    onClick={(value) => {
                                        // setAccountantId(prop._id);
                                        warningWithConfirmMessage();
                                        setItemId(prop._id);
                                    }}
                                >
                                    <Icon>delete</Icon>
                                </Tooltip>
                            </MDTypography>
                        }
                    </MDBox>

                )
            };
        });
        const Categoryresult = data?.filter(data => data.itemType === 'AccountBasedExpenseLineDetail');
        const Productresult = data?.filter(data => data.itemType === 'ItemBasedExpenseLineDetail');
        setCategoryList(data);
        setProductList(data);
        if (firstTime === true) {
            if (Categoryresult?.length === 0) {
                // setToggleShow(true),
                setItemList(data),
                    // setLoading(false),
                    setFirstTime(false);

            } else {
                // setToggleShow(false),
                setItemList(data)
                // setLoading(false),
                setFirstTime(false);
            }
        } else {
            // setLoading(false),
            toggleShow === true ? setItemList(data) : setItemList(data)
        }

    }, [checked]);

    useEffect(() => {
        if (productList || categoryList) {
            { toggleShow === true ? setItemList(productList) : setItemList(productList) }
        }
    }, [toggleShow])

    useEffect(() => {
        if (isDeleteItemLoading) {
            return () => null;
        }
        if (isDeleteItemError) {
            setLoading(false);
            return () => null;
        }
        if (DeleteItemResponse && DeleteItemResponse.data.isSuccess === true) {
            GetItemListListAPICall({
                payload: [{ "InvoiceId": `${invoice_id}` }]
            })
            DetailsUserAPICall({
                payload: [{ "InvoiceId": `${invoice_id}`, "businessId": `${businessId}` }]
            })
        }
    }, [DeleteItemResponse]);

    useEffect(() => {
        if (isCommentsLoading) {
            return () => null;
        }
        if (isCommentsError) {
            setLoading(false);
            setLoadingCircul(false);
            return () => null;
        }
        if (CommentsResponse && CommentsResponse.data.isSuccess === true) {
            const LogCommentdata = CommentsResponse.data.data.records.map((prop, key) => {
                const commentBy = prop.commentBy.fname.charAt(0).toUpperCase() + prop.commentBy.fname.slice(1) + " " + prop.commentBy.lname
                const comments = prop.comment;
                var found = [],
                    rxp = /{{([^}]+)}}/g,
                    str = comments,
                    curMatch;

                while (curMatch = rxp.exec(str)) {
                    found.push(curMatch[1]);
                }
                function findReplaceString(string, found) {
                    let results = string.match(/{{([^}]+)}}/g);
                    if (results) {
                        let results = string.match(/{{([^}]+)}}/g);
                        const final = results?.map((prop, key) => {
                            const Name = users.find(obj => {
                                return obj.id == found[key];
                            });
                            string = string.replace(new RegExp('\{\{(?:\\s+)?(' + found[key] + ')(?:\\s+)?\}\}'), `@${Name?.display}`);
                            return string;
                        });
                        return final.slice(-1);
                    }
                }
                const datacom = findReplaceString(comments, found)

                return (
                    <Paper style={{ padding: "30px 20px" }}>
                        <Grid container wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Avatar alt="Remy Sharp" sx={{ width: 33, height: 33 }} />
                            </Grid>
                            <Grid justifyContent="left" item xs zeroMinWidth>
                                <div style={{ display: "flex" }}>
                                    <h4 style={{
                                        margin: 0,
                                        textAlign: "left",
                                        fontFamily: "Arial",
                                        fontSize: "0.775rem",
                                        fontWeight: "bold",
                                        lineHeight: 1.5,
                                        textTransform: "uppercase",
                                        letterSpacing: "0.02857em",
                                        opacity: 1,
                                        textTransform: "none",
                                        verticalAlign: "unset",
                                        textAlign: "left",
                                        marginBottom: "5px",
                                    }}>{commentBy}</h4>
                                    <MDTypography sx={{ mt: 0.5, ml: 1 }} variant="caption" color="text">
                                        {inLocalTime(prop.createdAt)}
                                    </MDTypography>
                                </div>
                                <p style={{
                                    margin: 0,
                                    fontFamily: "Arial",
                                    fontSize: "0.775rem",
                                    fontWeight: 300,
                                    lineHeight: 1.5,
                                    textTransform: "uppercase",
                                    letterSpacing: "0.02857em",
                                    opacity: 1,
                                    textTransform: "none",
                                    verticalAlign: "unset",
                                    textAlign: "left"
                                }}>
                                    {datacom ? datacom[0] : comments}
                                </p>
                            </Grid>
                        </Grid>
                    </Paper >
                )
            });
            setCommentsList(LogCommentdata);
            setLoading(false);
            setLoadingCircul(false);
        }
    }, [CommentsResponse]);

    useEffect(() => {
        if (isAuditLogLoading) {
            return () => null;
        }
        if (isAuditLogError) {
            setLoadingCircul(false);
            setLoading(false);
            return () => null;
        }
        if (AuditLogResponse && AuditLogResponse.data.isSuccess === true) {
            const Logdata = AuditLogResponse.data.data.records.map((prop, key) => {

                return (
                    < TimelineItem
                        dateTime={prop.createdAt}
                        description={prop.message}
                        badges={["design"]}
                        type={prop.eventType}
                        button={prop.moduleId}
                    />
                )
            })
            setLoadingCircul(false);
            setAuditLogList(Logdata);
            setLoading(false);
        }
    }, [AuditLogResponse]);

    useEffect(() => {
        if (isLoading) {
            return () => null;
        }
        if (isError) {
            setBtnDisable(false);
            setLoadingCircul(false);
            return () => null;
        }
        if (response && response.data.isSuccess === true) {
            eventBus.$emit("refreshListAfterUpdate", response);
            DetailsUserAPICall({
                payload: [{ "InvoiceId": `${invoice_id}`, "businessId": `${businessId}` }]
            })
            setBtnDisable(false);
            setShowSnackbar(true);
            setTimeout(() => {
                setShowSnackbar(false);
            }, 10000);

            SetEditable(false)
            setLoading(true);
            setLoadingCircul(false);
        }
    }, [response]);

    useEffect(async () => {
        setFirstTime(onIssueClick1);
        await DetailsUserAPICall({
            payload: [{ "InvoiceId": `${invoice_id}`, "businessId": `${businessId}` }]
        })
        await VendorListAPICall({
            payload: [{ "businessId": `${businessId}`, IsSynced: true }]
        });
    }, []);


    useEffect(() => {
        if (isVendorLoading) {
            return () => null;
        }
        if (isVendorError) {
            setLoading(false);
            return () => null;
        }
        if (VendorResponse && VendorResponse.data.isSuccess === true) {
            setLoading(false);
            setVendorList(VendorResponse?.data?.data?.records);
            const Name = VendorResponse?.data?.data?.records.find(obj => {
                return obj._id == addNewVendorId;
            });
            Name?.name ? setVendorname2(Name?.name) : ''
        }
    }, [VendorResponse]);

    eventBus.$on('AddnewVendorError', (data) => AddnewVendorError(data));
    function AddnewVendorError(data) {
        setAddVendoreerror(true);
        setErrorMessagesVendorError('Error')
    }


    eventBus.$on('AddnewVendorComplete', (data) => AddnewVendorComplete(data));
    function AddnewVendorComplete(data) {
        setAddNewVendorId(data?.vendor?._id);
        setOpenEditVendor(false);
        setLoading(true);
        setAddNewVendor(true);
        VendorListAPICall({
            payload: [{ "businessId": `${businessId}`, IsSynced: true }]
        });
    }

    eventBus.$on('successInAddOwner', (data) => successInAddOwner(data));
    function successInAddOwner(data) {
        setOpenAddOwner(false);
        navigate('/accountant/client/list');
    }

    eventBus.$on('closeCategoryEdit', (data) => closeCategoryEdit(data));
    function closeCategoryEdit(data) {
        setOpenAddItem(false);
    }

    eventBus.$on('handleChangeRedio', (data) => handleChangeRedioEvent(data));
    function handleChangeRedioEvent(data) {
        data === 'ItemBasedExpenseLineDetail' ? handleAddItem() : handleCategort()
    }

    eventBus.$on('handleChangeEditRedio', (data) => handleChangeEditRedioEvent(data));
    function handleChangeEditRedioEvent(data) {
        setToggleShow(!toggleShow);
        data === 'ItemBasedExpenseLineDetail' ? setOpenEditItem(true) : setOpenEditItem(true)
    }

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (openAddItem) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openAddItem]);

    useEffect(() => {
        if (isDetailsLoading) {
            return () => null;
        }

        if (isDetailsError) {
            setLoadingCircul(false);
            return () => null;
        }

        if (DetailsResponse && DetailsResponse.data.isSuccess === true) {
            eventBus.$emit("refreshListAfterUpdate", response);
            DetailsResponse?.data?.data?.document?.documentSource === "QBO" ? setEditbleData(false) : setEditbleData(true)
            setOcrstatus(DetailsResponse?.data?.data?.document?.ocrStatus === 'INPROGRESS' ? true : false);
            setItemType(DetailsResponse?.data?.data?.document?.itemType);
            setOcrstatus1(DetailsResponse?.data?.data?.document?.ocrStatus);
            setActualSubtotal(DetailsResponse?.data?.data?.document?.actualSubtotal);
            setActualTotal(DetailsResponse?.data?.data?.document?.actualTotal);
            setVendorSelect(DetailsResponse?.data?.data?.document?.vendor ? DetailsResponse?.data?.data?.document?.vendor.name : '');
            setVendorname(DetailsResponse?.data?.data?.document?.vendor ? DetailsResponse?.data?.data?.document?.vendor.name : '');
            setVendorname1(DetailsResponse?.data?.data?.document?.vendor ? DetailsResponse?.data?.data?.document?.vendor.name : '');
            setInvoiceNumber(DetailsResponse?.data?.data?.document?.invoice_number ? DetailsResponse?.data?.data?.document?.invoice_number : DetailsResponse?.data?.data?.document?.docNumber);
            setPoNumber((DetailsResponse?.data?.data?.document?.order_no) ? DetailsResponse?.data?.data?.document?.order_no : '')
            setInvoiceDate(DetailsResponse?.data?.data?.document?.orderDate ? Moment(DetailsResponse?.data?.data?.document?.orderDate).format('YYYY-MM-DD') : null);
            setTransactionDate(DetailsResponse?.data?.data?.document?.transactionDate ? Moment(DetailsResponse?.data?.data?.document?.transactionDate).format('YYYY-MM-DD') : null)
            setUploadDate(DetailsResponse?.data?.data?.document?.createdAt ? Moment(DetailsResponse?.data?.data?.document?.createdAt).format('YYYY-MM-DD') : null);
            setTotal(DetailsResponse?.data?.data?.document?.total);
            setTotalStore(DetailsResponse?.data?.data?.document?.total);
            setOrderTotalStore(DetailsResponse?.data?.data?.document?.orderTotal);
            setTax(DetailsResponse?.data?.data?.document?.tax);
            setDiscount(DetailsResponse?.data?.data?.document?.discount);
            setDiscountStore(DetailsResponse?.data?.data?.document?.discount);
            setShippingpriceStore(DetailsResponse?.data?.data?.document?.shippingPrice);
            setShippingprice(DetailsResponse?.data?.data?.document?.shippingPrice);
            setTaxStore(DetailsResponse?.data?.data?.document?.tax)
            setPoNumber(DetailsResponse?.data?.data?.document?.purchaseOrderNo);
            setIssues(DetailsResponse?.data?.data?.document?.issues);
            setSubTotal(DetailsResponse?.data?.data?.document?.sub_total ? DetailsResponse?.data?.data?.document?.sub_total : DetailsResponse?.data?.data?.document?.subTotal);
            setSubTotStore(DetailsResponse?.data?.data?.document?.sub_total ? DetailsResponse?.data?.data?.document?.sub_total : DetailsResponse?.data?.data?.document?.subTotal);
            setCurrencySymbol(DetailsResponse?.data?.data?.document?.currencySymbol);
            setDocumnetSource(DetailsResponse?.data?.data?.document?.documentSource ? DetailsResponse?.data?.data?.document?.documentSource : '');
            setIsSynced(DetailsResponse?.data?.data?.document?.isSynced);
            setDocumentStatus(DetailsResponse?.data?.data?.document?.documentStatus ? DetailsResponse?.data?.data?.document?.documentStatus : '')
            setDocumentType(DetailsResponse?.data?.data?.document?.documentType ? DetailsResponse?.data?.data?.document?.documentType : '');
            setCreateObjectURL(DetailsResponse?.data?.data?.document?.uploadedFileUrl ? DetailsResponse?.data?.data?.document?.uploadedFileUrl : '')
            setImageUrl(DetailsResponse?.data?.data?.document?.uploadedFileUrl ? DetailsResponse?.data?.data?.document?.uploadedFileUrl : '')
            // setShowDetails(true);
            setLoading(false);
            DetailsResponse?.data?.data?.document?.itemType === 'ItemBasedExpenseLineDetail' ? setToggleShow(true) : setToggleShow(false)
            setLoadingCircul(false);
            setShowDetailsForm(true);
            setResetData(false);
            setInitialValues({
                vendorname: DetailsResponse?.data?.data?.document?.vendor ? DetailsResponse?.data?.data?.document?.vendor.name : '',
                documenttype: DetailsResponse?.data?.data?.document?.documentType ? DetailsResponse?.data?.data?.document?.documentType : '',
                invoicenumber: DetailsResponse?.data?.data?.document?.docNumber,
                ponumber: DetailsResponse?.data?.data?.document?.purchaseOrderNo,
                invoicedate: DetailsResponse?.data?.data?.document?.transactionDate ? Moment(DetailsResponse?.data?.data?.document?.transactionDate).format('YYYY-MM-DD') : null,
                uploaddate: DetailsResponse?.data?.data?.document?.createdAt ? Moment(DetailsResponse?.data?.data?.document?.createdAt).format('YYYY-MM-DD') : null,
                total: `${total}`,
                tax: `${tax}`,
                transactionDate: `${transactionDate}`,
                subtotal: `${subtotal}`
            })
            IssueListAPICall({
                payload: [{ "businessId": `${businessId}`, "InvoiceId": `${invoice_id}` }]
            });
        }
    }, [DetailsResponse]);
    useEffect(() => {
        setInitialValues({
            vendorname: 'test',
            documenttype: documentType,
            invoicenumber: invoicenumber,
            ponumber: ponumber,
            invoicedate: transactionDate,
            uploaddate: uploaddate,
            total: `${total}`,
            tax: `${tax}`,
            transactionDate: `${transactionDate}`,
            subtotal: `${subtotal}`
        })
    }, [resetData]);


    const handleClosebtn = async () => {
        setVendorname(vendorname1);
        setResetData(true);
        SetEditable(false);
        setOpenEditVendor(false);
        await DetailsUserAPICall({
            payload: [{ "InvoiceId": `${invoice_id}`, "businessId": `${businessId}` }]
        })
    }

    const handleCloseVendorbtn = async () => {
        setOpenEditVendor(false);
        await DetailsUserAPICall({
            payload: [{ "InvoiceId": `${invoice_id}`, "businessId": `${businessId}` }]
        })
    }

    const closeProductEdit = async () => {
        setOpenEditItem(false);
        setOnIssueClick1(false);
        eventBus.$emit("closeandremovealldata", 'remove');
    }

    const handleChangeComment = async (event) => {
        const value = event.target.value;
        const regex = /[^{}]+(?=})/g;
        const mentions = value.match(regex);
        // setComments(event);
    };

    const handleChange = async (event, newValue) => {
        setFirstTime(true);
        setOnIssueClick1(false);
        setValue(newValue);
        if (newValue === 1) {
            setLoading(true);
            // setLoadingCircul(true);
            await GetItemListListAPICall({
                payload: [{ "InvoiceId": `${invoice_id}` }]
            })
        } else if (newValue === 2) {
            setLoading(true);
            // setLoadingCircul(true);
            await AuditLogListAPICall({
                payload: [{ "InvoiceId": `${invoice_id}`, "businessId": `${businessId}` }]
            })
        } else if (newValue === 3) {
            setLoading(true);
            // setLoadingCircul(true);
            await CommentsListAPICall({
                payload: [{ "InvoiceId": `${invoice_id}`, "businessId": `${businessId}` }]
            })
        }
    };

    const handleAddNewVendor = async (values) => {
        DetailsResponse?.data?.data?.document?.vendor?.billingAddress?.line1 ?
            await AddVendorListAPICall({
                payload: [
                    {
                        "name": vendorname,
                        // "phone": DetailsResponse?.data?.data?.document?.vendor?.phone,
                        // "email": DetailsResponse?.data?.data?.document?.vendor?.email,
                        // "accountNo": values?.accountno,
                        // "businessNumber": values?.businessNumber,
                        // "billRate": Number(values?.billRate),
                        // "vendor1099": vendor1099,
                        "billingAddress": {
                            "line1": DetailsResponse?.data?.data?.document?.vendor?.billingAddress?.line1,
                            "city": DetailsResponse?.data?.data?.document?.vendor?.billingAddress?.city,
                            "state": DetailsResponse?.data?.data?.document?.vendor?.billingAddress?.state,
                            "zip": DetailsResponse?.data?.data?.document?.vendor?.billingAddress?.zip,
                        },
                        "clientId": `${businessId}`
                    }]
            }) :
            await AddVendorListAPICall({
                payload: [
                    {
                        "name": vendorname,
                        // "phone": DetailsResponse?.data?.data?.document?.vendor?.phone,
                        // "email": DetailsResponse?.data?.data?.document?.vendor?.email,
                        // "businessNumber": values?.businessNumber,
                        // "billRate": Number(values?.billRate),
                        // "vendor1099": vendor1099,
                        // "accountNo": values?.accountno,
                        "clientId": `${businessId}`
                    }]
            })
    };

    const handleSubmit = async (event, values) => {
        setBtnDisable(true);
        setLoadingCircul(true);
        const finalStore = (typeof (subTotStore) == 'string') ? subTotStore.replaceAll(',', '') : subTotStore;
        const finalTax = (typeof (taxStore) == 'string') ? taxStore.replaceAll(',', '') : taxStore;
        const finalShippingPrice = (typeof (shippingpriceStore) == 'string') ? shippingpriceStore.replaceAll(',', '') : shippingpriceStore;
        const finaldiscount = (typeof (discountStore) == 'string') ? discountStore.replaceAll(',', '') : discountStore;
        const finalTotal = (typeof (TotalStore) == 'string') ? TotalStore.replaceAll(',', '') : TotalStore;
        await setAPICall({
            payload: [
                {
                    "transactionDate": `${event.invoicedate}`,
                    "createdAt": `${event.uploaddate}`,
                    "documentType": `${event.documenttype}`,
                    "docNumber": `${event.invoicenumber}`,
                    "vendorId": `${addNewVendorId ? addNewVendorId : showselectedVendorId}`,
                    "subTotal": Number(finalStore),
                    "tax": Number(finalTax),
                    "shippingPrice": Number(finalShippingPrice),
                    "discount": Number(finaldiscount),
                    "total": Number(finalTotal),
                    "purchaseOrderNo": `${event.ponumber}`,
                    "businessId": `${businessId}`,
                    "documnetId": `${invoice_id}`
                }
            ]
        },
        );
        eventBus.$emit("refreshApiInvoiceList", event);
    }

    const ToggleClick = () => {
        setToggleShow(!toggleShow),
            setLoading(true),
            setAPICall({
                payload: [
                    {
                        "itemType": toggleShow === false ? `ItemBasedExpenseLineDetail` : `AccountBasedExpenseLineDetail`,
                        "businessId": `${businessId}`,
                        "documnetId": `${invoice_id}`
                    }
                ]
            },
            );
    }

    const handleToggle = () => {
        documentStatus === 'Synced' || isSynced === true ?
            setOpenQboSync(true)
            :
            ToggleClick()
    }
    const handleAddItem = () => {
        setOpenAddItem(true)
        setOpenCategoryAddItem(false)
    }
    const handleCategort = () => {
        setOpenCategoryAddItem(true)
        setOpenAddItem(false)
    }
    const handleEditItem = () => {
        setOpenEditItem(true)
    }

    const handleEditVendor = () => {
        handleClose()
        setOpenEditVendor(true);
    }

    const warningWithConfirmMessage = () => {
        setAlert(true);
    };
    const hideAlert = () => {
        setAlert(false);
    };
    const successDelete = () => {
        DeleteItemListAPICall({
            payload: [
                {
                    "adjustInvoice": false,
                    "InvoiceId": `${invoice_id}`,
                    "ItemId": `${itemId}`,
                }
            ]
        });
        setAlert(false);
        setLoading(true);
    }


    const hideInvoiceAlert = () => {
        setAlertDeleteInvoice(false);
    };
    const successInoiceDelete = () => {
        DeleteInvoiceListAPICall({
            payload: [
                {
                    "documentIds": {
                        'documentIds': [invoiceId]
                    },
                    "businessId": `${businessId}`,
                }
            ]
        });
        setAlert(false);
        setLoading(true);
    }

    const handleDeleteInvoice = () => {
        // setOpenMenu(false)
        handleClose()

        setAlertDeleteInvoice(true);
    }

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const closeMoveDialog = async () => {
        setOpenQboSyncAskClient(false);
    }
    const editItemList = finalSelect == 'Ask Client' || finalSelect == 'Classify' || documentStatus == 'AskClient' || documentStatus == 'Classify';

    const columns = [
        {
            headerName: "PRODUCTS/SERVICE",
            field: "product",
            minWidth: 110,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            renderEditCell: SelectProductEditInputCell,
            editable: editItemList && editbleData,
            flex: 0.2,
            renderCell: (params) => (
                params?.row?.proderror === true ?
                    <Tooltip title={params?.row?.proderrorMsg} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", color: '#7b809a', padding: 2
                                }, cursor: "pointer", color: '#7b809a', padding: 2
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>
                    :
                    <Tooltip title={params?.formattedValue} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                                },
                                // "& .MuiDataGrid-column:hover": {
                                //     backgroundColor: "skyblue",
                                //     color: "red"
                                // },
                                cursor: "pointer", color: '#7b809a'
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>),
        },
        {
            field: 'item',
            headerName: 'DESCRIPTION',
            minWidth: 30,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem",
                            },
                        }}
                    > {textEllipsis(params?.formattedValue, 15)}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'class',
            headerName: 'CLASS',
            renderEditCell: SelectClassEditInputCell,
            editable: editItemList && editbleData,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            minWidth: 65,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, cursor: "pointer", color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 15)}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'customer',
            headerName: 'CUSTOMER',
            renderEditCell: SelectCustomerEditInputCell,
            editable: editItemList && editbleData,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            minWidth: 60,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, cursor: "pointer", color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 11)}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "QTY",
            field: "qty",
            minWidth: 20,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.1,
            valueGetter: getQuantity,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "UNIT",
            field: "unit",
            minWidth: 40,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.1,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "PRICE",
            field: "price",
            minWidth: 55,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getPriceProduct,
            flex: 0.1,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "TOTAL",
            field: "total",
            headerAlign: 'center',
            minWidth: 55,
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getTotalProduct,
            flex: 0.1,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'ACTIONS',
            maxWidth: 75,
            flex: 0.1,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    documentStatus === 'Synced' || isSynced === true
                        ?
                        <>
                            <GridActionsCellItem
                                icon={<EditIcon />}
                                label="Edit"
                                className="textPrimary"
                                onClick={(value) => {
                                    setOpenQboSync(true);
                                }}
                                color="inherit"
                            />
                            <GridActionsCellItem
                                icon={<DeleteIcon />}
                                label="Delete"
                                onClick={(value) => {
                                    setOpenQboSync(true);
                                }}
                                color="inherit"
                            />
                        </>
                        :
                        <>
                            <GridActionsCellItem
                                icon={<EditIcon fontSize="small" />}
                                label="Edit"
                                className="textPrimary"
                                onClick={() => {
                                    // setAccountantId(prop._id);
                                    setEditItemId(id);
                                    handleEditItem();
                                }}
                                color="inherit"
                            />
                            <GridActionsCellItem
                                icon={<DeleteIcon fontSize="small" />}
                                label="Delete"
                                onClick={(value) => {
                                    totalItemLength === 1 ? setOneItemPopup(true) :
                                        warningWithConfirmMessage();
                                    setItemId(id);
                                }}
                                color="inherit"
                            />
                        </>
                ];
            },
        },
        {
            field: 'askClients',
            type: 'actions',
            headerName: 'ASK CLIENT',
            maxWidth: 95,
            flex: 0.15,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const result = itemListResponse?.data?.data?.records.map((prop, key) => {
                    {
                        return {
                            id: prop._id,
                            item: prop.description,
                            qty: prop.quantity,
                            unit: prop.unitOfMeasure,
                            price: prop.unitPrice,
                            total: prop.total,
                            sku: prop.sku,
                            unitPrice: prop.unitPrice,
                            results: prop.results,
                            askClients: prop.askClient,
                            category: prop.category ? prop.category.name : '',
                            categoryId: prop.category ? prop.category._id : '',
                            class: prop.class ? prop.class.name : '',
                            product: prop.product ? prop.product.name : '',
                            productId: prop.product ? prop.product._id : '',
                            classId: prop.class ? prop.class._id : '',
                            customer: prop.customer ? prop.customer.name : '',
                            customerId: prop.customer ? prop.customer._id : '',
                        }
                    }
                });
                const found = result.find(obj => {
                    return obj.id === id;
                });

                return [
                    <input
                        type="checkbox"
                        title={selectTab !== "Classify" ? "Document must be in classify in order to Ask Client." : ''}
                        disabled={selectTab === "Classify" ? false : true}
                        id={id}
                        onChange={() => toggleCheck(id)}
                        checked={found?.askClients}
                    />
                ];
            },
        },
    ];

    const columnsWithoutAction = [
        {
            headerName: "PRODUCTS/SERVICE",
            field: "product",
            minWidth: 110,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            renderEditCell: SelectProductEditInputCell,
            editable: editItemList && editbleData,
            flex: 0.2,
            renderCell: (params) => (
                params?.row?.proderror === true ?
                    <Tooltip title={params?.row?.proderrorMsg} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", color: '#7b809a', padding: 2
                                }, cursor: "pointer", color: '#7b809a', padding: 2
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>
                    :
                    <Tooltip title={params?.formattedValue} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                                },
                                // "& .MuiDataGrid-column:hover": {
                                //     backgroundColor: "skyblue",
                                //     color: "red"
                                // },
                                cursor: "pointer", color: '#7b809a'
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>),
        },
        {
            field: 'item',
            headerName: 'DESCRIPTION',
            minWidth: 30,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem",
                            },
                        }}
                    > {textEllipsis(params?.formattedValue, 15)}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'class',
            headerName: 'CLASS',
            renderEditCell: SelectClassEditInputCell,
            editable: editItemList && editbleData,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            minWidth: 65,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, cursor: "pointer", color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 15)}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'customer',
            headerName: 'CUSTOMER',
            renderEditCell: SelectCustomerEditInputCell,
            editable: editItemList && editbleData,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            minWidth: 60,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, cursor: "pointer", color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 11)}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "QTY",
            field: "qty",
            minWidth: 20,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.1,
            valueGetter: getQuantity,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "UNIT",
            field: "unit",
            minWidth: 40,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.1,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "PRICE",
            field: "price",
            minWidth: 55,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getPriceProduct,
            flex: 0.1,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "TOTAL",
            field: "total",
            headerAlign: 'center',
            minWidth: 55,
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getTotalProduct,
            flex: 0.1,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
    ];

    const columnsWitoutClass = [
        {
            headerName: "PRODUCTS/SERVICE",
            field: "product",
            minWidth: 60,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            renderEditCell: SelectProductEditInputCell,
            editable: editItemList && editbleData,
            flex: 0.2,
            renderCell: (params) => (
                params?.row?.proderror === true ?
                    <Tooltip title={params?.row?.proderrorMsg} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", color: '#7b809a', padding: 2
                                }, cursor: "pointer", color: '#7b809a', padding: 2
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>
                    :
                    <Tooltip title={params?.formattedValue} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                                }, cursor: "pointer", color: '#7b809a'
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>),
        },
        {
            field: 'item',
            headerName: 'DESCRIPTION',
            minWidth: 30,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 15)}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'customer',
            headerName: 'CUSTOMER',
            renderEditCell: SelectCustomerEditInputCell,
            editable: editItemList && editbleData,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            minWidth: 60,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, cursor: "pointer", color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 11)}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "QTY",
            field: "qty",
            minWidth: 25,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.1,
            valueGetter: getQuantity,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "UNIT",
            field: "unit",
            minWidth: 40,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.1,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "PRICE",
            field: "price",
            minWidth: 70,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getPriceProduct,
            flex: 0.1,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "TOTAL",
            field: "total",
            headerAlign: 'center',
            minWidth: 70,
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getTotalProduct,
            flex: 0.1,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'ACTIONS',
            maxWidth: 82,
            flex: 0.3,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    documentStatus === 'Synced' || isSynced === true
                        ?
                        <>
                            <GridActionsCellItem
                                icon={<EditIcon />}
                                label="Edit"
                                className="textPrimary"
                                onClick={(value) => {
                                    setOpenQboSync(true);
                                }}
                                color="inherit"
                            />
                            <GridActionsCellItem
                                icon={<DeleteIcon />}
                                label="Delete"
                                onClick={(value) => {
                                    setOpenQboSync(true);
                                }}
                                color="inherit"
                            />
                        </>
                        :
                        <>
                            <GridActionsCellItem
                                icon={<EditIcon fontSize="small" />}
                                label="Edit"
                                className="textPrimary"
                                onClick={() => {
                                    // setAccountantId(prop._id);
                                    setEditItemId(id);
                                    handleEditItem();
                                }}
                                color="inherit"
                            />
                            <GridActionsCellItem
                                icon={<DeleteIcon fontSize="small" />}
                                label="Delete"
                                onClick={(value) => {
                                    totalItemLength === 1 ? setOneItemPopup(true) :
                                        warningWithConfirmMessage();
                                    setItemId(id);
                                }}
                                color="inherit"
                            />
                        </>
                ];
            },
        },
        {
            field: 'askClients',
            type: 'actions',
            headerName: 'ASK CLIENT',
            maxWidth: 95,
            flex: 0.4,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const result = itemListResponse?.data?.data?.records.map((prop, key) => {
                    {
                        return {
                            id: prop._id,
                            item: prop.description,
                            qty: prop.quantity,
                            unit: prop.unitOfMeasure,
                            price: prop.unitPrice,
                            total: prop.total,
                            sku: prop.sku,
                            unitPrice: prop.unitPrice,
                            results: prop.results,
                            askClients: prop.askClient,
                            category: prop.category ? prop.category.name : '',
                            categoryId: prop.category ? prop.category._id : '',
                            class: prop.class ? prop.class.name : '',
                            product: prop.product ? prop.product.name : '',
                            productId: prop.product ? prop.product._id : '',
                            classId: prop.class ? prop.class._id : '',
                            customer: prop.customer ? prop.customer.name : '',
                            customerId: prop.customer ? prop.customer._id : '',
                        }
                    }
                });
                const found = result.find(obj => {
                    return obj.id === id;
                });

                return [
                    <input
                        type="checkbox"
                        title={selectTab !== "Classify" ? "Document must be in classify in order to Ask Client." : ''}
                        disabled={selectTab === "Classify" ? false : true}
                        id={id}
                        onChange={() => toggleCheck(id)}
                        checked={found?.askClients}
                    />
                ];
            },
        },
    ];

    const columnsWitoutClassAction = [
        {
            headerName: "PRODUCTS/SERVICE",
            field: "product",
            minWidth: 60,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            renderEditCell: SelectProductEditInputCell,
            editable: editItemList && editbleData,
            flex: 0.2,
            renderCell: (params) => (
                params?.row?.proderror === true ?
                    <Tooltip title={params?.row?.proderrorMsg} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", color: '#7b809a', padding: 2
                                }, cursor: "pointer", color: '#7b809a', padding: 2
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>
                    :
                    <Tooltip title={params?.formattedValue} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                                }, cursor: "pointer", color: '#7b809a'
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>),
        },
        {
            field: 'item',
            headerName: 'DESCRIPTION',
            minWidth: 30,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 15)}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'customer',
            headerName: 'CUSTOMER',
            renderEditCell: SelectCustomerEditInputCell,
            editable: editItemList && editbleData,
            sortable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            minWidth: 60,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, cursor: "pointer", color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 11)}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "QTY",
            field: "qty",
            minWidth: 25,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.1,
            valueGetter: getQuantity,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "UNIT",
            field: "unit",
            minWidth: 40,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.1,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "PRICE",
            field: "price",
            minWidth: 40,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getPriceProduct,
            flex: 0.1,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "TOTAL",
            field: "total",
            headerAlign: 'center',
            minWidth: 40,
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getTotalProduct,
            flex: 0.1,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
    ];

    const cetagorycolumns = [
        {
            field: 'category',
            headerName: 'CATEGORY',
            renderEditCell: SelectCategoryEditInputCell,
            editable: editItemList && editbleData,
            sortable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            minWidth: 120,
            flex: 0.4,
            renderCell: (params) => (
                params?.row?.proderror === true ?
                    <Tooltip title={params?.row?.proderrorMsg} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", color: '#7b809a', padding: 2
                                }, cursor: "pointer", color: '#7b809a', padding: 2
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>
                    :
                    <Tooltip title={params?.formattedValue} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", color: '#7b809a'
                                }, cursor: "pointer", color: '#7b809a'
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>),
        },
        {
            field: 'item',
            headerName: 'DESCRIPTION',
            minWidth: 120,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false,
            flex: 0.5,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 15)}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'class',
            headerName: 'CLASS',
            renderEditCell: SelectClassEditInputCell,
            editable: editItemList && editbleData,
            minWidth: 70,
            disableColumnMenu: true,
            headerAlign: 'center',
            sortable: false,
            flex: 0.3,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, cursor: "pointer", color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 15)}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'customer',
            headerName: 'CUSTOMER',
            renderEditCell: SelectCustomerEditInputCell,
            editable: editItemList && editbleData,
            minWidth: 100,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false,
            flex: 0.4,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, cursor: "pointer", color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 11)}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "QTY",
            field: "qty",
            minWidth: 20,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.26,
            valueGetter: getQuantity,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "UNIT",
            field: "unit",
            minWidth: 40,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.3,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "PRICE",
            field: "price",
            minWidth: 55,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getPriceProduct,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "TOTAL",
            field: "total",
            headerAlign: 'center',
            minWidth: 55,
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getTotalProduct,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'ACTION',
            maxWidth: 73,
            flex: 0.4,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    documentStatus === 'Synced' || isSynced === true
                        ?
                        <>
                            <GridActionsCellItem
                                icon={<EditIcon />}
                                label="Edit"
                                className="textPrimary"
                                onClick={(value) => {
                                    setOpenQboSync(true);
                                }}
                                color="inherit"
                            />
                            <GridActionsCellItem
                                icon={<DeleteIcon />}
                                label="Delete"
                                onClick={(value) => {
                                    setOpenQboSync(true);
                                }}
                                color="inherit"
                            />
                        </>
                        :
                        <>
                            <GridActionsCellItem
                                icon={<EditIcon />}
                                label="Edit"
                                className="textPrimary"
                                onClick={() => {
                                    editbleData === true ?
                                        setEditItemId(id) ||
                                        handleEditItem()
                                        :
                                        setOpenQboSync(true);
                                }}
                                color="inherit"
                            />
                            <GridActionsCellItem
                                icon={<DeleteIcon />}
                                label="Delete"
                                onClick={(value) => {
                                    totalItemLength === 1 ? setOneItemPopup(true) :
                                        editbleData === true ?
                                            warningWithConfirmMessage() ||
                                            setItemId(id)
                                            :
                                            setOpenQboSync(true);
                                }}
                                color="inherit"
                            />
                        </>
                ]
            }
        },
        {
            field: 'askClients',
            type: 'actions',
            headerName: 'ASK CLIENT',
            maxWidth: 97,
            flex: 0.52,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const result = itemListResponse?.data?.data?.records.map((prop, key) => {
                    {
                        return {
                            id: prop._id,
                            item: prop.description,
                            qty: prop.quantity,
                            unit: prop.unitOfMeasure,
                            price: prop.unitPrice,
                            total: prop.total,
                            sku: prop.sku,
                            unitPrice: prop.unitPrice,
                            results: prop.results,
                            askClients: prop.askClient,
                            category: prop.category ? prop.category.name : '',
                            categoryId: prop.category ? prop.category._id : '',
                            class: prop.class ? prop.class.name : '',
                            product: prop.product ? prop.product.name : '',
                            productId: prop.product ? prop.product._id : '',
                            classId: prop.class ? prop.class._id : '',
                            customer: prop.customer ? prop.customer.name : '',
                            customerId: prop.customer ? prop.customer._id : '',
                        }
                    }
                });
                const found = result.find(obj => {
                    return obj.id === id;
                });

                return [
                    <input
                        type="checkbox"
                        id={id}
                        title={selectTab !== "Classify" ? "Document must be in classify in order to Ask Client." : ''}
                        disabled={selectTab === "Classify" ? false : true}
                        onChange={() => toggleCheck(id)}
                        checked={found?.askClients}
                    />
                ];
            },
        },
    ];

    const cetagorycolumnsWithotAction = [
        {
            field: 'category',
            headerName: 'CATEGORY',
            renderEditCell: SelectCategoryEditInputCell,
            editable: editItemList && editbleData,
            sortable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            minWidth: 120,
            flex: 0.4,
            renderCell: (params) => (
                params?.row?.proderror === true ?
                    <Tooltip title={params?.row?.proderrorMsg} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", color: '#7b809a', padding: 2
                                }, cursor: "pointer", color: '#7b809a', padding: 2
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>
                    :
                    <Tooltip title={params?.formattedValue} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", color: '#7b809a'
                                }, cursor: "pointer", color: '#7b809a'
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>),
        },
        {
            field: 'item',
            headerName: 'DESCRIPTION',
            minWidth: 120,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false,
            flex: 0.5,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 15)}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'class',
            headerName: 'CLASS',
            renderEditCell: SelectClassEditInputCell,
            editable: editItemList && editbleData,
            minWidth: 70,
            disableColumnMenu: true,
            headerAlign: 'center',
            sortable: false,
            flex: 0.3,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, cursor: "pointer", color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 15)}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'customer',
            headerName: 'CUSTOMER',
            renderEditCell: SelectCustomerEditInputCell,
            editable: editItemList && editbleData,
            minWidth: 100,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false,
            flex: 0.4,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, cursor: "pointer", color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 11)}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "QTY",
            field: "qty",
            minWidth: 20,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.2,
            valueGetter: getQuantity,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "UNIT",
            field: "unit",
            minWidth: 40,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.3,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "PRICE",
            field: "price",
            minWidth: 55,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getPriceProduct,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "TOTAL",
            field: "total",
            headerAlign: 'center',
            minWidth: 55,
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getTotalProduct,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
    ];

    const cetagorycolumnsWithoutClassAction = [
        {
            field: 'category',
            headerName: 'CATEGORY',
            renderEditCell: SelectCategoryEditInputCell,
            editable: editItemList && editbleData,
            sortable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            minWidth: 120,
            flex: 0.4,
            renderCell: (params) => (
                params?.row?.proderror === true ?
                    <Tooltip title={params?.row?.proderrorMsg} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", color: '#7b809a', padding: 2
                                }, cursor: "pointer", color: '#7b809a', padding: 2
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>
                    :
                    <Tooltip title={params?.formattedValue} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", color: '#7b809a'
                                }, cursor: "pointer", color: '#7b809a'
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>),
        },
        {
            field: 'item',
            headerName: 'DESCRIPTION',
            minWidth: 120,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false,
            flex: 0.5,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 15)}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'customer',
            headerName: 'CUSTOMER',
            renderEditCell: SelectCustomerEditInputCell,
            editable: editItemList && editbleData,
            minWidth: 100,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false,
            flex: 0.4,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, cursor: "pointer", color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 11)}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "QTY",
            field: "qty",
            minWidth: 20,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.2,
            valueGetter: getQuantity,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "UNIT",
            field: "unit",
            minWidth: 40,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.3,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "PRICE",
            field: "price",
            minWidth: 55,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getPriceProduct,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "TOTAL",
            field: "total",
            headerAlign: 'center',
            minWidth: 55,
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getTotalProduct,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
    ];

    const cetagorycolumnsWithoutClass = [
        {
            field: 'category',
            headerName: 'CATEGORY',
            renderEditCell: SelectCategoryEditInputCell,
            editable: editItemList && editbleData,
            sortable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            minWidth: 120,
            flex: 0.4,
            renderCell: (params) => (
                params?.row?.proderror === true ?
                    <Tooltip title={params?.row?.proderrorMsg} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", color: '#7b809a', padding: 2
                                }, cursor: "pointer", color: '#7b809a', padding: 2
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>
                    :
                    <Tooltip title={params?.formattedValue} placement="top">
                        <MDTypography
                            sx={{
                                '@media (max-width: 1440px)': {
                                    fontSize: "0.775rem", color: '#7b809a'
                                }, cursor: "pointer", color: '#7b809a'
                            }}
                        > {textEllipsis(params?.formattedValue, 18)}</MDTypography>
                    </Tooltip>
            ),
        },
        {
            field: 'item',
            headerName: 'DESCRIPTION',
            minWidth: 120,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false,
            flex: 0.5,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 15)}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'customer',
            headerName: 'CUSTOMER',
            renderEditCell: SelectCustomerEditInputCell,
            editable: editItemList && editbleData,
            minWidth: 100,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false,
            flex: 0.4,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", color: '#7b809a'
                            }, cursor: "pointer", color: '#7b809a'
                        }}
                    > {textEllipsis(params?.formattedValue, 11)}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "QTY",
            field: "qty",
            minWidth: 20,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.2,
            valueGetter: getQuantity,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "UNIT",
            field: "unit",
            minWidth: 40,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            flex: 0.3,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "PRICE",
            field: "price",
            minWidth: 75,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getPriceProduct,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            headerName: "TOTAL",
            field: "total",
            headerAlign: 'center',
            minWidth: 75,
            sortable: false,
            disableColumnMenu: true,
            valueGetter: getTotalProduct,
            flex: 0.2,
            renderCell: (params) => (
                <Tooltip title={params?.formattedValue} placement="top">
                    <MDTypography
                        sx={{
                            '@media (max-width: 1440px)': {
                                fontSize: "0.775rem", cursor: "pointer", color: '#7b809a'
                            }, color: '#7b809a'
                        }}
                    > {params?.formattedValue}</MDTypography>
                </Tooltip>),
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'ACTIONS',
            maxWidth: 75,
            flex: 0.27,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    documentStatus === 'Synced' || isSynced === true
                        ?
                        <>
                            <GridActionsCellItem
                                icon={<EditIcon />}
                                label="Edit"
                                className="textPrimary"
                                onClick={(value) => {
                                    setOpenQboSync(true);
                                }}
                                color="inherit"
                            />
                            <GridActionsCellItem
                                icon={<DeleteIcon />}
                                label="Delete"
                                onClick={(value) => {
                                    setOpenQboSync(true);
                                }}
                                color="inherit"
                            />
                        </>
                        :
                        <>
                            <GridActionsCellItem
                                icon={<EditIcon />}
                                label="Edit"
                                className="textPrimary"
                                onClick={() => {
                                    editbleData === true ?
                                        setEditItemId(id) ||
                                        handleEditItem()
                                        :
                                        setOpenQboSync(true);
                                }}
                                color="inherit"
                            />
                            <GridActionsCellItem
                                icon={<DeleteIcon />}
                                label="Delete"
                                onClick={(value) => {
                                    totalItemLength === 1 ? setOneItemPopup(true) :
                                        editbleData === true ?
                                            warningWithConfirmMessage() ||
                                            setItemId(id)
                                            :
                                            setOpenQboSync(true);
                                }}
                                color="inherit"
                            />
                        </>
                ]
            }
        },
        {
            field: 'askClients',
            type: 'actions',
            headerName: 'ASK CLIENT',
            maxWidth: 86,
            flex: 0.35,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const result = itemListResponse?.data?.data?.records.map((prop, key) => {
                    {
                        return {
                            id: prop?._id,
                            item: prop?.description,
                            qty: prop?.quantity,
                            unit: prop?.unitOfMeasure,
                            price: prop?.unitPrice,
                            total: prop?.total,
                            sku: prop?.sku,
                            unitPrice: prop?.unitPrice,
                            results: prop?.results,
                            askClients: prop?.askClient,
                            category: prop?.category ? prop?.category.name : '',
                            categoryId: prop?.category ? prop?.category._id : '',
                            class: prop?.class ? prop?.class.name : '',
                            product: prop?.product ? prop?.product.name : '',
                            productId: prop?.product ? prop?.product._id : '',
                            classId: prop?.class ? prop?.class._id : '',
                            customer: prop?.customer ? prop?.customer.name : '',
                            customerId: prop?.customer ? prop?.customer._id : '',
                        }
                    }
                });
                const found = result.find(obj => {
                    return obj.id === id;
                });

                return [
                    <input
                        type="checkbox"
                        id={id}
                        title={selectTab !== "Classify" ? "Document must be in classify in order to Ask Client." : ''}
                        disabled={selectTab === "Classify" ? false : true}
                        onChange={() => toggleCheck(id)}
                        checked={found?.askClients}
                    />
                ];
            },
        },
    ];

    const dataTableData = {
        columns: [
            {
                Header: "Category",
                accessor: "category",
                // renderEditCell: renderCategorySelectEditInputCell,
                editable: editbleData,
                width: 90,
                // Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Description",
                accessor: "item",
                width: 90,
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Class",
                accessor: "class",
                width: 90,
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Customer",
                accessor: "customer",
                width: 90,
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            // {
            //     Header: "Qty",
            //     accessor: "qty",
            //     Cell: ({ value }) => <DefaultCell value={value} />,
            // },
            {
                Header: "Price",
                accessor: "total",
                width: 90,
                Cell: ({ value }) => <DefaultCell value={currencySymbol + value} />,
            },
            {
                Header: "Actions",
                accessor: "action",
                width: 90,
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Ask Client",
                accessor: "askClient",
                width: 90,
                Cell: ({ value }) => <DefaultCell value={value} />
            },
        ],
        rows: itemList
    }
    const dataTableData1 = {
        columns: [
            {
                Header: "Products/Service",
                accessor: "product",
                width: 90,
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Description",
                accessor: "item",
                width: 90,
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Class",
                accessor: "class",
                width: 90,
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Customer",
                accessor: "customer",
                width: 90,
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Qty",
                accessor: "qty",
                width: 90,
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Unit",
                accessor: "unit",
                width: 90,
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Price",
                accessor: "price",
                width: 90,
                Cell: ({ value }) => <DefaultCell value={currencySymbol + value} />,
            },
            {
                Header: "Total",
                accessor: "total",
                width: 90,
                Cell: ({ value }) => <DefaultCell value={currencySymbol + value} />,
            },
            {
                Header: "Actions",
                accessor: "action",
                width: 90,
                Cell: ({ value }) => <DefaultCell value={value} />,
            },
            {
                Header: "Ask Client",
                accessor: "askClient",
                Cell: ({ value }) => <DefaultCell value={value} />
            },
        ],
        rows: itemList
    }

    const InvoiceEditSchema2 = Yup.object().shape({
        name: Yup.string()
            .trim()
            .required('Comment is required.')
            .min(3, 'Comment should be more than 3 characters.'),
    });

    eventBus.$on('reloadDetailsTab', (data) => reloadDetailsTab(data));
    function reloadDetailsTab(data) {
        setShowDetailsForm(false);
        setOpenAddItem(false);
        setLoading(true);
        DetailsUserAPICall({
            payload: [{ "InvoiceId": `${invoice_id}`, "businessId": `${businessId}` }]
        })
    }

    eventBus.$on('closeAddNewItemPopup', (data) => fetchNewInvoicesAdd(data));

    function fetchNewInvoicesAdd(data) {
        setOpenAddItem(false);
        setLoading(true);
        setOpenCategoryAddItem(false);
        GetItemListListAPICall({
            payload: [{ "InvoiceId": `${invoice_id}` }]
        })
        DetailsUserAPICall({
            payload: [{ "InvoiceId": `${invoice_id}`, "businessId": `${businessId}` }]
        })
    }

    eventBus.$on('closeEditItemPopup', (data) => fetchNewInvoices(data));
    function fetchNewInvoices(data) {
        setOpenEditItem(false);
        setOnIssueClick1(false);
        setLoading(true);
        GetItemListListAPICall({
            payload: [{ "InvoiceId": `${invoice_id}` }]
        })
        DetailsUserAPICall({
            payload: [{ "InvoiceId": `${invoice_id}`, "businessId": `${businessId}` }]
        })
    }

    eventBus.$on('AddnewCategoryComplete', (data) => AddnewCategoryComplete(data));
    function AddnewCategoryComplete(data) {
        CategoryListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
    }

    eventBus.$on('AddnewCustomerComplete', (data) => AddnewCustomerComplete(data));
    function AddnewCustomerComplete(data) {
        CustomerListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
    }


    eventBus.$on('AddnewClassComplete', (data) => AddnewClassComplete(data));
    function AddnewClassComplete(data) {
        ClassesListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
    }

    eventBus.$on('AddnewProductComplete', (data) => AddnewProductComplete(data));
    function AddnewProductComplete(data) {
        ProductsListAPICall({
            payload: [{ "businessId": `${businessId}` }]
        });
    }




    // commented this code as discounted value is not there and total amount are provided in api after discount
    useEffect(() => {
        let taxNow = (typeof (taxStore) == 'string') ? taxStore.replaceAll(',', '') : taxStore;
        let subTotalNow = (typeof (subTotStore) == 'string') ? subTotStore.replaceAll(',', '') : subTotStore;
        let discountNow = (typeof (discountStore) == 'string') ? discountStore.replaceAll(',', '') : discountStore;
        let shippingPriceNow = (typeof (shippingpriceStore) == 'string') ? shippingpriceStore.replaceAll(',', '') : shippingpriceStore;
        let orderTotalNow = (typeof (orderTotalStore) == 'string') ? orderTotalStore.replaceAll(',', '') : orderTotalStore;
        const finaldiscountNow = discountNow ? discountNow : 0;
        const MyTotal = (Number.parseFloat(subTotalNow, 10) - Number.parseFloat(finaldiscountNow, 10));
        const finalshippingPriceNow = shippingPriceNow ? shippingPriceNow : 0;
        const finaltaxNow = taxNow ? taxNow : 0;

        if (onChanges === true) {
            const timeOutId = setTimeout(async () =>
                setTotalStore(MyTotal + Number.parseFloat(finaltaxNow, 10) + Number.parseFloat(finalshippingPriceNow, 10)),
                setTotal(MyTotal + Number.parseFloat(finaltaxNow, 10) + Number.parseFloat(finalshippingPriceNow, 10)),
                setOrderTotal(Number.parseFloat(orderTotalNow, 10) + Number.parseFloat(finaltaxNow, 10)),
                500);
            return () => clearTimeout(timeOutId);
        }

    }, [taxStore, subTotStore, discountStore, orderTotalStore, orderTotal, shippingpriceStore]);
    // }, [TotalStore, taxStore, subTotStore, tax, subtotal, total]);

    const getFormattedNumber = (n) => {
        let unFormatted = (typeof (n) == 'string') ? n.replaceAll(',', '') : n;
        const numberFormatter = Intl.NumberFormat('en-US',

            {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        const formatted = numberFormatter.format(unFormatted);
        return formatted;
    }

    const handleChangeBusiness = (event, newValue) => {
        if (newValue === '+ Add Vendor') {
            setVendorSelect(vendorSelect);
        } else {
            setVendorSelect1(newValue);
        }
        setAddNewVendor(false);
        setVendorname2('');
        if (newValue == '+ Add Vendor') {
            setOpenEditVendor(true);
        } else {
            if (newValue) {
                setVendorerrormsg('');
                setVendorerror(false);
            } else {
                setVendorerrormsg('Vendor name required');
                setVendorerror(true);
            }
            const found = vendorList.find(obj => {
                return obj.name === newValue;
            });
            if (found) {
                setShowselectedVendorId(found._id);
            } else {
                setShowselectedVendorId('');
            }
        }
    };

    useEffect(() => {
        setStyles(value === 2 || value === 3 ? { display: 'grid', overflowY: 'scroll', overflowX: 'scroll', height: '80%' } : { height: '80%', overflow: 'auto' })
    }, [value])


    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    const QboSyncMsgAskClient = () => {
        return <MDBox sx={{ ml: 8, mb: -1 }}>
            <MDTypography >
                Are you sure want to proceed with sending them to the ask client?
            </MDTypography>
        </MDBox>
    }

    const AsignNewClientMsg = () => {
        return <MDBox sx={{ ml: 4, mb: -1 }}>
            <MDTypography >
                No email has been associated with this client account.
            </MDTypography>
            <MDTypography display='flex' justifyContent='center'>
                Click here to add and email for this client
            </MDTypography>
        </MDBox>
    }

    useEffect(() => {
        if (createObjectURL) {
            setExtention(createObjectURL.split(".").pop())
        }
    }, [createObjectURL])
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { CurrentPageLabel } = pageNavigationPluginInstance;

    const QboSyncMsg = () => {
        return <MDBox sx={{ ml: 1, mb: -1 }}>
            <MDTypography >
                We are not allowed to update once they have been synced
            </MDTypography>
            <MDTypography sx={{ display: 'flex', justifyContent: 'center' }}>
                to quickbooks.
            </MDTypography>
        </MDBox>
    }
    useEffect(() => {
        !editable ? setDottedStyle({
            borderBottom: `dotted 0.8px`,
            marginTop: "23px",
            appearance: "none",
            borderTop: "0px",
            borderLeft: "0px",
            borderRight: "0px",
            color: "black",
            fontSize: '1rem',
            width: '100%',
        }) : setDottedStyle({
            marginTop: "23px",
            borderTop: "0px",
            borderLeft: "0px",
            borderRight: "0px",
            color: "black",
            fontSize: '1rem',
            width: '100%',
        })
    }, [editable])

    const hideMoveAlert = () => {
        setalertMoveInvoice(false);
    };

    useEffect(() => {
        if (issues?.documents?.count === 0 && issues?.items?.count === 0 && issues?.vendor?.count === 0) {
            setCheckIssue(false);
        } else {
            setCheckIssue(true);
        }
    }, [issues])

    const tabRowsDataToReview =
        [
            {
                key: {
                    AskClient: "Ask Client",
                    ToReview: 'To Review',
                    Excluded: 'Excluded',
                },
                Tab: 'Classify',
                Permission: ["Admin", "Manager", "User"]
            },
            {
                key: {
                    Classify: 'Classify',
                },
                Tab: 'AskClient',
                Permission: ["Admin", "Manager"]
            },
            {
                key: {
                    Classify: 'Classify',
                    PendingApproval: 'Pending Approval',
                },
                Tab: 'ToReview',
                Permission: ["Admin", "Manager"]
            },
            {
                key: {
                    Classify: 'Classify',
                    Approved: 'Approved',
                },
                Tab: 'PendingApproval',
                Permission: ["Admin", "Manager"]
            },
            {
                key: {
                    Classify: 'Classify',
                },
                Tab: 'Approved',
                Permission: ["Admin", "Manager"]
            },
            {
                key: {
                    Classify: 'Classify',
                },
                Tab: 'Excluded',
                Permission: ["Admin", "Manager"]
            },
            {
                key: {

                },
                Tab: 'AllDoc',
                Permission: [""]
            },
        ]

    const tabRowsData =
        [
            {
                key: {
                    AskClient: "Ask Client",
                    Excluded: 'Excluded',
                },
                Tab: 'Classify',
                Permission: ["Admin", "Manager", "User"]
            },
            {
                key: {
                    Classify: 'Classify',
                },
                Tab: 'AskClient',
                Permission: ["Admin", "Manager"]
            },
            {
                key: {
                    Classify: 'Classify',
                    PendingApproval: 'Pending Approval',
                },
                Tab: 'ToReview',
                Permission: ["Admin", "Manager"]
            },
            {
                key: {
                    Classify: 'Classify',
                    Approved: 'Approved',
                },
                Tab: 'PendingApproval',
                Permission: ["Admin", "Manager"]
            },
            {
                key: {
                    Classify: 'Classify',
                },
                Tab: 'Approved',
                Permission: ["Admin", "Manager"]
            },
            {
                key: {
                    Classify: 'Classify',
                },
                Tab: 'Excluded',
                Permission: ["Admin", "Manager"]
            },
            {
                key: {

                },
                Tab: 'AllDoc',
                Permission: [""]
            },
        ]
    const finaltabRowsData = checkIssue ? tabRowsData : tabRowsDataToReview;
    const moveSelect = finaltabRowsData.find(obj => {
        return obj.Tab === documentStatus ? obj.Tab === documentStatus : obj.Tab === 'AllDoc';
    });

    const openMenu = (event) => {
        setAnchor(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchor(null);
    };

    const onButtonItemClick = async (move) => {
        const selectMoveTab = (move).replace(/ /g, "");
        if (ownerAssigned === false && selectMoveTab === 'AskClient') {
            setAssignNewClient(true);
        } else {
            setTabsMove(selectMoveTab);
            setalertMoveInvoice(true);
        }

    };

    const onMenuItemClick = async (event, key) => {
        if (selectTab === "Approved") {
            setShowApprovedModel(true);
        } else {
            setAnchor(null);
            const selectMoveTab = (event.target.textContent).replace(/ /g, "");
            if (ownerAssigned === false && selectMoveTab === 'AskClient') {
                setAssignNewClient(true);
            } else {
                setTabsMove(selectMoveTab);
                setalertMoveInvoice(true);
            }
        }
    };

    const moveOptionName = {
        Classify: 'Classify',
        AskClient: 'Ask Client',
        ToReview: 'To Review',
        PendingApproval: 'Pending Approval',
        Approved: 'Approved',
        Excluded: 'Excluded',
        Synced: 'Quickbook',
    }

    useEffect(() => {
        Object.keys(moveOptionName).map(function (k) {
            k === documentStatus ? setFinalSelect(moveOptionName[k]) : ''
        })
    }, [documentStatus]);


    const handleClickImgDownload = () => {
        window.open(`${createObjectURL}`, '_blank');
    }

    async function createFile() {
        if (imageUrl && counts === 0) {
            setCounts(counts + 1)
            let response = await fetch(imageUrl);
            let data = await response.blob();
            let metadata = {
                type: 'image/jpeg'
            };
            let file = new File([data], "test.jpg", metadata);
            const imageDataUrl = URL.createObjectURL(file);
            setImageUrl1(imageDataUrl)
        }
    }
    useEffect(() => {
        createFile()
    }, [imageUrl]);


    const Controls = () => {
        const { zoomIn, zoomOut } = useControls();
        return (
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '23px', marginTop: '-23px', marginRight: '100px' }} >
                <a style={{ display: 'flex', justifyContent: 'center', marginLeft: '100px' }} >
                    <img onClick={() => zoomOut()} style={{ marginRight: '20px', cursor: 'pointer', marginBottom: '-6px' }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA+0lEQVR4nLXTTSvFQRTH8c9FKAsLS9lYWSgpG5dXwC1ZKTuLKwsbpWxEnp+SDStLKRsskYW8IW+Bpob+6c7/zs31q9Oc5kzfc+bMGf5RQzjFK57ieomRViCLeMEUKoX9MTxgLQdSww26EvEKzlAvg/TgHb1NknXEigdSB2axnlM2FrCSCm6hGv0+DDew/sJjXKdAhxiP/iiOG9h0jIdr3aZAy7HkHFWxlwoO4j4TdIWJsgMXmG8CmcRds0zdeMTSr2H81hw+cC5DYRg38IYjrGIXz9jECT6xL1OdsQ+12NzioB60CivTToSFz/1nhREIsO12wAJkph2gH30BrGgnHq5WuyEAAAAASUVORK5CYII=" title="ZoomIn" />
                </a>
                <a style={{ display: 'flex', justifyContent: 'center' }} >
                    <img onClick={() => zoomIn()} style={{ marginRight: '20px', cursor: 'pointer', marginBottom: '-6px' }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAABCElEQVR4nLXTTyuFQRTH8c9FKMrCUjZWFkrKxuUVcEtWyu4uyMJGKQsi//8lG1aWUjZYIgvxQrwEb4Gmhp6e7lzPzfWr05zzm/o2Z+YM/6h+HOMZD3E9x2AjkDk8YRyljD+MOywXgVRwhbbEfgknmK8H6cArOnP+Wq5uiSfuTYGmsFLDf6zhzWIxBdpAOeZdGIjxlsl7Mo9xmQLtYyTmQziM8Z7JJ+J+aOs6BVqIRy7SWhk7KVAfbguCLjCqjs4wk/O6c/UYbvyidtyjmhvGb03jA6cKKAzjKl5wgCVsxxbXcYRP7Cqo1ngPlXi52UHdaxRWT1sRFj73nxVGIMA2mwELkMlmgH70Bf8ZKsMW9hShAAAAAElFTkSuQmCC" title="ZoomIn" />
                </a>
            </div>
        );
    };


    const ContainerHeight = 800
    const ContainerWidth = 900
    return (
        <>
            {
                !showDetails ?
                    <>
                        <MDBox mt={'-10%'} display="flex" justifyContent="center" >
                            {<LoaderDailog />}
                        </MDBox>
                    </> :
                    <>
                        <MDBox mb={3} display={'flex'}>
                            <Typography variant="h6" className={classes.title} >
                                <MDBox display={'flex'}>
                                    <MDTypography fontSize={'1rem'} >Document in</MDTypography>
                                    <MDTypography sx={{ ml: 0.5 }} fontSize={'1rem'} fontWeight={'bold'} >{finalSelect ? finalSelect : documentStatus}</MDTypography>
                                    <MDBox>
                                        {ocrStatus1 !== 'COMPLETED' || documentSource === 'QBO' ? '' :
                                            <Tooltip title={action.Movetooltip}>
                                                <IconButton
                                                    onClick={openMenu}
                                                    sx={{ mt: -0.3 }}
                                                    aria-controls={openMenu ? 'account-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={openMenu ? 'true' : undefined}
                                                >
                                                    <DriveFileMoveOutlinedIcon sx={{ height: `0.7em`, width: `1em`, mb: 1 }} />
                                                </IconButton>
                                            </Tooltip>}
                                        <Menu
                                            open={Boolean(anchor)}
                                            anchorEl={anchor}
                                            onClose={closeMenu}
                                            keepMounted
                                        >
                                            {
                                                Object.keys(moveSelect.key).map((key, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        onClick={(event) => onMenuItemClick(event, key)}
                                                    // selected={index === selected}
                                                    >
                                                        {moveSelect.key[key]}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Menu>
                                    </MDBox>
                                </MDBox>
                            </Typography>
                            <MDBox>
                                <Icon sx={{ cursor: "pointer", mr: 2 }} onClick={reloadApiAfterClose}>close</Icon>
                            </MDBox>
                        </MDBox>
                        <MDBox mb={3} style={{ height: '95%', overflow: 'auto', display: 'block', marginBottom: 0 }}>
                            <Grid container style={{ height: '100%', overflow: 'auto' }}>
                                {createObjectURL ?
                                    <Grid item xs={12} sm={4} lg={4.5} ml={-2} style={{ height: '100%', overflow: 'auto' }}>
                                        {
                                            extention === 'pdf' ?
                                                <div
                                                    className="rpv-core__viewer"
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        height: '97%',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            alignItems: 'center',
                                                            backgroundColor: '#eeeeee',
                                                            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                                            display: 'flex',
                                                            padding: '0.25rem',
                                                        }}
                                                    >
                                                        <Toolbar>
                                                            {(props) => {
                                                                const {
                                                                    CurrentPageInput,
                                                                    NumberOfPages,
                                                                    ZoomIn,
                                                                    ZoomOut,
                                                                    EnterFullScreen,
                                                                    Download,
                                                                } = props;
                                                                return (
                                                                    <>
                                                                        <div style={{ padding: '0px 2px', width: '4rem', marginLeft: '10px' }}>
                                                                            <CurrentPageInput />
                                                                        </div>
                                                                        <div style={{ padding: '0px 2px' }}>
                                                                            of <NumberOfPages />
                                                                        </div>
                                                                        <div style={{ padding: '0px 2px', marginLeft: '35%' }}>
                                                                            <ZoomOut />
                                                                        </div>
                                                                        <div style={{ padding: '0px 2px', marginLeft: '10px' }}>
                                                                            <ZoomIn />
                                                                        </div>
                                                                        <div style={{ padding: '0px 2px', display: 'flex', marginLeft: 'auto' }}>
                                                                            <Download />
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                alignItems: 'center',
                                                                                display: 'flex',
                                                                                justifyContent: "end",
                                                                                paddingBottom: '2px',
                                                                                marginLeft: '8px'
                                                                            }}
                                                                        >
                                                                            <img style={{ marginRight: '20px', cursor: 'pointer' }} width="19" height="19" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAjUlEQVR4nO2VQQqAIBBF3yUmOlJXqePmor0dxAgMhGoc01b1YHYz/4kMCj+FDMAKBKUOLD0nfGYw1AqyDRcIML8l6AAXZ1xrgSQnX4C+pUAuwmklkJvwJgJRwk1oAqkN1wRiDH90RVJw8keCKdnzfe9L500NoyG8SmDlAwJveK5DpvYM9cOpkfiY8YOZDdq3j/znzI3XAAAAAElFTkSuQmCC" alt="fullscreen" onClick={() => window.open(`${createObjectURL}`, '_blank')
                                                                            } title="Full Screen" /></div>
                                                                    </>
                                                                );
                                                            }}
                                                        </Toolbar>
                                                    </div>
                                                    <div
                                                        style={{
                                                            flex: 1,
                                                            overflow: 'hidden',
                                                        }}
                                                    >
                                                        {extention === 'pdf' && <Viewer fileUrl={createObjectURL} plugins={[toolbarPluginInstance]} />
                                                        }

                                                    </div>
                                                </div>
                                                :
                                                <div
                                                    className="rpv-core__viewer"
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        height: '97%',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            alignItems: 'center',
                                                            backgroundColor: 'transparent',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        <CurrentPageLabel>
                                                            {(props) => (
                                                                <span>{`${props.currentPage + 1} of ${props.numberOfPages}`}</span>
                                                            )}
                                                        </CurrentPageLabel>
                                                    </div>
                                                    <div
                                                        style={{
                                                            alignItems: 'center',
                                                            backgroundColor: '#eeeeee',
                                                            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                                            display: 'flex',
                                                            justifyContent: "end",
                                                            padding: '0.25rem',
                                                        }}
                                                    >
                                                        <a href={imageUrl1} download={"downloaded-image.jpeg"} >
                                                            <img style={{ marginRight: '20px', cursor: 'pointer', marginBottom: '-6px' }} width="18" height="18" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAfklEQVR4nO2SUQqAIBAF5xJK3bcumB9eJb8MwQUTC8ItiBpYUFbeyCp8hRkIQMyV1pOmIBThUqumIOY62v8C/hHtMMDS+JaxONPqeWCgUyJ0hbckHhhPepfDzyRGK1ywgMuBrlqnngqmehOVmx9J/B3hgtUcy/uIyvW8gFeyAeoqduPSsPEqAAAAAElFTkSuQmCC" title="Download" />
                                                        </a>
                                                        <img style={{ marginRight: '20px', cursor: 'pointer' }} width="19" height="19" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAjUlEQVR4nO2VQQqAIBBF3yUmOlJXqePmor0dxAgMhGoc01b1YHYz/4kMCj+FDMAKBKUOLD0nfGYw1AqyDRcIML8l6AAXZ1xrgSQnX4C+pUAuwmklkJvwJgJRwk1oAqkN1wRiDH90RVJw8keCKdnzfe9L500NoyG8SmDlAwJveK5DpvYM9cOpkfiY8YOZDdq3j/znzI3XAAAAAElFTkSuQmCC" alt="fullscreen" onClick={handleClickImgDownload} title="Full Screen" />
                                                    </div>
                                                    <div>
                                                        <TransformWrapper
                                                            defaultScale={1}
                                                            defaultPositionX={0}
                                                            defaultPositionY={0}
                                                        >
                                                            <Controls />
                                                            <TransformComponent contentStyle={{
                                                                // '@media (max-width: 1440px)': {
                                                                //     fontWeight: `0  !important`,
                                                                //     fontSize: `0.775rem`
                                                                // },
                                                                width: '100% !important', height: '110%', marginBottom: '100px', marginLeft: '10px', marginRight: '10px'
                                                            }}>
                                                                <img
                                                                    src={createObjectURL}
                                                                    alt="test"
                                                                    width="100%"
                                                                    height="100%"
                                                                />
                                                            </TransformComponent>
                                                        </TransformWrapper>
                                                    </div>
                                                    {/* <div>
                                                        {/(jpg|jpeg|png)$/.test(extention) && <img src={createObjectURL}
                                                            width={`100%`}
                                                            height={'100%'}
                                                        />}
                                                    </div> */}
                                                </div>
                                        }


                                    </Grid>
                                    :
                                    <Grid item xs={12} sm={4} lg={4.5} ml={-2} style={{ height: '100%', overflow: 'auto' }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                height: '100%',
                                                border: '0.5px solid gray'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    flex: 1,
                                                    overflow: 'hidden',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    marginTop: '50%'
                                                }}
                                            >
                                                <h2>{documentSource === 'QBO' ?
                                                    'No image available from Quickbooks' : 'No image available from Paynthr'}</h2>
                                            </div>
                                        </div>
                                    </Grid>
                                }
                                <Grid item xs={12} sm={8} lg={7.5} style={{ height: '100%', overflow: 'auto', }}>
                                    <Card style={{ height: '100%', overflow: 'auto', }}>
                                        <Tabs value={value} onChange={handleChange} aria-label="tabs">
                                            <Tab label={issues?.documents ? (<MDBadge
                                                overlap="circular"
                                                badgeContent={issues?.documents?.count}
                                                sx={{
                                                    "& .MuiBadge-badge": {
                                                        color: '#ef5350',
                                                        background: 'transparent'
                                                    },
                                                }}
                                            >Details &nbsp;  &nbsp; &nbsp;</MDBadge>) : 'Details'} {...a11yProps(0)} />
                                            <Tab label={issues?.items ? (<MDBadge overlap="circular" badgeContent={issues?.items?.count} sx={{
                                                "& .MuiBadge-badge": {
                                                    color: '#ef5350',
                                                    background: 'transparent '
                                                },
                                            }}>Items &nbsp;  &nbsp; &nbsp;</MDBadge>) : 'Items'} {...a11yProps(1)} />
                                            <Tab label="Audit Log" {...a11yProps(2)} />
                                            <Tab label="Comments" {...a11yProps(3)} />
                                            <div>
                                                {canAccess(actions = 'update', resource = 'invoices') &&
                                                    <MDButton
                                                        id="fade-button"
                                                        aria-controls={open ? 'fade-menu' : undefined}
                                                        aria-haspopup="true"
                                                        aria-expanded={open ? 'true' : undefined}
                                                        onClick={handleClick}
                                                        color="info"
                                                    >More <Icon sx={{ ml: 0.8 }}>
                                                            expand_more
                                                        </Icon>
                                                    </MDButton>
                                                }
                                                <Menu
                                                    id="fade-menu"
                                                    MenuListProps={{
                                                        'aria-labelledby': 'fade-button',
                                                    }}
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    TransitionComponent={Fade}
                                                >
                                                    {/* <MenuItem onClick={handleEditVendor} >Edit Vendor</MenuItem> */}
                                                    {canAccess(actions = 'delete', resource = 'invoices') &&
                                                        documentStatus === 'Synced' || isSynced === true ?
                                                        <MenuItem style={{ color: 'red' }} onClick={() => setOpenQboSync(true)}>Delete Invoice</MenuItem>

                                                        :
                                                        <MenuItem style={{ color: 'red' }} onClick={handleDeleteInvoice}>Delete Invoice</MenuItem>
                                                    }
                                                </Menu>
                                            </div>
                                        </Tabs>
                                        {/* <Divider /> */}
                                        <CardHeader
                                            subheader={
                                                <MDBox>
                                                    <Grid item sx={12} sm={12} md={12} style={{ display: 'flex', marginLeft: '70px', fontSize: '1rem' }}>
                                                        <Grid item sx={12} xs={4}>
                                                            <span style={{ display: 'flex', justifyContent: 'center' }}>Subtotal</span>
                                                            <span style={{ display: 'flex', justifyContent: 'center' }}>{currencySymbol}{actualSubtotal}</span>
                                                        </Grid>
                                                        <Grid item sx={12} xs={4}>
                                                            <span style={{ display: 'flex', justifyContent: 'center' }}>Taxes & Fees</span>
                                                            <span style={{ display: 'flex', justifyContent: 'center' }}>{currencySymbol}{(taxStore) ? getFormattedNumber(taxStore) : getFormattedNumber(tax)}</span>
                                                        </Grid>
                                                        <Grid item sx={12} xs={4}>
                                                            <span style={{ display: 'flex', justifyContent: 'center' }}>Total</span>
                                                            <span style={{ display: 'flex', justifyContent: 'center' }}>{currencySymbol}{actualTotal}</span>
                                                        </Grid>
                                                    </Grid>
                                                    {
                                                        ocrStatus ? <MDBox sx={{ mb: -2, mt: 1, display: 'flex', justifyContent: 'center' }}>
                                                            <MDTypography fontSize={'1rem'} color={'warning'} >Modifications cannot be made during an ongoing document upload process.</MDTypography>
                                                        </MDBox> : ''
                                                    }

                                                </MDBox>
                                            }
                                        />
                                        <Divider />
                                        <CardContent style={styles}>
                                            <TabPanel value={value} index={0}>
                                                {showDetailsForm &&
                                                    <MDBox my={2}  >
                                                        <Box >
                                                            <Grid item xs={12} sm={12} md={12}>
                                                                <Formik
                                                                    enableReinitialize
                                                                    initialValues={initialValues}
                                                                    validationSchema={InvoiceEditSchema}
                                                                    onSubmit={handleSubmit}
                                                                >
                                                                    {({ values,
                                                                        errors,
                                                                        touched,
                                                                        handleChange,
                                                                        handleBlur,
                                                                        handleSubmit,
                                                                        isSubmitting,
                                                                        setFieldValue }) => (
                                                                        <Form autoComplete="off">
                                                                            {/* <Card sx={{ height: "100%" }}> */}
                                                                            <Grid container spacing={3}>
                                                                                <Grid item xs={12} sm={6}>
                                                                                    <Autocomplete
                                                                                        disableClearable
                                                                                        sx={{
                                                                                            "& .MuiInputBase-input.Mui-disabled": {
                                                                                                WebkitTextFillColor: "black",
                                                                                            },
                                                                                        }}
                                                                                        // noOptionsText={<MDTypography
                                                                                        //     sx={{ fontSize: "0.775rem", cursor: 'pointer' }}
                                                                                        //     color="info"
                                                                                        //     onClick={() => setOpenEditVendor(true)}
                                                                                        // >
                                                                                        //     + Add Vendor
                                                                                        // </MDTypography>}
                                                                                        noOptionsText={<MDTypography
                                                                                            sx={{ fontSize: "0.775rem", cursor: 'pointer' }}
                                                                                            color="info"
                                                                                            onClick={handleAddNewVendor}
                                                                                        >
                                                                                            {vendorname + " " + `(New Vendor)`}
                                                                                        </MDTypography>}
                                                                                        disableCloseOnSelect={false}
                                                                                        // classes={{ option: classes.option }}
                                                                                        forcePopupIcon={editable ? true : false}
                                                                                        options={finalSelect == 'Ask Client' || finalSelect == 'Classify' || documentStatus == 'AskClient' || documentStatus == 'Classify' ? vendorList : vendorList}
                                                                                        getOptionLabel={(option) => option.name}
                                                                                        value={{ name: vendorname2 ? vendorname2 : vendorname }}
                                                                                        onInputChange={(event, newInputValue) => {
                                                                                            setVendorname(newInputValue),
                                                                                                handleChangeBusiness(event, newInputValue);
                                                                                        }}
                                                                                        id="controllable-states-demo"
                                                                                        disabled={!editable}
                                                                                        renderInput={(params) => (
                                                                                            vendorerror || novendorIssue && !editable ?
                                                                                                <TextField {...params}
                                                                                                    label="Vendor Name *"
                                                                                                    variant="standard"
                                                                                                    helperText={vendorerrormsg}
                                                                                                    error={vendorerror || novendorIssue}
                                                                                                    InputProps={{
                                                                                                        endAdornment: (
                                                                                                            <Tooltip title={novendorIssueMsg} placement="top">
                                                                                                                <InputAdornment position="end">
                                                                                                                    <ErrorOutlineIcon sx={{ fontSize: '1rem !important', mr: '14px', color: 'red' }} />
                                                                                                                </InputAdornment>
                                                                                                            </Tooltip>
                                                                                                        ),
                                                                                                    }}

                                                                                                />
                                                                                                :
                                                                                                <TextField {...params}
                                                                                                    label="Vendor Name *"
                                                                                                    variant="standard"
                                                                                                    helperText={vendorerrormsg}
                                                                                                    error={vendorerror || novendorIssue}
                                                                                                />
                                                                                        )}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={6}>
                                                                                    <FormControl
                                                                                        fullWidth
                                                                                        variant="standard"
                                                                                        sx={{ mt: 1, mb: 1 }}
                                                                                    >
                                                                                        <InputLabel
                                                                                            style={{ fontSize: '0.8rem', lineHeight: 2 }}
                                                                                            sx={{ mt: -3 }} >
                                                                                            Document Type
                                                                                        </InputLabel>
                                                                                        <Field
                                                                                            as="select"
                                                                                            name="documenttype"
                                                                                            variant="standard"
                                                                                            disabled={!editable}
                                                                                            style={dottedStyle}
                                                                                        >
                                                                                            <option value="">Select Type</option>
                                                                                            {Object.keys(DocumnetType).map((key) => (
                                                                                                <option value={key
                                                                                                }>{DocumnetType[key]}</option>
                                                                                            ))}
                                                                                        </Field>
                                                                                        <MDBox mt={0.75}>
                                                                                            <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                                                                                <ErrorMessage error={true}
                                                                                                    name="documenttype" />
                                                                                            </MDTypography>
                                                                                        </MDBox>
                                                                                    </FormControl>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container spacing={3}>
                                                                                <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                                                                                    <FormField
                                                                                        type="text"
                                                                                        label="Invoice Number"
                                                                                        name="invoicenumber"
                                                                                        disabled={!editable}
                                                                                        editable={!editable}
                                                                                    // autoFocus={true}
                                                                                    // error={true}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                                                                                    <FormField
                                                                                        type="text"
                                                                                        label="PO Number"
                                                                                        name="ponumber"
                                                                                        // error={true}
                                                                                        disabled={!editable}
                                                                                        editable={!editable}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container spacing={3} >
                                                                                {documentSource === 'QBO' ?
                                                                                    <Grid item xs={12} sm={6} sx={{ mt: 1 }} className={!editable ? 'bg-salmon' : ''}>
                                                                                        <FormFieldDate
                                                                                            type="date"
                                                                                            label="Transaction Date"
                                                                                            name="transactionDate"
                                                                                            // error={true}
                                                                                            disabled={!editable}
                                                                                            editable={!editable}
                                                                                        />
                                                                                    </Grid> :
                                                                                    <Grid item xs={12} sm={6} sx={{ mt: 1 }} className={!editable ? 'bg-salmon' : ''}>
                                                                                        <FormFieldDate
                                                                                            classes={{ root: classes.InputRoot }}
                                                                                            type="date"
                                                                                            label="Transaction Date"
                                                                                            name="invoicedate"
                                                                                            dateMismatch={invoiceDateMismatch}
                                                                                            // error={invoiceDateMismatch}
                                                                                            noInvoiceDateMsg={'invoiceDateMismatch'}
                                                                                            autoFocus={invoiceDateMismatch}
                                                                                            // disabled={!editable}
                                                                                            editable={!editable}
                                                                                        />
                                                                                    </Grid>}
                                                                                <Grid item xs={12} sm={6} sx={{ mt: 1 }} className={'bg-salmon'}>
                                                                                    <FormFieldDate
                                                                                        type="date"
                                                                                        label="Upload Date"
                                                                                        name="uploaddate"
                                                                                        // error={true}
                                                                                        disabled={true}
                                                                                        editable={!editable}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container spacing={3}>
                                                                                <Grid item xs={12} sm={2.4} sx={{ mt: 1 }} >
                                                                                    <CurrencyFieldText
                                                                                        label="Subtotal"
                                                                                        name="subtotal"
                                                                                        ErrorToolTip={priceMismatchMsg}
                                                                                        error={priceMismatch}
                                                                                        priceMismatch={priceMismatch}
                                                                                        disabled={!editable}
                                                                                        value={subtotal}
                                                                                        onValueChange={(event) => {
                                                                                            setSubTotal(event.value)
                                                                                            setSubTotStore(event.value)
                                                                                            SetonChanges(true)
                                                                                        }}
                                                                                    />
                                                                                    <MDBox mt={0.75}>
                                                                                        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                                                                            {subtotalErrorMsg}
                                                                                        </MDTypography>
                                                                                    </MDBox>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={2.4} sx={{ mt: 1 }}>
                                                                                    <CurrencyFieldText
                                                                                        label="Discount"
                                                                                        name="discount"
                                                                                        disabled={!editable}
                                                                                        value={discount}
                                                                                        onValueChange={(event) => {
                                                                                            setDiscount(event.value)
                                                                                            setDiscountStore(event.value)
                                                                                            SetonChanges(true)
                                                                                        }}

                                                                                    />
                                                                                    <MDBox mt={0.75}>
                                                                                        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                                                                            {taxErrorMsg}
                                                                                        </MDTypography>
                                                                                    </MDBox>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={2.5} sx={{ mt: 1 }}>
                                                                                    <CurrencyFieldText
                                                                                        label="Shipping Price"
                                                                                        name="shippingprice"
                                                                                        disabled={!editable}
                                                                                        value={shippingprice}
                                                                                        onValueChange={(event) => {
                                                                                            setShippingprice(event.value)
                                                                                            setShippingpriceStore(event.value)
                                                                                            SetonChanges(true)
                                                                                        }}

                                                                                    />
                                                                                    <MDBox mt={0.75}>
                                                                                        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                                                                            {taxErrorMsg}
                                                                                        </MDTypography>
                                                                                    </MDBox>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={2.4} sx={{ mt: 1 }}>
                                                                                    <CurrencyFieldText
                                                                                        label="Taxes & Fees"
                                                                                        name="tax"
                                                                                        disabled={!editable}
                                                                                        value={tax}
                                                                                        onValueChange={(event) => {
                                                                                            setTax(event.value)
                                                                                            setTaxStore(event.value)
                                                                                            SetonChanges(true)
                                                                                        }}

                                                                                    />
                                                                                    <MDBox mt={0.75}>
                                                                                        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                                                                            {taxErrorMsg}
                                                                                        </MDTypography>
                                                                                    </MDBox>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={2.3} sx={{ mt: 1 }}>
                                                                                    <CurrencyFieldText
                                                                                        label="Total"
                                                                                        name="total"
                                                                                        ErrorToolTip={totalMismatchMsg}
                                                                                        error={totalMismatch}
                                                                                        priceMismatch={totalMismatch}
                                                                                        disabled={!editable}
                                                                                        value={total}
                                                                                        onValueChange={(event) => {
                                                                                            setTotal(event.value)
                                                                                            setTotalStore(event.value)
                                                                                            SetonChanges(true)
                                                                                        }}

                                                                                    />
                                                                                    <MDBox mt={0.75}>
                                                                                        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                                                                                            {totalErrorMsg}
                                                                                        </MDTypography>
                                                                                    </MDBox>

                                                                                </Grid>
                                                                            </Grid>
                                                                            {finalSelect == 'Ask Client' || finalSelect == 'Classify' || documentStatus == 'AskClient' || documentStatus == 'Classify' ?
                                                                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
                                                                                    {documentSource === 'Paynthr' && isSynced === false ?
                                                                                        <MDBox mt={{ xs: 2, sm: 3 }}>
                                                                                            {!editable && canAccess(actions = 'update', resource = 'documents') &&
                                                                                                <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                                                                                                    {selectTab === "AskClient" &&
                                                                                                        <MDButton
                                                                                                            variant="gradient"
                                                                                                            color="info"
                                                                                                            disabled={ocrStatus}
                                                                                                            onClick={() => onButtonItemClick('Classify')}
                                                                                                        >
                                                                                                            Move to Classify
                                                                                                        </MDButton>}
                                                                                                    {selectTab === "Classify" &&
                                                                                                        !showButton && checkIssue ?
                                                                                                        <Tooltip title={`Cannot move to Review until ${totalIssue} issues are resolved`} placement="top">
                                                                                                            <MDBox >
                                                                                                                <MDButton
                                                                                                                    sx={{ marginLeft: '8px' }}
                                                                                                                    variant="gradient"
                                                                                                                    color="info"
                                                                                                                    disabled={true}
                                                                                                                >
                                                                                                                    Move To Review
                                                                                                                </MDButton>
                                                                                                            </MDBox>
                                                                                                        </Tooltip>
                                                                                                        :
                                                                                                        <MDButton
                                                                                                            variant="gradient"
                                                                                                            color="info"
                                                                                                            sx={{ marginLeft: '8px' }}
                                                                                                            disabled={!showButton && checkIssue}
                                                                                                            onClick={() => onButtonItemClick('ToReview')}
                                                                                                        >
                                                                                                            Move To Review
                                                                                                        </MDButton>}
                                                                                                    <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                                                                        <MDButton
                                                                                                            variant="gradient"
                                                                                                            color="info"
                                                                                                            disabled={ocrStatus}
                                                                                                            onClick={() => SetEditable(true)}
                                                                                                        >
                                                                                                            Edit
                                                                                                        </MDButton>
                                                                                                    </MDBox>
                                                                                                </MDBox>
                                                                                            }
                                                                                        </MDBox> :
                                                                                        <MDBox mt={{ xs: 2, sm: 3 }}>
                                                                                            {!editable && canAccess(actions = 'update', resource = 'documents') && <MDButton
                                                                                                variant="gradient"
                                                                                                color="info"
                                                                                                disabled={ocrStatus}
                                                                                                onClick={() => setOpenQboSync(true)}
                                                                                            >
                                                                                                Edit
                                                                                            </MDButton>}
                                                                                        </MDBox>
                                                                                    }
                                                                                    {editable &&
                                                                                        <MDBox mt={4} width="100%" display="flex" justifyContent="end">
                                                                                            {/* {selectTab === "Classify" &&
                                                                                                <MDButton
                                                                                                    type="submit"
                                                                                                    variant="gradient"
                                                                                                    color="info"
                                                                                                    disabled={!showButton && checkIssue}
                                                                                                    onClick={() => onButtonItemClick('ToReview')}
                                                                                                >
                                                                                                    Move to To Review
                                                                                                </MDButton>} */}
                                                                                            <MDButton
                                                                                                sx={{ marginLeft: '8px' }}
                                                                                                disabled={btnDisable}
                                                                                                variant="gradient"
                                                                                                color="light"
                                                                                                onClick={handleClosebtn}
                                                                                            >
                                                                                                Cancel
                                                                                            </MDButton>
                                                                                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                                                                                <MDButton
                                                                                                    disabled={btnDisable}
                                                                                                    type="submit"
                                                                                                    variant="gradient"
                                                                                                    color="info"
                                                                                                    autoFocus
                                                                                                >
                                                                                                    {btnDisable ? "Updating..." : "Update"}
                                                                                                </MDButton>
                                                                                            </MDBox>
                                                                                        </MDBox>
                                                                                    }
                                                                                </Grid> : ''}
                                                                        </Form>
                                                                    )}
                                                                </Formik>
                                                            </Grid>
                                                        </Box>
                                                        <MDBox display='flex' sx={{ justifyContent: 'end' }} justify-content='end' mt={4}>
                                                            {selectTab === "Excluded" &&
                                                                <MDButton
                                                                    variant="gradient"
                                                                    color="info"
                                                                    disabled={ocrStatus}
                                                                    onClick={() => onButtonItemClick('Classify')}
                                                                >
                                                                    Move to Classify
                                                                </MDButton>}
                                                            {selectTab === "Approved" &&
                                                                <Tooltip title={'To move this document you must delete it from your QBO account.'} placement="top">
                                                                    <MDBox>
                                                                        <MDButton
                                                                            variant="gradient"
                                                                            color="info"
                                                                            disabled={true}
                                                                        >
                                                                            Move to Classify
                                                                        </MDButton>
                                                                    </MDBox>
                                                                </Tooltip>
                                                            }
                                                        </MDBox>
                                                        {
                                                            <MDBox display='flex' sx={{ justifyContent: 'space-between' }} justify-content='space-between' mt={4}>
                                                                {selectTab === "ToReview" &&
                                                                    <MDButton
                                                                        type="submit"
                                                                        variant="gradient"
                                                                        style={{ color: '#fffff', backgroundColor: '#ffffff' }}
                                                                        disabled={true}
                                                                        onClick={() => onButtonItemClick('AskClient')}
                                                                    >

                                                                    </MDButton>}
                                                                {selectTab === "PendingApproval" &&
                                                                    <MDButton
                                                                        type="submit"
                                                                        variant="gradient"
                                                                        style={{ color: '#fffff', backgroundColor: '#ffffff' }}
                                                                        disabled={true}
                                                                        onClick={() => onButtonItemClick('AskClient')}
                                                                    >

                                                                    </MDButton>}
                                                                {selectTab === "ToReview" &&
                                                                    <MDButton
                                                                        variant="gradient"
                                                                        color="info"
                                                                        disabled={ocrStatus}
                                                                        onClick={() => onButtonItemClick('Classify')}
                                                                    >
                                                                        Move to Classify
                                                                    </MDButton>}
                                                                {selectTab === "PendingApproval" &&
                                                                    <MDButton
                                                                        variant="gradient"
                                                                        color="info"
                                                                        disabled={ocrStatus}
                                                                        onClick={() => onButtonItemClick('Classify')}
                                                                    >
                                                                        Move to Classify
                                                                    </MDButton>}
                                                                {selectTab === "ToReview" &&
                                                                    <MDButton
                                                                        variant="gradient"
                                                                        color="info"
                                                                        disabled={ocrStatus}
                                                                        onClick={() => onButtonItemClick('PendingApproval')}
                                                                    >
                                                                        Move to Pending Approval
                                                                    </MDButton>}
                                                                {selectTab === "PendingApproval" &&
                                                                    <MDButton
                                                                        variant="gradient"
                                                                        color="info"
                                                                        disabled={ocrStatus}
                                                                        onClick={() => onButtonItemClick('Approved')}
                                                                    >
                                                                        Move to Approved
                                                                    </MDButton>}
                                                            </MDBox>
                                                        }
                                                        <MDBox>
                                                            <MDSnackbar
                                                                color="success"
                                                                icon="done"
                                                                // autoHideDuration={3000}
                                                                title="Success"
                                                                content="Document Updated Successfully."
                                                                open={showSnackbar}
                                                                close={() => setShowSnackbar(false)}
                                                            />
                                                        </MDBox>
                                                    </MDBox>
                                                }
                                            </TabPanel>
                                            <TabPanel value={value} index={1}>
                                                <MDBox mt={-3} sx={{ pt: 3 }} >
                                                    {finalSelect == 'Ask Client' || finalSelect == 'Classify' || documentStatus == 'AskClient' || documentStatus == 'Classify' ?
                                                        <MDBox display="flex" alignItems="center" ml={-1} justifyContent="end">
                                                            <Switch
                                                                checked={toggleShow}
                                                                onChange={handleToggle}
                                                                value="toggleShow"
                                                            />
                                                            <MDTypography
                                                                variant="button"
                                                                fontWeight="regular"
                                                                color="text"
                                                                // onClick={handleToggle}
                                                                sx={{ cursor: "pointer", userSelect: "none", }}
                                                            >
                                                                &nbsp;&nbsp;Split by Products/Services

                                                            </MDTypography>
                                                            {canAccess(actions = 'update', resource = 'documents') && documentStatus === 'Synced' || isSynced === true ?
                                                                <MDButton disabled={ocrStatus}
                                                                    variant='contained' color='info' sx={{ ml: 2 }}
                                                                    onClick={() => setOpenQboSync(true)}>
                                                                    Add New Item
                                                                </MDButton>
                                                                :
                                                                <MDButton disabled={ocrStatus}
                                                                    variant='contained' color='info' sx={{ ml: 2 }}
                                                                    onClick={toggleShow ? handleAddItem : handleCategort}
                                                                // onClick={handleAddItem}
                                                                >
                                                                    Add New Item
                                                                </MDButton>
                                                            }
                                                        </MDBox> : ''
                                                    }
                                                    <MDBox mt={1.5} mb={-5} ml={-6} mr={-6} sx={{ p: 3 }}>
                                                        {
                                                            !toggleShow ? dataTableData?.rows?.length === 0 ? <MDBox my={5} sx={{ mt: 1 }}>
                                                                <ThemeProvider theme={myTheme}>
                                                                    <DataGrid
                                                                        rows={itemList}
                                                                        columns={finalSelect == 'Ask Client' || finalSelect == 'Classify' || documentStatus == 'AskClient' || documentStatus == 'Classify' ? showClasses ? cetagorycolumns : cetagorycolumnsWithoutClass : showClasses ? cetagorycolumnsWithotAction : cetagorycolumnsWithoutClassAction}
                                                                        components={{
                                                                            NoRowsOverlay: () => (
                                                                                <Stack height="100%" alignItems="center" justifyContent="center">
                                                                                    No records found
                                                                                </Stack>
                                                                            ),
                                                                        }}
                                                                        sx={{
                                                                            '@media (max-width: 1440px)': {
                                                                                fontWeight: `0  !important`,
                                                                                fontSize: `0.775rem`
                                                                            },
                                                                            '.MuiDataGrid-columnSeparator': {
                                                                                display: 'none',
                                                                            },
                                                                            '.MuiDataGrid-columnHeaderDraggableContainer': {
                                                                                width: '112% !important'
                                                                            },
                                                                            border: 0,
                                                                            '.MuiDataGrid-columnHeaderTitle': {
                                                                                color: 'rgb(123, 128, 154)',
                                                                                fontWeight: 'bold !important',
                                                                            }
                                                                        }}
                                                                        pageSizeOptions={[5, 10, 25, 50, 100]}
                                                                        paginationModel={paginationModel}
                                                                        onPaginationModelChange={setPaginationModel} />
                                                                </ThemeProvider>
                                                            </MDBox> :
                                                                <ThemeProvider theme={myTheme}>
                                                                    <Box
                                                                        sx={{
                                                                            '& .caterror': {
                                                                                borderBottom: `0.5px solid red !important`
                                                                            },
                                                                        }}
                                                                    >
                                                                        <DataGrid
                                                                            rows={itemList}
                                                                            columns={finalSelect == 'Ask Client' || finalSelect == 'Classify' || documentStatus == 'AskClient' || documentStatus == 'Classify' ? showClasses ? cetagorycolumns : cetagorycolumnsWithoutClass : showClasses ? cetagorycolumnsWithotAction : cetagorycolumnsWithoutClassAction}
                                                                            getCellClassName={(params) => {
                                                                                return params?.row?.proderror == true && params?.field === 'category' ? 'caterror' : '';
                                                                            }}
                                                                            disableRowSelectionOnClick
                                                                            sx={{
                                                                                '@media (max-width: 1440px)': {
                                                                                    fontWeight: `0  !important`,
                                                                                    fontSize: `0.775rem`
                                                                                },
                                                                                '.MuiDataGrid-columnSeparator': {
                                                                                    display: 'none',
                                                                                },
                                                                                '.MuiDataGrid-columnHeaderDraggableContainer': {
                                                                                    width: '112% !important'
                                                                                },
                                                                                border: 0,
                                                                                '.MuiDataGrid-columnHeaderTitle': {
                                                                                    color: 'rgb(123, 128, 154)',
                                                                                    fontWeight: 'bold !important',
                                                                                    overflow: 'visible !important'
                                                                                }
                                                                            }}
                                                                            pageSizeOptions={[5, 10, 25, 50, 100]}
                                                                            cellModesModel={cellModesModel}
                                                                            onCellModesModelChange={handleCellModesModelChange}
                                                                            onCellClick={handleCellClick}
                                                                            paginationModel={paginationModel}
                                                                            onPaginationModelChange={setPaginationModel} />
                                                                    </Box>
                                                                </ThemeProvider>
                                                                : dataTableData1?.rows?.length === 0 ? <MDBox my={5} sx={{ mt: 1 }}>
                                                                    <ThemeProvider theme={myTheme}>
                                                                        < DataGrid rows={itemList} columns={finalSelect == 'Ask Client' || finalSelect == 'Classify' || documentStatus == 'AskClient' || documentStatus == 'Classify' ? showClasses ? columns : columnsWitoutClass : showClasses ? columnsWithoutAction : columnsWitoutClassAction}
                                                                            components={{
                                                                                NoRowsOverlay: () => (
                                                                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                                                                        No records found
                                                                                    </Stack>
                                                                                ),
                                                                            }}
                                                                            sx={{
                                                                                '@media (max-width: 1440px)': {
                                                                                    fontWeight: `0  !important`,
                                                                                    fontSize: `0.775rem`
                                                                                },
                                                                                '.MuiDataGrid-columnSeparator': {
                                                                                    display: 'none',
                                                                                },
                                                                                border: 0,
                                                                                '.MuiDataGrid-columnHeaderTitle': {
                                                                                    color: 'rgb(123, 128, 154)',
                                                                                    fontWeight: 'bold !important',
                                                                                    overflow: 'visible !important'
                                                                                }
                                                                            }}
                                                                            pageSizeOptions={[5, 10, 25, 50, 100]}
                                                                            paginationModel={paginationModel}
                                                                            onPaginationModelChange={setPaginationModel} />
                                                                    </ThemeProvider>
                                                                </MDBox> :
                                                                    <ThemeProvider theme={myTheme}>
                                                                        <Box
                                                                            sx={{
                                                                                '& .proerror': {
                                                                                    borderBottom: `0.5px solid red !important`
                                                                                },
                                                                                '& .proerror.tooltiptext': {
                                                                                    visibility: `hidden`,
                                                                                    width: `120px`,
                                                                                    backgroundColor: `black`,
                                                                                    color: `#fff`,
                                                                                    textAlign: `center`,
                                                                                    borderRadius: `6px`,
                                                                                    padding: `5px 0`,
                                                                                    position: `absolute`,
                                                                                    zIndex: 1,
                                                                                    bottom: `100%`,
                                                                                    left: `50%`,
                                                                                    marginLeft: `-60px`,
                                                                                },
                                                                                '& .proerror:hover .tooltiptext': {
                                                                                    visibility: `visible`
                                                                                }
                                                                            }}
                                                                        >
                                                                            <DataGrid
                                                                                rows={itemList}
                                                                                columns={finalSelect == 'Ask Client' || finalSelect == 'Classify' || documentStatus == 'AskClient' || documentStatus == 'Classify' ? showClasses ? columns : columnsWitoutClass : showClasses ? columnsWithoutAction : columnsWitoutClassAction}
                                                                                getCellClassName={(params) => {
                                                                                    return params?.row?.proderror == true && params?.field === 'product' ? 'proerror' : '';
                                                                                }}
                                                                                disableRowSelectionOnClick
                                                                                sx={{
                                                                                    '@media (max-width: 1440px)': {
                                                                                        fontWeight: `0  !important`,
                                                                                        fontSize: `0.775rem`
                                                                                    },
                                                                                    '.MuiDataGrid-columnSeparator': {
                                                                                        display: 'none',
                                                                                    },
                                                                                    border: 0,
                                                                                    '.MuiDataGrid-columnHeaderTitle': {
                                                                                        color: 'rgb(123, 128, 154)',
                                                                                        fontWeight: 'bold !important',
                                                                                        overflow: 'visible !important',
                                                                                        '@media (max-width: 1440px)': {
                                                                                            fontSize: `0.580rem`
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                pageSizeOptions={[5, 10, 25, 50, 100]}
                                                                                cellModesModel={cellModesModel}
                                                                                onCellModesModelChange={handleCellModesModelChange}
                                                                                onCellClick={handleCellClick}
                                                                                paginationModel={paginationModel}
                                                                                onPaginationModelChange={setPaginationModel}
                                                                            />
                                                                        </Box>
                                                                    </ThemeProvider>
                                                        }
                                                    </MDBox>
                                                    <MDBox display='flex' sx={{ justifyContent: 'end' }} justify-content='end' mt={6}>
                                                        {selectTab === "Excluded" &&
                                                            <MDButton
                                                                variant="gradient"
                                                                color="info"
                                                                disabled={ocrStatus}
                                                                onClick={() => onButtonItemClick('Classify')}
                                                            >
                                                                Move to Classify
                                                            </MDButton>}
                                                        {selectTab === "AskClient" &&
                                                            <MDButton
                                                                variant="gradient"
                                                                color="info"
                                                                disabled={ocrStatus}
                                                                onClick={() => onButtonItemClick('Classify')}
                                                            >
                                                                Move to Classify
                                                            </MDButton>}
                                                    </MDBox>
                                                    {
                                                        <MDBox display='flex' sx={{ justifyContent: 'space-between' }} justify-content='space-between' mt={6}>
                                                            {selectTab === "ToReview" &&
                                                                //  && checkFound === true
                                                                <MDButton
                                                                    type="submit"
                                                                    variant="gradient"
                                                                    style={{ paddingLeft: '130px', color: '#fffff', backgroundColor: '#ffffff' }}
                                                                    disabled={true}
                                                                    onClick={() => onButtonItemClick('AskClient')}
                                                                >

                                                                </MDButton>}
                                                            {selectTab === "PendingApproval" &&
                                                                <MDButton
                                                                    type="submit"
                                                                    variant="gradient"
                                                                    style={{ paddingLeft: '130px', color: '#fffff', backgroundColor: '#ffffff' }}
                                                                    disabled={true}
                                                                    onClick={() => onButtonItemClick('AskClient')}
                                                                >

                                                                </MDButton>}
                                                            {selectTab === "ToReview" &&
                                                                <MDButton
                                                                    variant="gradient"
                                                                    color="info"
                                                                    disabled={ocrStatus}
                                                                    onClick={() => onButtonItemClick('Classify')}
                                                                >
                                                                    Move to Classify
                                                                </MDButton>}
                                                            {selectTab === "PendingApproval" &&
                                                                <MDButton
                                                                    variant="gradient"
                                                                    color="info"
                                                                    disabled={ocrStatus}
                                                                    onClick={() => onButtonItemClick('Classify')}
                                                                >
                                                                    Move to Classify
                                                                </MDButton>}
                                                            {selectTab === "Approved" &&
                                                                <Tooltip title={'To move this document you must delete it from your QBO account.'} placement="top">
                                                                    <MDBox>
                                                                        <MDButton
                                                                            variant="gradient"
                                                                            color="info"
                                                                            disabled={true}
                                                                        >
                                                                            Move to Classify
                                                                        </MDButton>
                                                                    </MDBox>
                                                                </Tooltip>
                                                            }
                                                            {selectTab === "Classify" &&
                                                                <MDButton
                                                                    type="submit"
                                                                    variant="gradient"
                                                                    style={{ paddingLeft: '130px', color: '#fffff', backgroundColor: '#ffffff' }}
                                                                    disabled={true}
                                                                    onClick={() => onButtonItemClick('AskClient')}
                                                                >

                                                                </MDButton>}
                                                            {selectTab === "Classify" &&
                                                                <MDButton
                                                                    variant="gradient"
                                                                    color="info"
                                                                    disabled={ocrStatus || !checkFound}
                                                                    onClick={() => onButtonItemClick('AskClient')}
                                                                >
                                                                    Move to Ask Client
                                                                </MDButton>}
                                                            {selectTab === "Classify" &&
                                                                <Tooltip title={!showButton && checkIssue ? `Cannot move to Review until ${totalIssue} issues are resolved` : ''} placement="top">
                                                                    <MDBox>
                                                                        <MDButton
                                                                            variant="gradient"
                                                                            color="info"
                                                                            disabled={!showButton && checkIssue}
                                                                            onClick={() => onButtonItemClick('ToReview')}
                                                                        >
                                                                            Move To Review
                                                                        </MDButton>
                                                                    </MDBox>
                                                                </Tooltip>}
                                                            {selectTab === "ToReview" &&
                                                                <MDButton
                                                                    variant="gradient"
                                                                    color="info"
                                                                    disabled={ocrStatus}
                                                                    onClick={() => onButtonItemClick('PendingApproval')}
                                                                >
                                                                    Move to Pending Approval
                                                                </MDButton>}
                                                            {selectTab === "PendingApproval" &&
                                                                <MDButton
                                                                    variant="gradient"
                                                                    color="info"
                                                                    disabled={ocrStatus}
                                                                    onClick={() => onButtonItemClick('Approved')}
                                                                >
                                                                    Move to Approved
                                                                </MDButton>}
                                                        </MDBox>
                                                    }
                                                    <MDBox>
                                                        <Dialog
                                                            open={alert}
                                                            onClose={hideAlert}
                                                            aria-labelledby="alert-dialog-title"
                                                            sx={{
                                                                "& .MuiDialog-container": {
                                                                    "& .MuiPaper-root": {
                                                                        width: "100%",
                                                                        // height: "45%",
                                                                        maxWidth: "450px",
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <DialogContent sx={{ overflowY: 'hidden' }}>
                                                                <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                                                                    <h3>Are you sure?</h3>
                                                                </DialogContentText>
                                                                <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                                                                    <MDTypography
                                                                        variant="body2"
                                                                        color="secondary"
                                                                        sx={{ fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 4 }}
                                                                    >
                                                                        Once you delete this Item there is no way of getting any of the documents or data back.
                                                                    </MDTypography>
                                                                </DialogContentText>
                                                            </DialogContent>
                                                            <DialogActions sx={{ justifyContent: "center", mt: 2 }} >
                                                                <MDButton
                                                                    variant="gradient"
                                                                    color="success"
                                                                    onClick={successDelete} >
                                                                    Yes, delete it!
                                                                </MDButton>
                                                                <MDButton
                                                                    variant="gradient"
                                                                    color="error"
                                                                    onClick={hideAlert}
                                                                >Cancel
                                                                </MDButton>
                                                            </DialogActions>
                                                        </Dialog>
                                                    </MDBox>
                                                    <MDBox>
                                                        <Dialog
                                                            open={openEditItem}
                                                            onClose={closeProductEdit}
                                                            scroll="body"
                                                            aria-labelledby="scroll-dialog-title"
                                                            aria-describedby="scroll-dialog-description"
                                                            sx={{
                                                                "& .MuiDialog-container": {
                                                                    "& .MuiPaper-root": {
                                                                        width: "100%",
                                                                        maxWidth: "570px",
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <DialogTitle id="scroll-dialog-title"
                                                                sx={{
                                                                    justifyContent: "center",
                                                                    display: 'flex'
                                                                }}>Edit Item</DialogTitle>
                                                            <DialogContent>
                                                                {toggleShow || onIssueClick1 && itemType === 'ItemBasedExpenseLineDetail' ? <EditProductItemDialog showClasses={showClasses} businessId={businessId} noProduct={noProduct} itemListResponse={itemListResponse ? itemListResponse : GetItemListResponse} currencySymbol={currencySymbol} invoiceId={invoice_id} editItemId={editItemId} itemList={itemList} documentsId={invoice_id} onClose={closeProductEdit} noPrice={noPrice} itemPriceMismatch={itemPriceMismatch} noItem={noItem} noQuantity={noQuantity} noUnitProduct={noUnitProduct}
                                                                /> : <EditCategoryItemDialog noCategory={noCategory} showClasses={showClasses} businessId={businessId} itemListResponse={itemListResponse} currencySymbol={currencySymbol} invoiceId={invoice_id} editItemId={editItemId} itemList={itemList} onClose={closeProductEdit}
                                                                />}
                                                            </DialogContent>
                                                        </Dialog>
                                                    </MDBox>
                                                    <MDBox>
                                                        <Dialog
                                                            open={openAddItem}
                                                            scroll="body"
                                                            aria-labelledby="scroll-dialog-title"
                                                            aria-describedby="scroll-dialog-description"
                                                            sx={{
                                                                "& .MuiDialog-container": {
                                                                    "& .MuiPaper-root": {
                                                                        width: "100%",
                                                                        maxWidth: "570px",
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <DialogTitle id="scroll-dialog-title" sx={{
                                                                justifyContent: "center",
                                                                display: 'flex'
                                                            }}>Add New Item</DialogTitle>
                                                            <DialogContent
                                                            >
                                                                <AddItemDialog showClasses={showClasses} toggleShow={toggleShow} itemList={itemList} businessId={businessId} currencySymbol={currencySymbol} invoiceId={invoice_id} onClose={() => setOpenAddItem(false)} />
                                                            </DialogContent>
                                                        </Dialog>
                                                    </MDBox>
                                                    <MDBox>
                                                        <MDBox>
                                                            <Dialog
                                                                open={openCategoryAddItem}
                                                                scroll="body"
                                                                aria-labelledby="scroll-dialog-title"
                                                                aria-describedby="scroll-dialog-description"
                                                                sx={{
                                                                    "& .MuiDialog-container": {
                                                                        "& .MuiPaper-root": {
                                                                            width: "100%",
                                                                            maxWidth: "570px",
                                                                        },
                                                                    },
                                                                }}
                                                            >
                                                                <DialogTitle id="scroll-dialog-title" sx={{
                                                                    justifyContent: "center",
                                                                    display: 'flex'
                                                                }}>Add New Item</DialogTitle>
                                                                <DialogContent
                                                                >
                                                                    <AddCategoryItemDialog showClasses={showClasses} itemList={itemList} businessId={businessId} currencySymbol={currencySymbol} invoiceId={invoiceId} toggleShow={toggleShow} onClose={() => setOpenCategoryAddItem(false)} />
                                                                </DialogContent>
                                                            </Dialog>
                                                        </MDBox>
                                                    </MDBox>
                                                </MDBox>
                                            </TabPanel>
                                            <TabPanel value={value} index={2}>
                                                <MDBox my={2} sx={{ mt: 2 }} >
                                                    {AuditLogResponse &&
                                                        auditLogList.length === 0 ? <MDTypography
                                                            variant="body2"
                                                            color="secondary"
                                                            sx={{ marginLeft: "43%", fontSize: "1.20rem", marginTop: "10%" }}
                                                        >
                                                        No Logs
                                                    </MDTypography> : auditLogList
                                                    }
                                                </MDBox>
                                            </TabPanel>
                                            <TabPanel value={value} index={3}>
                                                <MDBox>
                                                    {canAccess(actions = 'create', resource = 'comments') &&
                                                        <Formik
                                                            initialValues={{ name: '' }}
                                                            // validationSchema={InvoiceEditSchema2}
                                                            onSubmit={async (values, actions) => {
                                                                setsubmitcomment(true);
                                                                SetonChanges(false);
                                                                var newStr = textAreaVal.replace(/@/g, '');
                                                                const newstr2 = newStr.replace(/\(/g, '{{').replace(/\)/g, '}}');
                                                                const newStr3 = newstr2.replace(/(?:\[.*?\]|[\[\]])/g, "")
                                                                await CreateComentsListAPICall({
                                                                    payload: [{ "InvoiceId": `${invoice_id}`, "businessId": `${businessId}`, "comment": `${newStr3}` }]
                                                                })
                                                                setLoading(true);
                                                                setBtnDisable1(true);
                                                                setTimeout(() => {
                                                                    setsubmitcomment(false);
                                                                    setTextAreaVal('');
                                                                    actions.setSubmitting(false);
                                                                    actions.resetForm();
                                                                }, 1000);
                                                            }}
                                                        >
                                                            {props => (
                                                                <form onSubmit={props.handleSubmit}>
                                                                    <MentionsInput
                                                                        style={mentionsInputStyle}
                                                                        placeholder="Leave Your Comment Here"
                                                                        value={textAreaVal}
                                                                        onChange={(e) => setTextAreaVal(e.target.value)}
                                                                    >
                                                                        <Mention
                                                                            data={users}
                                                                            displayTransform={(id, display) => `@${display}`}
                                                                        />
                                                                    </MentionsInput>
                                                                    {/* <textarea
                                                                        style={{
                                                                            width: "100%",
                                                                            height: "110px",
                                                                            padding: "12px 20px",
                                                                            boxSizing: "border-box",
                                                                            border: "1px solid #ccc",
                                                                            borderRadius: "2px",
                                                                            // backgroundColor: "#f8f8f8",
                                                                            fontSize: "16px",
                                                                            resize: "none"
                                                                        }}
                                                                        id="name"
                                                                        label="Comment"
                                                                        multiline
                                                                        rows={4}
                                                                        placeholder="Leave Your Comment Here"
                                                                        fullWidth
                                                                        type="textarea"
                                                                        onChange={props.handleChange}
                                                                        onBlur={props.handleBlur}
                                                                        value={props.values.name}
                                                                        name="name"
                                                                    /> */}
                                                                    {textAreaVal.length === 0 && submitcomment === true &&
                                                                        <MDTypography sx={{ mt: 1 }} component="div" variant="caption" color="error" fontWeight="regular">Comment is required.</MDTypography>
                                                                    }
                                                                    <Grid item xs={12} sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
                                                                        <MDButton
                                                                            variant="gradient"
                                                                            color="info"
                                                                            type="submit">
                                                                            {btnDisable1 ? "Submiting..." : "Submit"}
                                                                        </MDButton>
                                                                    </Grid>
                                                                </form>
                                                            )}
                                                        </Formik>
                                                    }
                                                </MDBox>
                                                {canAccess(actions = 'read', resource = 'comments') &&
                                                    <>
                                                        <MDBox>
                                                            Comment History
                                                        </MDBox>
                                                        <MDBox my={3} >

                                                            {CommentsResponse &&
                                                                commentsList.length === 0 ? <MDTypography
                                                                    variant="body2"
                                                                    color="secondary"
                                                                    sx={{ marginLeft: "43%", fontSize: "1.20rem", marginTop: "10%" }}
                                                                >
                                                                No Comments
                                                            </MDTypography> : commentsList}
                                                        </MDBox>
                                                    </>
                                                }
                                            </TabPanel>
                                        </CardContent>
                                    </Card>
                                    <MDBox>
                                        {loading && <LoaderDailog />}
                                    </MDBox>
                                </Grid>
                            </Grid>
                            <MDBox>
                                <MDBox>
                                    <Dialog
                                        open={openEditVendor}
                                        onClose={() => setOpenEditVendor(false)}
                                        aria-labelledby="alert-dialog-title"
                                        sx={{
                                            "& .MuiDialog-container": {
                                                "& .MuiPaper-root": {
                                                    width: "100%",
                                                    maxWidth: "570px",
                                                    minHeight: "30%"
                                                },
                                            },
                                        }}
                                    >   <DialogTitle sx={{
                                        justifyContent: "center",
                                        display: 'flex'
                                    }}>Add Vendor</DialogTitle>
                                        <DialogContent>
                                            <AddVendorDialog vendorSelect={vendorSelect} vendorSelect1={vendorSelect1} invoiceId={invoiceId} businessId={businessId} onClose={handleCloseVendorbtn}
                                            />
                                        </DialogContent>
                                    </Dialog>
                                </MDBox>
                            </MDBox>
                            {/* Delete invoice Dialog */}
                            <MDBox>
                                <Dialog
                                    open={openAddOwner}
                                    onClose={() => setOpenAddOwner(false)}
                                    aria-labelledby="alert-dialog-title"
                                    sx={{
                                        "& .MuiDialog-container": {
                                            "& .MuiPaper-root": {
                                                width: "100%",
                                                maxWidth: "500px",
                                                // height: "100%"
                                            },
                                        },
                                    }}
                                >   <DialogTitle sx={{
                                    justifyContent: "center",
                                    display: 'flex'
                                }}>Add Owner For - {ownerName}</DialogTitle>
                                    <DialogContent>
                                        <AddOwnerDetailsDialog businessId={businessId} />
                                    </DialogContent>
                                </Dialog>
                            </MDBox>
                            <MDBox>
                                <Dialog
                                    open={alertDeleteInvoice}
                                    onClose={hideInvoiceAlert}
                                    aria-labelledby="alert-dialog-title"
                                    sx={{
                                        "& .MuiDialog-container": {
                                            "& .MuiPaper-root": {
                                                width: "100%",
                                                // height: "45%",
                                                maxWidth: "450px",
                                            },
                                        },
                                    }}
                                >
                                    <DialogContent sx={{ overflowY: 'hidden' }}>
                                        <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                                            <h3>Are you sure?</h3>
                                        </DialogContentText>
                                        <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                                            <MDTypography
                                                variant="body2"
                                                color="secondary"
                                                sx={{ fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 4 }}
                                            >
                                                Once you delete this Invoice there is no way of getting any of the documents or data back?
                                            </MDTypography>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions sx={{ justifyContent: "center", mt: 2 }} >
                                        <MDButton
                                            variant="gradient"
                                            color="success"
                                            onClick={successInoiceDelete} >
                                            Yes, delete it!
                                        </MDButton>
                                        <MDButton
                                            variant="gradient"
                                            color="error"
                                            onClick={hideInvoiceAlert}
                                        >Cancel
                                        </MDButton>
                                    </DialogActions>
                                </Dialog>
                            </MDBox>
                            <MDBox>
                                <Dialog
                                    open={oneItemPopup}
                                    onClose={() => setOneItemPopup(false)}
                                    aria-labelledby="alert-dialog-title"
                                    sx={{
                                        "& .MuiDialog-container": {
                                            "& .MuiPaper-root": {
                                                width: "100%",
                                                // height: "45%",
                                                maxWidth: "450px",
                                            },
                                        },
                                    }}
                                >
                                    <DialogContent sx={{ overflowY: 'hidden' }}>
                                        <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                                            <MDTypography
                                                variant="body2"
                                                color="secondary"
                                                sx={{ fontWeight: 300, fontSize: "0.90rem", textAlign: "center", mt: 4 }}
                                            >
                                                At least 1 item is required in the document.
                                            </MDTypography>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => setOneItemPopup(false)}>Close</Button>
                                    </DialogActions>
                                </Dialog>
                            </MDBox>
                            <MDBox>
                                <Dialog
                                    open={alertMoveInvoice}
                                    onClose={hideMoveAlert}
                                    aria-labelledby="alert-dialog-title"
                                    sx={{
                                        "& .MuiDialog-container": {
                                            "& .MuiPaper-root": {
                                                width: "100%",
                                                // height: "40%",
                                                maxWidth: "500px",
                                            },
                                        },
                                    }}
                                >
                                    <DialogContent>
                                        <DialogContentText sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                                            <h3> Are you sure you want to move this invoice? </h3>
                                        </DialogContentText>
                                        <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 1 }}  >
                                            <MDTypography
                                                variant="body1"
                                                color="info"
                                                sx={{ p: 2, justifyContent: "center", display: 'flex', flexDirection: 'column' }}
                                            >
                                                {splitCamelCaseToString(selectTab)}
                                            </MDTypography>
                                            <MDTypography
                                                variant="body2"
                                                color="#037ffd"
                                                sx={{ fontSize: 40, justifyContent: "center", display: 'flex', flexDirection: 'column' }}
                                            > <DriveFileMoveIcon sx={{ color: "#037ffd", fontSize: 40 }} />
                                            </MDTypography>
                                            <MDTypography
                                                variant="body1"
                                                color="info"
                                                sx={{ p: 2, justifyContent: "center", display: 'flex', flexDirection: 'column' }}
                                            >
                                                {splitCamelCaseToString(tabsMove)}
                                            </MDTypography>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions sx={{ justifyContent: "center" }} >
                                        <MDButton
                                            variant="gradient"
                                            color="success"
                                            onClick={successInoiceMove} >
                                            Yes, Move it!
                                        </MDButton>
                                        <MDButton
                                            variant="gradient"
                                            color="error"
                                            onClick={hideMoveAlert}
                                        >Cancel
                                        </MDButton>
                                    </DialogActions>
                                </Dialog>
                            </MDBox>
                            <MDBox>
                                <Modal
                                    open={showApprovedModel}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}  >
                                        <MDBox sx={{ pl: 0, pb: 5, mb: -1 }} display="flex" justifyContent="center">
                                            <MDTypography
                                                component="span"
                                                variant="body2"
                                                color="dark"
                                            >
                                                To move this document you must delete it from your QBO account.
                                            </MDTypography>
                                        </MDBox>
                                        <MDBox sx={{
                                            justifyContent: "end",
                                            display: "flex",
                                            mb: -2.5
                                        }}>
                                            <MDButton onClick={() => setShowApprovedModel(false)}>Close</MDButton>
                                        </MDBox>
                                    </Box>
                                </Modal>
                            </MDBox>
                            <MDBox>
                                <Dialog
                                    open={openQboSyncAskClient}
                                    onClose={closeMoveDialog}
                                    aria-labelledby="alert-dialog-title"
                                    sx={{
                                        "& .MuiDialog-container": {
                                            "& .MuiPaper-root": {
                                                width: "100%",
                                                // height: "40%",
                                                maxWidth: "500px",
                                            },
                                        },
                                    }}
                                >
                                    <DialogContent>
                                        <DialogContentText sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                                            <h3>Are you sure want to proceed with sending them to </h3>
                                        </DialogContentText>
                                        <DialogContentText sx={{ display: "flex", justifyContent: "center" }}>
                                            <h3>the ask client?</h3>
                                        </DialogContentText>
                                        <DialogContentText sx={{ display: "flex", justifyContent: "center", mt: 1 }}  >
                                            <MDTypography
                                                variant="body1"
                                                color="info"
                                                sx={{ p: 2, justifyContent: "center", display: 'flex', flexDirection: 'column' }}
                                            >
                                                {splitCamelCaseToString(selectTab)}
                                            </MDTypography>
                                            <MDTypography
                                                variant="body2"
                                                color="#037ffd"
                                                sx={{ fontSize: 40, justifyContent: "center", display: 'flex', flexDirection: 'column' }}
                                            > <DriveFileMoveIcon sx={{ color: "#037ffd", fontSize: 40 }} />
                                            </MDTypography>
                                            <MDTypography
                                                variant="body1"
                                                color="info"
                                                sx={{ p: 2, justifyContent: "center", display: 'flex', flexDirection: 'column' }}
                                            >
                                                {splitCamelCaseToString('To Review')}
                                            </MDTypography>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions sx={{ justifyContent: "center" }} >
                                        <MDButton
                                            variant="gradient"
                                            color="success"
                                            onClick={successInoiceMove} >
                                            Yes, Move it!
                                        </MDButton>
                                        <MDButton
                                            variant="gradient"
                                            color="error"
                                            onClick={closeMoveDialog}
                                        >Cancel
                                        </MDButton>
                                    </DialogActions>
                                </Dialog>
                            </MDBox>
                            <MDBox>
                                <Dialog
                                    open={openAddNewCategory}
                                    onClose={() => setOpenAddNewCategory(false)}
                                    aria-labelledby="alert-dialog-title"
                                    sx={{
                                        "& .MuiDialog-container": {
                                            "& .MuiPaper-root": {
                                                width: "100%",
                                                maxWidth: "570px",
                                            },
                                        },
                                    }}
                                >   <DialogTitle sx={{
                                    justifyContent: "center",
                                    display: 'flex'
                                }}>Add Category</DialogTitle>
                                    <DialogContent>
                                        <AddNewCategoryDialog Edit={false} invoiceId={invoiceId} businessId={businessId} onClose={() => setOpenAddNewCategory(false)}
                                        />
                                    </DialogContent>
                                </Dialog>
                            </MDBox>
                            <MDBox>
                                <MDBox>
                                    <Dialog
                                        open={openAddOption}
                                        onClose={() => setOpenAddOption(false)}
                                        aria-labelledby="alert-dialog-title"
                                        sx={{
                                            "& .MuiDialog-container": {
                                                "& .MuiPaper-root": {
                                                    width: "100%",
                                                    maxWidth: "570px",
                                                },
                                            },
                                        }}
                                    >   <DialogTitle sx={{
                                        justifyContent: "center",
                                        display: 'flex'
                                    }}>Product/Service information--</DialogTitle>
                                        <DialogContent  >
                                            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                                <ListItem
                                                    alignItems="flex-start"
                                                    key={value}
                                                    disableGutters
                                                    secondaryAction={
                                                        <IconButton aria-label="comment" onClick={() => handleOpenAddNewProduct('Service')}>
                                                            <ArrowForwardIosIcon />
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemButton pr={0} onClick={() => handleOpenAddNewProduct('Service')} >
                                                        <ListItemAvatar>
                                                            <Avatar alt="Service" src="./components/services.png" />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary="Service"
                                                            secondary={
                                                                <React.Fragment>
                                                                    <Typography
                                                                        sx={{ display: 'inline' }}
                                                                        component="span"
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        Services that you provide to customers, for example, landscaping or tax preparation services.
                                                                    </Typography>
                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                                <Divider variant="inset" component="li" />
                                                <ListItem alignItems="flex-start" key={value}
                                                    disableGutters
                                                    secondaryAction={
                                                        <IconButton aria-label="comment" onClick={() => handleOpenAddNewProduct('Inventory')}>
                                                            <ArrowForwardIosIcon />
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemButton pr={0} onClick={() => handleOpenAddNewProduct('Inventory')}>
                                                        <ListItemAvatar>
                                                            <Avatar alt="Inventory" src="/static/images/avatar/2.jpg" />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary="Inventory"
                                                            secondary={
                                                                <React.Fragment>
                                                                    <Typography
                                                                        sx={{ display: 'inline' }}
                                                                        component="span"
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        Products you buy and/or sell and that you track quantities of.
                                                                    </Typography>
                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                                <Divider variant="inset" component="li" />
                                                <ListItem alignItems="flex-start" key={value}
                                                    disableGutters
                                                    secondaryAction={
                                                        <IconButton aria-label="comment" onClick={() => handleOpenAddNewProduct('Non Inventory')}>
                                                            <ArrowForwardIosIcon />
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemButton onClick={() => handleOpenAddNewProduct('Non Inventory')}>
                                                        <ListItemAvatar>
                                                            <Avatar alt="Non Inventory" src="/static/images/avatar/3.jpg" />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary="Non Inventory"
                                                            secondary={
                                                                <React.Fragment>
                                                                    <Typography
                                                                        sx={{ display: 'inline' }}
                                                                        component="span"
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        Products you buy and/or sell but don’t need to (or can’t) track quantities of, for example, nuts and bolts used in an installation.
                                                                    </Typography>
                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                            </List>
                                        </DialogContent>
                                    </Dialog>
                                </MDBox>
                            </MDBox>
                            <MDBox>
                                <Dialog
                                    open={openAddNewProduct}
                                    onClose={() => setOpenAddNewProduct(false)}
                                    aria-labelledby="alert-dialog-title"
                                    sx={{
                                        "& .MuiDialog-container": {
                                            "& .MuiPaper-root": {
                                                width: "100%",
                                                maxWidth: "570px",
                                            },
                                        },
                                    }}
                                >   <DialogTitle sx={{
                                    justifyContent: "center",
                                    display: 'flex'
                                }}>Add Product</DialogTitle>
                                    <DialogContent  >
                                        <AddNewProductDialog addProductSelectType={addProductSelectType} invoiceId={invoiceId} businessId={businessId} onClose={() => setOpenAddNewProduct(false)}
                                        />
                                    </DialogContent>
                                </Dialog>
                            </MDBox>
                            <MDBox>
                                <Dialog
                                    open={openAddNewCustomer}
                                    onClose={() => setOpenAddNewCustomer(false)}
                                    aria-labelledby="alert-dialog-title"
                                    sx={{
                                        "& .MuiDialog-container": {
                                            "& .MuiPaper-root": {
                                                width: "100%",
                                                maxWidth: "570px",
                                            },
                                        },
                                    }}
                                >   <DialogTitle sx={{
                                    justifyContent: "center",
                                    display: 'flex'
                                }}>Add Customer</DialogTitle>
                                    <DialogContent  >
                                        <AddCustomerDialog invoiceId={invoiceId} businessId={businessId} onClose={() => setOpenAddNewCustomer(false)}
                                        />
                                    </DialogContent>
                                </Dialog>
                            </MDBox>
                            <MDBox>
                                <Dialog
                                    open={openAddNewClass}
                                    onClose={() => setOpenAddNewClass(false)}
                                    aria-labelledby="alert-dialog-title"
                                    sx={{
                                        "& .MuiDialog-container": {
                                            "& .MuiPaper-root": {
                                                width: "100%",
                                                maxWidth: "570px",
                                            },
                                        },
                                    }}
                                >   <DialogTitle sx={{
                                    justifyContent: "center",
                                    display: 'flex'
                                }}>Add Class</DialogTitle>
                                    <DialogContent  >
                                        <AddClassDialog invoiceId={invoiceId} businessId={businessId} onClose={() => setOpenAddNewClass(false)}
                                        />
                                    </DialogContent>
                                </Dialog>
                            </MDBox>
                            <MDBox>
                                <CustomDialog
                                    DialogContentText={() => QboSyncMsg()}
                                    open={openQboSync}
                                    height={'27%'}
                                    onClose={() => setOpenQboSync(false)}
                                    Title={''}
                                    button={'Close'}
                                />
                            </MDBox>
                            <MDBox>
                                {loadingCircul && (
                                    <CircularProgress
                                        size={50}
                                        sx={{
                                            color: green[500],
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </MDBox>
                            <MDBox>
                                <MDSnackbar
                                    color="error"
                                    icon="warning"
                                    title="Failed"
                                    content={ErrorMessages}
                                    open={showErrorMoveSnackbar}
                                    close={() => setShowErrorMoveSnackbar(false)}
                                />
                            </MDBox>
                            <MDBox>
                                <MDSnackbar
                                    color="success"
                                    icon="done"
                                    title="Success"
                                    content="The item has been assigned to client for review."
                                    open={showTrueSnackbar}
                                    close={() => setShowTrueSnackbar(false)}
                                />
                            </MDBox>
                            <MDBox>
                                <MDSnackbar
                                    color="success"
                                    icon="done"
                                    title="Success"
                                    content={"The item has been unassigned to client for review"}
                                    open={showFalseSnackbar}
                                    close={() => setShowFalseSnackbar(false)}
                                />
                            </MDBox>
                            <MDBox>
                                <MDSnackbar
                                    color="error"
                                    icon="warning"
                                    title="Failed"
                                    content={ErrorMessagesVendorError}
                                    open={addVendoreerror}
                                    close={() => setAddVendoreerror(false)}
                                />
                            </MDBox>
                            <MDBox>
                                <MDSnackbar
                                    color="error"
                                    icon="warning"
                                    title="Failed."
                                    content={ErrorMessages}
                                    // autoHideDuration={3000}
                                    open={showErrorSnackbar}
                                    close={() => setShowErrorSnackbar(false)}
                                />
                            </MDBox>
                            {/* <MDBox>
                                <CustomDialog
                                    DialogContentText={() => QboSyncMsgAskClient()}
                                    open={openQboSyncAskClient}
                                    onClose={closeMoveDialog}
                                    onSuccess={successInoiceMove}
                                    Title={''}
                                    height={'27%'}
                                    buttonOk={'Move'}
                                    button={'Cancel'}
                                />
                            </MDBox> */}
                            <MDBox>
                                <CustomDialog2
                                    DialogContentText={() => AsignNewClientMsg()}
                                    open={assignNewClient}
                                    onClose={() => setAssignNewClient(false)}
                                    onSuccess={successAssignNewClient}
                                    Title={''}
                                    center={true}
                                    height={'27%'}
                                    buttonOk={'Assign client'}
                                    button={'Cancel'}
                                />
                            </MDBox>
                        </MDBox >
                    </>
            }
        </>
    )
}

export default EditInvoiceDialog;