export const AuditColor = {
    'ITEM_DELETED': "error",
    'COMMENT_CREATED': "success",
    'ITEM_CREATED': "success",
    'INVOICE_BODY_UPDATED': "warning",
    'ITEM_UPDATED': "warning",
    'INVOICE_PROCESSING_COMPLETED': "success",
    'OCR_ENDED': "info",
    'OCR_STARTED': "primary",
    'INVOICE_CREATED': "success",
}
